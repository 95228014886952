import MyCustomUploadAdapterPlugin from "./lib/CKEditorUploadAdapter";

export const Locales = Object.freeze({
    ESPANOL: 'es',
    INGLES: 'en'
});

export const Roles = Object.freeze({
    ADMIN: '1',
    CONTENT: '2',
    ECOMM_MG: '4',
    ECOMM_OP: '5',
    B2B_MG: '6',
    B2B_OP: '7',
});

export const SUPPORTED_LOCALES = [
/*{
    id: 50,
    code: 'es',
    base: '',
    flag: 'es',
    name: 'Castellano',
    translations: '/locales/es.json'
},*/ {
        id: 30,
        code: 'en',
        base: '/en',
        flag: 'en',
        name: 'Inglés',
        translations: '/locales/en.json'
    }/*, {
    id: 65,
    code: 'eu',
    base: '/eu',
    flag: 'eu',
    name: 'Euskera',
    translations: '/locales/eu.json'
}/*, {
    id: 16,
    code: 'ca',
    base: '/ca',
    flag: 'ca',
    name: 'Catalán',
    translations: '/locales/ca.json'
}, {
    id:76,
    code: 'ga',
    base: '/ga',
    flag: 'ga',
    name: 'Gallego',
    translations: '/locales/ga.json'
}*/
]

export const Cookies_Info = [
    {
        name: 'CognitoIdentityId',
        duration: '24h',
        description_i18n: 'cookies_info.cognito'
    },
];

export const CKEditorConfig = {
    toolbar: {
        items: [
            'heading',
            '|',
            'alignment',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'outdent',
            'indent',
            '|',
            'uploadImage',
            'blockQuote',
            'insertTable',
            // 'mediaEmbed',
            'undo',
            'redo',
            '|',
            'sourceEditing',
        ]
    },
    extraPlugins: [MyCustomUploadAdapterPlugin],
};

export const CKEditorConfigMin = {
    toolbar: {
        items: [
            'bold',
            'italic',
        ]
    },
};

export const CKEditorConfigMedium = {
    toolbar: {
        items: [
            'heading',
            '|',
            'alignment',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'outdent',
            'indent',
            '|',
            'blockQuote',
            'insertTable',
            'undo',
            'redo',
            '|',
            'sourceEditing',
        ]
    }
};

export const BOOKING_STATUS = [
    {
        value: -1,
        name: 'all',
    },
    {
        value: 0,
        name: 'pending_redeem',
    },
    {
        value: 1,
        name: 'no_show',
    },
    {
        value: 2,
        name: 'consumed',
    },
    {
        value: 3,
        name: 'canceled',
    },
    {
        value: 4,
        name: 'pending_payment',
    },
    {
        value: 5,
        name: 'cancel_refund',
    },
];

export const MERCHANT_STATUS = [
    {
        value: 0,
        name: 'pending_payment',
    },
    {
        value: 1,
        name: 'paid',
    },
    {
        value: 2,
        name: 'cancelled',
    },
    {
        value: 3,
        name: 'cancelled_refund',
    },
];

export const CURRENCY_SYMBOLS = {
    840: '$',   // USD
    978: '€',   // EUR
    826: '£',   // GBP
    392: '¥',   // JPY
    949: '₺',   // TRY
}

export const DEFAULT_CURRENCY = 978;