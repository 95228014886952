<template>
    <div class="md-layout">
        <div class="md-layout-item md-size-100">
            <h3 class="page-title">
                {{ $t("routes.alerts.title") }}
                <md-progress-spinner v-if="loading" :md-diameter="20" :md-stroke="1" md-mode="indeterminate">
                </md-progress-spinner>
            </h3>
        </div>
        <div class="md-layout-item md-size-100 inline-toolbar">
            <md-field class="search-input">
                <md-icon class="search-icon">image_search</md-icon>
                <label>{{ $t("label.search_alert") }}</label>
                <md-input v-model="search"  @keyup.enter="filter()" ></md-input>
                <md-button v-if="search" class="btn-floating" @click="clearFilter()">
                    <md-icon>close</md-icon>
                </md-button>
            </md-field>
            <md-button class="md-primary md-round btn-big" @click="filter()">
                {{ $t("search") }}
            </md-button>
            <router-link :to="{ name: $i18n.locale + '.alert_create' }" class="md-primary md-round btn-big" tag="md-button">
                {{ $t("new") }}
            </router-link>
        </div>
        <div class="md-layout-chips">
                <md-chips v-model="Filters" md-placeholder="" @md-delete="clearFilter()"></md-chips>
        </div>
        <div class="md-layout-item md-size-100">
            <md-table table-header-color="blue" v-model="Items" :md-sort-order.sync="currentSortOrder"
                :md-sort.sync="currentSort" :md-sort-fn="customSort">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-sort-by="title" :md-label="$t('label.alert_title')">{{ item.title }}
                    </md-table-cell>
                    <md-table-cell md-sort-by="fromDate" :md-label="$t('label.alert_from_date')">{{ item.fromDate }}</md-table-cell>
                    <md-table-cell md-sort-by="alertType" :md-label="$t('label.alert_level')">
                        <div
                            v-bind:class="{ 'Rectangle_lv2': item.alertType == 2, 'Rectangle_lv3': item.alertType == 3, 'Rectangle_lv1': item.alertType == 1 }">
                            <span class="Alerts-level">
                                {{ translateAlertLevel(item.alertType) }}
                            </span>
                        </div>
                    </md-table-cell>
                    <md-table-cell md-sort-by="enabled" :md-label="$t('label.alert_enabled')">
                        <!-- <input type="checkbox" onclick="return false;" id="checkbox" v-model="item.enabled"> -->
                        <img class="Icons-Enabled" src="@/assets/icons-tick.svg" v-show="item.enabled">
                        <img class="Icons-Enabled" src="@/assets/icons-cross.svg" v-show="!item.enabled">
                    </md-table-cell>
                    <md-table-cell class="table-cell-actions">
                        <router-link class="btn-icon" value=""
                            :to="{ name: $i18n.locale + '.alert_edit', params: { alertId: item.alertId } }" tag="button">
                            <md-icon>edit</md-icon>
                        </router-link>
                        <button class="btn-icon" @click="confirmDelete(item)">
                            <md-icon>delete</md-icon>
                        </button>
                    </md-table-cell>
                </md-table-row>
            </md-table>
            <!-- Pagination -->
            <div class="pagination-container">
                <md-pagination v-model="page" :noArrows="false" :perPage="limit" :total="total" />
            </div>
        </div>
        <md-modal v-if="showConfirm" @close="showConfirm = false">
            <h3 slot="header">
                {{ $t("confirm") }}
            </h3>
            <div slot="body">
                {{ $t("confirm_messages.delete") }}
            </div>
            <div slot="footer" class="modal-footer">
                <md-button @click="showConfirm = false" class="md-danger md-round">
                    {{ $t("cancel") }}
                </md-button>
                <md-button @click="deleteItem()" class="md-primary md-round">
                    {{ $t("delete") }}
                </md-button>
            </div>
        </md-modal>
    </div>
</template>

<script>
import { getAlerts, deleteAlert } from "@/services/alert";
import paginationMixin from "@/mixins/pagination";

export default {
    mixins: [paginationMixin],
    data() {
        return {
            Items: [],
            Filters:[],
            search: "",
            loading: false,
            showConfirm: false,
            ItemIdToDelete: "",
            currentSort: 'date',
            currentSortOrder: 'asc',
            sort: "date,asc"
        };
    },

    watch: {
        page() {
            this.fetchItems();
        },
    },

    created: async function () {
        await this.fetchItems();
    },

    methods: {
        async fetchItems() {
            this.loading = true;
            this.sort = this.currentSort + "," + this.currentSortOrder;
            if (this.search) {
                this.Filters = [];
                this.Filters.push(this.search);
            }else{
                this.Filters = [];
                this.search = "";
            }
            const response = await getAlerts(this.page, this.limit, this.search, this.sort);
            if (response) {
                this.Items = response.result;
                this.total = response.pageInfo.totalPages * this.limit;
            } else {
                this.Items = [];
                this.total = 0;
            }
            this.loading = false;
        },

        filter() {
            this.fetchItems();
        },

        clearFilter() {
            this.search = "";
            this.Filters = [];
            this.fetchItems();
        },

        confirmDelete(Item) {
            this.ItemIdToDelete = Item.alertId;
            this.showConfirm = true;
        },

        async deleteItem() {
            this.loading = true;
            let response = await deleteAlert(this.ItemIdToDelete);
            if (response.status == "200") {
                this.showConfirm = false;
                this.ItemIdToDelete = "";
                this.fetchItems();
                this.loading = false;
                this.$noty.success(this.$t('success.deleted'));
            } else {
                this.$noty.error(this.$t('error.deleted'));
            }
        },

        translateAlertLevel(level) {
            switch (level) {
                case 1:
                    return this.$t("yellow");
                case 2:
                    return this.$t("orange");

                case 3:
                    return this.$t("red");
                default:
                    return "Undefined";
            }
        },

        customSort: function customSort(value) {
            this.fetchItems();
            return value;
        }
    }
}
</script>

<style>

</style>