<template>
	<div class="dashboard-container">
		<div v-if="showStatusBookings" class="dashboard-item" :class="{ loading: isLoading }">
			<apexcharts width="480" type="donut" :options="radialOptions" :series="seriesRadial"></apexcharts>
		</div>
		<div v-if="showMonthlyBookings" class="dashboard-item" :class="{ loading: isLoading }">
			<apexcharts width="480" type="bar" :options="barOptions" :series="seriesBar"></apexcharts>
		</div>
		<div v-if="showMonthlyTrackings" class="dashboard-item" :class="{ loading: isLoading }">
			<apexcharts width="480" type="bar" :options="chartOptions" :series="seriesBarReverse"></apexcharts>
		</div>
		<div v-if=showMonthlyUsers class="dashboard-item">
			<apexcharts type="line" width="480" :options="lineOptions" :series="seriesLine"></apexcharts>
		</div>
	</div>
</template>

<style scoped>
.dashboard-item.loading {
	opacity: 0.2;
}

.chart-title {
	position: absolute;
	top: 0px;
	/* Ajusta la posición vertical según sea necesario */
	left: 50%;
	transform: translateX(-50%);
	font-size: 18px;
	/* Tamaño de fuente del título */
	color: #333;
	/* Color del título */
	text-align: center;
	/* Alineación del título */
}

.dashboard-container {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	/* Create 2 equal columns */
	grid-template-rows: repeat(2, 1fr);
	/* Create 2 equal rows */
	grid-gap: 10px;
	/* Adjust the gap between grid items as needed */
}

.dashboard-item {
	padding: 20px;
	text-align: center;
	display: flex;
	/* Center the content vertically */
	justify-content: center;
	align-items: center;
	position: relative;

}
</style>
  
<script>
import VueApexCharts from 'vue-apexcharts'
import { getDashboard } from "@/services/dashboard";
import authMixin from "@/mixins/auth";
import { Roles } from "@/config";


export default {
	name: 'Chart',
	mixins: [authMixin],
	components: {
		apexcharts: VueApexCharts,
	},
	data: function () {
		return {
			isLoading: true,
			barOptions: {
				title: {
					text: 'Montly bookings',
					align: 'left'
				},
				theme: {
					palette: 'palette1'
				},
				chart: {
					id: 'Bookings'
				},
				xaxis: {
					categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
				}
			},
			seriesBar: [{
				name: 'Bookings',
				data: []
			}],
			seriesRadial: [],
			radialOptions: {
				title: {
					text: 'Monthly bookings by status',
					align: 'left',
				},
				plotOptions: {
					radialBar: {
						dataLabels: {
							total: {
								show: true,
								label: 'Total',
								formatter: function (w) {
									const total = w.globals.series.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
									return total;
								}
							}
						}
					}
				},
				labels: ['Pending Redeem', 'No show', 'Consumed', 'Cancelled', 'Pending Payment', 'Cancelled & Refund'],
			},
			seriesLine: [{
				name: "Users",
				data: []
			}],
			lineOptions: {
				chart: {
					height: 350,
					type: 'line',
					zoom: {
						enabled: false
					}
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					curve: 'straight'
				},
				title: {
					text: 'Monthly registered users',
					align: 'left'
				},
				grid: {
					row: {
						colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
						opacity: 0.5
					},
				},
				xaxis: {
					categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
				}
			},
			chartOptions: {
				title: {
					text: 'Monthly trackings',
					align: 'left'
				},
				chart: {
					type: 'bar',
					height: 350
				},
				annotations: {
					xaxis: [],
					yaxis: []
				},
				plotOptions: {
					bar: {
						horizontal: true,
					}
				},
				dataLabels: {
					enabled: true
				},
				xaxis: {
					categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
				},
				grid: {
					xaxis: {
						lines: {
							show: true
						}
					}
				},
				yaxis: {
					reversed: true,
					axisTicks: {
						show: true
					}
				}
			},
			seriesBarReverse: [{
				name: 'Tracks',
				data: []
			}],
			Roles: Roles,
		}
	},
	created: async function () {

		const currentUserRole = await this.getUserRole();
		let clientId = null;
		if (currentUserRole == Roles.B2B_MG || currentUserRole == Roles.B2B_OP) {
			clientId = await this.getUserClientId();
		}
		let response = await getDashboard(clientId);
		let bookingsByMonthYear = response.bookingsByMonthYear;

		let dataBooking2 = this.getMonthlyTotals(bookingsByMonthYear);
		this.seriesBar = [{ name: 'Bookings', data: dataBooking2 }];

		let dataBooking1 = this.getBookingStatusForCurrentMonth(bookingsByMonthYear);
		this.seriesRadial = dataBooking1;

		let dataBooking3 = this.getTotalUsersByMonth(response.usersByMonthYear);
		this.seriesLine[0].data = dataBooking3;

		let dataBooking4 = this.getTotalTracksByMonth(response.tracksByMonthYear);
		this.seriesBarReverse[0].data = dataBooking4;

		this.isLoading = false;

	},

	computed: {
		showMonthlyBookings: function () {
			const currentUserRole = this.currentUser.role;
			if (currentUserRole == Roles.CONTENT) {
				return false;
			}
			return true;
		},

		showStatusBookings: function () {
			const currentUserRole = this.currentUser.role;
			if (currentUserRole == Roles.CONTENT) {
				return false;
			}
			return true;
		},

		showMonthlyTrackings: function () {
			const currentUserRole = this.currentUser.role;
			if (currentUserRole == Roles.B2B_MG || currentUserRole == Roles.B2B_OP) {
				return false;
			}
			return true;
		},

		showMonthlyUsers: function () {
			const currentUserRole = this.currentUser.role;
			if (currentUserRole == Roles.B2B_MG || currentUserRole == Roles.B2B_OP) {
				return false;
			}
			return true;
		},
	},

	methods: {

		getMonthlyTotals(dataCollection) {
			const currentYear = new Date().getFullYear();
			const monthlyTotals = {};

			for (let i = 1; i <= 12; i++) {
				monthlyTotals[`${currentYear}-${String(i).padStart(2, '0')}`] = 0;
			}

			dataCollection.forEach(item => {
				const yearItem = parseInt(item.year);
				const monthItem = parseInt(item.month);
				const totalItem = item.total;

				if (yearItem === currentYear && monthItem >= 1 && monthItem <= 12) {
					const key = `${currentYear}-${String(monthItem).padStart(2, '0')}`;
					monthlyTotals[key] += totalItem;
				}
			});

			const result = Object.keys(monthlyTotals).map(key => ({
				monthYear: key,
				total: monthlyTotals[key]
			}));

			let array = [];
			result.forEach(element => {
				array.push(element.total);
			});

			return array;
		},

		getBookingStatusForCurrentMonth(dataCollection) {
			const currentMonth = new Date().getMonth() + 1; // +1 because months are 0-indexed
			const currentYear = new Date().getFullYear();

			const result = dataCollection
				.filter(item => parseInt(item.month) === currentMonth && parseInt(item.year) === currentYear)
				.map(item => ({ bookingStatus: item.bookingStatus, total: item.total }))
				.sort((a, b) => a.bookingStatus - b.bookingStatus);

			const bookingStatusTotals = new Array(6).fill(0);

			result.forEach(item => {
				const bookingStatus = item.bookingStatus;
				const total = item.total || 0;
				bookingStatusTotals[bookingStatus] += total;
			});

			return bookingStatusTotals;

		},

		getTotalUsersByMonth(dataCollection) {
			const currentYear = new Date().getFullYear();
			const monthlyTotals = new Array(12).fill(0); // Inicializa un array de 12 elementos, todos en 0

			dataCollection
				.filter(item => parseInt(item.year) === currentYear)
				.forEach(item => {
					const month = parseInt(item.month);
					const total = item.total || 0;
					if (month >= 1 && month <= 12) {
						monthlyTotals[month - 1] += total; // Resta 1 para ajustar el índice (enero es 1, febrero es 2, etc.)
					}
				});

			return monthlyTotals;
		},

		getTotalTracksByMonth(dataCollection) {
			const currentYear = new Date().getFullYear();
			const monthlyTotals = new Array(12).fill(0); // Inicializa un array de 12 elementos, todos en 0

			dataCollection
				.filter(item => parseInt(item.year) === currentYear)
				.forEach(item => {
					const month = parseInt(item.month);
					const total = item.total || 0;
					if (month >= 1 && month <= 12) {
						monthlyTotals[month - 1] += total; // Resta 1 para ajustar el índice (enero es 1, febrero es 2, etc.)
					}
				});

			return monthlyTotals;
		}
	}
};

</script>
  