<template>
    <ValidationObserver tag="form" ref="form" v-slot="{ errors }">
        <div id="Main" class="md-layout">
            <!-- Button -->
            <div class="md-layout-item md-size-100 form-toolbar">
                <h3 class="page-title">
                    <span v-if="creating">
                        {{ $t("routes.newsItem_create.title") }}
                    </span>
                    <span v-if="editing">
                        {{ $t("routes.newsItem_edit.title") }} {{ item.title }}
                    </span>
                </h3>
                <div class="form-buttons">
                    <md-progress-spinner v-if="loading" :md-diameter="30" :md-stroke="3" md-mode="indeterminate">
                    </md-progress-spinner>
                    <!-- Save button -->
                    <md-button class="md-primary md-round  btn-medium" @click="save()">
                        {{ $t("save") }}
                    </md-button>
                </div>
            </div>
            <!-- publish date -->
            <div class="md-layout-item md-size-50">
                <md-datepicker v-model="item.date" :md-immediately="true">
                    <label>{{ $t("label.newsItem_publishing_date") }}</label>
                    <span class="md-error">
                        {{ errorDateMessage }}
                    </span>
                </md-datepicker>
            </div>
            <!-- author -->
            <div class="md-layout-item md-size-50">
                <md-field :class="[
                    { 'md-valid': errors['author'] && !errors['author'][0] && touchedFields.author },
                    { 'md-error': errors['author'] && errors['author'][0] }
                ]">
                    <label for="author">{{ $t('fields.author') }}</label>
                    <validation-provider name='author' rules="required" v-slot="{ errors, touched }" mode="aggressive">
                        <md-input v-model="item.author" name="author" type="text" :placeholder="$t('label.newsItem_author')"
                            id="author" :disabled="loading">
                        </md-input>
                        <slide-y-down-transition>
                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                        </slide-y-down-transition>
                        <span class="md-error">{{ errors[0] }}</span>
                    </validation-provider>
                </md-field>
            </div>
            <!-- media id -->
            <div class="md-layout-item md-size-40">
                <div class="md-layout">
                    <div>
                        <md-icon class="md-42">iconimage_search</md-icon>
                    </div>
                    <div class="md-layout-item md-size-70">
                        <md-field class="image-field">
                            <label class="label-images" v-if="item.media.fileName == ''">{{ $t("label.newsItem_image")
                            }}</label>
                            <label v-if="item.media.fileName == ''">{{ $t("label.select_image") }}</label>
                            <label v-else>{{ $t("label.newsItem_image") }}</label>
                            <md-input readonly id="mediaInput" v-model="item.media.fileName" :disabled="true">
                            </md-input>
                            <md-button v-if="item.media.fileName != ''" class="btn-floating" @click="clearMedia()">
                                <md-icon>close</md-icon>
                            </md-button>
                            <span class="md-error">
                                {{ errorMediaMessage }}
                            </span>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-20">
                        <md-button @click="openMediaModal()" class="md-primary md-round  btn-medium">
                            {{ $t("select_file") }}
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="md-layout-item md-size-10">

            </div>
            <!-- media image preview -->
            <div class="md-layout-item md-size-40">
                <div class="media-container">
                    <md-icon v-if="item.media.imageURL == ''" class="md-size-4x">image</md-icon>
                    <img v-else :src="item.media.imageURL" :alt="item.media.imageAlt"
                        @click.prevent="viewImage(item.media.imageURL)" />
                </div>
            </div>
            <!-- Separator localizations -->
            <div class="md-layout-item md-size-100">
                <div class="Group-Header">
                    <span class="Luggage-information">
                        {{ $t('label.language_information') }}
                    </span>
                    <div class="Path"></div>
                </div>
            </div>
            <!-- Translations -->
            <md-card class="translations-card">
                <md-card-content>
                    <md-tabs class="translations-tabs">
                        <md-tab id="tab-tr" :md-label="$t('languages.tr')" :md-icon="getLanguageIcon('tr', errors)">
                            <!-- title 1 -->
                            <md-field :class="[
                                { 'md-valid': errors['newsItem_title_tr'] && !errors['newsItem_title_tr'][0] && touchedFields.newsItem_title_tr },
                                { 'md-error': errors['newsItem_title_tr'] && errors['newsItem_title_tr'][0] }
                            ]">
                                <label for="title">{{ $t('fields.newsItem_title_tr') }}</label>
                                <validation-provider name='newsItem_title_tr' rules="required" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="item.newsItem_title_tr" name="newsItem_title_tr" type="text"
                                        :placeholder="$t('label.newsItem_title')" id="newsItem_title_tr"
                                        :disabled="loading">
                                    </md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <!-- subtitle 1 -->
                            <md-field :class="[
                                { 'md-valid': errors['newsItem_subtitle_tr'] && !errors['newsItem_subtitle_tr'][0] && touchedFields.newsItem_subtitle_tr },
                                { 'md-error': errors['newsItem_subtitle_tr'] && errors['newsItem_subtitle_tr'][0] }
                            ]">
                                <label for="title">{{ $t('fields.newsItem_subtitle_tr') }}</label>
                                <validation-provider name='newsItem_subtitle_tr' rules="" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="item.newsItem_subtitle_tr" name="newsItem_subtitle_tr" type="text"
                                        :placeholder="$t('label.newsItem_subtitle')" id="newsItem_subtitle_tr"
                                        :disabled="loading"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <!-- body 1 -->
                            <!-- CK Editor -->
                            <div class="rich-text-editor">
                                <validation-provider name='newsItem_body_tr' rules="required" v-slot="{ errors }"
                                    mode="aggressive">
                                    <ckeditor :editor="editor" v-model="item.newsItem_body_tr" :config="editorConfig">
                                    </ckeditor>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </div>
                            <!--         <md-field :class="[
                                { 'md-valid': errors['newsItem_body_tr'] && !errors['newsItem_body_tr'][0] && touchedFields.newsItem_body_tr },
                                { 'md-error': errors['newsItem_body_tr'] && errors['newsItem_body_tr'][0] }
                            ]">
                                <label for="title">{{ $t('fields.newsItem_body_tr') }}</label>
                                <validation-provider name='newsItem_body_tr' rules="required" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-textarea :md-autogrow="false" v-model="item.newsItem_body_tr"
                                        name="newsItem_body_tr" type="text" :placeholder="$t('label.newsItem_body')"
                                        id="newsItem_body_tr" :disabled="loading"></md-textarea>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field> -->
                            <!-- url 1 -->
                            <md-field :class="[
                                { 'md-valid': errors['newsItem_url_tr'] && !errors['newsItem_url_tr'][0] && touchedFields.newsItem_url_tr },
                                { 'md-error': errors['newsItem_url_tr'] && errors['newsItem_url_tr'][0] }
                            ]">
                                <label for="title">{{ $t('fields.newsItem_url_tr') }}</label>
                                <validation-provider name='newsItem_url_tr' rules="" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="item.newsItem_url_tr" name="newsItem_url_tr" type="text"
                                        :placeholder="$t('label.newsItem_url')" id="newsItem_url_tr" :disabled="loading">
                                    </md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                        </md-tab>
                        <md-tab id="tab-en" :md-label="$t('languages.en')" :md-icon="getLanguageIcon('en', errors)">
                            <!-- title 1 -->
                            <md-field :class="[
                                { 'md-valid': errors['newsItem_title_en'] && !errors['newsItem_title_en'][0] && touchedFields.newsItem_title_en },
                                { 'md-error': errors['newsItem_title_en'] && errors['newsItem_title_en'][0] }
                            ]">
                                <label for="title">{{ $t('fields.newsItem_title_en') }}</label>
                                <validation-provider name='newsItem_title_en' rules="required" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="item.newsItem_title_en" name="newsItem_title_en" type="text"
                                        :placeholder="$t('label.newsItem_title')" id="newsItem_title_en"
                                        :disabled="loading">
                                    </md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <!-- subtitle 1 -->
                            <md-field :class="[
                                { 'md-valid': errors['newsItem_subtitle_en'] && !errors['newsItem_subtitle_en'][0] && touchedFields.newsItem_subtitle_en },
                                { 'md-error': errors['newsItem_subtitle_en'] && errors['newsItem_subtitle_en'][0] }
                            ]">
                                <label for="title">{{ $t('fields.newsItem_subtitle_en') }}</label>
                                <validation-provider name='newsItem_subtitle_en' rules="" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="item.newsItem_subtitle_en" name="newsItem_subtitle_en" type="text"
                                        :placeholder="$t('label.newsItem_subtitle')" id="newsItem_subtitle_en"
                                        :disabled="loading"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <!-- body 1 -->
                            <!-- CK Editor -->
                            <div class="rich-text-editor">
                                <validation-provider name='newsItem_body_en' rules="required" v-slot="{ errors }"
                                    mode="aggressive">
                                    <ckeditor :editor="editor" v-model="item.newsItem_body_en" :config="editorConfig">
                                    </ckeditor>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </div>
                            <!--    <md-field :class="[
                                { 'md-valid': errors['newsItem_body_en'] && !errors['newsItem_body_en'][0] && touchedFields.newsItem_body_en },
                                { 'md-error': errors['newsItem_body_en'] && errors['newsItem_body_en'][0] }
                            ]">
                                <label for="title">{{ $t('fields.newsItem_body_en') }}</label>
                                <validation-provider name='newsItem_body_en' rules="required"
                                    v-slot="{ errors, touched }" mode="aggressive">
                                    <md-textarea :md-autogrow="false" v-model="item.newsItem_body_en" name="newsItem_body_en"
                                        type="text" :placeholder="$t('label.newsItem_body')" id="newsItem_body_en"
                                        :disabled="loading"></md-textarea>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field> -->
                            <!-- url 1 -->
                            <md-field :class="[
                                { 'md-valid': errors['newsItem_url_en'] && !errors['newsItem_url_en'][0] && touchedFields.newsItem_url_en },
                                { 'md-error': errors['newsItem_url_en'] && errors['newsItem_url_en'][0] }
                            ]">
                                <label for="title">{{ $t('fields.newsItem_url_en') }}</label>
                                <validation-provider name='newsItem_url_en' rules="" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="item.newsItem_url_en" name="newsItem_url_en" type="text"
                                        :placeholder="$t('label.newsItem_url')" id="newsItem_url_en" :disabled="loading">
                                    </md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                        </md-tab>
                    </md-tabs>
                </md-card-content>
            </md-card>
            <!-- Media Modal -->
            <media-modal :showProp="showMediaModal" @apply="applyMedia" @close="closeMediaModal()"></media-modal>
        </div>
    </ValidationObserver>
</template>

<script>


import MediaModal from "@/components/Modals/Media";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import { getNewItem, createNewItem, updateNewItem } from "@/services/newsItem";
import localeMixin from "@/mixins/locale";
import { CKEditorConfigMin } from "@/config";
import ClassicEditor from '@fiveflames/ckeditor5-build-classic';


export default {
    mixins: [localeMixin],
    components: {
        MediaModal,
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
    },

    data() {
        return {
            creating: false,
            editing: false,
            imagesLightbox: [],
            item: {
                newsItemId: "",
                media: { fileName: "", imageURL: "", imageAlt: "" },
                date: this.moment().format('DD/MM/yyyy'),
                author: "",
                mediaId: 0,
                imageURL: "",
                imageAlt: "",
                fileName: "",
                title: "",
                newsItem_translationId_tr: 0,
                newsItem_title_tr: "",
                newsItem_subtitle_tr: "",
                newsItem_body_tr: "",
                newsItem_url_tr: "",
                newsItem_translationId_en: 0,
                newsItem_title_en: "",
                newsItem_subtitle_en: "",
                newsItem_body_en: "",
                newsItem_url_en: "",
            },
            showMediaModal: false,
            touchedFields: {
                author: false,
                date: false,
                newsItem_title_en: false,
                newsItem_title_tr: false,
                newsItem_subtitle_en: false,
                newsItem_subtitle_tr: false,
                newsItem_url_en: false,
                newsItem_url_tr: false,
                newsItem_body_en: false,
                newsItem_body_tr: false,
            },
            errorDateMessage: "",
            errorMediaMessage: "",
            loading: false,
            editor: ClassicEditor,
            editorConfig: CKEditorConfigMin,
        };
    },


    watch: {
        'item.date'() {
            this.changeDate();
            //Watch para cambios de valor en la fecha, el control no funciona
        },
    },

    created: async function () {
        let newsItemId = this.$route.params.newsItemId;
        this.configDatePickerLocale();
        if (newsItemId) {
            await this.editItem(newsItemId);
        } else {
            this.newItem();
        }
    },


    methods: {

        viewImage(imageUrl) {
            this.imagesLightbox = [];
            this.$imageViewer.images(this.imagesLightbox);
            const image = {
                url: imageUrl,
            };
            this.imagesLightbox.push(image);
            this.$imageViewer.index(0);
            this.$imageViewer.show();
        },

        applyMedia(media) {
            this.item.media = media;
            this.item.mediaId = media.mediaId;
            this.item.imageURL = media.imageURL;
            this.item.fileName = media.fileName;
            this.item.imageAlt = media.imageAlt;
            this.errorMediaMessage = "";
        },

        clearMedia() {

            this.item.media = { fileName: "", imageURL: "", imageAlt: "" };
            this.item.mediaId = 0;
            this.item.imageURL = this.item.media.imageURL;
            this.item.imageAlt = this.item.media.imageAlt;
            this.item.fileName = this.item.media.fileName;
            this.errorMediaMessage = "";
        },

        openMediaModal() {
            this.showMediaModal = true;
        },

        closeMediaModal() {
            this.showMediaModal = false;
            if (this.item.mediaId == 0) {
                this.errorMediaMessage = this.$t('validation.required', { _field_: this.$t('fields.image') });

            }
        },


        async changeDate() {
            this.errorDateMessage = " ";
            if (!this.item.date) {
                this.errorDateMessage = this.$t('validation.required', { _field_: this.$t('fields.date') });

            }
        },

        async save() {

            this.errorDateMessage = "";
            this.errorMediaMessage = "";

            var valid = await this.$refs.form.validate();

            if (!this.item.date) {
                valid = false;
                this.errorDateMessage = this.$t('validation.required', { _field_: this.$t('fields.date') });

            }
            if (this.item.mediaId == 0) {
                valid = false;
                this.errorMediaMessage = this.$t('validation.required', { _field_: this.$t('fields.image') });
            }

            if (!valid) {
                this.$noty.error(this.$t('error.saved'));
            }


            if (valid) {
                this.loading = true;
                if (this.creating) {
                    let response = await createNewItem(this.convertDataModel());
                    if (response.status == "201") {
                        this.$noty.success(this.$t('success.saved'));
                        this.$router.push({ name: this.$i18n.locale + '.news' });

                    } else {
                        this.$noty.error(this.$t('error.saved'));
                    }
                }
                if (this.editing) {
                    let response = await updateNewItem(this.convertDataModel());
                    if (response.status == "200") {
                        this.$noty.success(this.$t('success.saved'));
                    } else {
                        this.$noty.error(this.$t('error.saved'));
                    }
                }
                this.loading = false;


            }

        },


        assignDataModel(item) {
            if (item.newsItemId) {
                this.item.newsItemId = item.newsItemId;
            } else {
                this.item.newsItemId = "";
            }

            if (item.title) {
                this.item.title = item.title;
            } else {
                this.item.title = "";
            }

            if (item.date) {
                this.item.date = this.moment(item.date).format('DD/MM/yyyy');
            } else {
                const today = this.moment();
                this.item.date = today.format('DD/MM/yyyy');
            }

            if (item.author) {
                this.item.author = item.author;
            } else {
                this.item.author = "";
            }

            if (item.mediaId) {
                this.item.mediaId = item.mediaId;
            } else {
                this.item.mediaId = 0;
            }

            if (item.fileName) {
                this.item.media.fileName = item.fileName;
            } else {
                this.item.media.fileName = "";
            }

            if (item.imageURL) {
                this.item.media.imageURL = item.imageURL;
            } else {
                this.item.media.imageURL = "";
            }

            if (item.imageAlt) {
                this.item.media.imageAlt = item.imageAlt;
            } else {
                this.item.media.imageAlt = "";
            }

            var translation = item.translations.filter(t => t.locale == 'en')[0];

            if (translation) {
                if (translation.translationId) {
                    this.item.newsItem_translationId_en = translation.translationId;
                } else {
                    this.item.newsItem_translationId_en = 0;
                }

                if (translation.title) {
                    this.item.newsItem_title_en = translation.title;
                } else {
                    this.item.newsItem_title_en = "";
                }

                if (translation.subtitle) {
                    this.item.newsItem_subtitle_en = translation.subtitle;
                } else {
                    this.item.newsItem_subtitle_en = "";
                }

                if (translation.body) {
                    this.item.newsItem_body_en = translation.body;
                } else {
                    this.item.newsItem_body_en = "";
                }

                if (translation.url) {
                    this.item.newsItem_url_en = translation.url;
                } else {
                    this.item.newsItem_url_en = "";
                }
            } else {
                this.item.newsItem_translationId_en = 0;
                this.item.newsItem_title_en = "";
                this.item.newsItem_subtitle_en = "";
                this.item.newsItem_body_en = "";
                this.newsItem_url_en = "";
            }


            translation = item.translations.filter(t => t.locale == 'tr')[0];

            if (translation) {
                if (translation.translationId) {
                    this.item.newsItem_translationId_tr = translation.translationId;
                } else {
                    this.item.newsItem_translationId_tr = 0;
                }

                if (translation.title) {
                    this.item.newsItem_title_tr = translation.title;
                } else {
                    this.item.newsItem_title_tr = "";
                }

                if (translation.subtitle) {
                    this.item.newsItem_subtitle_tr = translation.subtitle;
                } else {
                    this.item.newsItem_subtitle_tr = "";
                }

                if (translation.body) {
                    this.item.newsItem_body_tr = translation.body;
                } else {
                    this.item.newsItem_body_tr = "";
                }

                if (translation.url) {
                    this.item.newsItem_url_tr = translation.url;
                } else {
                    this.item.newsItem_url_tr = "";
                }
            } else {
                this.item.newsItem_translationId_tr = 0;
                this.item.newsItem_title_tr = "";
                this.item.newsItem_subtitle_tr = "";
                this.item.newsItem_body_tr = "";
                this.newsItem_url_tr = "";
            }
        },


        convertDataModel() {

            var newItem = {};
            newItem.newsItemId = this.item.newsItemId;
            const momentObject = this.moment(this.item.date, "DD/MM/YYYY");
            const dateISO = momentObject.format("YYYY-MM-DD");
            newItem.date = dateISO;
            newItem.author = this.item.author;
            newItem.mediaId = this.item.mediaId;
            newItem.translations = [];
            var trans_item_tr = {};
            trans_item_tr.translationId = this.item.newsItem_translationId_tr;
            trans_item_tr.locale = "tr";
            trans_item_tr.title = this.item.newsItem_title_tr;
            trans_item_tr.subtitle = this.item.newsItem_subtitle_tr;
            trans_item_tr.body = this.item.newsItem_body_tr;
            trans_item_tr.url = this.item.newsItem_url_tr;
            newItem.translations.push(trans_item_tr);
            var trans_item_en = {};
            trans_item_en.translationId = this.item.newsItem_translationId_en;
            trans_item_en.locale = "en";
            trans_item_en.title = this.item.newsItem_title_en;
            trans_item_en.subtitle = this.item.newsItem_subtitle_en;
            trans_item_en.body = this.item.newsItem_body_en;
            trans_item_en.url = this.item.newsItem_url_en;
            newItem.translations.push(trans_item_en);
            return newItem;

        },

        newItem() {
            this.creating = true;
            this.editing = false;
        },


        editItem(itemId) {
            this.creating = false;
            this.editing = true;
            this.fetchItem(itemId);
        },


        async fetchItem(itemId) {
            this.loading = true;
            let response = await getNewItem(itemId);
            if (response) {
                this.assignDataModel(response);
            }
            this.loading = false;
        }
    }
}


</script>

<style scoped>
.media-container img {
    object-fit: fill;
    cursor: pointer;
}
</style>