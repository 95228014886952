import { API, Auth } from "aws-amplify";
import util from "util";

const getAlerts = async (page = 1, limit = 10, search = "",sortby="") => {
    let responseData = [];
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const language = process.env.VUE_APP_I18N_LOCALE;
    const airportCode = process.env.VUE_APP_AIRPORT_CODE;
    const path = ["/CMS/alert"];
    var headers = {
        "x-api-key": API_KEY,
        "x-app-language": language,
        "x-airport-code": airportCode,
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const queryStringParameters = {
        page: page,
        limit: limit,
        title: search,
        sort:sortby
    };
    const myInit = {
        headers: headers,
        response: true,
        queryStringParameters: queryStringParameters,
    };
    try {
        var response = await API.get(API_NAME, path, myInit);
        responseData = response.data;
        responseData.status = response.status;

    } catch (error) {
        console.error(
            "Error getting alerts: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
    return responseData;
};

const getAlert = async (newsItemId) => {
    let responseData = {};
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const language = process.env.VUE_APP_I18N_LOCALE;
    const airportCode = process.env.VUE_APP_AIRPORT_CODE;
    const path = ["/CMS/alert/" + newsItemId];
    var headers = {
        "x-api-key": API_KEY,
        "x-app-language": language,
        "x-airport-code": airportCode,
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
    };
    try {
        var response = await API.get(API_NAME, path, myInit);
        responseData = response.data;
        responseData.status = response.status;

    } catch (error) {
        console.error(
            "Error getting alert detail: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
    return responseData;
};


const createAlert = async (item) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const language = process.env.VUE_APP_I18N_LOCALE;
    const airportCode = process.env.VUE_APP_AIRPORT_CODE;
    const path = ['/CMS/alert/'];
    var headers = {
        "x-api-key": API_KEY,
        "x-app-language": language,
        "x-airport-code": airportCode,
        "Authorization": (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
        body: item,
    };
    try {
        var response = await API.post(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error creating alert: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
}

const updateAlert = async (item) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const language = process.env.VUE_APP_I18N_LOCALE;
    const airportCode = process.env.VUE_APP_AIRPORT_CODE;
    const path = ['/CMS/alert/' + item.alertId];
    var headers = {
        "x-api-key": API_KEY,
        "x-app-language": language,
        "x-airport-code": airportCode,
        "Authorization": (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
        body: item,
    };
    try {
        var response = await API.put(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error updating alert: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
}

const deleteAlert = async (alertId) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const language = process.env.VUE_APP_I18N_LOCALE;
    const airportCode = process.env.VUE_APP_AIRPORT_CODE;
    const path = ["/CMS/alert/" + alertId];
    var headers = {
        "x-api-key": API_KEY,
        "x-app-language": language,
        "x-airport-code": airportCode,
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    };

    const myInit = {
        headers: headers,
        response: true,
    };
    try {
        var response = await API.del(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error deleting alert: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
};

export { 
    getAlerts, 
    getAlert,
    createAlert,
    updateAlert,
    deleteAlert,
};
