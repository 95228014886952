<template>

  <div>
    <form ref="assecoForm" target="paymentIframe" method="POST" :action="formAction">
      <input type="hidden" name="clientid" v-model="clientId" />
      <input type="hidden" name="amount" v-model="amount" />
      <input type="hidden" name="okurl" v-model="okUrl" />
      <input type="hidden" name="failurl" v-model="failUrl" />
      <input type="hidden" name="trantype" v-model="operationType" />
      <input type="hidden" name="Instalment" v-model="Instalment" />
      <input type="hidden" name="callbackUrl" v-model="callbackUrl" />
      <input type="hidden" name="currency" v-model="currency" />
      <input type="hidden" name="rnd" v-model="rnd" />
      <input type="hidden" name="storetype" v-model="storeType" />
      <input type="hidden" name="lang" v-model="lang" />
      <input type="hidden" name="hashAlgorithm" v-model="hashAlgorithm" />
      <input type="hidden" name="BillToName" v-model="BillToName" />
      <input type="hidden" name="BillToCompany" v-model="BillTocompany" />
      <input type="hidden" name="refreshTime" v-model="refreshTime" />
      <input type="hidden" name="hash" v-model="hash" />
      <input type="hidden" name="oid" v-model="oid" />
      <input type="hidden" name="pan" v-model="pan" />
      <input type="hidden" name="Ecom_Payment_Card_ExpDate_Year" v-model="expDateYear" />
      <input type="hidden" name="Ecom_Payment_Card_ExpDate_Month" v-model="expDateMonth" />
      <input type="hidden" name="cv2" v-model="cv2" />
      <input type="hidden" name="encoding" v-model="encoding" />
      <input type="hidden" name="comments" v-model="comments" />
    </form>

    <div v-show="popupVisible" class="popup">
      <div class="popup-content">
        <div>
          <span class="close" @click="closePopup">&times;</span>
          <iframe name="paymentIframe" id="paymentIframe" scrolling="no"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SHA512, enc } from 'crypto-js';

export default {
  data() {
    return {
      oid: '',
      clientId: "",
      storeType: '3d_pay',
      operationType: 'Auth',
      amount: 0,
      lang: 'en',
      storeKey: "",
      encoding: 'utf-8',
      okUrl: "https://r6rdo4witd.execute-api.eu-west-1.amazonaws.com/dev/iap/asseco",
      failUrl: "https://r6rdo4witd.execute-api.eu-west-1.amazonaws.com/dev/iap/asseco",
      formAction: '',
      currency: '949',
      popupVisible: false,
      debugMode: true,
      env:'',
      BillToName: '',
      rnd: 'asdf',
      pan: '5218487962459752',
      cv2: '000',
      expDateYear: '',
      expDateMonth: '',
      plaintext: '',
      hash: '',
      BillTocompany: '',
      hashAlgorithm: 'ver3',
      refreshTime: 5,
      Instalment: '',
      callbackUrl: "",
      comments: "",
    };
  },
  methods: {
    closePopup() {
      this.$emit("paymentFinishedUndefined", false);
      this.popupVisible = false;
    },
    launchPaymentForm(body) {
      
      const { VUE_APP_BANK_CLIENT: clientId, VUE_APP_BANK_KEY: storeKey, VUE_APP_BANK_URL: action, VUE_APP_BANK_RESP_URL: url, VUE_APP_ENV: viteEnv } = process.env;

      this.env = viteEnv;
      this.clientId = clientId;
      this.storeKey = storeKey;
      this.formAction = action;
      this.okUrl = url;
      this.failUrl = url;
      this.callbackUrl = url;
      this.BillTocompany = '';
      this.storeType = '3D_PAY';      
      this.operationType = 'Auth';
      this.lang = 'en';
      this.encoding = 'utf-8';

      if (body != null) {
        this.amount = body.amount; // Asigna el valor adecuado
        this.currency = body.currency; // Asigna el valor adecuado
        this.oid = body.oid; // Asigna el valor adecuado
        this.rnd = body.rnd;
        this.pan = body.pan; // Asigna el valor adecuado
        this.cv2 = body.cv2;
        this.expDateYear = body.expDateYear;
        this.expDateMonth = body.expDateMonth;
        this.BillToName = body.BillToName;
      }

      this.popupVisible = true;

      if (this.debugMode && this.env != 'pro') {
        console.info("DATOS");
        console.info(this.clientId);
        console.info(this.storeType);
        console.info(this.operationType);
        console.info(this.amount);
        console.info(this.currency);
        console.info(this.oid);
        console.info(this.lang);
        console.info(this.rnd);
        console.info(this.pan);
        console.info(this.storeKey);
        console.info(this.cv2);
        console.info(this.encoding);
        console.info(this.okUrl);
        console.info(this.failUrl);
        console.info(this.expDateYear);
        console.info(this.expDateMonth);
        console.info(this.popupVisible);
        console.info("FORM", this.$refs.assecoForm);
      }

      if (this.generateHash()) {
        setTimeout(() => {
          this.$refs.assecoForm.submit();
        }, 100); //It´s a kind of magic
      }
    },
    generateRandomOrderId() {
      return (Math.floor(Math.random() * (9999999999999 - 1000000000000 + 1)) + 1000000000000).toString();
    },
    generateHash() {
      this.generateHashProd();      
      return true;
    },
    generateHashProd() {
      const {
        amount,
        BillTocompany,
        BillToName,
        callbackUrl,
        clientId,
        currency,
        failUrl,
        hashAlgorithm,
        Instalment,
        lang,
        oid,
        okUrl,
        refreshTime,
        rnd,
        storeType,
        operationType,
        comments,
        cv2,
        expDateMonth,
        expDateYear,
        pan,
        storeKey
      } = this;

      // Reemplazar los caracteres '\' en las cadenas que lo contienen
      const escapeBackslash = str => str.replace(/\\/g, '\\\\');

      // Construir la cadena de texto plano con los valores de los parámetros
      const plaintextValue = `${amount}|${BillTocompany}|${escapeBackslash(BillToName)}|${escapeBackslash(callbackUrl)}|${clientId}|${comments}|${currency}|${cv2}|${expDateMonth}|${expDateYear}|${escapeBackslash(failUrl)}|${hashAlgorithm}|${Instalment}|${lang}|${oid}|${escapeBackslash(okUrl)}|${escapeBackslash(pan)}|${refreshTime}|${rnd}|${storeType}|${operationType}|${storeKey}`;

      this.plaintext = plaintextValue;

      // Calcular el hash SHA-512 y convertirlo a Base64
      const hashValue = SHA512(plaintextValue).toString(enc.Base64);
      this.hash = hashValue;
    },
  }
};
</script>

<style scoped>
.popup {
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;

  width: 100%;
  height: 100%;
  /*
    background: rgba(0, 45, 86, 0.35);
    backdrop-filter: blur(15px);
    */
  background: rgba(0, 45, 86, 0.35);
  backdrop-filter: blur(5px);
  position: fixed;
}


.popup-content {
  /*
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    position: relative;
    */

  background: rgba(255, 255, 255, 1.0);
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.15);

  padding: 1rem 1rem 1rem 1rem;
  border-radius: 1.25rem;
  position: relative;

  /**/
  background-image: url(/src/assets/isologotipo.svg);
  background-repeat: no-repeat;
  background-size: 15rem;
  background-position: center;

  transform: scale(0.8);
}

.popup-content>div:first-of-type {
  display: grid;
  justify-content: center;
  align-content: center;

  overflow: hidden;

  height: 100%;
}

.popup-content iframe {
  background: transparent;
  background: rgba(255, 255, 255, 0.85);
  border: 0;
}




.popup-content .close {
    position: absolute;
    z-index: 1;

    top: -1.25rem;
    right: -1.5rem;

    background: #002d56;
    cursor: pointer;
    color: white;

    font-size: 2.5rem;
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.popup-content .close:hover {
    background: #AD3227;
}




.popup-content iframe {
    padding: 1.0rem 1.0rem 1.0rem 1.0rem;
}

.popup-content iframe {
    width: 100vw;
    height: 100vh;
    max-width: 500px;
    max-height: 725px;
}

@media (max-width: 700px) {
    .popup-content {
        margin-top: 0;
        width: calc(100vw - 5.5rem);
        height: 85vh;
        transform: inherit;
    }
    .popup-content iframe {
        width: 200vw;
        max-width: inherit;
    }
}
</style>