<template>
  <div class="list-container">
    <ul :class="['list-container', alignment === 'vertical' ? 'vertical-align' : 'horizontal-align']">
      <li v-for="(item, index) in items" :key="index" @click="selectItem(index)"
        :class="{ 'selected': index === selectedIndex }">
        <slot :item="item">
          <span class="item-text">{{ item.title }}</span>
          <span :class="['arrow', arrowClass(index)]" v-if="showArrow && hasNextElement(index)"></span>
          <span class="flag-icon" v-if="isLastElement(index)">
            <md-icon>flag</md-icon>
          </span>
        </slot>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    selectedItem: {
      type: Object,
      default: null
    },
    showArrow: {
      type: Boolean,
      default: true
    },
    alignment: {
      type: String,
      default: 'vertical' // Default alignment is set to vertical
    }
  },
  data() {
    return {
      selectedIndex: -1
    };
  },
  watch: {
    items: {
      immediate: true,
      handler() {
        this.updateSelectedIndex(this.selectedItem);
      }
    },
    selectedItem(newValue) {
      this.updateSelectedIndex(newValue);
    }
  },
  methods: {
    selectItem(index) {
      this.selectedIndex = index;
      this.$emit('select', index);
    },
    updateSelectedIndex(selectedItem) {
      if (selectedItem) {
        const index = this.items.findIndex(item => item === selectedItem);
        this.selectedIndex = index;
      } else {
        this.selectedIndex = -1;
      }
    },
    hasNextElement(index) {
      return index < this.items.length - 1;
    },
    isLastElement(index) {
      return index === this.items.length - 1;
    },
    arrowClass(index) {
      if (this.alignment === 'horizontal') {
        const columns = 5; // Number of columns in the horizontal alignment
        const currentRow = Math.floor(index / columns);
        const nextRow = Math.floor((index + 1) / columns);
        
        if (nextRow > currentRow) {
          return 'arrow-down';
        }
      }
      
      return 'arrow-right';
    }
  }
};
</script>

<style scoped>
.list-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 2px;
  margin-top: 0px;
  margin-left: 0px;
  padding-left: 2px;
  margin-right: -13px;
}

.vertical-align {
  flex-direction: column;
}

.horizontal-align {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
}

.horizontal-align ul,
.vertical-align ul {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  list-style: none;
  padding: 0;
  overflow: auto;
  justify-content: flex-start;
  align-items: flex-start;
}

.horizontal-align li,
.vertical-align li {
  margin: 0;
  border-radius: 0;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 4px;
  box-shadow: none;
  text-align: left;
  background-color: #fff;
  cursor: pointer;
  min-width: 150px;
  width: fit-content;
  border: none;
  outline: none;
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
}

.horizontal-align .selected,
.vertical-align .selected {
  background-color: transparent;
  color: #0b1d4c;
  font-weight: bold;
  border-radius: 30px;
}

.vertical-align .item-text {
  flex: 1;
  text-align: left;
}

.horizontal-align .item-text {
  flex: 1;
  text-align: center;
}

.arrow {
  margin-left: 10px;
  width: 8px;
  height: 8px;
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  transform: rotate(-45deg);
  transition: transform 0.3s ease-in-out;
}

.arrow-right {
  transform: rotate(45deg);
}

.arrow-down {
  margin-top: -6px;
  transform: rotate(130deg);
}

.horizontal-align li:hover,
.vertical-align li:hover {
  background-color: rgba(211, 211, 211, 0.251);
  border-radius: 30px;
}


.show-arrow .arrow {
  display: inline-block;
}

.flag-icon {
  display: flex;
  align-items: center;
}

.md-icon.md-icon-font.md-theme-default {
  color: #0b1d4c; /* Por ejemplo, cambiar el color a azul */
  margin-left: 5px;
  font-size: 16px;
}

</style>
