<template>
    <ValidationObserver tag="form" ref="form" v-slot="{ errors }">
        <div class="md-layout form-layout">
            <!-- Button toolbar -->
            <div class="md-layout-item md-size-100 form-toolbar">
                <h3 class="page-title">
                    <span v-if="creating">{{ $t("routes.airport_create.title") }}</span>
                    <span v-if="editing">{{ $t("routes.airport_edit.title") }} - {{ item.destinationName }}</span>
                </h3>
                <div class="form-buttons">
                    <md-progress-spinner v-if="loading" :md-diameter="30" :md-stroke="3" md-mode="indeterminate">
                    </md-progress-spinner>
                    <md-button type="submit" class="md-primary md-round btn-medium" @click.prevent="save()">
                        {{ $t("save") }}
                    </md-button>
                </div>
            </div>
            <!-- Data form -->
            <div class="md-layout-item md-size-50">
                <!-- Airport IATA Code -->
                <md-field :class="[
                    { 'md-valid': errors['airport_iata_code'] && !errors['airport_iata_code'][0] && touchedFields.email },
                    { 'md-error': errors['airport_iata_code'] && errors['airport_iata_code'][0] }
                ]">
                    <label>{{ $t("label.airport_iata_code") }}</label>
                    <validation-provider name='airport_iata_code' rules="required|length:3" v-slot="{ errors, touched }"
                        mode="aggressive">
                        <md-input v-model="item.destinationIATACode"></md-input>
                        <slide-y-down-transition>
                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                        </slide-y-down-transition>
                        <span class="md-error">{{ errors[0] }}</span>
                    </validation-provider>
                </md-field>
                <!-- Media input -->
                <div class="md-layout">
                    <!-- media id -->
                    <div class="md-layout">
                        <div>
                            <md-icon class="md-42">iconimage_search</md-icon>
                        </div>
                        <div class="md-layout-item md-size-70">
                            <md-field class="image-field">
                                <label class="label-images" v-if="item.media.fileName == ''">{{
                                    $t("label.airport_image")
                                }}</label>
                                <label v-if="item.media.fileName == ''">{{ $t("label.select_image") }}</label>
                                <label v-else>{{ $t("label.airport_image") }}</label>
                                <md-input readonly id="mediaInput" v-model="item.media.fileName" :disabled="true">
                                </md-input>
                                <md-button v-if="item.media.fileName != ''" class="btn-floating" @click="clearMedia()">
                                    <md-icon>close</md-icon>
                                </md-button>
                                <span class="md-error">
                                    {{ errorMediaMessage }}
                                </span>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-20">
                            <md-button @click="openMediaModal()" class="md-primary md-round">
                                {{ $t("select_file") }}
                            </md-button>
                        </div>
                    </div>

                </div>
            </div>
            <!-- Media preview -->
            <div class="md-layout-item md-size-50">
                <div class="media-container float-right">
                    <md-icon v-if="item.media.imageURL == ''" class="md-size-5x">image</md-icon>
                    <img v-else :src="item.media.imageURL" :alt="item.media.imageAlt"
                        @click.prevent="viewImage(item.media.imageURL)" />
                </div>
            </div>

            <!-- Separator localizations -->
            <div class="md-layout-item md-size-100">
                <div class="Group-Header">
                    <span class="Luggage-information">
                        {{ $t("label.language_information") }}
                    </span>
                    <div class="Path"></div>
                </div>
            </div>

            <!-- Translations -->
            <md-card class="translations-card">
                <md-card-content>
                    <md-tabs class="translations-tabs">
                        <md-tab id="tab-tr" :md-label="$t('languages.tr')" :md-icon="getLanguageIcon('tr', errors)">
                            <!-- Airport Name TR -->
                            <md-field :class="[
                                { 'md-valid': errors['destination_name_tr'] && !errors['destination_name_tr'][0] && touchedFields.destination_name_tr },
                                { 'md-error': errors['destination_name_tr'] && errors['destination_name_tr'][0] }
                            ]">
                                <label>{{ $t("label.airport_name") }}</label>
                                <validation-provider name='destination_name_tr' rules="required"
                                    v-slot="{ errors, touched }" mode="aggressive">
                                    <md-input v-model="destination_name_tr"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                        </md-tab>
                        <md-tab id="tab-en" :md-label="$t('languages.en')" :md-icon="getLanguageIcon('en', errors)">
                            <!-- Airport Name EN -->
                            <md-field :class="[
                                { 'md-valid': errors['destination_name_en'] && !errors['destination_name_en'][0] && touchedFields.destination_name_en },
                                { 'md-error': errors['destination_name_en'] && errors['destination_name_en'][0] }
                            ]">
                                <label>{{ $t("label.airport_name") }}</label>
                                <validation-provider name='destination_name_en' rules="required"
                                    v-slot="{ errors, touched }" mode="aggressive">
                                    <md-input v-model="destination_name_en"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                        </md-tab>
                    </md-tabs>
                </md-card-content>
            </md-card>

            <!-- Media Modal -->
            <media-modal :showProp="showMediaModal" @apply="applyMedia" @close="closeMediaModal()"></media-modal>
        </div>
    </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import MediaModal from "@/components/Modals/Media";
import localeMixin from "@/mixins/locale";


import {
    getAirport,
    createAirport,
    updateAirport,
} from "@/services/airports";

import {
    getAirportDefaultImage,
} from "@/services/media";

export default {
    mixins: [localeMixin],
    components: {
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
        MediaModal,
    },
    data() {
        return {
            loading: false,
            creating: false,
            editing: false,
            imagesLightbox: [],
            touchedFields: {
                airport_iata_code: false,
                destination_name_tr: false,
                destination_name_en: false,
            },
            item: {
                destinationIATACode: "",
                destinationName: "",
                mediaId: 0,
                imageURL: "",
                fileName: "",
                media: {
                    fileName: "", imageURL: ""
                },
                translations: [
                    {
                        locale: "en",
                        destinationName: "",
                    },
                    {
                        locale: "tr",
                        destinationName: "",
                    }
                ],
            },
            destination_name_en: "",
            destination_name_tr: "",
            errorMediaMessage: "",
            showMediaModal: false,
        };
    },

    created: async function () {
        let airportId = this.$route.params.airportId;
        if (airportId) {
            this.editing = true;
            await this.fetchItem(airportId);
        } else {
            this.creating = true;
        }
    },

    methods: {

        viewImage(imageUrl) {
            this.imagesLightbox = [];
            this.$imageViewer.images(this.imagesLightbox);
            const image = {
                url: imageUrl,
            };
            this.imagesLightbox.push(image);
            this.$imageViewer.index(0);
            this.$imageViewer.show();
        },

        applyMedia(media) {
            this.item.media = media;
            this.item.mediaId = media.mediaId;
            this.item.imageURL = media.imageURL;
            this.item.fileName = media.fileName;
            this.errorMediaMessage = "";
        },

        clearMedia() {
            this.item.media = { fileName: "", imageURL: "" };
            this.item.mediaId = 0;
            this.item.imageURL = this.item.media.imageURL;
            this.item.fileName = this.item.media.fileName;
            this.errorMediaMessage = " ";
        },

        assignDataModel(item) {
            this.item = item;
            this.item.media = {
                mediaId: item.mediaId,
                fileName: item.fileName,
                imageURL: item.imageURL,
            };
            this.putItemTranslations();
        },

        getItemTranslations() {
            let translation_en = this.item.translations.find(({ locale }) => locale == "en");
            if (translation_en) {
                translation_en.destinationName = this.destination_name_en;
            } else {
                translation_en = {
                    locale: "en",
                    destinationName: this.destination_name_en,
                }
            }
            let translation_tr = this.item.translations.find(({ locale }) => locale == "tr");
            if (translation_tr) {
                translation_tr.destinationName = this.destination_name_tr;
            } else {
                translation_tr = {
                    locale: "tr",
                    destinationName: this.destination_name_en,
                }
            }
            const translations = [translation_en, translation_tr];
            this.item.translations = translations;
        },

        putItemTranslations() {
            let translation_en = this.item.translations.find(({ locale }) => locale == "en");
            if (translation_en) {
                this.destination_name_en = translation_en.destinationName;
            }
            let translation_tr = this.item.translations.find(({ locale }) => locale == "tr");
            if (translation_tr) {
                this.destination_name_tr = translation_tr.destinationName;
            }
            const translations = [translation_en, translation_tr];
            this.item.translations = translations;
        },

        openMediaModal() {
            this.showMediaModal = true;
        },

        closeMediaModal() {
            this.showMediaModal = false;
        },

        async fetchItem(itemId) {
            this.loading = true;
            let response = await getAirport(itemId);
            if (response) {
                this.assignDataModel(response);
            }
            this.loading = false;
        },

        async save() {
            this.loading = true;
            var valid = await this.$refs.form.validate();

            if (this.item.mediaId == 0) {
                let response_default = await getAirportDefaultImage();
                this.item.mediaId = response_default.media_id;
                if (this.item.mediaId == 0) {
                    valid = false;
                    this.errorMediaMessage = this.$t('validation.required', { _field_: this.$t('label.airport_image') });
                }
            }

            if (!valid) {
                this.$noty.error(this.$t('error.saved'));
            }

            if (valid) {
                this.getItemTranslations();

                if (this.creating) {
                    let response = await createAirport(this.item);
                    if (response.status == "201") {
                        this.$noty.success(this.$t("success.saved"));
                        this.$router.push({ name: this.$i18n.locale + '.airports' });
                    } else {
                        if (response.status == "422") {
                            if (response.error) {
                                switch (response.error) {
                                    case "ERR00":
                                        var errorMessage = this.$t('error.ERR00', { _field_: this.$t('label.airport_iata_code'), _value_: response.value });
                                        this.$noty.error(errorMessage);
                                        break;
                                    default:
                                        this.$noty.error(this.$t("error.saved"));
                                }
                            } else {
                                this.$noty.error(this.$t("error.saved"));
                            }
                        } else {
                            this.$noty.error(this.$t("error.saved"));
                        }
                    }
                }
                if (this.editing) {
                    let response = await updateAirport(this.item);
                    if (response.status == "200") {
                        this.$noty.success(this.$t("success.saved"));
                    } else {
                        if (response.status == "422") {
                            if (response.error) {
                                switch (response.error) {
                                    case "ERR00":
                                        var errorMessageUp = this.$t('error.ERR00', { _field_: this.$t('label.airport_iata_code'), _value_: response.value });
                                        this.$noty.error(errorMessageUp);
                                        break;
                                    default:
                                        this.$noty.error(this.$t("error.saved"));
                                }
                            } else {
                                this.$noty.error(this.$t("error.saved"));
                            }
                        } else {
                            this.$noty.error(this.$t("error.saved"));
                        }
                    }
                }
            }
            this.loading = false;
        },
    }
}
</script>

<style></style>