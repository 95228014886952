<template>
    <ValidationObserver tag="form" ref="form" v-slot="{ errors }" class="B2B-booking-form-2">
        <div class="md-layout form-layout">
            <!-- Button toolbar -->
            <div class="md-layout-item md-size-100 form-toolbar">
                <h3 class="page-title">
                    <span>{{ $t("routes.booking_edit.title") }} {{ item.reference }}</span>
                </h3>
                <div class="form-buttons">
                    <md-progress-spinner v-if="loading" :md-diameter="30" :md-stroke="3" md-mode="indeterminate">
                    </md-progress-spinner>
                    <!-- Cancel button -->
                    <md-button v-show="role == 4 || role == 1"
                        :disabled="!canCancelAndRefund"
                        class="md-danger md-round" @click="confirmRefund(item)">
                        {{ $t("cancel_and_refund") }}
                    </md-button>
                    <!-- Update button -->
                    <md-button :disabled="item.bookingStatus == 5" class="md-primary md-round btn-medium"
                        @click="confirmUpdate(item)">
                        {{ $t("save") }}
                    </md-button>
                </div>
            </div>
            <!-- Data form -->
            <div class="md-layout-item md-size-30">
                <!-- Reference -->
                <md-field>
                    <label>{{ $t("label.booking_reference") }}</label>
                    <md-input v-model="item.reference" :disabled="true"></md-input>
                </md-field>
            </div>
            <div class="md-layout-item md-size-10"></div>
            <div class="md-layout-item md-size-30">
                <!-- Created Date -->
                <md-field>
                    <label>{{ $t("label.booking_date") }}</label>
                    <md-input v-model="item.createdDate" :disabled="true"></md-input>
                </md-field>
            </div>
            <div class="md-layout-item md-size-10"></div>
            <div class="md-layout-item md-size-20" v-if="item.bookingStatus != 5">
                <!-- Booking Status -->
                <md-field>
                    <label>{{ $t("label.booking_status") }}</label>
                    <md-select v-model="item.bookingStatus" :disabled="loading"
                        :placeholder="$t('label.booking_status')">
                        <md-option v-for="(status, index) in bookingStatusOptions" :key="index" :value="status.value">
                            {{ status.text }}
                        </md-option>
                    </md-select>
                </md-field>
            </div>
            <div class="md-layout-item md-size-20" v-show="item.bookingStatus === 5">
                <md-field>
                    <label>{{ $t("label.booking_status") }}</label>
                    <md-input :disabled="true" :value="statusCancel"></md-input>
                </md-field>
            </div>
            <div class="md-layout-item md-size-30">
                <!-- Client name -->
                <md-field>
                    <label>{{ $t("label.booking_clientName") }}</label>
                    <md-input :disabled="true" v-model="item.clientName"></md-input>
                </md-field>
            </div>
            <div class="md-layout-item md-size-10"></div>
            <div class="md-layout-item md-size-30">
                <!-- client Email -->
                <md-field>
                    <label>{{ $t("label.booking_clientEmail") }}</label>
                    <md-input :disabled="true" v-model="item.clientEmail"></md-input>
                </md-field>
            </div>
            <div class="md-layout-item md-size-10"></div>
            <div class="md-layout-item md-size-20" v-if="item.clientType == 0">
                <!-- Merchant Status -->
                <md-field>
                    <label>{{ $t("label.merchant_status") }}</label>
                    <md-select v-model="item.merchantStatus" :disabled="loading"
                        :placeholder="$t('label.merchant_status')">
                        <md-option v-for="(status, index) in merchantStatusOptions" :key="index" :value="status.value">
                            {{ $t("label.merchant_status_" + status.name) }}
                        </md-option>
                    </md-select>
                </md-field>
            </div>
            <div class="md-layout-item md-size-30">
                <!-- Entry date time -->
                <md-field :class="[
        { 'md-valid': errors['booking_entryDateTime'] && !errors['booking_entryDateTime'][0] && false },
        { 'md-error': errors['booking_entryDateTime'] && errors['booking_entryDateTime'][0] }
    ]">
                    <label>{{ $t("label.booking_entryDateTime") }}</label>
                    <validation-provider name='booking_entryDateTime' v-slot="{ errors, touched }" mode="aggressive">
                        <md-input :disabled="true" v-model="item.entryDateTime"></md-input>
                        <slide-y-down-transition>
                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                        </slide-y-down-transition>
                        <span class="md-error">{{ errors[0] }}</span>
                    </validation-provider>
                </md-field>
            </div>
            <div class="md-layout-item md-size-10"></div>
            <div class="md-layout-item md-size-30">
                <!-- Exit date time -->
                <md-field>
                    <label>{{ $t("label.booking_exitDateTime") }}</label>
                    <md-input :disabled="true" v-model="item.exitDateTime"></md-input>
                </md-field>
            </div>
            <!-- Separator passport info -->
            <div v-if="item.passportInformation && item.passportInformation.length > 0"
                class="md-layout-item md-size-100">
                <div class="Group-Header">
                    <span class="Luggage-information">
                        {{ $t("label.passport_information") }}
                    </span>
                    <div class="Path"></div>
                </div>
            </div>
            <div v-if="item.passportInformation && item.passportInformation.length > 0"
                class="md-layout-item md-size-100">
                <div>
                    <table>
                        <tbody>
                            <tr v-for="(passport, index) in item.passportInformation" :key="index">
                                <td>
                                    <div class="field-switch">
                                        <span style="margin-right: 12px;">{{ $t("label.booking_main") }}</span>
                                        <md-switch id="switchEnabled" v-model="passport.isMain"
                                            :disabled="true"></md-switch>
                                    </div>
                                </td>
                                <td>
                                </td>
                                <td>
                                    <md-field>
                                        <label>{{ $t("label.booking_expeditionCountry") }}</label>
                                        <md-input :disabled="true"
                                            :value="getCountryName(passport.expeditionCountry)"></md-input>
                                    </md-field>
                                </td>
                                <td>
                                </td>
                                <td>
                                    <md-field>
                                        <label>{{ $t("label.booking_passportNumber") }}</label>
                                        <md-input :disabled="true" v-model="passport.passportNumber"></md-input>
                                    </md-field>
                                </td>
                                <td>
                                </td>
                                <td>
                                    <md-field>
                                        <label>{{ $t("label.name") }}</label>
                                        <md-input :disabled="true" v-model="passport.nameAndSurname"></md-input>
                                    </md-field>
                                </td>


                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- Separator flight info -->
            <div class="md-layout-item md-size-100">
                <div class="Group-Header">
                    <span class="Luggage-information">
                        {{ $t("label.flight_information") }}
                    </span>
                    <div class="Path"></div>
                </div>
            </div>
            <div class="md-layout-item md-size-40" v-if="item.flightInformation.flightDirection">
                <!-- Flight Direction -->
                <md-field>
                    <label>{{ $t("label.booking_flightDirection") }}</label>
                    <md-input :disabled="true"
                        :value="$t('label.booking_flightDirection_Value_' + item.flightInformation.flightDirection)"></md-input>
                </md-field>
            </div>
            <div class="md-layout-item md-size-10"></div>
            <div class="md-layout-item md-size-40">
                <!-- Airline Code -->
                <md-field>
                    <label>{{ $t("label.airline_name") }}</label>
                    <md-input :disabled="true" v-model="item.flightInformation.airlineName"></md-input>
                </md-field>
            </div>
            <div class="md-layout-item md-size-40">
                <!-- Origin or destination Airport -->
                <md-field>
                    <label>{{ $t("label.booking_originOrDestinationAirport") }}</label>
                    <md-input :disabled="true"
                        v-model="item.flightInformation.originOrDestinationAirportName"></md-input>
                </md-field>
            </div>
            <div class="md-layout-item md-size-10"></div>
            <div class="md-layout-item md-size-40">
                <!-- flight Number -->
                <md-field>
                    <label>{{ $t("label.booking_flightNumber") }}</label>
                    <md-input :disabled="true" v-model="item.flightInformation.flightNumber"></md-input>
                </md-field>
            </div>
            <div class="md-layout-item md-size-40">
                <!-- Flight date time -->
                <md-field>
                    <label>{{ $t("label.flight_date_time") }}</label>
                    <md-input :disabled="true" v-model="item.flightInformation.flightDateTime"></md-input>
                </md-field>
            </div>
            <!-- Separator payment info -->
            <div class="md-layout-item md-size-100">
                <div class="Group-Header">
                    <span class="Luggage-information">
                        {{ $t("label.payment_information") }}
                    </span>
                    <div class="Path"></div>
                </div>
            </div>
            <div class="md-layout-item md-size-40">
                <!-- paymentProvider -->
                <md-field>
                    <label>{{ $t("label.booking_paymentProvider") }}</label>
                    <md-input :disabled="true" v-model="item.paymentInformation.paymentProvider"></md-input>
                </md-field>
            </div>
            <div class="md-layout-item md-size-10"></div>
            <div class="md-layout-item md-size-40">
                <!-- paymentMethod -->
                <md-field>
                    <label>{{ $t("label.booking_paymentMethod") }}</label>
                    <md-input :disabled="true" :value="paymentMethod"></md-input>
                </md-field>
            </div>
            <div class="md-layout-item md-size-40">
                <!-- paymentCreditCardType -->
                <md-field>
                    <label>{{ $t("label.booking_paymentCreditCardType") }}</label>
                    <md-input :disabled="true" v-model="item.paymentInformation.paymentCreditCardType"></md-input>
                </md-field>
            </div>
            <div class="md-layout-item md-size-10"></div>
            <div class="md-layout-item md-size-40">
                <!-- paymentCreditCardMasked -->
                <md-field>
                    <label>{{ $t("label.booking_paymentCreditCardMasked") }}</label>
                    <md-input :disabled="true" v-model="item.paymentInformation.paymentCreditCardMasked"></md-input>
                </md-field>
            </div>
            <div class="md-layout-item md-size-40">
                <!-- transaction -->
                <md-field>
                    <label>{{ $t("label.booking_transaction") }}</label>
                    <md-input :disabled="true" v-model="item.paymentInformation.transaction"></md-input>
                </md-field>
            </div>
            <div class="md-layout-item md-size-10"></div>
            <div class="md-layout-item md-size-40">
                <!-- creditCardExpirationDate -->
                <md-field>
                    <label>{{ $t("label.booking_transactionDate") }}</label>
                    <md-input :disabled="true" v-model="item.paymentInformation.transactionDate"></md-input>
                </md-field>
            </div>
            <div class="md-layout-item md-size-40">
                <!-- creditCardHolder -->
                <md-field>
                    <label>{{ $t("label.booking_creditCardHolder") }}</label>
                    <md-input :disabled="true" v-model="item.paymentInformation.creditCardHolder"></md-input>
                </md-field>
            </div>
            <!-- Separator total charged info -->
            <div class="md-layout-item md-size-100">
                <div class="Group-Header">
                    <span class="Luggage-information">
                        {{ $t("label.total_charged_info") }}
                    </span>
                    <div class="Path"></div>
                </div>
            </div>
            <div class="md-layout-item md-size-40">
                <!-- value -->
                <md-field>
                    <label>{{ $t("label.booking_value") }}</label>
                    <md-input :disabled="true"
                        :value="formatNumber(item.totalCharged.value) + ' ' + getCurrencySymbol(item.totalCharged.currency)"></md-input>
                </md-field>
            </div>
            <div class="md-layout-item md-size-10"></div>
            <div class="md-layout-item md-size-40 hidden">
                <!-- currency -->
                <md-field>
                    <label>{{ $t("label.booking_currency") }}</label>
                    <md-input :disabled="true" v-model="item.totalCharged.currencyAlphabeticCode"></md-input>
                </md-field>
            </div>
            <!-- Separator total charged info -->
            <div class="md-layout-item md-size-100">
                <div class="Group-Header">
                    <span class="Luggage-information">
                        {{ $t("label.booking_quotes") }}
                    </span>
                    <div class="Path"></div>
                </div>
            </div>

            <div>
                <table v-show="item" v-if="info.length > 1">
                    <thead>
                        <tr>
                            <th class="Header">Id</th>
                            <th class="Header">Name</th>
                            <th class="Header">Quantity</th>
                            <th class="Header">Price</th>
                            <!-- <th class="Header">Currency</th> -->
                            <th class="Header">Discount</th>
                        </tr>
                        <tr style="height: 32px;"></tr>
                    </thead>
                    <tbody>

                        <tr v-for="(row, index) in info" :key="index">
                            <td>
                                {{ row.typeText }}
                            </td>
                            <td :class="{
        'Title': row.type === 1,
        'Ticket': row.type === 2,
        'Extra': row.type === 3,
        'Variant': row.type === 4
    }">
                                {{ row.name }}
                            </td>
                            <td :class="'Quantity'">
                                {{ row.quantity }}
                            </td>
                            <td>
                                <span v-if="(typeof row.price) == 'number'">
                                    {{ formatNumber(row.price) }} {{ getCurrencySymbol(row.currency) }}
                                </span>
                            </td>
                            <!-- <td>
                                {{ row.currency }}
                            </td> -->
                            <td>
                                <span v-if="(typeof row.discount) == 'number'">
                                    {{ row.discount }}%
                                </span>
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Separator QR Code -->
            <!-- <div class="md-layout-item md-size-100">
                <div class="Group-Header">
                    <span class="Luggage-information">
                        Qr Code
                    </span>
                    <div class="Path"></div>
                </div>
                <div class="qr-code-content">
                    <qrcode-vue :value="generateQRCode()" :size="300" level="H" />
                </div>
            </div> -->
            <md-modal v-if="showConfirm" @close="showConfirm = false">
                <h3 slot="header">
                    {{ $t("confirm") }}
                </h3>
                <div slot="body">
                    {{ $t("confirm_messages.update") }}
                </div>
                <div slot="footer" class="modal-footer">
                    <md-button @click="showConfirm = false, item.bookingStatus = originalBookingStatus"
                        class="md-danger md-round">
                        {{ $t("cancel") }}
                    </md-button>
                    <md-button @click="updateItem()" class="md-primary md-round">
                        {{ $t("Ok") }}
                    </md-button>
                </div>
            </md-modal>
            <md-modal v-if="showCancelConfirm" @close="showCancelConfirm = false">
                <h3 slot="header">
                    {{ $t("label.cancel_booking_header") }}
                </h3>
                <div slot="body">
                    {{ $t("label.cancel_booking_body") }}
                </div>
                <div slot="footer" class="modal-footer">
                    <md-button @click="showCancelConfirm = false, item.bookingStatus = originalBookingStatus"
                        class="md-danger md-round">
                        {{ $t("cancel") }}
                    </md-button>
                    <md-button @click="cancelItem()" class="md-primary md-round">
                        {{ $t("label.cancel_booking_ok") }}
                    </md-button>
                </div>
            </md-modal>
        </div>
    </ValidationObserver>
</template>

<script>

import { DateTime } from 'luxon';
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
// import QrcodeVue from 'qrcode.vue'
import localeMixin from "@/mixins/locale";
import { CURRENCY_SYMBOLS, MERCHANT_STATUS } from "@/config";
import { COUNTRIES } from "@/locales/countries";
import { Auth } from 'aws-amplify'

import {
    getBooking, updateBooking
} from "@/services/booking";

export default {
    mixins: [localeMixin],
    components: {
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
        // QrcodeVue,
    },
    data() {
        return {
            statusCancel: this.$t('label.booking_status_5'),
            loading: false,
            creating: false,
            editing: false,
            item: {
                bookingStatus: 0,
                merchantStatus: 0,
                totalCharged: 0,
                flightInformation: {},
                paymentInformation: {},
            },
            originalBookingStatus: 0,
            originalMerchantStatus: 0,
            info: [],
            showCancelConfirm: false,
            showConfirm: false,
            ItemIdToUpdate: null,
            ItemIdToRefund: null,
            bookingStatusOptions: [],
            merchantStatusOptions: [],
            role: null,
        }
    },

    async mounted() {
        this.fillTypeArray();
        const user = await Auth.currentAuthenticatedUser();

        this.role = user.attributes['custom:role_id'];

    },

    created: async function () {
        let bookingId = this.$route.params.bookingId;
        if (bookingId) {
            this.editing = false;
            await this.fetchItem(bookingId);
        }
    },

    computed: {
        paymentMethod() {
            const paymentMethodName = this.item.paymentInformation.paymentMethod?.toLowerCase();
            let paymentMethodTranslation = "";
            switch (paymentMethodName) {
                case "cash":
                    paymentMethodTranslation = this.$t("payment_methods.cash");
                    break;
                case "creditcard":
                    paymentMethodTranslation = this.$t("payment_methods.credit_card");
                    break;
                case "agreement":
                    paymentMethodTranslation = this.$t("payment_methods.agreement");
                    break;
                case "yda_pos":
                    paymentMethodTranslation = this.$t("payment_methods.yda_pos");
                    break;
                case "atm_pos":
                    paymentMethodTranslation = this.$t("payment_methods.atm_pos");
                    break;
                case "paterna_pos":
                    paymentMethodTranslation = this.$t("payment_methods.paterna_pos");
                    break;
            }
            return paymentMethodTranslation;
        },
        canCancelAndRefund() {
            if (this.item.bookingStatus == 5 || this.item.paymentInformation.paymentMethod === 'cash') {
                return false;
            } else if (this.item.paymentInformation.transaction) {
                return true;
            } else {
                return false;
            }
        },
    },

    methods: {

        fillTypeArray() {
            this.bookingStatusOptions = [];
            this.bookingStatusOptions.push({ text: this.$t("label.booking_status_0"), value: 0 });
            this.bookingStatusOptions.push({ text: this.$t("label.booking_status_1"), value: 1 });
            this.bookingStatusOptions.push({ text: this.$t("label.booking_status_2"), value: 2 });
            this.bookingStatusOptions.push({ text: this.$t("label.booking_status_3"), value: 3 });
            this.bookingStatusOptions.push({ text: this.$t("label.booking_status_4"), value: 4 });
            this.merchantStatusOptions = MERCHANT_STATUS;
    },

        assignDataModel(item) {
            this.item = item;

            const entryDateTimeString = this.item.entryDateTime; // Suponiendo que this.item.entryDateTime es una cadena de texto con formato ISO 8601
            const flightDateTimeString = this.item.flightInformation.flightDateTime;

            // Convertir la cadena de texto a un objeto DateTime en formato UTC
            const entryDateTimeUTC = DateTime.fromISO(entryDateTimeString, { zone: 'utc' });
            const flightDateTimeUTC = DateTime.fromISO(flightDateTimeString, { zone: 'utc' });

            // Aplicar la diferencia horaria de Dalaman
            const entryDateTimeDalaman = entryDateTimeUTC.setZone(process.env.VUE_APP_AIRPORT_TIMEZONE);
            const flightDateTimeDalaman = flightDateTimeUTC.setZone(process.env.VUE_APP_AIRPORT_TIMEZONE);

            // Formatear la fecha y hora en el formato deseado ('dd/MM/yyyy HH:mm')
            const formattedEntryDateTimeString = entryDateTimeDalaman.toFormat('dd/MM/yyyy HH:mm');
            const formattedFlightDateTimeString = flightDateTimeDalaman.toFormat('dd/MM/yyyy HH:mm');

            // Actualizar this.item.entryDateTime con la cadena de texto formateada
            this.item.entryDateTime = formattedEntryDateTimeString;
            this.item.flightInformation.flightDateTime = formattedFlightDateTimeString;

            //this.item.entryDateTime = this.formatDateTime(this.item.entryDateTime, true);
            this.item.exitDateTime = this.formatDateTime(this.item.exitDateTime);
            this.item.createdDate = this.formatDateTime(this.item.createdDate);
            this.item.paymentInformation.transactionDate = this.formatDateTime(this.item.paymentInformation.transactionDate);
            this.convertData();

            if (item.bookingStatus) {
                this.item.bookingStatus = item.bookingStatus;
                this.originalBookingStatus = item.bookingStatus;
            } else {
                this.item.bookingStatus = 0;
                this.originalBookingStatus = 0;
            }

            if (item.merchantStatus) {
                this.item.merchantStatus = item.merchantStatus;
                this.originalMerchantStatus = item.merchantStatus;
            } else {
                this.item.merchantStatus = 0;
                this.originalMerchantStatus = 0;
            }
        },

        convertData() {



            if (!this.item.anchillaryQuotes || this.item.anchillaryQuotes.length == 0) {
                this.info = [];
                return;
            }

            this.info = [];
            var id = 1;


            this.item.passportInformation.map(item => {
                item.isMain = Boolean(item.isMain);
            });


            this.item.anchillaryQuotes.map(item => {
                var datarow = {};
                datarow.type = 1;
                datarow.typeText = this.$t('label.booking_info_type_1');
                datarow.elementId = item.id;
                datarow.name = item.name;
                datarow.quantity = '';
                datarow.price = '';
                datarow.currency = '';
                datarow.discount = '';
                datarow.id = id;
                id++;
                this.info.push(datarow);
                item.ticketQuotes.map(t => {
                    var datarow = {};
                    datarow.type = 2;
                    datarow.typeText = this.$t('label.booking_info_type_2');
                    datarow.elementId = t.id;
                    datarow.name = t.name;
                    datarow.quantity = t.quantity;
                    datarow.discount = t.discount;
                    datarow.price = t.unitPrice.value;
                    datarow.currency = t.unitPrice.currency;
                    datarow.id = id;
                    id++;
                    this.info.push(datarow);
                });
                item.extraQuotes.map(extra => {
                    var datarow = {};
                    datarow.type = 3;
                    datarow.typeText = this.$t('label.booking_info_type_3');
                    datarow.elementId = extra.id;
                    datarow.name = extra.name;
                    datarow.quantity = '';
                    datarow.price = '';
                    datarow.currency = '';
                    datarow.discount = '';
                    datarow.id = id;
                    id++;
                    this.info.push(datarow);
                    extra.variantQuotes.map(v => {
                        var datarow = {};
                        datarow.type = 4;
                        datarow.typeText = this.$t('label.booking_info_type_4');
                        datarow.elementId = v.id;
                        datarow.name = v.name;
                        datarow.quantity = v.quantity;
                        datarow.discount = v.discount;
                        datarow.price = v.unitPrice.value;
                        datarow.currency = v.unitPrice.currency;
                        datarow.id = id;
                        id++;
                        this.info.push(datarow);
                    });
                });
            });
        },

        async fetchItem(itemId) {
            this.loading = true;
            let response = await getBooking(itemId);
            if (response) {
                this.assignDataModel(response);
                this.fillTypeArray();
            }
            this.loading = false;
        },

        isDateFormat(dateString) {
            const date = new Date(dateString);
            const formattedDate = date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
            return formattedDate === dateString;
        },

        formatDateTime(dateTimeString) {
            const entryTimeUTC = DateTime.fromISO(dateTimeString, { zone: 'utc' });
            const entryTimeDalaman = entryTimeUTC.setZone('Europe/Istanbul');
            const formattedEntryDateTime = entryTimeDalaman.toFormat('dd/MM/yyyy HH:mm');
            return formattedEntryDateTime;
        },

        confirmUpdate(Item) {
            this.ItemIdToUpdate = Item.bookingId;
            this.showConfirm = true;
        },

        confirmRefund(Item) {
            this.ItemIdToRefund = Item.bookingId;
            if (this.item.paymentInformation.paymentMethod.toLowerCase() == 'creditcard' && this.item.bookingStatus != 5) {
                this.showConfirm = false;
                this.showCancelConfirm = true;
            } else {
                this.showConfirm = true;
                this.showCancelConfirm = false;
            }
        },

        async updateItem() {
            this.loading = true;
            const bookingId = this.ItemIdToUpdate;
            const body = {
                bookingStatus: this.item.bookingStatus,
                merchantStatus: this.item.merchantStatus,
            };

            try {
                const response = await updateBooking(this.ItemIdToUpdate, body);

                if (response.status == 200) {
                    this.showConfirm = false;
                    this.ItemIdToUpdate = null;
                    this.$noty.success(this.$t('success.updated'));
                } else {
                    this.$noty.error(this.$t('error.updated'));
                }

                this.fetchItem(bookingId);
            } catch (error) {
                console.error("Error updating booking: ", error);
                this.$noty.error(this.$t('error.updated'));
            } finally {
                this.loading = false;
            }
        },


        async cancelItem() {
            this.loading = true;
            const bookingId = this.ItemIdToRefund;
            const body = { bookingStatus: 5 };

            try {
                const response = await updateBooking(this.ItemIdToRefund, body);

                if (response.status == 200) {
                    if (response.data.result == false) {
                        let errorMore = "";
                        if (response.data.bankResponse && response.data.bankResponse.ErrMsg) {
                            errorMore = response.data.bankResponse.ErrMsg;
                        }
                        const errorMessage = errorMore ? `${this.$t('error.cancelled')}\nError: ${errorMore}` : this.$t('error.cancelled');
                        this.$noty.error(errorMessage);
                    } else {
                        this.$noty.success(this.$t('success.updated'));
                    }
                } else {
                    this.$noty.error(this.$t('error.cancelled'));
                }

                this.showCancelConfirm = false;
                this.ItemIdToRefund = null;
                this.fetchItem(bookingId);
            } catch (error) {
                console.error("Error cancelling booking: ", error);
                this.$noty.error(this.$t('error.cancelled'));
            } finally {
                this.loading = false;
            }
        },


        formatNumber(num) {
            return parseFloat(num).toFixed(2)
        },

        getCurrencySymbol(currency) {
            return CURRENCY_SYMBOLS[currency];
        },

        getCountryName(code) {
            const locale = this.$i18n.locale;
            let countryName = "";
            if (code) {
                const countriesList = COUNTRIES[locale];
                const countryData = countriesList.find(country => country.alpha_2 == code);
                if (countryData) {
                    countryName = countryData.name;
                }
            }
            return countryName;
        },

        generateQRCode() {
            const bookingReference = this.item.reference;
            const mainPassengersName = this.item.passportInformation ? this.item.passportInformation[0].nameAndSurname : "";
            const bookingDate = this.item.createdDate;
            const mainProductBooked = this.item.anchillaryQuotes ? this.item.anchillaryQuotes[0].name : "";

            let QRContent = "";
            QRContent += `Ref: ${bookingReference}\n`;
            QRContent += `${mainPassengersName}\n`;
            QRContent += `BookingDate: ${bookingDate}\n`;
            QRContent += `${mainProductBooked}\n`;

            return QRContent;
        },
    }
}
</script>

<style scoped>
.Header {
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0b1d4c;
    text-align: left;

}

table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
}

.Ticket {
    text-align: left;
    font-family: Lato;
    font-size: 16px;
    color: #0b1d4c;
    padding-left: 12px;
}


.Extra {
    text-align: left;
    padding-top: 12px;
    font-family: Lato;
    font-size: 16px;
    color: #0b1d4c;
}

.Variant {
    text-align: left;
    padding-left: 12px;
    font-family: Lato;
    font-size: 16px;
}

.Quantity {
    padding-left: 20px;
}
</style>