<template>
    <ValidationObserver tag="form" ref="form" v-slot="{ errors }">
        <div class="md-layout form-layout">
            <!-- Button toolbar -->
            <div class="md-layout-item md-size-100 form-toolbar">
                <h3 class="page-title">
                    {{ $t("routes.normal_mode.title") }}
                </h3>
                <div class="form-buttons">
                    <md-progress-spinner v-if="loading" :md-diameter="30" :md-stroke="3" md-mode="indeterminate">
                    </md-progress-spinner>
                    <md-button type="submit" class="md-primary md-round btn-medium" @click.prevent="sendNormal()"
                        :disabled="loading">
                        <span>
                            {{ $t("send") }}
                        </span>
                    </md-button>
                </div>
            </div>

            <!-- Translations -->
            <md-card class="translations-card">
                <md-card-content>
                    <md-tabs class="translations-tabs">
                        <md-tab id="tab-tr" :md-label="$t('languages.tr')" :md-icon="getLanguageIcon('tr', errors)">
                            <!-- Title TR -->
                            <md-field :class="[
                                { 'md-valid': errors['title_tr'] && !errors['title_tr'][0] && touchedFields.title_tr },
                                { 'md-error': errors['title_tr'] && errors['title_tr'][0] }
                            ]">
                                <label>{{ $t("label.title") }}</label>
                                <validation-provider name='title_tr' rules="required" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="title_tr"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <!-- Text TR -->
                            <md-field :class="[
                                { 'md-valid': errors['body_tr'] && !errors['body_tr'][0] && touchedFields.body_tr },
                                { 'md-error': errors['body_tr'] && errors['body_tr'][0] }
                            ]">
                                <label>{{ $t("label.text") }}</label>
                                <validation-provider name='body_tr' rules="required" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="body_tr"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                        </md-tab>
                        <md-tab id="tab-en" :md-label="$t('languages.en')" :md-icon="getLanguageIcon('en', errors)">
                            <!-- Title EN -->
                            <md-field :class="[
                                { 'md-valid': errors['title_en'] && !errors['title_en'][0] && touchedFields.title_en },
                                { 'md-error': errors['title_en'] && errors['title_en'][0] }
                            ]">
                                <label>{{ $t("label.title") }}</label>
                                <validation-provider name='title_en' rules="required" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="title_en"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <!-- Text EN -->
                            <md-field :class="[
                                { 'md-valid': errors['body_en'] && !errors['body_en'][0] && touchedFields.body_en },
                                { 'md-error': errors['body_en'] && errors['body_en'][0] }
                            ]">
                                <label>{{ $t("label.text") }}</label>
                                <validation-provider name='body_en' rules="required" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="body_en"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                        </md-tab>
                    </md-tabs>
                </md-card-content>
            </md-card>

            <div class="md-layout-item md-size-100">
                <md-table table-header-color="blue" v-model="Items" :md-sort-order.sync="currentSortOrder"
                    :md-sort.sync="currentSort" :md-sort-fn="customSort">
                    <md-table-row slot="md-table-row" slot-scope="{ item }">
                        <md-table-cell md-sort-by="createdAt" :md-label="$t('label.createdAt')">
                            <span class="table-content-center">
                                {{ formatDateTime(item.createdAt) }}
                            </span>
                        </md-table-cell>
                        <md-table-cell md-sort-by="title" :md-label="$t('label.title')">{{ item.name }}
                            <span class="table-content-center">
                                {{ item.title }}
                            </span>
                        </md-table-cell>
                        <md-table-cell md-sort-by="body" :md-label="$t('label.body')">
                            <span class="table-content-center">
                                {{ item.body }}
                            </span>
                        </md-table-cell>
                        <md-table-cell md-sort-by="locale" :md-label="$t('label.locale')">
                            <span class="table-content-center">
                                {{ item.locale }}
                            </span>
                        </md-table-cell>

                        <md-table-cell class="table-cell-actions">
                            <md-button @click="copy(item)" class="
                        md-primary md-just-icon md-round md-simple
                        modal-close-btn">
                                <md-icon class="md-size-2x">repeat</md-icon>
                            </md-button>
                        </md-table-cell>
                    </md-table-row>
                </md-table>
                <!-- Pagination -->
                <div class="pagination-container" v-if="Items.length > 0">
                    <md-pagination v-model="page" :noArrows="false" :perPage="limit" :total="total" />
                </div>
            </div>

        </div>
    </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import localeMixin from "@/mixins/locale";
import paginationMixin from "@/mixins/pagination";
import { sendNormalMode, getHistorial } from "../../../services/notifications";
/* import {
    sendNormalMode,
} from "@/services/notifications";
 */
export default {
    mixins: [localeMixin, paginationMixin],
    components: {
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
    },

    data() {
        return {
            loading: false,
            currentSort: 'createdAt',
            currentSortOrder: 'asc',
            Items: [],
            Filters: [],
            search: "",
            sort: "createdAt,asc",
            touchedFields: {
                airport_iata_code: false,
                title_tr: false,
                title_en: false,
                body_en: false,
                body_tr: false,
            },
            item: {
                translations: [
                    {
                        locale: "en",
                        Title: "",
                        Body: "",
                    },
                    {
                        locale: "tr",
                        Title: "",
                        Body: "",
                    }
                ],
            },
            title_en: "",
            title_tr: "",
            body_en: "",
            body_tr: "",
        };
    },

    watch: {
        page() {
            this.fetchItems();
        },
    },

    created: async function () {
        await this.fetchItems();
    },

    methods: {

        copy(item) {
            if (item.locale == "en") {
                this.title_en = item.title;
                this.body_en = item.body;
            }
            if (item.locale == "tr") {
                this.title_tr = item.title;
                this.body_tr = item.body;
            }
        },

        async sendNormal() {
            this.loading = true;
            var valid = await this.$refs.form.validate();
            if (!valid) {
                this.$noty.error(this.$t('error.saved'));
            } else {

                let body_eng =
                {
                    "title": this.title_en,
                    "body": this.body_en,
                    "topic": process.env.VUE_APP_FIREBASE_TOPIC_NORMAL + "_" + "EN",
                    "locale": "en",
                };

                let result_eng = await sendNormalMode(body_eng);


                let body_tr =
                {
                    "title": this.title_tr,
                    "body": this.body_tr,
                    "topic": process.env.VUE_APP_FIREBASE_TOPIC_NORMAL + "_" + "TR",
                    "locale": "tr",
                };

                let result_tr = await sendNormalMode(body_tr);

                if (result_eng.data.result && result_tr.data.result) {
                    this.$noty.success(this.$t('success.send'));
                } else {
                    this.$noty.error(this.$t('error.send'));
                    console.info("result eng", result_eng);
                    console.info("result tr", result_tr);
                }

            }

            this.loading = false;

            await this.fetchItems();

        },

        async fetchItems() {
            this.loading = true;
            this.sort = this.currentSort + "," + this.currentSortOrder;
            if (this.search) {
                this.Filters = [];
                this.Filters.push(this.search);
            } else {
                this.Filters = [];
                this.search = "";
            }
            const response = await getHistorial(this.page, this.limit, this.search, this.sort);
            console.info(response);
            if (response) {
                this.Items = response.result;
                this.total = response.pageInfo.totalPages * this.limit;
            } else {
                this.Items = [];
                this.total = 0;
            }
            this.loading = false;
        },

        isDateFormat(dateString) {
            const date = new Date(dateString);
            const formattedDate = date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
            return formattedDate === dateString;
        },

        formatDateTime(dateTimeString, withtime = false) {
            let date = new Date();

            if (this.isDateFormat(dateTimeString)) {
                return dateTimeString;
            }

            if (dateTimeString) {
                date = new Date(dateTimeString);
            }


            const dateStr = date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
            const timeStr = date.toLocaleTimeString('en-GB', { hour12: false, hour: '2-digit', minute: '2-digit' });
            if (withtime) {
                return `${dateStr} ${timeStr}`;
            }
            return `${dateStr}`;
        },

        customSort: function customSort(value) {
            this.fetchItems();
            return value;
        }
    },
}
</script>

<style scoped></style>