<template>
    <ValidationObserver tag="form" ref="form" v-slot="{ errors }">
        <div class="md-layout form-layout">
            <!-- Button -->
            <div class="md-layout-item md-size-100 form-toolbar">
                <h3 class="page-title">
                    <span v-if="creating">
                        {{ $t("routes.destination_create.title") }}
                    </span>
                    <span v-if="editing">
                        {{ $t("routes.destination_edit.title") }} {{ item.title }}
                    </span>
                </h3>
                <div class="form-buttons">
                    <md-progress-spinner v-if="loading" :md-diameter="30" :md-stroke="3" md-mode="indeterminate">
                    </md-progress-spinner>
                    <!-- Save button -->
                    <md-button class="md-primary md-round btn-medium" @click="save()">
                        {{ $t("save") }}
                    </md-button>
                </div>
            </div>
            <!-- IATACode -->
            <div class="md-layout-item md-size-50">
                <div class="md-layout-item md-size-50">
                    <md-field :class="[
                        { 'md-valid': errors['IATACode'] && !errors['IATACode'][0] && touchedFields.IATACode },
                        { 'md-error': errors['IATACode'] && errors['IATACode'][0] }
                    ]">
                        <label for="author">{{ $t('fields.IATACode') }}</label>
                        <validation-provider name='IATACode' rules="required|max:3|min:2" v-slot="{ errors, touched }"
                            mode="aggressive">
                            <md-input v-model="item.IATACode" name="IATACode" type="text"
                                :placeholder="$t('label.destination_IATACode')" id="IATACode" :disabled="loading">
                            </md-input>
                            <slide-y-down-transition>
                                <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                            </slide-y-down-transition>
                            <span class="md-error">{{ errors[0] }}</span>
                        </validation-provider>
                    </md-field>
                </div>
            </div>
            <!-- Enabled -->
            <div class="md-layout-item md-size-50">
                <div class="field-switch">
                    <Span>{{ $t("label.destination_enabled") }}</Span>
                    <md-switch id="switchEnabled" v-model="item.active" :disabled="loading">
                    </md-switch>
                </div>
            </div>
            <!-- bookingURL -->
            <div class="md-layout-item md-size-50">
                <div class="md-layout-item md-size-50">
                    <md-field :class="[
                        { 'md-valid': errors['bookingURL'] && !errors['bookingURL'][0] && touchedFields.bookingURL },
                        { 'md-error': errors['bookingURL'] && errors['bookingURL'][0] }
                    ]">
                        <label for="author">{{ $t('fields.bookingURL') }}</label>
                        <validation-provider name='bookingURL' rules="" v-slot="{ errors, touched }"
                            mode="aggressive">
                            <md-input v-model="item.bookingURL" name="bookingURL" type="text"
                                :placeholder="$t('label.destination_bookingURL')" id="bookingURL" :disabled="loading">
                            </md-input>
                            <slide-y-down-transition>
                                <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                            </slide-y-down-transition>
                            <span class="md-error">{{ errors[0] }}</span>
                        </validation-provider>
                    </md-field>
                </div>
            </div>
            <!-- media id -->
            <div class="md-layout-item md-size-45">
                <div class="md-layout">
                    <div>
                        <md-icon class="md-42">iconimage_search</md-icon>
                    </div>
                    <div class="md-layout-item md-size-65">
                        <md-field class="image-field">
                            <label class="label-images" v-if="item.media.fileName == ''">{{
                                    $t("label.destination_image")
                            }}</label>
                            <label v-if="item.media.fileName == ''">{{ $t("label.select_image") }}</label>
                            <label v-else>{{ $t("label.destination_image") }}</label>
                            <md-input readonly id="mediaInput" v-model="item.media.fileName" :disabled="true">
                            </md-input>
                            <md-button v-if="item.media.fileName != ''" class="btn-floating" @click="clearMedia()">
                                <md-icon>close</md-icon>
                            </md-button>
                            <span class="md-error">
                                {{ errorMediaMessage }}
                            </span>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-20">
                        <md-button @click="openMediaModal()" class="md-primary md-round btn-medium">
                            {{ $t("select_file") }}
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="md-layout-item md-size-50"></div>
            <!-- media image preview -->
            <div class="media-container-destination">
                <div class="media-container">
                    <md-icon v-if="item.media.imageURL == ''" class="md-size-4x">image</md-icon>
                    <img v-else :src="item.media.imageURL" :alt="item.media.imageAlt" @click.prevent="viewImage(item.media.imageURL)" />
                </div>
            </div>
            <!-- flight information -->
            <div class="md-layout-item md-size-100">
                <div class="Group-Header">
                    <span class="Luggage-information">
                        {{ $t('label.flight_information') }}
                    </span>
                    <div class="Path"></div>
                </div>
            </div>
            <div class="md-layout">
                <!-- Select  Airlines -->
                <div class="md-layout-item md-size-30">
                    <md-field>
                        <md-select multiple :placeholder="$t('label.select_airlines')" v-model="item.airlines">
                            <md-option  v-for="(airline, index) of airlines" :value="airline" :key="index">
                                {{JSON.parse(airline).text}}
                            </md-option>
                         </md-select>
                    </md-field>
                </div>
                <div class="md-layout-item md-size-40"></div>
                <!-- Select Weekdays  Id -->
                <div class="md-layout-item md-size-30">
                    <md-field>
                        <md-select v-model="item.days" :disabled="loading" :placeholder="$t('label.select_weekdays')"
                            multiple>
                            <md-option v-for="(day, idx3) of days" :value="day" :key="idx3">
                                {{ JSON.parse(day).text }}</md-option>
                        </md-select>
                    </md-field>
                </div>
            </div>
            <!-- Separator location -->
            <div class="md-layout-item md-size-100">
                <div class="Group-Header">
                    <span class="Luggage-information">
                        {{ $t('label.location') }}
                    </span>
                    <div class="Path"></div>
                </div>
            </div>
            <div class="md-layout-item md-size-100 inlinefields gaplg">
                <!-- latitude -->
                <md-field :class="[
                    { 'md-valid': errors['latitude'] && !errors['latitude'][0] && touchedFields.latitude },
                    { 'md-error': errors['latitude'] && errors['latitude'][0] }
                ]">
                    <label for="luggageMaxHeight">{{ $t('fields.latitude') }}</label>
                    <validation-provider name='latitude' rules="double|required" v-slot="{ errors, touched }"
                        mode="aggressive">
                        <md-input v-model="item.latitude" name="latitude"
                            :placeholder="$t('label.destination_latitude')" id="latitude" :disabled="loading">
                        </md-input>
                        <slide-y-down-transition>
                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                        </slide-y-down-transition>
                        <span class="md-error">{{ errors[0] }}</span>
                    </validation-provider>
                </md-field>

                <!-- longitude -->
                <md-field :class="[
                    { 'md-valid': errors['longitude'] && !errors['longitude'][0] && touchedFields.longitude },
                    { 'md-error': errors['longitude'] && errors['longitude'][0] }
                ]">
                    <label for="longitude">{{ $t('fields.longitude') }}</label>
                    <validation-provider name='longitude' rules="double|required" v-slot="{ errors, touched }"
                        mode="aggressive">
                        <md-input v-model="item.longitude" name="longitude"
                            :placeholder="$t('label.destination_longitude')" id="longitude" :disabled="loading">
                        </md-input>
                        <slide-y-down-transition>
                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                        </slide-y-down-transition>
                        <span class="md-error">{{ errors[0] }}</span>
                    </validation-provider>
                </md-field>
            </div>
            <!-- Separator localizations -->
            <div class="md-layout-item md-size-100">
                <div class="Group-Header">
                    <span class="Luggage-information">
                        {{ $t('label.language_information') }}
                    </span>
                    <div class="Path"></div>
                </div>
            </div>
            <!-- Translations -->
            <md-card class="translations-card">
                <md-card-content>
                    <md-tabs class="translations-tabs">
                        <md-tab id="tab-tr" :md-label="$t('languages.tr')" :md-icon="getLanguageIcon('tr', errors)">
                            <!-- name tr -->
                            <div class="md-layout-item md-size-100">

                                <md-field :class="[
                                    { 'md-valid': errors['name_tr'] && !errors['name_tr'][0] && touchedFields.name_tr },
                                    { 'md-error': errors['name_tr'] && errors['name_tr'][0] }
                                ]">
                                    <label for="title">{{ $t('label.destination_name') }}</label>
                                    <validation-provider name='name_tr' rules="required" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-input v-model="item.name_tr" type="text"
                                            :placeholder="$t('label.destination_name')" :disabled="loading">
                                        </md-input>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                            <!-- subtitle tr -->
                            <div class="md-layout-item md-size-100">
                                <md-field :class="[
                                    { 'md-valid': errors['subtitle_tr'] && !errors['subtitle_tr'][0] && touchedFields.subtitle_tr },
                                    { 'md-error': errors['subtitle_tr'] && errors['subtitle_tr'][0] }
                                ]">
                                    <label for="title">{{ $t('fields.destination_subtitle') }}</label>
                                    <validation-provider name='subtitle_tr' rules="" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-input v-model="item.subtitle_tr" name="subtitle_tr" type="text"
                                            :placeholder="$t('label.destination_subtitle')" id="subtitle_tr"
                                            :disabled="loading"></md-input>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                            <!-- summary tr -->
                            <div class="md-layout-item md-size-100">
                                <md-field :class="[
                                    { 'md-valid': errors['summary_tr'] && !errors['summary_tr'][0] && touchedFields.summary_tr },
                                    { 'md-error': errors['summary_tr'] && errors['summary_tr'][0] }
                                ]">
                                    <label for="summary_tr">{{ $t('fields.destination_summary_tr') }}</label>
                                    <validation-provider name='summary_tr' rules="" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-textarea :md-autogrow="false" v-model="item.summary_tr" name="summary_tr"
                                            :placeholder="$t('label.destination_summary_tr')" :disabled="loading">
                                        </md-textarea>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                            <!-- text tr -->
                            <div class="md-layout-item md-size-100">
                                <md-field :class="[
                                    { 'md-valid': errors['destination_text_tr'] && !errors['destination_text_tr'][0] && touchedFields.destination_text_tr },
                                    { 'md-error': errors['destination_text_tr'] && errors['destination_text_tr'][0] }
                                ]">
                                    <label for="text">{{ $t('fields.destination_text_tr') }}</label>
                                    <validation-provider name='destination_text_tr' rules="required"
                                        v-slot="{ errors, touched }" mode="aggressive">
                                        <md-textarea :md-autogrow="false" f v-model="item.destination_text_tr"
                                            name="destination_text_tr" :placeholder="$t('label.destination_text')"
                                            :disabled="loading">
                                        </md-textarea>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                        </md-tab>
                        <md-tab id="tab-en" :md-label="$t('languages.en')" :md-icon="getLanguageIcon('en', errors)">
                            <!-- name en -->
                            <div class="md-layout-item md-size-100">
                                <md-field :class="[
                                    { 'md-valid': errors['name_en'] && !errors['name_en'][0] && touchedFields.name_en },
                                    { 'md-error': errors['name_en'] && errors['name_en'][0] }
                                ]">
                                    <label for="title">{{ $t('label.destination_name') }}</label>
                                    <validation-provider name='name_en' rules="required" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-input v-model="item.name_en" type="text"
                                            :placeholder="$t('label.destination_name')" :disabled="loading">
                                        </md-input>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                            <!-- subtitle en -->
                            <div class="md-layout-item md-size-100">
                                <md-field :class="[
                                    { 'md-valid': errors['subtitle_en'] && !errors['subtitle_en'][0] && touchedFields.subtitle_en },
                                    { 'md-error': errors['subtitle_en'] && errors['subtitle_en'][0] }
                                ]">
                                    <label for="title">{{ $t('fields.destination_subtitle') }}</label>
                                    <validation-provider name='subtitle_en' rules="" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-input v-model="item.subtitle_en" name="subtitle_en" type="text"
                                            :placeholder="$t('label.destination_subtitle')" id="subtitle_en"
                                            :disabled="loading"></md-input>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                            <!-- summary en -->
                            <div class="md-layout-item md-size-100">
                                <md-field :class="[
                                    { 'md-valid': errors['summary_en'] && !errors['summary_en'][0] && touchedFields.summary_en },
                                    { 'md-error': errors['summary_en'] && errors['summary_en'][0] }
                                ]">
                                    <label for="summary_en">{{ $t('fields.destination_summary_en') }}</label>
                                    <validation-provider name='summary_en' rules="" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-textarea :md-autogrow="false" v-model="item.summary_en" name="summary_en"
                                            :placeholder="$t('label.destination_summary_en')" :disabled="loading">
                                        </md-textarea>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                            <!-- text en -->
                            <div class="md-layout-item md-size-100">
                                <md-field :class="[
                                    { 'md-valid': errors['destination_text_en'] && !errors['destination_text_en'][0] && touchedFields.destination_text_en },
                                    { 'md-error': errors['destination_text_en'] && errors['destination_text_en'][0] }
                                ]">
                                    <label for="destination_text_en">{{ $t('fields.destination_text_en') }}</label>
                                    <validation-provider name='destination_text_en' rules="required"
                                        v-slot="{ errors, touched }" mode="aggressive">
                                        <md-textarea :md-autogrow="false" v-model="item.destination_text_en"
                                            name="destination_text_en" :placeholder="$t('label.destination_text')"
                                            :disabled="loading">
                                        </md-textarea>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                        </md-tab>
                    </md-tabs>
                </md-card-content>
            </md-card>
                <!-- Buttons -->
                <div class="cont">
                    <!-- gallery button -->
                    <md-button :disabled="loading == true || creating == true" class="md-primary md-round  btn-big"
                        @click="openGalleryModal()">
                        {{ $t("gallery") }}
                    </md-button>                  
                </div>
            <!-- Media Modal -->
            <media-modal :showProp="showMediaModal" @apply="applyMedia" @close="closeMediaModal()"></media-modal>
             <!-- Gallery Modal -->
             <gallery-modal  :showProp="showGalleryModal" :mode="'destination'" :parentId="item.destinationId" :parentName="item.title"  @close="closeGalleryModal()"></gallery-modal>
        </div>
    </ValidationObserver>
</template>

<script>

import MediaModal from "@/components/Modals/Media";
import GalleryModal from "@/components/Modals/Gallery";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import { getDestination, createDestination, updateDestination } from "@/services/destination";
import { getAirlinesWithoutPagination } from "@/services/airline";
import localeMixin from "@/mixins/locale";


export default {
    mixins: [localeMixin],
    components: {
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
        MediaModal,
        GalleryModal
    },
    data() {
        return {

            loading: false,
            creating: false,
            editing: false,
            imagesLightbox:[],
            touchedFields: {
                IATACode: false,
                bookingURL: false,
                latitude: false,
                longitude: false,
                name_tr: false,
                name_en: false,
                subtitle_tr: false,
                subtitle_en: false,
                destination_text_tr: false,
                destination_text_en: false,
                summary_tr: false,
                summary_en: false,
            },
            item: {
                destinationId: "",
                id: 0,
                IATACode: "",
                title: "",
                active: false,
                bookingURL: "",
                latitude: 0,
                longitude: 0,
                mediaId: 0,
                imageURL: "",
                fileName: "",
                imageAlt:"",
                media: {
                    fileName: "", imageURL: "",imageAlt:""
                },
                gallery:[],
                translationId_tr: 0,
                name_tr: "",
                subtitle_tr: "",
                destination_text_tr: "",
                summary_tr: "",
                translationId_en: 0,
                name_en: "",
                subtitle_en: "",
                destination_text_en: "",
                summary_en: "",
                days: [],
                airlines: [],

            },
            airlines: [],
            days: [],
            errorMediaMessage: "",
            showMediaModal: false,
            showGalleryModal: false,
        };
    },

    created: async function () {
        let destinationId = this.$route.params.destinationId;
        if (destinationId) {
            await this.editItem(destinationId);
        } else {
            this.newItem();
        }
    },

    async mounted() {
        this.fillWeekdaysArray();
    },

    methods: {

        viewImage(imageUrl) {
            this.imagesLightbox = [];
            this.$imageViewer.images(this.imagesLightbox);
            const image = {
                url: imageUrl,
            };
            this.imagesLightbox.push(image);
            this.$imageViewer.index(0);
            this.$imageViewer.show();
        },

        fillWeekdaysArray() {
            let days = [];
            
            days.push(JSON.stringify({ value: 1, text: this.$t('label.Monday') }));
            days.push(JSON.stringify({ value: 2, text: this.$t('label.Tuesday') }));
            days.push(JSON.stringify({ value: 3, text: this.$t('label.Wednesday') }));
            days.push(JSON.stringify({ value: 4, text: this.$t('label.Thursday') }));
            days.push(JSON.stringify({ value: 5, text: this.$t('label.Friday') }));
            days.push(JSON.stringify({ value: 6, text: this.$t('label.Saturday') }));
            days.push(JSON.stringify({ value: 7, text: this.$t('label.Sunday') }));
            this.days = days;

        },

        async fillAirlinesArray() {

            const responseAirlines = await getAirlinesWithoutPagination("");
            if (responseAirlines) {

                responseAirlines.map(item => {
                    let b = {
                        value: item.IATACode,
                        airlineId: item.airlineId,
                        text: item.name,
                    };
                    this.airlines.push(JSON.stringify(b));
                });
            } else {
                this.airlines = [];
            }
        },

        applyMedia(media) {
            this.item.media = media;
            this.item.mediaId = media.mediaId;
            this.item.imageURL = media.imageURL;
            this.item.fileName = media.fileName;
            this.item.imageAlt = media.imageAlt;
            this.errorMediaMessage = "";
        },

        clearMedia() {

            this.item.media = { fileName: "", imageURL: "",imageAlt:"" };
            this.item.mediaId = 0;
            this.item.imageURL = this.item.media.imageURL;
            this.item.fileName = this.item.media.fileName;
            this.item.imageAlt = this.item.media.imageAlt;
            this.errorMediaMessage = "";
        },

        openMediaModal() {
            this.showMediaModal = true;
        },

        closeMediaModal() {
            this.showMediaModal = false;
            if (this.item.mediaId == 0) {
                this.errorMediaMessage = this.$t('validation.required', { _field_: this.$t('label.airline_image') });
            }
        },

        openGalleryModal() {
            this.showGalleryModal = true;
        },

        closeGalleryModal() {
            this.showGalleryModal = false;          
        },

        async save() {

            this.errorMediaMessage = "";

            var valid = await this.$refs.form.validate();


            if (this.item.mediaId == 0) {
                valid = false;
                this.errorMediaMessage = this.$t('validation.required', { _field_: this.$t('label.destination_image') });
            }

            if (!valid) {
                this.$noty.error(this.$t('error.saved'));
            }


            if (valid) {
                this.loading = true;
                if (this.creating) {
                    let response = await createDestination(this.convertDataModel());
                    if (response.status == "201") {
                        this.$noty.success(this.$t('success.saved'));
                        this.$router.push({ name: this.$i18n.locale + '.destinations' });

                    } else {
                        if (response.status == "422") {
                            if (response.error) {
                                switch (response.error) {
                                    case "ERR00":
                                        var errorMessage = this.$t('error.ERR00', { _field_: this.$t('label.destination_IATACode'), _value_: response.value });
                                        this.$noty.error(errorMessage);
                                        break;
                                    default:
                                        this.$noty.error(this.$t("error.saved"));
                                }
                            } else {
                                this.$noty.error(this.$t("error.saved"));
                            }
                        } else {
                            this.$noty.error(this.$t("error.saved"));
                        }
                    }
                }
                if (this.editing) {
                    let response = await updateDestination(this.convertDataModel());
                    if (response.status == "200") {
                        this.$noty.success(this.$t('success.saved'));
                    } else {
                        if (response.status == "422") {
                            if (response.error) {
                                switch (response.error) {
                                    case "ERR00":
                                        var errorMessageUp = this.$t('error.ERR00', { _field_: this.$t('label.destination_IATACode'), _value_: response.value });
                                        this.$noty.error(errorMessageUp);
                                        break;
                                    default:
                                        this.$noty.error(this.$t("error.saved"));
                                }
                            } else {
                                this.$noty.error(this.$t("error.saved"));
                            }
                        } else {
                            this.$noty.error(this.$t("error.saved"));
                        }
                    }
                }
                this.loading = false;


            }

        },


        assignDataModel(item) {
            if (item.destinationId) {
                this.item.destinationId = item.destinationId;
            } else {
                this.item.destinationId = "";
            }

            if (item.id) {
                this.item.id = item.id;
            } else {
                this.item.id = 0;
            }


            if (item.title) {
                this.item.title = item.title;
            } else {
                this.item.title = "";
            }


            if (item.bookingURL) {
                this.item.bookingURL = item.bookingURL;
            } else {
                this.item.bookingURL = "";
            }

            if (item.IATACode) {
                this.item.IATACode = item.IATACode;
            } else {
                this.item.IATACode = "";
            }

            if (item.active) {
                this.item.active = Boolean(item.active);
            } else {
                this.item.active = false;
            }

            if (item.latitude) {
                this.item.latitude = item.latitude;
            } else {
                this.item.latitude = 0;
            }

            if (item.longitude) {
                this.item.longitude = item.longitude;
            } else {
                this.item.longitude = 0;
            }

            if (item.mediaId) {
                this.item.mediaId = item.mediaId;
            } else {
                this.item.mediaId = 0;
            }

            if (item.fileName) {
                this.item.media.fileName = item.fileName;
            } else {
                this.item.media.fileName = "";
            }

            if (item.imageURL) {
                this.item.media.imageURL = item.imageURL;
            } else {
                this.item.media.imageURL = "";
            }

            if (item.imageAlt) {
                this.item.media.imageAlt = item.imageAlt;
            } else {
                this.item.media.imageAlt = "";
            }

            

            if (item.airlines) {
                let airlines = [];
                item.airlines.map(item => {                    
                    var b = this.airlines.filter(t => JSON.parse(t).airlineId == item.airlineId)[0];
                    if (b){ airlines.push(b);}
                });
                this.item.airlines = airlines;

            } else {
                this.item.airlines = [];
            }

            if (item.days) {      
                
                let days = [];
                item.days.map(item => {                    
                    var b = this.days.filter(t => JSON.parse(t).value == item)[0];
                    if (b){days.push(b);}
                });
                this.item.days = days;

            } else {
                this.item.days = [];
            }

            if (item.gallery){
                this.item.gallery = item.gallery;
            }else{
                this.item.gallery = [];
            }


            var translation = item.translations.filter(t => t.locale == 'en')[0];

            if (translation) {


                if (translation.translationId) {
                    this.item.translationId_en = translation.translationId;
                } else {
                    this.item.translationId_en = 0;
                }

                if (translation.title) {
                    this.item.name_en = translation.title;
                } else {
                    this.item.name_en = "";
                }

                if (translation.subtitle) {
                    this.item.subtitle_en = translation.subtitle;
                } else {
                    this.item.subtitle_en = "";
                }

                if (translation.summary) {
                    this.item.summary_en = translation.summary;
                } else {
                    this.item.summary_en = "";
                }

                if (translation.text) {
                    this.item.destination_text_en = translation.text;
                } else {
                    this.item.destination_text_en = "";
                }




            } else {

                this.item.translationId_en = 0;
                this.item.name_en = "";
                this.item.subtitle_en = "";
                this.item.summary_en = "";
                this.item.destination_text_en = "";
            }


            translation = item.translations.filter(t => t.locale == 'tr')[0];

            if (translation) {


                if (translation.translationId) {
                    this.item.translationId_tr = translation.translationId;
                } else {
                    this.item.translationId_tr = 0;
                }

                if (translation.title) {
                    this.item.name_tr = translation.title;
                } else {
                    this.item.name_tr = "";
                }

                if (translation.subtitle) {
                    this.item.subtitle_tr = translation.subtitle;
                } else {
                    this.item.subtitle_tr = "";
                }

                if (translation.summary) {
                    this.item.summary_tr = translation.summary;
                } else {
                    this.item.summary_tr = "";
                }

                if (translation.text) {
                    this.item.destination_text_tr = translation.text;
                } else {
                    this.item.destination_text_tr = "";
                }




            } else {

                this.item.translationId_tr = 0;
                this.item.name_tr = "";
                this.item.subtitle_tr = "";
                this.item.summary_tr = "";
                this.item.destination_text_tr = "";
            }
        },


        convertDataModel() {

            var newItem = {};
            newItem.destinationId = this.item.destinationId;
            newItem.id = this.item.id;
            newItem.mediaId = this.item.mediaId;
            newItem.IATACode = this.item.IATACode;
            newItem.bookingURL = this.item.bookingURL;
            newItem.active = Number(this.item.active);
            newItem.latitude = Number(this.item.latitude);
            newItem.longitude = Number(this.item.longitude);
            newItem.airlines = [];
            newItem.airlines = this.item.airlines;
            newItem.airlines = [];
            this.item.airlines.map(item => {
                newItem.airlines.push(JSON.parse(item).value);
            });

            newItem.days = [];
            this.item.days.map(item => {
                newItem.days.push(JSON.parse(item).value);
            });

            newItem.translations = [];
            var trans_item_tr = {};
            trans_item_tr.translationId = this.item.translationId_tr;
            trans_item_tr.title = this.item.name_tr;
            trans_item_tr.subtitle = this.item.subtitle_tr;
            trans_item_tr.summary = this.item.summary_tr;
            trans_item_tr.text = this.item.destination_text_tr;
            trans_item_tr.locale = "tr";
            newItem.translations.push(trans_item_tr);
            var trans_item_en = {};
            trans_item_en.translationId = this.item.translationId_en;
            trans_item_en.title = this.item.name_en;
            trans_item_en.subtitle = this.item.subtitle_en;
            trans_item_en.summary = this.item.summary_en;
            trans_item_en.text = this.item.destination_text_en;
            trans_item_en.locale = "en";
            newItem.translations.push(trans_item_en);
            return newItem;

        },

        async newItem() {
            this.creating = true;
            this.editing = false;
            await this.fillAirlinesArray();
        },


        editItem(itemId) {
            this.creating = false;
            this.editing = true;
            this.fetchItem(itemId);
        },


        async fetchItem(itemId) {
            this.loading = true;
            let response = await getDestination(itemId);
            if (response) {
                await this.fillAirlinesArray();
                this.assignDataModel(response);
            }
            this.loading = false;
        }
    }
}
</script>

<style scoped>
.md-layout-item.centered {
    display: flex;
    justify-content: center;
}

.md-layout-item-padding {
    padding-left: 12px;
}


.media-container-destination {
    display: flex;
    float: left;
    width: 394px;
    height: 300px;
    max-width: 394px;
    max-height: 300px;
    margin-left: 38px;
    margin-bottom: 42px;
    min-height: 300px;
    background-color: var(--media-background);
    border: solid 0px var(--media-border);
}

.inlinefields {
    display: flex;
    padding: 0px;
    justify-content: space-between;
}

.gaplg {
    gap: 250px;
}

.gapsm {
    gap: 25px;
}

.media-container img {
    object-fit: fill;
    cursor:pointer;
}

</style>