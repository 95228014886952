<template>
    <div class="md-layout">
        <div class="md-layout-item md-size-100">
            <h3 class="page-title">
                {{ $t("routes.touristicRegions.title") }}
                <md-progress-spinner v-if="loading" :md-diameter="20" :md-stroke="1" md-mode="indeterminate">
                </md-progress-spinner>
            </h3>
        </div>

        <div class="md-layout-item md-size-100 inline-toolbar">
            <md-field class="search-input">
                <md-icon class="search-icon">image_search</md-icon>
                <label>{{ $t("label.search_touristicRegion") }}</label>
                <md-input v-model="search"  @keyup.enter="filter()" ></md-input>
                <md-button v-if="search" class="btn-floating" @click="clearFilter()">
                    <md-icon>close</md-icon>
                </md-button>
            </md-field>
            <md-button class="md-primary md-round btn-list" @click="filter()">
                {{ $t("search") }}
            </md-button>
            <router-link :to="{ name: $i18n.locale + '.touristicRegion_create' }" class="md-primary md-round btn-list" tag="md-button">
                {{ $t("new") }}
            </router-link>
        </div>
        <div class="md-layout-chips">
                <md-chips v-model="Filters" md-placeholder="" @md-delete="clearFilter()"></md-chips>
        </div>
        <div class="md-layout-item md-size-100">
            <md-table table-header-color="blue" v-model="Items" :md-sort-order.sync="currentSortOrder"
                :md-sort.sync="currentSort" :md-sort-fn="customSort">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-sort-by="title" :md-label="$t('label.touristicRegion_name')">
                        <span class="table-content-center">
                            {{ item.title }}
                        </span>
                    </md-table-cell>
                    <md-table-cell md-sort-by="enabled" :md-label="$t('label.touristicRegion_enabled')">
                        <img class="Icons-Enabled" src="@/assets/icons-tick.svg" v-show="item.enabled">
                        <img class="Icons-Enabled" src="@/assets/icons-cross.svg" v-show="!item.enabled">
                    </md-table-cell>
                    <md-table-cell class="table-cell-actions">
                        <router-link class="btn-icon"
                            :to="{ name: $i18n.locale + '.touristicRegion_edit', params: { touristicRegionId: item.touristicRegionId } }"
                            tag="button">
                            <md-icon>edit</md-icon>
                        </router-link>
                        <button class="btn-icon" @click="confirmDelete(item)">
                            <md-icon>delete</md-icon>
                        </button>
                    </md-table-cell>
                </md-table-row>
            </md-table>
            <!-- Pagination -->
            <div class="pagination-container">
                <md-pagination v-model="page" :noArrows="false" :perPage="limit" :total="total" />
            </div>
        </div>
        <md-modal v-if="showConfirm" @close="showConfirm = false">
            <h3 slot="header">
                {{ $t("confirm") }}
            </h3>
            <div slot="body">
                {{ $t("confirm_messages.delete") }}
            </div>
            <div slot="footer" class="modal-footer">
                <md-button @click="showConfirm = false" class="md-danger md-round">
                    {{ $t("cancel") }}
                </md-button>
                <md-button @click="deleteItem()" class="md-primary md-round">
                    {{ $t("delete") }}
                </md-button>
            </div>
        </md-modal>
    </div>
</template>

<script>
import { 
    getTouristicRegions, 
    deleteTouristicRegion, 
} from "@/services/touristic_region";
import paginationMixin from "@/mixins/pagination";

export default {
    mixins: [paginationMixin],
    data() {
        return {
            loading: false,
            showConfirm: false,
            search: "",
            currentSort: 'title',
            currentSortOrder: 'asc',
            sort: "title,asc",
            Items: [],
            Filters:[],
            ItemIdToDelete: null,
        };
    },

    //0013234
    watch: {
        page() {
            this.fetchItems();
        },
    },

    methods: {
        async fetchItems() {
            this.loading = true;
            this.sort = this.currentSort + "," + this.currentSortOrder;
            if (this.search) {
                this.Filters = [];
                this.Filters.push(this.search);
            }else{
                this.Filters = [];
                this.search = "";
            }
            const response = await getTouristicRegions(this.page, this.limit, this.search, this.sort);
            if (response) {
                this.Items = response.result;
                this.total = response.pageInfo.totalPages * this.limit;
            } else {
                this.Items = [];
                this.total = 0;
            }
            this.loading = false;
        },

        filter() {
            this.fetchItems();
        },

        clearFilter() {
            this.search = "";
            this.Filters = [];
            this.fetchItems();
        },

        confirmDelete(Item) {
            this.ItemIdToDelete = Item.touristicRegionId;
            this.showConfirm = true;
        },

        async deleteItem() {
            this.loading = true;
            let response = await deleteTouristicRegion(this.ItemIdToDelete);
            if (response.status == "200") {
                this.showConfirm = false;
                this.ItemIdToDelete = null;
                this.$noty.success(this.$t('success.deleted'));
                this.fetchItems();
            } else {
                this.$noty.error(this.$t('error.deleted'));
            }
            this.loading = false;
        },


        customSort: function customSort(value) {
            this.fetchItems();
            return value;
        },
    }
}
</script>

<style>

</style>