<template>
    <div>
        <md-dialog :md-close-on-esc="false" :md-click-outside-to-close="false" :md-active.sync="show"
            class="modal-category">
            <md-dialog-title class="modal-category-header">
                <div class="md-layout-item md-size-100 form-toolbar">
                    <h3 v-if="parentName == ''">{{ $t("categories") }}</h3>
                    <h3 v-if="parentName != ''">{{ this.parentName }} - {{ $t("categories") }}</h3>
                    <md-progress-spinner class="spinner-load" v-if="loading" :md-diameter="30" :md-stroke="3"
                        md-mode="indeterminate">
                    </md-progress-spinner>
                </div>
                <md-button @click="closeModal()" class="
                                        md-primary md-just-icon md-round md-simple
                                        modal-close-btn
                                    ">
                    <md-icon class="md-size-2x">close</md-icon>
                </md-button>
                <div class=" md-layout-item md-size-100 warning-message">
                    {{ $t("messages.warning_pricing") }}
                </div>
            </md-dialog-title>
            <md-dialog-content>
                <!-- Ticket List -->
                <div class="content-div" v-if="!creating && !editing">
                    <div class="inline-toolbar">
                        <md-field class="search-input">
                            <md-icon class="search-icon">image_search</md-icon>
                            <label>{{ $t("label.search_ticketname") }}</label>
                            <md-input v-model="search" @keyup.enter="filter()"></md-input>
                            <md-button v-if="search" class="btn-floating" @click="clearFilter()">
                                <md-icon>close</md-icon>
                            </md-button>
                        </md-field>
                        <md-button class="md-primary md-round" @click="filter()">
                            {{ $t("search") }}
                        </md-button>
                        <md-button @click="newItem()" class="md-primary md-round">
                            {{ $t("new") }}
                        </md-button>
                    </div>
                    <md-table table-header-color="blue" v-model="ticketList" :md-sort-order.sync="currentSortOrder"
                        :md-sort.sync="currentSort" :md-sort-fn="customSort">
                        <md-table-row slot="md-table-row" slot-scope="{ item }" v-model="ticketList">
                            <md-table-cell md-sort-by="order" :md-label="$t('label.ticket_order')">{{ item.order
                            }}</md-table-cell>
                            <md-table-cell md-sort-by="name" :md-label="$t('label.ticket_name')">{{ item.name
                            }}</md-table-cell>
                            <md-table-cell md-sort-by="active" :md-label="$t('label.ticket_enabled')">
                                <img class="Icons-Enabled" src="@/assets/icons-tick.svg" v-show="item.active">
                                <img class="Icons-Enabled" src="@/assets/icons-cross.svg" v-show="!item.active">
                            </md-table-cell>
                            <md-table-cell class="table-cell-actions">
                                <button class="btn-icon" @click="editItem(item.ticketId)">
                                    <md-icon>edit</md-icon>
                                </button>
                                <button class="btn-icon" @click="confirmDelete(item)">
                                    <md-icon>delete</md-icon>
                                </button>
                            </md-table-cell>
                        </md-table-row>
                    </md-table>
                    <div class="pagination-container">
                        <md-pagination v-model="page" :noArrows="false" :perPage="limit" :total="total" />
                    </div>
                </div>
                <!-- Ticket form -->
                <div v-if="creating || editing">
                    <ValidationObserver tag="form" ref="form" v-slot="{ errors }">
                        <div class="md-layout form-layout">
                            <div class="md-layout-item md-size-40">
                                <md-field>
                                    <md-select v-model="item.order" :disabled="loading"
                                        :placeholder="$t('label.ticket_order')">
                                        <md-option v-for="(order, index) in orders" :key="index" :value="order.value">
                                            {{ order.text }}
                                        </md-option>
                                    </md-select>
                                    <span class="md-error">
                                        {{ errorOrderMessage }}
                                    </span>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-size-20">
                            </div>
                            <div class="md-layout-item md-size-40">
                                <!-- Active -->
                                <div class="field-switch">
                                    <Span>{{ $t("label.ticket_enabled") }}</Span>
                                    <md-switch id="switchTicketEnabled" v-model="item.active">
                                    </md-switch>
                                </div>
                            </div>
                            <div class="md-layout-item md-size-40">
                                <md-field :class="[
                                    { 'md-valid': errors['price'] && !errors['price'][0] && touchedFields.price },
                                    { 'md-error': errors['price'] && errors['price'][0] }
                                ]">
                                    <label for="price">{{ $t('fields.price') }} ({{ defaultCurrencySymbol }})</label>
                                    <validation-provider name='price' rules="double" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-input @blur="item.price = formatNumber(item.price)" v-model="item.price" name="price" type="number"
                                            :placeholder="$t('label.ticket_price')" id="price" :disabled="loading">
                                        </md-input>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-size-20">
                            </div>
                            <!-- minQuantity -->
                            <div class="md-layout-item md-size-15">
                                <md-field :class="[
                                    { 'md-valid': errors['minQuantity'] && !errors['minQuantity'][0] && touchedFields.maxQuantity },
                                    { 'md-error': errors['minQuantity'] && errors['minQuantity'][0] }
                                ]">
                                    <label for="minQuantity">{{ $t('fields.minQuantity') }}</label>
                                    <validation-provider name='minQuantity' rules="required" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-input v-model="item.minQuantity" name="minQuantity" type="number"
                                            :placeholder="$t('label.ticket_minQuantity')" min="0" id="minQuantity"
                                            :disabled="loading">
                                        </md-input>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched" style="margin-right: 20px;margin-top: 3px;">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-size-5">
                            </div>
                            <!-- maxQuantity -->
                            <div class="md-layout-item md-size-15">
                                <md-field :class="[
                                    { 'md-valid': errors['maxQuantity'] && !errors['maxQuantity'][0] && touchedFields.maxQuantity },
                                    { 'md-error': errors['maxQuantity'] && errors['maxQuantity'][0] }
                                ]">
                                    <label for="maxQuantity">{{ $t('fields.maxQuantity') }}</label>
                                    <validation-provider name='maxQuantity' rules="required" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-input v-model="item.maxQuantity" name="maxQuantity"  type="number" min="0"
                                            :placeholder="$t('label.ticket_maxQuantity')" id="maxQuantity"
                                            :disabled="loading">
                                        </md-input>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched" style="margin-right: 20px;margin-top: 3px;">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-size-100">
                                <!-- Separator localizations -->
                                <div class="Group-Header">
                                    <span class="Luggage-information">
                                        {{ $t('label.language_information') }}
                                    </span>
                                    <div class="Path"></div>
                                </div>
                                <!-- Translations -->
                                <md-card class="translations-card">
                                    <md-card-content>
                                        <md-tabs class="translations-tabs">
                                            <md-tab id="tab-tr" :md-label="$t('languages.tr')"
                                                :md-icon="getLanguageIcon('tr', errors)">
                                                <div class="inlinefields gapsm">
                                                    <!-- name tr -->
                                                    <md-field :class="[
                                                        { 'md-valid': errors['name_tr'] && !errors['name_tr'][0] && touchedFields.name_tr },
                                                        { 'md-error': errors['name_tr'] && errors['name_tr'][0] }
                                                    ]">
                                                        <label for="name_tr">{{
                                                            $t("label.ticket_name")
                                                        }}</label>
                                                        <validation-provider name="name_tr" rules="required"
                                                            v-slot="{ errors, touched }" mode="aggressive">
                                                            <md-input v-model="item.name_tr" name="name_tr" type="text"
                                                                id="name_tr" :disabled="loading || view">
                                                            </md-input>
                                                            <slide-y-down-transition>
                                                                <md-icon class="error" v-show="errors[0] && touched">close
                                                                </md-icon>
                                                            </slide-y-down-transition>
                                                            <span class="md-error">{{ errors[0] }}</span>
                                                        </validation-provider>
                                                    </md-field>
                                                </div>
                                            </md-tab>
                                            <md-tab id="tab-en" :md-label="$t('languages.en')"
                                                :md-icon="getLanguageIcon('en', errors)">
                                                <div class="inlinefields gapsm">
                                                    <!-- name en -->
                                                    <md-field :class="[
                                                        { 'md-valid': errors['name_en'] && !errors['name_en'][0] && touchedFields.name_en },
                                                        { 'md-error': errors['name_en'] && errors['name_en'][0] }
                                                    ]">
                                                        <label for="name_en">{{
                                                            $t("label.ticket_name")
                                                        }}</label>
                                                        <validation-provider name="name_en" rules="required"
                                                            v-slot="{ errors, touched }" mode="aggressive">
                                                            <md-input v-model="item.name_en" name="name_en" type="text"
                                                                id="name_en" :disabled="loading || view">
                                                            </md-input>
                                                            <slide-y-down-transition>
                                                                <md-icon class="error" v-show="errors[0] && touched">close
                                                                </md-icon>
                                                            </slide-y-down-transition>
                                                            <span class="md-error">{{ errors[0] }}</span>
                                                        </validation-provider>
                                                    </md-field>
                                                </div>
                                            </md-tab>
                                        </md-tabs>
                                    </md-card-content>
                                </md-card>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <md-button @click.prevent="cancel()" class="md-danger md-round" :disabled="loading">
                                {{ $t("cancel") }}
                            </md-button>
                            <md-button v-if="!view" @click.prevent="save()" type="submit" class="md-primary md-round"
                                :disabled="loading">
                                {{ $t("save") }}
                            </md-button>
                        </div>
                    </ValidationObserver>
                </div>
            </md-dialog-content>
        </md-dialog>
        <!-- Modal Confirm Delete -->
        <md-modal v-if="showConfirm" @close="showConfirm = false">
            <h3 slot="header">
                {{ $t("confirm") }}
            </h3>
            <div slot="body">
                {{ $t("confirm_messages.delete") }}
            </div>
            <div slot="footer" class="modal-footer">
                <md-button @click="showConfirm = false" class="md-danger md-round">
                    {{ $t("cancel") }}
                </md-button>
                <md-button @click="deleteItem()" class="md-primary md-round">
                    {{ $t("delete") }}
                </md-button>
            </div>
        </md-modal>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import {
    getAncillaryTickets,
    getAncillaryTicket,
    createAncillaryTicket,
    updateAncillaryTicket,
    deleteAncillaryTicket
} from "@/services/tickets.js";
import paginationMixin from "@/mixins/pagination";
import localeMixin from "@/mixins/locale";
import { CURRENCY_SYMBOLS, DEFAULT_CURRENCY } from "@/config";


export default {
    props: ['showProp', 'parentIdProp', 'parentNameProp'],
    mixins: [paginationMixin, localeMixin],
    components: {
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
    },
    data() {
        return {
            loading: false,
            show: false,
            showConfirm: false,
            imagesLightbox: [],
            lastOrder:0,
            mode: null,
            parentId: "",
            parentName: "",
            touchedFields: {
                name_tr: false,
                name_en: false,
                price: false,
                minQuantity: false,
                maxQuantity: false
            },
            ticketList: [],
            item: {
                ticketId: "",
                name: "",
                price: 0,
                minQuantity: 0,
                maxQuantity: 0,
                order: 0,
                active: false,
                translationId_en: 0,
                translationId_tr: 0,
                name_tr: "",
                name_en: ""
            },
            view: false,
            creating: false,
            editing: false,
            search: "",
            ItemIdToDelete: null,
            orders: [],
            errorOrderMessage: "",
            currentSort: 'order',
            currentSortOrder: 'asc',
            sort: "order,asc",
            defaultCurrencySymbol: CURRENCY_SYMBOLS[DEFAULT_CURRENCY],
        };


    },

    created: async function () {
    },


    watch: {
        page() {
            this.fetchItems();
        },

        showProp() {
            this.show = this.showProp;
            this.parentName = this.parentNameProp;
            this.parentId = this.parentIdProp;
            this.fillOrdersArray();
            if (this.show) {
                this.limit = 5;
                this.clearFilter();
                this.clearData();
                this.editing = false;
                this.creating = false;
                this.view = true;
                this.fetchItems();
            }
        },
        // 'item.price': function(newPrice) {
        //     this.item.price = this.formatNumber(newPrice);
        // },
    },


    methods: {


        fillOrdersArray() {
            let min = 0;
            let max = 50;
            this.orders = [];
            for (let i = min; i <= max; i++) {
                if (i == 0) {
                    let z = { value: i, text: this.$t('label.order_default') };
                    this.orders.push(z);
                } else {
                    let b = { value: i, text: i.toString() };
                    this.orders.push(b);
                }
            }
        },

        async fetchItems() {
            this.loading = true;
            let response = null;
            this.sort = this.currentSort + "," + this.currentSortOrder;
            response = await getAncillaryTickets(this.parentId, this.page, this.limit, this.search,this.sort);
            if (response) {
                this.ticketList = response.result;
                this.total = response.pageInfo.totalPages * this.limit;
                this.lastOrder = response.lastOrder;
            } else {
                this.ticketList = [];
                this.total = 0;
                this.lastOrder = response.lastOrder;
            }
            this.loading = false;
        },

        async fetchItem(itemId) {
            this.loading = true;
            let response = null;
            response = await getAncillaryTicket(this.parentId, itemId, this.page, this.limit, this.search);
            if (response) {
                this.assignDataModel(response);
            }
            this.loading = false;
        },


        async filter() {
            this.fetchItems();
        },

        clearFilter() {
            this.search = "";
            this.fetchItems();
        },

        newItem() {
            this.clearData();
            this.item.order = this.lastOrder;
            this.creating = true;
            this.editing = false;
            this.view = false;
        },

        editItem(itemId) {
            this.creating = false;
            this.clearData();
            this.editing = true;
            this.view = false;
            this.fetchItem(itemId);
        },


        cancel() {
            this.view = false;
            this.editing = false;
            this.creating = false;
        },

        closeModal() {
            this.show = false;
            this.$emit("close");
        },

        clearData() {

            this.item.ticketId = "";
            this.item.price = 0;
            this.item.minQuantity = 1;
            this.item.maxQuantity = 1;
            this.item.translationId_en = 0;
            this.item.translationId_tr = 0;
            this.item.name_tr = "";
            this.item.name_en = "";
        },

        customSort: function customSort(value) {
            this.fetchItems();
            return value;
        },

        async save() {

            var valid = await this.$refs.form.validate();
            if (!valid) {
                this.$noty.error(this.$t('error.saved'));
            }

            if (this.item.minQuantity>this.item.maxQuantity){
                valid = false;
                this.$noty.error(this.$t('validation.quantity_rel'));
            }

            if (this.item.minQuantity == 0 && this.item.maxQuantity == 0){
                valid = false;
                this.$noty.error(this.$t('validation.both_quantity_0'));
            }

            if (valid) {
                this.loading = true;
                if (this.creating) {
                    let response = null;
                    response = await createAncillaryTicket(this.parentId, this.convertDataModel());
                    if (response.status == "201") {
                        this.$noty.success(this.$t('success.saved'));
                        this.cancel();
                        await this.fetchItems();
                    } else {
                        this.$noty.error(this.$t("error.saved"));
                    }
                }
                if (this.editing) {
                    let response = null;
                    response = await updateAncillaryTicket(this.parentId, this.convertDataModel());
                    if (response.status == "200") {
                        this.$noty.success(this.$t('success.saved'));
                        this.cancel();
                        await this.fetchItems();
                    } else {
                        this.$noty.error(this.$t("error.saved"));
                    }

                }
            }
            this.loading = false;
        },


        confirmDelete(Item) {
            this.ItemIdToDelete = Item.ticketId;
            this.showConfirm = true;
        },

        async deleteItem() {
            this.loading = true;
            let response = null;
            response = await deleteAncillaryTicket(this.parentId, this.ItemIdToDelete);
            if (response) {
                this.$noty.success(this.$t("success.item_deleted"));
                this.showConfirm = false;
                this.ItemIdToDelete = "";
                this.fetchItems();
            }
            this.loading = false;
        },

        assignDataModel(item) {

            if (item.ticketId) {
                this.item.ticketId = item.ticketId;
            } else {
                this.item.ticketId = "";
            }

            if (item.order) {
                this.item.order = item.order;
            } else {
                this.item.order = 0;
            }

            if (item.active) {
                this.item.active = Boolean(item.active);
            } else {
                this.item.active = false;
            }

            if (item.price) {
                this.item.price = this.formatNumber(item.price);
            } else {
                this.item.price = this.formatNumber(0);
            }

            if (item.minQuantity) {
                this.item.minQuantity = item.minQuantity;
            } else {
                this.item.minQuantity = 0;
            }

            if (item.maxQuantity) {
                this.item.maxQuantity = item.maxQuantity;
            } else {
                this.item.maxQuantity = 0;
            }

            var translation = item.translations.filter(t => t.locale == 'en')[0];

            if (translation) {

                if (translation.translationId) {
                    this.item.translationId_en = translation.translationId;
                } else {
                    this.item.translationId_en = 0;
                }

                if (translation.name) {
                    this.item.name_en = translation.name;
                } else {
                    this.item.name_en = "";
                }

            } else {

                this.item.translationId_en = 0;
                this.item.name_en = "";


            }


            translation = item.translations.filter(t => t.locale == 'tr')[0];

            if (translation) {


                if (translation.translationId) {
                    this.item.translationId_tr = translation.translationId;
                } else {
                    this.item.translationId_tr = 0;
                }
                if (translation.name) {
                    this.item.name_tr = translation.name;
                } else {
                    this.item.name_tr = "";
                }

            } else {

                this.item.translationId_tr = 0;
                this.item.name_tr = "";

            }

        },

        convertDataModel() {

            var newItem = {};
            newItem.ticketId = this.item.ticketId;
            newItem.order = this.item.order;
            newItem.price = this.item.price;
            newItem.minQuantity = this.item.minQuantity;
            newItem.maxQuantity = this.item.maxQuantity;
            newItem.active = Number(this.item.active);
            newItem.translations = [];
            var trans_item_tr = {};
            trans_item_tr.translationId = this.item.translationId_tr;
            trans_item_tr.name = this.item.name_tr;
            trans_item_tr.locale = "tr";
            newItem.translations.push(trans_item_tr);
            var trans_item_en = {};
            trans_item_en.translationId = this.item.translationId_en;
            trans_item_en.name = this.item.name_en;
            trans_item_en.locale = "en";
            newItem.translations.push(trans_item_en);
            console.log("convertDataModel item", newItem);
            return newItem;

        },

        formatNumber (num) {
            return parseFloat(num).toFixed(2)
        },
    }

};
</script>

<style scope>
.modal-category-header {
    width: 100%;
    position: relative;
}

.spinner-load {
    margin-left: 12px;
    margin-top: 12px;
}

.modal-category-header .modal-close-btn {
    position: absolute;
    top: 0;
    right: 0;
}

.modal-category .modal-container {
    max-width: 1000px !important;
}

.category-table .md-table-cell:not(.table-cell-actions) {
    text-align: left;
}

.category-checkbox .md-checkbox {
    margin: 0;
}

.form-text-info {
    text-align: left;
    font-weight: normal;
}

.gapsm {
    gap: 25px;
}

.image-container {
    display: flex;
    float: left;
    justify-content: center;
    padding: 0px;
    width: 400px;
    max-width: 400px;
    max-height: 300px;
    min-height: 300px;
    margin-left: 40px;
    margin-bottom: 48px;
    background-color: var(--media-background);
    border: solid 1px var(--media-border);
}

.image-container img {
    width: 400px;
    height: 300px;
    object-fit: fill;
    cursor: pointer;
}


.inlinefields {
    display: flex;
    padding: 0px;
    justify-content: space-between;
}

.gaplg {
    gap: 250px;
}

.gapsm {
    gap: 140px;
}
</style>