import { Storage } from "aws-amplify";

class MyUploadAdapter {
    constructor(loader) {
        // The file loader instance to use during the upload.
        this.loader = loader;
    }

    // Starts the upload process.
    upload() {
        return this.loader.file.then(file => {
            return uploadImage(file)
                .then(obj => {
                    return getS3Url(obj.key)
                        .then(url => {
                            var response = {};
                            response.urls = {};
                            response.urls.default = url;
                            return response;
                        })
                        .catch(error => console.error("Error getting S3 URL:", error));

                })
                .catch(error => {
                    console.error("Upload failed:", error);
                    throw error;
                });
        });
    }

    // Aborts the upload process.
    abort() {

    }
}

async function getS3Url(key) {
    const S3URL = process.env.VUE_APP_S3_URL;
    return Promise.resolve(S3URL + key);
}

function uploadImage(file) {
    return new Promise((resolve, reject) => {
        const filename = file.name;
        Storage.put(filename, file, { contentType: file.type })
            .then(result => resolve(result))
            .catch(error => reject(error));
    });
}

function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        // Configure the URL to the upload script in your back-end here!
        return new MyUploadAdapter(loader);
    };
}

export default MyCustomUploadAdapterPlugin;