<template>
    <div class="md-layout">
        <div class="md-layout-item md-size-100">
            <h3 class="page-title">
                {{ $t("routes.prices.title") }} - {{ name }}
                <md-progress-spinner v-if="loading" :md-diameter="20" :md-stroke="1" md-mode="indeterminate">
                </md-progress-spinner>
            </h3>
        </div>
        <div class="md-layout-item md-size-100 inline-toolbar">
            <md-field class="search-input">
                <md-icon class="search-icon">image_search</md-icon>
                <label>{{ $t("label.search_ancillary") }}</label>
                <md-input v-model="search" @keyup.enter="filter()"></md-input>
                <md-button v-if="search" class="btn-floating" @click="clearFilter()">
                    <md-icon>close</md-icon>
                </md-button>
            </md-field>
            <md-button class="md-primary md-round btn-big" @click="filter()">
                {{ $t("search") }}
            </md-button>
            <md-button class="md-primary md-round btn-big" @click="save()">
                {{ $t("save") }}
            </md-button>
        </div>
        <div class="md-layout-chips">
            <md-chips v-model="Filters" md-placeholder="" @md-delete="clearFilter()"></md-chips>
        </div>
        <div class="md-layout-item md-size-100">
            <custom-horizontal-list :items="Items" :selectedItemIndex="selectedItemIndex" @select="onSelect">
                <template slot-scope="{ item }">
                    <div class="custom-item-template">{{ item.name }}</div>
                </template>
            </custom-horizontal-list>
            <div>
                <table v-show="selectedItem" v-if="data.length > 1">
                    <thead>
                        <tr>
                            <th style="width: 100px;"></th>
                            <th class="Header">{{ $t("label.pricing_enabled") }} </th>
                            <th class="Header">{{ $t("label.minQuantity") }} </th>
                            <th class="Header">{{ $t("label.maxQuantity") }} </th>
                            <th class="Header">{{ $t("label.price") }} </th>
                            <th class="Header">{{ $t("label.discount") }} </th>
                        </tr>
                        <tr style="height: 32px;"></tr>
                    </thead>
                    <tbody>
                        <tr v-for="e in this.data" :key="e.id">
                            <td :class="{
                                'Title': e.type === 0,
                                'Ticket': e.type === 0,
                                'Extra': e.type === 2,
                                'Variant': e.type === 3
                            }">
                                {{ e.name }}

                            </td>
                            <td>
                                <md-switch v-if="e.type === 1 || e.type === 3" id="switchEnabled" v-model="e.enabled"
                                    :disabled="loading"></md-switch>
                            </td>
                            <td>
                                <md-field v-if="e.type === 1">
                                    <md-input v-model="e.minQuantity" name="minquantity" type="number" min="0"
                                        :placeholder="$t('label.minQuantity')" id="minquantity" :disabled="loading"
                                        @change="setDefault(e)">
                                    </md-input>
                                    <span class="md-error" style="width: 350px;margin-left: -24px;">
                                        {{ e.errorMsg }}
                                    </span>
                                </md-field>
                            </td>
                            <td>
                                <md-field v-if="e.type === 1 || e.type === 3">
                                    <md-input v-model="e.maxQuantity" name="maxquantity" type="number" min="0"
                                        :placeholder="$t('label.maxQuantity')" id="maxquantity" :disabled="loading"
                                        @change="setDefault(e)">
                                    </md-input>
                                </md-field>
                            </td>
                            <td>
                                <md-field v-if="e.type === 1 || e.type === 3">
                                    <md-input v-model="e.price" name="price" type="number" step="0.01"
                                        :placeholder="$t('label.price')" id="price" :disabled="loading"
                                        @change="setDefault(e)">
                                    </md-input>
                                    {{ currencyDisplay }}
                                </md-field>
                            </td>
                            <td>
                                <md-field v-if="e.type === 1 || e.type === 3">
                                    <md-input v-model="e.discount" name="discount" type="number" min="0" max="100"
                                        :placeholder="$t('label.discount')" id="discount" :disabled="loading"
                                        @change="setDefault(e)">
                                    </md-input>
                                </md-field>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { getAncillaries, getPricing, savePricing } from "@/services/ancillary";
import paginationMixin from "@/mixins/pagination";
import CustomHorizontalList from "@/components/CustomHorizontalList";
import { getClient } from "@/services/client";


export default {
    components: {
        CustomHorizontalList,
    },
    mixins: [paginationMixin],
    data() {
        return {
            clientId: 0,
            name: "",
            currencyDisplay: "",
            Items: [],
            Filters: [],
            lastOrder: 0,
            search: "",
            loading: false,
            showConfirm: false,
            ItemIdToDelete: "",
            currentSort: 'order',
            currentSortOrder: 'asc',
            sort: "order,asc",
            selectedItemIndex: -1,
            selectedItem: null,
            data: []
        };
    },

    watch: {
        page() {
            this.fetchItems();
        },
    },

    created: async function () {
        let clientId = this.$route.params.clientId;
        this.clientId = clientId;
        await this.fetchItems();
        await this.fetchItem(this.clientId);
        await this.onSelect(0);
    },

    methods: {


        async fetchItem(itemId) {
            this.loading = true;
            let response = await getClient(itemId);
            if (response) {
                this.name = response.name;
                this.currencyDisplay = response.currencyAlphabeticCode;
            }
            this.loading = false;
        },

        async onSelect(index) {
            this.loading = true;
            this.selectedItemIndex = index;
            this.selectedItem = await getPricing(this.Items[index].ancillaryId, this.clientId);
            this.convertData();
            this.loading = false;
        },

        convertData() {


            if (!this.selectedItem.tickets || this.selectedItem.tickets.length == 0) {
                this.data = [];
                return;
            }

            this.data = [];
            var id = 1;

            var datarow = {};
            datarow.type = 0;
            datarow.name = this.$t('label.tickets');
            datarow.enabled = 0;
            datarow.minQuantity = 1;
            datarow.maxQuantity = 1;
            datarow.price = this.formatNumber(0);
            datarow.discount = 0;
            datarow.id = id;
            id++;
            this.data.push(datarow);



            this.selectedItem.tickets.map(item => {
                var datarow = {};
                datarow.type = 1;
                datarow.name = item.name;
                datarow.enabled = item.enabled == 1 ? true : false;
                datarow.minQuantity = item.minQuantity;
                datarow.maxQuantity = item.maxQuantity;
                datarow.price = this.formatNumber(item.price);
                datarow.discount = item.discount;
                datarow.priceId = item.priceId;
                datarow.clientId = item.clientId;
                datarow.ancillaryId = item.ancillaryId;
                datarow.ticketId = item.ticketId;
                datarow.id = id;
                id++;
                this.data.push(datarow);
            });


            datarow = {};
            datarow.type = 0;
            datarow.name = this.$t('label.extras');
            datarow.enabled = 0;
            datarow.maxQuantity = 1;
            datarow.price = 0.0;
            datarow.discount = 0;
            datarow.id = id;
            id++;
            this.data.push(datarow);

            this.selectedItem.extras.map(item => {
                var datarow = {};
                datarow.type = 2;
                datarow.name = item.name;
                datarow.enabled = 0;
                datarow.maxQuantity = 0;
                datarow.price = 0.0;
                datarow.discount = 0;
                datarow.id = id;
                id++;
                this.data.push(datarow);
                item.variants.map(item => {
                    var datarow = {};
                    datarow.type = 3;
                    datarow.name = item.name;
                    datarow.enabled = item.enabled == 1 ? true : false;
                    datarow.maxQuantity = item.maxQuantity;
                    datarow.price = this.formatNumber(item.price);
                    datarow.discount = item.discount;
                    datarow.priceId = item.priceId;
                    datarow.id = id;
                    datarow.clientId = item.clientId;
                    datarow.ancillaryId = item.ancillaryId;
                    datarow.extraId = item.extraId;
                    datarow.variantId = item.variantId;
                    datarow.id = id;
                    id++;
                    this.data.push(datarow);
                });
            });
        },

        async fetchItems() {
            this.loading = true;
            this.selectedItem = null;
            this.selectedItemIndex = -1;
            this.sort = this.currentSort + "," + this.currentSortOrder;
            if (this.search) {
                this.Filters = [];
                this.Filters.push(this.search);
            } else {
                this.Filters = [];
                this.search = "";
            }
            const response = await getAncillaries(this.page, this.limit, this.search, this.sort);
            if (response) {
                this.Items = response.result;
                this.lastOrder = response.lastOrder;
                this.total = response.pageInfo.totalPages * this.limit;
            } else {
                this.Items = [];
                this.total = 0;
                this.lastOrder = 1;
            }
            this.loading = false;
        },

        filter() {
            this.fetchItems();
        },

        clearFilter() {
            this.search = "";
            this.Filters = [];
            this.fetchItems();
        },

        setDefault(e) {
            if (!e.discount)
                e.discount = 0;
            if (!e.minQuantity)
                e.minQuantity = 1;
            if (!e.maxQuantity)
                e.maxQuantity = 1;
            if (!e.price)
                e.price = this.formatNumber(0);
        },

        clearErrors(item){
            item.ticketPrices.forEach((ticketPrice) => {
                ticketPrice.errorMsg = "";
            });
        },

        async save() {
            this.loading = true;
            
            var item = {};
            item.ticketPrices = this.data.filter(f => f.type == 1);
            item.variantPrices = this.data.filter(f => f.type == 3);
            this.clearErrors(item);

            let valid = true; // Variable para almacenar si hay errores

            item.ticketPrices.forEach((ticketPrice) => {
                const minQuantity = ticketPrice.minQuantity;
                const maxQuantity = ticketPrice.maxQuantity;

                if (minQuantity == 0 && maxQuantity == 0) {
                    valid = false;
                    ticketPrice.errorMsg = this.$t('validation.both_quantity_0');
                } else if (minQuantity > maxQuantity) {
                    valid = false;
                    ticketPrice.errorMsg = this.$t('validation.quantity_rel');
                }

            });

            if (!valid) {
                this.loading = false;
            } else {
                const response = await savePricing(this.selectedItem.ancillaryId, this.clientId, item);
                if (response) {
                    this.$noty.success(this.$t("success.saved"));
                    await this.onSelect(this.selectedItemIndex);
                } else {
                    this.$noty.error(this.$t("error.saved"));
                }

                this.loading = false;

            }
        },

        formatNumber(num) {
            return parseFloat(num).toFixed(2)
        },

    }
}
</script>

<style scoped>
.Title {
    font-family: Lato;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0b1d4c;
    text-align: left;
}

.Header {
    font-family: Lato;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0b1d4c;
    text-align: left;

}

.Ticket {
    text-align: left;
    font-family: Lato;
    font-size: 16px;
    color: #0b1d4c;
}


.Extra {
    text-align: left;
    padding-top: 32px;
    padding-bottom: 12px;
    font-family: Lato;
    font-size: 16px;
    color: #0b1d4c;
}

.Variant {
    text-align: left;
    padding-left: 48px;
    font-family: Lato;
    font-size: 16px;
}

table {
    width: 100%;
    margin: auto;
    border-collapse: collapse;
}

td,
th {
    text-align: center;
    width: 100px;
}

table .md-field,
.md-input {
    width: 100px;
}

.md-switch {
    width: 50px;
}
</style>
