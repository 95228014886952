<template>
    <div class="md-layout">
        <div class="md-layout-item md-size-66 md-xsmall-size-80 mx-auto">
            <simple-wizard :prevButtonText="$t('previous')" :nextButtonText="$t('next')" :finishButtonText="$t('finish')">
                <template slot="header">
                    <h3 class="title">{{ $t('forgotten_password') }}</h3>
                    <h5 class="category">
                        {{ $t('forgotten_password') }}
                    </h5>
                </template>
                <wizard-tab :before-change="() => validateStep('step1')">
                    <template slot="label">
                        {{ $t('request_code') }}
                    </template>
                    <request-step ref="step1" @on-validated="onStepValidated"></request-step>
                </wizard-tab>
                <wizard-tab :before-change="() => validateStep('step2')">
                    <template slot="label">
                        {{ $t('reset_pass') }}
                    </template>
                    <reset-step ref="step2" @on-validated="wizardComplete"></reset-step>
                </wizard-tab>
            </simple-wizard>
        </div>
    </div>
</template>
<script>
import RequestStep from "./Wizard/RequestStep.vue";
import ResetStep from "./Wizard/ResetStep.vue";
import SimpleWizard from "../../Wizard/Wizard.vue";
import WizardTab from "../../Wizard/WizardTab.vue";

export default {
    data() {
        return {
            wizardModel: {}
        };
    },
    components: {
        RequestStep,
        ResetStep,
        SimpleWizard,
        WizardTab
    },
    methods: {
        validateStep(ref) {
            return this.$refs[ref].validate();
        },
        onStepValidated( /*validated, model*/ ) {
            //this.wizardModel = { ...this.wizardModel, ...model };
        },
        wizardComplete() {
            this.$noty.success(this.$t('success.forgot_pass_confirm'));
            //this.$router.push({ name: this.$i18n.locale + '.login' });
        }
    }
};
</script>