import { API, Auth } from "aws-amplify";
import util from "util";

const getDineOpeningSchedule = async (dineId) => {
    let responseData = [];
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const language = process.env.VUE_APP_I18N_LOCALE;
    const airportCode = process.env.VUE_APP_AIRPORT_CODE;
    const path = ["/CMS/dine/" + dineId + "/schedule"];
    var headers = {
        "x-api-key": API_KEY,
        "x-app-language": language,
        "x-airport-code": airportCode,
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    };

    const myInit = {
        headers: headers,
        response: true,
    };
    try {
        var response = await API.get(API_NAME, path, myInit);
        responseData = response.data;
        responseData.status = response.status;

    } catch (error) {
        console.error(
            "Error getting dine opening schedule list: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
    return responseData;
};

const createDineOpeningSchedule = async (dineId, item) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const language = process.env.VUE_APP_I18N_LOCALE;
    const airportCode = process.env.VUE_APP_AIRPORT_CODE;
    const path = ['/CMS/dine/' + dineId + '/schedule'];
    var headers = {
        "x-api-key": API_KEY,
        "x-app-language": language,
        "x-airport-code": airportCode,
        "Authorization": (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
        body: item,
    };
    try {
        var response = await API.post(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error creating dine opening schedule: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
};

const updateDineOpeningSchedule = async (dineId, item) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const language = process.env.VUE_APP_I18N_LOCALE;
    const airportCode = process.env.VUE_APP_AIRPORT_CODE;
    const path = ['/CMS/dine/' + dineId + '/schedule'];
    var headers = {
        "x-api-key": API_KEY,
        "x-app-language": language,
        "x-airport-code": airportCode,
        "Authorization": (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
        body: item,
    };
    try {
        var response = await API.put(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error updating dine opening schedule: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
};

const deleteDineOpeningSchedule = async (dineId) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const language = process.env.VUE_APP_I18N_LOCALE;
    const airportCode = process.env.VUE_APP_AIRPORT_CODE;
    const path = ["/CMS/dine/" + dineId + "/schedule"];
    var headers = {
        "x-api-key": API_KEY,
        "x-app-language": language,
        "x-airport-code": airportCode,
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
    };
    try {
        var response = await API.del(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error deleting dine opening schedule: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
};

const getShopOpeningSchedule = async (shopId) => {
    let responseData = [];
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const language = process.env.VUE_APP_I18N_LOCALE;
    const airportCode = process.env.VUE_APP_AIRPORT_CODE;
    const path = ["/CMS/shop/" + shopId + "/schedule"];
    var headers = {
        "x-api-key": API_KEY,
        "x-app-language": language,
        "x-airport-code": airportCode,
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    };

    const myInit = {
        headers: headers,
        response: true,
    };
    try {
        var response = await API.get(API_NAME, path, myInit);
        responseData = response.data;
        responseData.status = response.status;

    } catch (error) {
        console.error(
            "Error getting shop opening schedule list: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
    return responseData;
};

const createShopOpeningSchedule = async (shopId, item) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const language = process.env.VUE_APP_I18N_LOCALE;
    const airportCode = process.env.VUE_APP_AIRPORT_CODE;
    const path = ['/CMS/shop/' + shopId + '/schedule'];
    var headers = {
        "x-api-key": API_KEY,
        "x-app-language": language,
        "x-airport-code": airportCode,
        "Authorization": (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
        body: item,
    };
    try {
        var response = await API.post(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error creating shop opening schedule: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
};

const updateShopOpeningSchedule = async (shopId, item) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const language = process.env.VUE_APP_I18N_LOCALE;
    const airportCode = process.env.VUE_APP_AIRPORT_CODE;
    const path = ['/CMS/shop/' + shopId + '/schedule'];
    var headers = {
        "x-api-key": API_KEY,
        "x-app-language": language,
        "x-airport-code": airportCode,
        "Authorization": (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
        body: item,
    };
    try {
        var response = await API.put(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error updating shop opening schedule: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
};

const deleteShopOpeningSchedule = async (shopId) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const language = process.env.VUE_APP_I18N_LOCALE;
    const airportCode = process.env.VUE_APP_AIRPORT_CODE;
    const path = ["/CMS/shop/" + shopId + "/schedule"];
    var headers = {
        "x-api-key": API_KEY,
        "x-app-language": language,
        "x-airport-code": airportCode,
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
    };
    try {
        var response = await API.del(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error deleting shop opening schedule: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
};

export {
    getDineOpeningSchedule,
    createDineOpeningSchedule,
    updateDineOpeningSchedule,
    deleteDineOpeningSchedule,
    getShopOpeningSchedule,
    createShopOpeningSchedule,
    updateShopOpeningSchedule,
    deleteShopOpeningSchedule,
}