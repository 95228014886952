<template>
     <div class="horizontal-list">
    <ul>
      <li v-for="(item, index) in items" :key="index" @click="selectItem(index)" :class="{ 'selected': index === selectedItemIndex }">
        <slot :item="item">
          {{ item }}
        </slot>
      </li>
    </ul>
  </div>
  </template>
  
  <script>
  export default {
    props: {
      items: {
        type: Array,
        required: true
      },
      selectedItemIndex: {
        type: Number,
        default: -1
      }
    },
    methods: {
      selectItem(index) {
        this.$emit('select', index);
      }
    }
  };
  </script>
  
  <style scoped>
  .horizontal-list ul {
  margin-top: 0px;
  margin-left: -6px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  overflow: auto;
  min-width: 400px;
  min-height: 40px;
}
  
  .horizontal-list li {
    margin-right: 10px;
    border-radius: 10px;
    min-width: 200px;
    padding-top: 2px;
    padding-bottom: 0px;
    margin-top:0px;
    margin-bottom: 0px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 20px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    text-align: center;
    height: 30px;
  }
  
  .horizontal-list .selected {
    background-color: #0b1d4c;
  }

  .horizontal-list .selected:hover {
    background-color: #0b1d4c;
    color:white;
  }

  .selected {
  color: white;
}

.horizontal-list li:hover {
  background-color: rgba(211, 211, 211, 0.251);
  color:gray;
  cursor:pointer;
}

  </style>
  