<template>
    <div>
        <!--h5 class="info-text">
            {{ $t('reset_pass') }}
        </h5-->
        <div class="md-layout">
            <div class="md-layout-item md-size-95 ml-auto mt-4 md-small-size-100">
                <ValidationObserver v-slot="{ errors }" ref="validator">
                    <form>
                        <md-field slot="inputs" :class="[
                            { 'md-valid': errors['email'] && !errors['email'][0] && touchedFields.email },
                            { 'md-form-group': true },
                            { 'md-error': errors['email'] && errors['email'][0] }
                        ]">
                            <md-icon>email</md-icon>
                            <label for="email">{{ $t('fields.email') }}</label>
                            <validation-provider name='email' rules="required|email" v-slot="{ errors, touched }"
                                mode="aggressive">
                                <md-input v-model="email" name="email" type="email" :placeholder="$t('fields.email')"
                                    :disabled="loading"></md-input>
                                <slide-y-down-transition>
                                    <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                </slide-y-down-transition>
                                <span class="md-error">{{ errors[0] }}</span>
                            </validation-provider>
                        </md-field>
                        <md-field slot="inputs" :class="[
                            { 'md-valid': errors['confirmation_code'] && !errors['confirmation_code'][0] && touchedFields.confirmation_code },
                            { 'md-form-group': true },
                            { 'md-error': errors['confirmation_code'] && errors['confirmation_code'][0] }
                        ]">
                            <md-icon>confirmation_number</md-icon>
                            <label for="confirmation_code">{{ $t('fields.confirmation_code') }}</label>
                            <validation-provider name='confirmation_code' rules="required" v-slot="{ errors, touched }"
                                mode="aggressive">
                                <md-input v-model="confirmation_code" name="confirmation_code"
                                    :placeholder="$t('fields.confirmation_code')" id="confirmation_code"
                                    :disabled="loading"></md-input>
                                <slide-y-down-transition>
                                    <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                </slide-y-down-transition>
                                <span class="md-error">{{ errors[0] }}</span>
                            </validation-provider>
                        </md-field>
                        <md-field slot="inputs" :class="[
                            { 'md-valid': errors['password'] && !errors['password'][0] && touchedFields.password },
                            { 'md-form-group': true },
                            { 'md-error': errors['password'] && errors['password'][0] }
                        ]">
                            <md-icon>lock_outline</md-icon>
                            <label for="password">{{ $t('fields.password') }}</label>
                            <validation-provider name='password' rules="required" v-slot="{ errors, touched }"
                                mode="aggressive">
                                <md-input v-model="password" name="password" type="password"
                                    :placeholder="$t('fields.password')" id="password" :disabled="loading"></md-input>
                                <slide-y-down-transition>
                                    <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                </slide-y-down-transition>
                                <span class="md-error">{{ errors[0] }}</span>
                            </validation-provider>
                        </md-field>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<script>
import { Auth /*, API*/ } from 'aws-amplify';
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition
    },
    data() {
        return {
            loading: false,
            email: "",
            confirmation_code: "",
            password: "",
            touchedFields: {
                email: false,
                confirmation_code: false,
                password: false,
            },
        };
    },
    watch: {
        email() {
            this.touchedFields.email = true;
        },
        confirmation_code() {
            this.touchedFields.confirmation_code = true;
        },
        password() {
            this.touchedFields.password = true;
        }
    },
    methods: {
        async validate() {
            let valid = await this.$refs.validator.validate();
            if (valid) {
                await this.reset();
            }
            return valid;
        },
        async reset() {
            this.loading = true;
            try {
                let username = this.email;
                let code = this.confirmation_code;
                let new_password = this.password;
                await Auth.forgotPasswordSubmit(username, code, new_password);
                this.$emit("on-validated");
                this.loading = false;
                return true;
            } catch (err) {
                console.error('Error stablishing new pass', err);
                var errorMsg = '';
                switch (err.code) {
                    case 'CodeMismatchException':
                        errorMsg = this.$t('error.code_mismatch');
                        break;
                    case 'ExpiredCodeException':
                        errorMsg = this.$t('error.expired_code');
                        break;
                    case 'LimitExceededException':
                        errorMsg = this.$t('error.limit_exceeded');
                        break;
                    case 'InvalidLambdaResponseException': 
                        //Force error InvalidLambdaResponseException
                        this.$emit("on-validated");
                        this.loading = false;
                        return true;
                    default:
                        console.info("ERROR CODE:", err.code);
                        errorMsg = this.$t('error.forgot_pass_confirm');
                }
                this.$noty.error(errorMsg);
                this.loading = false;
                return false;
            }
        }
    }
};
</script>
<style></style>