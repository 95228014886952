<template>
    <ValidationObserver tag="form" ref="form" v-slot="{ errors }">
        <div class="md-layout form-layout">
            <!-- Button -->
            <div class="md-layout-item md-size-100 form-toolbar">
                <h3 class="page-title">
                    <span v-if="creating">
                        {{ $t("routes.shop_create.title") }}
                    </span>
                    <span v-if="editing">
                        {{ $t("routes.shop_edit.title") }} {{ item.name }}
                    </span>
                </h3>
                <div class="form-buttons">
                    <md-progress-spinner v-if="loading" :md-diameter="30" :md-stroke="3" md-mode="indeterminate">
                    </md-progress-spinner>
                    <!-- Save button -->
                    <md-button class="md-primary md-round btn-medium" @click="save()">
                        {{ $t("save") }}
                    </md-button>
                </div>
            </div>
            <!-- Order -->
            <div class="md-layout-item md-size-40">
                <md-field>
                    <md-select v-model="item.order" :disabled="loading" :placeholder="$t('label.shop_order')">
                        <md-option v-for="(order, index) in orders" :key="index" :value="order.value">
                            {{ order.text }}
                        </md-option>
                    </md-select>
                    <span class="md-error">
                        {{ errorOrderMessage }}
                    </span>
                </md-field>
            </div>
            <div class="md-layout-item md-size-10"></div>
            <!-- Enabled -->
            <div class="md-layout-item md-size-50">
                <div class="field-switch">
                    <Span>{{ $t("label.shop_enabled") }}</Span>
                    <md-switch id="switchEnabled" v-model="item.enabled" :disabled="loading">
                    </md-switch>
                </div>
                 <!-- Show In List -->
                 <div class="field-switch">
                    <Span>{{ $t("label.shop_showInList") }}</Span>
                    <md-switch id="switchshowInList" v-model="item.showInList" :disabled="loading">
                    </md-switch>
                </div>
            </div>
            <!-- media id -->
            <div class="md-layout-item md-size-40">
                <div class="md-layout-item">
                    <div class="md-layout">
                        <div>
                            <md-icon class="md-42">iconimage_search</md-icon>
                        </div>
                        <div class="md-layout-item md-size-70">
                            <md-field class="image-field">
                                <label class="label-images" v-if="item.media.fileName == ''">{{
                                    $t("label.shop_image")
                                }}</label>
                                <label v-if="item.media.fileName == ''">{{ $t("label.select_image") }}</label>
                                <label v-else>{{ $t("label.shop_image") }}</label>
                                <md-input readonly id="mediaInput" v-model="item.media.fileName" :disabled="true">
                                </md-input>
                                <md-button v-if="item.media.fileName != ''" class="btn-floating" @click="clearMedia()">
                                    <md-icon>close</md-icon>
                                </md-button>
                                <span class="md-error">
                                    {{ errorMediaMessage }}
                                </span>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-20">
                            <md-button @click="openMediaModal()" class="md-primary md-round btn-medium">
                                {{ $t("select_file") }}
                            </md-button>
                        </div>
                    </div>
                </div>
                <!-- media image preview -->
                <div class="md-layout-item md-size-50">
                    <div class="media-container-left">
                        <md-icon v-if="item.media.imageURL == ''" class="md-size-5x">image</md-icon>
                        <img v-else :src="item.media.imageURL" :alt="item.media.imageAlt"
                            @click.prevent="viewImage(item.media.imageURL)" />
                    </div>
                </div>
            </div>
            <div class="md-layout-item md-size-10"></div>
            <!-- logo media id -->
            <div class="md-layout-item md-size-40">
                <div class="md-layout-item">
                    <div class="md-layout">
                        <div>
                            <md-icon class="md-42">iconimage_search</md-icon>
                        </div>
                        <div class="md-layout-item md-size-70">
                            <md-field class="image-field">
                                <label class="label-images" v-if="item.logoMedia.fileName == ''">{{
                                    $t("label.shop_logo_image")
                                }}</label>
                                <label v-if="item.media.fileName == ''">{{ $t("label.select_image") }}</label>
                                <label v-else>{{ $t("label.shop_logo_image") }}</label>
                                <md-input readonly id="mediaInput" v-model="item.logoMedia.fileName" :disabled="true">
                                </md-input>
                                <md-button v-if="item.logoMedia.fileName != ''" class="btn-floating" @click="clearLogoMedia()">
                                    <md-icon>close</md-icon>
                                </md-button>
                                <span class="md-error">
                                    {{ errorMediaLogoMessage }}
                                </span>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-20">
                            <md-button @click="openLogoMediaModal()" class="md-primary md-round btn-medium">
                                {{ $t("select_file") }}
                            </md-button>
                        </div>
                    </div>
                </div>
                <!-- media image preview -->
                <div class="md-layout-item md-size-50">
                    <div class="media-container-logo">
                        <md-icon v-if="item.logoMedia.imageURL == ''" class="md-size-2x">image</md-icon>
                        <img v-else :src="item.logoMedia.imageURL" :alt="item.logoMedia.imageAlt"
                            @click.prevent="viewImage(item.logoMedia.imageURL)" />
                    </div>
                </div>
            </div>
            <!-- Contact information -->
            <div class="md-layout-item md-size-100">
                <div class="Group-Header">
                    <span class="Luggage-information">
                        {{ $t('label.contact_information') }}
                    </span>
                    <div class="Path"></div>
                </div>
            </div>
            <!-- Email -->
            <div class="md-layout-item md-size-40">
                <md-field :class="[
                    { 'md-valid': errors['email'] && !errors['email'][0] && touchedFields.email },
                    { 'md-error': errors['email'] && errors['email'][0] }
                ]">
                    <label for="mail">{{ $t('label.email') }}</label>
                    <validation-provider name='email' rules="email" v-slot="{ errors, touched }" mode="aggressive">
                        <md-input v-model="item.email" type="text" :placeholder="$t('label.email')" :disabled="loading">
                        </md-input>
                        <slide-y-down-transition>
                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                        </slide-y-down-transition>
                        <span class="md-error">{{ errors[0] }}</span>
                    </validation-provider>
                </md-field>
            </div>
            <div class="md-layout-item md-size-20"></div>
            <!-- Phone -->
            <div class="md-layout-item md-size-40">
                <md-field :class="[
                    { 'md-valid': errors['phone'] && !errors['phone'][0] && touchedFields.phone },
                    { 'md-error': errors['phone'] && errors['phone'][0] }
                ]">
                    <label for="title">{{ $t('label.phone') }}</label>
                    <validation-provider name='phone' rules="" v-slot="{ errors, touched }" mode="aggressive">
                        <md-input v-model="item.phone" type="text" :placeholder="$t('label.phone')" :disabled="loading">
                        </md-input>
                        <slide-y-down-transition>
                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                        </slide-y-down-transition>
                        <span class="md-error">{{ errors[0] }}</span>
                    </validation-provider>
                </md-field>
            </div>
            <!-- Separator Map information -->
            <div class="md-layout-item md-size-100">
                <div class="Group-Header">
                    <span class="Luggage-information">
                        {{ $t('label.map_information') }}
                    </span>
                    <div class="Path"></div>
                </div>
            </div>
            <div class="md-layout">
                <!-- Building  Floor -->
                <div class="md-layout-item md-size-30">
                    <md-field>
                        <md-select v-model="item.mapBuildingFloor" :disabled="loading"
                            :placeholder="$t('label.mapBuildingFloor')">
                            <md-option :value="-1000">
                                {{ $t("label.mapBuildingFloor_value_default") }}
                            </md-option>
                            <md-option :value="-1">
                                {{ $t("label.mapBuildingFloor_value_-1") }}
                            </md-option>
                            <md-option :value="0">
                                {{ $t("label.mapBuildingFloor_value_0") }}
                            </md-option>
                            <md-option :value="1">
                                {{ $t("label.mapBuildingFloor_value_1") }}
                            </md-option>
                        </md-select>
                    </md-field>
                </div>
                <div class="md-layout-item md-size-40"></div>
                <!-- Building  Id -->
                <div class="md-layout-item md-size-30">
                    <md-field>
                        <md-select v-model="item.mapBuildingId" :disabled="loading"
                            :placeholder="$t('label.mapBuildingId')">
                            <md-option v-for="(building, idx3) of buildings" :value="building.value" :key="idx3">
                                {{ building.text }}</md-option>
                        </md-select>
                    </md-field>
                </div>
            </div>
            <!-- Separator Location information -->
            <div class="md-layout-item md-size-100">
                <div class="Group-Header">
                    <span class="Luggage-information">
                        {{ $t('label.location_information') }}
                    </span>
                    <div class="Path"></div>
                </div>
            </div>
            <!-- Location selector -->
            <div class="md-layout-item md-size-30">
                <md-field>
                    <md-select v-model="item.airportLocationCode" :disabled="loading"
                        :placeholder="$t('label.location_value_default')">
                        <md-option v-for="(item, idx5) of locations" :value="item.value" :key="idx5">
                            {{ item.text }}
                        </md-option>
                    </md-select>
                </md-field>
            </div>
            <div class="md-layout-item md-size-40"></div>
            <!-- After or before  Security -->
            <div class="md-layout-item md-size-30">
                <md-field>
                    <md-select v-model="item.securityLocation" :disabled="loading"
                        :placeholder="$t('label.beforeSecurity_value_default')">
                        <md-option v-for="(item, idx4) of afterbeforelocations" :value="item.value" :key="idx4">
                            {{ item.text }}</md-option>
                    </md-select>
                    <span class="md-error">
                        {{ errorAfterOrBeforeSecurityMessage }}
                    </span>
                </md-field>
            </div>
            <!-- Separator localizations -->
            <div class="md-layout-item md-size-100">
                <div class="Group-Header">
                    <span class="Luggage-information">
                        {{ $t('label.language_information') }}
                    </span>
                    <div class="Path"></div>
                </div>
            </div>
            <!-- Translations -->
            <md-card class="translations-card">
                <md-card-content>
                    <md-tabs class="translations-tabs">
                        <md-tab id="tab-tr" :md-label="$t('languages.tr')" :md-icon="getLanguageIcon('tr', errors)">
                            <!-- name tr -->
                            <md-field :class="[
                                { 'md-valid': errors['name_tr'] && !errors['name_tr'][0] && touchedFields.name_tr },
                                { 'md-error': errors['name_tr'] && errors['name_tr'][0] }
                            ]">
                                <label for="title">{{ $t('label.shop_name') }}</label>
                                <validation-provider name='name_tr' rules="required" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="item.name_tr" type="text" :placeholder="$t('label.shop_name')"
                                        :disabled="loading">
                                    </md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <!-- brief tr -->
                            <md-field :class="[
                                { 'md-valid': errors['brief_tr'] && !errors['brief_tr'][0] && touchedFields.brief_tr },
                                { 'md-error': errors['brief_tr'] && errors['brief_tr'][0] }
                            ]">
                                <label for="title">{{ $t('label.shop_brief') }}</label>
                                <validation-provider name='brief_tr' rules="required" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="item.brief_tr" type="text" :placeholder="$t('label.shop_brief')"
                                        :disabled="loading">
                                    </md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <!-- description tr -->
                            <div class="md-layout-item md-size-100">
                                <md-field :class="[
                                    { 'md-valid': errors['description_tr'] && !errors['description_tr'][0] && touchedFields.description_tr },
                                    { 'md-error': errors['description_tr'] && errors['description_tr'][0] }
                                ]">
                                    <label for="title">{{ $t('fields.shop_description') }}</label>
                                    <validation-provider name='description_tr' rules="" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-textarea md-autogrow v-model="item.description_tr" name="description_tr"
                                            type="text" :placeholder="$t('label.shop_description')" id="description_tr"
                                            :disabled="loading"></md-textarea>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                            <!-- locationBriefDescription tr -->
                            <div class="md-layout-item md-size-100">
                                <md-field :class="[
                                    { 'md-valid': errors['locationBriefDescription_tr'] && !errors['locationBriefDescription_tr'][0] && touchedFields.locationBriefDescription_tr },
                                    { 'md-error': errors['locationBriefDescription_tr'] && errors['locationBriefDescription_tr'][0] }
                                ]">
                                    <label for="title">{{ $t('fields.shop_locationBriefDescription') }}</label>
                                    <validation-provider name='locationBriefDescription_tr' rules=""
                                        v-slot="{ errors, touched }" mode="aggressive">
                                        <md-textarea md-autogrow v-model="item.locationBriefDescription_tr"
                                            name="locationBriefDescription_tr" type="text"
                                            :placeholder="$t('label.shop_locationBriefDescription')"
                                            id="locationBriefDescription_tr" :disabled="loading"></md-textarea>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                            <!-- shareUrl tr -->
                            <div class="md-layout-item md-size-100">
                                <md-field :class="[
                                    { 'md-valid': errors['shareURL_tr'] && !errors['shareURL_tr'][0] && touchedFields.shareURL_tr },
                                    { 'md-error': errors['shareURL_tr'] && errors['shareURL_tr'][0] }
                                ]">
                                    <label for="title">{{ $t('label.shop_shareURL') }}</label>
                                    <validation-provider name='shareURL_tr' rules="" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-textarea md-autogrow v-model="item.shareURL_tr" name="shareURL_tr"
                                            type="text" :placeholder="$t('label.shop_shareURL')" id="shareURL_tr"
                                            :disabled="loading"></md-textarea>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                        </md-tab>
                        <md-tab id="tab-en" :md-label="$t('languages.en')" :md-icon="getLanguageIcon('en', errors)">
                            <!-- name en -->
                            <md-field :class="[
                                { 'md-valid': errors['name_en'] && !errors['name_en'][0] && touchedFields.name_en },
                                { 'md-error': errors['name_en'] && errors['name_en'][0] }
                            ]">
                                <label for="title">{{ $t('label.shop_name') }}</label>
                                <validation-provider name='name_en' rules="required" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="item.name_en" type="text" :placeholder="$t('label.shop_name')"
                                        :disabled="loading">
                                    </md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <!-- brief en -->
                            <md-field :class="[
                                { 'md-valid': errors['brief_en'] && !errors['brief_en'][0] && touchedFields.brief_en },
                                { 'md-error': errors['brief_en'] && errors['brief_en'][0] }
                            ]">
                                <label for="title">{{ $t('label.shop_brief') }}</label>
                                <validation-provider name='brief_en' rules="required" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="item.brief_en" type="text" :placeholder="$t('label.shop_brief')"
                                        :disabled="loading">
                                    </md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <!-- description en -->
                            <div class="md-layout-item md-size-100">
                                <md-field :class="[
                                    { 'md-valid': errors['description_en'] && !errors['description_en'][0] && touchedFields.description_en },
                                    { 'md-error': errors['description_tr'] && errors['description_en'][0] }
                                ]">
                                    <label for="title">{{ $t('fields.shop_description') }}</label>
                                    <validation-provider name='description_en' rules="" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-textarea md-autogrow v-model="item.description_en" name="description_en"
                                            type="text" :placeholder="$t('label.shop_description')" id="description_en"
                                            :disabled="loading"></md-textarea>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                            <!-- locationBriefDescription en -->
                            <div class="md-layout-item md-size-100">
                                <md-field :class="[
                                    { 'md-valid': errors['locationBriefDescription_en'] && !errors['locationBriefDescription_en'][0] && touchedFields.locationBriefDescription_en },
                                    { 'md-error': errors['locationBriefDescription_en'] && errors['locationBriefDescription_en'][0] }
                                ]">
                                    <label for="title">{{ $t('fields.shop_locationBriefDescription') }}</label>
                                    <validation-provider name='locationBriefDescription_en' rules=""
                                        v-slot="{ errors, touched }" mode="aggressive">
                                        <md-textarea md-autogrow v-model="item.locationBriefDescription_en"
                                            name="locationBriefDescription_en" type="text"
                                            :placeholder="$t('label.shop_locationBriefDescription')"
                                            id="locationBriefDescription_en" :disabled="loading"></md-textarea>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                            <!-- shareUrl en -->
                            <div class="md-layout-item md-size-100">
                                <md-field :class="[
                                    { 'md-valid': errors['shareURL_en'] && !errors['shareURL_en'][0] && touchedFields.shareURL_en },
                                    { 'md-error': errors['shareURL_en'] && errors['shareURL_en'][0] }
                                ]">
                                    <label for="title">{{ $t('label.shop_shareURL') }}</label>
                                    <validation-provider name='shareURL_en' rules="" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-textarea md-autogrow v-model="item.shareURL_en" name="shareURL_en"
                                            type="text" :placeholder="$t('label.shop_shareURL')" id="shareURL_en"
                                            :disabled="loading"></md-textarea>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                        </md-tab>
                    </md-tabs>
                </md-card-content>
            </md-card>
            <!-- Buttons -->
            <div class="cont">
                <!-- categories button -->
                <md-button :disabled="loading == true || creating == true" class="md-primary md-round"
                    @click="showModal('categories')">
                    {{ $t("categories") }}
                </md-button>
                <!-- opening hours button -->
                <md-button :disabled="loading == true || creating == true" class="md-primary md-round"
                    @click="showModal('opening_hours')">
                    {{ $t("opening_hours") }}
                </md-button>
                <!-- products button -->
                <md-button :disabled="loading == true || creating == true" class="md-primary md-round"
                    @click="showModal('products')">
                    {{ $t("products") }}
                </md-button>
                <!-- brands button -->
                <md-button :disabled="loading == true || creating == true" class="md-primary md-round"
                    @click="showModal('brands')">
                    {{ $t("brands") }}
                </md-button>
                <!-- promos button -->
                <md-button :disabled="loading == true || creating == true" class="md-primary md-round"
                    @click="showModal('promos')">
                    {{ $t("promos") }}
                </md-button>
            </div>
            <!-- Category Modal-->
            <category-modal :showProp="showCategoryModal" :shopId="item.shopId" :idProp="item.id"
                :parentNameProp="item.name" :selectedItemsProp="item.categories" @apply="applyCategory"
                @close="closeCategoryModal()">
            </category-modal>
            <!-- Opening Schedule Modal -->
            <opening-modal :showProp="showOpeningModal" :shopId="item.shopId" :parentNameProp="item.name"
                @close="closeOpeningModal()">
            </opening-modal>
            <!-- Product Modal-->
            <product-modal :showProp="showProductModal" :shopId="item.shopId" :name="item.name"
                @close="closeProductModal()">
            </product-modal>
            <!-- Brand Modal-->
            <brand-modal :showProp="showBrandModal" :shopId="item.shopId" :name="item.name" @close="closeBrandModal()">
            </brand-modal>
            <!-- Promo Modal-->
            <promo-modal :showProp="showPromoModal" :shopId="item.shopId" :parentNameProp="item.name"
                @close="closePromoModal()">
            </promo-modal>
            <!-- Media Modal -->
            <media-modal :showProp="showMediaModal" @apply="applyMedia" @close="closeMediaModal()"></media-modal>
            <!-- Logo Media Modal -->
            <media-modal :showProp="showLogoMediaModal" @apply="applyLogoMedia"
                @close="closeLogoMediaModal()"></media-modal>
        </div>
    </ValidationObserver>
</template>
<script>
import MediaModal from "@/components/Modals/Media";
import CategoryModal from "@/components/Modals/Category";
import ProductModal from "@/components/Modals/Product";
import BrandModal from "@/components/Modals/Brand";
import PromoModal from "@/components/Modals/Promo";
import OpeningModal from "@/components/Modals/OpeningSchedule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import { getShop, createShop, updateShop } from "@/services/shop";
import { getAirportLocations } from "@/services/airportLocation";
import localeMixin from "@/mixins/locale";


export default {
    mixins: [localeMixin],
    components: {
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
        MediaModal,
        CategoryModal,
        ProductModal,
        BrandModal,
        PromoModal,
        OpeningModal,
    },
    data() {
        return {

            loading: false,
            creating: false,
            editing: false,
            imagesLightbox: [],
            touchedFields: {
                name_tr: false,
                name_en: false,
                brief_tr: false,
                brief_en: false,
                description_tr: false,
                description_en: false,
                locationBriefDescription_en: false,
                locationBriefDescription_tr: false,
                shareURL_en: false,
                shareURL_tr: false,
                email: false,
                phone: false,
            },
            item: {
                shopId: "",
                id: 0,
                order: 0,
                name: "",
                email: "",
                phone: "",
                enabled: false,
                showInList: false,
                takeAwayEnabled: false,
                mapBuildingId: 0,
                mapBuildingFloor: -1000,
                mediaId: 0,
                imageURL: "",
                imageAlt: "",
                fileName: "",
                logoMediaId: 0,
                logoURL: "",
                logoAlt: "",
                logoFileName: "",
                securityLocation: 66,
                airportLocationCode: "",
                media: {
                    fileName: "", imageURL: "", imageAlt: ""
                },
                logoMedia: {
                    fileName: "", imageURL: "", imageAlt: ""
                },
                translationId_tr: 0,
                translationId_en: 0,
                name_tr: "",
                name_en: "",
                brief_tr: "",
                brief_en: "",
                description_en: "",
                description_tr: "",
                locationBriefDescription_en: "",
                locationBriefDescription_tr: "",
                shareURL_en: "",
                shareURL_tr: "",
                categories: []
            },
            SelectedAirportLocation: { value: "", text: this.$t('label.location_value_default') },
            errorMediaMessage: "",
            errorMediaLogoMessage: "",
            errorOrderMessage: "",
            errorAfterOrBeforeSecurityMessage: "",
            showMediaModal: false,
            showLogoMediaModal: false,
            showCategoryModal: false,
            showProductModal: false,
            showBrandModal: false,
            showOpeningModal: false,
            showPromoModal: false,
            orders: [],
            buildings: [],
            locations: [],
            afterbeforelocations: [],
            buildingfloors: [],
        };
    },
    created: async function () {
        let shopId = this.$route.params.shopId;
        if (shopId) {
            await this.editItem(shopId);
        } else {
            let lastOrder = this.$route.params.lastOrder;
            if (lastOrder) {
                this.item.order = lastOrder;
            }
            this.newItem();
        }

    },

    async mounted() {
        await this.fillLocations();
        this.fillOrdersArray();
        this.fillBuildingsArray();
        this.fillAfterOrBeforeArray();
    },

    methods: {

        viewImage(imageUrl) {
            this.imagesLightbox = [];
            this.$imageViewer.images(this.imagesLightbox);
            const image = {
                url: imageUrl,
            };
            this.imagesLightbox.push(image);
            this.$imageViewer.index(0);
            this.$imageViewer.show();
        },

        fillOrdersArray() {
            let min = 0;
            let max = 50;
            this.orders = [];
            for (let i = min; i <= max; i++) {
                if (i == 0) {
                    let z = { value: i, text: this.$t('label.order_default') };
                    this.orders.push(z);
                } else {
                    let b = { value: i, text: i.toString() };
                    this.orders.push(b);
                }
            }
        },

        fillBuildingsArray() {
            let min = 0;
            let max = 50;
            this.buildings = [];
            for (let i = min; i <= max; i++) {
                if (i == 0) {
                    let t = this.$t('label.mapBuildingId_default');
                    let z = { value: i, text: t };
                    this.buildings.push(z);
                } else {
                    let t = this.$t('label.mapBuildingId_value_N', { _field_: i });
                    let b = { value: i, text: t };
                    this.buildings.push(b);
                }
            }
        },

        fillAfterOrBeforeArray() {
            this.afterbeforelocations = [];
            this.afterbeforelocations.push({ value: 0, text: this.$t('label.beforeSecurity_value_default') });
            this.afterbeforelocations.push({ value: 66, text: this.$t('label.beforeSecurity_value_B') });
            this.afterbeforelocations.push({ value: 65, text: this.$t('label.beforeSecurity_value_A') });
        },

        async fillLocations() {
            let t = this.$t('label.location_value_default');
            let z = { value: "-", text: t };
            this.locations.push(z);
            const responseLocations = await getAirportLocations(this.page, this.limit, "");
            if (responseLocations) {

                responseLocations.result.map(item => {
                    let b = {
                        value: item.airportLocationCode,
                        text: item.description,
                        color: item.color
                    };

                    if (!b.color) {
                        b.color = this.RGBAToHexA('rgba(11, 29, 76, 1)');
                    } else {
                        b.color = this.RGBAToHexA(b.color);
                    }

                    this.locations.push(b);
                });

            } else {
                this.locations = [];
            }



        },

        applyMedia(media) {
            this.item.media = media;
            this.item.mediaId = media.mediaId;
            this.item.imageURL = media.imageURL;
            this.item.imageAlt = media.imageAlt;
            this.item.fileName = media.fileName;
            this.errorMediaMessage = "";
        },

        clearMedia() {

            this.item.media = { fileName: "", imageURL: "", imageAlt: "" };
            this.item.mediaId = 0;
            this.item.imageURL = this.item.media.imageURL;
            this.item.imageAlt = this.item.media.imageAlt;
            this.item.fileName = this.item.media.fileName;
            this.errorMediaMessage = "";
        },

        openMediaModal() {
            this.showMediaModal = true;
        },

        closeMediaModal() {
            this.showMediaModal = false;
            if (this.item.mediaId == 0) {
                this.errorMediaMessage = this.$t('validation.required', { _field_: this.$t('label.shop_image') });

            }
        },

        applyLogoMedia(media) {
            this.item.logoMedia = media;
            this.item.logoMediaId = media.mediaId;
            this.item.logoURL = media.imageURL;
            this.item.logoAlt = media.imageAlt;
            this.item.logoFileName = media.fileName;
            this.errorMediaLogoMessage = "";
        },

        clearLogoMedia() {

            this.item.logoMedia = { fileName: "", imageURL: "", imageAlt: "" };
            this.item.logoMediaId = 0;
            this.item.logoURL = this.item.media.imageURL;
            this.item.logoAlt = this.item.media.imageAlt;
            this.item.logoFileName = this.item.media.fileName;
            this.errorMediaLogoMessage = "";
        },

        openLogoMediaModal() {
            this.showLogoMediaModal = true;
        },

        closeLogoMediaModal() {
            this.showLogoMediaModal = false;
            if (this.item.logoMediaId == 0) {
                this.errorMediaLogoMessage = this.$t('validation.required', { _field_: this.$t('label.shop_logo') });

            }
        },

        openProductModal() {
            this.showProductModal = true;
        },

        closeProductModal() {
            this.showProductModal = false;
        },

        openBrandModal() {
            this.showBrandModal = true;
        },

        closeBrandModal() {
            this.showBrandModal = false;
        },

        openPromoModal() {
            this.showPromoModal = true;
        },

        closePromoModal() {
            this.showPromoModal = false;
        },

        openOpeningModal() {
            this.showOpeningModal = true;
        },

        async closeOpeningModal() {
            this.showOpeningModal = false;
            let shopId = this.$route.params.shopId;
            await this.fetchItem(shopId);
        },

        applyCategory(categories) {
            this.item.categories = categories;
        },

        openCategoryModal() {
            this.showCategoryModal = true;
        },

        closeCategoryModal() {
            this.showCategoryModal = false;
        },

        showModal(mode) {
            switch (mode) {
                case "categories":
                    this.openCategoryModal();
                    break;
                case "products":
                    this.openProductModal();
                    break;
                case "brands":
                    this.openBrandModal();
                    break;
                case "promos":
                    this.openPromoModal();
                    break;
                case "opening_hours":
                    this.openOpeningModal();
                    break;
                default:
                    break;
            }
        },

        async save() {

            this.errorMediaMessage = "";
            this.errorMediaLogoMessage = "";
            this.errorOrderMessage = "";
            this.errorAfterOrBeforeSecurityMessage = "";

            var valid = await this.$refs.form.validate();


            if (this.item.mediaId == 0) {
                valid = false;
                this.errorMediaMessage = this.$t('validation.required', { _field_: this.$t('label.shop_image') });
            }

            if (this.item.logoMediaId == 0) {
                valid = false;
                this.errorMediaLogoMessage = this.$t('validation.required', { _field_: this.$t('label.shop_logo') });
            }

            if (this.item.order == 0) {
                valid = false;
                this.errorOrderMessage = this.$t('validation.at_least_one_value', { _field_: this.$t('label.shop_order') });
            }

            if (this.item.securityLocation == 0) {
                valid = false;
                this.errorAfterOrBeforeSecurityMessage = this.$t('validation.at_least_one_value', { _field_: this.$t('label.shop_securityLocation') });
            }

            if (!valid) {
                this.$noty.error(this.$t('error.saved'));
            }


            if (valid) {
                this.loading = true;
                if (this.creating) {
                    let response = await createShop(this.convertDataModel());
                    if (response.status == "201") {
                        this.$noty.success(this.$t('success.saved'));
                        if (response.data.shopId) {
                            this.$router.push({ name: this.$i18n.locale + '.shop_edit', params: { shopId: response.data.shopId } });
                        } else {
                            this.$router.push({ name: this.$i18n.locale + '.shops' });
                        }

                    } else {
                        this.$noty.error(this.$t('error.saved'));
                    }
                }
                if (this.editing) {
                    let response = await updateShop(this.convertDataModel());
                    if (response.status == "200") {
                        this.$noty.success(this.$t('success.saved'));
                    } else {
                        this.$noty.error(this.$t('error.saved'));
                    }
                }
                this.loading = false;


            }

        },

        assignDataModel(item) {
            if (item.shopId) {
                this.item.shopId = item.shopId;
            } else {
                this.item.shopId = "";
            }

            if (item.id) {
                this.item.id = item.id;
            } else {
                this.item.id = 0;
            }

            if (item.name) {
                this.item.name = item.name;
            } else {
                this.item.name = "";
            }

            if (item.order) {
                this.item.order = item.order;
            } else {
                this.item.order = 0;
            }

            if (item.active) {
                this.item.enabled = Boolean(item.active);
            } else {
                this.item.enabled = false;
            }

            if (item.showInList) {
                this.item.showInList = Boolean(item.showInList);
            } else {
                this.item.showInList = false;
            }

            if (item.takeAwayEnabled) {
                this.item.takeAwayEnabled = Boolean(item.takeAwayEnabled);
            } else {
                this.item.takeAwayEnabled = false;
            }

            if (item.mediaId) {
                this.item.mediaId = item.mediaId;
            } else {
                this.item.mediaId = 0;
            }

            if (item.fileName) {
                this.item.media.fileName = item.fileName;
            } else {
                this.item.media.fileName = "";
            }

            if (item.imageURL) {
                this.item.media.imageURL = item.imageURL;
            } else {
                this.item.media.imageURL = "";
            }

            if (item.imageAlt) {
                this.item.media.imageAlt = item.imageAlt;
            } else {
                this.item.media.imageAlt = "";
            }

            if (item.logoMediaId) {
                this.item.logoMediaId = item.logoMediaId;
            } else {
                this.item.logoMediaId = 0;
            }

            if (item.logoFileName) {
                this.item.logoMedia.fileName = item.logoFileName;
            } else {
                this.item.logoMedia.fileName = "";
            }

            if (item.logoURL) {
                this.item.logoMedia.imageURL = item.logoURL;
            } else {
                this.item.logoMedia.imageURL = "";
            }

            if (item.logoAlt) {
                this.item.logoMedia.imageAlt = item.logoAlt;
            } else {
                this.item.logoMedia.imageAlt = "";
            }

            if (item.phone) {
                this.item.phone = item.phone;
            } else {
                this.item.phone = "";
            }

            if (item.email) {
                this.item.email = item.email;
            } else {
                this.item.email = "";
            }

            if (item.mapBuildingFloor != null) {
                this.item.mapBuildingFloor = item.mapBuildingFloor;
            } else {
                this.item.mapBuildingFloor = -1000;
            }

            if (item.mapBuildingId) {
                this.item.mapBuildingId = item.mapBuildingId;
            } else {
                this.item.mapBuildingId = 0;
            }

            if (item.securityLocation) {
                this.item.securityLocation = item.securityLocation.charCodeAt(0);
            } else {
                this.item.securityLocation = 66;
            }

            if (item.airportLocationCode) {
                this.item.airportLocationCode = item.airportLocationCode;
            } else {
                this.item.airportLocationCode = "";
            }

            if (item.categories) {
                this.item.categories = item.categories;
            } else {
                this.item.categories = [];
            }

            var translation = item.translations.filter(t => t.locale == 'en')[0];

            if (translation) {

                if (translation.translationId) {
                    this.item.translationId_en = translation.translationId;
                } else {
                    this.item.translationId_en = 0;
                }

                if (translation.name) {
                    this.item.name_en = translation.name;
                } else {
                    this.item.name_en = "";
                }

                if (translation.brief) {
                    this.item.brief_en = translation.brief;
                } else {
                    this.item.brief_en = "";
                }

                if (translation.description) {
                    this.item.description_en = translation.description;
                } else {
                    this.item.description_en = "";
                }

                if (translation.locationBriefDescription) {
                    this.item.locationBriefDescription_en = translation.locationBriefDescription;
                } else {
                    this.item.locationBriefDescription_en = "";
                }

                if (translation.shareURL) {
                    this.item.shareURL_en = translation.shareURL;
                } else {
                    this.item.shareURL_en = "";
                }

            } else {

                this.item.translationId_en = 0;
                this.item.name_en = "";
                this.item.brief_en = "";
                this.item.description_en = "";
                this.item.locationBriefDescription_en = "";
                this.item.shareURL_en = "";

            }

            translation = item.translations.filter(t => t.locale == 'tr')[0];

            if (translation) {

                if (translation.translationId) {
                    this.item.translationId_tr = translation.translationId;
                } else {
                    this.item.translationId_tr = 0;
                }

                if (translation.name) {
                    this.item.name_tr = translation.name;
                } else {
                    this.item.name_tr = "";
                }

                if (translation.brief) {
                    this.item.brief_tr = translation.brief;
                } else {
                    this.item.brief_tr = "";
                }

                if (translation.description) {
                    this.item.description_tr = translation.description;
                } else {
                    this.item.description_tr = "";
                }

                if (translation.locationBriefDescription) {
                    this.item.locationBriefDescription_tr = translation.locationBriefDescription;
                } else {
                    this.item.locationBriefDescription_tr = "";
                }

                if (translation.shareURL) {
                    this.item.shareURL_tr = translation.shareURL;
                } else {
                    this.item.shareURL_tr = "";
                }

            } else {

                this.item.translationId_tr = 0;
                this.item.name_tr = "";
                this.item.brief_tr = "";
                this.item.description_tr = "";
                this.item.locationBriefDescription_tr = "";
                this.item.shareURL_tr = "";

            }


        },


        convertDataModel() {
            var newItem = {};
            newItem.shopId = this.item.shopId;
            newItem.mediaId = this.item.mediaId;
            newItem.logoMediaId = this.item.logoMediaId;
            newItem.phone = this.item.phone;
            newItem.email = this.item.email;
            newItem.order = this.item.order;
            newItem.mapBuildingId = this.item.mapBuildingId;
            newItem.mapBuildingFloor = this.item.mapBuildingFloor;
            if (newItem.mapBuildingFloor == -1000){
                newItem.mapBuildingFloor = null;
            }
            newItem.active = Number(this.item.enabled);
            newItem.showInList = Number(this.item.showInList);
            newItem.capacity = 0; //Cannot be null into db
            newItem.grabEnabled = 0; //Cannot be null into db

            if (this.item.airportLocationCode) {
                newItem.airportLocationCode = this.item.airportLocationCode;
            } else {
                newItem.airportLocationCode = null;
            }


            newItem.securityLocation = String.fromCharCode(this.item.securityLocation);
            newItem.translations = [];
            var trans_item_tr = {};
            trans_item_tr.translationId = this.item.translationId_tr;
            trans_item_tr.locale = "tr";
            trans_item_tr.name = this.item.name_tr;
            trans_item_tr.description = this.item.description_tr;
            trans_item_tr.locationBriefDescription = this.item.locationBriefDescription_tr;
            trans_item_tr.brief = this.item.brief_tr;
            trans_item_tr.shareURL = this.item.shareURL_tr;
            newItem.translations.push(trans_item_tr);
            var trans_item_en = {};
            trans_item_en.translationId = this.item.translationId_en;
            trans_item_en.locale = "en";
            trans_item_en.name = this.item.name_en;
            trans_item_en.description = this.item.description_en;
            trans_item_en.locationBriefDescription = this.item.locationBriefDescription_en;
            trans_item_en.brief = this.item.brief_en;
            trans_item_en.shareURL = this.item.shareURL_en;
            newItem.translations.push(trans_item_en);

            newItem.categories = this.item.categories;
            return newItem;


        },


        newItem() {
            this.creating = true;
            this.editing = false;
        },


        async editItem(itemId) {
            this.creating = false;
            this.editing = true;
            await this.fetchItem(itemId);
        },

        async fetchItem(itemId) {
            this.loading = true;
            let response = await getShop(itemId);
            if (response) {
                this.assignDataModel(response);
            }
            this.loading = false;
        },

        RGBAToHexA(rgba, forceRemoveAlpha = false) {
            if (!rgba)
                return '#0b1d4c';
            else {
                try {
                    return "#" + rgba.replace(/^rgba?\(|\s+|\)$/g, '') // Get's rgba / rgb string values
                        .split(',') // splits them at ","
                        .filter((string, index) => !forceRemoveAlpha || index !== 3)
                        .map(string => parseFloat(string)) // Converts them to numbers
                        .map((number, index) => index === 3 ? Math.round(number * 255) : number) // Converts alpha to 255 number
                        .map(number => number.toString(16)) // Converts numbers to hex
                        .map(string => string.length === 1 ? "0" + string : string) // Adds 0 when length of one number is 1
                        .join("") // Puts the array to togehter to a string
                } catch {
                    return '#0b1d4c';
                }

            }
        },
    }
}
</script>

<style scoped>
.media-container-left {
    display: flex;
    float: left;
    width: 400px;
    max-width: 400px;
    max-height: 300px;
    margin-left: 24px;
    margin-bottom: 48px;
    min-height: 300px;
    background-color: var(--media-background);
    border: solid 1px var(--media-border);
}

.media-container-left img {
    object-fit: fill;
    cursor: pointer;
}

.cont {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    grid-auto-rows: 40px;
    grid-auto-columns: max-content;
    align-items: start;
}

.cont .md-button {
    display: table-footer-group;
    width: 100%;
    height: 39px;
    justify-content: center !important;
    text-align: center !important;
}


.media-container-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 394px;
    max-width: 394px;
    min-width: 20px;
    height: 67px;
    max-height: 67px;
    min-height: 67px;
    background-color: var(--media-background);
    border: solid 1px var(--media-border);
    overflow: hidden;
}

.media-container-logo img {
    object-fit: fill;
    cursor:pointer;
}
</style>