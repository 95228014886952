<template>
    <ValidationObserver tag="form" ref="form" v-slot="{ errors }">
        <div class="md-layout form-layout">

            <!-- Button toolbar -->
            <div class="md-layout-item md-size-100 form-toolbar">
                <h3 class="page-title">
                    <span v-if="creating">{{ $t("routes.step_create.title") }}</span>
                    <span v-if="editing">{{ $t("routes.step_edit.title") }} - {{ item.title }}</span>
                </h3>
                <div class="form-buttons">
                    <md-progress-spinner v-if="loading" :md-diameter="30" :md-stroke="3" md-mode="indeterminate">
                    </md-progress-spinner>
                    <md-button type="submit" class="md-primary md-round btn-medium" @click.prevent="save()">
                        {{ $t("save") }}
                    </md-button>
                </div>
            </div>
            <!-- Order -->
            <div class="md-layout-item md-size-40">
                <md-field>
                    <md-select v-model="item.order" :disabled="loading" :placeholder="$t('label.order')">
                        <md-option v-for="(order, index) in orders" :key="index" :value="order.value">
                            {{ order.text }}
                        </md-option>
                    </md-select>
                    <span class="md-error">
                        {{ errorOrderMessage }}
                    </span>
                </md-field>
            </div>
            <div class="md-layout-item md-size-10"></div>
            <!-- Enabled -->
            <div class="md-layout-item md-size-50">
                <div class="field-switch">
                    <Span>{{ $t("label.enabled") }}</Span>
                    <md-switch id="switchEnabled" v-model="item.enabled" :disabled="loading">
                    </md-switch>
                </div>
            </div>
            <!-- flight  direction -->
            <div class="md-layout-item md-size-40">
                <md-field>
                    <md-select v-model="item.flightDirection" :disabled="loading"
                        :placeholder="$t('label.step_flight_direction')">
                        <md-option :value="'N'">
                            {{ $t("None") }}
                        </md-option>
                        <md-option :value="'D'">
                            {{ $t("Departures") }}
                        </md-option>
                        <md-option :value="'A'">
                            {{ $t("Arrivals") }}
                        </md-option>
                    </md-select>
                    <span class="md-error">
                        {{ errorFlightDirectionMessage }}
                    </span>
                </md-field>
            </div>
            <div class="md-layout-item md-size-10"></div>

            <!-- flight  international status -->
            <div class="md-layout-item md-size-50">
                <md-field>
                    <md-select v-model="item.internationalStatus" :disabled="loading"
                        :placeholder="$t('label.step_international_status')">
                        <md-option :value="'N'">
                            {{ $t("None") }}
                        </md-option>
                        <md-option :value="'I'">
                            {{ $t("International") }}
                        </md-option>
                        <md-option :value="'D'">
                            {{ $t("Domestic") }}
                        </md-option>
                    </md-select>
                    <span class="md-error">
                        {{ errorInternationalStatusMessage }}
                    </span>
                </md-field>
            </div>

            <!-- media id -->
            <div class="md-layout-item md-size-45">
                <div class="md-layout">
                    <div>
                        <md-icon class="md-42">iconimage_search</md-icon>
                    </div>
                    <div class="md-layout-item md-size-65">
                        <md-field class="image-field">
                            <label class="label-images" v-if="!item.media || item.media.fileName == ''">{{
                                $t("label.step_image") }}</label>
                            <label v-if="!item.media || item.media.fileName == ''">{{ $t("label.select_image") }}</label>
                            <label v-else>{{ $t("label.step_image") }}</label>
                            <md-input readonly id="mediaInput" v-model="item.media.fileName" :disabled="true"></md-input>
                            <md-button v-if="item.media && item.media.fileName != ''" class="btn-floating"
                                @click="clearMedia()">
                                <md-icon>close</md-icon>
                            </md-button>
                            <span class="md-error">
                                {{ errorMediaMessage }}
                            </span>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-20">
                        <md-button @click="openMediaModal()" class="md-primary md-round btn-medium">
                            {{ $t("select_file") }}
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="md-layout-item md-size-10"></div>
            <!-- media image preview -->
            <div class="md-layout-item md-size-45 centered">
                <div class="media-container-step">
                    <md-icon v-if="!item.media || item.media.imageURL == ''" class="md-size-2x">image</md-icon>
                    <img v-else :src="item.media && item.media.imageURL" :alt="item.imageAlt"
                        @click.prevent="viewImage(item.media && item.media.imageURL)" />
                </div>
            </div>


            <!-- Separator localizations -->
            <div class="md-layout-item md-size-100">
                <div class="Group-Header">
                    <span class="Luggage-information">
                        {{ $t("label.language_information") }}
                    </span>
                    <div class="Path"></div>
                </div>
            </div>

            <!-- Translations -->
            <md-card class="translations-card">
                <md-card-content>
                    <md-tabs class="translations-tabs">
                        <md-tab id="tab-tr" :md-label="$t('languages.tr')" :md-icon="getLanguageIcon('tr', errors)">
                            <!-- title tr -->
                            <div class="md-layout-item md-size-100">
                                <md-field :class="[
                                    { 'md-valid': errors['title_tr'] && !errors['title_tr'][0] && touchedFields.title_tr },
                                    { 'md-error': errors['title_tr'] && errors['title_tr'][0] }
                                ]">
                                    <label for="text">{{ $t('label.step_title') }}</label>
                                    <validation-provider name='title_tr' rules="required" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-textarea :md-autogrow="false" f v-model="title_tr" name="title_tr"
                                            :placeholder="$t('label.step_title')" :disabled="loading">
                                        </md-textarea>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                            <!-- description tr -->
                            <div class="md-layout-item md-size-100">
                                <md-field :class="[
                                    { 'md-valid': errors['description_tr'] && !errors['description_tr'][0] && touchedFields.description_tr },
                                    { 'md-error': errors['description_tr'] && errors['description_tr'][0] }
                                ]">
                                    <label for="text">{{ $t('label.step_description') }}</label>
                                    <validation-provider name='description_tr'  v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-textarea :md-autogrow="false" f v-model="description_tr" name="description_tr"
                                            :placeholder="$t('label.step_description')" :disabled="loading">
                                        </md-textarea>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                            <!-- nextButtonTitle tr -->
                            <div class="md-layout-item md-size-100">
                                <md-field :class="[
                                    { 'md-valid': errors['nextButtonTitle_tr'] && !errors['nextButtonTitle_tr'][0] && touchedFields.nextButtonTitle_tr },
                                    { 'md-error': errors['nextButtonTitle_tr'] && errors['nextButtonTitle_tr'][0] }
                                ]">
                                    <label for="text">{{ $t('label.step_nextButtonTitle') }}</label>
                                    <validation-provider name='nextButtonTitle_tr' 
                                        v-slot="{ errors, touched }" mode="aggressive">
                                        <md-textarea :md-autogrow="false" f v-model="nextButtonTitle_tr"
                                            name="nextButtonTitle_tr" :placeholder="$t('label.step_nextButtonTitle')"
                                            :disabled="loading">
                                        </md-textarea>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>


                        </md-tab>
                        <md-tab id="tab-en" :md-label="$t('languages.en')" :md-icon="getLanguageIcon('en', errors)">
                            <!-- title en -->
                            <div class="md-layout-item md-size-100">
                                <md-field :class="[
                                    { 'md-valid': errors['title_en'] && !errors['title_en'][0] && touchedFields.title_en },
                                    { 'md-error': errors['title_en'] && errors['title_en'][0] }
                                ]">
                                    <label for="text">{{ $t('label.step_title') }}</label>
                                    <validation-provider name='title_en' rules="required" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-textarea :md-autogrow="false" f v-model="title_en" name="title_en"
                                            :placeholder="$t('label.step_title')" :disabled="loading">
                                        </md-textarea>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                            <!-- description en -->
                            <div class="md-layout-item md-size-100">
                                <md-field :class="[
                                    { 'md-valid': errors['description_en'] && !errors['description_en'][0] && touchedFields.description_en },
                                    { 'md-error': errors['description_en'] && errors['description_en'][0] }
                                ]">
                                    <label for="text">{{ $t('label.step_description') }}</label>
                                    <validation-provider name='description_en'  v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-textarea :md-autogrow="false" f v-model="description_en" name="description_en"
                                            :placeholder="$t('label.step_description')" :disabled="loading">
                                        </md-textarea>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                            <!-- nextButtonTitle en -->
                            <div class="md-layout-item md-size-100">
                                <md-field :class="[
                                    { 'md-valid': errors['nextButtonTitle_en'] && !errors['nextButtonTitle_en'][0] && touchedFields.nextButtonTitle_en },
                                    { 'md-error': errors['nextButtonTitle_en'] && errors['nextButtonTitle_en'][0] }
                                ]">
                                    <label for="text">{{ $t('label.step_nextButtonTitle') }}</label>
                                    <validation-provider name='nextButtonTitle_en' 
                                        v-slot="{ errors, touched }" mode="aggressive">
                                        <md-textarea :md-autogrow="false" f v-model="nextButtonTitle_en"
                                            name="nextButtonTitle_en" :placeholder="$t('label.step_nextButtonTitle')"
                                            :disabled="loading">
                                        </md-textarea>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>

                        </md-tab>
                    </md-tabs>
                </md-card-content>
            </md-card>
            <!-- Media Modal -->
            <media-modal :showProp="showMediaModal" @apply="applyMedia" @close="closeMediaModal()"></media-modal>
        </div>
    </ValidationObserver>
</template>

<script>
import MediaModal from "@/components/Modals/Media";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import localeMixin from "@/mixins/locale";
import s3Mixin from "@/mixins/s3";
import {
    getStep,
    createStep,
    updateStep,
} from "@/services/step";

export default {
    mixins: [localeMixin, s3Mixin],
    components: {
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
        MediaModal,

    },
    data() {
        return {
            loading: false,
            creating: false,
            editing: false,
            imagesLightbox: [],
            orders: [],
            touchedFields: {
                title_en: false,
                title_tr: false,
            },
            item: {
                order: 0,
                title: "",
                enabled: false,
                flightDirection: 'N',
                internationalStatus: 'N',
                mediaId: null,
                imageURL: "",
                fileName: "",
                imageAlt: "",
                media: {
                    fileName: "", imageURL: "", imageAlt: ""
                },
                translations: [
                    {
                        locale: "en",
                        title: "",
                        description:"",
                        nextButtonTitle:"",
                    },
                    {
                        locale: "tr",
                        title: "",
                        description:"",
                        nextButtonTitle:"",
                    }
                ],
            },
            title_en: "",
            title_tr: "",
            description_en: "",
            description_tr: "",
            nextButtonTitle_en: "",
            nextButtonTitle_tr: "",
            errorFlightDirectionMessage: "",
            errorInternationalStatusMessage: "",
            errorOrderMessage: "",
            errorMediaMessage: "",
            showMediaModal: false,
        };
    },

    created: async function () {

        let stepId = this.$route.params.stepId;
        if (stepId) {
            this.editing = true;
            await this.fetchItem(stepId);
        } else {
            let lastOrder = this.$route.params.lastOrder;
            if (lastOrder) {
                this.item.order = lastOrder;
            }

            this.creating = true;
        }
    },

    async mounted() {
        this.fillOrdersArray();
    },

    methods: {

        viewImage(imageUrl) {
            this.imagesLightbox = [];
            this.$imageViewer.images(this.imagesLightbox);
            const image = {
                url: imageUrl,
            };
            this.imagesLightbox.push(image);
            this.$imageViewer.index(0);
            this.$imageViewer.show();
        },

        fillOrdersArray() {
            let min = 0;
            let max = 50;
            this.orders = [];
            for (let i = min; i <= max; i++) {
                if (i == 0) {
                    let z = { value: i, text: this.$t('label.order_default') };
                    this.orders.push(z);
                } else {
                    let b = { value: i, text: i.toString() };
                    this.orders.push(b);
                }
            }
        },

        assignDataModel(item) {
            this.item = item;
            if (this.item.mediaId == null) {
                this.item.mediaId = 0;
                this.item.imageURL = "";
                this.item.fileName = "";
                this.item.imageAlt = "";
                this.item.media = {
                    fileName: "", imageURL: "", imageAlt: ""
                };
            } else {
                this.item.media = {
                    fileName: this.item.fileName, imageURL: this.item.imageURL, imageAlt: this.item.imageAlt
                };
            }
            this.item.enabled = this.item.enabled == 1 ? true : false;
            this.putItemTranslations();
        },

        getItemTranslations() {
            let translation_en = this.item.translations.find(({ locale }) => locale == "en");
            if (translation_en) {
                translation_en.title = this.title_en;
                translation_en.description = this.description_en;
                translation_en.nextButtonTitle = this.nextButtonTitle_en;
            } else {
                translation_en = {
                    locale: "en",
                    title: this.title_en,
                    description: this.description_en,
                    nextButtonTitle: this.nextButtonTitle_en
                }
            }
            let translation_tr = this.item.translations.find(({ locale }) => locale == "tr");
            if (translation_tr) {
                translation_tr.title = this.title_tr;
                translation_tr.description = this.description_tr;
                translation_tr.nextButtonTitle = this.nextButtonTitle_tr;
            } else {
                translation_tr = {
                    locale: "tr",
                    title: this.title_tr,
                    description : this.description_tr,
                    nextButtonTitle: this.nextButtonTitle_tr
                }
            }
            const translations = [translation_en, translation_tr];
            this.item.translations = translations;
        },

        putItemTranslations() {
            let translation_en = this.item.translations.find(({ locale }) => locale == "en");
            if (translation_en) {
                this.title_en = translation_en.title;
                this.description_en = translation_en.description;
                this.nextButtonTitle_en = translation_en.nextButtonTitle;
            }
            let translation_tr = this.item.translations.find(({ locale }) => locale == "tr");
            if (translation_tr) {
                this.title_tr = translation_tr.title;
                this.description_tr = translation_tr.description;
                this.nextButtonTitle_tr = translation_tr.nextButtonTitle;
            }
            const translations = [translation_en, translation_tr];
            this.item.translations = translations;
        },

        async fetchItem(itemId) {
            this.loading = true;
            let response = await getStep(itemId);
            if (response) {
                this.assignDataModel(response);
            }
            this.loading = false;
        },

        applyMedia(media) {
            this.item.media = media;
            this.item.mediaId = media.mediaId;
            this.item.imageURL = media.imageURL;
            this.item.fileName = media.fileName;
            this.item.imageAlt = media.imageAlt;
            this.errorMediaMessage = "";
        },

        clearMedia() {

            this.item.media = { fileName: "", imageURL: "", imageAlt: "" };
            this.item.mediaId = 0;
            this.item.imageURL = this.item.media.imageURL;
            this.item.fileName = this.item.media.fileName;
            this.item.imageAlt = this.item.media.imageAlt;
            this.errorMediaMessage = "";
        },

        openMediaModal() {
            this.showMediaModal = true;
        },

        closeMediaModal() {
            this.showMediaModal = false;
            if (this.item.mediaId == 0) {
                this.errorMediaMessage = this.$t('validation.required', { _field_: this.$t('label.step_image') });
            }
        },

        async save() {
            this.loading = true;

            valid = true;

            this.errorOrderMessage = "";
            this.errorFlightDirectionMessage = "";
            this.errorInternationalStatusMessage = "";


            if (this.item.order == 0) {
                valid = false;
                this.errorOrderMessage = this.$t('validation.at_least_one_value', { _field_: this.$t('label.dine_order') });
            }

            if (this.item.flightDirection == 'N') {
                valid = false;
                this.errorFlightDirectionMessage = this.$t('validation.at_least_one_value', { _field_: this.$t('label.step_flight_direction') });
            }

            if (this.item.internationalStatus == 'N') {
                valid = false;
                this.errorInternationalStatusMessage = this.$t('validation.at_least_one_value', { _field_: this.$t('label.step_international_status') });
            }

            var valid = await this.$refs.form.validate();



            if (valid) {

                this.getItemTranslations();

                var newItem = JSON.parse(JSON.stringify(this.item));
                newItem.enabled = Number(newItem.enabled);
                if (newItem.mediaId == 0) {
                    newItem.mediaId = null;
                }

                if (this.creating) {
                    const response = await createStep(newItem);
                    if (response) {
                        this.$noty.success(this.$t("success.saved"));
                        this.$router.push({ name: this.$i18n.locale + '.steps' });
                    } else {
                        this.$noty.error(this.$t("error.saved"));
                    }
                } else if (this.editing) {
                    const response = await updateStep(newItem);
                    if (response) {
                        this.$noty.success(this.$t("success.saved"));
                    } else {
                        this.$noty.error(this.$t("error.saved"));
                    }
                }
            } else {
                this.$noty.error(this.$t('error.saved'));
            }

            this.loading = false;
        },
    },
}
</script>

<style scoped>
.media-container-step {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 394px;
    max-width: 394px;
    min-width: 20px;
    height: 67px;
    max-height: 67px;
    min-height: 67px;
    background-color: var(--media-background);
    border: solid 1px var(--media-border);
    overflow: hidden;
}

.media-container-step img {
    object-fit: fill;
    cursor: pointer;
}
</style>