<template>
    <md-toolbar md-elevation="0" class="md-transparent top-nav-bar" :class="{
        'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute
    }">
        <div class="md-toolbar-row">
            <!-- <div class="md-toolbar-section-start">
                <h3 class="md-title">{{ pageTitle }}</h3>
            </div> -->
            <div class="md-toolbar-section-end">
                <md-button class="md-just-icon md-round md-simple md-toolbar-toggle"
                    :class="{ toggled: $sidebar.showSidebar }" @click="toggleSidebar">
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </md-button>
                <div class="md-collapse">
                    <!-- <div class="md-autocomplete">
                        <md-autocomplete class="search" v-model="selectedEmployee" :md-options="employees" :md-open-on-focus="false">
                            <label v-if="$route.meta.rtlActive">بحث...</label>
                            <label v-else>Search...</label>
                        </md-autocomplete>
                    </div> -->
                    <!-- <md-list>
                        <md-list-item :href="getRouteHref('dashboard')">
                            <i class="material-icons">dashboard</i>
                            <p class="hidden-lg hidden-md">Dashboard</p>
                        </md-list-item>
                        <li class="md-list-item">
                            <a href="#/components/notifications" class="md-list-item-router md-list-item-container md-button-clean dropdown">
                                <div class="md-list-item-content">
                                    <drop-down direction="down">
                                        <md-button slot="title" class="md-button md-just-icon md-simple" data-toggle="dropdown">
                                            <md-icon>notifications</md-icon>
                                            <span class="notification">5</span>
                                            <p class="hidden-lg hidden-md">Notifications</p>
                                        </md-button>
                                        <ul class="dropdown-menu dropdown-menu-right">
                                            <li><a href="#">Mike John responded to your email</a></li>
                                            <li><a href="#">You have 5 new tasks</a></li>
                                            <li><a href="#">You're now friend with Andrew</a></li>
                                            <li><a href="#">Another Notification</a></li>
                                            <li><a href="#">Another One</a></li>
                                        </ul>
                                    </drop-down>
                                </div>
                            </a>
                        </li>
                        <md-list-item :href="getRouteHref('profile')">
                            <i class="material-icons">person</i>
                            <p class="hidden-lg hidden-md">Profile</p>
                        </md-list-item>
                    </md-list> -->
                    <p class="p-user">
                        {{ this.currentUser.alias }}
                    </p>
                    <div class="vl"></div>
                    <md-list-item @click="showConfirm = true" class="logout-item">
                        <p class="mr-3">{{ $t("logout") }}</p>
                        <i class="material-icons">logout</i>
                    </md-list-item>
                </div>
            </div>
        </div>
        <md-modal v-if="showConfirm" @close="showConfirm = false">
            <h3 slot="header">
                {{ $t("confirm") }}
            </h3>
            <div slot="body">
                {{ $t("confirm_messages.logout") }}
            </div>
            <div slot="footer" class="modal-footer">
                <md-button @click="showConfirm = false" class="md-danger md-round">
                    {{ $t("cancel") }}
                </md-button>
                <md-button @click="logout" class="md-primary md-round">
                    {{ $t("logout") }}
                </md-button>
            </div>
        </md-modal>
    </md-toolbar>
</template>
<script>
import linkMixin from "../../../mixins/link";
import authMixin from "../../../mixins/auth";
import { Auth } from 'aws-amplify';
export default {
    mixins: [linkMixin, authMixin],
    computed: {
        pageTitle: function () {
            let route = this.$route.name.substr(3);
            return this.$t('routes.' + route + '.title');
        }
    },
    data() {
        return {
            showConfirm: false,
            // selectedEmployee: "",
            // employees: [
            //     "Jim Halpert",
            //     "Dwight Schrute",
            //     "Michael Scott",
            //     "Pam Beesly",
            //     "Angela Martin",
            //     "Kelly Kapoor",
            //     "Ryan Howard",
            //     "Kevin Malone"
            // ]
        };
    },
    methods: {
        toggleSidebar() {
            this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
        },
        minimizeSidebar() {
            if (this.$sidebar) {
                this.$sidebar.toggleMinimize();
            }
        },
        async logout() {
            await Auth.signOut();
            this.$router.push({ name: this.$i18n.locale + '.login' });
        }
    }
};
</script>

<style scoped>
.logout-item .md-list-item-content {
    gap: 10px;
}

.logout-item i{
    margin-left: 12px; 
}

.vl {
  border-left: 0.5px solid lightgray;
  height: 35px;
  margin-left: 24px;
  margin-top:22px;
}

.p-user {
    font-size: 14px;
    text-align: right;
    font-weight:light;
    vertical-align: top;
    margin-top: 24px;
    color: #cf2e2e;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
/* 0014346: Texto superpuesto al pop-up */
.top-nav-bar {
  z-index: 100 !important;
}
</style>