import { SUPPORTED_LOCALES } from "../config";

export default {
  methods: {
    // Returns locale configuration
    getLocaleId(code) {
      let locale = SUPPORTED_LOCALES.find((loc) => loc.code === code);
      if (locale) {
        return locale.id;
      }
    },

    configDatePickerLocale() {
      if (this.$i18n.locale == "es") {
        this.$material.locale = {
          startYear: 1900,
          endYear: 2099,
          dateFormat: "dd/MM/yyyy",
          days: [
            "Domingo",
            "Lunes",
            "Martes",
            "Miércoles",
            "Jueves",
            "Viernes",
            "Sábado",
          ],
          shortDays: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
          shorterDays: ["D", "L", "M", "Mi", "J", "V", "S"],
          months: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
          ],
          shortMonths: [
            "Ene",
            "Feb",
            "Mar",
            "Abr",
            "May",
            "Jun",
            "Jul",
            "Ago",
            "Sept",
            "Oct",
            "Nov",
            "Dic",
          ],
          shorterMonths: [
            "E",
            "F",
            "M",
            "A",
            "M",
            "Jun",
            "Jul",
            "A",
            "Se",
            "O",
            "N",
            "D",
          ],
          firstDayOfAWeek: 0,
          cancel: "Cancelar",
          confirm: "Ok",
        };
      }

      if (this.$i18n.locale == "en") {
        this.$material.locale = {
          startYear: 1900,
          endYear: 2099,
          dateFormat: "dd/MM/yyyy",
          days: [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ],
          shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          shorterDays: ["D", "L", "M", "My", "J", "V", "S"],
          months: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ],
          shortMonths: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sept",
            "Oct",
            "Nov",
            "Dec",
          ],
          shorterMonths: [
            "E",
            "F",
            "M",
            "A",
            "M",
            "Jun",
            "Jul",
            "A",
            "Se",
            "O",
            "N",
            "D",
          ],
          firstDayOfAWeek: 0,
          cancel: "Cancel",
          confirm: "Ok",
        };
      }
    },

    getLanguageIcon(lang, errors) {
      lang = lang.toUpperCase();
      let icon = "/img/flags/tabs/" + lang + ".svg";
      for (const [key, value] of Object.entries(errors)) {
        if (key.includes("_" + lang)) {
          if (value.length > 0) {
            icon = "warning";
          }
        }
      }
      return icon;
    },
  },
};
