<template>
    <div>
        <md-dialog :md-close-on-esc="false" :md-active.sync="show" :md-click-outside-to-close="false"
            class="modal-gallery">
            <md-dialog-title class="modal-media-header">
                <h3>
                    {{ name }} - {{ $t("gallery") }}
                    <md-progress-spinner v-if="loading" :md-diameter="20" :md-stroke="1" md-mode="indeterminate">
                    </md-progress-spinner>
                </h3>
                <md-button @click="closeModal()" class="
                        md-primary md-just-icon md-round md-simple
                        modal-close-btn
                    ">
                    <md-icon class="md-size-2x">close</md-icon>
                </md-button>
            </md-dialog-title>
            <md-dialog-content>
                <!-- Gallery List -->
                <div v-if="!creating && !editing">
                    <div class="md-layout-item md-size-100 inline-toolbar">
                        <md-field class="search-input">
                            <md-icon class="search-icon">image_search</md-icon>
                            <label>{{ $t("label.search_filename") }}</label>
                            <md-input v-model="search"  @keyup.enter="filter()" ></md-input>
                            <md-button v-if="search" class="btn-floating" @click="clearFilter()">
                                <md-icon>close</md-icon>
                            </md-button>
                        </md-field>
                        <md-button class="md-primary md-round btn-big" @click="filter()">
                            {{ $t("search") }}
                        </md-button>
                        <md-button @click="newItem()" class="md-primary md-round btn-big">
                            {{ $t("new") }}
                        </md-button>
                    </div>

                    <div v-if="Items.length == 0" table-header-color="blue"
                        class="md-content md-table md-theme-default">
                        <md-table>
                            <thead>
                                <tr><!---->
                                    <th class="md-table-head md-sortable md-sorted md-sorted-desc">
                                        <div class="md-table-head-container md-ripple">
                                            <div class="md-table-head-label">
                                                {{ $t('label.file_name') }}
                                                <!---->
                                            </div>
                                        </div>
                                    </th>
                                    <th class="md-table-head">
                                        <div class="md-table-head-container md-ripple md-disabled">
                                            <div class="md-table-head-label"><!---->
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="md-table-row">
                                    <td class="md-table-cell" style="width: 100%;">
                                        {{ $t('label.no_records') }}
                                    </td>
                                    <td class="md-table-cell" style="width: 100%;">
                                    </td>
                                </tr>
                            </tbody>
                        </md-table>
                    </div>

                    <md-table v-if="Items.length > 0" table-header-color="blue" v-model="Items"
                        :md-sort-order.sync="currentSortOrder" :md-sort.sync="currentSort" :md-sort-fn="customSort">
                        <md-table-row slot="md-table-row" slot-scope="{ item }">
                            <md-table-cell md-sort-by="order" :md-label="$t('label.order')">
                                {{ item.order }}
                            </md-table-cell>
                            <md-table-cell md-sort-by="filename" :md-label="$t('label.file_name')">
                                {{ item.fileName }}
                            </md-table-cell>
                            <md-table-cell class="table-cell-actions">
                                <button class="btn-icon" @click.prevent="viewImage(item.imageURL)">
                                    <md-icon>visibility</md-icon>
                                </button>
                                <button class="btn-icon" @click="editItem(item.galleryItemId)">
                                    <md-icon>edit</md-icon>
                                </button>
                                <button class="btn-icon" @click="confirmDelete(item)">
                                    <md-icon>delete</md-icon>
                                </button>
                            </md-table-cell>
                        </md-table-row>
                    </md-table>
                    <!-- Pagination -->
                    <div class="pagination-container">
                        <md-pagination v-model="page" :noArrows="false" :perPage="limit" :total="total" />
                    </div>
                    <md-modal v-if="showConfirm" @close="showConfirm = false">
                        <h3 slot="header">
                            {{ $t("confirm") }}
                        </h3>
                        <div slot="body">
                            {{ $t("confirm_messages.delete") }}
                        </div>
                        <div slot="footer" class="modal-footer">
                            <md-button @click="showConfirm = false" class="md-danger md-round">
                                {{ $t("cancel") }}
                            </md-button>
                            <md-button @click="deleteItem()" class="md-primary md-round">
                                {{ $t("delete") }}
                            </md-button>
                        </div>
                    </md-modal>
                </div>
                <!--Gallery Detail-->
                <div v-else>
                    <ValidationObserver tag="form" ref="form">
                        <div class="md-layout form-layout">
                            <div class="md-layout-item md-size-50">
                                <!-- Order -->
                                <div class="md-layout-item md-size-40">
                                    <md-field>
                                        <md-select v-model="item.order" :disabled="loading"
                                            :placeholder="$t('label.dine_order')">
                                            <md-option v-for="(order, index) in orders" :key="index"
                                                :value="order.value">
                                                {{ order.text }}
                                            </md-option>
                                        </md-select>
                                        <span class="md-error">
                                            {{ errorOrderMessage }}
                                        </span>
                                    </md-field>
                                </div>
                            </div>
                            <!-- image media -->
                            <div class="md-layout-item md-size-50">
                                <div class="form-container">
                                    <!-- Media selector -->
                                    <div class="md-layout">
                                        <div>
                                            <md-icon class="md-42">iconimage_search</md-icon>
                                        </div>
                                        <div class="md-layout-item md-size-70">
                                            <md-field class="image-field">
                                                <label class="label-images" v-if="item.media.fileName == ''">{{
                                                        $t("label.gallery_image")
                                                }}</label>
                                                <label v-if="item.media.fileName == ''">{{ $t("label.gallery_image")
                                                }}</label>
                                                <label v-else>{{ $t("label.gallery_image") }}</label>
                                                <md-input readonly id="mediaInput" v-model="item.media.fileName"
                                                    :disabled="true">
                                                </md-input>
                                                <md-button v-if="item.media.fileName != '' && creating"
                                                    class="btn-floating" @click="clearMedia()">
                                                    <md-icon>close</md-icon>
                                                </md-button>
                                                <span class="md-error">
                                                    {{ errorMediaMessage }}
                                                </span>
                                            </md-field>
                                        </div>
                                        <div class="md-layout-item md-size-20" v-if="creating">
                                            <md-button @click="openMediaModal()" class="md-primary md-round">
                                                {{ $t("select_file") }}
                                            </md-button>
                                        </div>
                                    </div>
                                    <!-- media image preview -->
                                    <div class="image-container">
                                        <md-icon v-if="item.media.imageURL == ''" class="md-size-4x">image</md-icon>
                                        <img v-else :src="item.media.imageURL"
                                            @click.prevent="viewImage(item.media.imageURL)" />

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <md-button @click.prevent="cancel()" class="md-danger md-round" :disabled="loading">
                                {{ $t("cancel") }}
                            </md-button>
                            <md-button v-if="!view" @click.prevent="save()" type="submit" class="md-primary md-round"
                                :disabled="loading">
                                {{ $t("save") }}
                            </md-button>
                        </div>
                    </ValidationObserver>
                </div>
                <!-- Media Modal -->
                <media-modal :showProp="showMediaModal" @apply="applyMedia" @close="closeMediaModal()"></media-modal>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import MediaModal from "@/components/Modals/Media";
import paginationMixin from "@/mixins/pagination";
import localeMixin from "@/mixins/locale";
import {
    getDestinationGalleryItems,
    getDestinationGalleryItem,
    createDestinationGalleryItem,
    updateDestinationGalleryItem,
    deleteDestinationGalleryItem,
    getActivityGalleryItems,
    getActivityGalleryItem,
    createActivityGalleryItem,
    updateActivityGalleryItem,
    deleteActivityGalleryItem,
    getTouristicRegionGalleryItems,
    getTouristicRegionGalleryItem,
    createTouristicRegionGalleryItem,
    updateTouristicRegionGalleryItem,
    deleteTouristicRegionGalleryItem,
    getServiceGalleryItems,
    getServiceGalleryItem,
    createServiceGalleryItem,
    updateServiceGalleryItem,
    deleteServiceGalleryItem
} from "@/services/gallery";

export default {
    props: ["showProp", "parentId", "parentName", "mode"],
    mixins: [paginationMixin, localeMixin],
    components: {
        ValidationObserver,
        MediaModal,
    },
    data() {
        return {
            loading: false,
            show: false,
            view: false,
            name: "",
            creating: false,
            editing: false,
            showConfirm: false,
            Items: [],
            orders: ["1"],
            item: {
                parentId: 0,
                galleryItemId: 0,
                order: 0,
                mediaId: 0,
                fileName: "",
                imageURL: "",
                media: {
                    fileName: "",
                    imageURL: "",
                },
            },
            sort: "",
            search: "",
            currentSort: "order",
            currentSortOrder: "asc",
            showMediaModal: false,
            errorMediaMessage: "",
            errorOrderMessage: "",
            ItemIdToDelete: null,
            imagesLightbox: [],

        };
    },



    watch: {
        page() {
            this.fetchItems();
        },
        async showProp() {
            this.show = this.showProp;
            if (this.show) {
                this.clearFilter();
                this.clearData();
                this.item.parentId = this.parentId;
                this.name = this.parentName;
                this.editing = false;
                this.creating = false;
                this.view = true;
                await this.fetchItems();
            }
        },
    },

    mounted() {
        this.fillOrdersArray();
    },

    methods: {

        viewImage(imageUrl) {
            this.view = true;
            this.imagesLightbox = [];
            this.$imageViewer.images(this.imagesLightbox);
            const image = {
                url: imageUrl,
            };
            this.imagesLightbox.push(image);
            this.$imageViewer.index(0);
            this.$imageViewer.show();
        },

        fillOrdersArray() {
            let min = 0;
            let max = 50;
            this.orders = [];
            for (let i = min; i <= max; i++) {
                if (i == 0) {
                    let z = { value: i, text: this.$t('label.order_default') };
                    this.orders.push(z);
                } else {
                    let b = { value: i, text: i.toString() };
                    this.orders.push(b);
                }
            }
        },

        applyMedia(media) {
            this.item.media = media;
            this.item.mediaId = media.mediaId;
            this.item.imageURL = media.imageURL;
            this.item.fileName = media.fileName;
            this.errorMediaMessage = "";
        },

        clearMedia() {

            this.item.media = { fileName: "", imageURL: "" };
            this.item.mediaId = 0;
        },

        async fetchItems() {
            this.loading = true;
            this.Items = [];
            let response;
            if (this.item.parentId == 0) {
                this.loading = false;
                return;
            }
            this.sort = this.currentSort + "," + this.currentSortOrder;
            switch (this.mode) {
                case "destination":
                    response = await getDestinationGalleryItems(this.item.parentId, this.page, this.limit, this.search, this.sort);
                    break;
                case "activity":
                    response = await getActivityGalleryItems(this.item.parentId, this.page, this.limit, this.search, this.sort);
                    break;
                case "touristicRegion":
                    response = await getTouristicRegionGalleryItems(this.item.parentId, this.page, this.limit, this.search, this.sort);
                    break;
                case "service":
                    response = await getServiceGalleryItems(this.item.parentId, this.page, this.limit, this.search, this.sort);
                    break;
                default:
                    this.$noty.error(this.$t('mode not defined'));
                    break;
            }
            if (response) {
                this.Items = response.result;
                this.total = response.pageInfo.totalPages * this.limit;
            } else {
                this.Items = [];
                this.total = 0;
            }
            this.loading = false;
        },

        async fetchItem(itemId) {
            this.loading = true;
            let response;
            switch (this.mode) {
                case "destination":
                    response = await getDestinationGalleryItem(this.item.parentId, itemId);
                    break;
                case "activity":
                    response = await getActivityGalleryItem(this.item.parentId, itemId);
                    break;
                case "touristicRegion":
                    response = await getTouristicRegionGalleryItem(this.item.parentId, itemId);
                    break;
                case "service":
                    response = await getServiceGalleryItem(this.item.parentId, itemId);
                    break;
                default:
                    this.$noty.error(this.$t('mode not defined'));
                    response = {};
                    break;
            }
            this.assignDataModel(response);
            this.loading = false;
        },

        assignDataModel(item) {

            if (item.galleryItemId) {
                this.item.galleryItemId = item.galleryItemId;
            } else {
                this.item.galleryItemId = 0;
            }

            if (item.order) {
                this.item.order = item.order;
            } else {
                this.item.order = 0;
            }

            if (item.mediaId) {
                this.item.mediaId = item.mediaId;
            } else {
                this.item.mediaId = 0;
            }

            if (item.fileName) {
                this.item.media.fileName = item.fileName;
            } else {
                this.item.media.fileName = "";
            }

            if (item.imageURL) {
                this.item.media.imageURL = item.imageURL;
            } else {
                this.item.media.imageURL = "";
            }

        },

        filter() {
            this.fetchItems();
        },

        clearFilter() {
            this.search = "";
            this.fetchItems();
        },

        clearData() {
            this.item = {
                parentId: 0,
                order: 0,
                mediaId: 0,
                fileName: "",
                media: {
                    fileName: "", imageURL: ""
                },
            };

            this.item.parentId = this.parentId;
        },



        openMediaModal() {
            this.showMediaModal = true;
        },

        closeMediaModal() {
            this.showMediaModal = false;
        },

        closeModal() {
            this.show = false;
            this.$emit("close");
        },

        cancel() {
            this.view = true;
            this.editing = false;
            this.creating = false;
        },

        customSort: function customSort(value) {
            this.fetchItems();
            return value;
        },

        newItem() {
            this.clearData();
            this.creating = true;
            this.editing = false;
            this.view = false;
        },

        editItem(itemId) {
            this.editing = true;
            this.creating = false;
            this.view = false;
            this.clearData();
            this.fetchItem(itemId);
        },

        confirmDelete(Item) {
            this.ItemIdToDelete = Item.galleryItemId;
            this.showConfirm = true;
        },

        async deleteItem() {
            this.loading = true;
            let response;
            switch (this.mode) {
                case "destination":
                    response = await deleteDestinationGalleryItem(this.item.parentId, this.ItemIdToDelete);
                    break;
                case "activity":
                    response = await deleteActivityGalleryItem(this.item.parentId, this.ItemIdToDelete);
                    break;
                case "touristicRegion":
                    response = await deleteTouristicRegionGalleryItem(this.item.parentId, this.ItemIdToDelete);
                    break;
                case "service":
                    response = await deleteServiceGalleryItem(this.item.parentId, this.ItemIdToDelete);
                    break;
                default:
                    this.$noty.error(this.$t('mode not defined'));
                    break;
            }
            if (response.status == "200") {
                this.showConfirm = false;
                this.ItemIdToDelete = null;
                this.$noty.success(this.$t('success.deleted'));
                this.fetchItems();
            } else {
                this.$noty.error(this.$t('error.deleted'));
            }
            this.loading = false;
        },

        async save() {

            var valid = true;
            this.loading = true;
            this.errorMediaMessage = "";
            this.errorOrderMessage = "";

            if (this.item.order == 0) {
                valid = false;
                this.errorOrderMessage = this.$t('validation.at_least_one_value', { _field_: this.$t('label.dine_order') });
            }

            if (this.item.mediaId == null) {
                valid = false;
                this.errorMediaMessage = this.$t('validation.required', { _field_: this.$t('label.product_image') });
            }

            if (valid) {
                let response;

                switch (this.mode) {
                    case "destination":
                        if (this.creating) {
                            response = await createDestinationGalleryItem(this.item.parentId, this.item);
                        } else if (this.editing) {
                            response = await updateDestinationGalleryItem(this.item.parentId, this.item);
                        } break;
                    case "activity":
                        if (this.creating) {
                            response = await createActivityGalleryItem(this.item.parentId, this.item);
                        } else if (this.editing) {
                            response = await updateActivityGalleryItem(this.item.parentId, this.item);
                        } break;
                    case "touristicRegion":
                        if (this.creating) {
                            response = await createTouristicRegionGalleryItem(this.item.parentId, this.item);
                        } else if (this.editing) {
                            response = await updateTouristicRegionGalleryItem(this.item.parentId, this.item);
                        } break;
                    case "service":
                        if (this.creating) {
                            response = await createServiceGalleryItem(this.item.parentId, this.item);
                        } else if (this.editing) {
                            response = await updateServiceGalleryItem(this.item.parentId, this.item);
                        } break;
                    default:
                        this.$noty.error(this.$t('mode not defined'));
                        break;
                }

                if (response) {
                    this.$noty.success(this.$t('success.saved'));
                    this.creating = false;
                    this.editing = false;
                    this.view = true;
                    this.fetchItems();
                } else {
                    this.$noty.error(this.$t('error.saved'));
                }
            } else {
                this.$noty.error(this.$t('error.saved'));
            }
            this.loading = false;
        },


    },
}
</script>

<style scoped>
.form-container {
    padding-left: 25px;
    padding-right: 25px;
}

.md-card-content {
    min-height: 375px;
}

.media-container {
    margin-bottom: 15px;
}

.image-container {
    display: flex;
    float: left;
    justify-content: left;
    padding: 0px;
    width: 300px;
    max-width: 300px;
    max-height: 200px;
    min-height: 200px;
    margin-left: 34px;
    margin-bottom: 48px;
    background-color: var(--media-background);
    border: solid 1px var(--media-border);
}

.image-container img {
    width: 300px;
    height: 200px;
    object-fit: fill;
    cursor: pointer;
}
</style>