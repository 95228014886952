export const COUNTRIES = {
    en: [
        {
            name: "Afghanistan",
            "alpha_2": "AF",
            "country_code": "004",
        },
        {
            name: "Åland Islands",
            "alpha_2": "AX",
            "country_code": "248",
        },
        {
            name: "Albania",
            "alpha_2": "AL",
            "country_code": "008",
        },
        {
            name: "Algeria",
            "alpha_2": "DZ",
            "country_code": "012",
        },
        {
            name: "American Samoa",
            "alpha_2": "AS",
            "country_code": "016",
        },
        {
            name: "Andorra",
            "alpha_2": "AD",
            "country_code": "020",
        },
        {
            name: "Angola",
            "alpha_2": "AO",
            "country_code": "024",
        },
        {
            name: "Anguilla",
            "alpha_2": "AI",
            "country_code": "660",
        },
        {
            name: "Antarctica",
            "alpha_2": "AQ",
            "country_code": "010",
        },
        {
            name: "Antigua and Barbuda",
            "alpha_2": "AG",
            "country_code": "028",
        },
        {
            name: "Argentina",
            "alpha_2": "AR",
            "country_code": "032",
        },
        {
            name: "Armenia",
            "alpha_2": "AM",
            "country_code": "051",
        },
        {
            name: "Aruba",
            "alpha_2": "AW",
            "country_code": "533",
        },
        {
            name: "Australia",
            "alpha_2": "AU",
            "country_code": "036",
        },
        {
            name: "Austria",
            "alpha_2": "AT",
            "country_code": "040",
        },
        {
            name: "Azerbaijan",
            "alpha_2": "AZ",
            "country_code": "031",
        },
        {
            name: "Bahamas",
            "alpha_2": "BS",
            "country_code": "044",
        },
        {
            name: "Bahrain",
            "alpha_2": "BH",
            "country_code": "048",
        },
        {
            name: "Bangladesh",
            "alpha_2": "BD",
            "country_code": "050",
        },
        {
            name: "Barbados",
            "alpha_2": "BB",
            "country_code": "052",
        },
        {
            name: "Belarus",
            "alpha_2": "BY",
            "country_code": "112",
        },
        {
            name: "Belgium",
            "alpha_2": "BE",
            "country_code": "056",
        },
        {
            name: "Belize",
            "alpha_2": "BZ",
            "country_code": "084",
        },
        {
            name: "Benin",
            "alpha_2": "BJ",
            "country_code": "204",
        },
        {
            name: "Bermuda",
            "alpha_2": "BM",
            "country_code": "060",
        },
        {
            name: "Bhutan",
            "alpha_2": "BT",
            "country_code": "064",
        },
        {
            name: "Bolivia (Plurinational State of)",
            "alpha_2": "BO",
            "country_code": "068",
        },
        {
            name: "Bonaire, Sint Eustatius and Saba",
            "alpha_2": "BQ",
            "country_code": "535",
        },
        {
            name: "Bosnia and Herzegovina",
            "alpha_2": "BA",
            "country_code": "070",
        },
        {
            name: "Botswana",
            "alpha_2": "BW",
            "country_code": "072",
        },
        {
            name: "Bouvet Island",
            "alpha_2": "BV",
            "country_code": "074",
        },
        {
            name: "Brazil",
            "alpha_2": "BR",
            "country_code": "076",
        },
        {
            name: "British Indian Ocean Territory",
            "alpha_2": "IO",
            "country_code": "086",
        },
        {
            name: "Brunei Darussalam",
            "alpha_2": "BN",
            "country_code": "096",
        },
        {
            name: "Bulgaria",
            "alpha_2": "BG",
            "country_code": "100",
        },
        {
            name: "Burkina Faso",
            "alpha_2": "BF",
            "country_code": "854",
        },
        {
            name: "Burundi",
            "alpha_2": "BI",
            "country_code": "108",
        },
        {
            name: "Cabo Verde",
            "alpha_2": "CV",
            "country_code": "132",
        },
        {
            name: "Cambodia",
            "alpha_2": "KH",
            "country_code": "116",
        },
        {
            name: "Cameroon",
            "alpha_2": "CM",
            "country_code": "120",
        },
        {
            name: "Canada",
            "alpha_2": "CA",
            "country_code": "124",
        },
        {
            name: "Cayman Islands",
            "alpha_2": "KY",
            "country_code": "136",
        },
        {
            name: "Central African Republic",
            "alpha_2": "CF",
            "country_code": "140",
        },
        {
            name: "Chad",
            "alpha_2": "TD",
            "country_code": "148",
        },
        {
            name: "Chile",
            "alpha_2": "CL",
            "country_code": "152",
        },
        {
            name: "China",
            "alpha_2": "CN",
            "country_code": "156",
        },
        {
            name: "Christmas Island",
            "alpha_2": "CX",
            "country_code": "162",
        },
        {
            name: "Cocos (Keeling) Islands",
            "alpha_2": "CC",
            "country_code": "166",
        },
        {
            name: "Colombia",
            "alpha_2": "CO",
            "country_code": "170",
        },
        {
            name: "Comoros",
            "alpha_2": "KM",
            "country_code": "174",
        },
        {
            name: "Congo",
            "alpha_2": "CG",
            "country_code": "178",
        },
        {
            name: "Congo, Democratic Republic of the",
            "alpha_2": "CD",
            "country_code": "180",
        },
        {
            name: "Cook Islands",
            "alpha_2": "CK",
            "country_code": "184",
        },
        {
            name: "Costa Rica",
            "alpha_2": "CR",
            "country_code": "188",
        },
        {
            name: "Côte d'Ivoire",
            "alpha_2": "CI",
            "country_code": "384",
        },
        {
            name: "Croatia",
            "alpha_2": "HR",
            "country_code": "191",
        },
        {
            name: "Cuba",
            "alpha_2": "CU",
            "country_code": "192",
        },
        {
            name: "Curaçao",
            "alpha_2": "CW",
            "country_code": "531",
        },
        {
            name: "Cyprus",
            "alpha_2": "CY",
            "country_code": "196",
        },
        {
            name: "Czechia",
            "alpha_2": "CZ",
            "country_code": "203",
        },
        {
            name: "Denmark",
            "alpha_2": "DK",
            "country_code": "208",
        },
        {
            name: "Djibouti",
            "alpha_2": "DJ",
            "country_code": "262",
        },
        {
            name: "Dominica",
            "alpha_2": "DM",
            "country_code": "212",
        },
        {
            name: "Dominican Republic",
            "alpha_2": "DO",
            "country_code": "214",
        },
        {
            name: "Ecuador",
            "alpha_2": "EC",
            "country_code": "218",
        },
        {
            name: "Egypt",
            "alpha_2": "EG",
            "country_code": "818",
        },
        {
            name: "El Salvador",
            "alpha_2": "SV",
            "country_code": "222",
        },
        {
            name: "United Kingdom",
            "alpha_2": "GB",
            "country_code": "826",
        },
        {
            name: "Equatorial Guinea",
            "alpha_2": "GQ",
            "country_code": "226",
        },
        {
            name: "Eritrea",
            "alpha_2": "ER",
            "country_code": "232",
        },
        {
            name: "Estonia",
            "alpha_2": "EE",
            "country_code": "233",
        },
        {
            name: "Eswatini",
            "alpha_2": "SZ",
            "country_code": "748",
        },
        {
            name: "Ethiopia",
            "alpha_2": "ET",
            "country_code": "231",
        },
        {
            name: "Falkland Islands (Malvinas)",
            "alpha_2": "FK",
            "country_code": "238",
        },
        {
            name: "Faroe Islands",
            "alpha_2": "FO",
            "country_code": "234",
        },
        {
            name: "Fiji",
            "alpha_2": "FJ",
            "country_code": "242",
        },
        {
            name: "Finland",
            "alpha_2": "FI",
            "country_code": "246",
        },
        {
            name: "France",
            "alpha_2": "FR",
            "country_code": "250",
        },
        {
            name: "French Guiana",
            "alpha_2": "GF",
            "country_code": "254",
        },
        {
            name: "French Polynesia",
            "alpha_2": "PF",
            "country_code": "258",
        },
        {
            name: "French Southern Territories",
            "alpha_2": "TF",
            "country_code": "260",
        },
        {
            name: "Gabon",
            "alpha_2": "GA",
            "country_code": "266",
        },
        {
            name: "Gambia",
            "alpha_2": "GM",
            "country_code": "270",
        },
        {
            name: "Georgia",
            "alpha_2": "GE",
            "country_code": "268",
        },
        {
            name: "Germany",
            "alpha_2": "DE",
            "country_code": "276",
        },
        {
            name: "Ghana",
            "alpha_2": "GH",
            "country_code": "288",
        },
        {
            name: "Gibraltar",
            "alpha_2": "GI",
            "country_code": "292",
        },
        {
            name: "Greece",
            "alpha_2": "GR",
            "country_code": "300",
        },
        {
            name: "Greenland",
            "alpha_2": "GL",
            "country_code": "304",
        },
        {
            name: "Grenada",
            "alpha_2": "GD",
            "country_code": "308",
        },
        {
            name: "Guadeloupe",
            "alpha_2": "GP",
            "country_code": "312",
        },
        {
            name: "Guam",
            "alpha_2": "GU",
            "country_code": "316",
        },
        {
            name: "Guatemala",
            "alpha_2": "GT",
            "country_code": "320",
        },
        {
            name: "Guernsey",
            "alpha_2": "GG",
            "country_code": "831",
        },
        {
            name: "Guinea",
            "alpha_2": "GN",
            "country_code": "324",
        },
        {
            name: "Guinea-Bissau",
            "alpha_2": "GW",
            "country_code": "624",
        },
        {
            name: "Guyana",
            "alpha_2": "GY",
            "country_code": "328",
        },
        {
            name: "Haiti",
            "alpha_2": "HT",
            "country_code": "332",
        },
        {
            name: "Heard Island and McDonald Islands",
            "alpha_2": "HM",
            "country_code": "334",
        },
        {
            name: "Holy See",
            "alpha_2": "VA",
            "country_code": "336",
        },
        {
            name: "Honduras",
            "alpha_2": "HN",
            "country_code": "340",
        },
        {
            name: "Hong Kong",
            "alpha_2": "HK",
            "country_code": "344",
        },
        {
            name: "Hungary",
            "alpha_2": "HU",
            "country_code": "348",
        },
        {
            name: "Iceland",
            "alpha_2": "IS",
            "country_code": "352",
        },
        {
            name: "India",
            "alpha_2": "IN",
            "country_code": "356",
        },
        {
            name: "Indonesia",
            "alpha_2": "ID",
            "country_code": "360",
        },
        {
            name: "Iran (Islamic Republic of)",
            "alpha_2": "IR",
            "country_code": "364",
        },
        {
            name: "Iraq",
            "alpha_2": "IQ",
            "country_code": "368",
        },
        {
            name: "Ireland",
            "alpha_2": "IE",
            "country_code": "372",
        },
        {
            name: "Isle of Man",
            "alpha_2": "IM",
            "country_code": "833",
        },
        {
            name: "Israel",
            "alpha_2": "IL",
            "country_code": "376",
        },
        {
            name: "Italy",
            "alpha_2": "IT",
            "country_code": "380",
        },
        {
            name: "Jamaica",
            "alpha_2": "JM",
            "country_code": "388",
        },
        {
            name: "Japan",
            "alpha_2": "JP",
            "country_code": "392",
        },
        {
            name: "Jersey",
            "alpha_2": "JE",
            "country_code": "832",
        },
        {
            name: "Jordan",
            "alpha_2": "JO",
            "country_code": "400",
        },
        {
            name: "Kazakhstan",
            "alpha_2": "KZ",
            "country_code": "398",
        },
        {
            name: "Kenya",
            "alpha_2": "KE",
            "country_code": "404",
        },
        {
            name: "Kiribati",
            "alpha_2": "KI",
            "country_code": "296",
        },
        {
            name: "Korea (Democratic People's Republic of)",
            "alpha_2": "KP",
            "country_code": "408",
        },
        {
            name: "Korea, Republic of",
            "alpha_2": "KR",
            "country_code": "410",
        },
        {
            name: "Kuwait",
            "alpha_2": "KW",
            "country_code": "414",
        },
        {
            name: "Kyrgyzstan",
            "alpha_2": "KG",
            "country_code": "417",
        },
        {
            name: "Lao People's Democratic Republic",
            "alpha_2": "LA",
            "country_code": "418",
        },
        {
            name: "Latvia",
            "alpha_2": "LV",
            "country_code": "428",
        },
        {
            name: "Lebanon",
            "alpha_2": "LB",
            "country_code": "422",
        },
        {
            name: "Lesotho",
            "alpha_2": "LS",
            "country_code": "426",
        },
        {
            name: "Liberia",
            "alpha_2": "LR",
            "country_code": "430",
        },
        {
            name: "Libya",
            "alpha_2": "LY",
            "country_code": "434",
        },
        {
            name: "Liechtenstein",
            "alpha_2": "LI",
            "country_code": "438",
        },
        {
            name: "Lithuania",
            "alpha_2": "LT",
            "country_code": "440",
        },
        {
            name: "Luxembourg",
            "alpha_2": "LU",
            "country_code": "442",
        },
        {
            name: "Macao",
            "alpha_2": "MO",
            "country_code": "446",
        },
        {
            name: "Madagascar",
            "alpha_2": "MG",
            "country_code": "450",
        },
        {
            name: "Malawi",
            "alpha_2": "MW",
            "country_code": "454",
        },
        {
            name: "Malaysia",
            "alpha_2": "MY",
            "country_code": "458",
        },
        {
            name: "Maldives",
            "alpha_2": "MV",
            "country_code": "462",
        },
        {
            name: "Mali",
            "alpha_2": "ML",
            "country_code": "466",
        },
        {
            name: "Malta",
            "alpha_2": "MT",
            "country_code": "470",
        },
        {
            name: "Marshall Islands",
            "alpha_2": "MH",
            "country_code": "584",
        },
        {
            name: "Martinique",
            "alpha_2": "MQ",
            "country_code": "474",
        },
        {
            name: "Mauritania",
            "alpha_2": "MR",
            "country_code": "478",
        },
        {
            name: "Mauritius",
            "alpha_2": "MU",
            "country_code": "480",
        },
        {
            name: "Mayotte",
            "alpha_2": "YT",
            "country_code": "175",
        },
        {
            name: "Mexico",
            "alpha_2": "MX",
            "country_code": "484",
        },
        {
            name: "Micronesia (Federated States of)",
            "alpha_2": "FM",
            "country_code": "583",
        },
        {
            name: "Moldova, Republic of",
            "alpha_2": "MD",
            "country_code": "498",
        },
        {
            name: "Monaco",
            "alpha_2": "MC",
            "country_code": "492",
        },
        {
            name: "Mongolia",
            "alpha_2": "MN",
            "country_code": "496",
        },
        {
            name: "Montenegro",
            "alpha_2": "ME",
            "country_code": "499",
        },
        {
            name: "Montserrat",
            "alpha_2": "MS",
            "country_code": "500",
        },
        {
            name: "Morocco",
            "alpha_2": "MA",
            "country_code": "504",
        },
        {
            name: "Mozambique",
            "alpha_2": "MZ",
            "country_code": "508",
        },
        {
            name: "Myanmar",
            "alpha_2": "MM",
            "country_code": "104",
        },
        {
            name: "Namibia",
            "alpha_2": "NA",
            "country_code": "516",
        },
        {
            name: "Nauru",
            "alpha_2": "NR",
            "country_code": "520",
        },
        {
            name: "Nepal",
            "alpha_2": "NP",
            "country_code": "524",
        },
        {
            name: "Netherlands",
            "alpha_2": "NL",
            "country_code": "528",
        },
        {
            name: "New Caledonia",
            "alpha_2": "NC",
            "country_code": "540",
        },
        {
            name: "New Zealand",
            "alpha_2": "NZ",
            "country_code": "554",
        },
        {
            name: "Nicaragua",
            "alpha_2": "NI",
            "country_code": "558",
        },
        {
            name: "Niger",
            "alpha_2": "NE",
            "country_code": "562",
        },
        {
            name: "Nigeria",
            "alpha_2": "NG",
            "country_code": "566",
        },
        {
            name: "Niue",
            "alpha_2": "NU",
            "country_code": "570",
        },
        {
            name: "Norfolk Island",
            "alpha_2": "NF",
            "country_code": "574",
        },
        {
            name: "North Macedonia",
            "alpha_2": "MK",
            "country_code": "807",
        },
        {
            name: "Northern Ireland",
            "alpha_2": "GB",
            "country_code": "826",
        },
        {
            name: "Northern Mariana Islands",
            "alpha_2": "MP",
            "country_code": "580",
        },
        {
            name: "Norway",
            "alpha_2": "NO",
            "country_code": "578",
        },
        {
            name: "Oman",
            "alpha_2": "OM",
            "country_code": "512",
        },
        {
            name: "Pakistan",
            "alpha_2": "PK",
            "country_code": "586",
        },
        {
            name: "Palau",
            "alpha_2": "PW",
            "country_code": "585",
        },
        {
            name: "Palestine, State of",
            "alpha_2": "PS",
            "country_code": "275",
        },
        {
            name: "Panama",
            "alpha_2": "PA",
            "country_code": "591",
        },
        {
            name: "Papua New Guinea",
            "alpha_2": "PG",
            "country_code": "598",
        },
        {
            name: "Paraguay",
            "alpha_2": "PY",
            "country_code": "600",
        },
        {
            name: "Peru",
            "alpha_2": "PE",
            "country_code": "604",
        },
        {
            name: "Philippines",
            "alpha_2": "PH",
            "country_code": "608",
        },
        {
            name: "Pitcairn",
            "alpha_2": "PN",
            "country_code": "612",
        },
        {
            name: "Poland",
            "alpha_2": "PL",
            "country_code": "616",
        },
        {
            name: "Portugal",
            "alpha_2": "PT",
            "country_code": "620",
        },
        {
            name: "Puerto Rico",
            "alpha_2": "PR",
            "country_code": "630",
        },
        {
            name: "Qatar",
            "alpha_2": "QA",
            "country_code": "634",
        },
        {
            name: "Réunion",
            "alpha_2": "RE",
            "country_code": "638",
        },
        {
            name: "Romania",
            "alpha_2": "RO",
            "country_code": "642",
        },
        {
            name: "Russian Federation",
            "alpha_2": "RU",
            "country_code": "643",
        },
        {
            name: "Rwanda",
            "alpha_2": "RW",
            "country_code": "646",
        },
        {
            name: "Saint Barthélemy",
            "alpha_2": "BL",
            "country_code": "652",
        },
        {
            name: "Saint Helena, Ascension and Tristan da Cunha",
            "alpha_2": "SH",
            "country_code": "654",
        },
        {
            name: "Saint Kitts and Nevis",
            "alpha_2": "KN",
            "country_code": "659",
        },
        {
            name: "Saint Lucia",
            "alpha_2": "LC",
            "country_code": "662",
        },
        {
            name: "Saint Martin (French part)",
            "alpha_2": "MF",
            "country_code": "663",
        },
        {
            name: "Saint Pierre and Miquelon",
            "alpha_2": "PM",
            "country_code": "666",
        },
        {
            name: "Saint Vincent and the Grenadines",
            "alpha_2": "VC",
            "country_code": "670",
        },
        {
            name: "Samoa",
            "alpha_2": "WS",
            "country_code": "882",
        },
        {
            name: "San Marino",
            "alpha_2": "SM",
            "country_code": "674",
        },
        {
            name: "Sao Tome and Principe",
            "alpha_2": "ST",
            "country_code": "678",
        },
        {
            name: "Saudi Arabia",
            "alpha_2": "SA",
            "country_code": "682",
        },        
        {
            name: "Senegal",
            "alpha_2": "SN",
            "country_code": "686",
        },
        {
            name: "Serbia",
            "alpha_2": "RS",
            "country_code": "688",
        },
        {
            name: "Seychelles",
            "alpha_2": "SC",
            "country_code": "690",
        },
        {
            name: "Sierra Leone",
            "alpha_2": "SL",
            "country_code": "694",
        },
        {
            name: "Singapore",
            "alpha_2": "SG",
            "country_code": "702",
        },
        {
            name: "Sint Maarten (Dutch part)",
            "alpha_2": "SX",
            "country_code": "534",
        },
        {
            name: "Slovakia",
            "alpha_2": "SK",
            "country_code": "703",
        },
        {
            name: "Slovenia",
            "alpha_2": "SI",
            "country_code": "705",
        },
        {
            name: "Solomon Islands",
            "alpha_2": "SB",
            "country_code": "090",
        },
        {
            name: "Somalia",
            "alpha_2": "SO",
            "country_code": "706",
        },
        {
            name: "South Africa",
            "alpha_2": "ZA",
            "country_code": "710",
        },
        {
            name: "South Georgia and the South Sandwich Islands",
            "alpha_2": "GS",
            "country_code": "239",
        },
        {
            name: "South Sudan",
            "alpha_2": "SS",
            "country_code": "728",
        },
        {
            name: "Spain",
            "alpha_2": "ES",
            "country_code": "724",
        },
        {
            name: "Sri Lanka",
            "alpha_2": "LK",
            "country_code": "144",
        },
        {
            name: "Sudan",
            "alpha_2": "SD",
            "country_code": "729",
        },
        {
            name: "Suriname",
            "alpha_2": "SR",
            "country_code": "740",
        },
        {
            name: "Svalbard and Jan Mayen",
            "alpha_2": "SJ",
            "country_code": "744",
        },
        {
            name: "Sweden",
            "alpha_2": "SE",
            "country_code": "752",
        },
        {
            name: "Switzerland",
            "alpha_2": "CH",
            "country_code": "756",
        },
        {
            name: "Syrian Arab Republic",
            "alpha_2": "SY",
            "country_code": "760",
        },
        {
            name: "Taiwan, Province of China",
            "alpha_2": "TW",
            "country_code": "158",
        },
        {
            name: "Tajikistan",
            "alpha_2": "TJ",
            "country_code": "762",
        },
        {
            name: "Tanzania, United Republic of",
            "alpha_2": "TZ",
            "country_code": "834",
        },
        {
            name: "Thailand",
            "alpha_2": "TH",
            "country_code": "764",
        },
        {
            name: "Timor-Leste",
            "alpha_2": "TL",
            "country_code": "626",
        },
        {
            name: "Togo",
            "alpha_2": "TG",
            "country_code": "768",
        },
        {
            name: "Tokelau",
            "alpha_2": "TK",
            "country_code": "772",
        },
        {
            name: "Tonga",
            "alpha_2": "TO",
            "country_code": "776",
        },
        {
            name: "Trinidad and Tobago",
            "alpha_2": "TT",
            "country_code": "780",
        },
        {
            name: "Tunisia",
            "alpha_2": "TN",
            "country_code": "788",
        },
        {
            name: "Turkey",
            "alpha_2": "TR",
            "country_code": "792",
        },
        {
            name: "Turkmenistan",
            "alpha_2": "TM",
            "country_code": "795",
        },
        {
            name: "Turks and Caicos Islands",
            "alpha_2": "TC",
            "country_code": "796",
        },
        {
            name: "Tuvalu",
            "alpha_2": "TV",
            "country_code": "798",
        },
        {
            name: "Uganda",
            "alpha_2": "UG",
            "country_code": "800",
        },
        {
            name: "Ukraine",
            "alpha_2": "UA",
            "country_code": "804",
        },
        {
            name: "United Arab Emirates",
            "alpha_2": "AE",
            "country_code": "784",
        },
        {
            name: "United States of America",
            "alpha_2": "US",
            "country_code": "840",
        },
        {
            name: "United States Minor Outlying Islands",
            "alpha_2": "UM",
            "country_code": "581",
        },
        {
            name: "Uruguay",
            "alpha_2": "UY",
            "country_code": "858",
        },
        {
            name: "Uzbekistan",
            "alpha_2": "UZ",
            "country_code": "860",
        },
        {
            name: "Vanuatu",
            "alpha_2": "VU",
            "country_code": "548",
        },
        {
            name: "Venezuela (Bolivarian Republic of)",
            "alpha_2": "VE",
            "country_code": "862",
        },
        {
            name: "Viet Nam",
            "alpha_2": "VN",
            "country_code": "704",
        },
        {
            name: "Virgin Islands (British)",
            "alpha_2": "VG",
            "country_code": "092",
        },
        {
            name: "Virgin Islands (U.S.)",
            "alpha_2": "VI",
            "country_code": "850",
        },        
        {
            name: "Wallis and Futuna",
            "alpha_2": "WF",
            "country_code": "876",
        },
        {
            name: "Western Sahara",
            "alpha_2": "EH",
            "country_code": "732",
        },
        {
            name: "Yemen",
            "alpha_2": "YE",
            "country_code": "887",
        },
        {
            name: "Zambia",
            "alpha_2": "ZM",
            "country_code": "894",
        },
        {
            name: "Zimbabwe",
            "alpha_2": "ZW",
            "country_code": "716",
        },
    ],
};
