import { API, Auth } from "aws-amplify";
import util from "util";

const getServices = async (page = 1, limit = 10, search = "",sortby="") => {
    let responseData = [];
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const language = process.env.VUE_APP_I18N_LOCALE;
    const airportCode = process.env.VUE_APP_AIRPORT_CODE;
    const path = ["/CMS/service"];
    var headers = {
        "x-api-key": API_KEY,
        "x-app-language": language,
        "x-airport-code": airportCode,
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const queryStringParameters = {
        page: page,
        limit: limit,
        name: search,
        sort:sortby,
    };
    const myInit = {
        headers: headers,
        response: true,
        queryStringParameters: queryStringParameters,
    };
    try {
        var response = await API.get(API_NAME, path, myInit);
        responseData = response.data;
        responseData.status = response.status;

    } catch (error) {
        console.error(
            "Error getting services: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
    return responseData;
};

const getService = async (serviceId) => {
    let responseData = {};
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const language = process.env.VUE_APP_I18N_LOCALE;
    const airportCode = process.env.VUE_APP_AIRPORT_CODE;
    const path = ["/CMS/service/" + serviceId];
    var headers = {
        "x-api-key": API_KEY,
        "x-app-language": language,
        "x-airport-code": airportCode,
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
    };
    try {
        var response = await API.get(API_NAME, path, myInit);
        responseData = response.data;
        responseData.status = response.status;

    } catch (error) {
        console.error(
            "Error getting service detail: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
    return responseData;
};


const createService = async (item) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const language = process.env.VUE_APP_I18N_LOCALE;
    const airportCode = process.env.VUE_APP_AIRPORT_CODE;
    const path = ['/CMS/service/'];
    var headers = {
        "x-api-key": API_KEY,
        "x-app-language": language,
        "x-airport-code": airportCode,
        "Authorization": (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
        body: item,
    };
    try {
        var response = await API.post(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error creating service: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
}

const updateService = async (item) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const language = process.env.VUE_APP_I18N_LOCALE;
    const airportCode = process.env.VUE_APP_AIRPORT_CODE;
    const path = ['/CMS/service/' + item.serviceId];
    var headers = {
        "x-api-key": API_KEY,
        "x-app-language": language,
        "x-airport-code": airportCode,
        "Authorization": (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
        body: item,
    };
    try {
        var response = await API.put(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error updating service: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
}

const deleteService = async (serviceId) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const language = process.env.VUE_APP_I18N_LOCALE;
    const airportCode = process.env.VUE_APP_AIRPORT_CODE;
    const path = ["/CMS/service/" + serviceId];
    var headers = {
        "x-api-key": API_KEY,
        "x-app-language": language,
        "x-airport-code": airportCode,
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    };

    const myInit = {
        headers: headers,
        response: true,
    };
    try {
        var response = await API.del(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error deleting service: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
};

export { 
    getServices, 
    getService,
    createService,
    updateService,
    deleteService,
};
