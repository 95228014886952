<template>
    <ValidationObserver tag="form" ref="form" v-slot="{ errors }">
        <div id="Main" class="md-layout">
            <!-- Button -->
            <div class="md-layout-item md-size-100 form-toolbar">
                <h3 class="page-title" v-if="creating">
                    {{ $t("routes.alert_create.title") }}
                </h3>
                <h3 class="page-title" v-if="editing">
                    {{ $t("routes.alert_edit.title") }}
                    {{ item.title }}
                </h3>
                <md-progress-spinner v-if="loading" :md-diameter="30" :md-stroke="3" md-mode="indeterminate">
                </md-progress-spinner>
                <!-- Save button -->
                <md-button class="md-primary md-round" @click="save()">
                    {{ $t("save") }}
                </md-button>
            </div>
            <!-- Alert level -->
            <div class="md-layout-item md-size-40">
                <md-field>
                    <md-select v-model="item.alertType" :disabled="loading" :placeholder="$t('label.alert_level')">
                        <md-option :value="3">

                            {{ $t("Red") }}

                        </md-option>
                        <md-option :value="2">

                            {{ $t("Orange") }}

                        </md-option>
                        <md-option :value="1">

                            {{ $t("Yellow") }}

                        </md-option>
                    </md-select>
                </md-field>
            </div>
            <div class="md-layout-item md-size-20"></div>
            <!-- Enabled -->
            <div class="md-layout-item md-size-40">
                <div class="field-switch">
                    <Span>{{ $t("label.alert_enabled") }}</Span>
                    <md-switch id="switchEnabled" v-model="item.enabled" :disabled="loading">
                    </md-switch>
                </div>
            </div>
            <!-- from alert date -->
            <div class="md-layout-item md-size-40">
                <md-datepicker v-model="item.fromDate" :md-immediately="true">
                    <label>{{ $t("label.alert_from_date") }}</label>
                    <span class="md-error">
                        {{ errorFromDateMessage }}
                    </span>
                </md-datepicker>
            </div>
            <!-- to alert date -->
            <div class="md-layout-item md-size-40">
                <md-datepicker v-model="item.toDate" :md-immediately="true">
                    <label>{{ $t("label.alert_to_alert_date") }}</label>
                    <span class="md-error">
                        {{ errorToDateMessage }}
                    </span>
                </md-datepicker>
            </div>
            <!-- Separator localizations -->
            <div class="md-layout-item md-size-100">
                <div class="Group-Header">
                    <span class="Luggage-information">
                        {{ $t('label.language_information') }}
                    </span>
                    <div class="Path"></div>
                </div>
            </div>
            <!-- Translations -->
            <md-card class="translations-card">
                <md-card-content>
                    <md-tabs class="translations-tabs">
                        <md-tab id="tab-tr" :md-label="$t('languages.tr')" :md-icon="getLanguageIcon('tr', errors)">
                            <!-- title tr -->
                            <md-field :class="[
                                { 'md-valid': errors['alert_title_tr'] && !errors['alert_title_tr'][0] && touchedFields.alert_title_tr },
                                { 'md-error': errors['alert_title_tr'] && errors['alert_title_tr'][0] }
                            ]">
                                <label for="title">{{ $t('fields.alert_title_tr') }}</label>
                                <validation-provider name='alert_title_tr' rules="required" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="item.alert_title_tr" name="alert_title_tr" type="text"
                                        :placeholder="$t('label.alert_title')" id="alert_title_tr" :disabled="loading">
                                    </md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <!-- text tr -->
                            <md-field :class="[
                                { 'md-valid': errors['alert_text_tr'] && !errors['alert_text_tr'][0] && touchedFields.alert_text_tr },
                                { 'md-error': errors['alert_text_tr'] && errors['alert_text_tr'][0] }
                            ]">
                                <label for="text">{{ $t('fields.alert_text_tr') }}</label>
                                <validation-provider name='alert_text_tr' rules="required" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-textarea :md-autogrow="false" f v-model="item.alert_text_tr" name="alert_text_tr"
                                        :placeholder="$t('label.alert_text')" id="alert_text_tr" :disabled="loading">
                                    </md-textarea>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>

                        </md-tab>
                        <md-tab id="tab-en" :md-label="$t('languages.en')" :md-icon="getLanguageIcon('en', errors)">
                            <!-- title en -->
                            <md-field :class="[
                                { 'md-valid': errors['alert_title_en'] && !errors['alert_title_en'][0] && touchedFields.alert_title_en },
                                { 'md-error': errors['alert_title_en'] && errors['alert_title_en'][0] }
                            ]">
                                <label for="title">{{ $t('fields.alert_title_en') }}</label>
                                <validation-provider name='alert_title_en' rules="required" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="item.alert_title_en" name="alert_title_en" type="text"
                                        :placeholder="$t('label.alert_title')" id="alert_title_en" :disabled="loading">
                                    </md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <!-- text en -->
                            <md-field :class="[
                                { 'md-valid': errors['alert_text_en'] && !errors['alert_text_en'][0] && touchedFields.alert_text_en },
                                { 'md-error': errors['alert_text_en'] && errors['alert_text_en'][0] }
                            ]">
                                <label for="alert_text_en">{{ $t('fields.alert_text_en') }}</label>
                                <validation-provider name='alert_text_en' rules="required" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-textarea :md-autogrow="false" v-model="item.alert_text_en" name="alert_text_en"
                                        :placeholder="$t('label.alert_text')" id="alert_text_en" :disabled="loading">
                                    </md-textarea>


                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                        </md-tab>
                    </md-tabs>
                </md-card-content>
            </md-card>
        </div>
    </ValidationObserver>
</template>

<script>


import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import { getAlert, createAlert, updateAlert } from "@/services/alert";
import localeMixin from "@/mixins/locale";


export default {
    mixins: [localeMixin],
    components: {
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
    },

    data() {
        return {
            creating: false,
            editing: false,
            item: {
                alertId: "",
                fromDate: this.moment().format('DD/MM/yyyy'),
                toDate: this.moment().format('DD/MM/yyyy'),
                alertType: 3,
                enabled: false,
                title: "",
                alert_translationId_tr: 0,
                alert_title_tr: "",
                alert_text_tr: "",
                alert_translationId_en: 1,
                alert_title_en: "",
                alert_text_en: ""
            },
            showMediaModal: false,
            touchedFields: {
                alert_title_tr: false,
                alert_title_en: false,
                alert_text_tr: false,
                alert_text_en: false
            },
            errorFromDateMessage: "",
            errorToDateMessage: "",
            loading: false
        };
    },


    watch: {
        'item.fromDate'() {
            this.changeDate();
            //Watch para cambios de valor en la fecha, el control no funciona
        },
        'item.toDate'() {
            this.changeDate();
        }
    },

    created: async function () {
        let alertId = this.$route.params.alertId;
        this.configDatePickerLocale();
        if (alertId) {
            await this.editItem(alertId);
        } else {
            this.newItem();
        }
    },


    methods: {

        assignDataModel(item) {
            if (item.alertId) {
                this.item.alertId = item.alertId;
            } else {
                this.item.alertId = "";
            }

            if (item.alertType) {
                this.item.alertType = item.alertType;
            } else {
                this.item.alertType = 0;
            }

            if (item.fromDate) {
                this.item.fromDate = this.moment(item.fromDate).format('DD/MM/yyyy');
            } else {
                const today = this.moment();
                this.item.fromDate = today.format('DD/MM/yyyy');
            }

            if (item.toDate) {
                this.item.toDate = this.moment(item.toDate).format('DD/MM/yyyy');
            } else {
                const today = this.moment();
                this.item.toDate = today.format('DD/MM/yyyy');
            }

            if (item.title) {
                this.item.title = item.title;
            } else {
                this.item.title = "";
            }

            if (item.enabled) {
                this.item.enabled = Boolean(item.enabled);
            } else {
                this.item.enabled = false;
            }


            var translation = item.translations.filter(t => t.locale == 'en')[0];

            if (translation) {
                if (translation.translationId) {
                    this.item.alert_translationId_en = translation.translationId;
                } else {
                    this.item.alert_translationId_en = 0;
                }

                if (translation.title) {
                    this.item.alert_title_en = translation.title;
                } else {
                    this.item.alert_title_en = "";
                }

                if (translation.text) {
                    this.item.alert_text_en = translation.text;
                } else {
                    this.item.alert_text_en = "";
                }
            } else {
                this.item.alert_translationId_en = 0;
                this.item.alert_title_en = "";
                this.item.alert_text_en = "";
            }


            translation = item.translations.filter(t => t.locale == 'tr')[0];

            if (translation) {

                if (translation.translationId) {
                    this.item.alert_translationId_tr = translation.translationId;
                } else {
                    this.item.alert_translationId_tr = 0;
                }

                if (translation.title) {
                    this.item.alert_title_tr = translation.title;
                } else {
                    this.item.alert_title_tr = "";
                }

                if (translation.text) {
                    this.item.alert_text_tr = translation.text;
                } else {
                    item.alert_text_tr = "";
                }
            } else {
                this.item.alert_translationId_tr = 0;
                this.item.alert_title_tr = "";
                this.item.alert_text_tr = "";
            }

        },

        convertDataModel() {

            var newItem = {};
            newItem.alertId = this.item.alertId;
            newItem.alertType = this.item.alertType;
            const momentObject = this.moment(this.item.fromDate, "DD/MM/YYYY");
            const dateISO = momentObject.format("YYYY-MM-DD");
            newItem.fromDate = dateISO;

            const momentObject2 = this.moment(this.item.toDate, "DD/MM/YYYY");
            const dateISO2 = momentObject2.format("YYYY-MM-DD");
            newItem.toDate = dateISO2;


            newItem.enabled = Number(this.item.enabled);
            newItem.translations = [];
            var trans_item_tr = {};
            trans_item_tr.translationId = this.item.alert_translationId_tr;
            trans_item_tr.locale = "tr";
            trans_item_tr.title = this.item.alert_title_tr;
            trans_item_tr.text = this.item.alert_text_tr;
            newItem.translations.push(trans_item_tr);
            var trans_item_en = {};
            trans_item_en.translationId = this.item.alert_translationId_en;
            trans_item_en.locale = "en";
            trans_item_en.title = this.item.alert_title_en;
            trans_item_en.text = this.item.alert_text_en;
            newItem.translations.push(trans_item_en);
            return newItem;

        },


        async changeDate() {
            this.errorFromDateMessage = " ";
            this.errorToDateMessage = "";

            if (!this.item.fromDate) {
                this.errorFromDateMessage = this.$t('validation.required', { _field_: this.$t('fields.date') });
            }

            this.errorFromDateMessage = " ";
            if (!this.item.toDate) {
                this.errorToDateMessage = this.$t('validation.required', { _field_: this.$t('fields.date') });
            }
        },

        async save() {

            this.errorFromDateMessage = "";

            var valid = await this.$refs.form.validate();

            if (!this.item.fromDate) {
                valid = false;
                this.errorFromDateMessage = this.$t('validation.required', { _field_: this.$t('fields.date') });
            }

            if (!valid) {
                this.$noty.error(this.$t('error.saved'));
            }

            if (valid) {
                this.loading = true;
                if (this.creating) {
                    let response = await createAlert(this.convertDataModel());
                    if (response.status == "201") {
                        this.$noty.success(this.$t('success.saved'));
                        this.$router.push({ name: this.$i18n.locale + '.alerts' });

                    } else {
                        this.$noty.error(this.$t('error.saved'));
                    }
                }
                if (this.editing) {
                    let response = await updateAlert(this.convertDataModel());
                    if (response.status == "200") {
                        this.$noty.success(this.$t('success.saved'));
                    } else {
                        this.$noty.error(this.$t('error.saved'));
                    }
                }
                this.loading = false;


            }

        },

        newItem() {
            this.creating = true;
            this.editing = false;
        },


        editItem(itemId) {
            this.creating = false;
            this.editing = true;
            this.fetchItem(itemId);
        },


        async fetchItem(itemId) {
            this.loading = true;
            let response = await getAlert(itemId);
            if (response) {
                this.assignDataModel(response);
            }
            this.loading = false;
        }

    }
}


</script>


<style scope>
.field-switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.md-field div {
    margin-left: 2px;
}

.custom-select input {
    opacity: 0;
}
</style>