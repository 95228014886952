import { Auth, Hub } from 'aws-amplify';
import { Roles } from '../config';

export default {
    data() {
        return {
            currentUser: {
                admin: false,
                superAdmin:false,
                alias: null,
                role: null,
                email: "",
            },
        }
    },

    computed: {
        isLoggedIn: function () {
            return this.currentUser != null && this.currentUser.username && this.currentUser.username != '';
        },
        isAdmin: function () {
            return this.currentUser != null && this.currentUser.admin;
        }
    },

    async beforeCreate() {
        Hub.listen('auth', data => {
            const { payload } = data
            /*
            const locale = this.$i18n.locale;
            */
            if (payload.event === 'signIn') {
                this.setAuthUser(payload.data);
            }
            if (payload.event === 'signOut') {
                this.unsetAuthUser();
            }
        })

        try {
            if (!this.currentUser) {
                var user = await Auth.currentAuthenticatedUser();
                this.setAuthUser(user);
            }
        } catch (error) {
            console.info('Not logged in');
        }
    },

    methods: {

        async getUserRole() {
            if (!this.currentUser.role) {
                var user = await Auth.currentAuthenticatedUser();
                this.currentUser.role = user.attributes['custom:role_id'];
                if ( this.currentUser.role== Roles.ADMIN){
                    this.currentUser.superAdmin = true;
                }
                return user.attributes['custom:role_id'];
            } else {
                return this.currentUser.role;
            }
        },

        async getUserEmail() {
            if (!this.currentUser.email) {
                var user = await Auth.currentAuthenticatedUser();
                this.currentUser.email = user.attributes.email;
                return user.attributes.email;
            } else {
                return this.currentUser.email;
            }
        },

        async getUserClientId() {
            if (!this.currentUser.clientId) {
                var user = await Auth.currentAuthenticatedUser();
                this.currentUser.clientId = user.attributes.given_name;
                return user.attributes.given_name;
            } else {
                return this.currentUser.clientId;
            }
        },

        setAuthUser(user) {
            this.currentUser = user;
            var alias = '';
            if (user.attributes.preferred_username) {
                alias = user.attributes.preferred_username;
            } else if (user.attributes.email) {
                alias = user.attributes.email;
            }
            // Get user clientId saved on cognito given_name attribute
            if (user.given_name) {
                this.currentUser.clientId = user.attributes.given_name;
            }
            this.currentUser.alias = alias;
            this.currentUser.role = user.attributes['custom:role_id'];
            if (this.currentUser.role) {
                this.currentUser.admin = true;
                if ( this.currentUser.role == Roles.ADMIN){
                 this.currentUser.superAdmin = true;
                }
            } else {
                this.currentUser.admin = false;
            }
        },
        unsetAuthUser() {
            this.currentUser = {
                admin: false,
                superAdmin:false,
                alias: null,
                role: null,
            }
        }
    }
};