<template>
    <div>
        <div class="md-layout" v-if="isLoggedIn && !currentUser.superAdmin">
            <div class="md-layout-item md-size-100">
                <h3 class="page-title">Access Denied</h3>
                <h4>You dont have permission to view this site.</h4>
            </div>
        </div>
        <div v-if="isLoggedIn && currentUser.superAdmin" class="md-layout">
            <div class="md-layout-item md-size-100">
                <h3 class="page-title">
                    {{ $t("label.user_title_list") }}
                    <md-progress-spinner v-if="loading" :md-diameter="20" :md-stroke="1" md-mode="indeterminate">
                    </md-progress-spinner>
                </h3>
            </div>
            <div class="md-layout-item md-size-100 inline-toolbar">
                <md-field class="search-input">
                    <md-icon class="search-icon">image_search</md-icon>
                    <label>{{ $t("label.search_user") }}</label>
                    <md-input v-model="search" @keyup.enter="filter()"></md-input>
                    <md-button v-if="search" class="btn-floating" @click="clearFilter()">
                        <md-icon>close</md-icon>
                    </md-button>
                </md-field>
                <md-button class="md-primary md-round btn-big" @click="filter()">
                    {{ $t("search") }}
                </md-button>
                <router-link :to="{ name: $i18n.locale + '.user_create' }" class="md-primary md-round btn-big"
                    tag="md-button">
                    {{ $t("new") }}
                </router-link>
            </div>
            <div class="md-layout-chips">
                <md-chips v-model="Filters" md-placeholder="" @md-delete="clearFilter()"></md-chips>
            </div>
            <div class="md-layout-item md-size-100">
                <md-table table-header-color="blue" v-model="Items" :md-sort-order.sync="currentSortOrder"
                    :md-sort.sync="currentSort" :md-sort-fn="customSort">
                    <md-table-row slot="md-table-row" slot-scope="{ item }">
                        <md-table-cell md-sort-by="email" :md-label="$t('label.user_email')">
                            {{ item.email }}
                        </md-table-cell>
                        <md-table-cell md-sort-by="roleName" :md-label="$t('label.user_roleName')">
                            {{ item.roleName }}
                        </md-table-cell>
                        <md-table-cell md-sort-by="enabled" :md-label="$t('label.user_enabled')">
                            <img class="Icons-Enabled" src="@/assets/icons-tick.svg" v-show="item.enabled">
                            <img class="Icons-Enabled" src="@/assets/icons-cross.svg" v-show="!item.enabled">
                        </md-table-cell>
                        <md-table-cell class="table-cell-actions">
                            <router-link class="btn-icon" value=""
                                :to="{ name: $i18n.locale + '.user_edit', params: { sub: item.sub } }" tag="button">
                                <md-icon>edit</md-icon>
                            </router-link>
                            <button class="btn-icon" @click="confirmDelete(item)">
                                <md-icon>delete</md-icon>
                            </button>
                        </md-table-cell>
                    </md-table-row>
                </md-table>
                <!-- Pagination -->
                <div class="pagination-container">
                    <md-pagination v-model="page" :noArrows="false" :perPage="limit" :total="total" />
                </div>
            </div>
        </div>
        <md-modal v-if="showConfirm" @close="showConfirm = false">
            <h3 slot="header">
                {{ $t("confirm") }}
            </h3>
            <div slot="body">
                {{ $t("confirm_messages.delete") }}
            </div>
            <div slot="footer" class="modal-footer">
                <md-button @click="showConfirm = false" class="md-danger md-round">
                    {{ $t("cancel") }}
                </md-button>
                <md-button @click="deleteItem()" class="md-primary md-round">
                    {{ $t("delete") }}
                </md-button>
            </div>
        </md-modal>
    </div>
</template>

<script>
import { getUsers, deleteUser } from "@/services/users";
import paginationMixin from "@/mixins/pagination";
import authMixin from "@/mixins/auth";

export default {
    mixins: [authMixin, paginationMixin],
    data() {
        return {
            currentSort: 'email',
            currentSortOrder: 'asc',
            Items: [],
            Filters:[],
            search: "",
            sort: "name,asc",
            loading: false,
            showConfirm: false,
            ItemIdToDelete: "",
        };
    },

    watch: {
        page() {
            this.fetchItems();
        },
    },

    created: async function () {
        await this.fetchItems();
    },

    methods: {
        async fetchItems() {
            this.loading = true;
            this.sort = this.currentSort + "," + this.currentSortOrder;
            if (this.search) {
                this.Filters = [];
                this.Filters.push(this.search);
            }else{
                this.Filters = [];
                this.search = "";
            }
            try {
                const response = await getUsers(this.page, this.limit, this.search, this.sort);
                if (response) {
                    this.Items = response.result;
                    this.total = response.pageInfo.totalPages * this.limit;
                } else {
                    this.Items = [];
                    this.total = 0;
                }
            } catch(error) {
                console.error("Error getting users: ", error);
            }
            this.loading = false;
        },

        async filter() {
            this.fetchItems();
        },

        clearFilter() {
            this.search = "";
            this.Filters = [];
            this.fetchItems();
        },

        confirmDelete(Item) {
            this.ItemIdToDelete = Item.sub;
            this.showConfirm = true;
        },

        async deleteItem() {
            this.loading = true;
            let response = await deleteUser(this.ItemIdToDelete);
            if (response.status == "200") {
                this.showConfirm = false;
                this.ItemIdToDelete = "";
                this.$noty.success(this.$t('success.deleted'));
                this.fetchItems();
            } else {
                this.$noty.error(this.$t('error.deleted'));
            }
            this.loading = true;
        },

        customSort: function customSort(value) {
            this.fetchItems();
            return value;
        },
    }
}
</script>

<style>

</style>