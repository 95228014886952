<template>
    <div>
        <md-dialog :md-close-on-esc="false" :md-active.sync="show" :md-click-outside-to-close="false" class="modal-product">
            <md-dialog-title class="modal-media-header">
                <h3>
                    {{ $t("Selections") }}
                    <md-progress-spinner v-if="loading" :md-diameter="20" :md-stroke="1" md-mode="indeterminate">
                    </md-progress-spinner>
                </h3>
                <md-button @click="closeModal()" class="
                        md-primary md-just-icon md-round md-simple
                        modal-close-btn
                    ">
                    <md-icon class="md-size-2x">close</md-icon>
                </md-button>
            </md-dialog-title>
            <md-dialog-content>
                <!-- Product List -->
                <div v-if="!creating && !editing">
                    <div class="md-layout-item md-size-100 inline-toolbar">
                        <md-field class="search-input">
                            <md-icon class="search-icon">image_search</md-icon>
                            <label>{{ $t("label.search_selection") }}</label>
                            <md-input v-model="search" @keyup.enter="filter()"></md-input>
                            <md-button v-if="search" class="btn-floating" @click="clearFilter()">
                                <md-icon>close</md-icon>
                            </md-button>
                        </md-field>
                        <md-button class="md-primary md-round" @click="filter()">
                            {{ $t("search") }}
                        </md-button>
                        <md-button @click="newItem()" class="md-primary md-round">
                            {{ $t("new") }}
                        </md-button>
                    </div>

                    <div v-if="Items.length == 0" table-header-color="blue" style="margin-left: 20px; margin-bottom: 20px"
                        class="md-content md-table md-theme-default">
                        {{ $t('label.no_records') }}
                    </div>


                    <md-table v-if="Items.length > 0" table-header-color="blue" v-model="Items"
                        :md-sort-order.sync="currentSortOrder" :md-sort.sync="currentSort" :md-sort-fn="customSort">
                        <md-table-row slot="md-table-row" slot-scope="{ item }">
                            <md-table-cell md-sort-by="order" :md-label="$t('label.selection_order')">
                                {{ item.order }}
                            </md-table-cell>
                            <md-table-cell md-sort-by="title" :md-label="$t('label.selection_title')">
                                {{ item.title }}
                            </md-table-cell>
                            <md-table-cell class="table-cell-actions">
                                <button class="btn-icon" @click="editItem(item.selectionId)">
                                    <md-icon>edit</md-icon>
                                </button>
                                <button class="btn-icon" @click="confirmDelete(item)">
                                    <md-icon>delete</md-icon>
                                </button>
                            </md-table-cell>
                        </md-table-row>
                    </md-table>
                    <!-- Pagination -->
                    <div class="pagination-container" v-if="Items.length > 0">
                        <md-pagination v-model="page" :noArrows="false" :perPage="limit" :total="total" />
                    </div>
                    <md-modal v-if="showConfirm" @close="showConfirm = false">
                        <h3 slot="header">
                            {{ $t("confirm") }}
                        </h3>
                        <div slot="body">
                            {{ $t("confirm_messages.delete") }}
                        </div>
                        <div slot="footer" class="modal-footer">
                            <md-button @click="showConfirm = false" class="md-danger md-round">
                                {{ $t("cancel") }}
                            </md-button>
                            <md-button @click="deleteItem()" class="md-primary md-round">
                                {{ $t("delete") }}
                            </md-button>
                        </div>
                    </md-modal>
                </div>
                <div v-else>
                    <ValidationObserver tag="form" ref="form" v-slot="{ errors }">
                        <div class="md-layout form-layout">
                            <!-- Order -->
                            <div class="md-layout-item md-size-40">
                                <md-field>
                                    <md-select v-model="item.order" :disabled="loading" :placeholder="$t('label.order')">
                                        <md-option v-for="(order, index) in orders" :key="index" :value="order.value">
                                            {{ order.text }}
                                        </md-option>
                                    </md-select>
                                    <span class="md-error">
                                        {{ errorOrderMessage }}
                                    </span>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-size-10"></div>
                            <!-- Type-->
                            <div class="md-layout-item md-size-45">
                                <md-field>
                                    <md-select v-model="selectionType" :disabled="loading">
                                        <md-option v-for="(type, index) in types" :key="index" :value="type.key">
                                            {{ $t(type.title) }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                            <!-- element id -->
                            <div class="md-layout-item md-size-45">
                                <div class="md-layout">
                                    <div class="md-layout-item md-size-65">
                                        <md-field class="image-field">
                                            <label class="label-images"
                                                v-if="!item.element || (item.element.name && item.element.name == '')">{{
                                                    $t("label.selection_name") }}</label>
                                            <label v-if="!item.element || item.element.name == ''">{{
                                                $t("label.select_element") }}</label>
                                            <label v-else>{{ $t("label.selection_name") }}</label>
                                            <md-input readonly id="mediaInput"
                                                :value="item.element && item.element.name ? item.element.name : ''"
                                                :disabled="true"></md-input>

                                            <md-button v-if="item.element && item.element.name != ''" class="btn-floating"
                                                @click="clearElement()">
                                                <md-icon>close</md-icon>
                                            </md-button>
                                            <span class="md-error">{{ errorElementMessage }}</span>
                                        </md-field>
                                    </div>
                                    <div class="md-layout-item md-size-20">
                                        <md-button @click="openDialog()" class="md-primary md-round btn-medium">
                                            {{ $t("label.selection_element") }}
                                        </md-button>
                                    </div>
                                    <!-- Building  Floor -->
                                    <div class="md-layout-item md-size-20">
                                        <md-field>
                                            <label for="text">{{ $t('label.mapBuildingFloor') }}</label>
                                            <md-input v-model="item.buildingFloor" :disabled="false"
                                                :placeholder="$t('label.mapBuildingFloor')">
                                            </md-input>
                                        </md-field>
                                    </div>
                                    <div class="md-layout-item md-size-20"></div>
                                    <!-- Building  Id -->
                                    <div class="md-layout-item md-size-20">
                                        <md-field>
                                            <label for="text">{{ $t('label.mapBuildingId') }}</label>
                                            <md-input v-model="item.buildingId" :disabled="true"
                                                :placeholder="$t('label.mapBuildingId')">
                                            </md-input>
                                        </md-field>
                                    </div>
                                </div>
                            </div>
                            <div class="md-layout-item md-size-10"></div>
                            <div class="md-layout-item md-size-45"></div>
                            <!-- Translations -->
                            <div class="md-layout-item md-size-50">
                                <md-card class="translations-card">
                                    <md-card-content>
                                        <md-tabs class="translations-tabs">
                                            <!-- Translations TR -->
                                            <md-tab id="tab-tr" :md-label="$t('languages.tr')"
                                                :md-icon="getLanguageIcon('tr', errors)">
                                                <!-- Product name TR -->
                                                <md-field :class="[
                                                    { 'md-valid': errors['title_tr'] && !errors['title_tr'][0] && touchedFields.title_tr },
                                                    { 'md-error': errors['title_tr'] && errors['title_tr'][0] }
                                                ]">
                                                    <label for="title_tr">{{
                                                        $t("fields.title_tr")
                                                    }}</label>
                                                    <validation-provider name="title_tr" rules="required"
                                                        v-slot="{ errors, touched }" mode="aggressive">
                                                        <md-input v-model="title_tr" name="title_tr" type="text"
                                                            id="title_tr" :disabled="loading"></md-input>
                                                        <slide-y-down-transition>
                                                            <md-icon class="error"
                                                                v-show="errors[0] && touched">close</md-icon>
                                                        </slide-y-down-transition>
                                                        <span class="md-error">{{ errors[0] }}</span>
                                                    </validation-provider>
                                                </md-field>
                                                <!-- Product description TR -->
                                                <md-field :class="[
                                                    { 'md-valid': errors['subtitle_tr'] && !errors['subtitle_tr'][0] && touchedFields.subtitle_tr },
                                                    { 'md-error': errors['subtitle_tr'] && errors['subtitle_tr'][0] }
                                                ]">
                                                    <label for="text">{{ $t('fields.subtitle_tr') }}</label>
                                                    <validation-provider name='subtitle_tr' rules=""
                                                        v-slot="{ errors, touched }" mode="aggressive">
                                                        <md-textarea :md-autogrow="false" f v-model="subtitle_tr"
                                                            name="subtitle_tr"
                                                            :placeholder="$t('label.selection_description')"
                                                            id="subtitle_tr" :disabled="loading">
                                                        </md-textarea>
                                                        <slide-y-down-transition>
                                                            <md-icon class="error"
                                                                v-show="errors[0] && touched">close</md-icon>
                                                        </slide-y-down-transition>
                                                        <span class="md-error">{{ errors[0] }}</span>
                                                    </validation-provider>
                                                </md-field>
                                            </md-tab>
                                            <!-- Translations EN -->
                                            <md-tab id="tab-en" :md-label="$t('languages.en')"
                                                :md-icon="getLanguageIcon('en', errors)">
                                                <!-- Product name EN -->
                                                <md-field :class="[
                                                    { 'md-valid': errors['title_en'] && !errors['title_en'][0] && touchedFields.title_en },
                                                    { 'md-error': errors['title_en'] && errors['title_en'][0] }
                                                ]">
                                                    <label for="title_en">{{
                                                        $t("fields.title_en")
                                                    }}</label>
                                                    <validation-provider name="title_en" rules="required"
                                                        v-slot="{ errors, touched }" mode="aggressive">
                                                        <md-input v-model="title_en" name="title_en" type="text"
                                                            id="title_en" :disabled="loading"></md-input>
                                                        <slide-y-down-transition>
                                                            <md-icon class="error"
                                                                v-show="errors[0] && touched">close</md-icon>
                                                        </slide-y-down-transition>
                                                        <span class="md-error">{{ errors[0] }}</span>
                                                    </validation-provider>
                                                </md-field>
                                                <!-- Product description EN -->
                                                <md-field :class="[
                                                    { 'md-valid': errors['subtitle_en'] && !errors['subtitle_en'][0] && touchedFields.subtitle_en },
                                                    { 'md-error': errors['subtitle_en'] && errors['subtitle_en'][0] }
                                                ]">
                                                    <label for="text">{{ $t('fields.subtitle_en') }}</label>
                                                    <validation-provider name='subtitle_en' rules=""
                                                        v-slot="{ errors, touched }" mode="aggressive">
                                                        <md-textarea :md-autogrow="false" f v-model="subtitle_en"
                                                            name="subtitle_en"
                                                            :placeholder="$t('label.selection_description')"
                                                            id="subtitle_en" :disabled="loading">
                                                        </md-textarea>
                                                        <slide-y-down-transition>
                                                            <md-icon class="error"
                                                                v-show="errors[0] && touched">close</md-icon>
                                                        </slide-y-down-transition>
                                                        <span class="md-error">{{ errors[0] }}</span>
                                                    </validation-provider>
                                                </md-field>
                                            </md-tab>
                                        </md-tabs>
                                    </md-card-content>
                                </md-card>
                            </div>
                            <!-- Product image media -->
                            <div class="md-layout-item md-size-50">
                                <div class="form-container">
                                    <!-- Media selector -->
                                    <div class="md-layout">
                                        <div>
                                            <md-icon class="md-42">iconimage_search</md-icon>
                                        </div>
                                        <div class="md-layout-item md-size-60">
                                            <md-field class="image-field">
                                                <label class="label-images" v-if="item.media.fileName == ''">{{
                                                    $t("label.selection_image")
                                                }}</label>
                                                <label v-if="item.media.fileName == ''">{{
                                                    $t("label.selection_image")
                                                }}</label>
                                                <label v-else>{{ $t("label.selection_image") }}</label>
                                                <md-input readonly id="mediaInput" v-model="item.media.fileName"
                                                    :disabled="true">
                                                </md-input>
                                                <md-button v-if="item.media.fileName != ''" class="btn-floating"
                                                    @click="clearMedia()">
                                                    <md-icon>close</md-icon>
                                                </md-button>
                                                <span class="md-error">
                                                    {{ errorMediaMessage }}
                                                </span>
                                            </md-field>
                                        </div>
                                        <div class="md-layout-item md-size-20">
                                            <md-button @click="openMediaModal()" class="md-primary md-round">
                                                {{ $t("select_file") }}
                                            </md-button>
                                        </div>
                                    </div>

                                    <!-- Media preview -->
                                    <div>
                                        <div class="media-container">
                                            <md-icon v-if="item.imageURL == ''" class="md-size-5x">image</md-icon>
                                            <img v-else :src="item.imageURL" :alt="item.imageAlt"
                                                @click.prevent="viewImage(item.media.imageURL)" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="modal-footer">
                            <md-button @click.prevent="cancel()" class="md-danger md-round" :disabled="loading">
                                {{ $t("cancel") }}
                            </md-button>
                            <md-button v-if="creating || editing" @click.prevent="save()" type="submit"
                                class="md-primary md-round" :disabled="loading">
                                {{ $t("save") }}
                            </md-button>
                        </div>
                    </ValidationObserver>
                </div>
                <!-- Selector Modal-->
                <service-modal :modeTypeProp="dialogMode" :showProp="showDialog" @apply="applyDialog"
                    @close="closeDialog()" />
                <!-- Media Modal -->
                <media-modal :showProp="showMediaModal" @apply="applyMedia" @close="closeMediaModal()"></media-modal>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import MediaModal from "@/components/Modals/Media";
import paginationMixin from "@/mixins/pagination";
import localeMixin from "@/mixins/locale";
import ServiceModal from "@/components/Modals/Service";

import {
    deleteStepSuggestionsSelection,
    getStepSuggestionsSelections,
    getStepSuggestionsSelection,
    createStepSuggestionsSelection,
    updateStepSuggestionsSelection
} from "@/services/suggestion"

import { getShop } from "@/services/shop";
import { getDine } from "@/services/dine";


export default {
    props: ["showProp", "suggestionId", "stepId", "name"],
    mixins: [paginationMixin, localeMixin],
    components: {
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
        MediaModal,
        ServiceModal,
    },
    data() {
        return {
            showDialog: false,
            dialogMode: "",
            types: [
                { key: "1", value: 1, title: this.$t("Shop") },
                { key: "2", value: 2, title: this.$t("Restaurant") },
            ],
            selectionType: "1",
            loading: false,
            show: false,
            view: false,
            creating: false,
            editing: false,
            mode: "",
            imagesLightbox: [],
            showConfirm: false,
            touchedFields: {
                title_tr: false,
                subtitle_tr: false,
                title_en: false,
                subtitle_en: false,
            },
            Items: [],
            item: {
                selectionId: 0,
                suggestionId: 0,
                retailerListSuggestionId: 0,
                order: 0,
                title: "",
                mediaId: 0,
                buildingId: null,
                buildingFloor: null,
                elementId: 0,
                selectionType: 0,
                imageURL: "",
                imageAlt: "",
                fileName: "",
                media: {
                    fileName: "",
                    imageURL: "",
                },
                element: {
                    elementId: "",
                    id: 0,
                    name: "",
                    locale: ""
                },
                translations: [
                    {
                        locale: "en",
                        title: "",
                        subtitle: "",
                    },
                    {
                        locale: "tr",
                        title: "",
                        subtitle: "",
                    },
                ],
            },
            title_tr: "",
            subtitle_tr: "",
            title_en: "",
            subtitle_en: "",
            search: "",
            currentSort: "name",
            currentSortOrder: "asc",
            showMediaModal: false,
            errorMediaMessage: "",
            errorElementMessage: "",
            errorOrderMessage: "",
            ItemIdToDelete: "",
        };
    },

    watch: {
        page() {
            this.fetchItems();
        },
        showProp() {
            this.show = this.showProp;
            if (this.show) {
                this.clearFilter();
                this.clearData();
                this.editing = false;
                this.creating = false;
                this.view = true;
                this.fetchItems();
            }
        }
    },

    async mounted() {
        this.fillOrdersArray();
    },

    methods: {

        fillOrdersArray() {
            let min = 0;
            let max = 50;
            this.orders = [];
            for (let i = min; i <= max; i++) {
                if (i === 0) {
                    let z = { value: i, text: this.$t("label.order_default") };
                    this.orders.push(z);
                } else {
                    let b = { value: i, text: i.toString() };
                    this.orders.push(b);
                }
            }
        },

        async applyDialog(element) {
            this.item.element = element;
            if (this.selectionType == "1") { //shop
                this.loading = true;
                this.item.selectionId = element.shopId;
                let response = await getShop(element.shopId);
                if (response) {
                    this.assignSelectedItem(response);

                }
                this.loading = false;
            } else if (this.selectionType == "2") {//Dine
                this.loading = true;
                this.item.selectionId = element.dineId;
                let response = await getDine(element.dineId);
                if (response) {
                    this.assignSelectedItem(response);
                }
                this.loading = false;
            }

        },

        assignSelectedItem(item) {
            var translation = item.translations.filter(t => t.locale == 'en')[0];
            if (translation.name) {
                this.title_en = translation.name;
            } else {
                this.title_en = "";
            }
            if (translation.description) {
                this.subtitle_en = translation.description;
            } else {
                this.subtitle_en = "";
            }
            translation = item.translations.filter(t => t.locale == 'tr')[0];
            if (translation.name) {
                this.title_tr = translation.name;
            } else {
                this.title_tr = "";
            }
            if (translation.description) {
                this.subtitle_tr = translation.description;
            } else {
                this.subtitle_tr = "";
            }

            this.item.media = {
                fileName: item.fileName, imageURL: item.imageURL
            };
            this.item.mediaId = item.mediaId;
            this.item.imageURL = item.imageURL;
            this.item.buildingId = item.mapBuildingId;
            this.item.buildingFloor = item.mapBuildingFloor;
            this.item.fileName = item.fileName;
            this.item.media = { fileName: item.fileName, imageURL: item.imageURL };
            this.errorMediaMessage = "";
        },

        openDialog() {


            switch (this.selectionType) {
                case "1":
                    this.dialogMode = "shop";
                    break;
                case "2":
                    this.dialogMode = "dine";
                    break;
                default:
                    // Manejar el caso por defecto si no coincide con ninguna opción
                    break;
            }


            this.showDialog = true;

        },

        closeDialog() {
            this.showDialog = false;
        },

        clearElement() {
            this.item.element = {
                elementId: "",
                id: 0,
                name: "",
                locale: ""
            };


        },

        viewImage(imageUrl) {
            this.view = true;
            this.imagesLightbox = [];
            this.$imageViewer.images(this.imagesLightbox);
            const image = {
                url: imageUrl,
            };
            this.imagesLightbox.push(image);
            this.$imageViewer.index(0);
            this.$imageViewer.show();
        },

        applyMedia(media) {
            this.item.media = media;
            this.item.mediaId = media.mediaId;
            this.item.imageURL = media.imageURL;
            this.item.fileName = media.fileName;
            this.errorMediaMessage = "";
        },

        clearMedia() {
            this.item.media = { fileName: "", imageURL: "" };
            this.item.mediaId = 0;
            this.item.imageURL = this.item.media.imageURL;
            this.item.fileName = this.item.media.fileName;
        },

        async fetchItems() {
            this.loading = true;
            this.Items = [];
            let response = await getStepSuggestionsSelections(this.stepId, this.suggestionId, this.page, this.limit, this.search);
            if (response) {
                this.Items = response.result;
                this.total = response.pageInfo.totalPages * this.limit;
                this.lastOrder = response.lastOrder;
            } else {
                this.Items = [];
                this.total = 0;
            }
            this.loading = false;
        },

        async fetchItem(itemId) {
            this.loading = true;
            let response;
            response = await getStepSuggestionsSelection(this.stepId, this.suggestionId, itemId);
            if (response) {
                this.item = response;
                this.item.media = {
                    fileName: response.fileName,
                    imageURL: response.imageURL,
                };
                this.item.mediaId = response.mediaId;
                this.item.imageURL = response.imageURL;
                this.item.buildingId = response.buildingId;
                this.item.buildingFloor = response.buildingFloor;
                this.item.imageURL = response.imageURL;
                this.item.fileName = response.fileName;
                this.selectionType = String(this.item.selectionType);
                this.putItemTranslations();
            }
            this.loading = false;
        },

        filter() {
            this.fetchItems();
        },

        clearFilter() {
            this.search = "";
            this.fetchItems();
        },

        clearData() {
            this.showDialog = false;
            this.dialogMode = "";
            this.selectionType = "1";
            this.loading = false;
            this.show = true;
            this.view = false;
            this.creating = false;
            this.editing = false;
            this.mode = "";
            this.imagesLightbox = [];
            this.showConfirm = false;
            this.touchedFields = {
                title_tr: false,
                subtitle_tr: false,
                title_en: false,
                subtitle_en: false,
            };
            this.item = {
                selectionId: 0,
                suggestionId: 0,
                retailerListSuggestionId: 0,
                order: 0,
                title: "",
                mediaId: 0,
                elementId: 0,
                buildingId: null,
                buildingFloor: null,
                selectionType: 0,
                imageURL: "",
                imageAlt: "",
                fileName: "",
                media: {
                    fileName: "",
                    imageURL: "",
                },
                element: {
                    elementId: "",
                    id: 0,
                    name: "",
                    locale: ""
                },
                translations: [
                    {
                        locale: "en",
                        title: "",
                        subtitle: "",
                    },
                    {
                        locale: "tr",
                        title: "",
                        subtitle: "",
                    },
                ],
            };
            this.title_tr = "";
            this.subtitle_tr = "";
            this.title_en = "";
            this.subtitle_en = "";
            this.search = "";
            this.currentSort = "name";
            this.currentSortOrder = "asc";
            this.showMediaModal = false;
            this.errorMediaMessage = "";
            this.errorElementMessage = "";
            this.errorOrderMessage = "";
            this.ItemIdToDelete = "";
        },

        getItemTranslations() {
            let translation_en = this.item.translations.find(({ locale }) => locale == "en");
            if (translation_en) {
                translation_en.title = this.title_en;
                translation_en.subtitle = this.subtitle_en;
            } else {
                translation_en = {
                    locale: "en",
                    title: this.title_en,
                    subtitle: this.subtitle_en,
                }
            }
            let translation_tr = this.item.translations.find(({ locale }) => locale == "tr");
            if (translation_tr) {
                translation_tr.title = this.title_tr;
                translation_tr.subtitle = this.subtitle_tr;
            } else {
                translation_tr = {
                    locale: "tr",
                    title: this.title_tr,
                    subtitle: this.subtitle_tr,
                }
            }
            const translations = [translation_en, translation_tr];
            this.item.translations = translations;
        },

        putItemTranslations() {
            let translation_en = this.item.translations.find(({ locale }) => locale == "en");
            if (translation_en) {
                this.title_en = translation_en.title;
                this.subtitle_en = translation_en.subtitle;
            }
            let translation_tr = this.item.translations.find(({ locale }) => locale == "tr");
            if (translation_tr) {
                this.title_tr = translation_tr.title;
                this.subtitle_tr = translation_tr.subtitle;
            }
            const translations = [translation_en, translation_tr];
            this.item.translations = translations;
        },

        openMediaModal() {
            this.showMediaModal = true;
        },

        closeMediaModal() {
            this.showMediaModal = false;
        },

        closeModal() {
            this.show = false;
            this.$emit("close");
        },

        cancel() {
            this.view = false;
            this.editing = false;
            this.creating = false;
        },

        customSort: function customSort(value) {
            this.fetchItems();
            return value;
        },

        newItem() {
            this.clearData();
            this.putItemTranslations();
            this.creating = true;
            this.view = false;
            this.editing = false;
            this.selectionType = "1";
            this.item.order = this.lastOrder;
        },

        editItem(itemId) {
            this.clearData();
            this.editing = true;
            this.view = false;
            this.creating = false;
            this.fetchItem(itemId);
        },

        confirmDelete(Item) {
            this.ItemIdToDelete = Item.selectionId;
            this.showConfirm = true;
        },

        async deleteItem() {
            this.loading = true;
            let response;
            deleteStepSuggestionsSelection
            response = await deleteStepSuggestionsSelection(this.stepId, this.suggestionId, this.ItemIdToDelete);
            if (response.status == "200") {
                this.showConfirm = false;
                this.ItemIdToDelete = null;
                this.$noty.success(this.$t('success.deleted'));
                this.fetchItems();
            } else {
                this.$noty.error(this.$t('error.deleted'));
            }
            this.loading = false;
        },

        async save() {
            this.loading = true;
            this.errorOrderMessage = "";
            this.errorMediaMessage = "";
            this.errorElementMessage = "";

            var valid = await this.$refs.form.validate();

            if (this.item.order === 0 || this.item.order === null) {
                valid = false;
                this.errorOrderMessage = this.$t(
                    "validation.at_least_one_value",
                    { _field_: this.$t("label.dine_order") }
                );
            }

            if (this.item.mediaId == null || this.item.mediaId == 0) {
                valid = false;
                this.errorMediaMessage = this.$t('validation.required', { _field_: this.$t('label.selection_image') });
            }

            if (this.item.element.id == null || this.item.element.id == 0) {
                valid = false;
                this.errorElementMessage = this.$t('validation.required', { _field_: this.$t('label.selection_name') });
            }


            if (valid) {
                this.getItemTranslations();
                var newItem = JSON.parse(JSON.stringify(this.item));
                newItem.selectionType = Number(this.selectionType);
                newItem.elementId = newItem.element.id;
                newItem.buildingId = this.item.buildingId;
                newItem.buildingFloor = this.item.buildingFloor;
                console.info("newItem",newItem);

                this.loading = false;
                if (this.creating) {
                    const response = await createStepSuggestionsSelection(this.stepId, this.suggestionId, newItem);
                    if (response) {
                        this.$noty.success(this.$t("success.saved"));
                        this.cancel();
                    } else {
                        this.$noty.error(this.$t("error.saved"));
                    }
                }

                if (this.editing) {
                    let response = await updateStepSuggestionsSelection(this.stepId, this.suggestionId, newItem.selectionId, newItem);
                    if (response.status == "200") {
                        this.$noty.success(this.$t('success.saved'));
                        this.cancel();
                    } else {
                        this.$noty.error(this.$t("error.saved"));
                    }

                }
            } else {
                this.$noty.error(this.$t('error.saved'));
            }
            this.loading = false;
        },
    },
}
</script>

<style scoped>
.form-container {
    padding-left: 25px;
    padding-right: 25px;
}

.md-card-content {
    min-height: 375px;
}

.media-container {
    margin-bottom: 15px;
}

.media-container img {
    cursor: pointer;
}</style>