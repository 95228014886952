<template>

    <div style="margin-top: 4px;margin-bottom: 4px;">
        <md-progress-spinner v-if="loading" :md-diameter="20" :md-stroke="1" md-mode="indeterminate">
        </md-progress-spinner>
        <button class="btn-icon" @click="bookingPdf" v-if="!loading">
            <md-icon>download</md-icon>
        </button>
        <qrcode-vue :value="QRValue" :size="250" level="H" ref="qrcode" :id="`${selectedItem.bookingId}_qr`"
            class="hidden" />
        <img src="@/assets/images/logotipos/06_YDADalamanAirport_negative.png" alt="Logo DALAMAN"
            :id="`${selectedItem.bookingId}_logo`" class="hidden" ref="logo">
    </div>
</template>

<script>

import { DateTime } from 'luxon';
import QrcodeVue from 'qrcode.vue';
import { CURRENCY_SYMBOLS } from "@/config";
import { COUNTRIES } from "@/locales/countries";
import jsPDF from 'jspdf';
import { ArialUnicodeFont } from '@/assets/fonts/ArialUnicodeFont-normal-2';
import localeMixin from "@/mixins/locale";
import {
    getAppBooking,
} from "@/services/booking";
export default {
    mixins: [localeMixin],
    components: {
        QrcodeVue,
    },
    data() {
        return {
            bookingItem: null,
            QRValue: "",
            loading: false,
            doc: null,
            posY: 0,
            posX1: 0,
            posX2: 0,
            nPage: 0,
            PAGE_HEIGHT: 842,
            CONTENT_BREAK_POINT: 755,
        }
    },
    async beforeMount() {
        this.bookingItem = await this.fetchBooking(this.selectedItem);
        this.generateQRCode();
    },
    props: {
        selectedItem: Object,
    },
    methods: {
        async fetchBooking(book) {
            const response = await getAppBooking(book.bookingId);
            return response;
        },
        myCountry(alpha_2_code) {
            let myCountry = COUNTRIES[this.$i18n.locale].find(c => c.alpha_2 === alpha_2_code);
            return myCountry.name;
        },
        formatDate(dateString) {
            const dateTimeUTC = DateTime.fromISO(dateString, { zone: 'utc' });
            const dateTimeDalaman = dateTimeUTC.setZone(process.env.VUE_APP_AIRPORT_TIMEZONE);
            return dateTimeDalaman.toFormat('dd/MM/yyyy');
        },
        formatTime_utc(dateString) {
            const dateTimeUTC = DateTime.fromISO(dateString, { zone: 'utc' });
            const dateTimeDalaman = dateTimeUTC.setZone(process.env.VUE_APP_AIRPORT_TIMEZONE);
            return dateTimeDalaman.toFormat('HH:mm');
        },
        generateQRCode() {

            const bookingReference = this.bookingItem.reference;
            const mainPassengersName = (this.bookingItem.passportInformation && this.bookingItem.passportInformation.length) ? this.bookingItem.passportInformation[0].nameAndSurname : "";
            const bookingDate = this.bookingItem.createdDate;
            const mainProductBooked = this.bookingItem.ancillaryQuotes ? this.bookingItem.ancillaryQuotes[0].name : "";

            this.QRValue = "";
            this.QRValue += `Ref: ${bookingReference}\n`;
            this.QRValue += `${mainPassengersName}\n`;
            this.QRValue += `BookingDate: ${bookingDate}\n`;
            this.QRValue += `${mainProductBooked}\n`;
        },
        convertDateTimeToDalaman(dateTime, mode) {
            const entryDateTimeUTC = DateTime.fromISO(dateTime, { zone: 'utc' });
            dateTime = entryDateTimeUTC.setZone(process.env.VUE_APP_AIRPORT_TIMEZONE);
            switch (mode) {
                case "date":
                    return dateTime.toFormat('yyyy-MM-dd');
                case "time":
                    return dateTime.toFormat('HH:mm');
                case "all":
                    return dateTime.toFormat('yyyy-MM-dd HH:mm');
            }
        },

        async initDoc() {

            this.posX1 = 40;
            this.posX2 = 555.28;
            this.nPage = 0;

            //this.generateQRCode();

            /*
            //An: 21 cm -> 595.28 px -> 297.64 px
            //Al: 29,7 cm -> 841.89 px
            */

            let din = "a4";
            this.doc = new jsPDF('p', 'pt', din);
            this.doc.addFileToVFS('ArialUnicodeFont-normal.ttf', ArialUnicodeFont);
            this.doc.addFont('ArialUnicodeFont-normal.ttf', 'ArialUnicodeFont', 'normal');
            this.doc.setFont("ArialUnicodeFont");

            // ****** RENDERIZADO *****

            //this.doc.texthis.$t("PDF.ıçşŞİĞÜÄÜÖ? / İç Hat CIP Salon / Dalaman Havalimanı · Tüm hakları saklıdır", 30, 30);
            //pdf.addImage(base64Image, 'png', 0, 0, 30, 60);
        },

        async checkPosY() {
            if (this.posY > this.CONTENT_BREAK_POINT) {
                console.info("posY exceeds CONTENT_BREAK_POINT. Handling page break.");
                await this.handlePageBreak();
            } else {
                //console.info("posY.value", posY.value);
            }
        },

        async handlePageBreak() {
            if (this.doc && this.bookingItem) {
                // Añade la nueva página
                this.doc.addPage();
                await this.printHeader();
                await this.printFooter();
                this.posY = 100; // Asegúrate de reiniciar posY aquí
                console.info("reinicio posY", this.posY);
            } else {
                console.info("reinicio posY falso");
            }
        },

        async printHeader() {
            this.posY = 20;
            let booking_logo = document.getElementById(`${this.bookingItem.bookingId}_logo`);
            if (booking_logo) {
                this.doc.addImage(booking_logo, 'png', 20, this.posY, 105, 46.885);
            }
            this.doc.setTextColor("#AD3227");
            this.posY = 35;
            this.doc.setFontSize(20);
            this.doc.text(this.bookingItem.ancillaryQuotes[0].name, 297.64, this.posY, "center");
        },

        async printQR() {
            this.posY = 55;
            let booking_qrCode = document.getElementById(`${this.bookingItem.bookingId}_qr`);
            if (booking_qrCode) {
                let canvas = booking_qrCode.querySelector('canvas');
                if (canvas) {
                    this.doc.addImage(canvas, 'png', 220, this.posY, 155.28, 155.28);
                }
            }
        },

        async printSubHeader() {
            this.doc.setTextColor("#002d56");

            this.posY = 240;
            this.doc.setFontSize(11);
            this.doc.text(this.$t("PDF.booking_reference"), this.posX1, this.posY, "left");

            this.posY = 260;
            this.doc.setFontSize(12);
            this.doc.text(this.bookingItem.reference, this.posX1, this.posY, "left");

            this.posY = 240;
            this.doc.setFontSize(11);
            this.doc.text(this.$t("PDF.booking_date"), 215, this.posY, "center");

            this.posY = 260;
            this.doc.setFontSize(12);
            this.doc.text(this.formatDate(this.bookingItem.entryDateTime) + " " + this.formatTime_utc(this.bookingItem.entryDateTime), 215, this.posY, "center");

            this.posY = 240;
            this.doc.setFontSize(11);
            this.doc.text(this.$t("PDF.flight_date"), 335, this.posY, "center");

            this.posY = 260;
            this.doc.setFontSize(12);
            this.doc.text(this.formatDate(this.bookingItem.flightInformation.flightDateTime) + " " + this.formatTime_utc(this.bookingItem.flightInformation.flightDateTime), 335, this.posY, "center");

            this.posY = 240;
            this.doc.setFontSize(11);
            this.doc.text(this.$t("PDF.flight_number"), 435, this.posY, "center");

            this.posY = 260;
            this.doc.setFontSize(12);
            this.doc.text(this.bookingItem.flightInformation.flightNumber, 435, this.posY, "center");

            this.posY = 240;
            this.doc.setFontSize(11);
            if (this.bookingItem.flightInformation.flightDirection == "A") {
                this.doc.text(this.$t("PDF.origin"), this.posX2, this.posY, "right");
            } else if (this.bookingItem.flightInformation.flightDirection == "D") {
                this.doc.text(this.$t("PDF.destination"), this.posX2, this.posY, "right");
            } else {
                this.doc.text("-", this.posX2, this.posY, "right");
            }

            this.posY = 260;
            this.doc.setFontSize(12);
            this.doc.text(this.bookingItem.flightInformation.originOrDestinationAirport, this.posX2, this.posY, "right");

            this.posY = this.posY + 15;
            //this.doc.setDrawColor(50, 50, 50);
            this.doc.setDrawColor("#91a1b5");
            this.doc.setLineWidth(1.0);
            this.doc.line(this.posX1, this.posY, this.posX2, this.posY);
        },

        async printFooter() {
            this.nPage += 1;

            this.doc.setTextColor("#002d56");
            this.doc.setFontSize(8);

            let posY_footer = this.PAGE_HEIGHT - 43;
            this.doc.text("YDA Havalimanı Yatırım ve İşletme A.Ş.", this.posX1, posY_footer, "left");

            posY_footer = this.PAGE_HEIGHT - 34;
            this.doc.text("Ege Mahallesi, Dalaman Havalimanı, İç ve Dış Hatlar Terminal Binaları, Dalaman, MUĞLA / TÜRKİYE", this.posX1, posY_footer, "left");

            posY_footer = this.PAGE_HEIGHT - 25;
            this.doc.text("www.dalamanairport.aero", this.posX1, posY_footer, "left");

            posY_footer = this.PAGE_HEIGHT - 25;
            //doc.text("posY: " + posY, posX2, posY_paginate, "right");
            this.doc.text("" + this.nPage, this.posX2, posY_footer, "right");

            this.doc.setDrawColor("#91a1b5");
            posY_footer = this.PAGE_HEIGHT - 20;
            this.doc.setLineWidth(0.5);
            this.doc.line(this.posX1, posY_footer, this.posX2, posY_footer);
        },

        async bookingPdf() {
            this.loading = true;


            await this.initDoc();
            this.checkPosY();
            await this.printHeader();
            await this.printQR();
            await this.printSubHeader();
            await this.printFooter();

            //Passport information
            this.doc.setDrawColor("#91a1b5");
            await this.checkPosY();
            this.posY = this.posY + 20;
            this.doc.setFontSize(11);
            this.doc.text(this.$t("PDF.passport_information"), this.posX1, this.posY, "left");

            let n = 0;

            if (this.bookingItem.passportInformation && this.bookingItem.passportInformation.length > 0) {
                for (const i of this.bookingItem.passportInformation) {

                    n++;

                    if (i.nameAndSurname && n == 1) {
                        await this.checkPosY();
                        this.posY = 315;
                        this.doc.setFontSize(11);
                        this.doc.text(this.$t("PDF.main_passenger"), 70, this.posY, "left");
                        await this.checkPosY();
                        this.posY = this.posY + 12;
                        this.doc.setFontSize(11);
                        this.doc.text(this.$t("PDF.name"), 90, this.posY, "left");
                        this.doc.setFontSize(11);
                        this.doc.text(i.nameAndSurname, this.posX2, this.posY, "right");
                    }

                    if (i.passportNumber && n == 1) {
                        await this.checkPosY();
                        this.posY = this.posY + 12;
                        this.doc.setFontSize(11);
                        this.doc.text(this.$t("PDF.passport"), 90, this.posY, "left");
                        this.doc.setFontSize(11);
                        this.doc.text(i.passportNumber, this.posX2, this.posY, "right");
                    }

                    if (i.expeditionCountry && n == 1) {
                        await this.checkPosY();
                        this.posY = this.posY + 12;
                        this.doc.setFontSize(11);
                        this.doc.text(this.$t("PDF.country"), 90, this.posY, "left");
                        this.doc.setFontSize(11);
                        this.doc.text(this.myCountry(i.expeditionCountry), this.posX2, this.posY, "right");
                    }

                    if (i.nameAndSurname && n > 1) {
                        await this.checkPosY();
                        this.posY = this.posY + 20;
                        this.doc.setFontSize(11);
                        this.doc.text(this.$t("PDF.passenger") + " " + n, 70, this.posY, "left");
                        await this.checkPosY();
                        this.posY = this.posY + 12;
                        this.doc.setFontSize(11);
                        this.doc.text(this.$t("PDF.name"), 90, this.posY, "left");
                        this.doc.setFontSize(11);
                        this.doc.text(i.nameAndSurname, this.posX2, this.posY, "right");
                    }

                }
            }

            await this.checkPosY();
            this.posY = this.posY + 15;
            this.doc.setDrawColor("#91a1b5");
            this.doc.setLineWidth(1.0);
            this.doc.line(this.posX1, this.posY, this.posX2, this.posY);



            await this.checkPosY();
            this.posY = this.posY + 20;
            this.doc.setFontSize(11);
            //this.doc.text(this.$t("PDF.fast_track_booking"), this.posX1, this.posY, "left");
            this.doc.text(this.bookingItem.ancillaryQuotes[0].name, this.posX1, this.posY, "left");

            if (this.bookingItem.ancillaryQuotes[0].ticketQuotes && this.bookingItem.ancillaryQuotes[0].ticketQuotes.length > 0) {
                for (const i of this.bookingItem.ancillaryQuotes[0].ticketQuotes) {
                    if (i.quantity > 0) {
                        await this.checkPosY();
                        this.posY = this.posY + 20;
                        this.doc.setFontSize(11);
                        this.doc.text(i.name + " x " + i.quantity, 70, this.posY, "left");
                        this.doc.setFontSize(11);
                        this.doc.text((i.unitPrice.value * i.quantity).toFixed(2) + " " + CURRENCY_SYMBOLS[i.unitPrice.currency], this.posX2, this.posY, "right");

                    }
                }
            }

            await this.checkPosY();
            this.posY = this.posY + 15;
            this.doc.setDrawColor("#91a1b5");
            this.doc.setLineWidth(1.0);
            this.doc.line(this.posX1, this.posY, this.posX2, this.posY);





            //if (this.bookingItem.ancillaryQuotes[0].extraQuotes && this.bookingItem.ancillaryQuotes[0].extraQuotes.length > 0) {
            if (this.bookingItem.ancillaryQuotes[0].extraQuotes && this.bookingItem.ancillaryQuotes[0].extraQuotes.length > 1) {
                await this.checkPosY();
                this.posY = this.posY + 20;
                this.doc.setFontSize(11);
                this.doc.text(this.$t("PDF.travel_extras"), this.posX1, this.posY, "left");

                for (const i of this.bookingItem.ancillaryQuotes[0].extraQuotes) {
                    for (const e of i.variantQuotes) {
                        if (e.quantity > 0) {
                            await this.checkPosY();
                            this.posY = this.posY + 20;
                            this.doc.setFontSize(11);
                            this.doc.text(i.name, 70, this.posY, "left");
                            this.doc.setFontSize(11);
                            await this.checkPosY();
                            this.posY = this.posY + 15;
                            this.doc.text(e.name, 70, this.posY, "left");
                            this.doc.setFontSize(14);
                            this.doc.text((e.unitPrice.value * e.quantity).toFixed(2) + " " + CURRENCY_SYMBOLS[e.unitPrice.currency], this.posX2, this.posY, "right");

                        }
                    }
                }

                await this.checkPosY();
                this.posY = this.posY + 15;
                this.doc.setDrawColor("#91a1b5");
                this.doc.setLineWidth(1.0);
                this.doc.line(this.posX1, this.posY, this.posX2, this.posY);

            }


            await this.checkPosY();
            this.posY = this.posY + 22;
            this.doc.setTextColor("#AD3227");
            this.doc.setFontSize(14);
            this.doc.text(this.$t("PDF.total").toUpperCase(), this.posX1, this.posY, "left");
            this.doc.setFontSize(14);
            this.doc.text((this.bookingItem.totalCharged.value).toFixed(2) + " " + CURRENCY_SYMBOLS[this.bookingItem.totalCharged.currency], this.posX2, this.posY, "right");

            await this.checkPosY();
            this.posY = this.posY + 12;
            this.doc.setDrawColor("#91a1b5");
            this.doc.setLineWidth(1.0);
            this.doc.line(this.posX1, this.posY, this.posX2, this.posY);


            await this.checkPosY();
            this.posY = this.posY + 20;
            this.doc.setTextColor("#002d56");
            this.doc.setFontSize(11);
            this.doc.text(this.$t("PDF.payment_method"), this.posX1, this.posY, "left");

            if (this.bookingItem.paymentInformation.paymentMethod === 'creditCard') {
                this.doc.setFontSize(11);
                this.doc.text(this.$t("PDF.credit_card"), this.posX2, this.posY, "right");
            }
            else if (this.bookingItem.paymentInformation.paymentMethod === 'cash') {
                this.doc.setFontSize(11);
                this.doc.text(this.$t("PDF.cash"), this.posX2, this.posY, "right");
            }
            else {
                this.doc.setFontSize(14);
                this.doc.text("-", this.posX2, this.posY, "right");
            }

            await this.checkPosY();
            this.posY = this.posY + 15;
            this.doc.setFontSize(11);
            this.doc.text(this.$t("PDF.status"), this.posX1, this.posY, "left");
            this.doc.setFontSize(11);
            this.doc.text(this.$t('PDF.booking_status_' + this.bookingItem.bookingStatus), this.posX2, this.posY, "right");

            if (this.bookingItem.paymentInformation.paymentMethod === 'creditCard') {
                await this.checkPosY();
                this.posY = this.posY + 15;
                this.doc.setDrawColor("#91a1b5");
                this.doc.setLineWidth(1.0);
                this.doc.line(this.posX1, this.posY, this.posX2, this.posY);
            }

            if (this.bookingItem.paymentInformation.paymentMethod === 'creditCard') {
                await this.checkPosY();
                this.posY = this.posY + 20;
                this.doc.setFontSize(11);
                this.doc.text(this.$t("PDF.credit_card_number"), this.posX1, this.posY, "left");
                this.doc.setFontSize(11);
                this.doc.text(this.bookingItem.paymentInformation.paymentCreditCardMasked, this.posX2, this.posY, "right");
            }

            await this.checkPosY();
            this.posY = this.posY + 15;
            this.doc.setDrawColor("#91a1b5");
            this.doc.setLineWidth(1.0);
            this.doc.line(this.posX1, this.posY, this.posX2, this.posY);

            await this.checkPosY();
            this.posY = this.posY + 20;
            this.doc.setFontSize(11);
            this.doc.text(this.$t("PDF.created_at"), this.posX1, this.posY, "left");
            this.doc.setFontSize(11);
            this.doc.text(this.formatDate(this.bookingItem.createdDate) + " " + this.formatTime_utc(this.bookingItem.createdDate), this.posX2, this.posY, "right");

            let html = '';

            // ****** RENDERIZADO *****

            let path = `${this.bookingItem.bookingId}${'.pdf'}`;

            this.doc.html(html, {
                callback: () => {
                    this.doc.save(path);  // Usamos arrow function para mantener el contexto correcto de 'this'
                },
            });


            this.loading = false;
        }
    },

};
</script>

<style scoped>
.hidden {
    visibility: hidden !important;
}
</style>
