<template>
    <div>
        <md-dialog :md-close-on-esc="false" :md-click-outside-to-close="false" :md-active.sync="show"
            class="modal-category">
            <md-dialog-title class="modal-category-header">
                <div class="md-layout-item md-size-100 form-toolbar">
                    <h3 v-if="parentName == ''">{{ $t("extras") }}</h3>
                    <h3 v-if="parentName != ''">{{ this.parentName }} - {{ $t("extras") }}</h3>
                    <md-progress-spinner class="spinner-load" v-if="loading" :md-diameter="30" :md-stroke="3"
                        md-mode="indeterminate">
                    </md-progress-spinner>
                </div>
                <md-button @click="closeModal()" class="
                                                    md-primary md-just-icon md-round md-simple
                                                    modal-close-btn
                                                ">
                    <md-icon class="md-size-2x">close</md-icon>
                </md-button>
                <div class=" md-layout-item md-size-100 warning-message">
                    {{ $t("messages.warning_pricing") }}
                </div>
            </md-dialog-title>
            <md-dialog-content>
                <!-- Extra List -->
                <div class="content-div" v-if="!creating && !editing">
                    <div class="inline-toolbar">
                        <md-field class="search-input">
                            <md-icon class="search-icon">image_search</md-icon>
                            <label>{{ $t("label.search_extraname") }}</label>
                            <md-input v-model="search" @keyup.enter="filter()"></md-input>
                            <md-button v-if="search" class="btn-floating" @click="clearFilter()">
                                <md-icon>close</md-icon>
                            </md-button>
                        </md-field>
                        <md-button class="md-primary md-round" @click="filter()">
                            {{ $t("search") }}
                        </md-button>
                        <md-button @click="newItem()" class="md-primary md-round">
                            {{ $t("new") }}
                        </md-button>
                    </div>
                    <md-table table-header-color="blue" v-model="extraList" :md-sort-order.sync="currentSortOrder"
                        :md-sort.sync="currentSort" :md-sort-fn="customSort">
                        <md-table-row slot="md-table-row" slot-scope="{ item }" v-model="extraList">
                            <md-table-cell md-sort-by="order" :md-label="$t('label.extra_order')">{{ item.order
                            }}</md-table-cell>
                            <md-table-cell md-sort-by="name" :md-label="$t('label.extra_name')">{{ item.name
                            }}</md-table-cell>
                            <md-table-cell md-sort-by="active" :md-label="$t('label.extra_enabled')">
                                <img class="Icons-Enabled" src="@/assets/icons-tick.svg" v-show="item.active">
                                <img class="Icons-Enabled" src="@/assets/icons-cross.svg" v-show="!item.active">
                            </md-table-cell>
                            <md-table-cell class="table-cell-actions">
                                <button class="btn-icon" @click="editItem(item.extraId)">
                                    <md-icon>edit</md-icon>
                                </button>
                                <button class="btn-icon" @click="confirmDelete(item)">
                                    <md-icon>delete</md-icon>
                                </button>
                            </md-table-cell>
                        </md-table-row>
                    </md-table>
                    <div class="pagination-container">
                        <md-pagination v-model="page" :noArrows="false" :perPage="limit" :total="total" />
                    </div>
                </div>
                <!-- Extra form -->
                <div v-if="creating || editing">
                    <ValidationObserver tag="form" ref="form" v-slot="{ errors }">
                        <div class="md-layout form-layout">
                            <div class="md-layout-item md-size-40">
                                <md-field>
                                    <md-select v-model="item.order" :disabled="loading"
                                        :placeholder="$t('label.extra_order')">
                                        <md-option v-for="(order, index) in orders" :key="index" :value="order.value">
                                            {{ order.text }}
                                        </md-option>
                                    </md-select>
                                    <span class="md-error">
                                        {{ errorOrderMessage }}
                                    </span>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-size-20">
                            </div>
                            <div class="md-layout-item md-size-40">
                                <!-- Active -->
                                <div class="field-switch">
                                    <Span>{{ $t("label.extra_enabled") }}</Span>
                                    <md-switch id="switchExtraEnabled" v-model="item.active">
                                    </md-switch>
                                </div>
                            </div>
                            <div class="md-layout-item md-size-40">
                                <!-- media id -->
                                <div class="md-layout" style="">
                                    <div>
                                        <md-icon class="md-42">iconimage_search</md-icon>
                                    </div>
                                    <div class="md-layout-item md-size-65">
                                        <md-field>
                                            <label class="label-images" v-if="item.media.fileName == ''">{{
                                                $t("label.extra_image")
                                            }}</label>
                                            <label v-if="item.media.fileName == ''">{{ $t("label.select_image")
                                            }}</label>
                                            <label v-else>{{ $t("label.extra_image") }}</label>
                                            <md-input readonly id="mediaInput" v-model="item.media.fileName"
                                                :disabled="true">
                                            </md-input>
                                            <md-button v-if="item.media.fileName != ''" class="btn-floating"
                                                @click="clearMedia()">
                                                <md-icon>close</md-icon>
                                            </md-button>
                                            <span class="md-error">
                                                {{ errorMediaMessage }}
                                            </span>
                                        </md-field>
                                    </div>
                                    <div class="md-layout-item md-size-20">
                                        <md-button @click="openMediaModal()" class="md-primary md-round">
                                            {{ $t("select_file") }}
                                        </md-button>
                                    </div>
                                    <!-- media image preview -->
                                    <div class="image-container">
                                        <md-icon v-if="item.media.imageURL == ''" class="md-size-4x">image</md-icon>
                                        <img v-else :src="item.media.imageURL"
                                            @click.prevent="viewImage(item.media.imageURL)" />
                                    </div>
                                </div>
                            </div>

                            <div class="md-layout-item md-size-100">
                                <!-- Separator localizations -->
                                <div class="Group-Header">
                                    <span class="Luggage-information">
                                        {{ $t('label.language_information') }}
                                    </span>
                                    <div class="Path"></div>
                                </div>
                                <!-- Translations -->
                                <md-card class="translations-card">
                                    <md-card-content>
                                        <md-tabs class="translations-tabs">
                                            <md-tab id="tab-tr" :md-label="$t('languages.tr')"
                                                :md-icon="getLanguageIcon('tr', errors)">
                                                <!-- name tr -->
                                                <md-field :class="[
                                                    { 'md-valid': errors['name_tr'] && !errors['name_tr'][0] && touchedFields.name_tr },
                                                    { 'md-error': errors['name_tr'] && errors['name_tr'][0] }
                                                ]">
                                                    <label for="title">{{ $t('label.extra_name') }}</label>
                                                    <validation-provider name='name_tr' rules="required"
                                                        v-slot="{ errors, touched }" mode="aggressive">
                                                        <md-input v-model="item.name_tr" type="text"
                                                            :placeholder="$t('label.extra_name')" :disabled="loading">
                                                        </md-input>
                                                        <slide-y-down-transition>
                                                            <md-icon class="error"
                                                                v-show="errors[0] && touched">close</md-icon>
                                                        </slide-y-down-transition>
                                                        <span class="md-error">{{ errors[0] }}</span>
                                                    </validation-provider>
                                                </md-field>
                                                <!-- title tr -->
                                                <md-field :class="[
                                                    { 'md-valid': errors['title_tr'] && !errors['title_tr'][0] && touchedFields.title_tr },
                                                    { 'md-error': errors['title_tr'] && errors['title_tr'][0] }
                                                ]">
                                                    <label for="title">{{ $t('label.extra_title') }}</label>
                                                    <validation-provider name='title_tr' rules="required"
                                                        v-slot="{ errors, touched }" mode="aggressive">
                                                        <md-input v-model="item.title_tr" type="text"
                                                            :placeholder="$t('label.extra_title')" :disabled="loading">
                                                        </md-input>
                                                        <slide-y-down-transition>
                                                            <md-icon class="error"
                                                                v-show="errors[0] && touched">close</md-icon>
                                                        </slide-y-down-transition>
                                                        <span class="md-error">{{ errors[0] }}</span>
                                                    </validation-provider>
                                                </md-field>
                                                <!-- description tr -->
                                                <div class="md-layout-item md-size-100">
                                                    <md-field :class="[
                                                        { 'md-valid': errors['description_tr'] && !errors['description_tr'][0] && touchedFields.description_tr },
                                                        { 'md-error': errors['description_tr'] && errors['description_tr'][0] }
                                                    ]">
                                                        <label for="title">{{ $t('fields.extra_description') }}</label>
                                                        <validation-provider name='description_tr' rules=""
                                                            v-slot="{ errors, touched }" mode="aggressive">
                                                            <md-textarea md-autogrow v-model="item.description_tr"
                                                                name="description_tr" type="text"
                                                                :placeholder="$t('label.extra_description')"
                                                                id="description_tr" :disabled="loading"></md-textarea>
                                                            <slide-y-down-transition>
                                                                <md-icon class="error"
                                                                    v-show="errors[0] && touched">close</md-icon>
                                                            </slide-y-down-transition>
                                                            <span class="md-error">{{ errors[0] }}</span>
                                                        </validation-provider>
                                                    </md-field>
                                                </div>
                                            </md-tab>
                                            <md-tab id="tab-en" :md-label="$t('languages.en')"
                                                :md-icon="getLanguageIcon('en', errors)">
                                                <!-- name en -->
                                                <md-field :class="[
                                                    { 'md-valid': errors['name_en'] && !errors['name_en'][0] && touchedFields.name_en },
                                                    { 'md-error': errors['name_en'] && errors['name_en'][0] }
                                                ]">
                                                    <label for="title">{{ $t('label.extra_name') }}</label>
                                                    <validation-provider name='name_en' rules="required"
                                                        v-slot="{ errors, touched }" mode="aggressive">
                                                        <md-input v-model="item.name_en" type="text"
                                                            :placeholder="$t('label.extra_name')" :disabled="loading">
                                                        </md-input>
                                                        <slide-y-down-transition>
                                                            <md-icon class="error"
                                                                v-show="errors[0] && touched">close</md-icon>
                                                        </slide-y-down-transition>
                                                        <span class="md-error">{{ errors[0] }}</span>
                                                    </validation-provider>
                                                </md-field>
                                                <!-- title en -->
                                                <md-field :class="[
                                                    { 'md-valid': errors['title_en'] && !errors['title_en'][0] && touchedFields.title_en },
                                                    { 'md-error': errors['title_en'] && errors['title_en'][0] }
                                                ]">
                                                    <label for="title">{{ $t('label.extra_title') }}</label>
                                                    <validation-provider name='title_en' rules="required"
                                                        v-slot="{ errors, touched }" mode="aggressive">
                                                        <md-input v-model="item.title_en" type="text"
                                                            :placeholder="$t('label.extra_title')" :disabled="loading">
                                                        </md-input>
                                                        <slide-y-down-transition>
                                                            <md-icon class="error"
                                                                v-show="errors[0] && touched">close</md-icon>
                                                        </slide-y-down-transition>
                                                        <span class="md-error">{{ errors[0] }}</span>
                                                    </validation-provider>
                                                </md-field>
                                                <!-- description en -->
                                                <div class="md-layout-item md-size-100">
                                                    <md-field :class="[
                                                        { 'md-valid': errors['description_en'] && !errors['description_en'][0] && touchedFields.description_en },
                                                        { 'md-error': errors['description_tr'] && errors['description_en'][0] }
                                                    ]">
                                                        <label for="title">{{ $t('fields.extra_description') }}</label>
                                                        <validation-provider name='description_en' rules=""
                                                            v-slot="{ errors, touched }" mode="aggressive">
                                                            <md-textarea md-autogrow v-model="item.description_en"
                                                                name="description_en" type="text"
                                                                :placeholder="$t('label.extra_description')"
                                                                id="description_en" :disabled="loading"></md-textarea>
                                                            <slide-y-down-transition>
                                                                <md-icon class="error"
                                                                    v-show="errors[0] && touched">close</md-icon>
                                                            </slide-y-down-transition>
                                                            <span class="md-error">{{ errors[0] }}</span>
                                                        </validation-provider>
                                                    </md-field>
                                                </div>
                                            </md-tab>
                                        </md-tabs>
                                    </md-card-content>
                                </md-card>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="cont">
                            <md-button v-if="!view" :disabled="creating == true" class="md-primary md-round"
                                @click="showModal('variants')">
                                {{ $t("variants") }}
                            </md-button>
                            </div>
                            <md-button @click.prevent="cancel()" class="md-danger md-round" :disabled="loading">
                                {{ $t("cancel") }}
                            </md-button>
                            <md-button v-if="!view" @click.prevent="save()" type="submit" class="md-primary md-round"
                                :disabled="loading">
                                {{ $t("save") }}
                            </md-button>
                        </div>
                    </ValidationObserver>
                </div>
            </md-dialog-content>
        </md-dialog>
        <!-- Modal Confirm Delete -->
        <md-modal v-if="showConfirm" @close="showConfirm = false">
            <h3 slot="header">
                {{ $t("confirm") }}
            </h3>
            <div slot="body">
                {{ $t("confirm_messages.delete") }}
            </div>
            <div slot="footer" class="modal-footer">
                <md-button @click="showConfirm = false" class="md-danger md-round">
                    {{ $t("cancel") }}
                </md-button>
                <md-button @click="deleteItem()" class="md-primary md-round">
                    {{ $t("delete") }}
                </md-button>
            </div>
        </md-modal>
        <!-- Media Modal -->
        <media-modal :showProp="showMediaModal" @apply="applyMedia" @close="closeMediaModal()"></media-modal>
        <!-- Variants Modal-->
        <variant-modal :showProp="showVariantsModal" :parentIdProp="parentId" :subParentIdProp="item.extraId"
            :parentNameProp="item.name" @close="closeVariantModal()"></variant-modal>
    </div>
</template>

<script>
import MediaModal from "@/components/Modals/Media";
import VariantModal from "@/components/Modals/Variant";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import {
    getAncillaryExtras,
    getAncillaryExtra,
    createAncillaryExtra,
    updateAncillaryExtra,
    deleteAncillaryExtra
} from "@/services/extras.js";
import paginationMixin from "@/mixins/pagination";
import localeMixin from "@/mixins/locale";


export default {
    props: ['showProp', 'parentIdProp', 'parentNameProp'],
    mixins: [paginationMixin, localeMixin],
    components: {
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
        MediaModal,
        VariantModal,

    },
    data() {
        return {
            loading: false,
            show: false,
            showConfirm: false,
            imagesLightbox: [],
            lastOrder: 0,
            mode: null,
            parentId: "",
            parentName: "",
            touchedFields: {
                name_tr: false,
                name_en: false,
                title_tr: false,
                title_en: false,
                description_tr: false,
                description_en: false
            },
            extraList: [],
            item: {
                extraId: "",
                name: "",
                order: 0,
                mediaId: 0,
                imageURL: "",
                imageAlt: "",
                fileName: "",
                dateMode: 0,
                media: {
                    fileName: "", imageURL: "", imageAlt: ""
                },
                active: false,
                translationId_en: 0,
                translationId_tr: 0,
                name_tr: "",
                name_en: "",
                title_tr: "",
                title_en: "",
                description_en: "",
                description_tr: "",
            },
            view: false,
            creating: false,
            editing: false,
            search: "",
            ItemIdToDelete: null,
            orders: [],
            errorOrderMessage: "",
            errorMediaMessage: "",
            currentSort: 'order',
            currentSortOrder: 'asc',
            sort: "order,asc",
            showMediaModal: false,
            showVariantsModal: false,

        };


    },

    created: async function () {
    },


    watch: {
        page() {
            this.fetchItems();
        },

        showProp() {
            this.show = this.showProp;
            this.parentName = this.parentNameProp;
            this.parentId = this.parentIdProp;
            this.fillOrdersArray();
            if (this.show) {
                this.limit = 5;
                this.clearFilter();
                this.clearData();
                this.editing = false;
                this.creating = false;
                this.view = true;
                this.fetchItems();
            }
        },
    },


    methods: {

        showModal(mode) {
            switch (mode) {
                case "variants":
                    this.openVariantModal();
                    break;
                default:
                    break;
            }
        },

        openVariantModal() {
            this.showVariantsModal = true;
        },

        closeVariantModal() {
            this.showVariantsModal = false;
        },

        viewImage(imageUrl) {
            this.view = true;
            this.imagesLightbox = [];
            this.$imageViewer.images(this.imagesLightbox);
            const image = {
                url: imageUrl,
            };
            this.imagesLightbox.push(image);
            this.$imageViewer.index(0);
            this.$imageViewer.show();
        },

        fillOrdersArray() {
            let min = 0;
            let max = 50;
            this.orders = [];
            for (let i = min; i <= max; i++) {
                if (i == 0) {
                    let z = { value: i, text: this.$t('label.order_default') };
                    this.orders.push(z);
                } else {
                    let b = { value: i, text: i.toString() };
                    this.orders.push(b);
                }
            }
        },

        async fetchItems() {
            this.loading = true;
            let response = null;
            this.sort = this.currentSort + "," + this.currentSortOrder;
            response = await getAncillaryExtras(this.parentId, this.page, this.limit, this.search, this.sort);
            if (response) {
                this.extraList = response.result;
                this.total = response.pageInfo.totalPages * this.limit;
                this.lastOrder = response.lastOrder;
            } else {
                this.extraList = [];
                this.total = 0;
                this.lastOrder = response.lastOrder;
            }
            this.loading = false;
        },

        async fetchItem(itemId) {
            this.loading = true;
            let response = null;
            response = await getAncillaryExtra(this.parentId, itemId, this.page, this.limit, this.search);
            if (response) {
                this.assignDataModel(response);
            }
            this.loading = false;
        },


        async filter() {
            this.fetchItems();
        },

        clearFilter() {
            this.search = "";
            this.fetchItems();
        },

        newItem() {
            this.clearData();
            this.item.order = this.lastOrder;
            this.creating = true;
            this.editing = false;
            this.view = false;
        },

        editItem(itemId) {
            this.creating = false;
            this.clearData();
            this.editing = true;
            this.view = false;
            this.fetchItem(itemId);
        },


        cancel() {
            this.view = false;
            this.editing = false;
            this.creating = false;
        },

        closeModal() {
            this.show = false;
            this.$emit("close");
        },

        clearData() {

            this.item.extraId = "";
            this.item.mediaId = 0;
            this.item.imageURL = "";
            this.item.fileName = "";
            this.item.fileName = "";
            this.item.imageURL = "";
            this.item.media = {
                fileName: "", imageURL: ""
            };
            this.item.translationId_en = 0;
            this.item.translationId_tr = 0;
            this.item.name_tr = "";
            this.item.name_en = "";
            this.item.title_tr = "";
            this.item.title_en = "";
            this.item.description_tr = "";
            this.item.description_en = "";
        },

        applyMedia(media) {
            this.item.media = media;
            this.item.mediaId = media.mediaId;
            this.item.imageURL = media.imageURL;
            this.item.fileName = media.fileName;
            this.errorMediaMessage = "";
        },

        openMediaModal() {
            this.showMediaModal = true;
        },

        closeMediaModal() {
            this.showMediaModal = false;
            if (this.item.mediaId == 0) {
                this.errorMediaMessage = this.$t('validation.required', { _field_: this.$t('label.promo_image') });
            }
        },

        async save() {

            var valid = await this.$refs.form.validate();


            if (this.item.mediaId == 0) {
                valid = false;
                this.errorMediaMessage = this.$t('validation.required', { _field_: this.$t('label.extra_image') });
            }

            if (!valid) {
                this.$noty.error(this.$t('error.saved'));
            }

            if (valid) {
                this.loading = true;
                if (this.creating) {
                    let response = null;
                    response = await createAncillaryExtra(this.parentId, this.convertDataModel());
                    if (response.status == "201") {
                        this.$noty.success(this.$t('success.saved'));
                        this.cancel();
                        await this.fetchItems();
                    } else {
                        this.$noty.error(this.$t("error.saved"));
                    }
                }
                if (this.editing) {
                    let response = null;
                    response = await updateAncillaryExtra(this.parentId, this.convertDataModel());
                    if (response.status == "200") {
                        this.$noty.success(this.$t('success.saved'));
                        this.cancel();
                        await this.fetchItems();
                    } else {
                        this.$noty.error(this.$t("error.saved"));
                    }

                }
            }
            this.loading = false;
        },


        confirmDelete(Item) {
            this.ItemIdToDelete = Item.extraId;
            this.showConfirm = true;
        },

        async deleteItem() {
            this.loading = true;
            let response = null;
            response = await deleteAncillaryExtra(this.parentId, this.ItemIdToDelete);
            if (response) {
                this.$noty.success(this.$t("success.item_deleted"));
                this.showConfirm = false;
                this.ItemIdToDelete = "";
                this.fetchItems();
            }
            this.loading = false;
        },

        assignDataModel(item) {

            if (item.extraId) {
                this.item.extraId = item.extraId;
            } else {
                this.item.extraId = "";
            }

            if (item.order) {
                this.item.order = item.order;
            } else {
                this.item.order = 0;
            }

            if (item.name) {
                this.item.name = item.name;
            } else {
                this.item.name = "";
            }

            if (item.active) {
                this.item.active = Boolean(item.active);
            } else {
                this.item.active = false;
            }

            if (item.mediaId) {
                this.item.mediaId = item.mediaId;
            } else {
                this.item.mediaId = 0;
            }

            if (item.fileName) {
                this.item.media.fileName = item.fileName;
            } else {
                this.item.media.fileName = "";
            }

            if (item.imageURL) {
                this.item.media.imageURL = item.imageURL;
            } else {
                this.item.media.imageURL = "";
            }

            var translation = item.translations.filter(t => t.locale == 'en')[0];

            if (translation) {

                if (translation.translationId) {
                    this.item.translationId_en = translation.translationId;
                } else {
                    this.item.translationId_en = 0;
                }

                if (translation.name) {
                    this.item.name_en = translation.name;
                } else {
                    this.item.name_en = "";
                }

                if (translation.title) {
                    this.item.title_en = translation.title;
                } else {
                    this.item.title_en = "";
                }

                if (translation.description) {
                    this.item.description_en = translation.description;
                } else {
                    this.item.description_en = "";
                }

            } else {

                this.item.translationId_en = 0;
                this.item.name_en = "";
                this.item.title_en = "";
                this.item.description_en = "";

            }


            translation = item.translations.filter(t => t.locale == 'tr')[0];

            if (translation) {


                if (translation.translationId) {
                    this.item.translationId_tr = translation.translationId;
                } else {
                    this.item.translationId_tr = 0;
                }
                if (translation.name) {
                    this.item.name_tr = translation.name;
                } else {
                    this.item.name_tr = "";
                }

                if (translation.title) {
                    this.item.title_tr = translation.title;
                } else {
                    this.item.title_tr = "";
                }

                if (translation.description) {
                    this.item.description_tr = translation.description;
                } else {
                    this.item.description_tr = "";
                }

            } else {

                this.item.translationId_tr = 0;
                this.item.name_tr = "";
                this.item.title_tr = "";
                this.item.description_tr = "";

            }

        },

        customSort: function customSort(value) {
            this.fetchItems();
            return value;
        },

        convertDataModel() {

            var newItem = {};
            newItem.extraId = this.item.extraId;
            newItem.order = this.item.order;
            newItem.mediaId = this.item.mediaId;
            newItem.active = Number(this.item.active);
            newItem.translations = [];
            var trans_item_tr = {};
            trans_item_tr.translationId = this.item.translationId_tr;
            trans_item_tr.name = this.item.name_tr;
            trans_item_tr.title = this.item.title_tr;
            trans_item_tr.description = this.item.description_tr;
            trans_item_tr.locale = "tr";
            newItem.translations.push(trans_item_tr);
            var trans_item_en = {};
            trans_item_en.translationId = this.item.translationId_en;
            trans_item_en.name = this.item.name_en;
            trans_item_en.title = this.item.title_en;
            trans_item_en.description = this.item.description_en;
            trans_item_en.locale = "en";
            newItem.translations.push(trans_item_en);
            return newItem;

        }
    }

};
</script>

<style scope>
.modal-category-header {
    width: 100%;
    position: relative;
}

.spinner-load {
    margin-left: 12px;
    margin-top: 12px;
}

.modal-category-header .modal-close-btn {
    position: absolute;
    top: 0;
    right: 0;
}

.modal-category .modal-container {
    max-width: 1000px !important;
}

.category-table .md-table-cell:not(.table-cell-actions) {
    text-align: left;
}

.category-checkbox .md-checkbox {
    margin: 0;
}

.form-text-info {
    text-align: left;
    font-weight: normal;
}

.gapsm {
    gap: 25px;
}

.image-container {
    display: flex;
    float: left;
    justify-content: center;
    padding: 0px;
    width: 400px;
    max-width: 400px;
    max-height: 300px;
    min-height: 300px;
    margin-left: 40px;
    margin-bottom: 48px;
    background-color: var(--media-background);
    border: solid 1px var(--media-border);
}

.image-container img {
    width: 400px;
    height: 300px;
    object-fit: fill;
    cursor: pointer;
}


.inlinefields {
    display: flex;
    padding: 0px;
    justify-content: space-between;
}

.gaplg {
    gap: 250px;
}

.gapsm {
    gap: 140px;
}

.cont {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    grid-auto-rows: 40px;
    grid-auto-columns: max-content;
    align-items: start;
}

.cont .md-button {
    display: table-footer-group;
    width: 100%;
    height: 39px;
    justify-content: center !important;
    text-align: center !important;
}
</style>