import { API, Auth } from "aws-amplify";
import util from "util";

const getDestinations = async (page = 1, limit = 10, search = "",sortby="") => {
    let responseData = [];
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const language = process.env.VUE_APP_I18N_LOCALE;
    const airportCode = process.env.VUE_APP_AIRPORT_CODE;
    const path = ["/CMS/destination"];
    var headers = {
        "x-api-key": API_KEY,
        "x-app-language": language,
        "x-airport-code": airportCode,
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const queryStringParameters = {
        page: page,
        limit: limit,
        title: search,
        sort: sortby,
    };
    const myInit = {
        headers: headers,
        response: true,
        queryStringParameters: queryStringParameters,
    };
    try {
        var response = await API.get(API_NAME, path, myInit);
        responseData = response.data;
        responseData.status = response.status;

    } catch (error) {
        console.error(
            "Error getting destinations: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
    return responseData;
};

const getDestination= async (destinationId) => {
    let responseData = {};
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const language = process.env.VUE_APP_I18N_LOCALE;
    const airportCode = process.env.VUE_APP_AIRPORT_CODE;
    const path = ["/CMS/destination/" + destinationId];
    var headers = {
        "x-api-key": API_KEY,
        "x-app-language": language,
        "x-airport-code": airportCode,
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
    };
    try {
        var response = await API.get(API_NAME, path, myInit);
        responseData = response.data;
        responseData.status = response.status;

    } catch (error) {
        console.error(
            "Error getting destination detail: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
    return responseData;
};

const createDestination = async (item) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const language = process.env.VUE_APP_I18N_LOCALE;
    const airportCode = process.env.VUE_APP_AIRPORT_CODE;
    const path = ['/CMS/destination/'];
    var headers = {
        "x-api-key": API_KEY,
        "x-app-language": language,
        "x-airport-code": airportCode,
        "Authorization": (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
        body: item,
    };
    try {
        var response = await API.post(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error creating destination: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        let r = error.response.data;
        r.status = error.response.status;
        return r;
    }
};

const updateDestination = async (item) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const language = process.env.VUE_APP_I18N_LOCALE;
    const airportCode = process.env.VUE_APP_AIRPORT_CODE;
    const path = ['/CMS/destination/' + item.destinationId];
    var headers = {
        "x-api-key": API_KEY,
        "x-app-language": language,
        "x-airport-code": airportCode,
        "Authorization": (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
        body: item,
    };
    try {
        var response = await API.put(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error updating destination: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        let r = error.response.data;
        r.status = error.response.status;
    }
};

const deleteDestination = async (destinationId) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const language = process.env.VUE_APP_I18N_LOCALE;
    const airportCode = process.env.VUE_APP_AIRPORT_CODE;
    const path = ["/CMS/destination/" + destinationId];
    var headers = {
        "x-api-key": API_KEY,
        "x-app-language": language,
        "x-airport-code": airportCode,
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
    };
    try {
        var response = await API.del(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error deleting destination: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
};

export { 
    getDestinations,
    getDestination,
    createDestination,
    updateDestination,
    deleteDestination 
};
