<template>
    <ValidationObserver tag="form" ref="form" v-slot="{ errors }">
        <div class="md-layout">
            <!-- Button toolbar -->
            <div class="md-layout-item md-size-100 form-toolbar">
                <h3 class="page-title">
                    <span v-if="creating">{{ $t("routes.touristicRegion_create.title") }}</span>
                    <span v-if="editing">{{ $t("routes.touristicRegion_edit.title") }} - {{ item.title }}</span>
                </h3>
                <div class="form-buttons">
                    <md-progress-spinner v-if="loading" :md-diameter="30" :md-stroke="3" md-mode="indeterminate">
                    </md-progress-spinner>
                    <md-button type="submit" class="md-primary md-round btn-medium" @click.prevent="save()">
                        {{ $t("save") }}
                    </md-button>
                </div>
            </div>
            <!-- Regions input -->
            <div class="md-layout-item md-size-50">
                <md-field :class="[
                    { 'md-valid': errors['activities'] && !errors['activities'][0] && touchedFields.activities },
                    { 'md-error': errors['activities'] && errors['activities'][0] }
                ]">
                    <validation-provider name='activities' rules="" v-slot="{ errors }" mode="aggressive">
                        <md-select multiple :placeholder="$t('label.select_activities')" v-model="item.activities">
                            <md-option v-for="(activity, index) of activities" :value="activity" :key="index">
                                {{ JSON.parse(activity).title }}
                            </md-option>
                        </md-select>
                        <span class="md-error">{{ errors[0] }}</span>
                    </validation-provider>
                </md-field>
            </div>
            <div class="md-layout-item md-size-50 px-40">
                <div class="field-switch">
                    <span>{{ $t("label.enabled") }}</span>
                    <md-switch id="switchEnabled" v-model="item.enabled" :disabled="loading">
                    </md-switch>
                </div>
                <!-- Media input -->
                <div class="md-layout mt-40">
                    <div>
                        <md-icon class="md-42">iconimage_search</md-icon>
                    </div>
                    <div class="md-layout-item md-size-60">
                        <md-field class="image-field">
                            <label class="label-images" v-if="item.media.fileName == ''">{{
                                    $t("label.image")
                            }}</label>
                            <label v-if="item.media.fileName == ''">{{ $t("label.select_image") }}</label>
                            <label v-else>{{ $t("label.touristicRegion_image") }}</label>
                            <md-input readonly id="mediaInput" v-model="item.media.fileName" :disabled="true">
                            </md-input>
                            <md-button v-if="item.media.fileName != ''" class="btn-floating" @click="clearMedia()">
                                <md-icon>close</md-icon>
                            </md-button>
                            <span class="md-error">
                                {{ errorMediaMessage }}
                            </span>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-20">
                        <md-button @click="openMediaModal()" class="md-primary md-round">
                            {{ $t("select_file") }}
                        </md-button>
                    </div>
                </div>
                <!-- Image preview -->
                <div class="media-container">
                    <md-icon v-if="item.media.imageURL == ''" class="md-size-5x">image</md-icon>
                    <img v-else :src="item.media.imageURL" :alt="item.media.imageAlt"
                        @click.prevent="viewImage(item.media.imageURL)" />
                </div>
            </div>
            <!-- Separator location -->
            <div class="md-layout-item md-size-100">
                <div class="Group-Header">
                    <span class="Luggage-information">
                        {{ $t('label.location') }}
                    </span>
                    <div class="Path"></div>
                </div>
            </div>
            <div class="md-layout-item md-size-100 inlinefields gaplg">
                <!-- latitude -->
                <md-field :class="[
                    { 'md-valid': errors['latitude'] && !errors['latitude'][0] && touchedFields.latitude },
                    { 'md-error': errors['latitude'] && errors['latitude'][0] }
                ]">
                    <label for="luggageMaxHeight">{{ $t('fields.latitude') }}</label>
                    <validation-provider name='latitude' rules="double|required" v-slot="{ errors, touched }"
                        mode="aggressive">
                        <md-input v-model="item.latitude" name="latitude"
                            :placeholder="$t('label.latitude')" id="latitude" :disabled="loading">
                        </md-input>
                        <slide-y-down-transition>
                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                        </slide-y-down-transition>
                        <span class="md-error">{{ errors[0] }}</span>
                    </validation-provider>
                </md-field>

                <!-- longitude -->
                <md-field :class="[
                    { 'md-valid': errors['longitude'] && !errors['longitude'][0] && touchedFields.longitude },
                    { 'md-error': errors['longitude'] && errors['longitude'][0] }
                ]">
                    <label for="longitude">{{ $t('fields.longitude') }}</label>
                    <validation-provider name='longitude' rules="double|required" v-slot="{ errors, touched }"
                        mode="aggressive">
                        <md-input v-model="item.longitude" name="longitude"
                            :placeholder="$t('label.longitude')" id="longitude" :disabled="loading">
                        </md-input>
                        <slide-y-down-transition>
                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                        </slide-y-down-transition>
                        <span class="md-error">{{ errors[0] }}</span>
                    </validation-provider>
                </md-field>
            </div>
            <!-- Separator localizations -->
            <div class="md-layout-item md-size-100">
                <div class="Group-Header">
                    <span class="Luggage-information">
                        {{ $t("label.language_information") }}
                    </span>
                    <div class="Path"></div>
                </div>
            </div>
            <!-- Translations -->
            <md-card class="translations-card">
                <md-card-content>
                    <md-tabs class="translations-tabs">
                        <md-tab id="tab-tr" :md-label="$t('languages.tr')" :md-icon="getLanguageIcon('tr', errors)">
                            <div class="md-layout translations-inline">
                                <!-- Touristic Region Title TR -->
                                <md-field class="md-layout-item md-size-45" :class="[
                                    { 'md-valid': errors['name_tr'] && !errors['name_tr'][0] && touchedFields.title_tr },
                                    { 'md-error': errors['name_tr'] && errors['name_tr'][0] }
                                ]">
                                    <label>{{ $t("label.name") }}</label>
                                    <validation-provider name='name_tr' rules="required" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-input v-model="title_tr"></md-input>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                                <!-- Touristic Region Subtitle TR -->
                                <md-field class="md-layout-item md-size-45" :class="[
                                    { 'md-valid': errors['subtitle_tr'] && !errors['subtitle_tr'][0] && touchedFields.subtitle_tr },
                                    { 'md-error': errors['subtitle_tr'] && errors['subtitle_tr'][0] }
                                ]">
                                    <label>{{ $t("label.subtitle") }}</label>
                                    <validation-provider name='subtitle_tr' rules="" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-input v-model="subtitle_tr"></md-input>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                            <!-- Touristic Region summary TR -->
                            <md-field :class="[
                                { 'md-valid': errors['summary_tr'] && !errors['summary_tr'][0] && touchedFields.summary_tr },
                                { 'md-error': errors['summary_tr'] && errors['summary_tr'][0] }
                            ]">
                                <label for="text">{{ $t('label.summary') }}</label>
                                <validation-provider name='summary_tr' rules="" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-textarea :md-autogrow="false" f v-model="summary_tr" name="summary_tr"
                                        :placeholder="$t('label.summary')" id="summary_tr" :disabled="loading">
                                    </md-textarea>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <!-- Touristic Region text TR -->
                            <!-- CK Editor -->
                            <div class="rich-text-editor">
                                <validation-provider name='text_tr' rules="required" v-slot="{ errors }" mode="aggressive">
                                    <ckeditor :editor="editor" v-model="text_tr" :config="editorConfig"></ckeditor>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </div>
                        </md-tab>
                        <md-tab id="tab-en" :md-label="$t('languages.en')" :md-icon="getLanguageIcon('en', errors)">
                            <div class="md-layout translations-inline">
                            <!-- Touristic Region Title EN -->
                            <md-field class="md-layout-item md-size-45" :class="[
                                { 'md-valid': errors['name_en'] && !errors['name_en'][0] && touchedFields.title_en },
                                { 'md-error': errors['name_en'] && errors['name_en'][0] }
                            ]">
                                <label>{{ $t("label.name") }}</label>
                                <validation-provider name='name_en' rules="required" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="title_en"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <!-- Touristic Region Subtitle EN -->
                            <md-field class="md-layout-item md-size-45" :class="[
                                { 'md-valid': errors['subtitle_en'] && !errors['subtitle_en'][0] && touchedFields.subtitle_en },
                                { 'md-error': errors['subtitle_en'] && errors['subtitle_en'][0] }
                            ]">
                                <label>{{ $t("label.subtitle") }}</label>
                                <validation-provider name='subtitle_en' rules="" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="subtitle_en"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            </div>
                            <!-- Touristic Region summary EN -->
                            <md-field :class="[
                                { 'md-valid': errors['summary_en'] && !errors['summary_en'][0] && touchedFields.summary_en },
                                { 'md-error': errors['summary_en'] && errors['summary_en'][0] }
                            ]">
                                <label for="text">{{ $t('label.summary') }}</label>
                                <validation-provider name='summary_en' rules="" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-textarea :md-autogrow="false" f v-model="summary_en" name="summary_en"
                                        :placeholder="$t('label.summary')" id="summary_en" :disabled="loading">
                                    </md-textarea>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <!-- Touristic Region text EN -->
                            <!-- CK Editor -->
                            <div class="rich-text-editor">
                                <validation-provider name='text_en' rules="required" v-slot="{ errors }" mode="aggressive">
                                    <ckeditor :editor="editor" v-model="text_en" :config="editorConfig"></ckeditor>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </div>
                        </md-tab>
                    </md-tabs>
                </md-card-content>
            </md-card>

            <!-- Modal Buttons -->
            <div class="open-modal-buttons">
                <md-button :disabled="loading == true || creating == true" class="md-primary md-round btn-big"
                    @click="showModal('gallery')">
                    {{ $t("gallery") }}
                </md-button>
            </div>

            <!-- Media Modal -->
            <media-modal :showProp="showMediaModal" @apply="applyMedia" @close="closeMediaModal()"></media-modal>
            <!-- Gallery Modal -->
            <gallery-modal :showProp="showGalleryModal" :mode="'touristicRegion'" :parentId="item.touristicRegionId"
                :parentName="item.title" @close="closeGalleryModal()"></gallery-modal>
        </div>
    </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import MediaModal from "@/components/Modals/Media";
import GalleryModal from "@/components/Modals/Gallery";
import { CKEditorConfigMin } from "@/config";
import localeMixin from "@/mixins/locale";
import ClassicEditor from '@fiveflames/ckeditor5-build-classic';
import {
    getTouristicRegion,
    createTouristicRegion,
    updateTouristicRegion,
} from "@/services/touristic_region";
import {
    getActivitiesWithoutPagination,
} from "@/services/activities";

export default {
    mixins: [localeMixin],
    components: {
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
        MediaModal,
        GalleryModal,
    },
    data() {
        return {
            loading: false,
            view: false,
            creating: false,
            editing: false,
            touchedFields: {
                latitude:false,
                longitude:false,
                activities: false,
                title_en: false,
                subtitle_en: false,
                text_en: false,
                summary_en: false,
                title_tr: false,
                subtitle_tr: false,
                text_tr: false,
                summary_tr: false,
            },
            item: {
                touristicRegionId: null,
                id: "",
                title: "",
                subtitle: "",
                text: "",
                summary: "",
                enabled: true,
                mediaId: 0,
                latitude: 0,
                longitude: 0,
                imageURL: "",
                imageAlt: "",
                imageS3Key: "",
                media: {
                    fileName: "", imageURL: ""
                },
                gallery: [],
                activities: [
                ],
                translations: [
                    {
                        locale: "en",
                        title: "",
                        subtitle: "",
                        text: "",
                        summary: "",
                    },
                    {
                        locale: "tr",
                        title: "",
                        subtitle: "",
                        text: "",
                        summary: "",
                    },
                ],
            },
            activities: [],
            title_en: "",
            subtitle_en: "",
            text_en: "",
            summary_en: "",
            title_tr: "",
            subtitle_tr: "",
            text_tr: "",
            summary_tr: "",
            errorMediaMessage: "",
            showGalleryModal: false,
            showMediaModal: false,
            editor: ClassicEditor,
            editorConfig: CKEditorConfigMin,
        };
    },

    created: async function () {
        await this.fetchActivities();
        let touristicRegionId = this.$route.params.touristicRegionId;
        if (touristicRegionId) {
            this.editing = true;
            await this.fetchItem(touristicRegionId);
        } else {
            this.creating = true;
        }
    },

    methods: {

        viewImage(imageUrl) {
            this.imagesLightbox = [];
            this.$imageViewer.images(this.imagesLightbox);
            const image = {
                url: imageUrl,
            };
            this.imagesLightbox.push(image);
            this.$imageViewer.index(0);
            this.$imageViewer.show();
        },

        applyMedia(media) {
            this.item.media = media;
            this.item.mediaId = media.mediaId;
            this.item.imageURL = media.imageURL;
            this.item.fileName = media.fileName;
            this.errorMediaMessage = "";
        },

        assignDataModel(item) {
            this.item = item;
            this.item.enabled = this.item.enabled == 1 ? true : false;
            this.item.latitude = item.latitude;
            this.item.longitude = item.longitude; 
            this.item.media = {
                mediaId: item.mediaId,
                fileName: item.fileName,
                imageURL: item.imageURL,
            };
            if (item.activities) {
                let activities = [];
                item.activities.map(item => {
                    var b = this.activities.filter(t => JSON.parse(t).activityId == item.activityId)[0];
                    if (b) { activities.push(b); }
                });
                this.item.activities = activities;

            } else {
                this.item.activities = [];
            }
            this.putItemTranslations();
        },

        convertDataModel() {
            this.getItemTranslations();
            let newItem = {};
            newItem.touristicRegionId = this.item.touristicRegionId;
            newItem.id = this.item.id;
            newItem.mediaId = this.item.mediaId;
            newItem.enabled = this.item.enabled;
            newItem.latitude = this.item.latitude;
            newItem.longitude = this.item.longitude;
            newItem.gallery = this.item.gallery;
            newItem.activities = [];
            this.item.activities.map(item => {
                newItem.activities.push(JSON.parse(item));
            });
            newItem.translations = this.item.translations;
            return newItem;
        },

        async fetchActivities() {
            const responseActivities = await getActivitiesWithoutPagination();
            if (responseActivities) {
                responseActivities.map(item => {
                    let b = {
                        activityId: item.activityId,
                        id: item.id,
                        title: item.title,
                        enabled: item.enabled,
                    };
                    this.activities.push(JSON.stringify(b));
                });
            } else {
                this.activities = [];
            }            
        },

        async fetchItem(touristicRegionId) {
            this.loading = true;
            const response = await getTouristicRegion(touristicRegionId);
            if (response) {
                this.assignDataModel(response);
            }
            this.loading = false;
        },

        clearMedia() {
            this.item.media = {
                fileName: "", imageURL: ""
            };
            this.item.mediaId = 0;
            this.item.imageURL = this.item.media.imageURL;
            this.item.fileName = this.item.media.fileName;
            this.errorMediaMessage = "";
        },

        showModal(mode) {
            switch (mode) {
                case "gallery":
                    this.openGalleryModal();
                    break;
                default:
                    break;
            }
        },

        openGalleryModal() {
            this.showGalleryModal = true;
        },

        closeGalleryModal() {
            this.showGalleryModal = false;
        },

        openMediaModal() {
            this.showMediaModal = true;
        },

        closeMediaModal() {
            this.showMediaModal = false;
        },

        getItemTranslations() {
            let translation_en = this.item.translations.find(({ locale }) => locale == "en");
            if (translation_en) {
                translation_en.title = this.title_en;
                translation_en.subtitle = this.subtitle_en;
                translation_en.text = this.text_en;
                translation_en.summary = this.summary_en;
            } else {
                translation_en = {
                    locale: "en",
                    title: this.title_en,
                    subtitle: this.subtitle_en,
                    text: this.text_en,
                    summary: this.summary_en,
                }
            }
            let translation_tr = this.item.translations.find(({ locale }) => locale == "tr");
            if (translation_tr) {
                translation_tr.title = this.title_tr;
                translation_tr.subtitle = this.subtitle_tr;
                translation_tr.text = this.text_tr;
                translation_tr.summary = this.summary_tr;
            } else {
                translation_tr = {
                    locale: "tr",
                    title: this.title_tr,
                    subtitle: this.subtitle_tr,
                    text: this.text_tr,
                    summary: this.summary_tr,
                }
            }
            const translations = [translation_en, translation_tr];
            this.item.translations = translations;
        },

        putItemTranslations() {
            let translation_en = this.item.translations.find(({ locale }) => locale == "en");
            if (translation_en) {
                this.title_en = translation_en.title;
                this.subtitle_en = translation_en.subtitle;
                this.text_en = translation_en.text;
                this.summary_en = translation_en.summary;
            }
            let translation_tr = this.item.translations.find(({ locale }) => locale == "tr");
            if (translation_tr) {
                this.title_tr = translation_tr.title;
                this.subtitle_tr = translation_tr.subtitle;
                this.text_tr = translation_tr.text;
                this.summary_tr = translation_tr.summary;

            }
            const translations = [translation_en, translation_tr];
            this.item.translations = translations;
        },

        async save() {
            this.loading = true;
            var valid = await this.$refs.form.validate();
            if (this.item.mediaId == 0) {
                valid = false;
                this.errorMediaMessage = this.$t('validation.required', { _field_: this.$t('label.image') });
            }
            if (valid) {
                if (this.creating) {
                    let response = await createTouristicRegion(this.convertDataModel());
                    if (response) {
                        this.$noty.success(this.$t("success.saved"));
                        const touristicRegionId = response.data.touristicRegionId;
                        if (touristicRegionId) {
                            this.$router.push({ name: this.$i18n.locale + '.touristicRegion_edit', params: { touristicRegionId: touristicRegionId } });
                        } else {
                            this.$router.push({ name: this.$i18n.locale + '.touristicRegions' });
                        }
                    } else {
                        this.$noty.error(this.$t("error.saved"));
                    }
                }
                if (this.editing) {
                    let response = await updateTouristicRegion(this.convertDataModel());
                    if (response) {
                        this.$noty.success(this.$t("success.saved"));
                    } else {
                        this.$noty.error(this.$t("error.saved"));
                    }
                }
            } else {
                this.$noty.error(this.$t('error.saved'));
            }
            this.loading = false;
        },
    },
}
</script>

<style scoped>
.open-modal-buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
}

.inlinefields {
    display: flex;
    padding: 12px;
    justify-content: space-between;
}

.gaplg {
    gap: 250px;
}

.gapsm {
    gap: 25px;
}
.translations-inline {
    display: flex;
    justify-content: space-between;
}

</style>