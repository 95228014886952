<template>
    <div class="md-layout">
        <div class="md-layout-item md-size-100">
            <h3 class="page-title">
                {{ $t("routes.bookings.title") }}
                <md-progress-spinner v-if="loading" :md-diameter="20" :md-stroke="1" md-mode="indeterminate">
                </md-progress-spinner>
            </h3>
        </div>
        <div v-if="currentUser.role == roles.B2B_MG || currentUser.role == roles.B2B_OP"
            class="md-layout-item md-size-100 create-toolbar">
            <router-link :to="{ name: $i18n.locale + '.booking_create' }" class="md-primary md-round new-booking-button"
                tag="md-button">
                {{ $t("new_booking") }}
            </router-link>
        </div>
        <div class="md-layout-item md-size-100 inline-toolbar">
            <div class="md-layout">
                <div class="md-layout-item md-size-30">
                    <md-datepicker v-model="bookingFilters.fromEntryDateTime" :md-immediately="true">
                        <label>{{ $t("label.booking_fromEntryDateTime") }}</label>
                        <span class="md-error">
                            {{ errorFromEntryDateTimeMessage }}
                        </span>
                    </md-datepicker>
                </div>
                <div class="md-layout-item md-size-30">
                    <md-datepicker v-model="bookingFilters.toEntryDateTime" :md-immediately="true">
                        <label>{{ $t("label.booking_toEntryDateTime") }}</label>
                        <span class="md-error">
                            {{ errorToEntryDateTimeMessage }}
                        </span>
                    </md-datepicker>
                </div>
                <div class="md-layout-item md-size-30">
                    <md-field>
                        <label>{{ $t("label.booking_reference") }}</label>
                        <md-input v-model="bookingFilters.reference"></md-input>
                    </md-field>
                </div>
                <div class="md-layout-item md-size-30 hidden">
                    <md-datepicker v-model="bookingFilters.fromExitDateTime" :md-immediately="true">
                        <label>{{ $t("label.booking_fromExitDateTime") }}</label>
                        <span class="md-error">
                            {{ errorFromExitDateTimeMessage }}
                        </span>
                    </md-datepicker>
                </div>
                <div class="md-layout-item md-size-30 hidden">
                    <md-datepicker v-model="bookingFilters.toExitDateTime" :md-immediately="true">
                        <label>{{ $t("label.booking_toExitDateTime") }}</label>
                        <span class="md-error">
                            {{ errorToExitDateTimeMessage }}
                        </span>
                    </md-datepicker>
                </div>
                <div class="md-layout-item md-size-30 hidden">
                    <md-field>                        
                        <label>{{ $t("label.booking_clientEmail") }}</label>
                        <md-input v-model="bookingFilters.clientEmail"></md-input>
                    </md-field>
                </div>
                <div class="md-layout-item md-size-30">
                    <md-field>
                        <label>{{ $t("label.booking_clientName") }}</label>
                        <md-input v-model="bookingFilters.clientName"></md-input>
                    </md-field>
                </div>
                <div class="md-layout-item md-size-30">
                    <md-field>
                        <md-select v-model="bookingFilters.flightDirection" :disabled="loading"
                            :placeholder="$t('label.booking_flightDirection')">
                            <md-option :value="0">
                                {{ $t("label.booking_flightDirection_Value_def") }}
                            </md-option>
                            <md-option :value="1">
                                {{ $t("label.booking_flightDirection_Value_A") }}
                            </md-option>
                            <md-option :value="2">
                                {{ $t("label.booking_flightDirection_Value_D") }}
                            </md-option>
                        </md-select>
                    </md-field>
                </div>
                <div class="md-layout-item md-size-30">
                    <md-field>
                        <label>{{ $t("label.ancillary_name") }}</label>
                        <md-select v-model="bookingFilters.ancillaryId" :disabled="loading"
                            :placeholder="$t('label.ancillary_name')">
                            <md-option v-for="ancillary in ancillaries" :value="ancillary.ancillaryId"
                                :key="ancillary.ancillaryId">
                                {{ ancillary.name }}
                            </md-option>
                        </md-select>
                    </md-field>
                </div>
                <div class="md-layout-item md-size-30">
                    <md-field>
                        <label>{{ $t("label.airline_name") }}</label>
                        <md-select v-model="bookingFilters.airlineCode" :disabled="loading"
                            :placeholder="$t('label.airline_name')">
                            <md-option :value="''">
                                {{ $t("all") }}
                            </md-option>
                            <md-option v-for="airline in airlines" :value="airline.IATACode" :key="airline.IATACode">
                                {{ airline.name }}
                            </md-option>
                        </md-select>
                    </md-field>
                </div>
                <div class="md-layout-item md-size-30">
                    <md-field>
                        <md-select v-model="bookingFilters.status" :disabled="loading"
                            :placeholder="$t('label.booking_status')">

                            <md-option v-for="status in bookingStatus" :value="status.value" :key="status.value">
                                {{ $t('label.booking_status_' + status.value) }}
                            </md-option>
                        </md-select>
                    </md-field>
                </div>
                <div v-if="currentUser.role == roles.ADMIN" class="md-layout-item md-size-30">
                    <md-field>
                        <md-select v-model="bookingFilters.clientId" :disabled="loading"
                            :placeholder="$t('label.merchant_name')">
                            <md-option :value="''">
                                {{ $t("all") }}
                            </md-option>
                            <md-option v-for="(merchant, index) in merchants" :value="merchant.id" :key="index">
                                {{ merchant.name }}
                            </md-option>
                        </md-select>
                    </md-field>
                </div>
            </div>
            <div class="button-container">
                <md-button class="md-primary md-round btn-big" @click="filter()">
                    {{ $t("search") }}
                </md-button>
                <md-button class="md-danger md-round btn-big" @click="clear()">
                    {{ $t("clear") }}
                </md-button>
            </div>
        </div>
        <div class="md-chips">
            <md-chips v-model="Filters" md-placeholder="" @md-delete="removeFilter($event)">
            </md-chips>
        </div>
        <div class="md-layout-item md-size-100">
            <md-table table-header-color="blue" v-model="Items" :md-sort-order.sync="currentSortOrder"
                :md-sort.sync="currentSort" :md-sort-fn="customSort">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-sort-by="entryDateTime" :md-label="$t('label.booking_entryDateTime')">
                        <span class="table-content-center">
                            {{ formatDateTime(item.entryDateTime, true) }}
                        </span>
                    </md-table-cell>
                    <!-- <md-table-cell md-sort-by="exitDateTime" :md-label="$t('label.booking_exitDateTime')">
                        <span class="table-content-center">
                            {{ formatDateTime(item.exitDateTime, true) }}
                        </span>
                    </md-table-cell> -->
                    <md-table-cell md-sort-by="merchantName" :md-label="$t('label.merchant_name')">
                        <span class="table-content-center">
                            {{ item.merchantName }}
                        </span>
                    </md-table-cell>
                    <md-table-cell md-sort-by="reference" :md-label="$t('label.booking_reference')">
                        <span class="table-content-center">
                            {{ item.reference }}
                        </span>
                    </md-table-cell>
                    <md-table-cell md-sort-by="clientName" :md-label="$t('label.booking_clientName')">
                        <span class="table-content-center">
                            {{ item.clientName }}
                        </span>
                    </md-table-cell>
                    <md-table-cell md-sort-by="airlineName" :md-label="$t('label.airline_name')">
                        <span class="table-content-center">
                            {{ item.flightInformation.airlineName !== 'airline_translations.name' ?
                                item.flightInformation.airlineName : '-' }}
                        </span>
                    </md-table-cell>
                    <md-table-cell md-sort-by="flightDirection" :md-label="$t('label.booking_flightDirection')">
                        <span class="table-content-center" v-show="item.flightInformation.flightDirection == 'D'">{{
                            $t('label.booking_flightDirection_Value_D')
                        }}</span>
                        <span class="table-content-center" v-show="item.flightInformation.flightDirection == 'A'">{{
                            $t('label.booking_flightDirection_Value_A')
                        }}</span>
                    </md-table-cell>
                    <md-table-cell md-sort-by="flightNumber" :md-label="$t('label.flight')">
                        <span class="table-content-center">
                            {{ item.flightInformation.flightNumber }}
                        </span>
                    </md-table-cell>
                    <md-table-cell md-sort-by="ancillaryName" :md-label="$t('label.product')">
                        <span class="table-content-center">
                            {{ item.ancillaryName }}
                        </span>
                    </md-table-cell>
                    <md-table-cell md-sort-by="bookingStatus" :md-label="$t('label.booking_status')">
                        <span class="table-content-center">
                            <div class="select-booking-status">

                                <select :disabled="loading || item.bookingStatus === 5 || !canEditStatus()"
                                    class="booking-status-control"
                                    @change="updateBookingStatus($event, item.bookingId)">
                                    <!-- onblur="this.size = 1" onfocus="this.size = 5"> -->
                                    <option :value="status.value" v-for="status in bookingStatus" :key="status.value"
                                        :selected="status.value == item.bookingStatus">
                                        {{ $t('label.booking_status_' + status.value) }}
                                    </option>
                                </select>

                            </div>
                            <!-- {{ $t('label.booking_status_' + item.bookingStatus) }} -->
                        </span>
                    </md-table-cell>
                    <md-table-cell style="max-width: 12px!important;margin:0px;padding:0px!important">
                        <router-link v-if="canEditB2B(item)" class="btn-icon"
                            :to="{ name: $i18n.locale + '.booking_edit_b2b', params: { bookingId: item.bookingId } }"
                            tag="button" style="padding: 0px!important">
                            <md-icon>edit</md-icon>
                        </router-link>
                        <router-link v-else class="btn-icon"
                            :to="{ name: $i18n.locale + '.booking_edit', params: { bookingId: item.bookingId } }"
                            tag="button" style="padding: 0px!important;">
                            <md-icon>edit</md-icon>
                        </router-link>
                        <button class="btn-icon" @click="generateBookingPDF(item)">
                            <md-icon>picture_as_pdf</md-icon>
                        </button>
                        <button v-if="false" class="btn-icon" @click="downloadBookingSummaryPDF(item)">
                            <md-icon>download</md-icon>
                        </button>
                        <pdfBooking :selectedItem="item"></pdfBooking>
                    </md-table-cell>
                </md-table-row>
            </md-table>
            <!-- Pagination -->
            <div class="pagination-container">
                <md-pagination v-model="page" :noArrows="false" :perPage="limit" :total="total" />
            </div>
        </div>
        <md-modal v-if="showConfirm" @close="showConfirm = false">
            <h3 slot="header">
                {{ $t("confirm") }}
            </h3>
            <div slot="body">
                {{ $t("confirm_messages.cancel") }}
            </div>
            <div slot="footer" class="modal-footer">
                <md-button @click="showConfirm = false" class="md-danger md-round">
                    {{ $t("cancel") }}
                </md-button>
                <md-button @click="deleteItem()" class="md-primary md-round">
                    {{ $t("Ok") }}
                </md-button>
            </div>
        </md-modal>
    </div>
</template>

<script>
import stateManager from '../../../store/stateBookingListManager';
import statePageManager from '../../../store/statePageManager';

import { DateTime } from 'luxon';
import jsPDF from 'jspdf';
const axios = require("axios");
import {
    getBookings,
    printBooking,
} from "@/services/booking";
import paginationMixin from "@/mixins/pagination";
import localeMixin from "@/mixins/locale";
import authMixin from "@/mixins/auth";
import { BOOKING_STATUS, Roles, CURRENCY_SYMBOLS } from "@/config";

import {
    updateBooking
} from "@/services/booking";
import {
    getAncillaries
} from "@/services/ancillary";
import {
    getAirlines
} from "@/services/airline";
import {
    getClients
} from "@/services/client";


import pdfBooking from "../../../components/pdf/pdfBooking.vue";


export default {
    mixins: [paginationMixin, localeMixin, authMixin],
    components: {
        pdfBooking,
    },
    data() {
        return {
            state: stateManager.getFiltersData(),
            pageState: statePageManager.getPage(),
            bookingFilters: {
                fromEntryDateTime: null,
                toEntryDateTime: null,
                fromExitDateTime: null,
                toExitDateTime: null,
                reference: '',
                clientEmail: '',
                clientName: '',
                flightDirection: 0,
                ancillaryId: null,
                airlineCode: '',
                status: null,
                clientId: null,
            },
            errorFromEntryDateTimeMessage: "",
            errorToEntryDateTimeMessage: "",
            errorFromExitDateTimeMessage: "",
            errorToExitDateTimeMessage: "",
            Items: [],
            Filters: [],
            ancillaries: [],
            airlines: [],
            search: "",
            lastOrder: 0,
            currentSort: 'entryDateTime',
            currentSortOrder: 'desc',
            sort: "entryDateTime,desc",
            loading: false,
            showConfirm: false,
            ItemIdToDelete: null,
            bookingStatus: BOOKING_STATUS,
            roles: Roles,
            merchants: [],
        };
    },
    beforeRouteLeave: function (to, from, next) {
        //Borramos los filtros del storage si salimos de edicion de book
        if (!to.name.includes(".booking_edit")) {
            this.setDefaultValuesForFilters();
            stateManager.setFiltersData({ bookingFilters: this.bookingFilters, Filters: this.Filters, search: this.search });
            statePageManager.setPage(null);
        }
        next();
    },

    watch: {

        page: async function (newValue) {
            statePageManager.setPage(newValue);
            await this.fetchItems();
        },
        'bookingFilters.flightDirection': async function (newValue, oldValue) {
            if (oldValue != newValue) {
                this.resetPage();
                await this.fetchItems();
            }
        },
        'bookingFilters.ancillaryId': async function (newValue, oldValue) {
            if (oldValue != newValue) {
                this.resetPage();
                await this.fetchItems();
            }
        },
        'bookingFilters.airlineCode': async function (newValue, oldValue) {
            if (oldValue != newValue) {
                this.resetPage();
                await this.fetchItems();
            }
        },
        'bookingFilters.status': async function (newValue, oldValue) {
            if (oldValue != newValue) {
                this.resetPage();
                await this.fetchItems();
            }
        },
        'bookingFilters.clientId': async function (newValue, oldValue) {
            if (oldValue != newValue) {
                this.resetPage();
                await this.fetchItems();
            }
        }



    },

    created: async function () {

        let currentPage = 1;
        // Verificar si hay filtros guardados para decidir qué valores establecer
        if (this.state && this.state.bookingFilters) {
            currentPage = this.pageState.page;
            this.bookingFilters = this.state.bookingFilters;            
        } else {
            // Si no hay filtros guardados, establecer los valores por defecto
            this.setDefaultValuesForFilters();
        }

        await this.fetchAncillaries();
        await this.fetchAirlines();
        await this.fetchClients();

        //Ponemos página
        if (!currentPage){
            currentPage = 1;
        }
        this.page = currentPage;
       

        if (!this.Items.length) {
            await this.fetchItems();
        }


        this.configDatePickerLocale();

    },


    methods: {

        resetPage() {
            this.page = 1;
        },

        isDateFormat(dateString) {
            const date = new Date(dateString);
            const formattedDate = date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
            return formattedDate === dateString;
        },

        formatDateTime(dateTimeString) {
            // Convertir la fecha y hora al timezone específico de Dalaman
            const entryDateTime = DateTime.fromISO(dateTimeString, { zone: 'utc' });
            let localDateTime = entryDateTime.setZone(process.env.VUE_APP_AIRPORT_TIMEZONE);
            const formattedDateTimeString = localDateTime.toFormat('dd/MM/yyyy HH:mm');
            return formattedDateTimeString;
        },

        async fetchAncillaries() {
            this.loading = true;
            const response = await getAncillaries(null, null);
            if (response) {
                this.ancillaries = response;
            }
            this.loading = false;
        },

        async fetchAirlines() {
            this.loading = true;
            const response = await getAirlines(null, null);
            if (response) {
                this.airlines = response;
            }
            this.loading = false;
        },

        async fetchClients() {
            this.loading = true;
            const response = await getClients(null, null);
            if (response) {
                this.merchants = response;
            }
            this.loading = false;
        },


        async fetchItems() {
            this.loading = true;
            await this.processFilters();
            this.sort = this.currentSort + "," + this.currentSortOrder;
            const response = await getBookings(this.page, this.limit, this.search, this.sort);
            if (response) {
                this.Items = response.result;
                this.total = response.pageInfo.totalPages * this.limit;
                this.lastOrder = response.lastOrder;
            } else {
                this.Items = [];
                this.total = 0;
                this.lastOrder = 1;
            }
            this.loading = false;
        },

        filter() {
            this.resetPage();
            this.processFilters();

            this.fetchItems();
        },

        async clear() {
            this.resetPage();
            this.setDefaultValuesForFilters();

            await this.fetchItems();
        },

        setDefaultValuesForFilters() {

            this.bookingFilters = {
                fromEntryDateTime: null,
                toEntryDateTime: null,
                fromExitDateTime: null,
                toExitDateTime: null,
                reference: '',
                clientEmail: '',
                clientName: '',
                flightDirection: 0,
                ancillaryId: null,
                status: null,
                clientId: null,
            };
        },



        async processFilters() {
            this.Filters = [];
            var urlString = "";

            if (this.bookingFilters.fromEntryDateTime) {
                this.Filters.push(`${this.$t('label.booking_filter_from_entry')} ${this.formatDateTime(this.bookingFilters.fromEntryDateTime)}`);
                urlString += "&fromEntryDate=" + this.bookingFilters.fromEntryDateTime;
            }

            if (this.bookingFilters.toEntryDateTime) {
                this.Filters.push(`${this.$t('label.booking_filter_to_entry')}  ${this.formatDateTime(this.bookingFilters.toEntryDateTime)}`);
                urlString += "&toEntryDate=" + this.bookingFilters.toEntryDateTime;
            }

            if (this.bookingFilters.fromExitDateTime) {
                this.Filters.push(`${this.$t('label.booking_filter_from_exit')} ${this.formatDateTime(this.bookingFilters.fromExitDateTime)}`);
                urlString += "&fromExitDate=" + this.bookingFilters.fromExitDateTime;
            }

            if (this.bookingFilters.toExitDateTime) {
                this.Filters.push(`${this.$t('label.booking_filter_to_exit')}  ${this.formatDateTime(this.bookingFilters.toExitDateTime)}`);
                urlString += "&toExitDate=" + this.bookingFilters.toExitDateTime;
            }

            if (this.bookingFilters.reference) {
                this.Filters.push(`${this.$t('label.booking_reference_filter')} ${this.bookingFilters.reference}`);
                urlString += "&bookingRef=" + this.bookingFilters.reference;
            }

            if (this.bookingFilters.clientEmail) {
                this.Filters.push(`${this.$t('label.booking_mail')} ${this.bookingFilters.clientEmail}`);
                urlString += "&userEmail=" + this.bookingFilters.clientEmail;
            }

            if (this.bookingFilters.clientName) {
                this.Filters.push(`${this.$t('label.booking_clientName')} ${this.bookingFilters.clientName}`);
                urlString += "&userName=" + this.bookingFilters.clientName;
            }

            if (this.bookingFilters.flightDirection) {
                if (this.bookingFilters.flightDirection === 1) {
                    this.Filters.push(`${this.$t('label.booking_direction')}  ${this.$t('label.booking_flightDirection_Value_A')}`);
                    urlString += "&flightDirection=A";
                } else if (this.bookingFilters.flightDirection === 2) {
                    this.Filters.push(`${this.$t('label.booking_direction')}  ${this.$t('label.booking_flightDirection_Value_D')}`);
                    urlString += "&flightDirection=D";
                } else if (this.bookingFilters.flightDirection === 0) {
                    this.Filters.push(`${this.$t('label.booking_direction')} ${this.$t('label.booking_flightDirection_Value_def')}`);
                    urlString += "&flightDirection=D";
                }
            }

            if (this.bookingFilters.ancillaryId) {
                const ancillarySearch = this.ancillaries.find(ancillary => ancillary.ancillaryId == this.bookingFilters.ancillaryId);
                this.Filters.push(`${this.$t('label.ancillary_name')} ${ancillarySearch.name}`);
                urlString += "&ancillaryId=" + this.bookingFilters.ancillaryId;
            }

            if (this.bookingFilters.airlineCode) {
                const airlineSearch = this.airlines.find(airline => airline.IATACode == this.bookingFilters.airlineCode);
                this.Filters.push(`${this.$t('label.airline_name')} ${airlineSearch.name}`);
                urlString += "&airlineCode=" + this.bookingFilters.airlineCode;
            }

            if (this.bookingFilters.status != null && this.bookingFilters.status != -1) {
                this.Filters.push(`${this.$t('label.booking_status')} ${this.$t("label.booking_status_" + this.bookingFilters.status)}`);
                urlString += "&bookingStatus=" + this.bookingFilters.status;
            }

            if (this.bookingFilters.clientId) {
                const clientSearch = this.merchants.find(merchant => merchant.id == this.bookingFilters.clientId);
                this.Filters.push(`${this.$t('label.merchant_name')} ${clientSearch.name}`);
                urlString += "&clientId=" + this.bookingFilters.clientId;
            }

            // Filter by clientId with B2B roles
            const userRole = await this.getUserRole();
            if (userRole == Roles.B2B_MG || userRole == Roles.B2B_OP) {
                const clientId = await this.getUserClientId();
                urlString += "&clientId=" + clientId;
            }
            this.search = urlString.substring(1);

            stateManager.setFiltersData({ bookingFilters: this.bookingFilters, Filters: this.Filters, search: this.search, page: this.page });


        },

        syncFilter(filter) {


            if (filter.includes(this.$t('label.merchant_name'))) {
                this.bookingFilters.clientId = null;
            }

            if (filter.includes(this.$t('label.airline_name'))) {
                this.bookingFilters.airlineCode = null;
            }

            if (filter.includes(this.$t('label.booking_filter_from_entry'))) {
                this.bookingFilters.fromEntryDateTime = null;
            }
            if (filter.includes(this.$t('label.booking_filter_to_entry'))) {
                this.bookingFilters.toEntryDateTime = null;
            }
            if (filter.includes(this.$t('label.booking_filter_from_exit'))) {
                this.bookingFilters.fromExitDateTime = null;
            }
            if (filter.includes(this.$t('label.booking_filter_to_exit'))) {
                this.bookingFilters.toExitDateTime = null;
            }
            if (filter.includes(this.$t('label.booking_reference_filter'))) {
                this.bookingFilters.reference = '';
            }
            if (filter.includes(this.$t('label.booking_mail'))) {
                this.bookingFilters.clientEmail = '';
            }
            if (filter.includes(this.$t('label.booking_clientName'))) {
                this.bookingFilters.clientName = '';
            }
            if (filter.includes(this.$t('label.booking_direction'))) {
                this.bookingFilters.flightDirection = 0;
            }
            if (filter.includes(this.$t('label.ancillary_name'))) {
                this.bookingFilters.ancillaryId = null;
            }
            if (filter.includes(this.$t('label.booking_status'))) {
                this.bookingFilters.status = null;
            }
        },

        async removeFilter(filter) {
            this.resetPage();
            if (this.Filters.length == 0) {
                this.setDefaultValuesForFilters();
            } else {
                this.syncFilter(filter);
            }

            await this.fetchItems();
        },

        clearFilter() {
            this.search = "";
            this.Filters = [];
            this.fetchItems();
        },

        customSort: function customSort(value) {
            this.fetchItems();
            return value;
        },

        async updateBookingStatus(event, bookingId) {
            this.loading = true;
            const newStatus = Number(event.target.value);
            let body = { bookingStatus: newStatus };
            let response = await updateBooking(bookingId, body);
            if (response.status != "200") {
                this.$noty.error(this.$t('error.updated'));
            }
            this.loading = false;

        },

        /// <summary>
        // Create html document base with styles
        /// </summary>
        createHtmlWithCss() {
            const html = `
    <!DOCTYPE html>
    <html>
    <head>
      <meta charset="UTF-8">
      <style>
        .booking-table {
          width: 100%;
          background-color: #FFFFFF; /* White background color */
        }
        .booking-table td {
          color: #002d56;
        }
        h2 {
          color: #FFFFFF;
        }
        /* Add any other custom styles here */
      </style>
    </head>
    <body>
      [bodyHtml]
    </body>
    </html>
  `;

            return html;
        },

        /// <summary>
        // Convert a image url to a base 64
        /// </summary>
        async convertImageUrlToBase64(url) {
            try {
                const response = await axios.get(url, {
                    responseType: 'arraybuffer',
                });

                if (response.status === 200) {
                    // Convierte el ArrayBuffer a base64
                    const base64 = Buffer.from(response.data, 'binary').toString('base64');
                    return base64;
                } else {
                    console.error(`Error fetching image: ${response.statusText}`);
                    return null;
                }
            } catch (error) {
                console.error(`Error fetching image: ${error.message}`);
                return null;
            }
        },
        async generateBookingPDF(bookingItem) {
            var doc = new jsPDF({
                orientation: 'landscape', // 'landscape' para A4 horizontal
                unit: 'mm', // Unidades en milímetros
                format: 'a4', // Tamaño de página A4
            });

            const entryDate = this.formatDateTime(bookingItem.entryDateTime, false);

            const imageUrl = 'https://tst-dev-images.s3.eu-west-1.amazonaws.com/public/isotipo_negativo.png';
            let tmpImg = await this.convertImageUrlToBase64(imageUrl);
            let imageData = 'data:image/png;base64,' + tmpImg;

            // Añadimos imagen
            doc.addImage(imageData, 'PNG', 10, 10, 50, 27); // Ajusta las coordenadas y el tamaño según sea necesario

            var text = bookingItem.clientName.toUpperCase();
            var pageWidth = doc.internal.pageSize.width;
            var pageHeight = doc.internal.pageSize.height;
            // var words = text.split(' '); // Divide la cadena en palabras separadas por espacios
            var fontSize = 60; // Tamaño de fuente en puntos

            var yPosition = 120; // Posición vertical inicial

            doc.setFontSize(fontSize);
            doc.text(text, pageWidth / 2, pageHeight - yPosition, { align: 'center' });
            yPosition -= 30;
            // words.forEach(function (word) {
            //     const numberOfChars = word.length;
            //     var wordWidth = numberOfChars * 16;
            //     var xPosition = (pageWidth - wordWidth) / 2;
            //     doc.setFontSize(fontSize);
            //     doc.text(word, xPosition, yPosition);
            //     yPosition = yPosition - 30;
            //     yPosition += fontSize + 5; // Aumenta la posición vertical para la siguiente línea
            // });

            // Tabla info extra Ref Number
            fontSize = 12;
            doc.setFontSize(fontSize);
            var infoText = `Ref Number: ${bookingItem.bookingId}`;
            doc.text(infoText, pageWidth / 2, pageHeight - yPosition, { align: 'center' });
            if (bookingItem.ancillaryName) {
                infoText = `Product Type: ${bookingItem.ancillaryName}`;
                yPosition -= 10;
                doc.text(infoText, pageWidth / 2, pageHeight - yPosition, { align: 'center' });
            }
            if (bookingItem.entryDateTime) {
                const entryDateTime = this.formatDateTime(bookingItem.entryDateTime, true);
                infoText = `Entry Date: ${entryDateTime}`;
                yPosition -= 10;
                doc.text(infoText, pageWidth / 2, pageHeight - yPosition, { align: 'center' });
            }
            if (bookingItem.flightInformation.flightDateTime) {
                const flightDateTime = this.formatDateTime(bookingItem.flightInformation.flightDateTime, true);
                infoText = `Flight Date: ${flightDateTime}`;
                yPosition -= 10;
                doc.text(infoText, pageWidth / 2, pageHeight - yPosition, { align: 'center' });
            }
            if (bookingItem.paymentInformation.paymentMethod && bookingItem.bookingStatus && bookingItem.totalCharged) {
                const paymentMethod = bookingItem.paymentInformation.paymentMethod;
                const totalCharged = bookingItem.totalCharged.value;
                const currencySymbol = CURRENCY_SYMBOLS[bookingItem.totalCharged.currency] ? CURRENCY_SYMBOLS[bookingItem.totalCharged.currency] : '';
                const bookingStatus = this.$t('label.booking_status_' + bookingItem.bookingStatus);
                infoText = `Payment: ${paymentMethod} - ${bookingStatus} - ${totalCharged}${currencySymbol}`;
                yPosition -= 10;
                doc.text(infoText, pageWidth / 2, pageHeight - yPosition, { align: 'center' });
            }
            if (bookingItem.contactInformation.driverName || bookingItem.contactInformation.driverPhone || bookingItem.contactInformation.driverLicencePlate) {
                const driverName = bookingItem.contactInformation.driverName ? bookingItem.contactInformation.driverName : '';
                const driverPhone = bookingItem.contactInformation.driverPhone ? bookingItem.contactInformation.driverPhone : '';
                const driverLicencePlate = bookingItem.contactInformation.driverLicencePlate ? bookingItem.contactInformation.driverLicencePlate : '';
                infoText = `Driver's Detail: ${driverName} - ${driverPhone} - ${driverLicencePlate}`;
                yPosition -= 10;
                doc.text(infoText, pageWidth / 2, pageHeight - yPosition, { align: 'center' });
            }

            //Pie PDF con fecha y vuelo
            fontSize = 36;
            var margin = 20;
            yPosition = pageHeight - margin;
            let foot = `${bookingItem.flightInformation.flightNumber} - ${entryDate}`;
            const numberOfChars = foot.length;
            var wordWidth = numberOfChars * 7;
            var xPosition = (pageWidth - wordWidth) / 2;
            doc.setFontSize(fontSize);
            doc.text(foot, xPosition, yPosition);

            //Guardamos PDF
            doc.save(bookingItem.bookingId + '.pdf');
        },
        async downloadBookingSummaryPDF(bookingItem) {
            const bookingId = bookingItem.bookingId;
            const response = await printBooking(bookingId);
            if (response) {
                let element = response.content;
                // Create a new PDF document
                const pdf = new jsPDF('p', 'pt', 'a4');

                element = element.replace(/ı/g, 'i');
                element = element.replace(/ç/g, 'c');
                element = element.replace(/ş/g, 's');
                element = element.replace(/Ş/g, 'S');
                element = element.replace(/İ/g, 'I');
                element = element.replace(/Ğ/g, 'G');
                element = element.replace(/Ü/g, 'U');

                pdf.html(element, {
                    html2canvas: {
                        scale: 0.65
                    },
                    width: 220 * 0.70, // 210mm * 0.75 scale
                    height: 297 * 0.70, // 297mm * 0.75 scale
                    y: 25,
                    windowWidth: 910,
                    callback: function (doc) {
                        doc.save(bookingId + '.pdf');
                    }
                });
            } else {
                this.$noty.error(this.$t('error.pdf'));
            }
        },

        canEditB2B(booking) {
            const userRole = this.currentUser.role;
            if (booking.clientId && (userRole == Roles.B2B_MG || userRole == Roles.B2B_OP)) {
                return true;
            }
            return false;
        },

        canEditStatus() {
            const userRole = this.currentUser.role;
            if (userRole == Roles.ADMIN || userRole == Roles.ECOMM_MG || userRole == Roles.ECOMM_OP) {
                return true;
            }

            return false;
        },


    },
}
</script>

<style scoped>
.md-chips {
    margin: 0px;
    padding: 0px;
    margin-left: 12px;
    margin-top: -12px;
    margin-bottom: 12px;
}

span {
    margin-left: 0px;
}

.select-booking-status select {
    padding: 5px;
    border: none;
    background-color: white;
}

.create-toolbar {
    display: flex;
    justify-content: flex-end;
}

.new-booking-button {
    background-color: #d61d0f !important;
}

.new-booking-button:hover {
    background-color: #ad170c !important;
}

.button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.md-button {
    margin-bottom: 10px;
    /* Espacio entre los botones */
}
</style>