<template>
    <ValidationObserver tag="form" ref="form" v-slot="{ errors }">
        <div class="md-layout form-layout">

            <!-- Button toolbar -->
            <div class="md-layout-item md-size-100 form-toolbar">
                <h3 class="page-title">
                    <span v-if="creating">{{ $t("routes.mail_template_create.title") }}</span>
                    <span v-if="editing">{{ $t("routes.mail_template_edit.title") }} - {{ item.title }}</span>
                </h3>
                <div class="form-buttons">
                    <md-progress-spinner v-if="loading" :md-diameter="30" :md-stroke="3" md-mode="indeterminate">
                    </md-progress-spinner>
                    <md-button type="submit" class="md-primary md-round btn-medium" @click.prevent="save()">
                        {{ $t("save") }}
                    </md-button>
                </div>
            </div>

            <!-- Active -->
            <div class="md-layout-item md-size-45">
                <div class="field-switch">
                    <Span>{{ $t("label.mail_template_active") }}</Span>
                    <md-switch id="switchEnabled" v-model="item.enabled" :disabled="loading">
                    </md-switch>
                </div>
            </div>


            <div class="md-layout-item md-size-10"></div>

            <!-- Mail  type -->
            <div class="md-layout-item md-size-45">
                <md-field>
                    <md-select v-model="item.mailType" :disabled="loading"
                        :placeholder="$t('label.mail_template_mailType')">
                        <md-option :value="0">
                            {{ $t("label.mail_template_mailType_value_default") }}
                        </md-option>
                        <md-option :value="1">
                            {{ $t("label.mail_template_mailType_1") }}
                        </md-option>
                        <md-option :value="2">
                            {{ $t("label.mail_template_mailType_2") }}
                        </md-option>
                        <md-option :value="3">
                            {{ $t("label.mail_template_mailType_3") }}
                        </md-option>
                        <md-option :value="4">
                            {{ $t("label.mail_template_mailType_4") }}
                        </md-option>
                        <md-option :value="5">
                            {{ $t("label.mail_template_mailType_5") }}
                        </md-option>
                        <md-option :value="6">
                            {{ $t("label.mail_template_mailType_6") }}
                        </md-option>
                        <md-option :value="7">
                            {{ $t("label.mail_template_mailType_7") }}
                        </md-option>
                        <md-option :value="8">
                            {{ $t("label.mail_template_mailType_8") }}
                        </md-option>
                        <md-option :value="9">
                            {{ $t("label.mail_template_mailType_9") }}
                        </md-option>
                        <md-option :value="10">
                            {{ $t("label.mail_template_mailType_10") }}
                        </md-option>
                    </md-select>
                </md-field>
            </div>

            <!-- Separator localizations -->
            <div class="md-layout-item md-size-100">
                <div class="Group-Header">
                    <span class="Luggage-information">
                        {{ $t("label.language_information") }}
                    </span>
                    <div class="Path"></div>
                </div>
            </div>
            <!-- Translations -->
            <md-card class="translations-card">
                <md-card-content>
                    <md-tabs class="translations-tabs">
                        <md-tab id="tab-tr" :md-label="$t('languages.tr')" :md-icon="getLanguageIcon('tr', errors)">
                            <!-- Title TR -->
                            <md-field class="w-50" :class="[
                                { 'md-valid': errors['title_tr'] && !errors['title_tr'][0] && touchedFields.title_tr },
                                { 'md-error': errors['title_tr'] && errors['title_tr'][0] }
                            ]">
                                <label>{{ $t("label.title") }}</label>
                                <validation-provider name='title_tr' rules="required" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="title_tr"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <!-- Subtitle TR -->
                            <md-field :class="[
                                { 'md-valid': errors['subject_tr'] && !errors['subject_tr'][0] && touchedFields.subject_tr },
                                { 'md-error': errors['subject_tr'] && errors['subject_tr'][0] }
                            ]">
                                <label>{{ $t("label.subject") }}</label>
                                <validation-provider name='subject_tr' rules="" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="subject_tr"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <!-- Body TR -->
                            <div style="padding: 0px;margin-bottom: 12px;margin-top: -42px;opacity: 0.7;">
                                <span style="font-weight: bold;">Avaliable vars: </span>
                                <label style="font-style: italic;" v-if="item.mailType === 1">{{
                                    $t("label.mandatory_mail_template_mailType_1") }}</label>
                                <label style="font-style: italic;" v-if="item.mailType === 2">{{
                                    $t("label.mandatory_mail_template_mailType_2") }}</label>
                                <label style="font-style: italic;" v-if="item.mailType === 3">{{
                                    $t("label.mandatory_mail_template_mailType_3") }}</label>
                                <label style="font-style: italic;" v-if="item.mailType === 4">{{
                                    $t("label.mandatory_mail_template_mailType_4") }}</label>
                                <label style="font-style: italic;" v-if="item.mailType === 5">{{
                                    $t("label.mandatory_mail_template_mailType_5") }}</label>
                                <label style="font-style: italic;" v-if="item.mailType === 6">{{
                                    $t("label.mandatory_mail_template_mailType_6") }}</label>
                                <label style="font-style: italic;" v-if="item.mailType === 7">{{
                                    $t("label.mandatory_mail_template_mailType_7") }}</label>
                                <label style="font-style: italic;" v-if="item.mailType === 8">{{
                                    $t("label.mandatory_mail_template_mailType_8") }}</label>
                                <label style="font-style: italic;" v-if="item.mailType === 9">{{
                                    $t("label.mandatory_mail_template_mailType_9") }}</label>
                                <label style="font-style: italic;" v-if="item.mailType === 10">{{
                                    $t("label.mandatory_mail_template_mailType_10") }}</label>
                            </div>
                            <!-- CK Editor -->
                            <div class="rich-text-editor" v-if="item.mailType!=8">
                                <validation-provider name='body_tr' rules="required" v-slot="{ errors }" mode="aggressive">
                                    <ckeditor :editor="editor" v-model="body_tr" :config="editorConfig"></ckeditor>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </div>

                            <!-- body TR -->
                                 <md-field style="margin-top: 22px;" v-if="item.mailType==8" class="w-50" :class="[
                                { 'md-valid': errors['body_tr'] && !errors['body_tr'][0] && touchedFields.body_tr },
                                { 'md-error': errors['body_tr'] && errors['body_tr'][0] }
                            ]">
                                <label>{{ $t("label.body") }}</label>
                                <validation-provider name='body_tr' rules="required" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="body_tr"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>

                        </md-tab>
                        <md-tab id="tab-en" :md-label="$t('languages.en')" :md-icon="getLanguageIcon('en', errors)">
                            <!-- Title EN -->
                            <md-field class="w-50" :class="[
                                { 'md-valid': errors['title_en'] && !errors['title_en'][0] && touchedFields.title_en },
                                { 'md-error': errors['title_en'] && errors['title_en'][0] }
                            ]">
                                <label>{{ $t("label.title") }}</label>
                                <validation-provider name='title_en' rules="required" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="title_en"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <!-- Subtitle EN -->
                            <md-field :class="[
                                { 'md-valid': errors['subject_en'] && !errors['subject_en'][0] && touchedFields.subject_en },
                                { 'md-error': errors['subject_en'] && errors['subject_en'][0] }
                            ]">
                                <label>{{ $t("label.subject") }}</label>
                                <validation-provider name='subject_en' rules="" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="subject_en"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <!-- Body EN -->
                            <div style="padding: 0px;margin-bottom: 12px;margin-top: -42px;opacity: 0.7;">
                                <span style="font-weight: bold;">Avaliable vars: </span>
                                <label style="font-style: italic;" v-if="item.mailType === 1">{{
                                    $t("label.mandatory_mail_template_mailType_1") }}</label>
                                <label style="font-style: italic;" v-if="item.mailType === 2">{{
                                    $t("label.mandatory_mail_template_mailType_2") }}</label>
                                <label style="font-style: italic;" v-if="item.mailType === 3">{{
                                    $t("label.mandatory_mail_template_mailType_3") }}</label>
                                <label style="font-style: italic;" v-if="item.mailType === 4">{{
                                    $t("label.mandatory_mail_template_mailType_4") }}</label>
                                <label style="font-style: italic;" v-if="item.mailType === 5">{{
                                    $t("label.mandatory_mail_template_mailType_5") }}</label>
                                <label style="font-style: italic;" v-if="item.mailType === 6">{{
                                    $t("label.mandatory_mail_template_mailType_6") }}</label>
                                <label style="font-style: italic;" v-if="item.mailType === 7">{{
                                    $t("label.mandatory_mail_template_mailType_7") }}</label>
                                <label style="font-style: italic;" v-if="item.mailType === 8">{{
                                    $t("label.mandatory_mail_template_mailType_8") }}</label>
                                <label style="font-style: italic;" v-if="item.mailType === 9">{{
                                    $t("label.mandatory_mail_template_mailType_9") }}</label>
                                <label style="font-style: italic;" v-if="item.mailType === 10">{{
                                    $t("label.mandatory_mail_template_mailType_10") }}</label>
                            </div>
                            <!-- CK Editor -->
                            <div class="rich-text-editor" v-if="item.mailType!=8">
                                <validation-provider name='body_en' rules="required" v-slot="{ errors }" mode="aggressive">
                                    <ckeditor :editor="editor" v-model="body_en" :config="editorConfig"></ckeditor>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </div>

                            <!-- body EN -->
                            <md-field style="margin-top: 22px;" v-if="item.mailType==8" class="w-50" :class="[
                                { 'md-valid': errors['body_en'] && !errors['body_en'][0] && touchedFields.body_en },
                                { 'md-error': errors['body_en'] && errors['body_en'][0] }
                            ]">
                                <label>{{ $t("label.body") }}</label>
                                <validation-provider name='body_en' rules="required" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="body_en"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>

                        </md-tab>
                    </md-tabs>
                </md-card-content>
            </md-card>

        </div>
    </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import localeMixin from "@/mixins/locale";
import s3Mixin from "@/mixins/s3";
import { CKEditorConfig } from "@/config";
import ClassicEditor from '@fiveflames/ckeditor5-build-classic';
import {
    getMailTemplate,
    createMailTemplate,
    updateMailTemplate,
} from "@/services/mail_templates";

export default {
    mixins: [localeMixin, s3Mixin],
    components: {
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
    },
    data() {
        return {
            loading: false,
            creating: false,
            editing: false,
            touchedFields: {
                title_en: false,
                title_tr: false,
                subject_en: false,
                subject_tr: false,
                body_en: false,
                body_tr: false,
            },
            item: {
                mailType: 0,
                enabled: 0,
                translations: [
                    {
                        locale: "en",
                        title: "",
                        subject: "",
                        body: "",
                    },
                    {
                        locale: "tr",
                        title: "",
                        subject: "",
                        body: "",
                    }
                ],
            },
            title_en: "",
            title_tr: "",
            subject_en: "",
            subject_tr: "",
            body_en: "",
            body_tr: "",
            editor: ClassicEditor,
            editorConfig: CKEditorConfig,
        };
    },

    created: async function () {
        let mailTemplateId = this.$route.params.mailTemplateId;
        if (mailTemplateId) {
            this.editing = true;
            await this.fetchItem(mailTemplateId);
        } else {
            this.creating = true;
            this.item.mailType = 0;
        }
    },

    methods: {

        assignDataModel(item) {
            this.item = item;
            item.enabled = Boolean(item.enabled);
            this.putItemTranslations();
        },

        getItemTranslations() {
            let translation_en = this.item.translations.find(({ locale }) => locale == "en");
            if (translation_en) {
                translation_en.title = this.title_en;
                translation_en.subject = this.subject_en;
                translation_en.body = this.body_en;
            } else {
                translation_en = {
                    locale: "en",
                    title: this.title_en,
                    subject: this.subject_en,
                    body: this.body_en,
                }
            }
            let translation_tr = this.item.translations.find(({ locale }) => locale == "tr");
            if (translation_tr) {
                translation_tr.title = this.title_tr;
                translation_tr.subject = this.subject_tr;
                translation_tr.body = this.body_tr;
            } else {
                translation_tr = {
                    locale: "tr",
                    title: this.title_tr,
                    subject: this.subject_tr,
                    body: this.body_tr,
                }
            }
            const translations = [translation_en, translation_tr];
            this.item.translations = translations;
        },

        putItemTranslations() {
            let translation_en = this.item.translations.find(({ locale }) => locale == "en");
            if (translation_en) {
                this.title_en = translation_en.title;
                this.subject_en = translation_en.subject;
                this.body_en = translation_en.body;
            }
            let translation_tr = this.item.translations.find(({ locale }) => locale == "tr");
            if (translation_tr) {
                this.title_tr = translation_tr.title;
                this.subject_tr = translation_tr.subject;
                this.body_tr = translation_tr.body;
            }
            const translations = [translation_en, translation_tr];
            this.item.translations = translations;
        },

        async fetchItem(itemId) {
            this.loading = true;
            let response = await getMailTemplate(itemId);
            if (response) {
                this.assignDataModel(response);
            }
            this.loading = false;
        },

        async save() {
            this.loading = true;
            var valid = await this.$refs.form.validate();



            if (valid) {
                this.getItemTranslations();
                if (this.creating) {
                    this.item.enabled = Number(this.item.enabled);
                    const response = await createMailTemplate(this.item);
                    if (response) {
                        this.$noty.success(this.$t("success.saved"));
                        this.item.enabled = Boolean(this.item.enabled);
                        this.$router.push({ name: this.$i18n.locale + '.mail_templates' });
                    } else {
                        this.item.enabled = Boolean(this.item.enabled);
                        this.$noty.error(this.$t("error.saved"));
                    }
                } else if (this.editing) {
                    this.item.enabled = Number(this.item.enabled);
                    const response = await updateMailTemplate(this.item);
                    if (response) {
                        this.item.enabled = Boolean(this.item.enabled);
                        this.$noty.success(this.$t("success.saved"));
                    } else {
                        this.item.enabled = Boolean(this.item.enabled);
                        this.$noty.error(this.$t("error.saved"));
                    }
                }
            } else {
                this.$noty.error(this.$t('error.saved'));
            }

            this.loading = false;
        },

        handleImageAdded: async function (file, Editor, cursorLocation, resetUploader) {
            const result = await this.uploadS3(file);
            if (result) {
                const s3Key = result.key;
                const url = await this.getS3Url(s3Key);
                Editor.insertEmbed(cursorLocation, "image", url);
                resetUploader();
            }
        },
    },
}
</script>