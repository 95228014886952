<template>
    <ValidationObserver tag="form" ref="form" v-slot="{ errors }">
        <div class="md-layout form-layout">
            <!-- Button -->
            <div class="md-layout-item md-size-100 form-toolbar">
                <h3 class="page-title">
                    <span v-if="creating">
                        {{ $t("routes.ancillary_create.title") }}
                    </span>
                    <span v-if="editing">
                        {{ $t("routes.ancillary_edit.title") }} {{ item.name }}
                    </span>
                </h3>
                <div class="form-buttons">
                    <md-progress-spinner v-if="loading" :md-diameter="30" :md-stroke="3" md-mode="indeterminate">
                    </md-progress-spinner>
                    <!-- Save button -->
                    <md-button class="md-primary md-round btn-medium" @click="save()">
                        {{ $t("save") }}
                    </md-button>
                </div>
            </div>
              <!-- Ancillary  type -->
            <div class="md-layout-item md-size-40">
                <md-field>
                    <md-select v-model="item.ancillaryType" :disabled="loading" :placeholder="$t('label.ancillary_ancillaryType')">
                        <md-option :value="0">
                            {{ $t("label.ancillary_ancillaryType_value_default") }}
                        </md-option>
                        <md-option :value="1">
                            {{ $t("label.ancillary_ancillaryType_value_0") }}
                        </md-option>
                        <md-option :value="3">
                            {{ $t("label.ancillary_ancillaryType_value_2") }}
                        </md-option>
                        <md-option :value="4">
                            {{ $t("label.ancillary_ancillaryType_value_3") }}
                        </md-option>
                        <md-option :value="5">
                            {{ $t("label.ancillary_ancillaryType_value_4") }}
                        </md-option>
                    </md-select>
                    <span class="md-error">
                        {{ errorAncillaryTypeMessage }}
                    </span>
                </md-field>
            </div>
            <div class="md-layout-item md-size-40">                
            </div>
            <!-- Order -->
            <div class="md-layout-item md-size-40">
                <md-field>
                    <md-select v-model="item.order" :disabled="loading" :placeholder="$t('label.ancillary_order')">
                        <md-option v-for="(order, index) in orders" :key="index" :value="order.value">
                            {{ order.text }}
                        </md-option>
                    </md-select>
                    <span class="md-error">
                        {{ errorOrderMessage }}
                    </span>
                </md-field>
            </div>
            <div class="md-layout-item md-size-10"></div>
            <!-- Enabled -->
            <div class="md-layout-item md-size-50">
                <div class="field-switch">
                    <Span>{{ $t("label.ancillary_enabled") }}</Span>
                    <md-switch id="switchEnabled" v-model="item.enabled" :disabled="loading">
                    </md-switch>
                </div>
            </div>           
            <!-- media id -->
            <div class="md-layout-item md-size-40">
                <div class="md-layout-item">
                    <div class="md-layout">
                        <div>
                            <md-icon class="md-42">iconimage_search</md-icon>
                        </div>
                        <div class="md-layout-item md-size-70">
                            <md-field>
                                <label class="label-images" v-if="item.media.fileName == ''">{{
                                    $t("label.ancillary_image")
                                }}</label>
                                <label v-if="item.media.fileName == ''">{{ $t("label.select_image") }}</label>
                                <label v-else>{{ $t("label.ancillary_image") }}</label>
                                <md-input readonly id="mediaInput" v-model="item.media.fileName" :disabled="true">
                                </md-input>
                                <md-button v-if="item.media.fileName != ''" class="btn-floating" @click="clearMedia()">
                                    <md-icon>close</md-icon>
                                </md-button>
                                <span class="md-error">
                                    {{ errorMediaMessage }}
                                </span>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-20">
                            <md-button @click="openMediaModal()" class="md-primary md-round btn-medium">
                                {{ $t("select_file") }}
                            </md-button>
                        </div>
                    </div>
                </div>
                <!-- media image preview -->
                <div class="md-layout-item md-size-50">
                    <div class="media-container-left">
                        <md-icon v-if="item.media.imageURL == ''" class="md-size-5x">image</md-icon>
                        <img v-else :src="item.media.imageURL" :alt="item.media.imageAlt"
                            @click.prevent="viewImage(item.media.imageURL)" />
                    </div>
                </div>
            </div>
            <div class="md-layout-item md-size-10"></div>
            <!-- date mode -->
            <div class="md-layout-item md-size-40">
                <md-field>
                    <md-select v-model="item.dateMode" :disabled="loading" :placeholder="$t('label.ancillary_dateMode')">
                        <md-option v-for="(dateMode, index) in dateModes" :key="index" :value="dateMode.value">
                            {{ dateMode.text }}
                        </md-option>
                    </md-select>
                    <span class="md-error">
                        {{ errorDateModeMessage }}
                    </span>
                </md-field>
           </div>
            <!-- Translations -->
            <md-card class="translations-card">
                <md-card-content>
                    <md-tabs class="translations-tabs">
                        <md-tab id="tab-tr" :md-label="$t('languages.tr')" :md-icon="getLanguageIcon('tr', errors)">
                            <!-- name tr -->
                            <md-field :class="[
                                { 'md-valid': errors['name_tr'] && !errors['name_tr'][0] && touchedFields.name_tr },
                                { 'md-error': errors['name_tr'] && errors['name_tr'][0] }
                            ]">
                                <label for="title">{{ $t('label.ancillary_name') }}</label>
                                <validation-provider name='name_tr' rules="required" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="item.name_tr" type="text" :placeholder="$t('label.ancillary_name')"
                                        :disabled="loading">
                                    </md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <!-- title tr -->
                            <md-field :class="[
                                { 'md-valid': errors['title_tr'] && !errors['title_tr'][0] && touchedFields.title_tr },
                                { 'md-error': errors['title_tr'] && errors['title_tr'][0] }
                            ]">
                                <label for="title">{{ $t('label.ancillary_title') }}</label>
                                <validation-provider name='title_tr' rules="required" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="item.title_tr" type="text" :placeholder="$t('label.ancillary_title')"
                                        :disabled="loading">
                                    </md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <!-- description tr -->
                            <div class="md-layout-item md-size-100">
                                <md-field :class="[
                                    { 'md-valid': errors['description_tr'] && !errors['description_tr'][0] && touchedFields.description_tr },
                                    { 'md-error': errors['description_tr'] && errors['description_tr'][0] }
                                ]">
                                    <label for="title">{{ $t('fields.ancillary_description') }}</label>
                                    <validation-provider name='description_tr' rules="" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-textarea md-autogrow v-model="item.description_tr" name="description_tr"
                                            type="text" :placeholder="$t('label.ancillary_description')" id="description_tr"
                                            :disabled="loading"></md-textarea>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                        </md-tab>
                        <md-tab id="tab-en" :md-label="$t('languages.en')" :md-icon="getLanguageIcon('en', errors)">
                            <!-- name en -->
                            <md-field :class="[
                                { 'md-valid': errors['name_en'] && !errors['name_en'][0] && touchedFields.name_en },
                                { 'md-error': errors['name_en'] && errors['name_en'][0] }
                            ]">
                                <label for="title">{{ $t('label.ancillary_name') }}</label>
                                <validation-provider name='name_en' rules="required" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="item.name_en" type="text" :placeholder="$t('label.ancillary_name')"
                                        :disabled="loading">
                                    </md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <!-- title en -->
                            <md-field :class="[
                                { 'md-valid': errors['title_en'] && !errors['title_en'][0] && touchedFields.title_en },
                                { 'md-error': errors['title_en'] && errors['title_en'][0] }
                            ]">
                                <label for="title">{{ $t('label.ancillary_title') }}</label>
                                <validation-provider name='title_en' rules="required" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="item.title_en" type="text" :placeholder="$t('label.ancillary_title')"
                                        :disabled="loading">
                                    </md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <!-- description en -->
                            <div class="md-layout-item md-size-100">
                                <md-field :class="[
                                    { 'md-valid': errors['description_en'] && !errors['description_en'][0] && touchedFields.description_en },
                                    { 'md-error': errors['description_tr'] && errors['description_en'][0] }
                                ]">
                                    <label for="title">{{ $t('fields.ancillary_description') }}</label>
                                    <validation-provider name='description_en' rules="" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-textarea md-autogrow v-model="item.description_en" name="description_en"
                                            type="text" :placeholder="$t('label.ancillary_description')" id="description_en"
                                            :disabled="loading"></md-textarea>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                        </md-tab>
                    </md-tabs>
                </md-card-content>
            </md-card>
            <!-- Buttons -->
            <div class="cont">            
                <!-- tickets button -->
                <md-button :disabled="loading == true || creating == true" class="md-primary md-round"
                    @click="showModal('tickets')">
                    {{ $t("default_pricing_categories") }}
                </md-button>
                <!-- extras button -->
                <md-button :disabled="loading == true || creating == true" class="md-primary md-round"
                    @click="showModal('extras')">
                    {{ $t("extras") }}
                </md-button>
            </div>
            <!-- Tickets Modal-->
            <ticket-modal :showProp="showTicketsModal" :parentIdProp="item.ancillaryId" :parentNameProp="item.name"
                @close="closeTicketModal()">
            </ticket-modal>
            <!-- Extras Modal -->
            <extra-modal :showProp="showExtrasModal" :parentIdProp="item.ancillaryId" :parentNameProp="item.name"
                @close="closeExtraModal()">
            </extra-modal>
            <!-- Media Modal -->
            <media-modal :showProp="showMediaModal" @apply="applyMedia" @close="closeMediaModal()"></media-modal>
        </div>
    </ValidationObserver>
</template>
<script>
import MediaModal from "@/components/Modals/Media";
import TicketModal from "@/components/Modals/Ticket";
import ExtraModal from "@/components/Modals/Extra";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import { getAncillary, createAncillary, updateAncillary } from "@/services/ancillary";
import localeMixin from "@/mixins/locale";


export default {
    mixins: [localeMixin],
    components: {
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
        MediaModal,
        TicketModal,
        ExtraModal,
    },
    data() {
        return {

            loading: false,
            creating: false,
            editing: false,
            imagesLightbox: [],
            touchedFields: {
                name_tr: false,
                name_en: false,
                title_tr: false,
                title_en: false,
                description_tr: false,
                description_en: false,
            },
            item: {
                ancillaryId: "",
                id: 0,
                order: 0,
                name: "",
                enabled: false,
                mediaId: 0,
                imageURL: "",
                imageAlt: "",
                fileName: "",
                dateMode: 0,
                media: {
                    fileName: "", imageURL: "", imageAlt: ""
                },
                logoMedia: {
                    fileName: "", imageURL: "", imageAlt: ""
                },
                translationId_tr: 0,
                translationId_en: 0,
                name_tr: "",
                name_en: "",
                title_tr: "",
                title_en: "",
                description_en: "",
                description_tr: "",
                tickets: [],
                extras: [],
            },
            errorMediaMessage: "",
            errorAncillaryTypeMessage:"",
            errorDateModeMessage: "",
            errorOrderMessage: "",
            showMediaModal: false,
            showTicketsModal: false,
            showExtrasModal: false,
            orders: [],
            dateModes: [],
        };
    },
    created: async function () {
        let ancillaryId = this.$route.params.ancillaryId;
        if (ancillaryId) {
            await this.editItem(ancillaryId);
        } else {
            let lastOrder = this.$route.params.lastOrder;
            if (lastOrder) {
                this.item.order = lastOrder;
            }
            this.newItem();
        }

    },

    async mounted() {
        this.fillOrdersArray();
        this.fillDateModeArray();
    },

    methods: {

        viewImage(imageUrl) {
            this.imagesLightbox = [];
            this.$imageViewer.images(this.imagesLightbox);
            const image = {
                url: imageUrl,
            };
            this.imagesLightbox.push(image);
            this.$imageViewer.index(0);
            this.$imageViewer.show();
        },

        fillOrdersArray() {
            let min = 0;
            let max = 50;
            this.orders = [];
            for (let i = min; i <= max; i++) {
                if (i == 0) {
                    let z = { value: i, text: this.$t('label.order_default') };
                    this.orders.push(z);
                } else {
                    let b = { value: i, text: i.toString() };
                    this.orders.push(b);
                }
            }
        },

        fillDateModeArray() {
            this.dateModes = [];
            this.dateModes.push({ value: 0, text: this.$t('label.dateMode_value_arrival') });
            this.dateModes.push({ value: 1, text: this.$t('label.dateMode_return') });
            this.dateModes.push({ value: 2, text: this.$t('label.dateMode_arrivalAndReturn') });
        },

        applyMedia(media) {
            this.item.media = media;
            this.item.mediaId = media.mediaId;
            this.item.imageURL = media.imageURL;
            this.item.imageAlt = media.imageAlt;
            this.item.fileName = media.fileName;
            this.errorMediaMessage = "";
        },

        clearMedia() {

            this.item.media = { fileName: "", imageURL: "", imageAlt: "" };
            this.item.mediaId = 0;
            this.item.imageURL = this.item.media.imageURL;
            this.item.imageAlt = this.item.media.imageAlt;
            this.item.fileName = this.item.media.fileName;
            this.errorMediaMessage = "";
        },

        openMediaModal() {
            this.showMediaModal = true;
        },

        closeMediaModal() {
            this.showMediaModal = false;
            if (this.item.mediaId == 0) {
                this.errorMediaMessage = this.$t('validation.required', { _field_: this.$t('label.shop_image') });

            }
        },

        openTicketModal() {
            this.showTicketsModal = true;
        },

        closeTicketModal() {
            this.showTicketsModal = false;
        },

        openExtraModal() {
            this.showExtrasModal = true;
        },

        closeExtraModal() {
            this.showExtrasModal = false;
        },

        showModal(mode) {
            switch (mode) {
                case "tickets":
                    this.openTicketModal();
                    break;
                case "extras":
                    this.openExtraModal();
                    break;
                default:
                    break;
            }
        },

        async save() {

            this.errorMediaMessage = "";
            this.errorOrderMessage = "";
            this.errorDateModeMessage = "";
            this.errorAncillaryTypeMessage = "";

            var valid = await this.$refs.form.validate();


            if (this.item.mediaId == 0) {
                valid = false;
                this.errorMediaMessage = this.$t('validation.required', { _field_: this.$t('label.shop_image') });
            }

            if (this.item.order == 0) {
                valid = false;
                this.errorOrderMessage = this.$t('validation.at_least_one_value', { _field_: this.$t('label.shop_order') });
            }

            if (this.item.ancillaryType == 0) {
                valid = false;
                this.errorAncillaryTypeMessage = this.$t('validation.at_least_one_value', { _field_: this.$t('label.ancillary_type') });
            }

            if (!valid) {
                this.$noty.error(this.$t('error.saved'));
            }


            if (valid) {
                this.loading = true;
                if (this.creating) {
                    let response = await createAncillary(this.convertDataModel());
                    if (response.status == "201") {
                        this.$noty.success(this.$t('success.saved'));
                        if (response.data.ancillaryId) {
                            this.$router.push({ name: this.$i18n.locale + '.ancillary_edit', params: { ancillaryId: response.data.ancillaryId } });
                        } else {
                            this.$router.push({ name: this.$i18n.locale + '.ancillaries' });
                        }

                    } else {
                        this.$noty.error(this.$t('error.saved'));
                    }
                }
                if (this.editing) {
                    let response = await updateAncillary(this.convertDataModel());
                    if (response.status == "200") {
                        this.$noty.success(this.$t('success.saved'));
                    } else {
                        this.$noty.error(this.$t('error.saved'));
                    }
                }
                this.loading = false;


            }

        },

        assignDataModel(item) {
            if (item.ancillaryId) {
                this.item.ancillaryId = item.ancillaryId;
            } else {
                this.item.ancillaryId = "";
            }

            if (item.id) {
                this.item.id = item.id;
            } else {
                this.item.id = 0;
            }

            if (item.name) {
                this.item.name = item.name;
            } else {
                this.item.name = "";
            }

            if (item.order) {
                this.item.order = item.order;
            } else {
                this.item.order = 0;
            }

            if (item.active) {
                this.item.enabled = Boolean(item.active);
            } else {
                this.item.enabled = false;
            }

            if (item.mediaId) {
                this.item.mediaId = item.mediaId;
            } else {
                this.item.mediaId = 0;
            }

            if (item.fileName) {
                this.item.media.fileName = item.fileName;
            } else {
                this.item.media.fileName = "";
            }

            if (item.imageURL) {
                this.item.media.imageURL = item.imageURL;
            } else {
                this.item.media.imageURL = "";
            }

            if (item.imageAlt) {
                this.item.media.imageAlt = item.imageAlt;
            } else {
                this.item.media.imageAlt = "";
            }

            if (item.ancillaryType) {
                this.item.ancillaryType = item.ancillaryType;
            } else {
                this.item.ancillaryType = 0;
            }

            if (item.dateMode) {
                this.item.dateMode = item.dateMode;
            } else {
                this.item.dateMode = 0;
            }

            var translation = item.translations.filter(t => t.locale == 'en')[0];

            if (translation) {

                if (translation.translationId) {
                    this.item.translationId_en = translation.translationId;
                } else {
                    this.item.translationId_en = 0;
                }

                if (translation.name) {
                    this.item.name_en = translation.name;
                } else {
                    this.item.name_en = "";
                }

                if (translation.title) {
                    this.item.title_en = translation.title;
                } else {
                    this.item.title_en = "";
                }

                if (translation.description) {
                    this.item.description_en = translation.description;
                } else {
                    this.item.description_en = "";
                }


            } else {

                this.item.translationId_en = 0;
                this.item.name_en = "";
                this.item.title_en = "";
                this.item.description_en = "";
               
            }

            translation = item.translations.filter(t => t.locale == 'tr')[0];

            if (translation) {

                if (translation.translationId) {
                    this.item.translationId_tr = translation.translationId;
                } else {
                    this.item.translationId_tr = 0;
                }

                if (translation.name) {
                    this.item.name_tr = translation.name;
                } else {
                    this.item.name_tr = "";
                }

                if (translation.title) {
                    this.item.title_tr = translation.title;
                } else {
                    this.item.title_tr = "";
                }

                if (translation.description) {
                    this.item.description_tr = translation.description;
                } else {
                    this.item.description_tr = "";
                }

            } else {

                this.item.translationId_tr = 0;
                this.item.name_tr = "";
                this.item.title_tr = "";
                this.item.description_tr = "";
            }


        },


        convertDataModel() {
            var newItem = {};
            newItem.ancillaryId = this.item.ancillaryId;
            newItem.mediaId = this.item.mediaId;
            newItem.order = this.item.order;
            newItem.ancillaryType = this.item.ancillaryType; 
            newItem.dateMode = this.item.dateMode;          
            newItem.active = Number(this.item.enabled);
            newItem.translations = [];
            var trans_item_tr = {};
            trans_item_tr.translationId = this.item.translationId_tr;
            trans_item_tr.locale = "tr";
            trans_item_tr.name = this.item.name_tr;
            trans_item_tr.description = this.item.description_tr;
            trans_item_tr.title = this.item.title_tr;
            newItem.translations.push(trans_item_tr);
            var trans_item_en = {};
            trans_item_en.translationId = this.item.translationId_en;
            trans_item_en.locale = "en";
            trans_item_en.name = this.item.name_en;
            trans_item_en.description = this.item.description_en;
            trans_item_en.title= this.item.title_en;
            newItem.translations.push(trans_item_en);
            return newItem;
        },


        newItem() {
            this.creating = true;
            this.editing = false;
        },


        async editItem(itemId) {
            this.creating = false;
            this.editing = true;
            await this.fetchItem(itemId);
        },

        async fetchItem(itemId) {
            this.loading = true;
            let response = await getAncillary(itemId);
            if (response) {
                this.assignDataModel(response);
            }
            this.loading = false;
        },
    }
}
</script>

<style scoped>
.media-container-left {
    display: flex;
    float: left;
    width: 400px;
    max-width: 400px;
    max-height: 300px;
    margin-left: 24px;
    margin-bottom: 48px;
    min-height: 300px;
    background-color: var(--media-background);
    border: solid 1px var(--media-border);
}

.media-container-left img {
    object-fit: fill;
    cursor: pointer;
}

.cont {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    grid-auto-rows: 40px;
    grid-auto-columns: max-content;
    align-items: start;
}

.cont .md-button {
    display: table-footer-group;
    width: 100%;
    height: 39px;
    justify-content: center !important;
    text-align: center !important;
}


.media-container-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 394px;
    max-width: 394px;
    min-width: 20px;
    height: 67px;
    max-height: 67px;
    min-height: 67px;
    background-color: var(--media-background);
    border: solid 1px var(--media-border);
    overflow: hidden;
}

.media-container-logo img {
    object-fit: fill;
    cursor:pointer;
}
</style>