<template>
    <ValidationObserver tag="form" ref="form" v-slot="{ errors }">
        <div class="md-layout form-layout">
            <!-- Button -->
            <div class="md-layout-item md-size-100 form-toolbar">
                <h3 class="page-title">
                    <span v-if="creating">
                        {{ $t("routes.client_create.title") }}
                    </span>
                    <span v-if="editing">
                        {{ $t("routes.client_edit.title") }} {{ item.name }}
                    </span>
                </h3>
                <div class="form-buttons">
                    <md-progress-spinner v-if="loading" :md-diameter="30" :md-stroke="3" md-mode="indeterminate">
                    </md-progress-spinner>
                    <!-- Save button -->
                    <md-button class="md-primary md-round btn-medium" @click="save()">
                        {{ $t("save") }}
                    </md-button>
                </div>
            </div>
            <!-- Order -->
            <!-- <div class="md-layout-item md-size-40">
                <md-field>
                    <md-select v-model="item.order" :disabled="loading" :placeholder="$t('label.client_order')">
                        <md-option v-for="(order, index) in orders" :key="index" :value="order.value">
                            {{ order.text }}
                        </md-option>
                    </md-select>
                    <span class="md-error">
                        {{ errorOrderMessage }}
                    </span>
                </md-field>
            </div>
            <div class="md-layout-item md-size-10"></div> -->
            <!-- Enabled -->
            <div class="md-layout-item md-size-40">
                <div class="field-switch">
                    <Span>{{ $t("label.client_enabled") }}</Span>
                    <md-switch id="switchEnabled" v-model="item.enabled" :disabled="loading">
                    </md-switch>
                </div>
            </div>
            <div class="md-layout-item md-size-10"></div>
            <!-- Name -->
            <div class="md-layout-item md-size-40">

                <md-field :class="[
                    { 'md-valid': errors['client_name'] && !errors['client_name'][0] && touchedFields.name },
                    { 'md-error': errors['client_name'] && errors['client_name'][0] }
                ]">
                    <label for="client_name">{{
                        $t("label.client_name")
                    }}</label>
                    <validation-provider name="name" rules="required" v-slot="{ errors, touched }" mode="aggressive">
                        <md-input v-model="item.name" name="name" type="text" id="name" :disabled="loading"></md-input>
                        <slide-y-down-transition>
                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                        </slide-y-down-transition>
                        <span class="md-error">{{ errors[0] }}</span>
                    </validation-provider>
                </md-field>
            </div>
            <div class="md-layout-item md-size-10"></div>
            <!-- type -->
            <div class="md-layout-item md-size-40">
                <md-field>
                    <md-select v-model="item.type" :disabled="loading" :placeholder="$t('label.client_type')">
                        <md-option v-for="(type, index) in clientTypes" :key="index" :value="type.value">
                            {{ type.text }}
                        </md-option>
                    </md-select>
                </md-field>
            </div>
            <div class="md-layout-item md-size-10"></div>
            <!-- Vat number -->
            <div v-if="item.type == 0" class="md-layout-item md-size-40">

                <md-field :class="[
                    { 'md-valid': errors['vatNumber'] && !errors['vatNumber'][0] && touchedFields.vatNumber },
                    { 'md-error': errors['vatNumber'] && errors['vatNumber'][0] }
                ]">
                    <label for="vatNumber">{{
                        $t("label.vatNumber")
                    }}</label>
                    <validation-provider name="vatNumber" :rules="{ required: item.type == 0 }" v-slot="{ errors, touched }"
                        mode="aggressive">
                        <md-input v-model="item.vatNumber" name="vatNumber" type="text" id="vatNumber"
                            :disabled="loading"></md-input>
                        <slide-y-down-transition>
                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                        </slide-y-down-transition>
                        <span class="md-error">{{ errors[0] }}</span>
                    </validation-provider>
                </md-field>
            </div>
            <div class="md-layout-item md-size-10"></div>
            <!-- currency selector -->
            <div class="md-layout-item md-size-40">
                <md-field>
                    <md-select v-model="item.currencyAlphabeticCode" :disabled="loading"
                        :placeholder="$t('label.currency_value_default')">
                        <md-option v-for="(item, idx5) of currencies" :value="item.value" :key="idx5">
                            {{ item.text }}
                        </md-option>
                    </md-select>
                    <span class="md-error">
                        {{ errorCurrencyMessage }}
                    </span>
                </md-field>
            </div>
            <!-- B2B Payment Methods -->
            <div v-if="item.type == 0" class="md-layout-item md-size-100">
                <h5>{{ $t("label.allowed_payment_methods") }}</h5>
                <div class="allowed-payment-methods">

                    <div class="field-switch">
                        <Span>{{ $t("payment_methods.agreement") }}</Span>
                        <md-switch id="switchEnabled" v-model="item.allowAgreement" :disabled="loading">
                        </md-switch>
                    </div>
                    <div class="field-switch">
                        <Span>{{ $t("payment_methods.credit_card") }}</Span>
                        <md-switch id="switchEnabled" v-model="item.allowCreditCard" :disabled="loading">
                        </md-switch>
                    </div>
                    <div class="field-switch">
                        <Span>{{ $t("payment_methods.cash") }}</Span>
                        <md-switch id="switchEnabled" v-model="item.allowCash" :disabled="loading">
                        </md-switch>
                    </div>
                    <div class="field-switch">
                        <Span>{{ $t("payment_methods.yda_pos") }}</Span>
                        <md-switch id="switchEnabled" v-model="item.allowYDAPOS" :disabled="loading">
                        </md-switch>
                    </div>
                    <div class="field-switch">
                        <Span>{{ $t("payment_methods.atm_pos") }}</Span>
                        <md-switch id="switchEnabled" v-model="item.allowATMPOS" :disabled="loading">
                        </md-switch>
                    </div>
                    <div class="field-switch">
                        <Span>{{ $t("payment_methods.paterna_pos") }}</Span>
                        <md-switch id="switchEnabled" v-model="item.allowPaternaPOS" :disabled="loading">
                        </md-switch>
                    </div>
                </div>
                <span class="md-error">
                    {{ errorPaymentMethods }}
                </span>
            </div>
            <!-- Separator localizations -->
            <div class="md-layout-item md-size-100">
                <div class="Group-Header">
                    <span class="Luggage-information">
                        {{ $t("label.user_title_list") }}
                    </span>
                    <div class="Path"></div>
                </div>
            </div>

            <div class="md-layout-item md-size-100 inline-toolbar">
                <md-field class="search-input">
                    <md-icon class="search-icon">image_search</md-icon>
                    <label>{{ $t("label.search_user") }}</label>
                    <md-input v-model="search" @keyup.enter="filter()"></md-input>
                    <md-button v-if="search" class="btn-floating" @click="clearFilter()">
                        <md-icon>close</md-icon>
                    </md-button>
                </md-field>
                <md-button class="md-primary md-round btn-big" @click="filter()" :disabled="loading || creating">
                    {{ $t("search") }}
                </md-button>
                <router-link v-if="item.type == 0" :to="{ name: $i18n.locale + '.client_user_create', params: { clientId:item.id } }"
                    class="md-primary md-round btn-big" tag="md-button" :disabled="loading || creating">
                    {{ $t("new") }}
                </router-link>
            </div>

            <div class="md-layout-item md-size-100">
                <md-table table-header-color="blue" v-model="Items" :md-sort-order.sync="currentSortOrder"
                    :md-sort.sync="currentSort" :md-sort-fn="customSort">
                    <md-table-row slot="md-table-row" slot-scope="{ item }">
                        <md-table-cell md-sort-by="email" :md-label="$t('label.user_email')">
                            {{ item.email }}
                        </md-table-cell>
                        <md-table-cell md-sort-by="clientName" :md-label="$t('label.client_name')">
                            {{ item.name }}
                        </md-table-cell>
                        <md-table-cell md-sort-by="createdDate" :md-label="$t('label.user_createDate')">
                            {{ customFormatDate(item.createdAt) }}
                        </md-table-cell>
                        <md-table-cell class="table-cell-actions">
                            <router-link class="btn-icon" value=""
                                :to="{ name: $i18n.locale + '.client_user_edit', params: { sub: item.userSub, clientId:item.clientId } }" tag="button">
                                <md-icon>edit</md-icon>
                            </router-link>
                            <button type="button" class="btn-icon" @click="confirmDelete(item)">
                                <md-icon>delete</md-icon>
                            </button>
                        </md-table-cell>
                    </md-table-row>
                </md-table>
                <!-- Pagination -->
                <div class="pagination-container">
                    <md-pagination v-model="page" :noArrows="false" :perPage="limit" :total="total" />
                </div>
            </div>
            <!-- Buttons -->
            <div class="cont">
                <md-button :disabled="loading == true || creating == true" class="md-primary md-round" @click="goPricing()">
                    {{ $t("label.pricing") }}
                </md-button>
            </div>
            <md-modal v-if="showConfirm" @close="showConfirm = false">
                <h3 slot="header">
                    {{ $t("confirm") }}
                </h3>
                <div slot="body">
                    {{ $t("confirm_messages.delete") }}
                </div>
                <div slot="footer" class="modal-footer">
                    <md-button @click="showConfirm = false" class="md-danger md-round">
                        {{ $t("cancel") }}
                    </md-button>
                    <md-button @click="deleteItem()" class="md-primary md-round">
                        {{ $t("delete") }}
                    </md-button>
                </div>
            </md-modal>
        </div>
    </ValidationObserver>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import { getClient, createClient, updateClient, getClientUsers, deleteClientUser } from "@/services/client";
import { getCurrenciesWithoutPagination } from "@/services/currency";
import paginationMixin from "@/mixins/pagination";
import authMixin from "@/mixins/auth";

import localeMixin from "@/mixins/locale";


export default {
    mixins: [localeMixin, authMixin, paginationMixin],
    components: {
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
    },
    data() {
        return {

            loading: false,
            creating: false,
            editing: false,
            touchedFields: {
                name: false,
            },
            item: {
                clientId: "",
                id: 0,
                order: 0,
                name: "",
                enabled: false,
                currencyAlphabeticCode: "",
                currencyNumericCode: 0,
                vatNumber: "",
                currencyId: 0,
                type: 0,
                allowAgreement: true,
                allowCreditCard: false,
                allowCash: false,
                allowATMPOS: false,
                allowYDAPOS: false,
                allowPaternaPOS: false,
            },
            errorOrderMessage: "",
            errorCurrencyMessage: "",
            errorPaymentMethods: "",
            orders: [],
            clientTypes: [],
            currencies: [],
            showConfirm: false,
            currentSort: 'createdAt',
            currentSortOrder: 'desc',
            Items: [],
            Filters: [],
            search: "",
            sort: "createdAt,desc",
            ItemIdToDelete: {},

        };
    },
    created: async function () {
        let clientId = this.$route.params.clientId;
        if (clientId) {
            await this.editItem(clientId);
        } else {
            let lastOrder = this.$route.params.lastOrder;
            if (lastOrder) {
                this.item.order = lastOrder;
            }
            this.newItem();
        }

    },

    async mounted() {
        await this.fillCurrencies();
        this.fillOrdersArray();
        this.fillTypeArray();
    },

    methods: {

        confirmDelete(Item) {
            this.ItemIdToDelete = Item;
            this.showConfirm = true;
        },

        customFormatDate(userCreateDate) {           
            return this.moment(userCreateDate).format('DD/MM/YYYY HH:MM');
        },

        async deleteItem() {
            this.loading = true;
            const clientId = this.ItemIdToDelete.clientId;
            const user = {
                userId: this.ItemIdToDelete.userSub,
            }
            let response = await deleteClientUser(this.ItemIdToDelete.clientId, user);
             if (response) {
                this.showConfirm = false;
                this.ItemIdToDelete = {};
                this.$noty.success(this.$t('success.deleted'));
                await this.editItem(clientId);
             } else {
                 this.$noty.error(this.$t('error.deleted'));
             }
            this.loading = false;
        },

        goPricing() {
            this.$router.push({ name: this.$i18n.locale + '.prices', params: { clientId: this.item.clientId } });
        },

        updateCurrencyValues() {

            if (this.currencies) {

                var curr = this.currencies.find(
                    (t) => t.value === this.item.currencyAlphabeticCode
                );
                if (curr) {
                    console.debug("Encontrada currency", curr);
                } else {
                    console.debug("No encontrada currency");
                }
            } else {
                console.debug("No hay currencies");
            }

        },

        fillOrdersArray() {
            let min = 0;
            let max = 50;
            this.orders = [];
            for (let i = min; i <= max; i++) {
                if (i == 0) {
                    let z = { value: i, text: this.$t('label.order_default') };
                    this.orders.push(z);
                } else {
                    let b = { value: i, text: i.toString() };
                    this.orders.push(b);
                }
            }
        },

        fillTypeArray() {
            this.clientTypes = [];
            this.clientTypes.push({ value: 0, text: this.$t('label.clientType_value_B2B') });
            this.clientTypes.push({ value: 1, text: this.$t('label.clientType_value_B2C') });
        },

        async fillCurrencies() {
            let t = this.$t('label.currency_value_default');
            let z = { value: "-", text: t };
            this.currencies.push(z);
            const responseCurrencies = await getCurrenciesWithoutPagination();
            if (responseCurrencies) {

                responseCurrencies.map(item => {
                    let b = {
                        value: item.alphabeticCode,
                        numeric: item.numericCode,
                        alphabetic: item.alphabeticCode,
                        id: item.currencyId,
                        text: item.name + " (" + item.alphabeticCode + ") "
                    };


                    this.currencies.push(b);
                });

            } else {
                this.currencies = [];
            }



        },

        validatePaymentMethods() {
            return (this.item.allowAgreement || 
                this.item.allowCreditCard || 
                this.item.allowCash || 
                this.item.allowYDAPOS ||
                this.item.allowATMPOS ||
                this.item.allowPaternaPOS ) ?
                    true :
                    false;
        },


        async save() {

            this.errorOrderMessage = "";
            this.errorCurrencyMessage = "";

            var valid = await this.$refs.form.validate();

            if (this.item.order == 0) {                
                valid = false;
                this.errorOrderMessage = this.$t('validation.at_least_one_value', { _field_: this.$t('label.shop_order') });
            }

            if (this.item.currencyAlphabeticCode == "-") {              
                valid = false;
                this.errorCurrencyMessage = this.$t('validation.at_least_one_value', { _field_: this.$t('label.currency_code') });
            }
            if (this.item.type == 0 && !this.validatePaymentMethods()) {
                valid = false;
                this.errorPaymentMethods = this.$t('validation.at_least_one_value', { _field_: this.$t('label.allowed_payment_methods') });
            } else {
                this.errorPaymentMethods = "";
            }

            if (!valid) {                
                this.$noty.error(this.$t('error.saved'));
            }

            if (valid) {
                this.loading = true;
                if (this.creating) {
                    let response = await createClient(this.convertDataModel());
                    if (response.status == "201") {
                        this.$noty.success(this.$t('success.saved'));
                        if (response.data.ancillaryId) {
                            this.$router.push({ name: this.$i18n.locale + '.client_edit', params: { clientId: response.data.clientId } });
                        } else {
                            this.$router.push({ name: this.$i18n.locale + '.clients' });
                        }

                    } else {
                        this.$noty.error(this.$t('error.saved'));
                    }
                }
                if (this.editing) {
                    let response = await updateClient(this.convertDataModel());
                    if (response.status == "200") {
                        this.$noty.success(this.$t('success.saved'));
                    } else {
                        this.$noty.error(this.$t('error.saved'));
                    }
                }
                this.loading = false;


            }

        },

        assignDataModel(item) {
            if (item.clientId) {
                this.item.clientId = item.clientId;
            } else {
                this.item.clientId = "";
            }

            if (item.id) {
                this.item.id = item.id;
            } else {
                this.item.id = 0;
            }

            if (item.name) {
                this.item.name = item.name;
            } else {
                this.item.name = "";
            }

            if (item.order) {
                this.item.order = item.order;
            } else {
                this.item.order = 0;
            }

            if (item.active) {
                this.item.enabled = Boolean(item.active);
            } else {
                this.item.enabled = false;
            }

            if (item.type) {
                this.item.type = item.type;
            } else {
                this.item.type = 0;
            }

            if (item.vatNumber) {
                this.item.vatNumber = item.vatNumber;
            } else {
                this.item.vatNumber = "";
            }

            if (item.currencyNumericCode) {
                this.item.currencyNumericCode = item.currencyNumericCode;
            } else {
                this.item.currencyNumericCode = 0;
            }

            if (item.currencyAlphabeticCode) {
                this.item.currencyAlphabeticCode = item.currencyAlphabeticCode;
            } else {
                this.item.currencyAlphabeticCode = "";
            }

            if (item.currencyId) {
                this.item.currencyId = item.currencyId;
            } else {
                this.item.currencyId = 0;
            }

            if (item.allowAgreement != undefined) {
                this.item.allowAgreement = Boolean(item.allowAgreement);
            } else {
                this.item.allowAgreement = true;
            }

            if (item.allowCreditCard != undefined) {
                this.item.allowCreditCard = Boolean(item.allowCreditCard);
            } else {
                this.item.allowCreditCard = false;
            }

            if (item.allowCash != undefined) {
                this.item.allowCash = Boolean(item.allowCash);
            } else {
                this.item.allowCash = false;
            }

            if (item.allowATMPOS != undefined) {
                this.item.allowATMPOS = Boolean(item.allowATMPOS);
            } else {
                this.item.allowATMPOS = false;
            }

            if (item.allowYDAPOS != undefined) {
                this.item.allowYDAPOS = Boolean(item.allowYDAPOS);
            } else {
                this.item.allowYDAPOS = false;
            }

            if (item.allowPaternaPOS != undefined) {
                this.item.allowPaternaPOS = Boolean(item.allowPaternaPOS);
            } else {
                this.item.allowPaternaPOS = false;
            }


        },


        convertDataModel() {
            var newItem = {};
            newItem.clientId = this.item.clientId;
            newItem.order = this.item.order;
            newItem.type = this.item.type;
            newItem.enabled = Number(this.item.enabled);
            newItem.name = this.item.name;
            newItem.vatNumber = this.item.vatNumber;
            newItem.allowAgreement = Number(this.item.allowAgreement);
            newItem.allowCreditCard = Number(this.item.allowCreditCard);
            newItem.allowCash = Number(this.item.allowCash);
            newItem.allowYDAPOS = Number(this.item.allowYDAPOS);
            newItem.allowATMPOS = Number(this.item.allowATMPOS);
            newItem.allowPaternaPOS = Number(this.item.allowPaternaPOS);

            var curr = this.currencies.find(
                (t) => t.value === this.item.currencyAlphabeticCode
            );

            newItem.currencyId = curr.id;
            newItem.currencyAlphabeticCode = this.item.currencyAlphabeticCode;
            newItem.currencyNumericCode = this.item.currencyNumericCode;
            return newItem;
        },


        newItem() {
            this.creating = true;
            this.editing = false;
        },


        async editItem(itemId) {
            this.creating = false;
            this.editing = true;
            await this.fetchItem(itemId);
        },

        async fetchItem(itemId) {
            this.loading = true;
            let response = await getClient(itemId);

            if (response) {
                this.assignDataModel(response);
            }
            this.loading = false;

            if (this.item.id && this.item.id > 0) {
                await this.fetchItems();
            }
        },

        async fetchItems() {

            if (!this.item.id || this.item.id == 0) {
                return false;
            }

            this.loading = true;
            this.sort = this.currentSort + "," + this.currentSortOrder;
            if (this.search) {
                this.Filters = [];
                this.Filters.push(this.search);
            } else {
                this.Filters = [];
                this.search = "";
            }

            const response = await getClientUsers(this.page, this.limit, this.search, this.item.id, this.sort);
            if (response) {
                this.Items = response.result;
                this.total = response.pageInfo.totalPages * this.limit;

            } else {
                this.Items = [];
                this.total = 0;
            }



            this.loading = false;
        },


        async filter() {
            this.fetchItems();
        },

        clearFilter() {
            this.search = "";
            this.Filters = [];
            this.fetchItems();
        },

        customSort: function customSort(value) {
            this.fetchItems();
            return value;
        }
    }
}
</script>

<style scoped>
.media-container-left {
    display: flex;
    float: left;
    width: 400px;
    max-width: 400px;
    max-height: 300px;
    margin-left: 24px;
    margin-bottom: 48px;
    min-height: 300px;
    background-color: var(--media-background);
    border: solid 1px var(--media-border);
}

.media-container-left img {
    object-fit: fill;
    cursor: pointer;
}

.cont {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    grid-auto-rows: 40px;
    grid-auto-columns: max-content;
    align-items: start;
}

.cont .md-button {
    display: table-footer-group;
    width: 100%;
    height: 39px;
    justify-content: center !important;
    text-align: center !important;
}


.media-container-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 394px;
    max-width: 394px;
    min-width: 20px;
    height: 67px;
    max-height: 67px;
    min-height: 67px;
    background-color: var(--media-background);
    border: solid 1px var(--media-border);
    overflow: hidden;
}

.media-container-logo img {
    object-fit: fill;
    cursor: pointer;
}
.md-error {
    color: var(--error-color);
    padding: 12px 0px 0px 0px;
    font-size: 12px;
}

.allowed-payment-methods {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
}
</style>
