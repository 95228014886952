import { API, Auth } from "aws-amplify";
import util from "util";

const getShops = async (page = 1, limit = 10, search = "",search2="",sortby="") => {
    let responseData = [];
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const language = process.env.VUE_APP_I18N_LOCALE;
    const airportCode = process.env.VUE_APP_AIRPORT_CODE;
    const path = ["/CMS/shop"];
    var headers = {
        "x-api-key": API_KEY,
        "x-app-language": language,
        "x-airport-code": airportCode,
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const queryStringParameters = {
        page: page,
        limit: limit,
        name: search,
        airportLocationCode:search2,
        sort:sortby
    };
    const myInit = {
        headers: headers,
        response: true,
        queryStringParameters: queryStringParameters,
    };
    try {
        var response = await API.get(API_NAME, path, myInit);
        responseData = response.data;
        responseData.status = response.status;

    } catch (error) {
        console.error(
            "Error getting shops: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
    return responseData;
};

const getShop= async (shopId) => {
    let responseData = {};
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const language = process.env.VUE_APP_I18N_LOCALE;
    const airportCode = process.env.VUE_APP_AIRPORT_CODE;
    const path = ["/CMS/shop/" + shopId];
    var headers = {
        "x-api-key": API_KEY,
        "x-app-language": language,
        "x-airport-code": airportCode,
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
    };
    try {
        var response = await API.get(API_NAME, path, myInit);
        responseData = response.data;
        responseData.status = response.status;

    } catch (error) {
        console.error(
            "Error getting shop detail: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
    return responseData;
};

const createShop = async (item) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const language = process.env.VUE_APP_I18N_LOCALE;
    const airportCode = process.env.VUE_APP_AIRPORT_CODE;
    const path = ['/CMS/shop/'];
    var headers = {
        "x-api-key": API_KEY,
        "x-app-language": language,
        "x-airport-code": airportCode,
        "Authorization": (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
        body: item,
    };
    try {
        var response = await API.post(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error creating shop: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
};

const updateShop = async (item) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const language = process.env.VUE_APP_I18N_LOCALE;
    const airportCode = process.env.VUE_APP_AIRPORT_CODE;
    const path = ['/CMS/shop/' + item.shopId];
    var headers = {
        "x-api-key": API_KEY,
        "x-app-language": language,
        "x-airport-code": airportCode,
        "Authorization": (await Auth.currentSession()).getIdToken().getJwtToken(),
    };
    const myInit = {
        headers: headers,
        response: true,
        body: item,
    };
    try {
        var response = await API.put(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error updating shop: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
};


const deleteShop = async (shopId) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const language = process.env.VUE_APP_I18N_LOCALE;
    const airportCode = process.env.VUE_APP_AIRPORT_CODE;
    const path = ["/CMS/shop/" + shopId];
    var headers = {
        "x-api-key": API_KEY,
        "x-app-language": language,
        "x-airport-code": airportCode,
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    };

    const myInit = {
        headers: headers,
        response: true,
    };
    try {
        var response = await API.del(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error deleting shop: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
};

export { 
    getShops, 
    getShop,
    createShop,
    updateShop,
    deleteShop,
};
