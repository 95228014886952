const MAX_FLIGHTS = 3;
const APP_VERSION = '3.0';
const API_KEY = process.env.VUE_APP_API_KEY;
const API_NAME = process.env.VUE_APP_API_NAME;
const AIRPORT_CODE = process.env.VUE_APP_AIRPORT_CODE;
import { API,Auth } from "aws-amplify";
import util from "util";



/**
 * Get airport flights
 *
 * @param {Object} filters - Filters, values:
 *      {string} locale - language code
 *      {string} flightDirection - D/A (Departure/Arrival)
 *      {Date} date - Flights date
 * @returns {Promise} Promise - object with the result of the api call response
 */
const getFlights = async (filters) => {
    let flights = [];
    const path = ['/flights'];

    let params = {...filters};
    delete params.locale;
    if (filters.date == null) {
        delete params.date;
    }
    if (filters.internationalStatus == null) {
        delete params.internationalStatus;
    }

    /* No sirve para limitar los resultados, cambia la estructura de la respuesta
    if (filters.isHome) {
        params.page = 1;
        params.limit = 6;
    }
    */
    const language = process.env.VUE_APP_I18N_LOCALE;

    const myInit = {
        headers: {
            "x-api-key": API_KEY,
            "x-app-version": APP_VERSION,
            "x-airport-code": AIRPORT_CODE,
            "x-app-language": language
        },
        response: true,
        queryStringParameters: params,
    };
    try {
        let response = await API.get(API_NAME, path, myInit);
        flights = response.data;
    } catch (error) {
        console.error('Error getting flights', error);
    }

    if (filters.isHome) {
        // Limit
        return flights.slice(0, MAX_FLIGHTS);
    }

    return flights;
}

const deleteFlight = async (flightId) => {
    const API_KEY = process.env.VUE_APP_API_KEY;
    const API_NAME = process.env.VUE_APP_API_NAME;
    const language = process.env.VUE_APP_I18N_LOCALE;
    const airportCode = process.env.VUE_APP_AIRPORT_CODE;
    const path = ["/CMS/flight/" + flightId];
    var headers = {
        "x-api-key": API_KEY,
        "x-app-language": language,
        "x-airport-code": airportCode,
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    };

    const myInit = {
        headers: headers,
        response: true,
    };
    try {
        var response = await API.del(API_NAME, path, myInit);
        return response;
    } catch (error) {
        console.error(
            "Error deleting flight: ",
            util.inspect(error, { showHidden: false, depth: null })
        );
        return false;
    }
};

export {
    getFlights,
    deleteFlight
}

