<template>
    <div>

        <div class="timePicker_info">
            <div style="grid-template-columns: auto auto auto;">
                <span v-if="disabled">Disabled: {{ disabled }}</span>
                <span v-else>Disabled: -</span>
                <span>/</span>
                <span v-if="creating">Creating: {{ creating }}</span>
                <span v-else>Creating: -</span>
            </div>
            <div style="grid-template-columns: auto auto auto auto;">
                <span v-if="entryDate">{{ entryDate }}</span>
                <span>/</span>
                <span v-if="entryTimeHour">{{ entryTimeHour }}</span>
                <span v-if="entryTimeMinute">{{ entryTimeMinute }}</span>
            </div>
            <div style="grid-template-columns: auto auto auto auto auto auto;">
                <span v-if="flightDate">{{ flightDate }}</span>
                <span>/</span>
                <span v-if="flightTimeHour">{{ flightTimeHour }}</span>
                <span v-if="flightTimeMinute">{{ flightTimeMinute }}</span>
                <span>/</span>
                <span v-if="flightDirection">{{ flightDirection }}</span>
            </div>
        </div>

        <div class="inputTypeTime">
            <div>
                <select v-model="hour" @input="inputHour" :disabled="disabled">
                    <option v-for="(hour, index) in hoursArray" :key="index" :value="hour"
                        :disabled="ifIsPast_hours(hour)">{{ hour }}</option>
                </select>
                <span class="inputTypeTimeTwoPoints">
                    :
                </span>
                <select v-model="minute" @input="inputMinute" :disabled="disabled">
                    <option v-for="(minute, index) in minutesArray_1" :key="index" :value="minute"
                        :disabled="ifIsPast_minutes(minute)">{{ minute }}</option>
                </select>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: ['value', 'disabled', 'creating', 'entryDate', 'entryTimeHour', 'entryTimeMinute', 'flightDirection', 'flightDate', 'flightTimeHour', 'flightTimeMinute'],
    created: async function () {
    },
    mounted: async function () {
        if (this.entryTimeHour && this.entryTimeMinute) {
            this.hour = this.entryTimeHour;
            this.minute = this.entryTimeMinute;
            this.$emit('input', this.getTimeString());
        }
        if (this.flightTimeHour && this.flightTimeMinute) {
            this.hour = this.flightTimeHour;
            this.minute = this.flightTimeMinute;
            this.$emit('input', this.getTimeString());
        }
    },
    watch: {
        /*
        value: function (newVal, oldVal) {
        },
        */
        flightDate: function (newVal, oldVal) {
            if ((newVal && this.creating) || ((oldVal != null) && (newVal != oldVal) && (!this.creating))) {
                this.recalculateTime();
            }
        },
        flightDirection: function (newVal, oldVal) {
            if ((newVal && this.creating) || ((oldVal != null) && (newVal != oldVal) && (!this.creating))) {
                this.recalculateTime();
            }
        },
        entryTimeHour: function (newVal, oldVal) {
            if (newVal) {
                this.hour = this.entryTimeHour;
                this.$emit('input', this.getTimeString());
            }
            //((oldVal != null) && (newVal != oldVal) && (this.creating == false)) {
            if ((newVal && this.creating) || ((oldVal != null) && (newVal != oldVal) && (!this.creating))) {
                //console.log(newVal + " / " + oldVal + " / " + this.creating);
                this.recalculateTime();
            }
        },
        entryTimeMinute: function (newVal, oldVal) {
            if (newVal) {
                this.minute = this.entryTimeMinute;
                this.$emit('input', this.getTimeString());
            }
            if ((newVal && this.creating) || ((oldVal != null) && (newVal != oldVal) && (!this.creating))) {
                this.recalculateTime();
            }
        },
        flightTimeHour: function (newVal) {
            if (newVal) {
                this.hour = this.flightTimeHour;
                //console.log(newVal + " / " + this.flightTimeHour + " / " + this.hour);
                this.$emit('input', this.getTimeString());
            }
        },
        flightTimeMinute: function (newVal) {
            if (newVal) {
                this.minute = this.flightTimeMinute;
                this.$emit('input', this.getTimeString());
            }
        }
    },
    data() {
        return {
            hour: "",
            minute: "",
            hoursArray: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"],
            minutesArray_1:
                ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"],
            minutesArray_2:
                ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09",
                    "10", "11", "12", "13", "14", "15", "16", "17", "18", "19",
                    "20", "21", "22", "23", "24", "25", "26", "27", "28", "29",
                    "30", "31", "32", "33", "34", "35", "36", "37", "38", "39",
                    "40", "41", "42", "43", "44", "45", "46", "47", "48", "49",
                    "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"]
        }
    },
    methods: {
        inputHour(event) {
            this.touched = true;
            this.hour = event.target.value;
            this.$emit('input', this.getTimeString());

            this.$emit('entryTimeHour_call', this.hour);

            this.recalculate_flightTime();
        },
        inputMinute(event) {
            this.touched = true;
            this.minute = event.target.value;
            this.$emit('input', this.getTimeString());

            this.$emit('entryTimeMinute_call', this.minute);

            this.recalculate_flightTime();
        },
        getTimeString() {
            var timeString = "";
            if (this.hour != "" && this.minute != "") {
                timeString = `${this.hour}:${this.minute}`;
            }
            return timeString;
        },
        currentHourValue(hourMore) {
            const today = new Date();
            let hh = today.getHours();

            //Añadir una hora
            if (hourMore == 1) {
                hh += 1;
            }

            //Pasar al día siguiente
            if (hh == 24) {
                hh = 0;
                //emit('changeNextDay',true);
                this.$emit('changeNextDay');
            }

            if (hh < 10) hh = '0' + hh;
            let currentHour = hh;

            this.$emit('entryTimeHour_call', hh);

            return currentHour;
        },
        currentMinutesValue(round) {
            const today = new Date();
            let mm = today.getMinutes();

            //if (mm < 10) mm = '0' + mm;
            let currentMinute = mm;

            //····· Caso solo entryDate ·····
            //Redondear a los 5 minutos siguientes (solo con minutesArray_1)
            if (round == 1) {
                currentMinute = Math.ceil(currentMinute / 5) * 5;
            }

            //Pasar a la hora siguiente
            if (currentMinute == 60) {
                currentMinute = 0;
                this.hour = this.currentHourValue(1);
            }
            //·······························

            let currentMinute_2 = currentMinute.toString();
            if (currentMinute_2.length < 2) {
                currentMinute = "0" + currentMinute;
            }

            //console.log(currentMinute + "/" + hour);

            this.$emit('entryTimeMinute_call', currentMinute);

            return currentMinute;
        },
        recalculateTime() {
            let selectedEntryDate = this.entryDate;
            let selectedFlightDate = this.flightDate;

            if (selectedEntryDate && selectedFlightDate) {
                this.hour = "";
                this.minute = "";
                this.$emit('input', null);
            }
        },
        recalculate_flightTime_minutes(mm) {
            mm = Math.ceil(mm / 5) * 5;

            //Pasar a la hora siguiente
            if (mm == 60) {
                mm = "00";
                this.hour = parseInt(this.hour) + 1;
            }

            return mm;
        },
        recalculate_flightTime() {

            const today = new Date();
            let yyyy = today.getFullYear();
            let mm = today.getMonth() + 1; // Months start at 0!
            let dd = today.getDate();
            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;
            let currentDate = yyyy + '-' + mm + '-' + dd;
            let currentHours = today.getHours();
            let currentMinutes = today.getMinutes();

            let selectedEntryDate = this.entryDate;
            let selectedFlightDate = this.flightDate;
            let selectedDirection = this.flightDirection;
            let selectedEntryHour_hours = this.entryTimeHour;
            let selectedEntryHour_minutes = this.entryTimeMinute;

            /*
            console.info("selectedDirection: " + selectedDirection);
            console.info("selectedFlightDate: " + selectedFlightDate);
            console.info("selectedEntryDate: " + selectedEntryDate);
            console.info("this.hour : " + this.hour);
            console.info("selectedEntryHour_hours: " + selectedEntryHour_hours);
            console.info("this.minute: " + this.minute);
            console.info("selectedEntryHour_minutes: " + selectedEntryHour_minutes);

            console.info("selectedFlightDate: " + selectedFlightDate);
            console.info("currentDate: " + currentDate);
            console.info("this.hour : " + this.hour);
            console.info("currentHours: " + currentHours);
            */

            if (selectedFlightDate) {
                if ((selectedFlightDate == currentDate) && (this.hour == currentHours) && (this.minute < currentMinutes)) {
                    if (currentMinutes < 10) currentMinutes = '0' + currentMinutes;
                    //this.minute = currentMinutes;
                    this.minute = this.recalculate_flightTime_minutes(currentMinutes);
                    this.$emit('input', this.getTimeString());
                }
            }
            if (selectedEntryDate && selectedFlightDate) {
                if ((selectedDirection == "D") && (selectedFlightDate == selectedEntryDate) && (this.hour == selectedEntryHour_hours) && (this.minute < selectedEntryHour_minutes)) {
                    //this.minute = selectedEntryHour_minutes;
                    this.minute = this.recalculate_flightTime_minutes(selectedEntryHour_minutes);
                    this.$emit('input', this.getTimeString());
                }
                if ((selectedDirection == "A") && (selectedFlightDate == selectedEntryDate) && (this.hour == selectedEntryHour_hours) && (this.minute > selectedEntryHour_minutes)) {
                    //this.minute = selectedEntryHour_minutes;
                    this.minute = this.recalculate_flightTime_minutes(selectedEntryHour_minutes);
                    this.$emit('input', this.getTimeString());
                }
            }

        },
        ifIsPast_hours(hourParam) {
            var result = false;

            const today = new Date();
            let yyyy = today.getFullYear();
            let mm = today.getMonth() + 1; // Months start at 0!
            let dd = today.getDate();
            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;
            let currentDate = yyyy + '-' + mm + '-' + dd;
            let currentHours = today.getHours();
            //let currentMinutes = today.getMinutes();

            let selectedEntryDate = this.entryDate;
            let selectedFlightDate = this.flightDate;
            let selectedDirection = this.flightDirection;
            let selectedEntryHour_hours = this.entryTimeHour;
            //let selectedEntryHour_minutes = this.entryTimeMinute;



            if (selectedEntryDate && !selectedFlightDate) {
                //Si la fecha de entrada es la misma que la fecha actual y las horas son inferiores a las horas actuales:
                //-> cambio las horas por las horas actuales + 1 hora y cambio los minutos por 00" 
                if ((selectedEntryDate == currentDate) && (this.hour < currentHours) && (this.creating)) {
                    this.hour = this.currentHourValue(1);
                    this.minute = "00";
                    this.$emit('input', this.getTimeString());
                }
                //Si la fecha de entrada es la misma que la fecha actual:
                //-> bloqueo las horas anteriores a las horas actuales en el select
                if ((selectedEntryDate == currentDate)) {
                    result = hourParam < currentHours ? true : false;
                }
            }




            if (selectedFlightDate) {

                if ((selectedFlightDate == currentDate)) {
                    result = hourParam < currentHours ? true : false;
                }

            }

            if (selectedEntryDate && selectedFlightDate) {

                if (selectedFlightDate == selectedEntryDate) {
                    if (selectedDirection == 'D') {
                        result = hourParam < selectedEntryHour_hours ? true : false;
                    }
                    else if (selectedDirection == 'A') {
                        result = hourParam > selectedEntryHour_hours ? true : false;

                        if ((selectedFlightDate == currentDate) && (selectedEntryDate == currentDate)) {
                            result = ((hourParam < currentHours) || (hourParam > selectedEntryHour_hours)) ? true : false;
                        }

                    }
                }

            }



            return result;
        },
        ifIsPast_minutes(minuteParam) {
            var result = false;

            const today = new Date();
            let yyyy = today.getFullYear();
            let mm = today.getMonth() + 1; // Months start at 0!
            let dd = today.getDate();
            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;
            let currentDate = yyyy + '-' + mm + '-' + dd;
            let currentHours = today.getHours();
            let currentMinutes = today.getMinutes();

            let selectedEntryDate = this.entryDate;
            let selectedFlightDate = this.flightDate;
            let selectedDirection = this.flightDirection;
            let selectedEntryHour_hours = this.entryTimeHour;
            let selectedEntryHour_minutes = this.entryTimeMinute;



            if (selectedEntryDate && !selectedFlightDate) {
                //Si la fecha de entrada es la misma que la fecha actual y las horas son las mismas que las horas actuales y los minutos son inferiores a los minutos actuales:
                //-> cambio los minutos por los minutos actuales redondeándolos
                if ((selectedEntryDate == currentDate) && (this.hour == currentHours) && (this.minute < currentMinutes) && (this.creating)) {
                    this.minute = this.currentMinutesValue(1);
                    this.$emit('input', this.getTimeString());
                }
                //Si la fecha de entrada es la misma que la fecha actual y las horas son las mismas que las horas actuales:
                //-> bloqueo los minutos anteriores a los minutos actuales en el select
                if ((selectedEntryDate == currentDate) && (this.hour == currentHours)) {
                    result = minuteParam < currentMinutes ? true : false;
                }
            }



            if (selectedFlightDate) {

                if ((selectedFlightDate == currentDate) && (this.hour == currentHours)) {
                    result = minuteParam < currentMinutes ? true : false;
                }

            }

            if (selectedEntryDate && selectedFlightDate) {

                if ((selectedFlightDate == selectedEntryDate) && (this.hour == selectedEntryHour_hours)) {
                    if (selectedDirection == 'D') {
                        result = minuteParam < selectedEntryHour_minutes ? true : false;
                    }
                    else if (selectedDirection == 'A') {
                        result = minuteParam > selectedEntryHour_minutes ? true : false;

                        if ((selectedFlightDate == currentDate) && (selectedEntryDate == currentDate) && (this.hour == currentHours)) {
                            result = ((minuteParam < currentMinutes) || (minuteParam > selectedEntryHour_minutes)) ? true : false;
                        }

                    }
                }

            }



            return result;
        }
    }
}
</script>

<style scoped>
.timePicker_info {
    position: absolute;
    top: -5rem;
    width: 25rem;
    font-size: 0.75rem;
    display: none;
}

.timePicker_info>div {
    display: grid;
    gap: 0.5rem;
    width: fit-content;
}
</style>