<template>
    <div>
        <md-dialog  :md-close-on-esc="false" :md-active.sync="show" :md-click-outside-to-close="false">
            <md-dialog-content>
                <ValidationObserver tag="form" ref="form" v-slot="{ errors }">
                    <md-field slot="inputs" :class="[
                        {
                            'md-valid':
                                errors['password'] &&
                                !errors['password'][0] &&
                                touchedFields.password,
                        },
                        { 'md-form-group': true },
                        {
                            'md-error':
                                errors['password'] &&
                                errors['password'][0],
                        },
                    ]">
                        <md-icon>lock_outline</md-icon>
                        <label for="password">{{
                            $t("fields.password")
                        }}</label>
                        <validation-provider name="password" rules="required" v-slot="{ errors, touched }"
                            mode="aggressive">
                            <md-input v-model="password" name="password" type="password"
                                :placeholder="$t('fields.password')" id="password" :disabled="loading"></md-input>
                            <slide-y-down-transition>
                                <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                            </slide-y-down-transition>
                            <span class="md-error">{{ errors[0] }}</span>
                        </validation-provider>
                    </md-field>
                </ValidationObserver>
            </md-dialog-content>
            <md-dialog-actions>
                <md-button @click="closeModal()" class="md-danger md-round" :disabled="loading">
                    {{ $t("cancel") }}
                </md-button>
                <md-button @click="confirmPassword()" class="md-primary md-round" :disabled="loading">
                    {{ $t("confirm") }}
                </md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>

<script>
import { Auth } from "aws-amplify";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import authMixin from "@/mixins/auth";

export default {
    mixins: [authMixin],
    props: ['showProp'],
    components: {
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
    },

    data() {
        return {
            show: false,
            loading: false,
            password: "",
            touchedFields: {
                password: false,
            },
        };
    },

    watch: {
        showProp() {
            this.show = this.showProp;
        },
    },

    methods: {
        closeModal() {
            this.$emit("close");
            this.clearForm();
        },

        clearForm() {
            this.password = "";
            this.touchedFields.password = false;
        },

        async confirmPassword() {
            this.loading = true;

            var valid = await this.$refs.form.validate();

            if (valid) {
                try {
                    const email = await this.getUserEmail();
                    this.user = await Auth.signIn(email, this.password);
                    this.$emit("confirm");
                    this.clearForm();
                    this.loading = false;
                } catch (error) {
                    console.error(error);
                    this.$noty.error(this.$t('error.login'));
                    this.loading = false;
                }
            }
            this.loading = false;
        },
    },
}
</script>

<style scoped>

</style>