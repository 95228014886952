<template>
    <ValidationObserver tag="form" ref="form" v-slot="{ errors }">
        <div class="md-layout form-layout">

            <!-- Button toolbar -->
            <div class="md-layout-item md-size-100 form-toolbar">
                <h3 class="page-title">
                    <span v-if="creating">{{ $t("routes.faq_create.title") }}</span>
                    <span v-if="editing">{{ $t("routes.faq_edit.title") }} - {{ item.question }}</span>
                </h3>
                <div class="form-buttons">
                    <md-progress-spinner v-if="loading" :md-diameter="30" :md-stroke="3" md-mode="indeterminate">
                    </md-progress-spinner>
                    <md-button type="submit" class="md-primary md-round btn-medium" @click.prevent="save()">
                        {{ $t("save") }}
                    </md-button>
                </div>
            </div>
            <!-- Order -->
            <div class="md-layout-item md-size-40">
                <md-field>
                    <md-select v-model="item.order" :disabled="loading" :placeholder="$t('label.order')">
                        <md-option v-for="(order, index) in orders" :key="index" :value="order.value">
                            {{ order.text }}
                        </md-option>
                    </md-select>
                    <span class="md-error">
                        {{ errorOrderMessage }}
                    </span>
                </md-field>
            </div>
            <div class="md-layout-item md-size-10"></div>
            <!-- Enabled -->
            <div class="md-layout-item md-size-50">
                <div class="field-switch">
                    <Span>{{ $t("label.faq_enabled") }}</Span>
                    <md-switch id="switchEnabled" v-model="item.enabled" :disabled="loading">
                    </md-switch>
                </div>
            </div>
            <!-- faq  type -->
            <div class="md-layout-item md-size-40">
                <md-field>
                    <md-select v-model="item.faqType" :disabled="loading" :placeholder="$t('label.faq_faqType')"
                        @md-selected="checkValues()">
                        <md-option :value="0">
                            {{ $t("label.faq_faqType_value_0") }}
                        </md-option>
                        <md-option :value="1">
                            {{ $t("label.faq_faqType_value_1") }}
                        </md-option>
                        <md-option :value="2">
                            {{ $t("label.faq_faqType_value_2") }}
                        </md-option>
                        <md-option :value="3">
                            {{ $t("label.faq_faqType_value_3") }}
                        </md-option>
                    </md-select>
                </md-field>
            </div>

            <!-- Separator localizations -->
            <div class="md-layout-item md-size-100">
                <div class="Group-Header">
                    <span class="Luggage-information">
                        {{ $t("label.language_information") }}
                    </span>
                    <div class="Path"></div>
                </div>
            </div>

            <!-- Translations -->
            <md-card class="translations-card">
                <md-card-content>
                    <md-tabs class="translations-tabs">
                        <md-tab id="tab-tr" :md-label="$t('languages.tr')" :md-icon="getLanguageIcon('tr', errors)">
                            <!-- question tr -->
                            <div class="md-layout-item md-size-100">
                                <md-field :class="[
                                    { 'md-valid': errors['question_tr'] && !errors['question_tr'][0] && touchedFields.question_tr },
                                    { 'md-error': errors['question_tr'] && errors['question_tr'][0] }
                                ]">
                                    <label for="text">{{ $t('fields.faq_question') }}</label>
                                    <validation-provider name='question_tr' rules="required" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-textarea :md-autogrow="false" f v-model="question_tr" name="question_tr"
                                            :placeholder="$t('label.faq_question')" :disabled="loading">
                                        </md-textarea>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                            <!-- answer TR -->
                            <!-- CK Editor -->
                            <div class="rich-text-editor">
                                <label class="rich-text-title">{{ $t("label.faq_answer") }}</label>
                                <validation-provider name='answer_tr' rules="required" v-slot="{ errors }" mode="aggressive">
                                    <ckeditor :editor="editor" v-model="answer_tr" :config="editorConfig"></ckeditor>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </div>

                        </md-tab>
                        <md-tab id="tab-en" :md-label="$t('languages.en')" :md-icon="getLanguageIcon('en', errors)">
                            <!-- question en -->
                            <div class="md-layout-item md-size-100">
                                <md-field :class="[
                                    { 'md-valid': errors['question_en'] && !errors['question_en'][0] && touchedFields.question_en },
                                    { 'md-error': errors['question_en'] && errors['question_en'][0] }
                                ]">
                                    <label for="text">{{ $t('fields.faq_question') }}</label>
                                    <validation-provider name='question_en' rules="required" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-textarea :md-autogrow="false" f v-model="question_en" name="question_en"
                                            :placeholder="$t('label.faq_question')" :disabled="loading">
                                        </md-textarea>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                            <!-- answer EN -->
                            <!-- CK Editor -->
                            <div class="rich-text-editor">
                                <label class="rich-text-title">{{ $t("label.faq_answer") }}</label>
                                <validation-provider name='answer_en' rules="required" v-slot="{ errors }" mode="aggressive">
                                    <ckeditor :editor="editor" v-model="answer_en" :config="editorConfig"></ckeditor>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </div>

                        </md-tab>
                    </md-tabs>
                </md-card-content>
            </md-card>
        </div>
    </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import localeMixin from "@/mixins/locale";
import s3Mixin from "@/mixins/s3";
import { CKEditorConfigMedium } from "@/config";
import ClassicEditor from '@fiveflames/ckeditor5-build-classic';
import {
    getFaq,
    createFaq,
    updateFaq,
} from "@/services/faqs";

export default {
    mixins: [localeMixin, s3Mixin],
    components: {
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
    },
    data() {
        return {
            loading: false,
            creating: false,
            editing: false,
            orders:[],
            touchedFields: {
                answer_en: false,
                answer_tr: false,
                question_en: false,
                question_tr: false,
            },
            item: {
                order: 0,
                enabled: false,
                faqType: 0,
                translations: [
                    {
                        locale: "en",
                        answer: "",
                        question: "",
                    },
                    {
                        locale: "tr",
                        answer: "",
                        question: "",
                    }
                ],
            },
            answer_en: "",
            answer_tr: "",
            question_en: "",
            question_tr: "",
            errorFaqTypeMessage: "",
            errorOrderMessage: "",
            editor: ClassicEditor,
            editorConfig: CKEditorConfigMedium,
        };
    },

    created: async function () {
        let faqId = this.$route.params.faqId;
        if (faqId) {
            this.editing = true;
            await this.fetchItem(faqId);
        } else {
            let lastOrder = this.$route.params.lastOrder;
            if (lastOrder) {
                this.item.order = lastOrder;
            }
            
            this.creating = true;
        }
    },

    async mounted() {
        this.fillOrdersArray();
    },

    methods: {

        fillOrdersArray() {
            let min = 0;
            let max = 50;
            this.orders = [];
            for (let i = min; i <= max; i++) {
                if (i == 0) {
                    let z = { value: i, text: this.$t('label.order_default') };
                    this.orders.push(z);
                } else {
                    let b = { value: i, text: i.toString() };
                    this.orders.push(b);
                }
            }
        },

        assignDataModel(item) {
            this.item = item;
            this.item.enabled = this.item.enabled == 1 ? true : false;
            this.putItemTranslations();
        },

        getItemTranslations() {
            let translation_en = this.item.translations.find(({ locale }) => locale == "en");
            if (translation_en) {
                translation_en.answer = this.answer_en;
                translation_en.question = this.question_en;
            } else {
                translation_en = {
                    locale: "en",
                    answer: this.answer_en,
                    question: this.question_en,
                }
            }
            let translation_tr = this.item.translations.find(({ locale }) => locale == "tr");
            if (translation_tr) {
                translation_tr.answer = this.answer_tr;
                translation_tr.question = this.question_tr;
            } else {
                translation_tr = {
                    locale: "tr",
                    answer: this.answer_tr,
                    question: this.question_tr,
                }
            }
            const translations = [translation_en, translation_tr];
            this.item.translations = translations;
        },

        putItemTranslations() {
            let translation_en = this.item.translations.find(({ locale }) => locale == "en");
            if (translation_en) {
                this.answer_en = translation_en.answer;
                this.question_en = translation_en.question;
            }
            let translation_tr = this.item.translations.find(({ locale }) => locale == "tr");
            if (translation_tr) {
                this.answer_tr = translation_tr.answer;
                this.question_tr = translation_tr.question;
            }
            const translations = [translation_en, translation_tr];
            this.item.translations = translations;
        },

        async fetchItem(itemId) {
            this.loading = true;
            let response = await getFaq(itemId);
            if (response) {
                this.assignDataModel(response);
            }
            this.loading = false;
        },

        async save() {
            this.loading = true;



            if (this.item.order == 0) {
                valid = false;
                this.errorOrderMessage = this.$t('validation.at_least_one_value', { _field_: this.$t('label.dine_order') });
            }

            var valid = await this.$refs.form.validate();

            if (valid) {
                this.getItemTranslations();
                if (this.creating) {
                    const response = await createFaq(this.item);
                    if (response) {
                        this.$noty.success(this.$t("success.saved"));
                        this.$router.push({ name: this.$i18n.locale + '.faqs' });
                    } else {
                        this.$noty.error(this.$t("error.saved"));
                    }
                } else if (this.editing) {
                    const response = await updateFaq(this.item);
                    if (response) {
                        this.$noty.success(this.$t("success.saved"));
                    } else {
                        this.$noty.error(this.$t("error.saved"));
                    }
                }
            } else {
                this.$noty.error(this.$t('error.saved'));
            }

            this.loading = false;
        },
    },
}
</script>