<template>
    <div class="wrapper" :class="[
        { 'nav-open': $sidebar.showSidebar },
        { rtl: $route.meta.rtlActive }
    ]">
        <notifications></notifications>
        <side-bar :active-color="sidebarBackground" :data-background-color="sidebarBackgroundColor">
            <!-- <user-menu></user-menu> -->
            <mobile-menu></mobile-menu>
            <template slot="links">
                <!-- <sidebar-item v-if="$route.meta.rtlActive" :link="{ name: '', icon: 'dashboard' }">
                </sidebar-item> -->

                <sidebar-item v-for="(item, index) of sidebarElements" :key="index"
                    :link="getRouteLink(item.routeName, item.icon)">

                    <sidebar-item v-for="(item, index) of item.sidebarSubElements" :key="index"
                        :link="getRouteLink(item.routeName, item.icon)">
                    </sidebar-item>

                </sidebar-item>

                <!-- <sidebar-item :class="link" v-for="(item, index) of sidebarElements" :key="index"
                    :link="getRouteLink(item.routeName, item.icon)">
                </sidebar-item> -->

                <!-- <sidebar-item v-if="$route.meta.rtlActive" :link="{ name: '', icon: 'image' }">
                    <sidebar-item :link="getRouteLink('login')"></sidebar-item>
                    <sidebar-item :link="getRouteLink('register')"></sidebar-item>
                    <sidebar-item :link="getRouteLink('profile')"></sidebar-item>
                </sidebar-item> -->
                <!-- <sidebar-item v-else :link="{ name: 'Pages', icon: 'image' }">
                    <sidebar-item :link="getRouteLink('login')"></sidebar-item>
                    <sidebar-item :link="getRouteLink('register')"></sidebar-item>
                    <sidebar-item :link="getRouteLink('profile')"></sidebar-item>
                </sidebar-item> -->
            </template>
        </side-bar>
        <div class="main-panel">
            <top-navbar></top-navbar>
            <!-- <fixed-plugin :color.sync="sidebarBackground" :colorBg.sync="sidebarBackgroundColor" :sidebarMini.sync="sidebarMini" :sidebarImg.sync="sidebarImg" :image.sync="sidebarBackgroundImage">
            </fixed-plugin> -->
            <div :class="{ content: !$route.meta.hideContent }" @click="toggleSidebar">
                <zoom-center-transition :duration="200" mode="out-in">
                    <!-- your content here -->
                    <router-view></router-view>
                </zoom-center-transition>
            </div>
            <!-- <content-footer v-if="!$route.meta.hideFooter"></content-footer> -->
        </div>
    </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";


function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
    if (hasElement(className)) {
        new PerfectScrollbar(`.${className}`);
    } else {
        // try to init it later in case this component is loaded async
        setTimeout(() => {
            initScrollbar(className);
        }, 100);
    }
}

import authMixin from "../../../mixins/auth";
import TopNavbar from "./TopNavbar.vue";
// import ContentFooter from "./ContentFooter.vue";
import MobileMenu from "./MobileMenu.vue";
// import FixedPlugin from "../FixedPlugin.vue";
// import UserMenu from "./UserMenu.vue";
import { ZoomCenterTransition } from "vue2-transitions";
import linkMixin from "../../../mixins/link";

export default {
    mixins: [authMixin, linkMixin],
    components: {
        TopNavbar,
        // ContentFooter,
        MobileMenu,
        // FixedPlugin,
        // UserMenu,
        ZoomCenterTransition
    },
    data() {
        return {
            sidebarBackgroundColor: "white",
            sidebarBackground: "azure",
            sidebarBackgroundImage: "/img/sidebar-2.jpg",
            sidebarMini: true,
            sidebarImg: true,
            sidebarElements: [],
            sidebarElementsSource: [
                {
                    routeName: "dashboard_general",
                    role: ["1", "2", "3"],
                    order: "00",
                    isDefault: true,
                    isMenu: true,
                    sidebarSubElements: [
                        {
                            routeName: "dashboard",
                            icon: "dashboard",
                            role: ["1", "2", "3"],
                            order: "00",
                        },
                        {
                            routeName: "activities",
                            icon: "local_activity",
                            role: ["1", "2"],
                            order: "01"
                        },
                        {
                            routeName: "airlines",
                            icon: "flight",
                            role: ["1", "2"],
                            order: "02"
                        },
                        {
                            routeName: "airports",
                            icon: "connecting_airports",
                            role: ["1", "2"],
                            order: "03"
                        },
                        {
                            routeName: "alerts",
                            icon: "notifications",
                            role: ["1", "2"],
                            order: "04"
                        },
                        {
                            routeName: "custom_pages",
                            icon: "web",
                            role: ["1", "2"],
                            order: "05"
                        },
                        {
                            routeName: "destinations",
                            icon: "flight_takeoff",
                            role: ["1", "2"],
                            order: "06"
                        },
                        {
                            routeName: "faqs",
                            icon: "quiz",
                            role: ["1", "2"],
                            order: "07"
                        },
                        {
                            routeName: "multimedia",
                            icon: "collections",
                            role: ["1", "2", "3"],
                            order: "08"
                        },
                        {
                            routeName: "news",
                            icon: "newspaper",
                            role: ["1", "2"],
                            order: "09"
                        },
                        {
                            routeName: "restaurants",
                            icon: "restaurant",
                            role: ["1", "2"],
                            order: "10"
                        },
                        {
                            routeName: "shops",
                            icon: "shopping_bag",
                            role: ["1", "2"],
                            order: "11"
                        },
                        {
                            routeName: "services",
                            icon: "miscellaneous_services",
                            role: ["1", "2"],
                            order: "12"
                        },
                        {
                            routeName: "steps",
                            icon: "assistant",
                            role: ["1", "2"],
                            order: "13"
                        },
                        {
                            routeName: "suggestions",
                            icon: "assistant_photo",
                            role: ["1", "2"],
                            order: "14"
                        },
                        {
                            routeName: "touristicRegions",
                            icon: "explore",
                            role: ["1", "2"],
                            order: "15"
                        },
                    ]
                },
                {
                    routeName: "dashboard_iap",
                    role: ["1", "3","4","5", "6", "7"],
                    order: "01",
                    isMenu: true,
                    sidebarSubElements: [
                        {
                            routeName: "booking_create",
                            icon: "fiber_new",
                            role: ["6", "7"],
                            order: "01"
                        },
                        {
                            routeName: "bookings",
                            icon: "book_online",
                            role: ["1", "3","4","5", "6", "7"],
                            order: "02"
                        },
                        {
                            routeName: "ancillaries",
                            icon: "shopping_cart",
                            role: ["1", "3","4"],
                            order: "03"
                        },
                        {
                            routeName: "clients",
                            icon: "switch_account",
                            role: ["1", "3","4"],
                            order: "04"
                        },
                        {
                            routeName: "client_users",
                            icon: "person",
                            role: ["1", "3","4"],
                            order: "05"
                        }
                    ]
                },
                {
                    routeName: "dashboard_cms_notifications",
                    icon: "notifications",
                    role: ["1"],
                    order: "03",
                    isMenu: true,
                    sidebarSubElements: [

                        {
                            routeName: "emergency_mode",
                            icon: "crisis_alert",
                            role: ["1"],
                            order: "01"
                        },
                        {
                            routeName: "normal_mode",
                            icon: "sms",
                            role: ["1"],
                            order: "02"
                        },
                    ]
                },
                {
                    routeName: "dashboard_cms_setup",
                    icon: "account_circle",
                    role: ["1"],
                    order: "16",
                    isMenu: true,
                    sidebarSubElements: [

                        {
                            routeName: "users",
                            icon: "account_circle",
                            role: ["1"],
                            order: "01"
                        },
                        {
                            routeName: "mail_templates",
                            icon: "mail",
                            role: ["1"],
                            order: "02"
                        },
                        {
                            routeName: "flights",
                            icon: "flight",
                            role: ["1"],
                            order: "16"
                        }
                    ]
                },

            ],
        };
    },

    created: async function () {
        await this.fillMenu();
    },

    methods: {
        async fillMenu() {
            let role = await this.getUserRole();
            this.sidebarElementsSource.map(element => {
                if (element.role.includes(role)) {
                    var newelement = {};
                    newelement.routeName = element.routeName;
                    newelement.role = element.role;
                    newelement.order = element.order;
                    newelement.isMenu = true;
                    newelement.sidebarSubElements = [];
                    element.sidebarSubElements.map(subelement => {
                        if (subelement.role.includes(role)) {
                            subelement.isMenu = false;
                            newelement.sidebarSubElements.push(subelement);
                        }
                    });
                    newelement.sidebarSubElements.sort((a, b) => a.order.localeCompare(b.order)); // Sort by name field
                    this.sidebarElements.push(newelement);
                }
            });
            this.sidebarElements.sort((a, b) => a.order.localeCompare(b.order)); // Sort by name field

            this.sidebarElements[0].sidebarSubElements[0].isDefault = true;
        },
        toggleSidebar() {
            if (this.$sidebar.showSidebar) {
                this.$sidebar.displaySidebar(false);
            }
        },
        minimizeSidebar() {
            if (this.$sidebar) {
                this.$sidebar.toggleMinimize();
            }
        },
        getRouteLink(route, icon) {
            let routeLink = {
                name: this.$t('routes.' + route + '.title'),
                path: this.getRouteHref(route)
            }

            if (icon) {
                routeLink.icon = icon;
            }

            return routeLink;
        }
    },



    mounted() {
        let docClasses = document.body.classList;
        //Mantis 0012990: Menu - No se ven todas las opciones
        let useScroll = false;
        try {
            let platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown'
            if (platform.toLowerCase().startsWith("win") || platform.toLowerCase().startsWith("mac")) {
                useScroll = true;
            }
        } catch {
            useScroll = false;
        }
        if (useScroll) {
            // if we are on windows OS or mac OS we activate the perfectScrollbar function
            initScrollbar("sidebar");
            initScrollbar("sidebar-wrapper");
            initScrollbar("main-panel");

            docClasses.add("perfect-scrollbar-on");
        } else {
            docClasses.add("perfect-scrollbar-off");
        }
    },
    watch: {
        sidebarMini() {
            this.minimizeSidebar();
        }
    }
};
</script>
<style lang="scss">
$scaleSize: 0.95;

@keyframes zoomIn95 {
    from {
        opacity: 0;
        transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }

    to {
        opacity: 1;
    }
}

.main-panel .zoomIn {
    animation-name: zoomIn95;
}

@keyframes zoomOut95 {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
}

.main-panel .zoomOut {
    animation-name: zoomOut95;
}
</style>