<template>
    <ValidationObserver tag="form" ref="form" v-slot="{ errors }" class="B2B-booking-form">
        <div v-if="editing && item && item.bookingId" class="booking-reference">
            Booking reference: {{ item.bookingId }}
            <!--
            {{ item }}
            -->
        </div>
        <!-- Main data -->
        <div>
            <div class="Group-Header">

                <span class="Luggage-information">
                    {{ $t('label.general_information') }}
                    <md-progress-spinner style="margin-left:12px;margin-top: 4px;" v-if="loading" :md-diameter="18"
                        :md-stroke="3" md-mode="indeterminate">
                    </md-progress-spinner>
                </span>

                <div class="Path"></div>
            </div>
        </div>
        <div class="grid-2">

            <!-- Ancillary -->
            <md-field class="md-field-select custom_selectProduct" :class="[
        { 'md-valid': errors['ancillaryId'] && !errors['ancillaryId'] && touchedFields.ancillaryId },
        { 'md-error': errors['ancillaryId'] && errors['ancillaryId'] }]">
                <label class="md-select-label">{{ $t("label.product") }} *</label>
                <validation-provider name='product' rules="required" v-slot="{ errors }" mode="aggressive">
                    <md-select v-model="ancillaryId" :disabled="loading || editing"
                        :placeholder="$t('label.select_product') + ' *'">
                        <md-option v-for="(ancillary, index) in ancillaries" :key="index" :value="ancillary.ancillaryId"
                            class="custom_selectProduct_option">
                            {{ ancillary.name }}
                        </md-option>
                    </md-select>
                    <span class="md-error">{{ errors[0] }}</span>
                </validation-provider>
            </md-field>





            <!-- Entry Date Time -->
            <div class="entry-date-time">

                <!-- Entry Date -->
                <!--
                <md-datepicker v-model="entryDate" :md-immediately="true">
                    <label>{{ $t("label.entry_date") }} *</label>
                    <span class="md-error">
                        {{ errorEntryDateMessage }}
                    </span>
                </md-datepicker>
                -->
                <div class="md-field md-theme-default md-has-value md-has-placeholder custom_dateControl">
                    <div></div>
                    <label>{{ $t("label.entry_date") }} *</label>
                    <!--  //DATE PICKER -->
                    <div class="custom_dateControl_mask"></div>
                    <!-- onkeydown="return false" -->
                    <input v-model="entryDate" id="entryDate" name="entryDate" type="date" :min="currentDate"
                        :max="maxDate_entryDate" @input="change_flightDate" :disabled="loading || !canEditBooking">
                    <!--  //DATE PICKER -->
                    <span class="md-error">
                        {{ errorEntryDateMessage }}
                    </span>
                </div>

                <!-- Entry Hour -->
                <!--
                <md-field class="hour-input" :class="[
        { 'md-valid': errors['entry_time'] && !errors['entry_time'][0] && touchedFields.entryTime },
        { 'md-error': errors['entry_time'] && errors['entry_time'][0] }]">
                    <label for="entry_time">{{ $t('label.entry_time') }} *</label>
                    <validation-provider name='entry_time'
                        :rules="{ required: true, regex: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/ }" mode="aggressive">
                        <md-input v-model="entryTime" type="text" :placeholder="'HH:MM' + ' *'"
                            @keydown="checkHourFormat" :disabled="loading">
                        </md-input>
                        <slide-y-down-transition>
                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                        </slide-y-down-transition>
                        <span v-if="errors['entry_time'] && errors['entry_time'][0]" class="md-error">
                            {{ errors['entry_time'][0] }}
                        </span>
                    </validation-provider>
                </md-field>
                -->
                <div class="md-field md-theme-default md-has-value md-has-placeholder custom_timeControl">
                    <validation-provider name='entry_time' rules="required">
                        <label for="entry_time">{{ $t('label.entry_time') }} *</label>
                        <!--  //TIME PICKER 1 component -->
                        <timePicker v-model="entryTime" :creating="creating" :entryDate="entryDate"
                            @changeNextDay="changeNextDay_method" :entryTimeHour="entryTimeHour_value"
                            :entryTimeMinute="entryTimeMinute_value" @entryTimeHour_call="entryTimeHour_method"
                            @entryTimeMinute_call="entryTimeMinute_method" 
                            :disabled="loading || !canEditBooking"/>
                        <!--  //TIME PICKER 1 component -->
                        <span v-if="errors['entry_time'] && errors['entry_time'][0]" class="md-error">
                            {{ errors['entry_time'][0] }}
                        </span>
                    </validation-provider>
                </div>

            </div>





            <!-- Payment method -->
            <md-field :class="[
        { 'md-valid': errors['paymentMethod'] && !errors['paymentMethod'] && touchedFields.paymentMethod },
        { 'md-error': errors['paymentMethod'] && errors['paymentMethod'] }
    ]">
                <label class="md-select-label">{{ $t("label.payment_method") }}</label>
                <validation-provider name='payment_method' v-slot="{ errors }" mode="aggressive">
                    <md-select v-model="paymentMethod" :disabled="loading || editing"
                        :placeholder="$t('label.select_payment_method')">
                        <md-option v-if="allowAgreement" value="agreement">
                            {{ $t("payment_methods.agreement") }}
                        </md-option>
                        <md-option v-if="allowCreditCard" value="creditcard">
                            {{ $t("payment_methods.credit_card") }}
                        </md-option>
                        <md-option v-if="allowCash" value="cash">
                            {{ $t("payment_methods.cash") }}
                        </md-option>
                        <md-option v-if="allowYDAPOS" value="yda_pos">
                            {{ $t("payment_methods.yda_pos") }}
                        </md-option>
                        <md-option v-if="allowATMPOS" value="atm_pos">
                            {{ $t("payment_methods.atm_pos") }}
                        </md-option>
                        <md-option v-if="allowPaternaPOS" value="paterna_pos">
                            {{ $t("payment_methods.paterna_pos") }}
                        </md-option>
                    </md-select>
                    <span class="md-error">{{ errors[0] }}</span>
                </validation-provider>
            </md-field>
            <!-- Merchant Status -->
            <md-field>
                <label>{{ $t("label.merchant_status") }}</label>
                <md-select v-model="merchantStatus" :disabled="loading || !canEditMerchantStatus || !editing"
                    :placeholder="$t('label.merchant_status')">
                    <md-option v-for="(status, index) in merchantStatusOptions" :key="index" :value="status.value">
                        {{ $t("label.merchant_status_" + status.name) }}
                    </md-option>
                </md-select>
            </md-field>
        </div>
        <div v-if="editing" class="md-layout-item md-size-40" style="margin-left:-12px;">
            <!-- Booking Status -->
            <md-field>
                <label for="mail">{{ $t('label.booking_status') }}</label>
                <md-input v-model="bookingStatusText" :disabled=true type="text"
                    :placeholder="$t('label.booking_status')">
                </md-input>
            </md-field>
        </div>
        <!-- Main passenger separator -->
        <div>
            <div class="Group-Header">
                <span class="Luggage-information">
                    {{ $t('label.main_passenger_information') }}
                    <md-progress-spinner style="margin-left:12px;margin-top: 4px;" v-if="loading" :md-diameter="18"
                        :md-stroke="3" md-mode="indeterminate">
                    </md-progress-spinner>
                </span>
                <div class="Path"></div>
            </div>
        </div>
        <!-- Main passenger form -->
        <div class="passenger-information-form">
            <!-- Main passenger ticket -->
            <md-field :class="[]">
                <label>{{ $t("label.category") }} *</label>
                <validation-provider name='category' rules="required" v-slot="{ errors }" mode="aggressive">
                    <md-select v-model="mainPassenger.ticket" :disabled="loading || !ancillaryId || !canEditBooking || isEditingCreditCard"
                        :placeholder="$t('label.select_category') + ' *'">
                        <md-option value="">

                        </md-option>
                        <md-option v-for="(ticket, index) in tickets" :key="index" :value="ticket.ticketId">
                            {{ ticket.name }} ({{ ticket.price.toFixed(2) }} {{ CurrencySymbol }})
                        </md-option>
                    </md-select>
                    <span class="md-error">{{ errors[0] }}</span>
                </validation-provider>
            </md-field>
            <!-- Main passenger name -->
            <md-field :class="[
        { 'md-valid': errors['name_and_surname'] && !errors['name_and_surname'][0] && touchedFields.mainPassenger.nameAndSurname },
        { 'md-error': errors['name_and_surname'] && errors['name_and_surname'][0] }
    ]">
                <label for="mail">{{ $t('label.name_and_surname') }} *</label>
                <validation-provider name='name_and_surname' rules="required|nameAndSurname" v-slot="{ errors, touched }"
                    mode="aggressive">
                    <md-input v-model="mainPassenger.nameAndSurname" type="text"
                        :placeholder="$t('label.name_and_surname') + ' *'" :disabled="loading || !canEditBooking"
                        @input="mainNameToUpperCase($event)">
                    </md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                    </slide-y-down-transition>
                    <span class="md-error">{{ errors[0] }}</span>
                </validation-provider>
            </md-field>
            <!-- Main passenger passport -->
            <md-field :class="[
        { 'md-valid': errors['passport_number'] && !errors['passport_number'][0] && touchedFields.mainPassenger.passportNumber },
        { 'md-error': errors['passport_number'] && errors['passport_number'][0] }
    ]">
                <label for="mail">{{ $t('label.passenger_passport_number') }} *</label>
                <validation-provider name='passport_number' rules="required" v-slot="{ errors, touched }"
                    mode="aggressive">
                    <md-input v-model="mainPassenger.passportNumber" type="text"
                        :placeholder="$t('label.passenger_passport_number') + ' *'" :disabled="loading || !canEditBooking"
                        @input="mainPassportToUpperCase($event)">
                    </md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                    </slide-y-down-transition>
                    <span class="md-error">{{ errors[0] }}</span>
                </validation-provider>
            </md-field>
            <!-- Expedition Country -->
            <md-field :class="[
        // { 'md-valid': errors['country'] && !errors['country'] && touchedFields.mainPassenger.expeditionCountry },
        // { 'md-error': errors['country'] && errors['country'] }
    ]">
                <label>{{ $t("label.country_of_issue") }}</label>
                <validation-provider name='country' rules="" v-slot="{ errors }" mode="aggressive">
                    <md-select v-model="mainPassenger.expeditionCountry" :disabled="loading || !canEditBooking"
                        :placeholder="$t('label.select_country')">
                        <md-option value="">

                        </md-option>
                        <md-option v-for="(country, index) in Countries[$i18n.locale]" :key="index"
                            :value="country.alpha_2">
                            {{ country.name }}
                        </md-option>
                    </md-select>
                    <span class="md-error">{{ errors[0] }}</span>
                </validation-provider>
            </md-field>





            <!-- Main passenger birth date -->
            <validation-provider class="main-passenger-birthdate" name='birthdate' rules="" v-slot="{ errors }"
                mode="aggressive">

                <!--
                <md-datepicker v-model="mainPassenger.birthdate" :md-immediately="true">
                    <label>{{ $t("label.birthdate") }}</label>
                    <span class="md-error">
                        {{ errors[0] }}
                    </span>
                    <span v-if="mainPassenger.errorBirthDate" class="md-error">
                        {{ mainPassenger.errorBirthDate }}
                    </span>
                </md-datepicker>
                -->
                <div class="md-field md-theme-default md-has-value md-has-placeholder custom_dateControl">
                    <label>{{ $t("label.birthdate") }}</label>
                    <!--  //DATE PICKER -->
                    <div class="custom_dateControl_mask"></div>
                    <!-- onkeydown="return false" -->
                    <input v-model="mainPassenger.birthdate" type="date" :disabled="loading || !canEditBooking">
                    <!--  //DATE PICKER -->
                    <span class="md-error">
                        {{ errors[0] }}
                    </span>
                    <span v-if="mainPassenger.errorBirthDate" class="md-error">
                        {{ mainPassenger.errorBirthDate }}
                    </span>
                </div>

            </validation-provider>





        </div>

        <!-- Other passengers separator -->
        <div class="Group-Header Other-Passengers" :style="{ paddingTop: errors.length ? '0px' : '80px' }">
            <span class="Luggage-information">
                {{ $t('label.other_passengers_information') }}
                <md-progress-spinner style="margin-left:12px;margin-top: 4px;" v-if="loading" :md-diameter="18"
                    :md-stroke="3" md-mode="indeterminate">
                </md-progress-spinner>
            </span>
            <button v-if="ancillaryId && canEditBooking && !isEditingCreditCard" @click.prevent="addPassenger()" class="add-button">
                <md-icon class="icon-add">add</md-icon>
            </button>
            <div class="Path"></div>
        </div>
        <!-- Other Passengers form -->
        <div v-for="(passenger, index) in otherPassengers" :key="index" class="passenger-information-form">
            <!-- Other passenger ticket -->
            <md-field :class="[]">
                <label>{{ $t("label.category") }} *</label>
                <validation-provider name='category' rules="required" v-slot="{ errors }" mode="aggressive">
                    <md-select v-model="otherPassengers[index].ticket" :disabled="loading || !ancillaryId || !canEditBooking || isEditingCreditCard"
                        :placeholder="$t('label.select_category') + ' *'">
                        <md-option value="">
                        </md-option>
                        <md-option v-for="(ticket, index) in tickets" :key="index" :value="ticket.ticketId">
                            {{ ticket.name }} ({{ ticket.price.toFixed(2) }} {{ CurrencySymbol }})
                        </md-option>
                    </md-select>
                    <span class="md-error">{{ errors[0] }}</span>
                </validation-provider>
            </md-field>
            <!-- Other passenger name -->
            <md-field :class="[
        { 'md-valid': errors['name_and_surname'] && !errors['name_and_surname'][0] && touchedFields.mainPassenger.nameAndSurname },
        { 'md-error': errors['name_and_surname'] && errors['name_and_surname'][0] }
    ]">
                <label for="mail">{{ $t('label.name_and_surname') }} *</label>
                <validation-provider name='name_and_surname' rules="required|nameAndSurname" v-slot="{ errors, touched }"
                    mode="aggressive">
                    <md-input v-model="otherPassengers[index].nameAndSurname" type="text"
                        :placeholder="$t('label.name_and_surname') + ' *'" :disabled="loading || !canEditBooking"
                        @input="otherNameToUpperCase($event, index)">
                    </md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                    </slide-y-down-transition>
                    <span class="md-error">{{ errors[0] }}</span>
                </validation-provider>
            </md-field>
            <!-- Other passenger passport -->
            <md-field :class="[
        { 'md-valid': errors['passport_number'] && !errors['passport_number'][0] && touchedFields.mainPassenger.passportNumber },
        { 'md-error': errors['passport_number'] && errors['passport_number'][0] }
    ]">
                <label for="mail">{{ $t('label.passenger_passport_number') }}</label>
                <validation-provider name='passport_number' rules="" v-slot="{ errors, touched }" mode="aggressive">
                    <md-input v-model="otherPassengers[index].passportNumber" type="text"
                        :placeholder="$t('label.passenger_passport_number')" :disabled="loading || !canEditBooking"
                        @input="otherPassportToUpperCase($event, index)">
                    </md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                    </slide-y-down-transition>
                    <span class="md-error">{{ errors[0] }}</span>
                </validation-provider>
            </md-field>
            <!-- Expedition Country -->
            <md-field :class="[]">
                <label>{{ $t("label.country_of_issue") }}</label>
                <validation-provider name='country' rules="" v-slot="{ errors }" mode="aggressive">
                    <md-select v-model="otherPassengers[index].expeditionCountry" :disabled="loading || !canEditBooking"
                        :placeholder="$t('label.select_country')">
                        <md-option value="">

                        </md-option>
                        <md-option v-for="(country, index) in Countries[$i18n.locale]" :key="index"
                            :value="country.alpha_2">
                            {{ country.name }}
                        </md-option>
                    </md-select>
                    <span class="md-error">{{ errors[0] }}</span>
                </validation-provider>
            </md-field>





            <!-- Other passenger birth date & delete button -->
            <div class="passenger-birthday">
                <validation-provider name='other_birthdate' rules="" v-slot="{ errors }" mode="aggressive">

                    <!--
                    <md-datepicker v-model="otherPassengers[index].birthdate" :md-immediately="true">
                        <label>{{ $t("label.birthdate") }}</label>
                        <span class="md-error">
                            {{ errors[0] }}
                        </span>
                        <span v-if="otherPassengers[index].errorBirthDate" class="md-error">
                            {{ otherPassengers[index].errorBirthDate }}
                        </span>
                    </md-datepicker>
                    -->
                    <div class="md-field md-theme-default md-has-value md-has-placeholder custom_dateControl">
                        <label>{{ $t("label.birthdate") }}</label>
                        <!--  //DATE PICKER -->
                        <div class="custom_dateControl_mask"></div>
                        <!-- onkeydown="return false" -->
                        <input v-model="otherPassengers[index].birthdate" type="date" :disabled="loading || !canEditBooking">
                        <!--  //DATE PICKER -->
                        <span class="md-error">
                            {{ errors[0] }}
                        </span>
                        <span v-if="otherPassengers[index].errorBirthDate" class="md-error">
                            {{ otherPassengers[index].errorBirthDate }}
                        </span>
                    </div>

                </validation-provider>
                <button v-if="canEditBooking && !isEditingCreditCard" @click.prevent="deletePassenger(index)" class="delete-passenger">
                    <md-icon>delete</md-icon>
                </button>
            </div>





        </div>
        <div v-if="errorTickets">
            <span class="md-error">{{ errorTickets }}</span>
        </div>

        <!-- Flight information separator -->
        <div>
            <div class="Group-Header" :style="{ paddingTop: otherPassengers.length ? '20px' : '40px' }">
                <span class="Luggage-information">
                    {{ $t('label.flight_information') }}
                    <md-progress-spinner style="margin-left:12px;margin-top: 4px;" v-if="loading" :md-diameter="18"
                        :md-stroke="3" md-mode="indeterminate">
                    </md-progress-spinner>
                </span>
                <div class="Path"></div>
            </div>
        </div>
        <!--Flight form -->
        <div class="flight-information-form">
            <!-- Flight direction -->
            <md-field :class="[]">
                <label>{{ $t("label.flight_direction") }}</label>
                <validation-provider name='flight_direction' rules="required" v-slot="{ errors }" mode="aggressive">
                    <md-select v-model="flightInformation.flightDirection" :disabled="loading || disableFlightDirection || !canEditBooking"
                        :placeholder="$t('label.select_flight_direction') + ' *'">
                        <md-option value="">
                        </md-option>
                        <md-option value="A">
                            {{ $t("arrival") }}
                        </md-option>
                        <md-option value="D">
                            {{ $t("departure") }}
                        </md-option>
                    </md-select>
                    <span class="md-error">{{ errors[0] }}</span>
                </validation-provider>
            </md-field>
            <!-- Airline -->
            <md-field :class="[]">
                <label>{{ $t("label.airline") }}</label>
                <validation-provider name='airline' rules="required" v-slot="{ errors }" mode="aggressive">
                    <md-select v-model="flightInformation.airline" :disabled="loading || !canEditBooking"
                        @md-selected="completeFlightNumber()" :placeholder="$t('label.select_airline') + ' *'">
                        <md-option value="">
                        </md-option>
                        <md-option v-for="(airline, index) in airlines" :key="index" :value="airline.IATACode">
                            {{ airline.name }}
                        </md-option>

                    </md-select>
                    <span class="md-error">{{ errors[0] }}</span>
                </validation-provider>
            </md-field>
            <!-- Destination -->
            <md-field :class="[]">
                <label>{{ $t("label.destination") }}</label>
                <validation-provider name='destination' rules="required" v-slot="{ errors }" mode="aggressive">
                    <md-select v-model="flightInformation.destination" :disabled="loading || !canEditBooking"
                        :placeholder="$t('label.select_destination') + ' *'">
                        <md-option value="">
                        </md-option>
                        <md-option v-for="(destination, index) in destinations" :key="index"
                            :value="destination.destinationIATACode">
                            {{ destination.destinationName }}
                        </md-option>

                    </md-select>
                    <span class="md-error">{{ errors[0] }}</span>
                </validation-provider>
            </md-field>
            <!-- Flight number -->
            <md-field :class="[
        { 'md-valid': errors['flight_number'] && !errors['flight_number'][0] && touchedFields.flightInformation.flightNumber },
        { 'md-error': errors['flight_number'] && errors['flight_number'][0] }
    ]">
                <label for="flight_number">{{ $t('label.flight_number') }} *</label>
                <validation-provider name='flight_number'
                    :rules="{ required: true, regex: /\b([A-Z]{2,3}|[A-Z]\d|\d[A-Z])\s?\d{3,4}\b/ }"
                    v-slot="{ errors, touched }" mode="aggressive">
                    <md-input v-model="flightInformation.flightNumber" type="text"
                        :placeholder="$t('label.flight_number') + ' *'" :disabled="loading || !canEditBooking">
                    </md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                    </slide-y-down-transition>
                    <span class="md-error">{{ errors[0] }}</span>
                </validation-provider>
            </md-field>





            <!-- FlightDate -->
            <div class="entry-date-time">

                <!--
                <md-datepicker v-model="flightInformation.flightDate" :md-immediately="true">
                    <label>{{ $t("label.flight_date") }} *</label>
                    <span class="md-error">
                        {{ errorFlightDateTime }}
                    </span>
                </md-datepicker>
                -->
                <div class="md-field md-theme-default md-has-value md-has-placeholder custom_dateControl">
                    <label>{{ $t("label.flight_date") }} *</label>
                    <!--  //DATE PICKER -->
                    <div class="custom_dateControl_mask"></div>
                    <!-- onkeydown="return false" -->
                    <input v-model="flightInformation.flightDate" id="flightDate" name="flightDate" type="date"
                        :min="minDate_flightDate" :max="maxDate_flightDate" :disabled="loading || !canEditBooking">
                    <!--  //DATE PICKER -->
                    <span class="md-error">
                        {{ errorFlightDateTime }}
                    </span>
                </div>

            </div>





            <!-- FlightTime -->
            <!--
            <md-field class="hour-input" :class="[
        { 'md-valid': errors['flight_time'] && !errors['flight_time'][0] && touchedFields.flightInformation.flightTime },
        { 'md-error': errors['flight_time'] && errors['flight_time'][0] }
    ]">
                <label for="flight_time">{{ $t('label.flight_time') }} *</label>
                <validation-provider name='flight_time'
                    :rules="{ required: true, regex: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/ }" mode="aggressive">
                    <md-input v-model="flightInformation.flightTime" type="text" :placeholder="'HH:MM' + ' *'"
                        :disabled="loading" @keydown="checkHourFormat">
                    </md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                    </slide-y-down-transition>
                    <span v-if="errors['flight_time'] && errors['flight_time'][0]" class="md-error">
                        {{ errors['flight_time'][0] }}
                    </span>
                </validation-provider>
            </md-field>
            -->
            <div class="md-field md-theme-default md-has-value md-has-placeholder custom_timeControl">
                <validation-provider name='flight_time' rules="required">
                    <label for="flight_time">{{ $t('label.flight_time') }} *</label>
                    <!--  //TIME PICKER 2 component -->
                    <timePicker v-model="flightInformation.flightTime" :creating="creating"
                        :flightDate="flightInformation.flightDate" :entryDate="entryDate"
                        :flightDirection="flightInformation.flightDirection" @changeNextDay="changeNextDay_method"
                        :flightTimeHour="flightTimeHour_value" :flightTimeMinute="flightTimeMinute_value"
                        :entryTimeHour="entryTimeHour_value" :entryTimeMinute="entryTimeMinute_value" 
                        :disabled="loading || !canEditBooking"/>
                    <!--  //TIME PICKER 2 component -->
                    <span v-if="errors['flight_time'] && errors['flight_time'][0]" class="md-error">
                        {{ errors['flight_time'][0] }}
                    </span>
                </validation-provider>
            </div>





        </div>
        <!-- FlightDateTime -->
        <div class="flight-date-time">

        </div>

        <!-- Contact information separator -->
        <div>
            <div class="Group-Header" :style="{ paddingTop: errors.length ? '20px' : '40px' }">
                <span class="Luggage-information">
                    {{ $t('label.contact_details_and_comments') }}
                    <md-progress-spinner style="margin-left:12px;margin-top: 4px;" v-if="loading" :md-diameter="18"
                        :md-stroke="3" md-mode="indeterminate">
                    </md-progress-spinner>
                </span>
                <div class="Path"></div>
            </div>
        </div>
        <!-- Contact form -->
        <div class="contact-information-form">
            <!-- Passenger phone number -->
            <md-field :class="[
        { 'md-valid': errors['passenger_phone'] && !errors['passenger_phone'][0] && touchedFields.contactInformation.passengerPhone },
        { 'md-error': errors['passenger_phone'] && errors['passenger_phone'][0] }
    ]">
                <label for="mail">{{ $t('label.passenger_phone') }}</label>
                <validation-provider name='passenger_phone' rules="" v-slot="{ errors, touched }" mode="aggressive">
                    <md-input v-model="contactInformation.passengerPhone" type="text"
                        :placeholder="$t('label.passenger_phone')" :disabled="loading || !canEditBooking">
                    </md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                    </slide-y-down-transition>
                    <span class="md-error">{{ errors[0] }}</span>
                </validation-provider>
            </md-field>
            <!-- Passenger email -->
            <md-field :class="[
        { 'md-valid': errors['email'] && !errors['email'][0] && touchedFields.contactInformation.email },
        { 'md-error': errors['email'] && errors['email'][0] }
    ]">
                <label for="mail">{{ $t('label.passenger_email') }}</label>
                <validation-provider name='email' rules="email" v-slot="{ errors, touched }" mode="aggressive">
                    <md-input v-model="contactInformation.email" type="text" :placeholder="$t('label.passenger_email')"
                        :disabled="loading || !canEditBooking">
                    </md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                    </slide-y-down-transition>
                    <span class="md-error">{{ errors[0] }}</span>
                </validation-provider>
            </md-field>
        </div>
        <div class="contact-information-form">
            <!-- Driver phone number -->
            <md-field :class="[
        { 'md-valid': errors['driver_phone'] && !errors['driver_phone'][0] && touchedFields.contactInformation.driverPhone },
        { 'md-error': errors['driver_phone'] && errors['driver_phone'][0] }
    ]">
                <label for="mail">{{ $t('label.driver_phone') }}</label>
                <validation-provider name='driver_phone' rules="" v-slot="{ errors, touched }" mode="aggressive">
                    <md-input v-model="contactInformation.driverPhone" type="text"
                        :placeholder="$t('label.driver_phone')" :disabled="loading || !canEditBooking">
                    </md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                    </slide-y-down-transition>
                    <span class="md-error">{{ errors[0] }}</span>
                </validation-provider>
            </md-field>
            <!-- Driver name -->
            <md-field :class="[
        { 'md-valid': errors['driver_name'] && !errors['driver_name'][0] && touchedFields.contactInformation.driverName },
        { 'md-error': errors['driver_name'] && errors['driver_name'][0] }
    ]">
                <label for="mail">{{ $t('label.driver_name') }}</label>
                <validation-provider name='driver_name' rules="" v-slot="{ errors, touched }" mode="aggressive">
                    <md-input v-model="contactInformation.driverName" type="text" :placeholder="$t('label.driver_name')"
                        :disabled="loading || !canEditBooking">
                    </md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                    </slide-y-down-transition>
                    <span class="md-error">{{ errors[0] }}</span>
                </validation-provider>
            </md-field>
            <!-- Driver licence plate -->
            <md-field :class="[
        { 'md-valid': errors['driver_licence_plate'] && !errors['driver_licence_plate'][0] && touchedFields.contactInformation.driverLicencePlate },
        { 'md-error': errors['driver_licence_plate'] && errors['driver_licence_plate'][0] }
    ]">
                <label for="mail">{{ $t('label.driver_licence_plate') }}</label>
                <validation-provider name='driver_name' rules="" v-slot="{ errors, touched }" mode="aggressive">
                    <md-input v-model="contactInformation.driverLicencePlate" type="text"
                        :placeholder="$t('label.driver_licence_plate')" :disabled="loading || !canEditBooking">
                    </md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                    </slide-y-down-transition>
                    <span class="md-error">{{ errors[0] }}</span>
                </validation-provider>
            </md-field>
        </div>
        <div class="">
            <!-- Contact Comments -->
            <md-field :class="[
        { 'md-valid': errors['comments'] && !errors['comments'][0] && touchedFields.contactInformation.comments },
        { 'md-error': errors['comments'] && errors['comments'][0] }
    ]">
                <label for="mail">{{ $t('label.comments') }}</label>
                <validation-provider name='comments' rules="" v-slot="{ errors, touched }" mode="aggressive">
                    <md-input v-model="contactInformation.comments" type="text" :placeholder="$t('label.comments')"
                        :disabled="loading || !canEditBooking">
                    </md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                    </slide-y-down-transition>
                    <span class="md-error">{{ errors[0] }}</span>
                </validation-provider>
            </md-field>
        </div>

        <!-- Payment information & summary -->
        <div class="grid">
            <!-- Payment information -->
            <div>
                <div v-if="paymentMethod == 'creditcard'">
                    <!-- Payment separator -->
                    <div>
                        <div class="Group-Header">
                            <span class="Luggage-information">
                                {{ $t('label.payment_information') }}
                                <md-progress-spinner style="margin-left:12px;margin-top: 4px;" v-if="loading"
                                    :md-diameter="18" :md-stroke="3" md-mode="indeterminate">
                                </md-progress-spinner>
                            </span>
                            <div class="Path"></div>
                        </div>
                    </div>
                    <div class="md-layout" style="margin-left: -12px;" v-if="editing">
                        <div class="md-layout-item md-size-40">
                            <!-- paymentCreditCardType -->
                            <md-field>
                                <label>{{ $t("label.booking_paymentCreditCardType") }}</label>
                                <md-input :disabled="true"
                                    v-model="item.paymentInformation.paymentCreditCardType"></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-10"></div>
                        <div class="md-layout-item md-size-40">
                            <!-- paymentCreditCardMasked -->
                            <md-field>
                                <label>{{ $t("label.booking_paymentCreditCardMasked") }}</label>
                                <md-input :disabled="true"
                                    v-model="item.paymentInformation.paymentCreditCardMasked"></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-40">
                            <!-- transaction -->
                            <md-field>
                                <label>{{ $t("label.booking_transaction") }}</label>
                                <md-input :disabled="true" v-model="item.paymentInformation.transaction"></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-10"></div>
                        <div class="md-layout-item md-size-40">
                            <!-- creditCardExpirationDate -->
                            <md-field>
                                <label>{{ $t("label.booking_transactionDate") }}</label>
                                <md-input :disabled="true" v-model="item.paymentInformation.transactionDate"></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-40">
                            <!-- creditCardHolder -->
                            <md-field>
                                <label>{{ $t("label.booking_creditCardHolder") }}</label>
                                <md-input :disabled="true"
                                    v-model="item.paymentInformation.creditCardHolder"></md-input>
                            </md-field>
                        </div>

                    </div>


                    <!-- Credit card number -->
                    <md-field v-if="!editing" :class="[
        { 'md-valid': errors['credit_card_number'] && !errors['credit_card_number'][0] && touchedFields.paymentInformation.creditCardNumber },
        { 'md-error': errors['credit_card_number'] && errors['credit_card_number'][0] }
    ]">
                        <label for="mail">{{ $t('label.credit_card_number') }} *</label>
                        <validation-provider name='credit_card_number' rules="required|min:15|max:20" v-slot="{ errors, touched }"
                            mode="aggressive">
                            <md-input v-model="paymentInformation.creditCardNumber"
                            autocomplete="off" @keypress="onlyNumbers($event)"
                            type="tel" maxlength="20"
                                :placeholder="$t('label.credit_card_number') + ' *'" :disabled="loading">
                            </md-input>
                            <slide-y-down-transition>
                                <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                            </slide-y-down-transition>
                            <span class="md-error">{{ errors[0] }}</span>
                        </validation-provider>
                    </md-field>
                    <!-- Credit card holder's name -->
                    <md-field v-if="!editing" :class="[
        { 'md-valid': errors['credit_card_holder_name'] && !errors['credit_card_holder_name'][0] && touchedFields.paymentInformation.cardHolderName },
        { 'md-error': errors['credit_card_holder_name'] && errors['credit_card_holder_name'][0] }
    ]">
                        <label for="mail">{{ $t('label.credit_card_holder_name') }} *</label>
                        <validation-provider name='credit_card_holder_name' rules="required"
                            v-slot="{ errors, touched }" mode="aggressive">
                            <md-input v-model="paymentInformation.cardHolderName" type="text"
                                :placeholder="$t('label.credit_card_holder_name') + ' *'" :disabled="loading">
                            </md-input>
                            <slide-y-down-transition>
                                <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                            </slide-y-down-transition>
                            <span class="md-error">{{ errors[0] }}</span>
                        </validation-provider>
                    </md-field>
                    <div class="grid-2" v-if="!editing">
                        <!--Expiration month and year -->

                        <md-field>
                            <md-select v-model="paymentInformation.expirationMonth" :disabled="loading"
                                :placeholder="$t('label.expiration_month')">
                                <md-option v-for="(month, index) in months" :key="index" :value="month.value">
                                    {{ month.label }}
                                </md-option>
                            </md-select>
                            <span class="md-error">
                                {{ errorMonthMessage }}
                            </span>
                        </md-field>

                        <md-field>
                            <md-select v-model="paymentInformation.expirationYear" :disabled="loading"
                                :placeholder="$t('label.expiration_year')">
                                <md-option v-for="(year, index) in years" :key="index" :value="year.value">
                                    {{ year.label }}
                                </md-option>
                            </md-select>
                            <span class="md-error">
                                {{ errorYearMessage }}
                            </span>
                        </md-field>
                        <!--CVV -->
                        <md-field :class="[
        { 'md-valid': errors['cvv'] && !errors['cvv'][0] && touchedFields.paymentInformation.CVV },
        { 'md-error': errors['cvv'] && errors['cvv'][0] }
    ]">
                            <label for="mail">{{ $t('label.cvv') }} *</label>
                            <validation-provider name='cvv' rules="required" v-slot="{ errors, touched }"
                                mode="aggressive">
                                <md-input v-model="paymentInformation.CVV"
                                type="tel" maxlength="4"
                                autocomplete="off" @keypress="onlyNumbers($event)"
                                    :placeholder="$t('label.cvv') + ' *'" :disabled="loading">
                                </md-input>
                                <slide-y-down-transition>
                                    <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                </slide-y-down-transition>
                                <span class="md-error">{{ errors[0] }}</span>
                            </validation-provider>
                        </md-field>
                    </div>
                </div>
            </div>
            <!-- Summary & booking button -->
            <div class="booking-summary">
                <div class="total-amount">
                    {{ $t("total") }}: {{ totalAmount }} {{ CurrencySymbol }}
                </div>
                <div class="book-buttons">
                    <md-progress-spinner v-if="loading" :md-diameter="30" :md-stroke="3" md-mode="indeterminate">
                    </md-progress-spinner>
                    <!-- Save button -->
                    <md-button v-if="creating && paymentMethod != 'creditcard'" class="md-primary md-round btn-medium"
                        @click="book()" :disabled="loading || !ancillaryId">
                        {{ $t("book") }}
                    </md-button>
                    <md-button v-if="creating && paymentMethod == 'creditcard'" class="md-primary md-round btn-medium"
                        @click="bookCreditCard()" :disabled="loading || !ancillaryId">
                        {{ $t("book") }}
                    </md-button>
                    <md-button v-if="editing && canEditBooking" class="md-primary md-round btn-medium" @click="edit()"
                        :disabled="loading || (merchantStatus !== null && merchantStatus >= 2)">
                        {{ $t("save") }}
                    </md-button>
                    <md-button
                        v-if="editing && paymentMethod === 'creditcard' && item.bookingStatus === 0 && (merchantStatus !== null && merchantStatus === 1)"
                        class="md-danger md-round" @click="confirmRefund()">
                        {{ $t("cancel_and_refund") }}
                    </md-button>
                    <md-button
                        v-if="editing && paymentMethod != 'creditcard' && (merchantStatus !== null && merchantStatus === 0)"
                        class="md-danger md-round" @click="confirmUpdate()">
                        {{ $t("cancel") }}
                    </md-button>
                </div>

                <div class="booking-errors" v-if="!valid">
                    <span class="md-error">{{ $t("error.booking_data") }}</span>
                </div>
                <div class="booking-info" v-if="postingBooking">
                    <span class="booking-info-text">{{ $t("label.booking_posting") }}</span>
                    <md-progress-spinner class="booking-info-spinner" v-if="postingBooking" :md-diameter="20"
                        :md-stroke="1" md-mode="indeterminate">
                    </md-progress-spinner>
                </div>
            </div>

            <md-modal v-if="showConfirm" @close="showConfirm = false">
                <h3 slot="header">
                    {{ $t("confirm") }}
                </h3>
                <div slot="body">
                    {{ $t("confirm_messages.update") }}
                </div>
                <div slot="footer" class="modal-footer">
                    <md-button @click="showConfirm = false" class="md-danger md-round">
                        {{ $t("cancel") }}
                    </md-button>
                    <md-button @click="updateItem()" class="md-primary md-round">
                        {{ $t("Ok") }}
                    </md-button>
                </div>
            </md-modal>

            <md-modal v-if="showCancelConfirm" @close="showCancelConfirm = false">
                <h3 slot="header">
                    {{ $t("label.cancel_booking_header") }}
                </h3>
                <div slot="body">
                    {{ $t("label.cancel_booking_body") }}
                </div>
                <div slot="footer" class="modal-footer">
                    <md-button @click="showCancelConfirm = false, item.bookingStatus = originalBookingStatus"
                        class="md-danger md-round">
                        {{ $t("cancel") }}
                    </md-button>
                    <md-button @click="cancelItem()" class="md-primary md-round">
                        {{ $t("label.cancel_booking_ok") }}
                    </md-button>
                </div>
            </md-modal>

        </div>

        <div style="position: absolute;" v-show="showBankPopUp">
            <AssecoView ref="refAsseco" @paymentFinishedOK="paymentFinished(true)"
                @paymentFinishedKO="paymentFinished(false)" @paymentFinishedUndefined="paymentFinished(null)" />
        </div>

    </ValidationObserver>
</template>

<script>
import { DateTime } from 'luxon';
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import { getAppAncillary, getAncillaries } from "@/services/ancillary";
import { createBookingAncillary, getAppBooking, updateBookingB2B, updateBooking,sendMailBooking } from "@/services/booking";
import { getClient } from "@/services/client";
import { getAirlines } from "@/services/airline";
import { getAirports } from "@/services/airports";
import { CURRENCY_SYMBOLS, DEFAULT_CURRENCY, MERCHANT_STATUS, Roles } from "@/config";
import { COUNTRIES } from "@/locales/countries";
import authMixin from "@/mixins/auth";
import AssecoView from '@/components/Bank/AssecoView'
import { Auth } from 'aws-amplify'

import timePicker from "@/components/Inputs/timePicker";

export default {
    mixins: [authMixin],
    components: {
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
        AssecoView,
        timePicker,
    },

    data() {
        return {
            bookingId:null,
            //TIME PICKER
            entryTimeHour_value: null,
            entryTimeMinute_value: null,
            flightTimeHour_value: null,
            flightTimeMinute_value: null,
            //TIME PICKER
            item: null,
            ItemIdToRefund: null,
            showCancelConfirm: false,
            showConfirm: false,
            ItemIdToUpdate: null,
            role: null,
            valid: true,
            showBankPopUp: false,
            postingBooking: false,
            loading: false,
            creating: false,
            editing: false,
            ancillaryId: null,
            entryDate: null,
            entryTime: "",
            paymentMethod: 'agreement',
            merchantStatus: 0,
            mainPassenger: {
                ticket: "",
                name: "",
                passportNumber: "",
                birthdate: null,
                expeditionCountry: "",
                bookingId: null,
                errorBirthDate: "",
            },
            paymentInformation: {
                creditCardNumber: "",
                cardHolderName: "",
                expirationMonth: "",
                expirationYear: "",
                CVV: "",
            },
            flightInformation: {
                flightDirection: "",
                airline: "",
                destination: "",
                flightNumber: "",
                flightDate: null,
                flightTime: "",
            },
            otherPassengers: [],
            contactInformation: {
                passengerPhone: "",
                email: "",
                driverPhone: "",
                driverName: "",
                driverLicencePlate: "",
                comments: "",
            },
            touchedFields: {
                ancillaryId: false,
                entryTime: false,
                paymentMethod: false,
                mainPassenger: {
                    ticket: false,
                    name: false,
                    passportNumber: false,
                    birthdate: false,
                    expeditionCountry: false,
                },
                paymentInformation: {
                    creditCardNumber: false,
                    cardHolderName: false,
                    expirationMonth: false,
                    expirationYear: false,
                    CVV: false,
                },
                flightInformation: {
                    flightDirection: false,
                    airline: false,
                    destination: false,
                    flightNumber: false,
                    flightTime: false,
                },
                contactInformation: {
                    passengerPhone: false,
                    email: false,
                    driverPhone: false,
                    driverName: false,
                    driverLicencePlate: false,
                    comments: false,
                },
            },
            ancillaries: [],
            selectedAncillary: {},
            CurrencySymbol: CURRENCY_SYMBOLS[DEFAULT_CURRENCY],
            tickets: [],
            airlines: [],
            destinations: [],
            idBooking: null,    //autoincrement booking ID
            allowAgreement: true,
            allowCreditCard: false,
            allowCash: false,
            allowATMPOS: false,
            allowYDAPOS: false,
            allowPaternaPOS: false,
            merchantStatusOptions: MERCHANT_STATUS,
            Roles: Roles,
            Countries: COUNTRIES,
            errorFlightDateTime: "",
            errorEntryDateTime: "",
            errorTickets: "",
            disableFlightDirection: false,
            years: [],
            months: [],
            bookingStatusText: '',
            errorMonthMessage: "",
            errorYearMessage: "",
            errorEntryDateMessage: "",
        }
    },
    created: async function () {
        this.loading = true;
        const routeName = this.$route.name.substring(3);
        if (routeName == "booking_create") {
            this.creating = true;
        } else if (routeName == "booking_edit_b2b") {
            this.editing = true;
            await this.fetchBooking();
        }
        await this.fetchAncillaries();
        await this.fetchAirlines();
        await this.fetchAirports();
        await this.fetchUserClient();
        this.populateYearsAndMonths();
        this.loading = false;

    },
    mounted: async function () {
        //0014290: [B2B] Nueva reserva - Formato de fecha
        this.$material.locale.dateFormat = "dd/MM/yyyy";
        const user = await Auth.currentAuthenticatedUser();
        this.role = user.attributes['custom:role_id'];
        window.addEventListener('message', this.handleMessage);
    },
    beforeDestroy: async function () {
        window.removeEventListener('message', this.handleMessage);

    },


    watch: {
        async ancillaryId() {
            this.loading = true;
            const response = await getAppAncillary(this.ancillaryId);
            if (response.status == '200') {
                this.selectedAncillary = response;
                this.CurrencySymbol = CURRENCY_SYMBOLS[this.selectedAncillary.currencyNumericCode];
                this.tickets = this.selectedAncillary.tickets;
                this.tickets.forEach(ticket => {
                    ticket.quantity = 0,
                        ticket.unitPrice = {
                            value: ticket.price,
                            currency: this.selectedAncillary.currencyNumericCode,
                        };
                });
                this.setFlightDirection(this.selectedAncillary.dateMode);
            }
            this.loading = false;
        },
        paymentMethod(newValue, oldValue) {
            if (newValue != oldValue && !this.editing) {
                if (newValue == 'agreement' || newValue == 'creditcard') {
                    this.merchantStatus = 0;
                } else {
                    this.merchantStatus = 1;
                }
            }
        },
        'flightInformation.flightDate'(newValue, oldValue) {
            if (newValue != oldValue) {
                if (newValue != null) {
                    this.errorFlightDateTime = "";
                }
            }
        },
        'paymentInformation.expirationMonth'(newValue, oldValue) {
            if (newValue != oldValue) {
                if (newValue != null) {
                    this.errorMonthMessage = "";
                }
            }
        },
        'paymentInformation.expirationYear'(newValue, oldValue) {
            if (newValue != oldValue) {
                if (newValue != null) {
                    this.errorYearMessage = "";
                }
            }
        },
        'flightInformation.flightNumber'(newFlightNumber, oldFlightNumber) {
            const airlineCode = this.flightInformation.airline;
            if (newFlightNumber != oldFlightNumber && airlineCode && !newFlightNumber.startsWith(airlineCode)) {
                this.flightInformation.flightNumber = airlineCode;
            }
        },
        //DATE PICKER
        entryDate(newValue, oldValue) {
            if (newValue != oldValue) {
                if (newValue != null) {
                    this.errorEntryDateMessage = "";
                }
            }
            //console.log(newValue);
            if (newValue && this.creating) {
                if (this.flightInformation.flightDirection == 'D') {
                    this.flightInformation.flightDate = this.entryDate;
                }
                else if (this.flightInformation.flightDirection == 'A') {
                    this.flightInformation.flightDate = this.entryDate;
                }
                //else {
                //}
            }
        },
        'flightInformation.flightDirection'(newValue) {
            if (newValue && this.creating) {
                if (newValue == 'D') {
                    //console.log(newValue);
                    //console.log(this.flightInformation.flightDate);
                    this.flightInformation.flightDate = this.entryDate;
                }
                else if (newValue == 'A') {
                    this.flightInformation.flightDate = this.entryDate;
                }
                //else {
                //}
            }
        }
        //DATE PICKER
    },

    computed: {
        totalAmount: function () {
            let amount = 0;
            if (this.tickets.length) {
                if (this.mainPassenger.ticket) {
                    let ticket = this.tickets.find(ticket => ticket.ticketId == this.mainPassenger.ticket);
                    amount += ticket.price;
                }
                this.otherPassengers.forEach(passenger => {
                    if (passenger.ticket) {
                        let ticket = this.tickets.find(ticket => ticket.ticketId == passenger.ticket);
                        amount += ticket.price;
                    }
                });
            }
            return amount.toFixed(2);
        },
        canEditMerchantStatus: function () {
            const currentUserRole = this.currentUser.role;
            return (currentUserRole == Roles.ADMIN || currentUserRole == Roles.ECOMM_MG);
        },
        canEditBooking: function () {
            const booking = this.item;
            let allowEdit = true;
            
            if (this.creating) {
                allowEdit = true;
                return allowEdit;
            }

            if (booking && 
                (booking.bookingStatus == 1 || booking.bookingStatus == 2 || booking.bookingStatus == 3 || booking.bookingStatus == 5)
            ) {
                allowEdit = false;
                return allowEdit
            }

            // [0014586] Check if editing booking is past
            const isBookingPast = !this.validateEntryDateTime();
            if (isBookingPast) {
                allowEdit = false;
                return allowEdit;
            }
            
            return allowEdit;
        },
        isEditingCreditCard() {
            if (this.editing) {
                const paymentMethod = this.paymentInformation.paymentMethod.toLowerCase();
                return (paymentMethod == "creditcard");
            } else {
                return false;
            }
        },
        //DATE PICKER
        currentDate: function () {
            //function currentDate(dayMore = false) {
            const today = new Date();

            let yyyy = today.getFullYear();
            let mm = today.getMonth() + 1; // Months start at 0!
            let dd = today.getDate();
            //console.info(yyyy,mm,dd);
            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;
            let currentDate = yyyy + '-' + mm + '-' + dd;
            //console.log(currentDate);
            return currentDate;
        },
        minDate_flightDate: function () {
            //const entryDate = new Date(eCommerceStore.context.entryDate);
            const entryDate = new Date(this.entryDate);
            const today = new Date();

            let yyyy = "";
            let mm = "";
            let dd = "";

            //console.log(this.flightInformation.flightDirection);

            if (this.flightInformation.flightDirection == "D") {
                entryDate.setDate(entryDate.getDate()); // Fecha del booking
                yyyy = entryDate.getFullYear();
                mm = entryDate.getMonth() + 1; // Months start at 0!
                dd = entryDate.getDate();
            }
            else if (this.flightInformation.flightDirection == "A") {
                //today.setDate(today.getDate()); // Fecha actual
                //yyyy = today.getFullYear();
                //mm = today.getMonth() + 1; // Months start at 0!
                //dd = today.getDate();

                //console.log(this.currentDate + " / " + this.entryDate);
                if (this.currentDate == this.entryDate) {
                    entryDate.setDate(entryDate.getDate());
                } else {
                    entryDate.setDate(entryDate.getDate() - 1); // Un día anterior a la fecha del booking
                }

                yyyy = entryDate.getFullYear();
                mm = entryDate.getMonth() + 1; // Months start at 0!
                dd = entryDate.getDate();
            }
            else {
                today.setDate(today.getDate()); // Fecha actual
                yyyy = today.getFullYear();
                mm = today.getMonth() + 1; // Months start at 0!
                dd = today.getDate();
            }

            //console.info(yyyy,mm,dd);
            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;
            let minDate = yyyy + '-' + mm + '-' + dd;

            //console.log(minDate);
            return minDate;
        },
        maxDate_entryDate: function () {
            const today = new Date();
            let yyyy = today.getFullYear();
            let mm = today.getMonth() + 1; // Months start at 0!
            let dd = today.getDate();
            //console.info(yyyy,mm,dd);
            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;
            let maxDate = (yyyy + 10) + '-' + mm + '-' + dd;
            return maxDate;
        },
        maxDate_flightDate: function () {
            //const date = new Date();
            //const date = new Date(2024, 0, 24); // Months start at 0!
            const entryDate = new Date(this.entryDate);
            //const today = new Date();

            let yyyy = "";
            let mm = "";
            let dd = "";

            if (this.flightInformation.flightDirection == "D") {
                //entryDate.setDate(entryDate.getDate() + 180);
                //yyyy = entryDate.getFullYear();
                //mm = entryDate.getMonth() + 1; // Months start at 0!
                //dd = entryDate.getDate();
                entryDate.setDate(entryDate.getDate() + 1); // Un día después de la fecha del booking
                yyyy = entryDate.getFullYear();
                mm = entryDate.getMonth() + 1; // Months start at 0!
                dd = entryDate.getDate();
            }
            else if (this.flightInformation.flightDirection == "A") {
                entryDate.setDate(entryDate.getDate()); // Fecha del booking
                yyyy = entryDate.getFullYear();
                mm = entryDate.getMonth() + 1; // Months start at 0!
                dd = entryDate.getDate();
            }
            /*
            else {
            }
            */

            //console.info(yyyy,mm,dd);
            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;
            let maxDate = yyyy + '-' + mm + '-' + dd;

            //console.log(maxDate);
            return maxDate;
        }
        //DATE PICKER
    },

    methods: {
        //DATE PICKER
        change_flightDate() {
            this.flightInformation.flightDate = this.entryDate;
        },
        //DATE PICKER
        //TIME PICKER
        entryTimeHour_method(value) {
            this.entryTimeHour_value = value;
            //console.log(value);
        },
        entryTimeMinute_method(value) {
            this.entryTimeMinute_value = value;
            //console.log(value);
        },
        flightTimeHour_method(value) {
            this.flightTimeHour_value = value;
            //console.log(value);
        },
        flightTimeMinute_method(value) {
            this.flightTimeMinute_value = value;
            //console.log(value);
        },
        currentDate2(dayMore = false) {
            const today = new Date();

            if (dayMore == true) {
                //console.info("dayMore:",dayMore);
                today.setDate(today.getDate() + 1);
                //console.log(today);
            }

            let yyyy = today.getFullYear();
            let mm = today.getMonth() + 1; // Months start at 0!
            let dd = today.getDate();
            //console.info(yyyy,mm,dd);
            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;
            let currentDate = yyyy + '-' + mm + '-' + dd;
            //console.log(currentDate);
            return currentDate;
        },
        changeNextDay_method() {
            this.entryDate = this.currentDate2(true);
        },
        //TIME PICKER

        onlyNumbers(event) {
            //console.log(event);
            if (event.charCode >= 48 && event.charCode <= 57) {
                event.returnValue = true;
            } else {
                event.preventDefault(); //or event.returnValue = false;
            }
        },

        /**
         * Populate year and month combos
         */
        populateYearsAndMonths() {
            const currentYear = new Date().getFullYear();

            for (let i = currentYear; i <= currentYear + 30; i++) {
                const shortYear = i.toString().slice(-2);
                this.years.push({ label: shortYear, value: shortYear });
            }

            for (let i = 1; i <= 12; i++) {
                const paddedMonth = i.toString().padStart(2, '0');
                this.months.push({ label: paddedMonth, value: paddedMonth });
            }
        },

        /**
         * Handle message from bank integration 
         * @param {*} event 
         */
        handleMessage(event) {

            const data = event.data;
            if (data && data.message === 'END') {
                const payload = JSON.parse(data.payload);
                // Aquí puedes acceder a las propiedades del objeto
                // Cierra el popup
                this.showBankPopUp = false;
                let okshow = payload.bank_result_ok;
                if (okshow == true) {
                    this.paymentFinished(true);
                } else if (okshow == false) {
                    this.paymentFinished(false);
                } else {
                    this.paymentFinished(null);
                }
                this.popupVisible = false;
            }
        },

        /**
         * Fetch ancillaries
         */
        async fetchAncillaries() {
            const response = await getAncillaries(null, null, null, "order,asc");
            if (response) {
                this.ancillaries = response;
            }
        },

        /**
         * Fetch airlines
         */
        async fetchAirlines() {
            const response = await getAirlines(null, null, null, "name,asc");
            if (response) {
                this.airlines = response;
            }
        },

        /**
         * Fetch airports
         */
        async fetchAirports() {
            const response = await getAirports(null, null, null, "destinationName,asc");
            if (response) {
                this.destinations = response;
            }
        },

        /**
         * Fetch booking
         */
        async fetchBooking() {
            this.loading = true;

            const bookingId = this.$route.params.bookingId;
            const response = await getAppBooking(bookingId);
            if (response) {
                this.assignDataModel(response);
            }
            this.loading = false;
        },

        /**
         * Fetch client (merchant)
         */
        async fetchUserClient() {
            const clientId = await this.getUserClientId();
            let response = await getClient(clientId);
            if (response) {
                this.allowAgreement = Boolean(response.allowAgreement);
                this.allowCreditCard = Boolean(response.allowCreditCard);
                this.allowCash = Boolean(response.allowCash);
                this.allowYDAPOS = Boolean(response.allowYDAPOS);
                this.allowATMPOS = Boolean(response.allowATMPOS);
                this.allowPaternaPOS = Boolean(response.allowPaternaPOS);
            }
        },

        /**
         * set Flight direction
         */
        setFlightDirection(dateMode) {
            const numberDateMode = Number(dateMode);
            switch (numberDateMode) {
                case 0:
                    this.flightInformation.flightDirection = "A";
                    this.disableFlightDirection = true;
                    break;
                case 1:
                    this.flightInformation.flightDirection = "D";
                    this.disableFlightDirection = true;
                    break;
                default:
                    this.disableFlightDirection = false;
                    break;
            }
        },

        /**
         *  Add passenger other
         */
        addPassenger() {
            const bookingId = this.mainPassenger.bookingId;
            this.otherPassengers.push({
                ticket: "",
                name: "",
                passportNumber: "",
                birthdate: null,
                expeditionCountry: "",
                bookingId: bookingId,
                errorBirthDate: "",
            });
        },

        /**
        *  Remove passenger other
        */
        deletePassenger(index) {
            this.otherPassengers.splice(index, 1);
        },

        /**
        *  Make main passenger Passport Number to upper case
        */
        mainPassportToUpperCase(event) {
            this.mainPassenger.passportNumber = event.toUpperCase();
        },

        /**
        *  Make main passenger Name to upper case
        */
        mainNameToUpperCase(event) {
            this.mainPassenger.nameAndSurname = event.toUpperCase();
        },

        /**
        *  Make other passenger Passport Numer to upper case
        */
        otherPassportToUpperCase(event, index) {
            this.otherPassengers[index].passportNumber = event.toUpperCase();
        },

        /**
        *  Make other passenger Name to upper case
        */
        otherNameToUpperCase(event, index) {
            this.otherPassengers[index].nameAndSurname = event.toUpperCase();
        },

        /**
        *  Complete flight number
        */
        completeFlightNumber() {
            const flightNumber = this.flightInformation.flightNumber;
            const airlineCode = this.flightInformation.airline;
            if (!flightNumber || !flightNumber.startsWith(airlineCode)) {
                this.flightInformation.flightNumber = this.flightInformation.airline;
            }
        },

        /**
        *  Flight changed
        */
        flightChanged(event) {
            const newFlightNumber = event;
            const airlineCompany = this.flightInformation.airline;
            if (airlineCompany && !newFlightNumber.startsWith(airlineCompany)) {
                this.flightInformation.flightNumber = airlineCompany;
            }
        },

        /**
        *  Assign data to model on GET
        */
        assignDataModel(bookingItem) {
            this.item = bookingItem;
            let label = bookingItem.bookingStatus;
            this.bookingStatusText = this.$t(`label.booking_status_${label}`);
            this.idBooking = bookingItem.id;   //Get autoincrement booking ID
            this.entryDate = this.convertDateTimeToDalaman(bookingItem.entryDateTime,"date");
            this.entryTime = this.convertDateTimeToDalaman(bookingItem.entryDateTime,"time");
            this.merchantStatus = bookingItem.merchantStatus;
            this.ancillaryId = bookingItem.ancillaryQuotes[0].id
            this.paymentMethod = bookingItem.paymentInformation.paymentMethod;
            const mainPassenger = bookingItem.passportInformation.find(passenger => passenger.isMain);
            if (mainPassenger) {
                this.mainPassenger.id = mainPassenger.id;
                this.mainPassenger.nameAndSurname = mainPassenger.nameAndSurname;
                this.mainPassenger.passportNumber = mainPassenger.passportNumber;
                const momentObject = this.moment(mainPassenger.birthdate);
                const dateString = momentObject.format("YYYY-MM-DD");
                this.mainPassenger.birthdate = dateString;
                this.mainPassenger.isMain = true;
                this.mainPassenger.ticket = mainPassenger.ticketId;
                this.mainPassenger.bookingId = mainPassenger.bookingId;
                this.mainPassenger.expeditionCountry = mainPassenger.expeditionCountry;
            }
            const otherPassengers = bookingItem.passportInformation.filter(passenger => !passenger.isMain);
            otherPassengers.forEach(passenger => {
                const momentObject = this.moment(passenger.birthdate);
                const dateString = momentObject.format("YYYY-MM-DD");

                const newPassenger = {
                    id: passenger.id,
                    nameAndSurname: passenger.nameAndSurname,
                    passportNumber: passenger.passportNumber,
                    expeditionCountry: passenger.expeditionCountry,
                    birthdate: dateString,
                    isMain: false,
                    ticket: passenger.ticketId,
                    bookingId: passenger.bookingId,
                };
                this.otherPassengers.push(newPassenger);
            });

            // Convertir la fecha y hora al timezone específico de Dalaman
            const flightDateTime = DateTime.fromISO(bookingItem.flightInformation.flightDateTime, { zone: 'utc' });
            bookingItem.flightInformation.flightDateTime = flightDateTime.setZone(process.env.VUE_APP_AIRPORT_TIMEZONE);
            this.flightInformation.flightDate = this.convertDateTimeToDalaman(bookingItem.flightInformation.flightDateTime,"date");
            this.flightInformation.flightTime = this.convertDateTimeToDalaman(bookingItem.flightInformation.flightDateTime,"time");
            this.flightInformation.flightDirection = bookingItem.flightInformation.flightDirection;
            this.flightInformation.airline = bookingItem.flightInformation.airlineCode;
            this.flightInformation.destination = bookingItem.flightInformation.originOrDestinationAirport;
            this.flightInformation.flightNumber = bookingItem.flightInformation.flightNumber;
            this.paymentInformation = bookingItem.paymentInformation;
            this.contactInformation = bookingItem.contactInformation;

            //TIME PICKER
            //console.log(this.entryTime + " / " + this.flightInformation.flightTime);
            const array_entryTime = this.entryTime.split(":");
            this.entryTimeHour_value = array_entryTime[0];
            this.entryTimeMinute_value = array_entryTime[1];
            //console.log(this.entryTimeHour_value + " / " + this.entryTimeMinute_value);
            const array_flightTime = this.flightInformation.flightTime.split(":");
            this.flightTimeHour_value = array_flightTime[0];
            this.flightTimeMinute_value = array_flightTime[1];
            /*
            this.entryTimeHour_method(array_entryTime[0]);
            this.entryTimeMinute_method(array_entryTime[1]);
            //console.log(this.entryTimeHour_value + " / " + this.entryTimeMinute_value);
            this.flightTimeHour_method(array_flightTime[0]);
            this.flightTimeMinute_method(array_flightTime[1]);
            console.log(this.flightTimeHour_value + " / " + this.flightTimeMinute_value);
            */
            //TIME PICKER

        },

        /**
        *  Convert data model to object
        */
        convertDataModel() {
            const combinedEntryDateTime = this.getEntryDateTime();

            // Convertir a UTC 
            const dateTimeEntry = DateTime.fromFormat(combinedEntryDateTime, 'yyyy-MM-dd HH:mm', { zone: process.env.VUE_APP_AIRPORT_TIMEZONE });
            const dateTimeEntryUTC = dateTimeEntry.setZone('utc');
            const entryDateTime = dateTimeEntryUTC.toFormat('yyyy-MM-dd HH:mm');
         
            const currentCurrency = this.selectedAncillary.currencyNumericCode;
            const bookingId = this.$route.params.bookingId;

            // Get Passport Information
            let passportInformation = [];
            const mainPassengerTicket = this.tickets.find(ticket => ticket.ticketId == this.mainPassenger.ticket);
            // const dateISO = this.moment(this.mainPassenger.birthdate, "DD/MM/YYYY").format("YYYY-MM-DD");

            const mainPassenger = {
                nameAndSurname: this.mainPassenger.nameAndSurname,
                passportNumber: this.mainPassenger.passportNumber,
                isMain: true,
                ticketName: mainPassengerTicket.name,
                expeditionCountry: this.mainPassenger.expeditionCountry,
                birthdate: this.mainPassenger.birthdate,
                ticketId: mainPassengerTicket.ticketId,
                bookingId: this.mainPassenger.bookingId,
            };
            passportInformation.push(mainPassenger);
            this.otherPassengers.forEach(passenger => {
                const otherPassengerTicket = this.tickets.find(ticket => ticket.ticketId == passenger.ticket);
                // const dateISO = this.moment(passenger.birthdate, "DD/MM/YYYY").format("YYYY-MM-DD");

                const otherPassenger = {
                    nameAndSurname: passenger.nameAndSurname,
                    passportNumber: passenger.passportNumber,
                    isMain: false,
                    ticketName: otherPassengerTicket.name,
                    expeditionCountry: passenger.expeditionCountry,
                    birthdate: passenger.birthdate,
                    ticketId: otherPassengerTicket.ticketId,
                    bookingId: passenger.bookingId,
                };
                passportInformation.push(otherPassenger);
            });

            this.calculateTicketsQuantity();
            const ticketQuotes = this.tickets.filter((ticket) => ticket.quantity > 0);

            const combinedFlightDateTime = this.getFlightDateTime();
            // Convertir a UTC
            const dateTimeFlight = DateTime.fromFormat(combinedFlightDateTime, 'yyyy-MM-dd HH:mm', { zone: process.env.VUE_APP_AIRPORT_TIMEZONE });
            //const flightDateTime = dateTimeFlight.toUTC().toISO(); Ajuste formato fecha BBDD ver. 8
            const flightDateTime = dateTimeFlight.toUTC().toFormat('yyyy-MM-dd HH:mm:ss');

            const model = {
                id: this.idBooking,
                reference: bookingId,
                merchantStatus: this.merchantStatus,
                clientType: 0,  // client type B2B
                entryDateTime: entryDateTime,
                exitDateTime: entryDateTime,
                passportInformation,
                flightInformation: {
                    flightDirection: this.flightInformation.flightDirection,
                    airlineCode: this.flightInformation.airline,
                    originOrDestinationAirport: this.flightInformation.destination,
                    flightNumber: this.flightInformation.flightNumber,
                    flightDateTime: flightDateTime,
                },
                paymentInformation: {
                    paymentMethod: this.paymentMethod,
                    creditCardHolder: this.paymentInformation.cardHolderName,
                    totalCharged: {
                        value: this.totalAmount,
                        currency: currentCurrency,
                    },
                },
                ancillaryQuotes: [
                    {
                        id: this.selectedAncillary.ancillaryId,
                        name: this.selectedAncillary.name,
                        ticketQuotes: ticketQuotes,
                        extraQuotes: [],
                    },
                ],
                contactInformation: this.contactInformation,
            };
            return model;
        },

        /**
        *  Get NOW DateTime
        */
        getNowDateTime() {
            const dt = new Date();
            const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(len, chr);
            const dateNow = `${dt.getFullYear()}-${padL(dt.getMonth() + 1)}-${padL(dt.getDate())} ${padL(dt.getHours())}:${padL(dt.getMinutes())}:${padL(dt.getSeconds())}`;
            return dateNow;
        },

        /**
        *  Get Entry DateTime
        */
        getEntryDateTime() {
            let entryDateTime = null;
            if (this.entryDate && this.entryTime) {                
                entryDateTime = this.entryDate + ' ' + this.entryTime;
            }
            return entryDateTime;
        },
        /**
         * Convierte a Dalaman una fecha 
         */
        convertDateTimeToDalaman(dateTime, mode) {
            const entryDateTimeUTC = DateTime.fromISO(dateTime, { zone: 'utc' });
            dateTime = entryDateTimeUTC.setZone(process.env.VUE_APP_AIRPORT_TIMEZONE);
            switch (mode) {
                case "date":
                    return dateTime.toFormat('yyyy-MM-dd');
                case "time":
                    return dateTime.toFormat('HH:mm');
                case "all":
                    return dateTime.toFormat('yyyy-MM-dd HH:mm');
            }
        },      
        /**
        *  Get Entry flightDateTime
        */
        getFlightDateTime() {
            let flightDateTime = null;
            if (this.flightInformation.flightDate && this.flightInformation.flightTime) {
                // const dateISO = this.moment(this.flightInformation.flightDate, "DD/MM/YYYY").format("YYYY-MM-DD");
                // flightDateTime = dateISO + ' ' + this.flightInformation.flightTime;
                flightDateTime = this.flightInformation.flightDate + ' ' + this.flightInformation.flightTime;
            }
            return flightDateTime;
        },
        /**
         * reset values model
         */
        reset() {
            this.ancillaryId = {};
            this.paymentMethod = 'agreement';
            this.mainPassenger = {
                ticket: "",
                name: "",
                passportNumber: "",
                birthdate: null,
                expeditionCountry: "",
            };
            this.paymentInformation = {
                creditCardNumber: "",
                cardHolderName: "",
                expirationDate: "",
                expirationMonth: "01",
                expirationYear: "2024",
                CVV: "",
            };
            this.flightInformation = {
                flightDirection: "",
                airline: "",
                destination: "",
                flightNumber: "",
            };
            this.otherPassengers = [],
                this.contactInformation = {
                    passengerPhone: "",
                    email: "",
                    driverPhone: "",
                    driverName: "",
                    driverLicencePlate: "",
                    comments: "",
                };
            this.errorFlightDateTime = "";

        },

        validateEntryDateTime() {
            let isValid = true;

            const entryDateTimeString = this.getEntryDateTime();
            const nowDate = new Date();

            const entryDateTime = entryDateTimeString ? Date.parse(entryDateTimeString) : null;
            if (entryDateTime < nowDate) {
                isValid = false;
            }

            return isValid;
        },

        validateFlightDateTime() {
            let isValid = true;

            const flightDateTimeString = this.getFlightDateTime();
            const entryDateTimeString = this.getEntryDateTime();
            const nowDate = new Date();
            const entryDateTime = entryDateTimeString ? Date.parse(entryDateTimeString) : null;
            const flightDateTime = flightDateTimeString ? Date.parse(flightDateTimeString) : null;
            const flightDirecion = this.flightInformation.flightDirection;
            if (this.creating) {
                if (flightDateTime < nowDate) {
                    isValid = false;
                } else if (entryDateTime && flightDateTime && flightDirecion) {
                    if (flightDirecion == "D" && entryDateTime > flightDateTime) {
                        isValid = false;
                    } else if (flightDirecion == "A" && entryDateTime < flightDateTime) {
                        isValid = false;
                    }
                }
            }

            return isValid;
        },

        validateTickets() {
            var isValid = true;
            this.calculateTicketsQuantity();

            this.tickets.forEach(ticket => {
                // console.log(`Ticket ${ticket.name}: min ${ticket.minQuantity} max: ${ticket.maxQuantity} quantity: ${ticket.quantity}`);
                if (ticket.quantity > ticket.maxQuantity) {
                    isValid = false;
                    this.errorTickets = this.$t('error.ticket_quantity', { name: ticket.name, maxQuantity: ticket.maxQuantity });
                    return isValid;
                }
            });


            return isValid;
        },

        validateBirthDates() {
            var isValid = true;
            const now = new Date();

            const mainPassengerBirthDate = new Date(this.mainPassenger.birthdate);
            if (mainPassengerBirthDate && mainPassengerBirthDate >= now) {
                this.mainPassenger.errorBirthDate = this.$t('error.invalid_date_before_today');
                isValid = false;
            } else {
                this.mainPassenger.errorBirthDate = "";
            }
            this.otherPassengers.forEach(passenger => {
                const passengerBirthdate = new Date(passenger.birthdate);
                if (passenger.birthdate && passengerBirthdate >= now) {
                    passenger.errorBirthDate = this.$t('error.invalid_date_before_today');
                    isValid = false;
                } else {
                    passenger.errorBirthDate = "";
                }
            });

            return isValid;
        },

        calculateTicketsQuantity() {
            // Reset ticket quantities
            this.tickets.forEach(ticket => {
                ticket.quantity = 0;
            });
            // Add ticket quantity
            if (this.mainPassenger.ticket) {
                const mainPassengerTicket = this.tickets.find(ticket => ticket.ticketId == this.mainPassenger.ticket);
                var ticketIndex = this.tickets.findIndex(ticket => ticket.ticketId == mainPassengerTicket.ticketId);
                this.tickets[ticketIndex].quantity += 1;
            }
            this.otherPassengers.forEach(passenger => {
                if (passenger.ticket) {
                    let guestTicket = this.tickets.find(ticket => ticket.ticketId == passenger.ticket);
                    var ticketIndex = this.tickets.findIndex(ticket => ticket.ticketId == guestTicket.ticketId);
                    this.tickets[ticketIndex].quantity += 1;
                }
            });
        },

        checkHourFormat(event) {
            const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ':', 'Shift', 'Backspace'];
            const keyPressed = event.key;
            if (!keysAllowed.includes(keyPressed)) {
                event.preventDefault();
            }
        },

        preventChangeEntryDate(event) {
            const keysNotAllowed = ['Backspace', 'Delete'];
            const keyPressed = event.key;
            if (keysNotAllowed.includes(keyPressed)) {
                this.entryDate = null;
            }
        },

        preventChangeFlightDate(event) {
            const keysNotAllowed = ['Backspace', 'Delete'];
            const keyPressed = event.key;
            if (keysNotAllowed.includes(keyPressed)) {
                this.flightInformation.flightDate = null;
            }
        },

        async bookCreditCardTest() {
            this.showBankPopUp = true;
            this.$refs.refAsseco.launchPaymentForm(null);

        },
        async checkData() {
            this.valid = true;

            // Validate entry date
            if (!this.entryDate) {
                this.errorEntryDateMessage = this.$t('validation.required', { _field_: this.$t('label.entry_date') });
                this.valid = false;
            } else {
                this.errorEntryDateMessage = "";
            }

            // Validate tickets
            var isValidTickets = this.validateTickets();
            if (!isValidTickets) {
                this.valid = false;
            } else {
                this.errorTickets = "";
            }

            // Validate entry date
            if (!this.flightInformation.flightDate) {
                this.errorFlightDateTime = this.$t('validation.required', { _field_: this.$t('label.flight_date') });
                this.valid = false;
            } else {
                this.errorFlightDateTime = "";
            }

            // Validate entry date time      
            var isValidEntryDateTime = this.validateEntryDateTime();
            if (!isValidEntryDateTime) {
                this.errorEntryDateTime = this.$t('error.invalid_date');
                this.valid = false;
            } else {
                this.errorEntryDateTime = "";
            }

            var isValidBirthDates = this.validateBirthDates();
            if (!isValidBirthDates) {
                this.valid = false;
            }



            if (this.paymentMethod == 'creditcard' && this.creating) {

                // Validate expiration Month
                if (!this.paymentInformation.expirationMonth) {
                    this.errorMonthMessage = this.$t('validation.at_least_one_value', { _field_: this.$t('label.expiration_month') });
                    this.valid = false;
                } else {
                    this.errorMonthMessage = "";
                }

                // Validate expiration Year
                if (!this.paymentInformation.expirationYear) {
                    this.errorYearMessage = this.$t('validation.at_least_one_value', { _field_: this.$t('label.expiration_year') });
                    this.valid = false;
                } else {
                    this.errorYearMessage = "";
                }

            }

            if (!this.valid) {
                await this.$refs.form.validate();
            } else {
                this.valid = await this.$refs.form.validate();
            }

            return this.valid;
        },

        async bookCreditCard() {

            try {

                let isValid = await this.checkData();
                if (isValid) {
                    const booking = this.convertDataModel();
                    try {
                        this.postingBooking = true;
                        const response = await createBookingAncillary(booking);
                        if (response) {
                            let body = {};
                            body.amount = this.totalAmount;
                            body.currency = booking.paymentInformation.totalCharged.currency;
                            body.oid = response.data.bookingId;
                            this.bookingId = response.data.bookingId;
                            body.rnd = 'adsf';
                            body.pan = this.paymentInformation.creditCardNumber;
                            body.cv2 = this.paymentInformation.CVV;
                            body.expDateYear = this.paymentInformation.expirationYear;
                            body.expDateMonth = this.paymentInformation.expirationMonth;
                            body.BillToName = this.paymentInformation.cardHolderName + " / " + this.mainPassenger.passportNumber;
                            this.showBankPopUp = true;
                            this.$refs.refAsseco.launchPaymentForm(body);
                        } else {
                            this.$noty.error(this.$t("error.saved"));
                        }
                    } catch (ex) {
                        console.error(ex);
                        this.$noty.error(this.$t("error.saved"));
                    } finally {
                        this.postingBooking = false;
                    }

                }

            } catch (error) {
                console.error("Error credit card", error);
                throw error;
            }
        },
        async book() {
            let isValid = await this.checkData();
            if (isValid) {
                const booking = this.convertDataModel();
                try {
                    this.postingBooking = true;                    
                    const response = await createBookingAncillary(booking);
                    if (response) {
                        this.$noty.success(this.$t('success.saved'));
                        this.$router.push({ name: this.$i18n.locale + '.bookings' });
                        this.bookingId = response.data.bookingId;
                        await sendMailBooking(this.bookingId);
                    } else {
                        this.$noty.error(this.$t("error.saved"));
                    }
                } catch (ex) {
                    console.error(ex);
                    this.$noty.error(this.$t("error.saved"));
                } finally {
                    this.postingBooking = false;
                }
            }
        },

        async edit() {
            let isValid = await this.checkData();

            if (isValid) {
                try {
                    this.loading = true;
                    const booking = this.convertDataModel();
                    if (this.paymentMethod == 'creditcard') {
                        booking.merchantStatus = 1;
                    }
                    const bookingId = this.$route.params.bookingId;
                    this.bookingId = bookingId;          
                    const response = await updateBookingB2B(bookingId, booking);
                    if (response) {
                        this.$noty.success(this.$t('success.saved'));
                    } else {
                        this.$noty.error(this.$t("error.saved"));
                    }
                } catch (ex) {
                    console.error("Error saving data:", ex);
                    this.$noty.error(this.$t("error.saved"));
                } finally {
                    this.loading = false;
                }
            }
        },

        async paymentFinished(status) {
            if (status == true) {
                this.$noty.success(this.$t('success.payment'));
                this.$router.push({ name: this.$i18n.locale + '.bookings' });
                await sendMailBooking(this.bookingId);
            } else if (status == false) {
                this.$noty.error(this.$t('error.payment'));
            } else if (status == null) {
                this.$noty.error(this.$t('error.payment'));
            }
        },

        confirmUpdate() {
            this.ItemIdToUpdate = this.$route.params.bookingId;
            this.showConfirm = true;
        },

        confirmRefund() {
            this.ItemIdToRefund = this.$route.params.bookingId;
            if (this.paymentMethod == 'creditcard') {
                this.showCancelConfirm = true;
            } else {
                this.showCancelConfirm = false;
            }
        },

        //Credit card
        async cancelItem() {
            this.loading = true;
            const booking = this.convertDataModel();
            booking.bookingStatus = 5;
            booking.merchantStatus = 3;


            try {
                const response = await updateBooking(this.ItemIdToRefund, booking);

                if (response.status == 200) {
                    if (response.data.result == false) {
                        if (response.data.is6hError) {
                            this.$noty.error(this.$t('error.booking_canceled_6h'));
                        } else {
                            let errorMore = "";
                            if (response.data.bankResponse && response.data.bankResponse.ErrMsg) {
                                errorMore = response.data.bankResponse.ErrMsg;
                            }
                            const errorMessage = errorMore ? `${this.$t('error.cancelled')}\nError: ${errorMore}` : this.$t('error.cancelled');
                            this.$noty.error(errorMessage);
                        }
                    } else {
                        this.$noty.success(this.$t('success.updated'));
                    }
                } else {
                    this.$noty.error(this.$t('error.cancelled'));
                }

                this.showCancelConfirm = false;
                this.ItemIdToRefund = null;
                await this.fetchBooking();
            } catch (error) {
                console.error("Error cancelling booking: ", error);
                this.$noty.error(this.$t('error.cancelled'));
            } finally {
                this.loading = false;
            }
        },

        //Formas de pago no credit card
        async updateItem() {
            this.loading = true;
            const booking = this.convertDataModel();
            booking.bookingStatus = 3;
            booking.merchantStatus = 2;
            try {
                const response = await updateBooking(this.ItemIdToUpdate, booking);
                if (response.status == 200 && response.data.result == true) {
                    this.showConfirm = false;
                    this.ItemIdToUpdate = null;
                    this.$noty.success(this.$t('success.updated'));
                    await this.fetchBooking();
                } else if (response.status == 200 && response.data.result == false) {
                    if (response.data.is6hError) {
                        this.$noty.error(this.$t('error.booking_canceled_6h'));
                        this.showConfirm = false;
                    } else {
                        this.$noty.error(this.$t('error.updated'));
                    }
                } else {
                    this.$noty.error(this.$t('error.updated'));
                }
            } catch (error) {
                console.error("Error updating booking: ", error);
                this.$noty.error(this.$t('error.updated'));
            } finally {
                this.loading = false;
            }
        }
    },
}
</script>

<style scoped>
.B2B-booking-form {
    padding-left: 35px;
    padding-right: 35px;
}

.grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 100px;
}

.passenger-information-form,
.contact-information-form {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr 1fr;
    gap: 75px;
}

.flight-information-form {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 1fr;
    column-gap: 75px;
}

.entry-date-time {
    display: flex;
    gap: 15px;
}

.hour-input {
    max-width: 100px;
}

.Other-Passengers .Luggage-information {
    margin-right: 20px;
}

.passenger-birthday {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 30px;
}

.passenger-birthday .md-field {
    margin: auto;
}

.add-button {
    background: #E4E4E6;
    border: none;
    border-radius: 999px;
    height: 30px;
    width: 30px;
}

.icon-add {
    padding-right: 6px;
}

.add-button:hover {
    box-shadow: 0px 0px 4px black;
    /* Cambia los valores según desees */
    cursor: pointer;
}

.delete-passenger {
    background: transparent;
    border: none;
}

.md-field:not(.md-datepicker):not(.md-has-value) label {
    z-index: -100;
}

.md-select-label {
    z-index: 0 !important;
}

.book-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.booking-summary {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0px;
}

.total-amount {
    color: var(--main-color);
    font-weight: bold;
    font-size: 40px;
    text-align: center;
    margin-bottom: 40px;
}

.booking-errors {
    font-size: 14px;
    text-align: center;
    margin-bottom: -12px;
    margin-top: -82px;
}

.booking-info {
    font-size: 14px;
    text-align: center;
    margin-bottom: -12px;
    margin-top: -82px;
}

.booking-info-spinner {
    margin-left: 12px;
}

.booking-info-text {
    margin-top: 0px;
}

.main-passenger-birthdate {
    margin-right: 37px;
}

.md-error {
    color: var(--error-color);
}

.booking-reference {
    display: flex;
    justify-content: flex-end;
    color: var(--main-color);
    font-size: 20px;
    font-weight: bold;
}
</style>
