<template>
    <div>
        <MediaData />
    </div>
</template>

<script>
import MediaData from "@/components/Media/Media";

export default {
    components:{
        MediaData,
    },
}
</script>

<style scoped>

</style>