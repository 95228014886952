<template>
    <div>
        <!-- Media List -->
        <div v-if="!creating && !editing">
            <div class="inline-toolbar">
                <md-field class="search-input">
                    <md-icon class="search-icon">image_search</md-icon>
                    <label>{{ $t("label.search_filename") }}</label>
                    <md-input v-model="search" @keyup.enter="filter()"></md-input>
                    <md-button v-if="search" class="btn-floating" @click="clearFilter()">
                        <md-icon>close</md-icon>
                    </md-button>
                </md-field>
                <md-button class="md-primary md-round" @click="filter()">
                    {{ $t("search") }}
                </md-button>
                <md-button @click="newItem()" class="md-primary md-round">
                    {{ $t("new") }}
                </md-button>
            </div>

            <md-table table-header-color="blue" class="media-table" v-model="mediaList">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell class="media-checkbox" v-if="!isMultimediaView">
                        <md-checkbox v-model="selectedMedia" :value="item"></md-checkbox>
                    </md-table-cell>
                    <md-table-cell :md-label="$t('label.file_name')">{{ item.fileName }}</md-table-cell>
                    <md-table-cell :md-label="$t('label.type')">{{ item.mediaType }}</md-table-cell>
                    <md-table-cell class="table-cell-actions">
                        <button class="btn-icon" @click.prevent="viewImage(item.imageURL)">
                            <md-icon>visibility</md-icon>
                        </button>
                        <button class="btn-icon" @click="editItem(item.mediaId)">
                            <md-icon>edit</md-icon>
                        </button>
                        <button class="btn-icon" @click="confirmDelete(item)">
                            <md-icon>delete</md-icon>
                        </button>
                    </md-table-cell>
                </md-table-row>
            </md-table>
            <!-- Pagination -->
            <div class="pagination-container">
                <md-pagination v-model="page" :noArrows="false" :perPage="limit" :total="total" />
            </div>
        </div>
        <!-- Media form -->
        <div v-else>
            <ValidationObserver tag="form" ref="form" v-slot="{ errors }">
                <div class="md-layout">
                    <div class="md-layout-item md-size-70">
                        <validation-provider name="file" rules="required" v-slot="{ errors }" mode="aggressive">
                            <div class="media-input-container">
                                <md-field>
                                    <md-icon>image_search</md-icon>
                                    <md-input v-model="media.fileName" name="fileName" type="text" id="fileName" readonly
                                        :disabled="loading || view || editing"></md-input>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </md-field>

                                <label class="input-media">
                                    <input ref="file" type="file" @change="onFileChange"
                                        :disabled="loading || view || editing" />
                                    <span>{{ $t("select_file") }}</span>
                                </label>
                            </div>
                        </validation-provider>
                    </div>
                    <!-- defaultAirportMedia -->
                    <div class="md-layout-item md-size-30">
                        <div class="field-switch">
                            <Span>{{ $t("label.media_defaultAirportMedia") }}</Span>
                            <md-switch id="switchEnabled" v-model="media.defaultAirportMedia" :disabled="loading">
                            </md-switch>
                        </div>
                    </div>
                </div>
                <div class="form-text-info">
                    {{ $t("file_type") + ":" + media.mediaType }}
                </div>
                <!-- Translations -->
                <md-card class="translations-card">
                    <md-card-content>
                        <md-tabs class="translations-tabs">
                            <md-tab id="tab-tr" :md-label="$t('languages.tr')" :md-icon="getLanguageIcon('tr', errors)">
                                <!-- Alternative Text TR -->
                                <md-field :class="[
                                    { 'md-valid': errors['alternative_text_tr'] && !errors['alternative_text_tr'][0] && touchedFields.email },
                                    { 'md-error': errors['alternative_text_tr'] && errors['alternative_text_tr'][0] }
                                ]">
                                    <label for="alternative_text_tr">{{
                                        $t("fields.alternative_text")
                                    }}</label>
                                    <validation-provider name="alternative_text_tr" rules="required"
                                        v-slot="{ errors, touched }" mode="aggressive">
                                        <md-input v-model="alternative_text_tr" name="alternative_text_tr" type="text"
                                            id="alternative_text_tr" :disabled="loading || view"></md-input>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </md-tab>
                            <md-tab id="tab-en" :md-label="$t('languages.en')" :md-icon="getLanguageIcon('en', errors)">
                                <!-- Alternative Text EN -->
                                <md-field :class="[
                                    { 'md-valid': errors['alternative_text_en'] && !errors['alternative_text_en'][0] && touchedFields.email },
                                    { 'md-error': errors['alternative_text_en'] && errors['alternative_text_en'][0] }
                                ]">
                                    <label for="alternative_text_en">{{
                                        $t("fields.alternative_text")
                                    }}</label>
                                    <validation-provider name="alternative_text_en" rules="required"
                                        v-slot="{ errors, touched }" mode="aggressive">
                                        <md-input v-model="alternative_text_en" name="alternative_text_en" type="text"
                                            id="alternative_text_en" :disabled="loading || view"></md-input>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </md-tab>
                        </md-tabs>
                    </md-card-content>
                </md-card>

                <div class="modal-footer">
                    <md-button @click.prevent="cancel()" class="md-danger md-round" :disabled="loading">
                        {{ $t("cancel") }}
                    </md-button>
                    <md-button v-if="!view" @click.prevent="save()" type="submit" class="md-primary md-round"
                        :disabled="loading">
                        {{ $t("save") }}
                    </md-button>
                </div>
            </ValidationObserver>
        </div>
        <!-- Modal Confirm Delete -->
        <md-modal v-if="showConfirm" @close="showConfirm = false">
            <h3 slot="header">
                {{ $t("confirm") }}
            </h3>
            <div slot="body">
                {{ $t("confirm_messages.delete") }}
            </div>
            <div slot="footer" class="modal-footer">
                <md-button @click="showConfirm = false" class="md-danger md-round">
                    {{ $t("cancel") }}
                </md-button>
                <md-button @click="deleteItem()" class="md-primary md-round">
                    {{ $t("delete") }}
                </md-button>
            </div>
        </md-modal>
    </div>
</template>

<script>
import s3Mixin from "@/mixins/s3";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import {
    getMediaList,
    getMediaById,
    createMedia,
    updateMedia,
    deleteMedia,
} from "@/services/media";
import paginationMixin from "@/mixins/pagination";
import localeMixin from "@/mixins/locale";

export default {
    mixins: [paginationMixin, localeMixin, s3Mixin],
    components: {
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
    },
    data() {
        return {
            loading: false,
            showConfirm: false,
            touchedFields: {
                alternative_text_en: false,
                alternative_text_tr: false,
            },
            mediaList: [],
            media: {
                fileName: "",
                s3Key: "",
                mediaType: "",
                defaultAirportMedia: false,
                translations: [
                    {
                        locale: "en",
                        alternativeText: "",
                    },
                    {
                        locale: "tr",
                        alternativeText: "",
                    },
                ],
            },
            alternative_text_en: "",
            alternative_text_tr: "",
            fileData: null,
            view: false,
            creating: false,
            editing: false,
            search: "",
            selectedMedia: null,
            ItemIdToDelete: null,
            imagesLightbox: [],
        };
    },

    computed: {
        isMultimediaView() {
            const routeName = this.$route.name.substring(3);
            return routeName == "multimedia" ? true : false;
        }
    },

    watch: {
        page() {
            this.fetchItems();
        },
        selectedMedia: {
            handler(newValue) {
                this.$emit('change', newValue);
            },
            deep: true,
        }
    },

    created: async function () {
        const routeName = this.$route.name.substring(3);
        this.limit = routeName != "multimedia" ? 5 : 10;
        this.fetchItems();
    },

    methods: {
        async fetchItems() {
            this.loading = true;
            const response = await getMediaList(
                this.page,
                this.limit,
                this.search
            );
            if (response) {
                this.mediaList = response.result;
                this.total = response.pageInfo.totalPages * this.limit;
            } else {
                this.mediaList = [];
                this.total = 0;
            }
            this.loading = false;
        },

        async fetchItemAsync(itemId) {
            this.loading = true;
            let response = await getMediaById(itemId);
            if (response) {
                this.media = response;
                this.media.defaultAirportMedia =  response.defaultAirportMedia == 1 ? true : false;
                const translation_en = this.media.translations.find(({ locale }) => locale == "en");
                if (translation_en) {
                    this.alternative_text_en = translation_en.alternativeText;
                }
                const translation_tr = this.media.translations.find(({ locale }) => locale == "tr");
                if (translation_tr) {
                    this.alternative_text_tr = translation_tr.alternativeText;
                }
            }
            this.loading = false;
        },

        fetchItem(itemId) {
            this.loading = true;
            getMediaById(itemId)
                .then(response => {
                    if (response) {
                        this.media = response;
                        this.media.defaultAirportMedia =  response.defaultAirportMedia == 1 ? true : false;
                        const translation_en = this.media.translations.find(({ locale }) => locale == "en");
                        if (translation_en) {
                            this.alternative_text_en = translation_en.alternativeText;
                        }
                        const translation_tr = this.media.translations.find(({ locale }) => locale == "tr");
                        if (translation_tr) {
                            this.alternative_text_tr = translation_tr.alternativeText;
                        }
                    }
                    this.loading = false;
                })
                .catch(error => {
                    console.error(error);
                    this.loading = false;
                });
        },

        async filter() {
            this.fetchItems();
        },

        clearFilter() {
            this.search = "";
            this.fetchItems();
        },

        newItem() {
            this.clearData();
            this.creating = true;
            this.$emit("creating");
        },

        viewImage(imageUrl) {
            this.view = true;
            this.imagesLightbox = [];
            this.$imageViewer.images(this.imagesLightbox);
            const image = {
                url: imageUrl,
            };
            this.imagesLightbox.push(image);
            this.$imageViewer.index(0);
            this.$imageViewer.show();
        },

        editItem(itemId) {
            this.editing = true;
            this.clearData();
            this.fetchItem(itemId);
            this.$emit("editing");
        },

        cancel() {
            this.view = false;
            this.editing = false;
            this.creating = false;
            this.$emit("cancel");
        },

        clearData() {
            this.alternative_text_en = "";
            this.alternative_text_tr = "";
            this.media = {
                defaultAirportMedia:false,
                fileName: "",
                s3Key: "",
                mediaType: "",
                translations: [
                    {
                        locale: "en",
                        alternativeText: "",
                    },
                    {
                        locale: "tr",
                        alternativeText: "",
                    },
                ],
            };
        },

        async save() {
            this.loading = true;
            var valid = await this.$refs.form.validate();

            if (valid) {
                let s3Key = null;
                if (this.creating && this.fileData) {
                    if (!this.validFile(this.fileData)) {
                        this.loading = false;
                        return;
                    }

                    s3Key = await this.uploadS3(this.fileData);
                    if (!s3Key) {
                        this.$noty.error(this.$t("error.upload"));
                        this.loading = false;
                        return;
                    } else {
                        this.media.s3Key = s3Key.key;
                    }
                }

                let translation_en = this.media.translations.find(({ locale }) => locale == "en");
                if (translation_en) {
                    translation_en.alternativeText = this.alternative_text_en;
                } else {
                    translation_en = {
                        locale: "en",
                        alternativeText: this.alternative_text_en,
                    }
                }
                let translation_tr = this.media.translations.find(({ locale }) => locale == "tr");
                if (translation_tr) {
                    translation_tr.alternativeText = this.alternative_text_tr;
                } else {
                    translation_tr = {
                        locale: "tr",
                        alternativeText: this.alternative_text_tr,
                    }
                }
                const translations = [translation_en, translation_tr];
                this.media.translations = translations;
                let response;
                if (this.creating) {
                    response = await createMedia(this.media);
                } else if (this.editing) {
                    response = await updateMedia(this.media);

                }
                if (response) {
                    this.$noty.success(this.$t("success.saved"));
                    this.creating = false;
                    this.editing = false;
                    this.fetchItems();
                } else {
                    this.$noty.error(this.$t("error.saved"));
                }
            } else {
                this.$noty.error(this.$t('error.saved'));
            }
            this.loading = false;
        },

        confirmDelete(Item) {
            this.ItemIdToDelete = Item.mediaId;
            this.showConfirm = true;
        },

        async deleteItem() {
            this.loading = true;
            let response = await deleteMedia(this.ItemIdToDelete);
            if (response) {
                this.$noty.success(this.$t("success.item_deleted"));
                this.showConfirm = false;
                this.ItemIdToDelete = "";
                this.fetchItems();
            }
            this.loading = false;
        },

        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.fileData = files[0];
            this.media.fileName = this.fileData.name;
            this.media.mediaType = this.fileData.type;
        },

        validFile(file) {
            const sizeMb = file.size / 1000000;
            if (sizeMb > 2) {
                this.$noty.error(this.$t("error.exceeded_size"));
                return false;
            }
            const fileType = file.type;
            if (fileType != "image/jpeg" && fileType != "image/png" && fileType != "video/mp4" && fileType != "image/svg+xml") {
                this.$noty.error(this.$t("error.image_required"));
                return false;
            }
            return true;
        },

    },
}
</script>

<style scoped>
.media-table .md-table-cell:not(.table-cell-actions) {
    text-align: left;
}

.media-checkbox .md-checkbox {
    margin: 0;
}

.form-text-info {
    text-align: left;
    font-weight: normal;
}
</style>