<template>
    <ValidationObserver tag="form" ref="form" v-slot="{ errors }">
        <div id="Main" class="md-layout">
            <!-- Button -->
            <div class="md-layout-item md-size-100 form-toolbar">
                <h3 class="page-title">
                    <span v-if="creating">
                        {{ $t("routes.user_create.title") }}
                    </span>
                    <span v-if="editing">
                        {{ $t("routes.user_edit.title") }} {{ item.email }}
                    </span>
                </h3>
                <div class="form-buttons">
                    <md-progress-spinner v-if="loading" :md-diameter="30" :md-stroke="3" md-mode="indeterminate">
                    </md-progress-spinner>
                    <!-- Save button -->
                    <md-button class="md-primary md-round btn-medium" @click="save()" :disabled="loading">
                        {{ $t("save") }}
                    </md-button>
                </div>
            </div>
            <!-- User role-->
            <div class="md-layout-item md-size-40">
                <md-field v-if="isB2B || creating">
                    <md-select v-model="item.roleId" :disabled="loading" @md-selected="checkValues()">
                        <md-option v-for="(item, idx5) of userRoles" :value="item.value" :key="idx5">
                            {{ item.text }}
                        </md-option>
                    </md-select>
                    <span class="md-error">
                        {{ errorRoleMessage }}
                    </span>
                </md-field>
            </div>
            <div class="md-layout-item md-size-60">
            </div>
            <!--user email-->
            <div class="md-layout-item md-size-40">
                <!-- email-->
                <md-field :class="[
        { 'md-valid': errors['user_name'] && !errors['user_name'][0] && touchedFields.user_email },
        { 'md-error': errors['user_name'] && errors['user_name'][0] }
    ]">
                    <label for="sub">{{ $t('fields.user_name') }}</label>
                    <validation-provider name='user_name' rules="" v-slot="{ errors, touched }" mode="aggressive">
                        <md-input v-model="item.name" name="user_name" type="text" :disabled="editing"
                            :placeholder="$t('label.user_name')" id="user_name">
                        </md-input>
                        <slide-y-down-transition>
                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                        </slide-y-down-transition>
                        <span class="md-error">{{ errors[0] }}</span>
                    </validation-provider>
                </md-field>
            </div>
            <div class="md-layout-item md-size-20">
            </div>
            <!-- Enabled -->
            <div class="md-layout-item md-size-40">
                <div class="field-switch" v-if="!creating">
                    <Span>{{ $t("label.user_enabled") }}</Span>
                    <md-switch id="switchEnabled" v-model="item.enabled" :disabled="loading">
                    </md-switch>
                </div>
            </div>
            <!--user email-->
            <div class="md-layout-item md-size-40">
                <!-- email-->
                <md-field :class="[
        { 'md-valid': errors['user_email'] && !errors['user_email'][0] && touchedFields.user_email },
        { 'md-error': errors['user_email'] && errors['user_email'][0] }
    ]">
                    <label for="sub">{{ $t('fields.user_email') }}</label>
                    <validation-provider name='user_email' rules="required|email" v-slot="{ errors, touched }"
                        mode="aggressive">
                        <md-input v-model="item.email" name="user_email" type="text" :disabled="editing"
                            :placeholder="$t('label.user_email')" id="user_email">
                        </md-input>
                        <slide-y-down-transition>
                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                        </slide-y-down-transition>
                        <span class="md-error">{{ errors[0] }}</span>
                    </validation-provider>
                </md-field>
            </div>
            <div class="md-layout-item md-size-20">
            </div>
            <!-- email verified -->
            <div class="md-layout-item md-size-40">
                <div class="field-switch" v-if="!creating">
                    <Span>{{ $t("label.user_emailVerified") }}</Span>
                    <md-switch v-model="item.emailVerifified" :disabled="true">
                    </md-switch>
                </div>
            </div>
            <!--phone Number-->
            <div class="md-layout-item md-size-40">
                <!-- phone number-->
                <md-field :class="[
        { 'md-valid': errors['user_phoneNumber'] && !errors['user_phoneNumber'][0] && touchedFields.user_phoneNumber },
        { 'md-error': errors['user_phoneNumber'] && errors['user_phoneNumber'][0] }
    ]">
                    <label for="sub">{{ $t('fields.user_phoneNumber') }}</label>
                    <validation-provider name='user_phoneNumber' rules="numeric" v-slot="{ errors, touched }"
                        mode="aggressive">
                        <md-input v-model="item.phoneNumber" name="phoneNumber" type="text"
                            :placeholder="$t('label.user_phoneNumber')" id="user_phoneNumber" :disabled="loading">
                        </md-input>
                        <slide-y-down-transition>
                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                        </slide-y-down-transition>
                        <span class="md-error">{{ errors[0] }}</span>
                    </validation-provider>
                </md-field>
            </div>
            <div class="md-layout-item md-size-20">
            </div>
            <!-- phone Number verified -->
            <div class="md-layout-item md-size-40">
                <div class="field-switch" v-if="!creating">
                    <Span>{{ $t("label.user_phoneNumberVerified") }}</Span>
                    <md-switch v-model="item.phoneNumberVerifified" :disabled="true">
                    </md-switch>
                </div>
            </div>
            <!-- Client selector -->
            <div v-if="!creating" class="md-layout-item md-size-40">
                <md-field>
                    <md-select v-model="item.clientId" :disabled="loading">
                        <md-option v-for="(item, idx5) of clients" :value="item.value" :key="idx5">
                            {{ item.text }}
                        </md-option>
                    </md-select>
                </md-field>
            </div>
            <!-- Separator Map information -->
            <div class="md-layout-item md-size-100" v-if="!creating">
                <div class="Group-Header">
                    <span class="Luggage-information">
                        {{ $t('label.other_info') }}
                    </span>
                    <div class="Path"></div>
                </div>
            </div>
            <!-- create date -->
            <div class="md-layout-item md-size-40" v-if="!creating">
                <md-field :placeholder="$t('label.user_createDate')">
                    <label for="user_createDate">{{ $t('label.user_createDate') }}</label>
                    <md-input v-model="item.userCreateDate" type="text" :placeholder="$t('label.user_createDate')"
                        id="userCreateDate" :disabled="true">
                    </md-input>
                </md-field>
            </div>
            <div class="md-layout-item md-size-20" v-if="!creating">
            </div>
            <!-- last modified date -->
            <div class="md-layout-item md-size-40" v-if="!creating">
                <md-field :placeholder="$t('label.user_lastModifiedDate')">
                    <label for="user_lastModifiedDate">{{ $t('label.user_lastModifiedDate') }}</label>
                    <md-input v-model="item.userLastModifiedDate" type="text"
                        :placeholder="$t('label.user_lastModifiedDate')" id="lastModifiedDate" :disabled="true">
                    </md-input>
                </md-field>
            </div>
            <!-- last modified date -->
            <div class="md-layout-item md-size-40" v-if="!creating">
                <md-field :placeholder="$t('label.user_status')">
                    <label for="user_status">{{ $t('label.user_status') }}</label>
                    <md-input v-model="item.userStatus" type="text" :placeholder="$t('label.user_status')"
                        id="user_status" :disabled="true">
                    </md-input>
                </md-field>
            </div>


        </div>
    </ValidationObserver>
</template>
<script>

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import { getUser, createUser, updateUser, getUserRole } from "@/services/users";
import { getClients, linkClientUser } from "@/services/client";
import localeMixin from "@/mixins/locale";



export default {
    mixins: [localeMixin],
    components: {
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
    },

    data() {
        return {
            creating: false,
            editing: false,
            imagesLightbox: [],
            item: {
                userName: "",
                name: "",
                sub: "",
                email: "",
                clientId: "",
                emailVerifified: false,
                phoneNumber: "",
                phoneNumberVerifified: false,
                userCreateDate: "",
                userLastModifiedDate: "",
                enabled: false,
                userStatus: "",
                roleId: "",
            },
            orders: [],
            showMediaModal: false,
            showMediaIconModal: false,
            touchedFields: {

            },
            errorRoleMessage: "",
            loading: false,
            clients: [],
            userRoles: [],
        };
    },




    created: async function () {
        let sub = this.$route.params.sub;
        this.item.clientId = this.$route.params.clientId;

        if (sub) {
            this.editing = true;
            this.creating = false;
            await this.editItem(sub);
        } else {
            this.editing = false;
            this.creating = true;
            this.newItem();
        }
    },

    async beforeMount() {
        await this.fillClients();
        await this.fillUserRole();
    },

    computed: {
        isB2B: function () {
            return (this.item.roleId) ? true: false;
        },
    },

    methods: {

        async save() {

            this.errorRoleMessage = "";

            var valid = await this.$refs.form.validate();

            if (this.item.roleId == "0") {
                valid = false;
                this.errorRoleMessage = this.$t('validation.at_least_one_value', { _field_: this.$t('label.user_rolId') });
            }

            if (valid) {
                this.loading = true;
                if (this.creating) {
                    let param = this.convertDataModel();
                    let response = await createUser(param);
                    if (response.status == "201") {
                        const clientId = this.item.clientId;
                        const user = {
                            userId: response.data.sub,
                            email: this.item.email.trim(),
                        }
                        let responseLink = await linkClientUser(clientId, user);
                        if (responseLink) {
                            this.$noty.success(this.$t('success.saved'));
                            this.$router.go(-1);
                        } else {
                            this.$noty.error(this.$t("error.saved"));
                        }

                    } else {
                        if (response.data.error == "An account with the given email already exists.") {
                            this.$noty.error(this.$t("error.duplicated_user"));
                        } else {
                            this.$noty.error(this.$t("error.saved"));
                        }
                    }

                }
                if (this.editing) {
                    let response = await updateUser(this.convertDataModel());
                    if (response.status == "200") {
                        if (this.isB2B) {
                            const clientId = this.item.clientId;
                            const user = {
                                userId: response.data.sub,
                                email: this.item.email.trim(),
                            };
                            // Update or insert cognito_clients table
                            let responseLink = await linkClientUser(clientId, user);
                            if (responseLink) {
                                this.$noty.success(this.$t('success.saved'));
                            } else {
                                this.$noty.error(this.$t("error.saved"));
                            }
                        } else {
                            this.$noty.success(this.$t('success.saved'));
                        }
                    } else {
                        this.$noty.error(this.$t("error.saved"));
                    }
                }

                this.loading = false;


            }

        },


        assignDataModel(item) {
            this.item.userName = item.Username;
            this.item.name = this.searchValue('name', item.Attributes);
            this.item.sub = this.searchValue('sub', item.Attributes);
            this.item.email = this.searchValue('email', item.Attributes);
            this.item.clientId = this.searchValue('given_name', item.Attributes);
            let phone = this.searchValue('phone_number', item.Attributes);
            if (phone)
                this.item.phoneNumber = phone.replace('+', "");
            this.item.userCreateDate = this.customFormatDate(item.UserCreateDate);
            this.item.userLastModifiedDate = this.customFormatDate(item.UserLastModifiedDate);
            this.item.emailVerifified = Boolean(this.searchValue('email_verified', item.Attributes));
            this.item.phoneNumberVerifified = Boolean(this.searchValue('phone_number_verified', item.Attributes));
            this.item.userStatus = item.UserStatus;
            this.item.roleId = this.searchValue('custom:role_id', item.Attributes);
            this.item.enabled = Boolean(item.Enabled);

            let isB2C = !this.item.roleId;
            if (isB2C) {
                this.clients = this.clients.filter(client => client.type === 1);
            } else {
                this.clients = this.clients.filter(client => client.type === 0);
            }


        },

        customFormatDate(userCreateDate) {
            return this.moment(userCreateDate).format('DD/MM/YYYY HH:MM');
        },


        convertDataModel() {

            var newItem = {};
            newItem.sub = this.item.sub;
            newItem.email = this.item.email.trim();
            newItem.name = this.item.name ? this.item.name.trim() : null;
            newItem.phoneNumber = this.item.phoneNumber ? "+" + this.item.phoneNumber : null;
            newItem.givenName = String(this.item.clientId);
            if (this.item.roleId) {
                newItem.rolId = String(this.item.roleId);
            }
            newItem.enabled = Number(this.item.enabled);
            newItem.groupName = "Admin";
            return newItem;

        },

        newItem() {
            this.creating = true;
            this.editing = false;
        },


        editItem(sub) {
            this.creating = false;
            this.editing = true;
            this.fetchItem(sub);
        },


        async fetchItem(sub) {
            this.loading = true;
            let response = await getUser(sub);
            if (response) {
                this.assignDataModel(response);
            }
            this.loading = false;
        },

        /// <summary>
        /// Obtener el valor de una coleccion pasandole el nombre de la clave
        /// </summary>
        /// <param name="nameKey">Valor name a buscar</param>
        /// <param name="myArray">Valor coleccion donde buscar</param>
        searchValue(nameKey, myArray) {
            for (var i = 0; i < myArray.length; i++) {
                if (myArray[i].Name === nameKey) {
                    return myArray[i].Value;
                }
            }
        },

        async fillClients() {
            this.loading = true;
            const response = await getClients(null, null, "");
            if (response) {
                response.map(item => {
                    let b = {
                        value: item.id,
                        clientId: item.clientId,
                        text: item.name,
                        type: item.type,
                    };
                    this.clients.push(b);
                }
                );

            } else {
                this.clients = [];
            }
            this.loading = false;
        },

        checkValues() {

            this.errorRoleMessage = "";

            if (this.item.roleId == "0") {
                this.errorRoleMessage = this.$t('validation.at_least_one_value', { _field_: this.$t('label.user_rolId') });
            }


        },

        async fillUserRole() {
            this.loading = true;
            let z = { value: "0", text: "None" };
            this.userRoles.push(z);
            let userRoles = await getUserRole();


            if (userRoles) {

                userRoles.map(item => {
                    let b = {
                        value: item.code,
                        text: item.description,
                    };
                    // Add only B2B Manager & B2B Operator Roles
                    if (item.code == "6" || item.code == "7") {
                        this.userRoles.push(b);
                    }
                });

            } else {
                this.userRoles = [];
            }
            this.loading = false;
        },
    }
}


</script>

<style scoped></style>