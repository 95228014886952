<template>
    <div>
        <md-dialog  :md-close-on-esc="false" :md-active.sync="show" :md-click-outside-to-close="false"  class="modal-media">
            <md-dialog-title class="modal-media-header">
                <h3>{{ $t("multimedia") }}</h3>
                <md-button
                    @click="closeModal()"
                    class="
                        md-primary md-just-icon md-round md-simple
                        modal-close-btn
                    "
                >
                    <md-icon class="md-size-2x">close</md-icon>
                </md-button>
            </md-dialog-title>
            <md-dialog-content>
                <MediaData 
                    @change="selectMedia" 
                    @creating="creating = true"
                    @editing="editing = true"
                    @cancel="cancel()"/>
            </md-dialog-content>
            <md-dialog-actions v-if="!creating && !editing">
                <md-button @click="closeModal()" class="md-danger md-round">
                    {{ $t("cancel") }}
                </md-button>
                <md-button @click="applyMedia()" class="md-primary md-round">
                    {{ $t("apply") }}
                </md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>

<script>
import MediaData from "@/components/Media/Media";

export default {
    props: ['showProp'],
    components:{
        MediaData,
    },
    data() {
        return {
            show: false,
            creating: false,
            editing: false,
            selectedMedia: null,
        };
    },

    watch: {
        showProp() {
            this.show = this.showProp;
        },
    },

    methods: {
        applyMedia() {
            this.$emit("apply", this.selectedMedia);
            this.closeModal();
        },

        closeModal() {
            this.show = false;
            this.$emit("close");
        },

        cancel() {
            this.creating = false;
            this.editing = false;
        },

        selectMedia(media) {
            this.selectedMedia = media;
        }

    },
};
</script>

<style>
.modal-media-header {
    width: 100%;
    position: relative;
}
.modal-media-header .modal-close-btn {
    position: absolute;
    top: 0;
    right: 0;
}

.modal-media .modal-container {
    max-width: 1000px !important;
}
</style>