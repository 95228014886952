const localStorageKey = 'dalamanCMSAppStorage';

class StateManager {
  constructor() {
    // Estado inicial
    this.state = {
      bookingFilters: {
        fromEntryDateTime: null,
        toEntryDateTime: null,
        fromExitDateTime: null,
        toExitDateTime: null,
        reference: '',
        clientEmail: '',
        clientName: '',
        flightDirection: null,
        ancillaryId: null,
        airlineCode: null,
        status: null,
        clientId: null,      
      },
      Filters: [],
      search: '',
    };

    // Cargar estado almacenado en localStorage
    this.loadState();
  }

  // Método para guardar el estado en localStorage
  saveState() {
    localStorage.setItem(localStorageKey, JSON.stringify(this.state));
  }

  // Método para cargar el estado desde localStorage
  loadState() {
    const storedState = localStorage.getItem(localStorageKey);
    if (storedState) {
      try {
        const parsedState = JSON.parse(storedState);
        // Actualizar solo las propiedades existentes en el estado actual
        this.state = parsedState;
      } catch (error) {
        console.error('Error parsing stored state:', error);
      }
    }
  }

  // Método para actualizar todos los filtros
  setFiltersData(filtersData) {
    this.state = filtersData; // Asignar el objeto completo de state recibido
    this.saveState();
  }

  // Getter para obtener todos los filtros
  getFiltersData() {
    return this.state;
  }

}

// Crear una instancia de StateManager
const stateManager = new StateManager();

export default stateManager;
