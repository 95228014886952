import DropDown from './components/Dropdown.vue'
import Collapse from './components/Collapse.vue'
import Pagination from './components/Pagination.vue'
import Modal from './components/Modal.vue'

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install (Vue) {
    Vue.component('drop-down', DropDown),
    Vue.component('md-collapse', Collapse),
    Vue.component('md-pagination', Pagination),
    Vue.component('md-modal', Modal)
  }
}

export default GlobalComponents
