<template>
    <div>
        <md-dialog :md-close-on-esc="false" :md-active.sync="show" :md-click-outside-to-close="false"
            class="modal-company">
            <md-dialog-title class="modal-media-header">
                <h3>
                    <span v-if="creating">
                        {{ $t("new_company") }}
                    </span>
                    <span v-else-if="editing">
                        {{ $t("company") }} - {{ item.name }}
                    </span>
                    <span v-else>
                        {{ activityName }} - {{ $t("companies") }}
                    </span>
                    <md-progress-spinner v-if="loading" :md-diameter="20" :md-stroke="1" md-mode="indeterminate">
                    </md-progress-spinner>
                </h3>
                <md-button @click="closeModal()" class="
                        md-primary md-just-icon md-round md-simple
                        modal-close-btn
                    ">
                    <md-icon class="md-size-2x">close</md-icon>
                </md-button>
            </md-dialog-title>

            <md-dialog-content>
                <!-- Company List -->
                <div v-if="!creating && !editing">
                    <div class="md-layout-item md-size-100 inline-toolbar">
                        <md-field class="search-input">
                            <md-icon class="search-icon">image_search</md-icon>
                            <label>{{ $t("label.search_company") }}</label>
                            <md-input v-model="search"  @keyup.enter="filter()" ></md-input>
                            <md-button v-if="search" class="btn-floating" @click="clearFilter()">
                                <md-icon>close</md-icon>
                            </md-button>
                        </md-field>
                        <md-button class="md-primary md-round btn-big" @click="filter()">
                            {{ $t("search") }}
                        </md-button>
                        <md-button @click="newItem()" class="md-primary md-round btn-big">
                            {{ $t("new") }}
                        </md-button>
                    </div>
                    <!-- Empty table -->
                    <div v-if="Items.length == 0" table-header-color="blue"
                        class="md-content md-table md-theme-default">
                        <md-table>
                            <thead>
                                <tr><!---->
                                    <th class="md-table-head md-sortable md-sorted md-sorted-desc">
                                        <div class="md-table-head-container md-ripple">
                                            <div class="md-table-head-label">
                                                {{ $t('label.company_name') }}
                                                <!---->
                                            </div>
                                        </div>
                                    </th>
                                    <th class="md-table-head">
                                        <div class="md-table-head-container md-ripple md-disabled">
                                            <div class="md-table-head-label"><!---->
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="md-table-row">
                                    <td class="md-table-cell" style="width: 100%;">
                                        {{ $t('label.no_records') }}
                                    </td>
                                    <td class="md-table-cell" style="width: 100%;">
                                    </td>
                                </tr>
                            </tbody>
                        </md-table>
                    </div>
                    <md-table v-else table-header-color="blue" v-model="Items"
                        :md-sort-order.sync="currentSortOrder" :md-sort.sync="currentSort" :md-sort-fn="customSort">
                        <md-table-row slot="md-table-row" slot-scope="{ item }">
                            <md-table-cell md-sort-by="name" :md-label="$t('label.product_name')">
                                {{ item.name }}
                            </md-table-cell>
                            <md-table-cell class="table-cell-actions">
                                <button class="btn-icon" @click="editItem(item.activityCompanyId)">
                                    <md-icon>edit</md-icon>
                                </button>
                                <button class="btn-icon" @click="confirmDelete(item)">
                                    <md-icon>delete</md-icon>
                                </button>
                            </md-table-cell>
                        </md-table-row>
                    </md-table>
                    <!-- Pagination -->
                    <div class="pagination-container">
                        <md-pagination v-model="page" :noArrows="false" :perPage="limit" :total="total" />
                    </div>
                    <md-modal v-if="showConfirm" @close="showConfirm = false">
                        <h3 slot="header">
                            {{ $t("confirm") }}
                        </h3>
                        <div slot="body">
                            {{ $t("confirm_messages.delete") }}
                        </div>
                        <div slot="footer" class="modal-footer">
                            <md-button @click="showConfirm = false" class="md-danger md-round">
                                {{ $t("cancel") }}
                            </md-button>
                            <md-button @click="deleteItem()" class="md-primary md-round">
                                {{ $t("delete") }}
                            </md-button>
                        </div>
                    </md-modal>
                </div>
                <!-- Company Detail -->
                <div v-else>
                    <ValidationObserver tag="form" ref="form" v-slot="{ errors }">
                        <div class="md-layout form-layout">
                            <div class="md-layout-item md-size-50">
                                <!-- Company Name -->
                                <md-field :class="[
                                    { 'md-valid': errors['company_name'] && !errors['company_name'][0] && touchedFields.company_name },
                                    { 'md-error': errors['company_name'] && errors['company_name'][0] }
                                ]">
                                    <label for="company_name">{{
                                            $t("label.company_name")
                                    }}</label>
                                    <validation-provider name="company_name" rules="required"
                                        v-slot="{ errors, touched }" mode="aggressive">
                                        <md-input v-model="item.name" name="company_name"
                                            type="text" id="company_name"
                                            :disabled="loading || view"></md-input>
                                        <slide-y-down-transition>
                                            <md-icon class="error"
                                                v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                                <!-- Company Website -->
                                <md-field :class="[
                                    { 'md-valid': errors['company_website'] && !errors['company_website'][0] && touchedFields.company_website },
                                    { 'md-error': errors['company_website'] && errors['company_website'][0] }
                                ]">
                                    <label for="company_website">{{
                                            $t("label.company_website")
                                    }}</label>
                                    <validation-provider name="company_website" rules="required"
                                        v-slot="{ errors, touched }" mode="aggressive">
                                        <md-input v-model="item.website" name="company_website"
                                            type="text" id="company_website"
                                            :disabled="loading || view"></md-input>
                                        <slide-y-down-transition>
                                            <md-icon class="error"
                                                v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                                <!-- Company Phone -->
                                <md-field :class="[
                                    { 'md-valid': errors['company_phone'] && !errors['company_phone'][0] && touchedFields.company_phone },
                                    { 'md-error': errors['company_phone'] && errors['company_phone'][0] }
                                ]">
                                    <label for="company_phone">{{
                                            $t("label.company_phone")
                                    }}</label>
                                    <validation-provider name="company_phone" rules="required"
                                        v-slot="{ errors, touched }" mode="aggressive">
                                        <md-input v-model="item.phoneNumber" name="company_phone"
                                            type="text" id="company_phone"
                                            :disabled="loading || view"></md-input>
                                        <slide-y-down-transition>
                                            <md-icon class="error"
                                                v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                                <!-- Company Email -->
                                <md-field :class="[
                                    { 'md-valid': errors['company_email'] && !errors['company_email'][0] && touchedFields.company_email },
                                    { 'md-error': errors['company_email'] && errors['company_email'][0] }
                                ]">
                                    <label for="company_email">{{
                                            $t("label.company_email")
                                    }}</label>
                                    <validation-provider name="company_email" rules="required"
                                        v-slot="{ errors, touched }" mode="aggressive">
                                        <md-input v-model="item.email" name="company_email"
                                            type="text" id="company_email"
                                            :disabled="loading || view"></md-input>
                                        <slide-y-down-transition>
                                            <md-icon class="error"
                                                v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-size-50">
                                <div class="form-container">
                                    <!-- Media selector -->
                                    <div class="md-layout">
                                        <div>
                                            <md-icon class="md-42">iconimage_search</md-icon>
                                        </div>
                                        <div class="md-layout-item md-size-60">
                                            <md-field class="image-field">
                                                <label class="label-images" v-if="item.fileName == ''">
                                                    {{ $t("label.company_logo") }}
                                                </label>
                                                <label v-if="item.fileName == ''">
                                                    {{ $t("label.select_image") }}
                                                </label>
                                                <label v-else>
                                                    {{ $t("label.company_logo") }}
                                                </label>
                                                <md-input readonly id="mediaInput" v-model="item.fileName"
                                                    :disabled="true">
                                                </md-input>
                                                <md-button v-if="item.fileName != ''" class="btn-floating"
                                                    @click="clearMedia()">
                                                    <md-icon>close</md-icon>
                                                </md-button>
                                                <span class="md-error">
                                                    {{ errorMediaMessage }}
                                                </span>
                                            </md-field>
                                        </div>
                                        <div class="md-layout-item md-size-20">
                                            <md-button @click="openMediaModal()" class="md-primary md-round">
                                                {{ $t("select_file") }}
                                            </md-button>
                                        </div>
                                    </div>

                                    <!-- Media preview -->
                                    <div>
                                        <div class="media-container">
                                            <md-icon v-if="item.imageURL == ''" class="md-size-5x">image</md-icon>
                                            <img v-else :src="item.imageURL" :alt="item.imageAlt" @click.prevent="viewImage(item.imageURL)" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer mt-25">
                            <md-button @click.prevent="cancel()" class="md-danger md-round" :disabled="loading">
                                {{ $t("cancel") }}
                            </md-button>
                            <md-button v-if="!view" @click.prevent="save()" type="submit" class="md-primary md-round"
                                :disabled="loading">
                                {{ $t("save") }}
                            </md-button>
                        </div>
                    </ValidationObserver>
                </div>
                <!-- Media Modal -->
                <media-modal :showProp="showMediaModal" @apply="applyMedia" @close="closeMediaModal()"></media-modal>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import MediaModal from "@/components/Modals/Media";
import paginationMixin from "@/mixins/pagination";
import {
    getActivityCompanies,
    getActivityCompany,
    createActivityCompany,
    updateActivityCompany,
    deleteActivityCompany,
} from "@/services/companies";

export default {
    props: ["showProp", "activityId", "activityName"],
    mixins: [paginationMixin],
    components: {
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
        MediaModal,
    },
    data() {
        return {
            loading: false,
            show: false,
            view: false,
            creating: false,
            editing: false,
            showConfirm: false,
            touchedFields: {
                company_name: false,
                company_website: false,
                company_phone: false,
                company_email: false,
            },
            Items: [],
            item: {
                activityCompanyId: null,
                name: "",
                website: "",
                phoneNumber: "",
                email: "",
                mediaId: null,
                imageURL: "",
                imagelAlt: "",
                fileName: "",
            },
            ItemIdToDelete: null,
            search: "",
            currentSort: "name",
            currentSortOrder: "asc",
            showMediaModal: false,
            errorMediaMessage: "",
            imagesLightbox: [],
        };
    },

    watch: {
        page() {
            this.fetchItems();
        },
        showProp() {
            this.show = this.showProp;
            if (this.show) {
                this.clearFilter();
                this.clearData();
                this.editing = false;
                this.creating = false;
                this.view = true;
                this.fetchItems();
            }
        },
    },

    methods: {

        applyMedia(media) {
            this.item.mediaId = media.mediaId;
            this.item.imageURL = media.imageURL;
            this.item.fileName = media.fileName;
            this.errorMediaMessage = "";
        },

        cancel() {
            this.view = false;
            this.editing = false;
            this.creating = false;
        },

        closeModal() {
            this.show = false;
            this.$emit("close");
        },

        openMediaModal() {
            this.showMediaModal = true;
        },

        closeMediaModal() {
            this.showMediaModal = false;
        },

        clearData() {
            this.item = {
                activityCompanyId: null,
                name: "",
                website: "",
                phoneNumber: "",
                email: "",
                mediaId: null,
                imageURL: "",
                imagelAlt: "",
                fileName: "",
            };
        },

        filter() {
            this.fetchItems();
        },

        clearFilter() {
            this.search = "";
            this.fetchItems();
        },

        newItem() {
            this.clearData();
            this.creating = true;
            this.editing = false;
            this.view = false;
        },

        editItem(itemId) {
            this.editing = true;
            this.creating = false;
            this.view = false;
            this.clearData();
            this.fetchItem(itemId);
        },

        confirmDelete(Item) {
            this.ItemIdToDelete = Item.activityCompanyId;
            this.showConfirm = true;
            console.log("delete item id", this.ItemIdToDelete);
        },

        customSort: function customSort(value) {
            this.fetchItems();
            return value;
        },

        viewImage(imageUrl) {
            this.view = true;
            this.imagesLightbox = [];
            this.$imageViewer.images(this.imagesLightbox);
            const image = {
                url: imageUrl,
            };
            this.imagesLightbox.push(image);
            this.$imageViewer.index(0);
            this.$imageViewer.show();
        },

        async fetchItems() {
            this.loading = true;
            const response = await getActivityCompanies(this.activityId, this.page, this.limit, this.search);
            if (response) {
                this.Items = response.result;
                this.total = response.pageInfo.totalPages * this.limit;
            } else {
                this.Items = [];
                this.total = 0;
            }
            this.loading = false;
        },

        async fetchItem(itemId) {
            this.loading = true;
            const response = await getActivityCompany(this.activityId, itemId);
            if (response) {
                this.item = response;
            }
            this.loading = false;
        },

        async deleteItem() {
            this.loading = true;
            let response = await deleteActivityCompany(this.activityId, this.ItemIdToDelete);
            if (response.status == "200") {
                this.showConfirm = false;
                this.ItemIdToDelete = null;
                this.$noty.success(this.$t('success.deleted'));
                this.fetchItems();
            } else {
                this.$noty.error(this.$t('error.deleted'));
            }
            this.loading = false;
        },

        async save() {
            this.loading = true;
            var valid = await this.$refs.form.validate();

            if (this.item.mediaId == null) {
                valid = false;
                this.errorMediaMessage = this.$t('validation.required', { _field_: this.$t('label.company_logo') });
            }

            if (valid) {
                if (this.creating) {
                    const response = await createActivityCompany(this.activityId, this.item);
                    if (response) {
                        this.$noty.success(this.$t('success.saved'));
                        this.creating = false;
                        this.editing = false;
                        this.fetchItems();
                    } else {
                        this.$noty.error(this.$t('error.saved'));
                    }
                }
                if (this.editing) {
                    const response = await updateActivityCompany(this.activityId, this.item);
                    if (response) {
                        this.$noty.success(this.$t('success.saved'));
                    } else {
                        this.$noty.error(this.$t('error.saved'));
                    }
                }
                
            } else {
                this.$noty.error(this.$t('error.saved'));
            }
            this.loading = false;
        },
    },
}
</script>

<style scoped>
.form-container {
    padding-left: 25px;
    padding-right: 25px;
}
</style>