import { extend, configure, setInteractionMode } from "vee-validate";
import { required, email, regex, between, min, max, confirmed, length, double, size, numeric } from "vee-validate/dist/rules";
import i18n from './i18n';

configure({
    defaultMessage: (field, values) => {
        // override the field name.
        values._field_ = i18n.t(`fields.${field}`);

        return i18n.t(`validation.${values._rule_}`, values);
    }
});

// Install required rules and messages.
extend("required", required);

extend("email", email);

extend("regex", regex);

extend("between", between);

extend("min", min);

extend("max", max);

extend("confirmed", confirmed);

extend("length", length);

extend("double", double);

extend("numeric", numeric);

extend("size", size);

extend('complex_password', {
    validate: (value/*, args*/) => {
        //var tester = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{6,10}$/;
        var tester = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d$@$!%*?&/.]{6,10}$/;
        var valid = tester.test(String(value));
        return valid;
    }
});

extend('accepted', {
    validate: (value/*, args*/) => {
        return value;
    }
});

extend('custom', {
    validate: (/*value, args*/) => {
        return true;
    }
});

extend('minQuantity', {
    validate: (value) => {
      // Check if the value is a number (double) and greater than or equal to 1
      return !isNaN(value) && parseFloat(value) >= 1;
    },
  });

extend('nameAndSurname', {
    validate: (value/*, args*/) => {
        const valueSplitted = value.split(' ');
        if (valueSplitted.length >= 2) {
            const surname = valueSplitted[1];
            return surname ? true : false;
        } else {
            return false;
        }
    }
});



setInteractionMode('passive');