<template>
    <div>
        <md-dialog :md-close-on-esc="false" :md-click-outside-to-close="false" :md-active.sync="show"
            class="modal-category">
            <md-dialog-title class="modal-category-header">
                <div class="md-layout-item md-size-100 form-toolbar">
                    <h3 v-if="parentName == ''">{{ $t("categories") }}</h3>
                    <h3 v-if="parentName != ''">{{ this.parentName }} - {{ $t("categories") }}</h3>
                    <md-progress-spinner class="spinner-load" v-if="loading" :md-diameter="30" :md-stroke="3"
                        md-mode="indeterminate">
                    </md-progress-spinner>
                </div>
                <md-button @click="applyCategory()" class="
                        md-primary md-just-icon md-round md-simple
                        modal-close-btn
                    ">
                    <md-icon class="md-size-2x">close</md-icon>
                </md-button>
            </md-dialog-title>
            <md-dialog-content>
                <!-- Category List -->
                <div class="content-div" v-if="!creating && !editing">
                    <div class="inline-toolbar">
                        <md-field class="search-input">
                            <md-icon class="search-icon">image_search</md-icon>
                            <label>{{ $t("label.search_categoryname") }}</label>
                            <md-input v-model="search"  @keyup.enter="filter()" ></md-input>
                            <md-button v-if="search" class="btn-floating" @click="clearFilter()">
                                <md-icon>close</md-icon>
                            </md-button>
                        </md-field>
                        <md-button class="md-primary md-round" @click="filter()">
                            {{ $t("search") }}
                        </md-button>
                        <md-button @click="newItem()" class="md-primary md-round">
                            {{ $t("new") }}
                        </md-button>
                    </div>
                    <md-table table-header-color="blue" v-model="categoryList">
                        <md-table-row class="md-table-header" slot="md-table-row" slot-scope="{ item }">
                            <md-table-cell class="select-checkbox">
                                <input :id="item.id" v-model="item.selected" name="item" type="checkbox"
                                    @change="updateSelected($event)" />
                            </md-table-cell>
                            <md-table-cell :md-label="$t('label.category_name')">{{ item.name }}</md-table-cell>
                            <md-table-cell class="table-cell-actions">
                                <button class="btn-icon" @click="editItem(item.categoryId)">
                                    <md-icon>edit</md-icon>
                                </button>
                                <button class="btn-icon" @click="confirmDelete(item)">
                                    <md-icon>delete</md-icon>
                                </button>
                            </md-table-cell>

                        </md-table-row>
                    </md-table>

                    <div class="pagination-container">
                        <md-pagination v-model="page" :noArrows="false" :perPage="limit" :total="total" />
                    </div>
                </div>
                <!-- Category form -->
                <div v-if="creating || editing">
                    <ValidationObserver tag="form" ref="form" v-slot="{ errors }">
                        <div class="md-layout form-layout">
                            <div class="md-layout-item md-size-50">
                                <!-- Separator localizations -->
                                <div class="Group-Header">
                                    <span class="Luggage-information">
                                        {{ $t('label.language_information') }}
                                    </span>
                                    <div class="Path"></div>
                                </div>
                                <!-- Translations -->
                                <md-card class="translations-card">
                                    <md-card-content>
                                        <md-tabs class="translations-tabs">
                                            <md-tab id="tab-tr" :md-label="$t('languages.tr')"
                                                :md-icon="getLanguageIcon('tr', errors)">
                                                <!-- name tr -->
                                                <md-field :class="[
                                                    { 'md-valid': errors['name_tr'] && !errors['name_tr'][0] && touchedFields.name_tr },
                                                    { 'md-error': errors['name_tr'] && errors['name_tr'][0] }
                                                ]">
                                                    <label for="name_tr">{{
                                                            $t("fields.name")
                                                    }}</label>
                                                    <validation-provider name="name_tr" rules="required"
                                                        v-slot="{ errors, touched }" mode="aggressive">
                                                        <md-input v-model="item.name_tr" name="name_tr" type="text"
                                                            id="name_tr" :disabled="loading || view">
                                                        </md-input>
                                                        <slide-y-down-transition>
                                                            <md-icon class="error" v-show="errors[0] && touched">close
                                                            </md-icon>
                                                        </slide-y-down-transition>
                                                        <span class="md-error">{{ errors[0] }}</span>
                                                    </validation-provider>
                                                </md-field>
                                            </md-tab>
                                            <md-tab id="tab-en" :md-label="$t('languages.en')"
                                                :md-icon="getLanguageIcon('en', errors)">
                                                <!-- Alternative Text EN -->
                                                <md-field :class="[
                                                    { 'md-valid': errors['name_en'] && !errors['name_en'][0] && touchedFields.name_en },
                                                    { 'md-error': errors['name_en'] && errors['name_en'][0] }
                                                ]">
                                                    <label for="name_en">{{
                                                            $t("fields.name")
                                                    }}</label>
                                                    <validation-provider name="name_en" rules="required"
                                                        v-slot="{ errors, touched }" mode="aggressive">
                                                        <md-input v-model="item.name_en" name="name_en" type="text"
                                                            id="name_en" :disabled="loading || view">
                                                        </md-input>
                                                        <slide-y-down-transition>
                                                            <md-icon class="error" v-show="errors[0] && touched">close
                                                            </md-icon>
                                                        </slide-y-down-transition>
                                                        <span class="md-error">{{ errors[0] }}</span>
                                                    </validation-provider>
                                                </md-field>
                                            </md-tab>
                                        </md-tabs>
                                    </md-card-content>
                                </md-card>
                            </div>
                            <div class="md-layout-item md-size-50">
                                <!-- media id -->
                                <div class="md-layout" style="margin-left:40px">
                                    <div>
                                        <md-icon class="md-42">iconimage_search</md-icon>
                                    </div>
                                    <div class="md-layout-item md-size-65">
                                        <md-field class="image-field">
                                            <label class="label-images" v-if="item.media.fileName == ''">{{
                                                    $t("label.category_image")
                                            }}</label>
                                            <label v-if="item.media.fileName == ''">{{ $t("label.select_image")
                                            }}</label>
                                            <label v-else>{{ $t("label.category_image") }}</label>
                                            <md-input readonly id="mediaInput" v-model="item.media.fileName"
                                                :disabled="true">
                                            </md-input>
                                            <md-button v-if="item.media.fileName != ''" class="btn-floating"
                                                @click="clearMedia()">
                                                <md-icon>close</md-icon>
                                            </md-button>
                                            <span class="md-error">
                                                {{ errorMediaMessage }}
                                            </span>
                                        </md-field>
                                    </div>
                                    <div class="md-layout-item md-size-20">
                                        <md-button @click="openMediaModal()" class="md-primary md-round">
                                            {{ $t("select_file") }}
                                        </md-button>
                                    </div>
                                    <!-- media image preview -->
                                    <div class="image-container">
                                        <md-icon v-if="item.media.imageURL == ''" class="md-size-4x">image</md-icon>
                                        <img v-else :src="item.media.imageURL" @click.prevent="viewImage(item.media.imageURL)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <md-button @click.prevent="cancel()" class="md-danger md-round" :disabled="loading">
                                {{ $t("cancel") }}
                            </md-button>
                            <md-button v-if="!view" @click.prevent="save()" type="submit" class="md-primary md-round"
                                :disabled="loading">
                                {{ $t("save") }}
                            </md-button>
                        </div>
                    </ValidationObserver>
                </div>
            </md-dialog-content>
        </md-dialog>
        <!-- Modal Confirm Delete -->
        <md-modal v-if="showConfirm" @close="showConfirm = false">
            <h3 slot="header">
                {{ $t("confirm") }}
            </h3>
            <div slot="body">
                {{ $t("confirm_messages.delete") }}
            </div>
            <div slot="footer" class="modal-footer">
                <md-button @click="showConfirm = false" class="md-danger md-round">
                    {{ $t("cancel") }}
                </md-button>
                <md-button @click="deleteItem()" class="md-primary md-round">
                    {{ $t("delete") }}
                </md-button>
            </div>
        </md-modal>
        <!-- Media Modal -->
        <media-modal :showProp="showMediaModal" @apply="applyMedia" @close="closeMediaModal()"></media-modal>
    </div>
</template>

<script>
import MediaModal from "@/components/Modals/Media";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import {
    getCategories,
    getCategory,
    createCategory,
    updateCategory,
    deleteCategory,
} from "@/services/category";
import paginationMixin from "@/mixins/pagination";
import localeMixin from "@/mixins/locale";
import {
    createCategoryRelationDine,
    deleteCategoryRelationDine,
    createCategoryRelationShop,
    deleteCategoryRelationShop
} from "@/services/category";


export default {
    props: ['showProp', 'dineId', 'shopId', 'parentNameProp', 'selectedItemsProp', 'idProp'],
    mixins: [paginationMixin, localeMixin],
    components: {
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
        MediaModal,
    },
    data() {
        return {
            loading: false,
            show: false,
            showConfirm: false,
            parentId: "",
            imagesLightbox:[],
            id: 0,
            parentName: "",
            touchedFields: {
                name_tr: false,
                name_en: false,
            },
            categoryList: [],
            item: {
                categoryId: "",
                id: 0,
                mediaId: 0,
                imageURL: "",
                fileName: "",
                media: {
                    fileName: "", imageURL: ""
                },
                translationId_en: 0,
                translationId_tr: 0,
                name_tr: "",
                name_en: "",
            },
            errorMediaMessage: "",
            view: false,
            creating: false,
            editing: false,
            search: "",
            selectedCategories: [],
            prevSelectedCategories: [],
            ItemIdToDelete: null,
            categoryType: 0,
            showMediaModal: false
        };
    },

    watch: {
        page() {
            this.fetchItems();
        },
        showProp() {
            this.show = this.showProp;

            if (this.show) {
                this.limit = 5;


                this.parentName = this.parentNameProp;

                if (this.dineId) {
                    this.parentId = this.dineId;
                    this.categoryType = 2;
                } else if (this.shopId) {
                    this.parentId = this.shopId;
                    this.categoryType = 1;
                }

                this.id = this.idProp;


                this.fetchItems();

                this.selectedCategories = this.selectedItemsProp;
                this.prevSelectedCategories = [];
                this.selectedCategories.forEach(item => {
                    this.prevSelectedCategories.push(item);
                });
            }


        },
    },


    methods: {

        viewImage(imageUrl) {
            this.view = true;
            this.imagesLightbox = [];
            this.$imageViewer.images(this.imagesLightbox);
            const image = {
                url: imageUrl,
            };
            this.imagesLightbox.push(image);
            this.$imageViewer.index(0);
            this.$imageViewer.show();
        },

        applyMedia(media) {
            this.item.media = media;
            this.item.mediaId = media.mediaId;
            this.item.imageURL = media.imageURL;
            this.item.fileName = media.fileName;
            this.errorMediaMessage = "";
        },

        openMediaModal() {
            this.showMediaModal = true;
        },

        closeMediaModal() {
            this.showMediaModal = false;
            if (this.item.mediaId == 0) {
                this.errorMediaMessage = this.$t('validation.required', { _field_: this.$t('label.airline_image') });
            }
        },

        getSelected(id) {
            var categoryfind = this.selectedCategories.filter(t => t.categoryId == id)[0];
            if (categoryfind) {
                return true;
            } else {
                return false;
            }
        },

        async updateSelected(e) {
            let id = e.target.id.toString();
            let isChecked = e.target.checked;
            if (isChecked) {
                let categoryfound = this.categoryList.filter(t => t.id == id)[0];
                categoryfound.selected = true;
                await this.sendCategoryRelation(id, true);
                if (categoryfound) {
                    this.selectedCategories.push(categoryfound);
                }
            } else {
                let categoryfound = this.selectedCategories.filter(t => t.id == id)[0];
                categoryfound.selected = false;
                await this.sendCategoryRelation(id, false);
                if (categoryfound) {
                    this.selectedCategories.splice(this.selectedCategories.indexOf(categoryfound), 2);
                }
            }
        },


        async fetchItems() {
            this.loading = true;
            const response = await getCategories(this.page, this.limit, this.search, this.categoryType);
            if (response) {
                response.result.forEach(item => {
                    item.selected = this.getSelected(item.categoryId);
                });
                this.categoryList = response.result;
                this.total = response.pageInfo.totalPages * this.limit;
            } else {
                this.categoryList = [];
                this.total = 0;
            }
            this.loading = false;
        },

        async fetchItem(itemId) {
            this.loading = true;
            let response = await getCategory(itemId, this.categoryType);
            if (response) {
                this.assignDataModel(response);
            }
            this.loading = false;
        },


        async filter() {
            this.fetchItems();
        },

        clearFilter() {
            this.search = "";
            this.fetchItems();
        },

        newItem() {
            this.clearData();
            this.creating = true;
            this.editing = false;
        },

        editItem(itemId) {
            this.creating = false;
            this.clearData();
            this.editing = true;
            this.fetchItem(itemId);
        },


        applyCategory() {
            this.$emit("apply", this.selectedCategories);
            this.selectedCategories = [];
            this.prevSelectedCategories = [];
            this.show = false;
            this.$emit("close");
        },

        async sendCategoryRelation(id, selected) {

            this.loading = true;

            if (this.categoryType == 2) {
                if (selected == true) {
                    await createCategoryRelationDine(Number(id), this.id);
                } else if (selected == false) {
                    await deleteCategoryRelationDine(Number(id), this.id);
                }
            }

            if (this.categoryType == 1) {
                if (selected == true) {
                    await  createCategoryRelationShop(Number(id), this.id);
                } else if (selected == false) {
                    await  deleteCategoryRelationShop(Number(id), this.id);
                }

            }
            
            this.loading = false;
        },


        clearMedia() {

            this.item.media = { fileName: "", imageURL: "" };
            this.item.mediaId = 0;
            this.item.imageURL = this.item.media.imageURL;
            this.item.fileName = this.item.media.fileName;
        },


        closeModal() {
            this.$emit("apply", this.prevSelectedCategories);
            this.selectedCategories = [];
            this.prevSelectedCategories = [];
            this.show = false;
            this.$emit("close");
        },

        cancel() {
            this.view = false;
            this.editing = false;
            this.creating = false;
        },

        clearData() {
            this.item.categoryId = "";
            this.item.mediaId = 0;
            this.item.imageURL = "";
            this.item.fileName = "";
            this.item.media.fileName = "";
            this.item.media.imageURL = "";
            this.item.name_tr = "";
            this.item.name_en = "";
            this.errorMediaMessage = "";
        },

        async save() {

            this.errorMediaMessage = "";

            var valid = await this.$refs.form.validate();


            if (this.item.mediaId == 0) {
                valid = false;
                this.errorMediaMessage = this.$t('validation.required', { _field_: this.$t('label.category_image') });
            }

            if (!valid) {
                this.$noty.error(this.$t('error.saved'));
            }

            if (valid) {
                this.loading = true;
                if (this.creating) {
                    let response = await createCategory(this.convertDataModel(), this.categoryType);
                    if (response.status == "201") {
                        this.$noty.success(this.$t('success.saved'));
                        this.cancel();

                    } else {
                        this.$noty.error(this.$t("error.saved"));
                    }
                }
                if (this.editing) {
                    let response = await updateCategory(this.convertDataModel(), this.categoryType);
                    if (response.status == "200") {
                        this.$noty.success(this.$t('success.saved'));
                        this.cancel();
                    } else {
                        this.$noty.error(this.$t("error.saved"));
                    }

                }
            }
            this.loading = false;
        },


        confirmDelete(Item) {
            this.ItemIdToDelete = Item.categoryId;
            this.showConfirm = true;
        },

        async deleteItem() {
            this.loading = true;
            let response = await deleteCategory(this.ItemIdToDelete, this.categoryType);
            if (response) {
                this.$noty.success(this.$t("success.item_deleted"));
                this.showConfirm = false;
                this.ItemIdToDelete = "";
                this.fetchItems();
            }
            this.loading = false;
        },

        assignDataModel(item) {

            if (item.categoryId) {
                this.item.categoryId = item.categoryId;
            } else {
                this.item.categoryId = "";
            }

            if (item.mediaId) {
                this.item.mediaId = item.mediaId;
            } else {
                this.item.mediaId = 0;
            }

            if (item.fileName) {
                this.item.media.fileName = item.fileName;
            } else {
                this.item.media.fileName = "";
            }

            if (item.imageURL) {
                this.item.media.imageURL = item.imageURL;
            } else {
                this.item.media.imageURL = "";
            }

            var translation = item.translations.filter(t => t.locale == 'en')[0];

            if (translation) {


                if (translation.translationId) {
                    this.item.translationId_en = translation.translationId;
                } else {
                    this.item.translationId_en = 0;
                }

                if (translation.name) {
                    this.item.name_en = translation.name;
                } else {
                    this.item.name_en = "";
                }

            } else {

                this.item.translationId_en = 0;
                this.item.name_en = "";
            }


            translation = item.translations.filter(t => t.locale == 'tr')[0];

            if (translation) {


                if (translation.translationId) {
                    this.item.translationId_tr = translation.translationId;
                } else {
                    this.item.translationId_tr = 0;
                }

                if (translation.name) {
                    this.item.name_tr = translation.name;
                } else {
                    this.item.name_tr = "";
                }


            } else {

                this.item.translationId_tr = 0;
                this.item.name_tr = "";

            }
        },

        convertDataModel() {

            var newItem = {};
            newItem.categoryId = this.item.categoryId;
            newItem.mediaId = this.item.mediaId;
            newItem.translations = [];
            var trans_item_tr = {};
            trans_item_tr.translationId = this.item.translationId_tr;
            trans_item_tr.name = this.item.name_tr;
            trans_item_tr.locale = "tr";
            newItem.translations.push(trans_item_tr);
            var trans_item_en = {};
            trans_item_en.translationId = this.item.translationId_en;
            trans_item_en.name = this.item.name_en;
            trans_item_en.locale = "en";
            newItem.translations.push(trans_item_en);
            return newItem;

        }
    }

};
</script>

<style scope>
.modal-category-header {
    width: 100%;
    position: relative;
}

.spinner-load {
    margin-left: 12px;
    margin-top: 12px;
}

.modal-category-header .modal-close-btn {
    position: absolute;
    top: 0;
    right: 0;
}

.modal-category .modal-container {
    max-width: 1000px !important;
}

.category-table .md-table-cell:not(.table-cell-actions) {
    text-align: left;
}

.category-checkbox .md-checkbox {
    margin: 0;
}

.form-text-info {
    text-align: left;
    font-weight: normal;
}

.gapsm {
    gap: 25px;
}

.image-container {
    display: flex;
    float: left;
    justify-content: center;
    padding: 0px;
    width: 132px;
    max-width: 132px;
    max-height: 132px;
    min-height: 132px;
    margin-left: 220px;
    margin-bottom: 48px;
    background-color: var(--media-background);
    border: solid 1px var(--media-border);
}

.image-container img {
    width: 132px;
    height: 132px;
    object-fit: none;
    cursor:pointer;
}
</style>