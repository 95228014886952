<template>
    <ValidationObserver tag="form" ref="form" v-slot="{ errors }">
        <div id="Main" class="md-layout">
            <!-- Button -->
            <div class="md-layout-item md-size-100 form-toolbar">
                <h3 class="page-title">
                    <span v-if="creating">
                        {{ $t("routes.service_create.title") }}
                    </span>
                    <span v-if="editing">
                        {{ $t("routes.service_edit.title") }} {{ item.name }}
                    </span>
                </h3>
                <div class="form-buttons">
                    <md-progress-spinner v-if="loading" :md-diameter="30" :md-stroke="3" md-mode="indeterminate">
                    </md-progress-spinner>
                    <!-- Save button -->
                    <md-button class="md-primary md-round btn-medium" @click="save()">
                        {{ $t("save") }}
                    </md-button>
                </div>
            </div>
            <!-- Booking  type -->
            <div class="md-layout-item md-size-40">
                <md-field>
                    <md-select v-model="item.bookingType" :disabled="loading" :placeholder="$t('label.service_bookingType')"
                        @md-selected="checkValues()">
                        <md-option :value="0">
                            {{ $t("label.service_bookingType_value_default") }}
                        </md-option>
                        <md-option :value="1">
                            {{ $t("label.service_bookingType_value_0") }}
                        </md-option>
                        <md-option :value="2">
                            {{ $t("label.service_bookingType_value_1") }}
                        </md-option>
                        <md-option :value="3">
                            {{ $t("label.service_bookingType_value_2") }}
                        </md-option>
                        <md-option :value="4">
                            {{ $t("label.service_bookingType_value_3") }}
                        </md-option>
                        <md-option :value="5">
                            {{ $t("label.service_bookingType_value_4") }}
                        </md-option>
                    </md-select>
                    <span class="md-error">
                        {{ errorBookingTypeMessage }}
                    </span>
                </md-field>

            </div>
            <div class="md-layout-item md-size-10"></div>
            <!-- Order -->
            <div class="md-layout-item md-size-40">
                <md-field>
                    <md-select v-model="item.order" :disabled="loading" :placeholder="$t('label.dine_order')">
                        <md-option v-for="(order, index) in orders" :key="index" :value="order.value">
                            {{ order.text }}
                        </md-option>
                    </md-select>
                    <span class="md-error">
                        {{ errorOrderMessage }}
                    </span>
                </md-field>
            </div>
            <!-- Url -->
            <div class="md-layout-item md-size-40">
                <md-field :class="[
                    { 'md-valid': errors['actionURLl'] && !errors['actionURL'][0] && touchedFields.actionURL },
                    { 'md-error': errors['actionURL'] && errors['actionURL'][0] }
                ]">
                    <label for="title">{{ $t('label.service_url') }}</label>
                    <validation-provider name='actionURL' :rules="item.bookingType == 2 ? 'required' : ''"
                        v-slot="{ errors, touched }" mode="aggressive">
                        <md-input v-model="item.actionURL" type="text" :placeholder="$t('label.service_url')"
                            :disabled="loading">
                        </md-input>
                        <slide-y-down-transition>
                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                        </slide-y-down-transition>
                        <span class="md-error">{{ errors[0] }}</span>
                    </validation-provider>
                </md-field>
            </div>
            <div class="md-layout-item md-size-10"></div>
            <!-- Enabled -->
            <div class="md-layout-item md-size-50">
                <div class="field-switch">
                    <Span>{{ $t("label.service_enabled") }}</Span>
                    <md-switch id="switchEnabled" v-model="item.enabled" :disabled="loading">
                    </md-switch>
                </div>
            </div>
            <!-- media id -->
            <div class="md-layout-item md-size-40">
                <div class="md-layout">
                    <div>
                        <md-icon class="md-42">iconimage_search</md-icon>
                    </div>
                    <div class="md-layout-item md-size-70">
                        <md-field>
                            <label>{{ $t("label.service_image") }}</label>
                            <md-input readonly v-model="item.media.fileName">
                            </md-input>
                            <md-button v-if="item.media.fileName != ''" class="btn-floating" @click="clearMedia()">
                                <md-icon>close</md-icon>
                            </md-button>
                            <span class="md-error">
                                {{ errorMediaMessage }}
                            </span>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-20">
                        <md-button @click="openMediaModal()" class="md-primary md-round btn-medium">
                            {{ $t("select_file") }}
                        </md-button>
                    </div>
                </div>
                <!-- media image preview -->
                <div class="md-layout-item md-size-50">
                    <div class="media-container-left">
                        <md-icon v-if="item.media.imageURL == ''" class="md-size-4x">image</md-icon>
                        <img v-else :src="item.media.imageURL" :alt="item.media.imageAlt"
                            @click.prevent="viewImage(item.media.imageURL)" />
                    </div>
                </div>
            </div>
            <div class="md-layout-item md-size-10"></div>
            <!-- Service  type selector -->
            <div class="md-layout-item md-size-50">
                <md-field>
                    <md-select v-model="item.providerTypeCode" :disabled="loading" @md-selected="checkValues()"
                        :placeholder="$t('label.providerType_value_default')">
                        <md-option v-for="(item, idx5) of providerTypes" :value="item.value" :key="idx5">
                            {{ item.text }}
                        </md-option>
                    </md-select>
                    <span class="md-error">
                        {{ errorProviderTypeMessage }}
                    </span>
                </md-field>
                <!-- icon id -->
                <div class="md-layout-item md-size-100">
                    <div class="md-layout">
                        <div>
                            <md-icon class="md-42">iconimage_search</md-icon>
                        </div>
                        <div class="md-layout-item md-size-60">
                            <md-field>
                                <label>{{ $t("label.service_icon") }}</label>
                                <md-input readonly v-model="item.iconMedia.fileName">
                                </md-input>
                                <md-button v-if="item.media.fileName != ''" class="btn-floating" @click="clearIconMedia()">
                                    <md-icon>close</md-icon>
                                </md-button>
                                <span class="md-error">
                                    {{ errorMediaIconMessage }}
                                </span>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-20">
                            <md-button @click="openMediaIconModal()" class="md-primary md-round btn-medium">
                                {{ $t("select_file") }}
                            </md-button>
                        </div>
                    </div>
                    <!-- icon media image preview -->
                    <div class="md-layout-item md-size-70">
                        <div class="media-container-small-left">
                            <md-icon v-if="item.iconMedia.imageURL == ''" class="md-size-4x">image</md-icon>
                            <img v-else :src="item.iconMedia.imageURL"
                                @click.prevent="viewImage(item.iconMedia.imageURL)" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- secondary media id -->
            <div class="md-layout-item md-size-40">
                <div class="md-layout">
                    <div>
                        <md-icon class="md-42">iconimage_search</md-icon>
                    </div>
                    <div class="md-layout-item md-size-70">
                        <md-field>
                            <label>{{ $t("label.service_secondary_image") }}</label>
                            <md-input readonly v-model="item.secondaryMedia.fileName">
                            </md-input>
                            <md-button v-if="item.secondaryMedia.fileName != ''" class="btn-floating"
                                @click="clearSecondaryMedia()">
                                <md-icon>close</md-icon>
                            </md-button>
                            <span class="md-error">
                                {{ errorSecondaryMediaMessage }}
                            </span>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-20">
                        <md-button @click="openSecondaryMediaModal()" class="md-primary md-round btn-medium">
                            {{ $t("select_file") }}
                        </md-button>
                    </div>
                </div>
                <!-- media image preview -->
                <div class="md-layout-item md-size-50">
                    <div class="media-container-left">
                        <md-icon v-if="item.secondaryMedia.imageURL == ''" class="md-size-4x">image</md-icon>
                        <img v-else :src="item.secondaryMedia.imageURL" :alt="item.secondaryMedia.imageAlt"
                            @click.prevent="viewImage(item.secondaryMedia.imageURL)" />
                    </div>
                </div>
            </div>
            <div class="md-layout-item md-size-10"></div>
            <!-- ancillary id -->
            <div class="md-layout-item md-size-45">
                <div class="md-layout">
                    <div class="md-layout-item md-size-65">
                        <md-field class="image-field">
                            <label class="label-images" v-if="!item.ancillary || item.ancillary.name == ''">{{
                                $t("label.service_ancillary") }}</label>
                            <label v-if="!item.ancillary || item.ancillary.name == ''">{{ $t("label.select_ancillary")
                            }}</label>
                            <label v-else>{{ $t("label.service_ancillary") }}</label>
                            <md-input readonly id="mediaInput" v-model="item.ancillary.name" :disabled="true"></md-input>
                            <md-button v-if="item.ancillary && item.ancillary.name != ''" class="btn-floating"
                                @click="clearAncillary()">
                                <md-icon>close</md-icon>
                            </md-button>
                            <span class="md-error">
                                {{ errorAncillaryMessage }}
                            </span>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-20">
                        <md-button @click="openAncillaryDialog()" class="md-primary md-round btn-medium">
                            {{ $t("label.select_ancillary") }}
                        </md-button>
                    </div>
                </div>
            </div>
            <!-- Separator Map information -->
            <div class="md-layout-item md-size-100">
                <div class="Group-Header">
                    <span class="Luggage-information">
                        {{ $t('label.map_information') }}
                    </span>
                    <div class="Path"></div>
                </div>
            </div>
            <div class="md-layout">
                <!-- Building  Floor -->
                <div class="md-layout-item md-size-50">
                    <md-field>
                        <md-select v-model="item.mapBuildingFloor" :disabled="loading"
                            :placeholder="$t('label.mapBuildingFloor')">
                            <md-option :value="-1000">
                                {{ $t("label.mapBuildingFloor_value_default") }}
                            </md-option>
                            <md-option :value="-1">
                                {{ $t("label.mapBuildingFloor_value_-1") }}
                            </md-option>
                            <md-option :value="0">
                                {{ $t("label.mapBuildingFloor_value_0") }}
                            </md-option>
                            <md-option :value="1">
                                {{ $t("label.mapBuildingFloor_value_1") }}
                            </md-option>
                        </md-select>
                    </md-field>
                </div>
                <!-- Building  Id -->
                <div class="md-layout-item md-size-50">
                    <md-field>
                        <md-select v-model="item.mapBuildingId" :disabled="loading"
                            :placeholder="$t('label.mapBuildingId')">
                            <md-option v-for="(building, index) of buildings" :value="building.value" :key="index">
                                {{ building.text }}</md-option>
                        </md-select>
                    </md-field>
                </div>
            </div>

            <!-- Separator localizations -->
            <div class="md-layout-item md-size-100">
                <div class="Group-Header">
                    <span class="Luggage-information">
                        {{ $t('label.language_information') }}
                    </span>
                    <div class="Path"></div>
                </div>
            </div>
            <!-- Translations -->
            <md-card class="translations-card">
                <md-card-content>
                    <md-tabs class="translations-tabs">
                        <md-tab id="tab-tr" :md-label="$t('languages.tr')" :md-icon="getLanguageIcon('tr', errors)">
                            <!-- name tr -->
                            <div class="md-layout-item md-size-50">
                                <md-field :class="[
                                    { 'md-valid': errors['name_tr'] && !errors['name_tr'][0] && touchedFields.name_tr },
                                    { 'md-error': errors['name_tr'] && errors['name_tr'][0] }
                                ]">
                                    <label for="title">{{ $t('label.service_name') }}</label>
                                    <validation-provider name='name_tr' rules="required" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-input v-model="item.name_tr" type="text" :placeholder="$t('label.service_name')"
                                            :disabled="loading">
                                        </md-input>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                            <!-- text tr -->
                            <div class="rich-text-editor">
                                <label class="rich-text-title">{{ $t("label.service_text") }}</label>
                                <validation-provider name='text_tr' rules="required" v-slot="{ errors }" mode="aggressive">
                                    <ckeditor :editor="editor" v-model="item.text_tr" :config="editorConfig"></ckeditor>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </div>

                            <!-- benefits tr -->
                            <div class="rich-text-editor">
                                <label class="rich-text-title">{{ $t("label.service_benefits") }}</label>
                                <validation-provider name='benefits_tr' v-slot="{ errors }" mode="aggressive">
                                    <ckeditor :editor="editor" v-model="item.benefits_tr" :config="editorConfig"></ckeditor>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </div>
                            <!-- action text tr -->
                            <div class="md-layout-item md-size-50">
                                <md-field :class="[
                                    { 'md-valid': errors['actionText_tr'] && !errors['actionText_tr'][0] && touchedFields.actionText_tr },
                                    { 'md-error': errors['actionText_tr'] && errors['actionText_tr'][0] }
                                ]">
                                    <label for="title">{{ $t('label.service_action_text') }}</label>
                                    <validation-provider name='actionText_tr' rules="required" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-input v-model="item.actionText_tr" type="text"
                                            :placeholder="$t('label.service_action_text')" :disabled="loading">
                                        </md-input>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                            <!-- shareUrl tr -->
                            <div class="md-layout-item md-size-100">
                                <md-field :class="[
                                    { 'md-valid': errors['shareURL_tr'] && !errors['shareURL_tr'][0] && touchedFields.shareURL_tr },
                                    { 'md-error': errors['shareURL_tr'] && errors['shareURL_tr'][0] }
                                ]">
                                    <label for="title">{{ $t('label.service_shareURL') }}</label>
                                    <validation-provider name='shareURL_tr' rules="" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-textarea md-autogrow v-model="item.shareURL_tr" name="shareURL_tr" type="text"
                                            :placeholder="$t('label.service_shareURL')" id="shareURL_tr"
                                            :disabled="loading"></md-textarea>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                        </md-tab>
                        <md-tab id="tab-en" :md-label="$t('languages.en')" :md-icon="getLanguageIcon('en', errors)">
                            <!-- name en -->
                            <div class="md-layout-item md-size-50">
                                <md-field :class="[
                                    { 'md-valid': errors['name_en'] && !errors['name_en'][0] && touchedFields.name_en },
                                    { 'md-error': errors['name_en'] && errors['name_en'][0] }
                                ]">
                                    <label for="title">{{ $t('label.service_name') }}</label>
                                    <validation-provider name='name_en' rules="required" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-input v-model="item.name_en" type="text" :placeholder="$t('label.service_name')"
                                            :disabled="loading">
                                        </md-input>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                            <!-- text en -->
                            <div class="rich-text-editor">
                                <label class="rich-text-title">{{ $t("label.service_text") }}</label>
                                <validation-provider name='text_en' rules="required" v-slot="{ errors }" mode="aggressive">
                                    <ckeditor :editor="editor" v-model="item.text_en" :config="editorConfig"></ckeditor>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </div>

                            <!-- benefits en -->
                            <div class="rich-text-editor">
                                <label class="rich-text-title">{{ $t("label.service_benefits") }}</label>
                                <validation-provider name='benefits_en' v-slot="{ errors }" mode="aggressive">
                                    <ckeditor :editor="editor" v-model="item.benefits_en" :config="editorConfig"></ckeditor>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </div>

                            <!-- action text tr -->
                            <div class="md-layout-item md-size-50">
                                <md-field :class="[
                                    { 'md-valid': errors['actionText_en'] && !errors['actionText_en'][0] && touchedFields.actionText_en },
                                    { 'md-error': errors['actionText_en'] && errors['actionText_en'][0] }
                                ]">
                                    <label for="title">{{ $t('label.service_action_text') }}</label>
                                    <validation-provider name='actionText_en' rules="required" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-input v-model="item.actionText_en" type="text"
                                            :placeholder="$t('label.service_action_text')" :disabled="loading">
                                        </md-input>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                            <!-- shareUrl en -->
                            <div class="md-layout-item md-size-100">
                                <md-field :class="[
                                    { 'md-valid': errors['shareURL_en'] && !errors['shareURL_en'][0] && touchedFields.shareURL_en },
                                    { 'md-error': errors['shareURL_en'] && errors['shareURL_en'][0] }
                                ]">
                                    <label for="title">{{ $t('label.service_shareURL') }}</label>
                                    <validation-provider name='shareURL_en' rules="" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-textarea md-autogrow v-model="item.shareURL_en" name="shareURL_en" type="text"
                                            :placeholder="$t('label.service_shareURL')" id="shareURL_en"
                                            :disabled="loading"></md-textarea>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                        </md-tab>
                    </md-tabs>
                </md-card-content>
            </md-card>

            <!-- Modal Buttons -->
            <div class="open-modal-buttons">
                <md-button :disabled="loading == true || creating == true" class="md-primary md-round btn-big"
                    @click="showModal('gallery')">
                    {{ $t("gallery") }}
                </md-button>
            </div>
            <!-- Ancillary Modal-->
            <service-modal :modeTypeProp="'ancillary'" :showProp="showAncillaryDialog" @apply="applyAncillary"
                @close="closeAncillaryDialog()" />
            <!-- Media Modal -->
            <media-modal :showProp="showMediaModal" @apply="applyMedia" @close="closeMediaModal()"></media-modal>
            <media-modal :showProp="showMediaIconModal" @apply="applyMediaIcon"
                @close="closeMediaIconModal()"></media-modal>
            <media-modal :showProp="showSecondaryMediaModal" @apply="applySecondaryMedia"
                @close="closeSecondaryMediaModal()"></media-modal>
            <!-- Gallery Modal -->
            <gallery-modal :showProp="showGalleryModal" :mode="'service'" :parentId="item.serviceId" :parentName="item.name"
                @close="closeGalleryModal()"></gallery-modal>
        </div>
    </ValidationObserver>
</template>

<script>


import MediaModal from "@/components/Modals/Media";
import GalleryModal from "@/components/Modals/Gallery";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import { getService, createService, updateService } from "@/services/service";
import { getProviderTypes } from "@/services/providerType";
import localeMixin from "@/mixins/locale";
import { CKEditorConfigMedium } from "@/config";
import ClassicEditor from '@fiveflames/ckeditor5-build-classic';
import ServiceModal from "@/components/Modals/Service";

export default {
    mixins: [localeMixin],
    components: {
        MediaModal,
        GalleryModal,
        ServiceModal,
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
    },

    data() {
        return {
            creating: false,
            editing: false,
            imagesLightbox: [],
            item: {
                serviceId: "",
                order: 0,
                name: "",
                media: { fileName: "", imageURL: "", imageAlt: "" },
                iconMedia: { fileName: "", imageURL: "" },
                secondaryMedia: { fileName: "", imageURL: "", imageAlt: "" },
                bookingType: 0,
                mediaId: 0,
                ancillary: { name: "", ancillaryId: null, id: null },
                ancillaryId: 0,
                ancillaryName: "",
                imageURL: "",
                imageAlt: "",
                fileName: "",
                iconMediaId: 0,
                iconURL: "",
                iconFileName: "",
                secondaryMediaId: 0,
                secondaryImageURL: "",
                secondaryImageAlt: "",
                secondaryFileName: "",
                mapBuildingId: 0,
                mapBuildingFloor: -1000,
                providerTypeCode: "",
                actionURL: "",
                enabled: false,
                translationId_tr: 0,
                name_tr: "",
                text_tr: "",
                benefits_tr: "",
                actionText_tr: "",
                shareURL_tr: "",
                translationId_en: 0,
                name_en: "",
                text_en: "",
                benefits_en: "",
                actionText_en: "",
                shareURL_en: "",
                gallery: [],
            },
            orders: [],
            showGalleryModal: false,
            showMediaModal: false,
            showSecondaryMediaModal: false,
            showMediaIconModal: false,
            touchedFields: {
                actionURL: false,
                name_tr: false,
                text_tr: false,
                actionText_tr: false,
                name_en: false,
                text_en: false,
                actionText_en: false,
                benefits_tr: false,
                benefits_en: false,
                shareURL_tr: false,
                shareURL_en: false,
            },
            errorOrderMessage: "",
            errorMediaMessage: "",
            errorSecondaryMediaMessage: "",
            errorMediaIconMessage: "",
            errorBookingTypeMessage: "",
            errorProviderTypeMessage: "",
            loading: false,
            buildings: [],
            selectedBuilding: null,
            providerTypes: [],
            editor: ClassicEditor,
            editorConfig: CKEditorConfigMedium,
            showAncillaryDialog: false,
            errorAncillaryMessage: "",

        };
    },




    created: async function () {
        let serviceId = this.$route.params.serviceId;
        if (serviceId) {
            await this.editItem(serviceId);
        } else {
            let lastOrder = this.$route.params.lastOrder;
            if (lastOrder) {
                this.item.order = lastOrder;
            }
            this.newItem();
        }
    },

    async mounted() {
        await this.fillProviderTypes();
        this.fillBuildingsArray();
        this.fillOrdersArray();
    },

    methods: {

        openAncillaryDialog() {
            this.showAncillaryDialog = true;
        },

        closeAncillaryDialog() {
            this.showAncillaryDialog = false;
        },

        clearAncillary() {
            this.item.ancillary = { name: "", id: 0, ancillaryId: "" };
            this.item.ancillaryId = 0;
            this.errorAncillaryMessage = "";
        },

        applyAncillary(ancillary) {
            this.selectedAncillary = ancillary;
            this.item.ancillary.name = ancillary.name;
            this.item.ancillaryId = ancillary.id;
            this.item.ancillary.ancillaryId = ancillary.ancillaryId;
            this.item.ancillary.id = ancillary.id;
            this.errorServiceMessage = "";
        },

        viewImage(imageUrl) {
            this.imagesLightbox = [];
            this.$imageViewer.images(this.imagesLightbox);
            const image = {
                url: imageUrl,
            };
            this.imagesLightbox.push(image);
            this.$imageViewer.index(0);
            this.$imageViewer.show();
        },

        async fillProviderTypes() {
            let t = this.$t('label.providerType_value_default');
            let z = { value: "-", text: t };
            this.providerTypes.push(z);
            const responseProviderTypes = await getProviderTypes(this.page, this.limit, "");
            if (responseProviderTypes) {

                responseProviderTypes.result.map(item => {
                    let b = {
                        value: item.providerTypeCode,
                        text: item.description,
                    };

                    this.providerTypes.push(b);
                });

            } else {
                this.providerTypes = [];
            }

        },

        fillBuildingsArray() {
            let min = 0;
            let max = 50;
            this.buildings = [];
            for (let i = min; i <= max; i++) {
                if (i == 0) {
                    let t = this.$t('label.mapBuildingId_default');
                    let z = { value: i, text: t };
                    this.buildings.push(z);
                } else {
                    let t = this.$t('label.mapBuildingId_value_N', { _field_: i });
                    let b = { value: i, text: t };
                    this.buildings.push(b);
                }
            }
        },

        fillOrdersArray() {
            let min = 0;
            let max = 50;
            this.orders = [];
            for (let i = min; i <= max; i++) {
                if (i == 0) {
                    let z = { value: i, text: this.$t('label.order_default') };
                    this.orders.push(z);
                } else {
                    let b = { value: i, text: i.toString() };
                    this.orders.push(b);
                }
            }
        },


        applyMedia(media) {
            this.item.media = media;
            this.item.mediaId = media.mediaId;
            this.item.media.imageURL = media.imageURL;
            this.item.media.imageAlt = media.imageAlt;
            this.item.media.fileName = media.fileName;
            this.errorMediaMessage = "";
            this.errorMediaIconMessage = "";
        },

        clearMedia() {

            this.item.media = { fileName: "", imageURL: "", imageAlt: "" };
            this.item.mediaId = 0;
            this.item.imageURL = this.item.media.imageURL;
            this.item.fileName = this.item.media.fileName;
            this.item.imageAlt = this.item.media.imageAlt;
            this.errorMediaMessage = "";
        },

        showModal(mode) {
            switch (mode) {
                case "gallery":
                    this.openGalleryModal();
                    break;
                default:
                    break;
            }
        },

        openGalleryModal() {
            this.showGalleryModal = true;
        },

        closeGalleryModal() {
            this.showGalleryModal = false;
        },

        openMediaModal() {
            this.showMediaModal = true;
        },

        closeMediaModal() {
            this.showMediaModal = false;
            //https://mantis.fiveflamesmobile.com/view.php?id=13166
            if (this.item.mediaId == 0) {
                this.errorMediaMessage = this.$t('validation.required', { _field_: this.$t('field.image') });
            }
        },

        applySecondaryMedia(media) {
            this.item.secondaryMedia = media;
            this.item.secondaryMediaId = media.mediaId;
            this.item.secondaryMedia.imageURL = media.imageURL;
            this.item.secondaryMedia.imageAlt = media.imageAlt;
            this.item.secondaryMedia.fileName = media.fileName;
            this.errorSecondaryMediaMessage = "";
        },

        clearSecondaryMedia() {

            this.item.secondaryMedia = { fileName: "", imageURL: "", imageAlt: "" };
            this.item.secondaryMediaId = 0;
            this.item.secondaryImageURL = this.item.secondaryMedia.imageURL;
            this.item.secondaryFileName = this.item.secondaryMedia.fileName;
            this.item.secondaryImageAlt = this.item.secondaryMedia.imageAlt;
            this.errorSecondaryMediaMessage = "";
        },

        openSecondaryMediaModal() {
            this.showSecondaryMediaModal = true;
        },

        closeSecondaryMediaModal() {
            this.showSecondaryMediaModal = false;
            if (this.item.secondaryMediaId == 0) {
                this.errorSecondaryMediaMessage = this.$t('validation.required', { _field_: this.$t('field.image') });
            }
        },

        applyMediaIcon(media) {
            this.item.iconMedia = media;
            this.item.iconMediaId = media.mediaId;
            this.item.iconMedia.imageURL = media.imageURL;
            this.item.iconMedia.fileName = media.fileName;
            this.errorMediaIconMessage = "";
        },

        clearIconMedia() {

            this.item.iconMedia = { fileName: "", imageURL: "" };
            this.item.iconMediaId = 0;
            this.item.iconURL = this.item.iconMedia.imageURL;
            this.item.iconFileName = this.item.iconMedia.fileName;
            this.errorMediaMessage = "";
        },

        openMediaIconModal() {
            this.showMediaIconModal = true;
        },

        closeMediaIconModal() {
            this.showMediaIconModal = false;
            if (this.item.iconMediaId == 0) {
                this.errorMediaIconMessage = this.$t('validation.required', { _field_: this.$t('fields.icon') });

            }
        },


        checkValues() {

            this.errorBookingTypeMessage = "";
            this.errorProviderTypeMessage = "";

            if (this.item.bookingType == 0) {
                this.errorBookingTypeMessage = this.$t('validation.at_least_one_value', { _field_: this.$t('label.service_bookingType') });
            }

            if (this.item.providerTypeCode == "" || this.item.providerTypeCode == "-") {
                this.errorProviderTypeMessage = this.$t('validation.at_least_one_value', { _field_: this.$t('label.service_serviceType') });
            }
        },

        async save() {

            this.errorMediaMessage = "";
            this.errorMediaIconMessage = "";
            this.errorBookingTypeMessage = "";
            this.errorProviderTypeMessage = "";

            var valid = await this.$refs.form.validate();

            if (this.item.bookingType == 0) {
                valid = false;
                this.errorBookingTypeMessage = this.$t('validation.at_least_one_value', { _field_: this.$t('label.service_bookingType') });
            }

            if (this.item.providerTypeCode == "" || this.item.providerTypeCode == "-") {
                valid = false;
                this.errorProviderTypeMessage = this.$t('validation.at_least_one_value', { _field_: this.$t('label.service_serviceType') });
            }

            if (this.item.order == 0) {
                valid = false;
                this.errorOrderMessage = this.$t('validation.at_least_one_value', { _field_: this.$t('label.dine_order') });
            }

            //https://mantis.fiveflamesmobile.com/view.php?id=13166
            if (this.item.mediaId == 0) {
                valid = false;
                this.errorMediaMessage = this.$t('validation.required', { _field_: this.$t('fields.image') });
            }

            //if (this.item.secondaryMediaId == 0) {
            //    valid = false;
            //    this.errorSecondaryMediaMessage = this.$t('validation.required', { _field_: this.$t('fields.image') });
            //}

            if (this.item.iconMediaId == 0) {
                valid = false;
                this.errorMediaIconMessage = this.$t('validation.required', { _field_: this.$t('fields.icon') });
            }

            if (!valid) {
                this.$noty.error(this.$t('error.saved'));
            }


            if (valid) {
                this.loading = true;
                if (this.creating) {
                    let response = await createService(this.convertDataModel());
                    if (response.status == "201") {
                        this.$noty.success(this.$t('success.saved'));
                        this.$router.push({ name: this.$i18n.locale + '.services' });

                    } else {
                        this.$noty.error(this.$t('error.saved'));
                    }
                }
                if (this.editing) {
                    let response = await updateService(this.convertDataModel());
                    if (response.status == "200") {
                        this.$noty.success(this.$t('success.saved'));
                    } else {
                        this.$noty.error(this.$t('error.saved'));
                    }
                }
                this.loading = false;


            }

        },


        assignDataModel(item) {
            if (item.serviceId) {
                this.item.serviceId = item.serviceId;
            } else {
                this.item.serviceId = "";
            }

            if (item.name) {
                this.item.name = item.name;
            } else {
                this.item.name = "";
            }

            if (item.bookingType != null) {
                this.item.bookingType = Number(item.bookingType) + 1;
            } else {
                this.item.bookingType = 0;
            }

            if (item.order) {
                this.item.order = item.order;
            } else {
                this.item.order = 0;
            }

            if (item.enabled) {
                this.item.enabled = Boolean(item.enabled);
            } else {
                this.item.enabled = false;
            }

            if (item.actionURL) {
                this.item.actionURL = item.actionURL;
            } else {
                this.item.actionURL = "";
            }

            if (item.providerTypeCode) {
                this.item.providerTypeCode = item.providerTypeCode;
            } else {
                this.item.providerTypeCode = "";
            }

            if (item.mediaId) {
                this.item.mediaId = item.mediaId;
            } else {
                this.item.mediaId = 0;
            }

            if (item.ancillaryId) {
                this.item.ancillaryId = item.ancillaryId;
            } else {
                this.item.ancillaryId = 0;
            }
            if (item.ancillaryName) {
                this.item.ancillaryName = item.ancillaryName;
            } else {
                this.item.ancillaryName = "";
            }

            if (item.ancillary) {
                this.item.ancillary = item.ancillary;
            } else {
                this.item.ancillary = { name: "", id: 0, ancillaryId: "" };
            }

            if (item.secondaryMediaId) {
                this.item.secondaryMediaId = item.secondaryMediaId;
            } else {
                this.item.secondaryMediaId = 0;
            }

            if (item.iconMediaId) {
                this.item.iconMediaId = item.iconMediaId;
            } else {
                this.item.iconMediaId = 0;
            }

            if (item.fileName) {
                this.item.media.fileName = item.fileName;
            } else {
                this.item.media.fileName = "";
            }

            if (item.secondaryFileName) {
                this.item.secondaryMedia.fileName = item.secondaryFileName;
            } else {
                this.item.secondaryMedia.fileName = "";
            }

            if (item.iconFileName) {
                this.item.iconMedia.fileName = item.iconFileName;
            } else {
                this.item.iconMedia.fileName = "";
            }

            if (item.imageURL) {
                this.item.media.imageURL = item.imageURL;
            } else {
                this.item.media.imageURL = "";
            }

            if (item.imageAlt) {
                this.item.media.imageAlt = item.imageAlt;
            } else {
                this.item.media.imageAlt = "";
            }

            if (item.secondaryImageURL) {
                this.item.secondaryMedia.imageURL = item.secondaryImageURL;
            } else {
                this.item.secondaryMedia.imageURL = "";
            }

            if (item.secondaryImageAlt) {
                this.item.secondaryMedia.imageAlt = item.secondaryImageAlt;
            } else {
                this.item.secondaryMedia.imageAlt = "";
            }

            if (item.iconURL) {
                this.item.iconMedia.imageURL = item.iconURL;
            } else {
                this.item.iconMedia.imageURL = "";
            }

            if (item.mapBuildingFloor != null) {
                this.item.mapBuildingFloor = item.mapBuildingFloor;
            } else {
                this.item.mapBuildingFloor = -1000;
            }

            if (item.mapBuildingId) {
                this.item.mapBuildingId = item.mapBuildingId;
            } else {
                this.item.mapBuildingId = 0;
            }

            var translation = item.translations.filter(t => t.locale == 'en')[0];

            if (translation) {
                if (translation.translationId) {
                    this.item.translationId_en = translation.translationId;
                } else {
                    this.item.translationId_en = 0;
                }

                if (translation.name) {
                    this.item.name_en = translation.name;
                } else {
                    this.item.name_en = "";
                }

                if (translation.text) {
                    this.item.text_en = translation.text;
                } else {
                    this.item.text_en = "";
                }

                if (translation.benefits) {
                    this.item.benefits_en = translation.benefits;
                } else {
                    this.item.benefits_en = "";
                }

                if (translation.actionText) {
                    this.item.actionText_en = translation.actionText;
                } else {
                    this.item.actionText_en = "";
                }

                if (translation.shareURL) {
                    this.item.shareURL_en = translation.shareURL;
                } else {
                    this.item.shareURL_en = "";
                }

            } else {

                this.item.translationId_en = 0;
                this.item.name_en = "";
                this.item.text_en = "";
                this.item.benefits_en = "";
                this.item.actionText_en = "";
                this.item.shareURL_en = "";

            }


            translation = item.translations.filter(t => t.locale == 'tr')[0];

            if (translation) {
                if (translation.translationId) {
                    this.item.translationId_tr = translation.translationId;
                } else {
                    this.item.translationId_tr = 0;
                }

                if (translation.name) {
                    this.item.name_tr = translation.name;
                } else {
                    this.item.name_tr = "";
                }

                if (translation.text) {
                    this.item.text_tr = translation.text;
                } else {
                    this.item.text_tr = "";
                }

                if (translation.benefits) {
                    this.item.benefits_tr = translation.benefits;
                } else {
                    this.item.benefits_tr = "";
                }

                if (translation.actionText) {
                    this.item.actionText_tr = translation.actionText;
                } else {
                    this.item.actionText_tr = "";
                }

                if (translation.shareURL) {
                    this.item.shareURL_tr = translation.shareURL;
                } else {
                    this.item.shareURL_tr = "";
                }

            } else {

                this.item.translationId_tr = 0;
                this.item.name_tr = "";
                this.item.text_tr = "";
                this.item.benefits_tr = "";
                this.item.actionText_tr = "";
                this.item.shareURL_tr = "";

            }
        },


        convertDataModel() {

            var newItem = {};
            newItem.serviceId = this.item.serviceId;
            newItem.bookingType = Number(this.item.bookingType) - 1;
            newItem.order = this.item.order;
            newItem.enabled = Number(this.item.enabled);
            newItem.actionURL = this.item.actionURL;
            newItem.providerTypeCode = this.item.providerTypeCode;
            newItem.mediaId = this.item.mediaId;
            newItem.ancillaryId = this.item.ancillaryId;
            if (newItem.ancillaryId == 0) {
                newItem.ancillaryId = null;
            }
            newItem.secondaryMediaId = this.item.secondaryMediaId;
            newItem.iconMediaId = this.item.iconMediaId;
            newItem.mapBuildingId = this.item.mapBuildingId;
            newItem.mapBuildingFloor = this.item.mapBuildingFloor;
            if (newItem.mapBuildingFloor == -1000){
                newItem.mapBuildingFloor = null;
            }
            newItem.gallery = this.item.gallery;
            newItem.translations = [];
            var trans_item_tr = {};
            trans_item_tr.translationId = this.item.translationId_tr;
            trans_item_tr.locale = "tr";
            trans_item_tr.name = this.item.name_tr;
            trans_item_tr.text = this.item.text_tr;
            trans_item_tr.benefits = this.item.benefits_tr;
            trans_item_tr.actionText = this.item.actionText_tr;
            trans_item_tr.shareURL = this.item.shareURL_tr;
            newItem.translations.push(trans_item_tr);
            var trans_item_en = {};
            trans_item_en.translationId = this.item.translationId_en;
            trans_item_en.locale = "en";
            trans_item_en.name = this.item.name_en;
            trans_item_en.text = this.item.text_en;
            trans_item_en.benefits = this.item.benefits_en;
            trans_item_en.actionText = this.item.actionText_en;
            trans_item_en.shareURL = this.item.shareURL_en;
            newItem.translations.push(trans_item_en);
            return newItem;

        },

        newItem() {
            this.creating = true;
            this.editing = false;
        },


        editItem(itemId) {
            this.creating = false;
            this.editing = true;
            this.fetchItem(itemId);
        },


        async fetchItem(itemId) {
            this.loading = true;
            let response = await getService(itemId);
            if (response) {
                this.assignDataModel(response);
            }
            this.loading = false;
        },


    }
}


</script>

<style scoped>
.media-container-left {
    display: flex;
    float: left;
    width: 400px;
    max-width: 400px;
    max-height: 300px;
    margin-left: 24px;
    margin-bottom: 48px;
    min-height: 300px;
    background-color: var(--media-background);
    border: solid 1px var(--media-border);
}

.media-container-left img:first-child {
    width: 100%;
    object-fit: fill;
    cursor: pointer;
}

.media-container-small-left {
    display: flex;
    float: right;
    width: 200px;
    max-width: 200px;
    max-height: 150px;
    margin-left: 24px;
    margin-bottom: 48px;
    min-height: 150px;
    background-color: var(--media-background);
    border: solid 1px var(--media-border);
}

.media-container-small-left img:first-child {
    width: 100%;
    object-fit: fill;
    cursor: pointer;

}

.rich-text-editor {
    margin-left: 12px;
    margin-block: 24px !important;
}
</style>