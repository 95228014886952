<template>
    <ValidationObserver tag="form" ref="form" v-slot="{ errors }">
        <div class="md-layout">
            <!-- Button toolbar -->
            <div class="md-layout-item md-size-100 form-toolbar">
                <h3 class="page-title">
                    <span v-if="creating">{{ $t("routes.activity_create.title") }}</span>
                    <span v-if="editing">{{ $t("routes.activity_edit.title") }} - {{ item.title }}</span>
                </h3>
                <div class="form-buttons">
                    <md-progress-spinner v-if="loading" :md-diameter="30" :md-stroke="3" md-mode="indeterminate">
                    </md-progress-spinner>
                    <md-button type="submit" class="md-primary md-round btn-medium" @click.prevent="save()">
                        {{ $t("save") }}
                    </md-button>
                </div>
            </div>
            <!-- Regions input -->
            <div class="md-layout-item md-size-50">
                <md-field :class="[
                    { 'md-valid': errors['regions'] && !errors['regions'][0] && touchedFields.regions },
                    { 'md-error': errors['regions'] && errors['regions'][0] }
                ]">
                    <validation-provider name='regions' rules=""
                                    v-slot="{ errors }" mode="aggressive">
                        <md-select multiple :placeholder="$t('label.select_regions')" v-model="item.regions">
                            <md-option  v-for="(region, index) of regions" :value="region" :key="index">
                                {{ JSON.parse(region).title }}
                            </md-option>
                        </md-select>
                        <span class="md-error">{{ errors[0] }}</span>
                    </validation-provider>
                </md-field>
            </div>
            <div class="md-layout-item md-size-50 px-40">
                <div class="field-switch">
                    <span>{{ $t("label.alert_enabled") }}</span>
                    <md-switch id="switchEnabled" v-model="item.enabled" :disabled="loading">
                    </md-switch>
                </div>
                <!-- Media input -->
                <div class="md-layout mt-40">
                    <div>
                        <md-icon class="md-42">iconimage_search</md-icon>
                    </div>
                    <div class="md-layout-item md-size-60">
                        <md-field class="image-field">
                            <label class="label-images" v-if="item.media.fileName == ''">{{
                                    $t("label.image")
                            }}</label>
                            <label v-if="item.media.fileName == ''">{{ $t("label.select_image") }}</label>
                            <label v-else>{{ $t("label.airport_image") }}</label>
                            <md-input readonly id="mediaInput" v-model="item.media.fileName" :disabled="true">
                            </md-input>
                            <md-button v-if="item.media.fileName != ''" class="btn-floating" @click="clearMedia()">
                                <md-icon>close</md-icon>
                            </md-button>
                            <span class="md-error">
                                {{ errorMediaMessage }}
                            </span>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-20">
                        <md-button @click="openMediaModal()" class="md-primary md-round">
                            {{ $t("select_file") }}
                        </md-button>
                    </div>
                </div>
                <!-- Image preview -->
                <div class="media-container">
                    <md-icon v-if="item.media.imageURL == ''" class="md-size-5x">image</md-icon>
                    <img v-else :src="item.media.imageURL" :alt="item.media.imageAlt" @click.prevent="viewImage(item.media.imageURL)"/>
                </div>
            </div>

            <!-- Separator localizations -->
            <div class="md-layout-item md-size-100">
                <div class="Group-Header">
                    <span class="Luggage-information">
                        {{ $t("label.language_information") }}
                    </span>
                    <div class="Path"></div>
                </div>
            </div>

            <!-- Translations -->
            <md-card class="translations-card">
                <md-card-content>
                    <md-tabs class="translations-tabs">
                        <md-tab id="tab-tr" :md-label="$t('languages.tr')" :md-icon="getLanguageIcon('tr', errors)">
                            <!-- Activity Title TR -->
                            <md-field class="w-50" :class="[
                                { 'md-valid': errors['name_tr'] && !errors['name_tr'][0] && touchedFields.title_tr },
                                { 'md-error': errors['name_tr'] && errors['name_tr'][0] }
                            ]">
                                <label>{{ $t("label.name") }}</label>
                                <validation-provider name='name_tr' rules="required"
                                    v-slot="{ errors, touched }" mode="aggressive">
                                    <md-input v-model="title_tr"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <!-- Activity Subtitle TR -->
                            <md-field :class="[
                                { 'md-valid': errors['subtitle_tr'] && !errors['subtitle_tr'][0] && touchedFields.subtitle_tr },
                                { 'md-error': errors['subtitle_tr'] && errors['subtitle_tr'][0] }
                            ]">
                                <label>{{ $t("label.subtitle") }}</label>
                                <validation-provider name='subtitle_tr' rules=""
                                    v-slot="{ errors, touched }" mode="aggressive">
                                    <md-input v-model="subtitle_tr"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <!-- Activity text TR -->
                            <md-field :class="[
                                { 'md-valid': errors['text_tr'] && !errors['text_tr'][0] && touchedFields.text_tr },
                                { 'md-error': errors['text_tr'] && errors['text_tr'][0] }
                            ]">
                                <label for="text">{{ $t('label.text') }}</label>
                                <validation-provider name='text_tr' rules=""
                                    v-slot="{ errors, touched }" mode="aggressive">
                                    <md-textarea :md-autogrow="false" f
                                        v-model="text_tr"
                                        name="text_tr"
                                        :placeholder="$t('label.text')"
                                        id="text_tr" :disabled="loading">
                                    </md-textarea>
                                    <slide-y-down-transition>
                                        <md-icon class="error"
                                            v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                        </md-tab>
                        <md-tab id="tab-en" :md-label="$t('languages.en')" :md-icon="getLanguageIcon('en', errors)">
                            <!-- Activity Title EN -->
                            <md-field class="w-50" :class="[
                                { 'md-valid': errors['name_en'] && !errors['name_en'][0] && touchedFields.title_en },
                                { 'md-error': errors['name_en'] && errors['name_en'][0] }
                            ]">
                                <label>{{ $t("label.name") }}</label>
                                <validation-provider name='name_en' rules="required"
                                    v-slot="{ errors, touched }" mode="aggressive">
                                    <md-input v-model="title_en"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <!-- Activity Subtitle EN -->
                            <md-field :class="[
                                { 'md-valid': errors['subtitle_en'] && !errors['subtitle_en'][0] && touchedFields.subtitle_en },
                                { 'md-error': errors['subtitle_en'] && errors['subtitle_en'][0] }
                            ]">
                                <label>{{ $t("label.subtitle") }}</label>
                                <validation-provider name='subtitle_en' rules=""
                                    v-slot="{ errors, touched }" mode="aggressive">
                                    <md-input v-model="subtitle_en"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <!-- Activity text EN -->
                            <md-field :class="[
                                { 'md-valid': errors['text_en'] && !errors['text_en'][0] && touchedFields.text_en },
                                { 'md-error': errors['text_en'] && errors['text_en'][0] }
                            ]">
                                <label for="text">{{ $t('label.text') }}</label>
                                <validation-provider name='text_en' rules=""
                                    v-slot="{ errors, touched }" mode="aggressive">
                                    <md-textarea :md-autogrow="false" f
                                        v-model="text_en"
                                        name="text_en"
                                        :placeholder="$t('label.text')"
                                        id="text_en" :disabled="loading">
                                    </md-textarea>
                                    <slide-y-down-transition>
                                        <md-icon class="error"
                                            v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                        </md-tab>
                    </md-tabs>
                </md-card-content>
            </md-card>

            <!-- Modal Buttons -->
            <div class="open-modal-buttons">
                <md-button :disabled="loading == true || creating == true" class="md-primary md-round btn-big"
                    @click="showModal('gallery')">
                    {{ $t("gallery") }}
                </md-button>
                <md-button :disabled="loading == true || creating == true" class="md-primary md-round btn-big"
                    @click="showModal('company')">
                    {{ $t("company") }}
                </md-button>
            </div>

            <!-- Media Modal -->
            <media-modal 
                :showProp="showMediaModal" 
                @apply="applyMedia" 
                @close="closeMediaModal()"
            ></media-modal>
            <!-- Gallery Modal -->
            <gallery-modal
                :showProp="showGalleryModal"
                :mode="'activity'"
                :parentId="item.activityId"
                :parentName="item.title"
                @close="closeGalleryModal()"
            ></gallery-modal>
            <!-- Company Modal -->
            <company-modal 
                :showProp="showCompanyModal"
                :activityId="item.activityId"
                :activityName="item.title"
                @close="closeCompanyModal()"
            ></company-modal>
        </div>
    </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import MediaModal from "@/components/Modals/Media";
import GalleryModal from "@/components/Modals/Gallery";
import CompanyModal from "@/components/Modals/Company";
import localeMixin from "@/mixins/locale";
import {
    getActivity,
    createActivity,
    updateActivity,
} from "@/services/activities";
import {
    getTouristicRegionsWithoutPagination,
} from "@/services/touristic_region";

export default {
    mixins: [localeMixin],
    components: {
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
        MediaModal,
        GalleryModal,
        CompanyModal,
    },
    data() {
        return {
            loading: false,
            view: false,
            creating: false,
            editing: false,
            touchedFields: {
                regions: false,
                title_en: false,
                subtitle_en: false,
                text_en: false,
                title_tr: false,
                subtitle_tr: false,
                text_tr: false,
            },
            regions: [],
            item: {
                activityId: null,
                title: "",
                subtitle: "",
                text: "",
                enabled: true,
                mediaId: 0,
                imageURL: "",
                imageAlt: "",
                imageS3Key: "",
                media: {
                    fileName: "", imageURL: ""
                },
                gallery: [],
                activitiyCompanies: [],
                regions: [

                ],
                translations: [
                    {
                        locale: "en",
                        title: "",
                        subtitle: "",
                        text: "",
                    },
                    {
                        locale: "tr",
                        title: "",
                        subtitle: "",
                        text: "",
                    },
                ],
            },
            title_en: "",
            subtitle_en: "",
            text_en: "",
            title_tr: "",
            subtitle_tr: "",
            text_tr: "",
            errorMediaMessage: "",
            showGalleryModal: false,
            showCompanyModal: false,
            showMediaModal: false,
        };
    },

    created: async function () {
        await this.fetchTouristicRegions();
        let activityId = this.$route.params.activityId;
        if (activityId) {
            this.editing = true;
            await this.fetchItem(activityId);
        } else {
            this.creating = true;
        }
    },

    methods: {

        viewImage(imageUrl) {
            this.imagesLightbox = [];
            this.$imageViewer.images(this.imagesLightbox);
            const image = {
                url: imageUrl,
            };
            this.imagesLightbox.push(image);
            this.$imageViewer.index(0);
            this.$imageViewer.show();
        },

        applyMedia(media) {            
            this.item.media = media;
            this.item.mediaId = media.mediaId;
            this.item.imageURL = media.imageURL;
            this.item.fileName = media.fileName;
            this.errorMediaMessage = "";
        },

        assignDataModel(item) {
            this.item = item;
            this.item.enabled = this.item.enabled == 1 ? true : false;
            this.item.media = {
                mediaId: item.mediaId,
                fileName: item.fileName,
                imageURL: item.imageURL,
            };
            if (item.regions) {
                let regions = [];
                item.regions.map(item => {                    
                    var b = this.regions.filter(t => JSON.parse(t).touristicRegionId == item.touristicRegionId)[0];
                    if (b){ regions.push(b); }
                });
                this.item.regions = regions;

            } else {
                this.item.regions = [];
            }
            this.putItemTranslations();
        },

        convertDataModel() {
            this.getItemTranslations();
            let newItem = {};
            newItem.activityId = this.item.activityId;
            newItem.id = this.item.id;
            newItem.mediaId = this.item.mediaId;
            newItem.enabled = this.item.enabled;
            newItem.gallery = this.item.gallery;
            newItem.activitiyCompanies = this.item.activitiyCompanies;
            newItem.regions = [];
            this.item.regions.map(item => {
                newItem.regions.push(JSON.parse(item));
            });
            newItem.translations = this.item.translations;
            return newItem;
        },

        async fetchTouristicRegions() {
            const responseRegions = await getTouristicRegionsWithoutPagination();
            if (responseRegions) {
                responseRegions.map(item => {
                    let b = {
                        touristicRegionId: item.touristicRegionId,
                        id: item.id,
                        title: item.title,
                        enabled: item.enabled,
                    };
                    this.regions.push(JSON.stringify(b));
                });
            } else {
                this.regions = [];
            }
        },

        async fetchItem(activityId) {
            this.loading = true;
            const response = await getActivity(activityId);
            if (response) {
                this.assignDataModel(response);
            }
            this.loading = false;
        },

        clearMedia() {
            this.item.media = {
                fileName: "", imageURL: ""
            };
            this.item.mediaId = 0;
            this.item.imageURL = this.item.media.imageURL;
            this.item.fileName = this.item.media.fileName;
            this.errorMediaMessage = "";
        },

        showModal(mode) {
            switch (mode) {
                case "gallery":
                    this.openGalleryModal();
                    break;
                case "company":
                    this.openCompanyModal();
                    break;
                default:
                    break;
            }
        },

        openGalleryModal () {
            this.showGalleryModal = true;
        },

        closeGalleryModal () {
            this.showGalleryModal = false;
        },

        openCompanyModal () {
            this.showCompanyModal = true;
        },

        closeCompanyModal () {
            this.showCompanyModal = false;
        },

        openMediaModal() {
            this.showMediaModal = true;
        },

        closeMediaModal() {
            this.showMediaModal = false;
        },

        getItemTranslations() {
            let translation_en = this.item.translations.find(({ locale }) => locale == "en");
            if (translation_en) {
                translation_en.title = this.title_en;
                translation_en.subtitle = this.subtitle_en;
                translation_en.text = this.text_en;
            } else {
                translation_en = {
                    locale: "en",
                    title: this.title_en,
                    subtitle: this.subtitle_en,
                    text: this.text_en,
                }
            }
            let translation_tr = this.item.translations.find(({ locale }) => locale == "tr");
            if (translation_tr) {
                translation_tr.title = this.title_tr;
                translation_tr.subtitle = this.subtitle_tr;
                translation_tr.text = this.text_tr;
            } else {
                translation_tr = {
                    locale: "tr",
                    title: this.title_tr,
                    subtitle: this.subtitle_tr,
                    text: this.text_tr,
                }
            }
            const translations = [translation_en, translation_tr];
            this.item.translations = translations;
        },

        putItemTranslations() {
            let translation_en = this.item.translations.find(({ locale }) => locale == "en");
            if (translation_en) {
                this.title_en = translation_en.title;
                this.subtitle_en = translation_en.subtitle;
                this.text_en = translation_en.text;
            }
            let translation_tr = this.item.translations.find(({ locale }) => locale == "tr");
            if (translation_tr) {
                this.title_tr = translation_tr.title;
                this.subtitle_tr = translation_tr.subtitle;
                this.text_tr = translation_tr.text;
            }
            const translations = [translation_en, translation_tr];
            this.item.translations = translations;
        },
        
        async save() {
            this.loading = true;
            var valid = await this.$refs.form.validate();
            if (this.item.mediaId == 0) {
                valid = false;
                this.errorMediaMessage = this.$t('validation.required', { _field_: this.$t('label.image') });
            }
            if (valid) {
                if (this.creating) {
                    let response = await createActivity(this.convertDataModel());
                    if (response) {
                        this.$noty.success(this.$t("success.saved"));
                        this.$router.push({ name: this.$i18n.locale + '.activities' });
                    } else {
                        this.$noty.error(this.$t("error.saved"));
                    }
                }
                if (this.editing) {
                    let response = await updateActivity(this.convertDataModel());
                    if (response) {
                        this.$noty.success(this.$t("success.saved"));
                    } else {
                        this.$noty.error(this.$t("error.saved"));
                    }
                }
            } else {
                this.$noty.error(this.$t('error.saved'));
            }
            this.loading = false;
        },
    },
}
</script>

<style scoped>
.open-modal-buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
}
</style>