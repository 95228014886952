<template>
    <ValidationObserver tag="form" ref="form" v-slot="{ errors }">
        <div class="md-layout form-layout">
            <!-- Button toolbar -->
            <div class="md-layout-item md-size-100 form-toolbar">
                <h3 class="page-title">
                    {{ $t("routes.emergency_mode.title") }}
                </h3>
                <div class="form-buttons">
                    <md-progress-spinner v-if="loading" :md-diameter="30" :md-stroke="3" md-mode="indeterminate">
                    </md-progress-spinner>
                    <md-button type="submit" class="md-primary md-round btn-medium" @click.prevent="confirmUpdateCrisis()" :disabled="loading">
                        <span v-if="item.emergencyMode">
                            {{ $t("update") }} {{ $t("crisis") }}
                        </span>
                        <span v-else>
                            {{ $t("start") }} {{ $t("crisis") }}
                        </span>
                    </md-button>
                    <md-button v-if="item.emergencyMode" type="submit" class="md-primary md-round btn-medium" @click.prevent="confirmFinishCrisis()" :disabled="loading">
                        {{ $t("finish") }} {{ $t("crisis") }}
                    </md-button>
                </div>
            </div>

            <!-- Translations -->
            <md-card class="translations-card">
                <md-card-content>
                    <md-tabs class="translations-tabs">
                        <md-tab id="tab-tr" :md-label="$t('languages.tr')" :md-icon="getLanguageIcon('tr', errors)">
                            <!-- Title TR -->
                            <md-field :class="[
                                { 'md-valid': errors['title_tr'] && !errors['title_tr'][0] && touchedFields.title_tr },
                                { 'md-error': errors['title_tr'] && errors['title_tr'][0] }
                            ]">
                                <label>{{ $t("label.title") }}</label>
                                <validation-provider name='title_tr' rules="required"
                                    v-slot="{ errors, touched }" mode="aggressive">
                                    <md-input v-model="title_tr"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <!-- Text TR -->
                            <md-field :class="[
                                { 'md-valid': errors['body_tr'] && !errors['body_tr'][0] && touchedFields.body_tr },
                                { 'md-error': errors['body_tr'] && errors['body_tr'][0] }
                            ]">
                                <label>{{ $t("label.text") }}</label>
                                <validation-provider name='body_tr' rules="required"
                                    v-slot="{ errors, touched }" mode="aggressive">
                                    <md-input v-model="body_tr"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                        </md-tab>
                        <md-tab id="tab-en" :md-label="$t('languages.en')" :md-icon="getLanguageIcon('en', errors)">
                            <!-- Title EN -->
                            <md-field :class="[
                                { 'md-valid': errors['title_en'] && !errors['title_en'][0] && touchedFields.title_en },
                                { 'md-error': errors['title_en'] && errors['title_en'][0] }
                            ]">
                                <label>{{ $t("label.title") }}</label>
                                <validation-provider name='title_en' rules="required"
                                    v-slot="{ errors, touched }" mode="aggressive">
                                    <md-input v-model="title_en"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <!-- Text EN -->
                            <md-field :class="[
                                { 'md-valid': errors['body_en'] && !errors['body_en'][0] && touchedFields.body_en },
                                { 'md-error': errors['body_en'] && errors['body_en'][0] }
                            ]">
                                <label>{{ $t("label.text") }}</label>
                                <validation-provider name='body_en' rules="required"
                                    v-slot="{ errors, touched }" mode="aggressive">
                                    <md-input v-model="body_en"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                        </md-tab>
                    </md-tabs>
                </md-card-content>
            </md-card>
        </div>
        <ModalPasswordConfirm @close="showPasswordConfirm = false" @confirm="confirmedAction()"
            :showProp="showPasswordConfirm" />
    </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import ModalPasswordConfirm from "@/components/Modals/PasswordConfirm.vue";
import localeMixin from "@/mixins/locale";
import {
    getEmergencyMode,
    updateEmergencyMode,
} from "@/services/notifications";

export default {
    mixins: [localeMixin],
    components: {
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
        ModalPasswordConfirm,
    },

    data() {
        return{
            loading: false,
            showPasswordConfirm: false,
            action: null,
            touchedFields: {
                airport_iata_code: false,
                title_tr: false,
                title_en: false,
                body_en: false,
                body_tr: false,
            },
            item: {
                emergencyMode: false,
                translations: [
                    {
                        locale: "en",
                        emergencyTitle: "",
                        emergencyBody: "",
                    },
                    {
                        locale: "tr",
                        emergencyTitle: "",
                        emergencyBody: "",
                    }
                ],
            },
            title_en: "",
            title_tr: "",
            body_en: "",
            body_tr: "",
        };
    },

    created: async function () {
        await this.fetchEmergencyMode();
    },

    methods: {
        async fetchEmergencyMode() {
            this.loading = true;
            const response = await getEmergencyMode();
            if (response) {
                this.assignDataModel(response);
            }
            this.loading = false;
        },

        putItemTranslations() {
            let translation_en = this.item.translations.find(({ locale }) => locale == "en");
            if (translation_en) {
                this.title_en = translation_en.emergencyTitle;
                this.body_en = translation_en.emergencyBody;
            }
            let translation_tr = this.item.translations.find(({ locale }) => locale == "tr");
            if (translation_tr) {
                this.title_tr = translation_tr.emergencyTitle;
                this.body_tr = translation_tr.emergencyBody;
            }
            const translations = [translation_en, translation_tr];
            this.item.translations = translations;
        },

        getItemTranslations() {
            let translation_en = this.item.translations.find(({ locale }) => locale == "en");
            if (translation_en) {
                translation_en.emergencyTitle = this.title_en;
                translation_en.emergencyBody = this.body_en;
            } else {
                translation_en = {
                    locale: "en",
                    emergencyTitle: this.title_en,
                    emergencyBody: this.body_en,
                }
            }
            let translation_tr = this.item.translations.find(({ locale }) => locale == "tr");
            if (translation_tr) {
                translation_tr.emergencyTitle = this.title_tr;
                translation_tr.emergencyBody = this.body_tr;
            } else {
                translation_tr = {
                    locale: "tr",
                    emergencyTitle: this.title_tr,
                    emergencyBody: this.body_tr,
                }
            }
            const translations = [translation_en, translation_tr];
            this.item.translations = translations;
        },

        assignDataModel(item) {
            this.item = item;
            this.putItemTranslations();
        },

        async updateCrisis(emergencyMode) {
            this.loading = true;
            var valid = await this.$refs.form.validate();

            if (!valid) {
                this.$noty.error(this.$t('error.saved'));
            } else {
                this.item.emergencyMode = emergencyMode;
                this.getItemTranslations();
                const response = await updateEmergencyMode(this.item);
                if (response) {
                    this.$noty.success(this.$t('success.saved'));
                } else {
                    this.$noty.error(this.$t('error.saved'));
                }
            }

            this.loading = false;
        },

        confirmUpdateCrisis() {
            this.action = "update";
            this.showPasswordConfirm = true;
        },

        confirmFinishCrisis() {
            this.action = "finish";
            this.showPasswordConfirm = true;
        },

        async confirmedAction() {
            switch (this.action) {
                case "finish":
                    await this.updateCrisis(false);
                    break;
                case "update":
                    await this.updateCrisis(true);
                    break;
            }
            this.showPasswordConfirm = false;
        },

    },
}
</script>

<style scoped>

</style>