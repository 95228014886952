<template>
    <component :is="baseComponent" :to="link.path ? link.path : '/'" :class="{ active: isActive }" tag="li">
        <a v-if="isMenu" href="#" class="nav-link sidebar-menu-item" :aria-expanded="!collapsed" data-toggle="collapse"
            @click.prevent="collapseMenu">
            <!-- <md-icon>{{ link.icon }}</md-icon> -->
            <p>
                {{ link.name }}
                <b class="caret"></b>
            </p>
        </a>
        <collapse-transition>
            <div v-if="$slots.default || this.isMenu" v-show="!collapsed">
                <ul class="nav">
                    <slot></slot>
                </ul>
            </div>
        </collapse-transition>
        <slot name="title" v-if="children.length === 0 && !$slots.default && link.path">
            <component :to="link.path" @click.native="linkClick" :is="elementType(link, false)"
                :class="{ active: isActive }" class="nav-link" :target="link.target" :href="link.path">
                <template v-if="addLink">
                    <!-- <span class="sidebar-mini">{{ linkPrefix }}</span> -->
                    <md-icon>{{ link.icon }}</md-icon>
                    <span class="sidebar-normal">{{ link.name }}</span>
                </template>
                <template v-else>
                    <md-icon>{{ link.icon }}</md-icon>
                    <p>{{ link.name }}</p>
                </template>
            </component>
        </slot>
    </component>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";

export default {
    name: "sidebar-item",
    components: {
        CollapseTransition
    },
    props: {
        menu: {
            type: Boolean,
            default: false
        },
        link: {
            type: Object,
            default: () => {
                return {
                    name: "",
                    path: "",
                    children: []
                };
            }
        },
        isDefault: {
            type: Boolean,
            default: false
        }
    },
    provide() {
        return {
            addLink: this.addChild,
            removeLink: this.removeChild
        };
    },
    inject: {
        addLink: { default: null },
        removeLink: { default: null },
        autoClose: {
            default: true
        }
    },
    data() {
        return {
            children: [],
            collapsed: true
        };
    },
    computed: {
        baseComponent() {
            return this.isMenu || this.link.isRoute ? "li" : "router-link";
        },
        linkPrefix() {
            if (this.link.name) {
                let words = this.link.name.split(" ");
                return words.map(word => word.substring(0, 1)).join("");
            }
            return false;
        },
        isMenu() {
            return this.children.length > 0 || this.menu === true;
        },
        isActive() {
            if (this.$route && this.$route.path) {
                let matchingRoute = this.children.find(c =>
                    this.$route.path.startsWith(c.link.path)
                );
                if (matchingRoute !== undefined) {
                    return true;
                }

                // Check subroutes names (translate value on link)
                if (this.link.name == this.$t('routes.activities.title') && (this.$route.name.substr(3) == 'activity_create' || this.$route.name.substr(3) == 'activity_edit')) {
                    return true;
                }
                if (this.link.name == this.$t('routes.airlines.title') && (this.$route.name.substr(3) == 'airline_create' || this.$route.name.substr(3) == 'airline_edit')) {
                    return true;
                }
                if (this.link.name == this.$t('routes.airports.title') && (this.$route.name.substr(3) == 'airport_create' || this.$route.name.substr(3) == 'airport_edit')) {
                    return true;
                }
                if (this.link.name == this.$t('routes.alerts.title') && (this.$route.name.substr(3) == 'alert_create' || this.$route.name.substr(3) == 'alert_edit')) {
                    return true;
                }
                if (this.link.name == this.$t('routes.destinations.title') && (this.$route.name.substr(3) == 'destination_create' || this.$route.name.substr(3) == 'destination_edit')) {
                    return true;
                }
                if (this.link.name == this.$t('routes.news.title') && (this.$route.name.substr(3) == 'newsItem_create' || this.$route.name.substr(3) == 'newsItem_edit')) {
                    return true;
                }
                if (this.link.name == this.$t('routes.restaurants.title') && (this.$route.name.substr(3) == 'restaurant_create' || this.$route.name.substr(3) == 'restaurant_edit')) {
                    return true;
                }
                if (this.link.name == this.$t('routes.shops.title') && (this.$route.name.substr(3) == 'shop_create' || this.$route.name.substr(3) == 'shop_edit')) {
                    return true;
                }
                if (this.link.name == this.$t('routes.services.title') && (this.$route.name.substr(3) == 'service_create' || this.$route.name.substr(3) == 'service_edit')) {
                    return true;
                }
                if (this.link.name == this.$t('routes.touristicRegions.title') && (this.$route.name.substr(3) == 'touristicRegion_create' || this.$route.name.substr(3) == 'touristicRegion_edit')) {
                    return true;
                }
                if (this.link.name == this.$t('routes.custom_pages.title') && (this.$route.name.substr(3) == 'custom_page_create' || this.$route.name.substr(3) == 'custom_page_edit')) {
                    return true;
                }
                if (this.link.name == this.$t('routes.ancillaries.title') && (this.$route.name.substr(3) == 'ancillary_create' || this.$route.name.substr(3) == 'ancillary_edit')) {
                    return true;
                }
                if (this.link.name == this.$t('routes.clients.title') && (this.$route.name.substr(3) == 'client_create' || this.$route.name.substr(3) == 'client_edit')) {
                    return true;
                }
                if (this.link.name == this.$t('routes.users.title') && (this.$route.name.substr(3) == 'user_create' || this.$route.name.substr(3) == 'user_edit')) {
                    return true;
                }

                if (this.link.name == this.$t('routes.faqs.title') && (this.$route.name.substr(3) == 'faq_create' || this.$route.name.substr(3) == 'faq_edit')) {
                    return true;
                }

                if (this.link.name == this.$t('routes.faqs.title') && (this.$route.name.substr(3) == 'faq_create' || this.$route.name.substr(3) == 'faq_edit')) {
                    return true;
                }

                if (this.link.name == this.$t('routes.clients.title') && (this.$route.name.substr(3) == 'client_user_create' || this.$route.name.substr(3) == 'client_user_edit')) {
                    return true;
                }

                if (this.link.name == this.$t('routes.mail_templates.title') && (this.$route.name.substr(3) == 'mail_template_create' || this.$route.name.substr(3) == 'mail_template_edit')) {
                    return true;
                }

                if (this.link.name == this.$t('routes.bookings.title') && this.$route.name.substr(3) == 'booking_edit') {
                    return true;
                }

                if (this.link.name == this.$t('routes.flights.title') && (this.$route.name.substr(3) == 'flight_create' || this.$route.name.substr(3) == 'flight_edit')) {
                    return true;
                }

                if (this.link.name == this.$t('routes.steps.title') && (this.$route.name.substr(3) == 'step_create' || this.$route.name.substr(3) == 'step_edit')) {
                    return true;
                }

                if (this.link.name == this.$t('routes.suggestion.title') && (this.$route.name.substr(3) == 'suggestion_create' || this.$route.name.substr(3) == 'suggestion_edit')) {
                    return true;
                }

            }
            return false;
        }
    },
    methods: {
        addChild(item) {
            const index = this.$slots.default.indexOf(item.$vnode);
            this.children.splice(index, 0, item);
        },
        removeChild(item) {
            const tabs = this.children;
            const index = tabs.indexOf(item);
            tabs.splice(index, 1);
        },
        elementType(link, isParent = true) {
            if (link.isRoute === false) {
                return isParent ? "li" : "a";
            } else {
                return "router-link";
            }
        },
        linkAbbreviation(name) {
            const matches = name.match(/\b(\w)/g);
            return matches.join("");
        },
        linkClick() {
            if (
                this.autoClose &&
                this.$sidebar &&
                this.$sidebar.showSidebar === true
            ) {
                this.$sidebar.displaySidebar(false);
            }
        },
        collapseMenu() {
            this.collapsed = !this.collapsed;
        },
        collapseSubMenu(link) {
            link.collapsed = !link.collapsed;
        }
    },
    mounted() {
        if (this.addLink) {
            this.addLink(this);
        }
        if (this.link.collapsed !== undefined) {
            this.collapsed = this.link.collapsed;
        }
        if (this.isActive && this.isMenu) {
            this.collapsed = false;
        }

        if (this.isMenu) {
            this.children.map(element => {
                if (element.isActive) {
                    this.collapsed = false;

                }
            });

        }

        if (this.isDefault) {
            this.collapsed = false;
        }


    },
    destroyed() {
        if (this.$el && this.$el.parentNode) {
            this.$el.parentNode.removeChild(this.$el);
        }
        if (this.removeLink) {
            this.removeLink(this);
        }
    }
};
</script>
<style>
.sidebar-menu-item {
    cursor: pointer;
}
</style>