import { Storage } from "aws-amplify";

export default {
    methods: {
        async uploadS3(file) {
            try {

                var contentTypeValue = "";

                switch (file.type) {
                    case "image/jpeg":
                        contentTypeValue = "image/jpeg";
                        break;
                    case "image/png":
                        contentTypeValue = "image/png";
                        break;
                    case "video/mp4":
                        contentTypeValue = "video/mp4";
                        break;
                    case "image/svg+xml":
                        contentTypeValue = "image/svg+xml";
                        break;
                }

                const s3Key = Date.now() + "_" + file.name;
                const storageResult = await Storage.put(s3Key, file, {
                    level: "public",
                    type: file.type,
                    contentType: contentTypeValue
                });

                if (storageResult) {
                    return storageResult;
                } else {
                    return false;
                }
            } catch (error) {
                console.error("Error uploading file to S3", error);
                return false;
            }
        },

        async getS3Url(key) {
            const S3URL = process.env.VUE_APP_S3_URL;
            return S3URL + key;
        },
    }
}