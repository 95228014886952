<template>
    <ValidationObserver tag="form" ref="form" v-slot="{ errors }">
        <div class="md-layout form-layout">
            <!-- Button -->
            <div class="md-layout-item md-size-100 form-toolbar">
                <h3 class="page-title">
                    <span v-if="creating">
                        {{ $t("routes.airline_create.title") }}
                    </span>
                    <span v-if="editing">
                        {{ $t("routes.airline_edit.title") }} {{ item.name }}
                    </span>
                </h3>
                <div class="form-buttons">
                    <md-progress-spinner v-if="loading" :md-diameter="30" :md-stroke="3" md-mode="indeterminate">
                    </md-progress-spinner>
                    <!-- Save button -->
                    <md-button class="md-primary md-round btn-medium" @click="save()">
                        {{ $t("save") }}
                    </md-button>
                </div>
            </div>
            <!-- IATACode y ICAOCode -->
            <div class="md-layout-item md-size-100 inlinefields">
                <!-- IATACode -->
                <div class="md-layout-item md-size-40">
                    <md-field :class="[
                        { 'md-valid': errors['IATACode'] && !errors['IATACode'][0] && touchedFields.IATACode },
                        { 'md-error': errors['IATACode'] && errors['IATACode'][0] }
                    ]">
                        <label for="author">{{ $t('fields.IATACode') }}</label>
                        <validation-provider name='IATACode' rules="required|max:3|min:2" v-slot="{ errors, touched }"
                            mode="aggressive">
                            <md-input v-model="item.IATACode" name="IATACode" type="text"
                                :placeholder="$t('label.airline_IATACode')" id="IATACode" :disabled="loading">
                            </md-input>
                            <slide-y-down-transition>
                                <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                            </slide-y-down-transition>
                            <span class="md-error">{{ errors[0] }}</span>
                        </validation-provider>
                    </md-field>
                </div>
                <!-- ICAOCode -->
                <div class="md-layout-item md-size-40">
                    <md-field :class="[
                        { 'md-valid': errors['ICAOCode'] && !errors['ICAOCode'][0] && touchedFields.ICAOCode },
                        { 'md-error': errors['ICAOCode'] && errors['ICAOCode'][0] }
                    ]">
                        <label for="author">{{ $t('fields.ICAOCode') }}</label>
                        <validation-provider name='ICAOCode' rules="" v-slot="{ errors, touched }" mode="aggressive">
                            <md-input v-model="item.ICAOCode" name="ICAOCode" type="text"
                                :placeholder="$t('label.airline_ICAOCode')" id="ICAOCode" :disabled="loading">
                            </md-input>
                            <slide-y-down-transition>
                                <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                            </slide-y-down-transition>
                            <span class="md-error">{{ errors[0] }}</span>
                        </validation-provider>
                    </md-field>
                </div>
            </div>

            <!-- media id -->
            <div class="md-layout-item md-size-45">
                <div class="md-layout">
                    <div>
                        <md-icon class="md-42">iconimage_search</md-icon>
                    </div>
                    <div class="md-layout-item md-size-65">
                        <md-field class="image-field">
                            <label class="label-images" v-if="item.media.fileName == ''">{{ $t("label.airline_image")
                            }}</label>
                            <label v-if="item.media.fileName == ''">{{ $t("label.select_image") }}</label>
                            <label v-else>{{ $t("label.airline_image") }}</label>
                            <md-input readonly id="mediaInput" v-model="item.media.fileName" :disabled="true">
                            </md-input>
                            <md-button v-if="item.media.fileName != ''" class="btn-floating" @click="clearMedia()">
                                <md-icon>close</md-icon>
                            </md-button>
                            <span class="md-error">
                                {{ errorMediaMessage }}
                            </span>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-20">
                        <md-button @click="openMediaModal()" class="md-primary md-round btn-medium">
                            {{ $t("select_file") }}
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="md-layout-item md-size-10"></div>
            <!-- media image preview -->
            <div class="md-layout-item md-size-45 centered">
                <div class="media-container-airline">
                    <md-icon v-if="item.media.imageURL == ''" class="md-size-2x">image</md-icon>
                    <img v-else :src="item.media.imageURL" :alt="item.imageAlt"
                        @click.prevent="viewImage(item.media.imageURL)" />
                </div>
            </div>
            <!-- Active -->
            <div class="md-layout-item md-size-45">
                <div class="field-switch">
                    <Span>{{ $t("label.airline_active") }}</Span>
                    <md-switch id="switchEnabled" v-model="item.active" :disabled="loading">
                    </md-switch>
                </div>
            </div>
            <div class="md-layout-item md-size-10"></div>
            <!-- Active AR -->
            <div class="md-layout-item md-size-45">
                <div class="field-switch">
                    <Span>{{ $t("label.airline_activeAR") }}</Span>
                    <md-switch id="switchEnabledAR" v-model="item.activeAR" :disabled="loading">
                    </md-switch>
                </div>
            </div>
            <!-- Luggage information -->
            <div class="md-layout-item md-size-100">
                <div class="Group-Header">
                    <span class="Luggage-information">
                        {{ $t('label.luggage_information') }}
                    </span>
                    <div class="Path"></div>
                </div>
            </div>

            <div class="md-layout-item md-size-100 inlinefields gaplg">
                <!-- luggageMaxHeight -->
                <md-field :class="[
                    { 'md-valid': errors['luggageMaxHeight'] && !errors['luggageMaxHeight'][0] && touchedFields.luggageMaxHeight },
                    { 'md-error': errors['luggageMaxHeight'] && errors['luggageMaxHeight'][0] }
                ]">
                    <label for="luggageMaxHeight">{{ $t('fields.luggageMaxHeight') }}</label>
                    <validation-provider name='luggageMaxHeight' rules="double" v-slot="{ errors, touched }"
                        mode="aggressive">
                        <md-input v-model="item.luggageMaxHeight" name="luggageMaxHeight" type="number"
                            :placeholder="$t('label.airline_luggageMaxHeight')" id="luggageMaxHeight" :disabled="loading">
                        </md-input>
                        <slide-y-down-transition>
                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                        </slide-y-down-transition>
                        <span class="md-error">{{ errors[0] }}</span>
                    </validation-provider>
                </md-field>
                <!-- luggageMaxWidth -->
                <md-field :class="[
                    { 'md-valid': errors['luggageMaxWidth'] && !errors['luggageMaxWidth'][0] && touchedFields.luggageMaxWidth },
                    { 'md-error': errors['luggageMaxWidth'] && errors['luggageMaxWidth'][0] }
                ]">
                    <label for="luggageMaxWidth">{{ $t('fields.luggageMaxWidth') }}</label>
                    <validation-provider name='luggageMaxWidth' rules="double" v-slot="{ errors, touched }"
                        mode="aggressive">
                        <md-input v-model="item.luggageMaxWidth" name="luggageMaxWidth" type="number"
                            :placeholder="$t('label.airline_luggageMaxWidth')" id="luggageMaxWidth" :disabled="loading">
                        </md-input>
                        <slide-y-down-transition>
                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                        </slide-y-down-transition>
                        <span class="md-error">{{ errors[0] }}</span>
                    </validation-provider>
                </md-field>
                <!-- luggageMaxLength -->
                <md-field :class="[
                    { 'md-valid': errors['luggageMaxLength'] && !errors['luggageMaxLength'][0] && touchedFields.luggageMaxLength },
                    { 'md-error': errors['luggageMaxLength'] && errors['luggageMaxLength'][0] }
                ]">
                    <label for="luggageMaxLength">{{ $t('fields.luggageMaxLength') }}</label>
                    <validation-provider name='luggageMaxLength' rules="double" v-slot="{ errors, touched }"
                        mode="aggressive">
                        <md-input v-model="item.luggageMaxLength" name="luggageMaxLength" type="number"
                            :placeholder="$t('label.airline_luggageMaxLength')" id="luggageMaxLength" :disabled="loading">
                        </md-input>
                        <slide-y-down-transition>
                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                        </slide-y-down-transition>
                        <span class="md-error">{{ errors[0] }}</span>
                    </validation-provider>
                </md-field>
            </div>
            <!-- Separator localizations -->
            <div class="md-layout-item md-size-100">
                <div class="Group-Header">
                    <span class="Luggage-information">
                        {{ $t('label.language_information') }}
                    </span>
                    <div class="Path"></div>
                </div>
            </div>
            <!-- Translations -->
            <md-card class="translations-card">
                <md-card-content>
                    <md-tabs class="translations-tabs">
                        <md-tab id="tab-tr" :md-label="$t('languages.tr')" :md-icon="getLanguageIcon('tr', errors)">
                            <!-- name tr -->
                            <md-field :class="[
                                { 'md-valid': errors['name_tr'] && !errors['name_tr'][0] && touchedFields.name_tr },
                                { 'md-error': errors['name_tr'] && errors['name_tr'][0] }
                            ]">
                                <label for="title">{{ $t('label.airline_name') }}</label>
                                <validation-provider name='name_tr' rules="required" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="item.name_tr" type="text" :placeholder="$t('label.airline_name')"
                                        :disabled="loading">
                                    </md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <!-- text tr -->
                            <md-field :class="[
                                { 'md-valid': errors['airline_text_tr'] && !errors['airline_text_tr'][0] && touchedFields.airline_text_tr },
                                { 'md-error': errors['airline_text_tr'] && errors['airline_text_tr'][0] }
                            ]">
                                <label for="airline_text">{{ $t('label.airline_text') }}</label>
                                <validation-provider name='airline_text_tr' rules="required"
                                    v-slot="{ errors, touched }" mode="aggressive">
                                    <md-textarea :md-autogrow="false" f
                                        v-model="item.airline_text_tr"
                                        name="airline_text_tr"
                                        :placeholder="$t('label.airline_text')"
                                        id="airline_text_tr" :disabled="loading">
                                    </md-textarea>
                                    <slide-y-down-transition>
                                        <md-icon class="error"
                                            v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <!-- website tr -->
                            <md-field :class="[
                                { 'md-valid': errors['website_tr'] && !errors['website_tr'][0] && touchedFields.website_tr },
                                { 'md-error': errors['website_tr'] && errors['website_tr'][0] }
                            ]">
                                <label for="title">{{ $t('label.airline_website') }}</label>
                                <validation-provider name='website_tr' rules="" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="item.website_tr" type="text"
                                        :placeholder="$t('label.airline_website')" :disabled="loading">
                                    </md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <div class="inlinefields gapsm">
                                <!-- contact email tr -->
                                <md-field :class="[
                                    { 'md-valid': errors['contactEmail_tr'] && !errors['contactEmail_tr'][0] && touchedFields.contactEmail_tr },
                                    { 'md-error': errors['contactEmail_tr'] && errors['contactEmail_tr'][0] }
                                ]">
                                    <label for="title">{{ $t('label.airline_contactEmail') }}</label>
                                    <validation-provider name='contactEmail_tr' rules="email" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-input v-model="item.contactEmail_tr" type="email"
                                            :placeholder="$t('label.airline_contactEmail')" :disabled="loading">
                                        </md-input>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                                <!-- contact phone tr -->
                                <md-field :class="[
                                    { 'md-valid': errors['contactPhone_tr'] && !errors['contactPhone_tr'][0] && touchedFields.contactPhone_tr },
                                    { 'md-error': errors['contactPhone_tr'] && errors['contactPhone_tr'][0] }
                                ]">
                                    <label for="title">{{ $t('label.airline_contactPhone') }}</label>
                                    <validation-provider name='contactPhone_tr' rules="" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-input v-model="item.contactPhone_tr" type="text"
                                            :placeholder="$t('label.airline_contactPhone')" :disabled="loading">
                                        </md-input>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                            <!-- customer service message tr -->
                            <md-field :class="[
                                { 'md-valid': errors['customerServiceMessage_tr'] && !errors['customerServiceMessage_tr'][0] && touchedFields.customerServiceMessage_tr },
                                { 'md-error': errors['customerServiceMessage_tr'] && errors['customerServiceMessage_tr'][0] }
                            ]">
                                <label for="title">{{ $t('label.airline_customerServiceMessage') }}</label>
                                <validation-provider name='customerServiceMessage_tr' rules="" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="item.customerServiceMessage_tr" type="text"
                                        :placeholder="$t('label.airline_customerServiceMessage')" :disabled="loading">
                                    </md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <!-- ground handling phone tr -->
                            <div class="inlinefields gapsm">
                                <!-- ground handling email tr -->

                                <md-field :class="[
                                    { 'md-valid': errors['groundHandlingEmail_tr'] && !errors['groundHandlingEmail_tr'][0] && touchedFields.groundHandlingEmail_tr },
                                    { 'md-error': errors['groundHandlingEmail_tr'] && errors['groundHandlingEmail_tr'][0] }
                                ]">
                                    <label for="title">{{ $t('label.airline_groundHandlingEmail') }}</label>
                                    <validation-provider name='groundHandlingEmail_tr' rules="email"
                                        v-slot="{ errors, touched }" mode="aggressive">
                                        <md-input v-model="item.groundHandlingEmail_tr" type="text"
                                            :placeholder="$t('label.airline_groundHandlingEmail')" :disabled="loading">
                                        </md-input>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                                <md-field :class="[
                                    { 'md-valid': errors['groundHandlingPhone_tr'] && !errors['groundHandlingPhone_tr'][0] && touchedFields.groundHandlingPhone_tr },
                                    { 'md-error': errors['groundHandlingPhone_tr'] && errors['groundHandlingPhone_tr'][0] }
                                ]">
                                    <label for="title">{{ $t('label.airline_groundHandlingPhone') }}</label>
                                    <validation-provider name='groundHandlingPhone_tr' rules="" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-input v-model="item.groundHandlingPhone_tr" type="text"
                                            :placeholder="$t('label.airline_groundHandlingPhone')" :disabled="loading">
                                        </md-input>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>

                            </div>
                            <!-- ground handling message tr -->
                            <md-field :class="[
                                { 'md-valid': errors['groundHandlingMessage_tr'] && !errors['groundHandlingMessage_tr'][0] && touchedFields.groundHandlingMessage_tr },
                                { 'md-error': errors['groundHandlingMessage_tr'] && errors['groundHandlingMessage_tr'][0] }
                            ]">
                                <label for="title">{{ $t('label.airline_groundHandlingMessage') }}</label>
                                <validation-provider name='groundHandlingMessage_tr' rules="" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="item.groundHandlingMessage_tr" type="text"
                                        :placeholder="$t('label.airline_groundHandlingMessage')" :disabled="loading">
                                    </md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                        </md-tab>
                        <md-tab id="tab-en" :md-label="$t('languages.en')" :md-icon="getLanguageIcon('en', errors)">
                            <!-- name en -->
                            <md-field :class="[
                                { 'md-valid': errors['name_en'] && !errors['name_en'][0] && touchedFields.name_en },
                                { 'md-error': errors['name_en'] && errors['name_en'][0] }
                            ]">
                                <label for="title">{{ $t('label.airline_name') }}</label>
                                <validation-provider name='name_en' rules="required" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="item.name_en" type="text" :placeholder="$t('label.airline_name')"
                                        :disabled="loading">
                                    </md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                               <!-- text EN -->
                               <md-field :class="[
                                { 'md-valid': errors['airline_text_en'] && !errors['airline_text_en'][0] && touchedFields.airline_text_en },
                                { 'md-error': errors['airline_text_en'] && errors['airline_text_en'][0] }
                            ]">
                                <label for="airline_text">{{ $t('label.airline_text') }}</label>
                                <validation-provider name='airline_text_en' rules="required"
                                    v-slot="{ errors, touched }" mode="aggressive">
                                    <md-textarea :md-autogrow="false" f
                                        v-model="item.airline_text_en"
                                        name="airline_text_en"
                                        :placeholder="$t('label.airline_text')"
                                        id="airline_text_en" :disabled="loading">
                                    </md-textarea>
                                    <slide-y-down-transition>
                                        <md-icon class="error"
                                            v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <!-- website en -->
                            <md-field :class="[
                                { 'md-valid': errors['website_en'] && !errors['website_en'][0] && touchedFields.website_en },
                                { 'md-error': errors['website_en'] && errors['website_en'][0] }
                            ]">
                                <label for="title">{{ $t('label.airline_website') }}</label>
                                <validation-provider name='website_en' rules="" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="item.website_en" type="text"
                                        :placeholder="$t('label.airline_website')" :disabled="loading">
                                    </md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <div class="inlinefields gapsm">
                                <!-- contact email en -->
                                <md-field :class="[
                                    { 'md-valid': errors['contactEmail_en'] && !errors['contactEmail_en'][0] && touchedFields.contactEmail_en },
                                    { 'md-error': errors['contactEmail_en'] && errors['contactEmail_en'][0] }
                                ]">
                                    <label for="title">{{ $t('label.airline_contactEmail') }}</label>
                                    <validation-provider name='contactEmail_en' rules="email" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-input v-model="item.contactEmail_en" type="email"
                                            :placeholder="$t('label.airline_contactEmail')" :disabled="loading">
                                        </md-input>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                                <!-- contact phone en -->
                                <md-field :class="[
                                    { 'md-valid': errors['contactPhone_en'] && !errors['contactPhone_en'][0] && touchedFields.contactPhone_en },
                                    { 'md-error': errors['contactPhone_en'] && errors['contactPhone_en'][0] }
                                ]">
                                    <label for="title">{{ $t('label.airline_contactPhone') }}</label>
                                    <validation-provider name='contactPhone_en' rules="" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-input v-model="item.contactPhone_en" type="text"
                                            :placeholder="$t('label.airline_contactPhone')" :disabled="loading">
                                        </md-input>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                            <!-- customer service message en -->
                            <md-field :class="[
                                { 'md-valid': errors['customerServiceMessage_en'] && !errors['customerServiceMessage_en'][0] && touchedFields.customerServiceMessage_en },
                                { 'md-error': errors['customerServiceMessage_en'] && errors['customerServiceMessage_en'][0] }
                            ]">
                                <label for="title">{{ $t('label.airline_customerServiceMessage') }}</label>
                                <validation-provider name='customerServiceMessage_en' rules="" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="item.customerServiceMessage_en" type="text"
                                        :placeholder="$t('label.airline_customerServiceMessage')" :disabled="loading">
                                    </md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                            <!-- ground handling phone en -->
                            <div class="inlinefields gapsm">
                                <!-- ground handling email en -->

                                <md-field :class="[
                                    { 'md-valid': errors['groundHandlingEmail_en'] && !errors['groundHandlingEmail_en'][0] && touchedFields.groundHandlingEmail_en },
                                    { 'md-error': errors['groundHandlingEmail_en'] && errors['groundHandlingEmail_en'][0] }
                                ]">
                                    <label for="title">{{ $t('label.airline_groundHandlingEmail') }}</label>
                                    <validation-provider name='groundHandlingEmail_en' rules="email"
                                        v-slot="{ errors, touched }" mode="aggressive">
                                        <md-input v-model="item.groundHandlingEmail_en" type="text"
                                            :placeholder="$t('label.airline_groundHandlingEmail')" :disabled="loading">
                                        </md-input>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                                <md-field :class="[
                                    { 'md-valid': errors['groundHandlingPhone_en'] && !errors['groundHandlingPhone_en'][0] && touchedFields.groundHandlingPhone_en },
                                    { 'md-error': errors['groundHandlingPhone_en'] && errors['groundHandlingPhone_en'][0] }
                                ]">
                                    <label for="title">{{ $t('label.airline_groundHandlingPhone') }}</label>
                                    <validation-provider name='groundHandlingPhone_en' rules="" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-input v-model="item.groundHandlingPhone_en" type="text"
                                            :placeholder="$t('label.airline_groundHandlingPhone')" :disabled="loading">
                                        </md-input>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>

                            </div>
                            <!-- ground handling message en -->
                            <md-field :class="[
                                { 'md-valid': errors['groundHandlingMessage_en'] && !errors['groundHandlingMessage_en'][0] && touchedFields.groundHandlingMessage_en },
                                { 'md-error': errors['groundHandlingMessage_en'] && errors['groundHandlingMessage_en'][0] }
                            ]">
                                <label for="title">{{ $t('label.airline_groundHandlingMessage') }}</label>
                                <validation-provider name='groundHandlingMessage_en' rules="" v-slot="{ errors, touched }"
                                    mode="aggressive">
                                    <md-input v-model="item.groundHandlingMessage_en" type="text"
                                        :placeholder="$t('label.airline_groundHandlingMessage')" :disabled="loading">
                                    </md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                        </md-tab>
                    </md-tabs>
                </md-card-content>
            </md-card>
            <!-- Media Modal -->
            <media-modal :showProp="showMediaModal" @apply="applyMedia" @close="closeMediaModal()"></media-modal>
        </div>
    </ValidationObserver>
</template>

<script>
import MediaModal from "@/components/Modals/Media";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import { getAirline, createAirline, updateAirline } from "@/services/airline";
import localeMixin from "@/mixins/locale";


export default {
    mixins: [localeMixin],
    components: {
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
        MediaModal,
    },
    data() {
        return {

            loading: false,
            creating: false,
            editing: false,
            imagesLightbox: [],
            touchedFields: {
                IATACode: false,
                ICAOCode: false,
                luggageMaxWidth: false,
                luggageMaxHeight: false,
                luggageMaxLength: false,
                name_tr: false,
                name_en: false,
                website_tr: false,
                website_en: false,
                contactPhone_tr: false,
                contactPhone_en: false,
                contactEmail_tr: false,
                contactEmail_en: false,
                customerServiceMessage_tr: false,
                customerServiceMessage_en: false,
                groundHandlingPhone_tr: false,
                groundHandlingPhone_en: false,
                groundHandlingEmail_tr: false,
                groundHandlingEmail_en: false,
                groundHandlingMessage_tr: false,
                groundHandlingMessage_en: false,
                airline_text_tr: false,
                airline_text_en: false,
            },
            item: {
                airlineId: "",
                IATACode: "",
                ICAOCode:"",
                name: "",
                active: false,
                activeAR: false,
                luggageMaxWidth: 0,
                luggageMaxHeight: 0,
                luggageMaxLength: 0,
                mediaId: 0,
                imageURL: "",
                fileName: "",
                imageAlt: "",
                media: {
                    fileName: "", imageURL: "", imageAlt: ""
                },
                translationId_tr: 0,
                name_tr: "",
                airline_text_tr: "",
                website_tr: "",
                contactPhone_tr: "",
                contactEmail_tr: "",
                customerServiceMessage_tr: "",
                groundHandlingPhone_tr: "",
                groundHandlingEmail_tr: "",
                groundHandlingMessage_tr: "",
                translationId_en: 0,
                name_en: "",
                airline_text_en: "",
                website_en: "",
                contactPhone_en: "",
                contactEmail_en: "",
                customerServiceMessage_en: "",
                groundHandlingPhone_en: "",
                groundHandlingEmail_en: "",
                groundHandlingMessage_en: ""

            },
            errorMediaMessage: "",
            showMediaModal: false,
        };
    },

    created: async function () {
        let airlineId = this.$route.params.airlineId;
        if (airlineId) {
            await this.editItem(airlineId);
        } else {
            this.newItem();
        }
    },

    methods: {

        viewImage(imageUrl) {
            this.imagesLightbox = [];
            this.$imageViewer.images(this.imagesLightbox);
            const image = {
                url: imageUrl,
            };
            this.imagesLightbox.push(image);
            this.$imageViewer.index(0);
            this.$imageViewer.show();
        },

        applyMedia(media) {
            this.item.media = media;
            this.item.mediaId = media.mediaId;
            this.item.imageURL = media.imageURL;
            this.item.fileName = media.fileName;
            this.item.imageAlt = media.imageAlt;
            this.errorMediaMessage = "";
        },

        clearMedia() {

            this.item.media = { fileName: "", imageURL: "", imageAlt: "" };
            this.item.mediaId = 0;
            this.item.imageURL = this.item.media.imageURL;
            this.item.fileName = this.item.media.fileName;
            this.item.imageAlt = this.item.media.imageAlt;
            this.errorMediaMessage = "";
        },

        openMediaModal() {
            this.showMediaModal = true;
        },

        closeMediaModal() {
            this.showMediaModal = false;
            if (this.item.mediaId == 0) {
                this.errorMediaMessage = this.$t('validation.required', { _field_: this.$t('label.airline_image') });
            }
        },

        async save() {

            this.errorMediaMessage = "";

            var valid = await this.$refs.form.validate();


            if (this.item.mediaId == 0) {
                valid = false;
                this.errorMediaMessage = this.$t('validation.required', { _field_: this.$t('label.airline_image') });
            }

            if (!valid) {
                this.$noty.error(this.$t('error.saved'));
            }


            if (valid) {
                this.loading = true;
                if (this.creating) {
                    let response = await createAirline(this.convertDataModel());
                    if (response.status == "201") {
                        this.$noty.success(this.$t('success.saved'));
                        this.$router.push({ name: this.$i18n.locale + '.airlines' });

                    } else {
                        if (response.status == "422") {
                            if (response.error) {
                                switch (response.error) {
                                    case "ERR00":
                                        var errorMessage = this.$t('error.ERR00', { _field_: this.$t('label.airline_IATACode'), _value_: response.value });
                                        this.$noty.error(errorMessage);
                                        break;
                                    default:
                                        this.$noty.error(this.$t("error.saved"));
                                }
                            } else {
                                this.$noty.error(this.$t("error.saved"));
                            }
                        } else {
                            this.$noty.error(this.$t("error.saved"));
                        }
                    }
                }
                if (this.editing) {
                    let response = await updateAirline(this.convertDataModel());
                    if (response.status == "200") {
                        this.$noty.success(this.$t('success.saved'));
                    } else {
                        if (response.status == "422") {
                            if (response.error) {
                                switch (response.error) {
                                    case "ERR00":
                                        var errorMessageUp = this.$t('error.ERR00', { _field_: this.$t('label.airline_IATACode'), _value_: response.value });
                                        this.$noty.error(errorMessageUp);
                                        break;
                                    default:
                                        this.$noty.error(this.$t("error.saved"));
                                }
                            } else {
                                this.$noty.error(this.$t("error.saved"));
                            }
                        } else {
                            this.$noty.error(this.$t("error.saved"));
                        }
                    }
                }
                this.loading = false;


            }

        },


        assignDataModel(item) {
            if (item.airlineId) {
                this.item.airlineId = item.airlineId;
            } else {
                this.item.airlineId = "";
            }

            if (item.name) {
                this.item.name = item.name;
            } else {
                this.item.name = "";
            }

            if (item.IATACode) {
                this.item.IATACode = item.IATACode;
            } else {
                this.item.IATACode = "";
            }

            if (item.ICAOCode) {
                this.item.ICAOCode = item.ICAOCode;
            } else {
                this.item.ICAOCode = "";
            }

            

            if (item.active) {
                this.item.active = Boolean(item.active);
            } else {
                this.item.active = false;
            }

            if (item.activeAR) {
                this.item.activeAR = Boolean(item.activeAR);
            } else {
                this.item.activeAR = false;
            }

            if (item.luggageMaxWidth) {
                this.item.luggageMaxWidth = item.luggageMaxWidth;
            } else {
                this.item.luggageMaxWidth = 0;
            }

            if (item.luggageMaxHeight) {
                this.item.luggageMaxHeight = item.luggageMaxHeight;
            } else {
                this.item.luggageMaxHeight = 0;
            }

            if (item.luggageMaxLength) {
                this.item.luggageMaxLength = item.luggageMaxLength;
            } else {
                this.item.luggageMaxLength = 0;
            }

            if (item.mediaId) {
                this.item.mediaId = item.mediaId;
            } else {
                this.item.mediaId = 0;
            }

            if (item.fileName) {
                this.item.media.fileName = item.fileName;
            } else {
                this.item.media.fileName = "";
            }

            if (item.imageURL) {
                this.item.media.imageURL = item.imageURL;
            } else {
                this.item.media.imageURL = "";
            }

            if (item.imageAlt) {
                this.item.imageAlt = item.imageAlt;
            } else {
                this.item.imageAlt = "";
            }


            var translation = item.translations.filter(t => t.locale == 'en')[0];

            if (translation) {


                if (translation.translationId) {
                    this.item.translationId_en = translation.translationId;
                } else {
                    this.item.translationId_en = 0;
                }

                if (translation.name) {
                    this.item.name_en = translation.name;
                } else {
                    this.item.name_en = "";
                }

                if (translation.website) {
                    this.item.website_en = translation.website;
                } else {
                    this.item.website_en = "";
                }

                if (translation.contactPhone) {
                    this.item.contactPhone_en = translation.contactPhone;
                } else {
                    this.item.contactPhone_en = "";
                }

                if (translation.contactEmail) {
                    this.item.contactEmail_en = translation.contactEmail;
                } else {
                    this.item.contactEmail_en = "";
                }

                if (translation.customerServiceMessage) {
                    this.item.customerServiceMessage_en = translation.customerServiceMessage;
                } else {
                    this.item.customerServiceMessage_en = "";
                }

                if (translation.groundHandlingPhone) {
                    this.item.groundHandlingPhone_en = translation.groundHandlingPhone;
                } else {
                    this.item.groundHandlingPhone_en = "";
                }

                if (translation.groundHandlingEmail) {
                    this.item.groundHandlingEmail_en = translation.groundHandlingEmail;
                } else {
                    this.item.groundHandlingEmail_en = "";
                }

                if (translation.groundHandlingMessage) {
                    this.item.groundHandlingMessage_en = translation.groundHandlingMessage;
                } else {
                    this.item.groundHandlingMessage_en = "";
                }

                if (translation.text) {
                    this.item.airline_text_en = translation.text;
                } else {
                    this.item.airline_text_en = "";
                }


            } else {

                this.item.translationId_en = 0;
                this.item.name_en = "";
                this.item.website_en = "";
                this.item.contactPhone_en = "";
                this.item.contactEmail_en = "";
                this.item.customerServiceMessage_en = "";
                this.item.groundHandlingPhone_en = "";
                this.item.groundHandlingEmail_en = "";
                this.item.groundHandlingMessage_en = "";
                this.item.airline_text_en = "";

            }


            translation = item.translations.filter(t => t.locale == 'tr')[0];

            if (translation) {


                if (translation.translationId) {
                    this.item.translationId_tr = translation.translationId;
                } else {
                    this.item.translationId_tr = 0;
                }

                if (translation.name) {
                    this.item.name_tr = translation.name;
                } else {
                    this.item.name_tr = "";
                }

                if (translation.website) {
                    this.item.website_tr = translation.website;
                } else {
                    this.item.website_tr = "";
                }

                if (translation.contactPhone) {
                    this.item.contactPhone_tr = translation.contactPhone;
                } else {
                    this.item.contactPhone_tr = "";
                }

                if (translation.contactEmail) {
                    this.item.contactEmail_tr = translation.contactEmail;
                } else {
                    this.item.contactEmail_tr = "";
                }

                if (translation.customerServiceMessage) {
                    this.item.customerServiceMessage_tr = translation.customerServiceMessage;
                } else {
                    this.item.customerServiceMessage_tr = "";
                }

                if (translation.groundHandlingPhone) {
                    this.item.groundHandlingPhone_tr = translation.groundHandlingPhone;
                } else {
                    this.item.groundHandlingPhone_tr = "";
                }

                if (translation.groundHandlingEmail) {
                    this.item.groundHandlingEmail_tr = translation.groundHandlingEmail;
                } else {
                    this.item.groundHandlingEmail_tr = "";
                }

                if (translation.groundHandlingMessage) {
                    this.item.groundHandlingMessage_tr = translation.groundHandlingMessage;
                } else {
                    this.item.groundHandlingMessage_tr = "";
                }

                if (translation.text) {
                    this.item.airline_text_tr = translation.text;
                } else {
                    this.item.airline_text_tr = "";
                }


            } else {

                this.item.translationId_tr = 0;
                this.item.name_tr = "";
                this.item.website_tr = "";
                this.item.contactPhone_tr = "";
                this.item.contactEmail_tr = "";
                this.item.customerServiceMessage_tr = "";
                this.item.groundHandlingPhone_tr = "";
                this.item.groundHandlingEmail_tr = "";
                this.item.groundHandlingMessage_tr = "";
                this.item.airline_text_tr = "";

            }
        },


        convertDataModel() {

            var newItem = {};
            newItem.airlineId = this.item.airlineId;
            newItem.mediaId = this.item.mediaId;
            newItem.IATACode = this.item.IATACode;
            newItem.ICAOCode = this.item.ICAOCode;
            newItem.active = Number(this.item.active);
            newItem.activeAR = Number(this.item.activeAR);
            newItem.luggageMaxWidth = Number(this.item.luggageMaxWidth);
            newItem.luggageMaxHeight = Number(this.item.luggageMaxHeight);
            newItem.luggageMaxLength = Number(this.item.luggageMaxLength);
            newItem.translations = [];
            var trans_item_tr = {};
            trans_item_tr.translationId = this.item.translationId_tr;
            trans_item_tr.name = this.item.name_tr;
            trans_item_tr.website = this.item.website_tr;
            trans_item_tr.contactPhone = this.item.contactPhone_tr;
            trans_item_tr.contactEmail = this.item.contactEmail_tr;
            trans_item_tr.customerServiceMessage = this.item.customerServiceMessage_tr;
            trans_item_tr.groundHandlingPhone = this.item.groundHandlingPhone_tr;
            trans_item_tr.groundHandlingEmail = this.item.groundHandlingEmail_tr;
            trans_item_tr.groundHandlingMessage = this.item.groundHandlingMessage_tr;
            trans_item_tr.text = this.item.airline_text_tr;
            trans_item_tr.locale = "tr";
            newItem.translations.push(trans_item_tr);
            var trans_item_en = {};
            trans_item_en.translationId = this.item.translationId_en;
            trans_item_en.name = this.item.name_en;
            trans_item_en.website = this.item.website_en;
            trans_item_en.contactPhone = this.item.contactPhone_en;
            trans_item_en.contactEmail = this.item.contactEmail_en;
            trans_item_en.customerServiceMessage = this.item.customerServiceMessage_en;
            trans_item_en.groundHandlingPhone = this.item.groundHandlingPhone_en;
            trans_item_en.groundHandlingEmail = this.item.groundHandlingEmail_en;
            trans_item_en.groundHandlingMessage = this.item.groundHandlingMessage_en;
            trans_item_en.text = this.item.airline_text_en;
            trans_item_en.locale = "en";
            newItem.translations.push(trans_item_en);
            return newItem;

        },

        newItem() {
            this.creating = true;
            this.editing = false;
        },


        editItem(itemId) {
            this.creating = false;
            this.editing = true;
            this.fetchItem(itemId);
        },


        async fetchItem(itemId) {
            this.loading = true;
            let response = await getAirline(itemId);
            if (response) {
                this.assignDataModel(response);
            }
            this.loading = false;
        }
    }
}
</script>

<style scoped>
.md-layout-item.centered {
    display: flex;
    justify-content: center;
}

.md-layout-item-padding {
    padding-left: 12px;
}


.media-container-airline {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 394px;
    max-width: 394px;
    min-width: 20px;
    height: 67px;
    max-height: 67px;
    min-height: 67px;
    background-color: var(--media-background);
    border: solid 1px var(--media-border);
    overflow: hidden;
}

.media-container-airline img {
    object-fit: fill;
    cursor: pointer;
}



.inlinefields {
    display: flex;
    padding: 0px;
    justify-content: space-between;
}

.gaplg {
    gap: 250px;
}

.gapsm {
    gap: 25px;
}
</style>