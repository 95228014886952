var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-95 ml-auto mt-4 md-small-size-100"},[_c('ValidationObserver',{ref:"validator",scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('form',[_c('md-field',{class:[
                        { 'md-valid': errors['email'] && !errors['email'][0] && _vm.touchedFields.email },
                        { 'md-form-group': true },
                        { 'md-error': errors['email'] && errors['email'][0] }
                    ],attrs:{"slot":"inputs"},slot:"inputs"},[_c('md-icon',[_vm._v("email")]),_c('label',{attrs:{"for":"email"}},[_vm._v(_vm._s(_vm.$t('fields.email')))]),_c('validation-provider',{attrs:{"name":"email","rules":"required|email","mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, touched }){return [_c('md-input',{attrs:{"name":"email","type":"email","placeholder":_vm.$t('fields.email'),"disabled":_vm.loading},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"error"},[_vm._v("close")])],1),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('md-field',{class:[
                        { 'md-valid': errors['confirmation_code'] && !errors['confirmation_code'][0] && _vm.touchedFields.confirmation_code },
                        { 'md-form-group': true },
                        { 'md-error': errors['confirmation_code'] && errors['confirmation_code'][0] }
                    ],attrs:{"slot":"inputs"},slot:"inputs"},[_c('md-icon',[_vm._v("confirmation_number")]),_c('label',{attrs:{"for":"confirmation_code"}},[_vm._v(_vm._s(_vm.$t('fields.confirmation_code')))]),_c('validation-provider',{attrs:{"name":"confirmation_code","rules":"required","mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, touched }){return [_c('md-input',{attrs:{"name":"confirmation_code","placeholder":_vm.$t('fields.confirmation_code'),"id":"confirmation_code","disabled":_vm.loading},model:{value:(_vm.confirmation_code),callback:function ($$v) {_vm.confirmation_code=$$v},expression:"confirmation_code"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"error"},[_vm._v("close")])],1),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('md-field',{class:[
                        { 'md-valid': errors['password'] && !errors['password'][0] && _vm.touchedFields.password },
                        { 'md-form-group': true },
                        { 'md-error': errors['password'] && errors['password'][0] }
                    ],attrs:{"slot":"inputs"},slot:"inputs"},[_c('md-icon',[_vm._v("lock_outline")]),_c('label',{attrs:{"for":"password"}},[_vm._v(_vm._s(_vm.$t('fields.password')))]),_c('validation-provider',{attrs:{"name":"password","rules":"required","mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, touched }){return [_c('md-input',{attrs:{"name":"password","type":"password","placeholder":_vm.$t('fields.password'),"id":"password","disabled":_vm.loading},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"error"},[_vm._v("close")])],1),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]}}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }