<template>
    <div>
        <md-dialog :md-close-on-esc="false" :md-active.sync="show" :md-click-outside-to-close="false" class="modal-product">
            <md-dialog-title class="modal-media-header">
                <h3>
                    {{ parentNameProp }} - {{ $t("opening_schedule") }}
                    <md-progress-spinner v-if="loading" :md-diameter="20" :md-stroke="1" md-mode="indeterminate">
                    </md-progress-spinner>
                </h3>
                <md-button @click="closeModal()" class="
                            md-primary md-just-icon md-round md-simple
                            modal-close-btn
                        ">
                    <md-icon class="md-size-2x">close</md-icon>
                </md-button>
            </md-dialog-title>
            <md-dialog-content class="schedule-modal-content">
                <div class="save-toolbar">
                    <md-button :disabled="loading" class="md-primary md-round btn-mark" @click.prevent="showfilldialog()">
                        {{ $t("label.24x7") }}
                    </md-button>
                    <md-button :disabled="loading" class="md-primary md-round btn-save" @click="save()">
                        {{ $t("save") }}
                    </md-button>
                </div>
                <ValidationObserver tag="form" ref="form" v-slot="{ errors }">
                    <div class="opening-schedule-container">
                        <!-- Opening Schedule Headers -->
                        <div class="opening-schedule-header">
                            {{ $t("label.week_day") }}
                        </div>
                        <div class="opening-schedule-header">
                            {{ $t("label.open") }}
                        </div>
                        <div class="opening-schedule-header">
                            {{ $t("label.opening_time") }}
                        </div>
                        <div class="opening-schedule-header">
                            {{ $t("label.closing_time") }}
                        </div>
                        <hr>
                        <!-- Opening Schedule Monday -->
                        <div>
                            {{ $t("week_days.monday") }}
                        </div>
                        <div>
                            <md-switch v-model="schedules[0].openingHours[0].openingStatus" :disabled="loading"></md-switch>
                        </div>
                        <div>
                            <md-field class="field-hour" :class="[
                                { 'md-valid': errors['opening_time_monday'] && !errors['opening_time_monday'][0] && touchedFields.opening_time_monday },
                                { 'md-error': errors['opening_time_monday'] && errors['opening_time_monday'][0] }
                            ]">
                                <validation-provider name="opening_time_monday"
                                    :rules="getValidationRules(schedules[0].openingHours[0].openingStatus)"
                                    v-slot="{ errors, touched }" mode="aggressive">
                                    <md-input v-model="schedules[0].openingHours[0].openHour"
                                        :disabled="loading"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                        </div>
                        <div>
                            <md-field class="field-hour" :class="[
                                { 'md-valid': errors['closing_time_monday'] && !errors['closing_time_monday'][0] && touchedFields.closing_time_monday },
                                { 'md-error': errors['closing_time_monday'] && errors['closing_time_monday'][0] }
                            ]">
                                <validation-provider name="closing_time_monday"
                                    :rules="getValidationRules(schedules[0].openingHours[0].openingStatus)"
                                    v-slot="{ errors, touched }" mode="aggressive">
                                    <md-input v-model="schedules[0].openingHours[0].closeHour"
                                        :disabled="loading"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                        </div>
                        <!-- Opening Schedule Tuesday -->
                        <div>
                            {{ $t("week_days.tuesday") }}
                        </div>
                        <div>
                            <md-switch v-model="schedules[1].openingHours[0].openingStatus" :disabled="loading"></md-switch>
                        </div>
                        <div>
                            <md-field class="field-hour" :class="[
                                { 'md-valid': errors['opening_time_tuesday'] && !errors['opening_time_tuesday'][0] && touchedFields.opening_time_tuesday },
                                { 'md-error': errors['opening_time_tuesday'] && errors['opening_time_tuesday'][0] }
                            ]">
                                <validation-provider name="opening_time_tuesday"
                                    :rules="getValidationRules(schedules[1].openingHours[0].openingStatus)"
                                    v-slot="{ errors, touched }" mode="aggressive">
                                    <md-input v-model="schedules[1].openingHours[0].openHour"
                                        :disabled="loading"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                        </div>
                        <div>
                            <md-field class="field-hour" :class="[
                                { 'md-valid': errors['closing_time_tuesday'] && !errors['closing_time_tuesday'][0] && touchedFields.closing_time_tuesday },
                                { 'md-error': errors['closing_time_tuesday'] && errors['closing_time_tuesday'][0] }
                            ]">
                                <validation-provider name="closing_time_tuesday"
                                    :rules="getValidationRules(schedules[1].openingHours[0].openingStatus)"
                                    v-slot="{ errors, touched }" mode="aggressive">
                                    <md-input v-model="schedules[1].openingHours[0].closeHour"
                                        :disabled="loading"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                        </div>
                        <!-- Opening Schedule Wednesday -->
                        <div>
                            {{ $t("week_days.wednesday") }}
                        </div>
                        <div>
                            <md-switch v-model="schedules[2].openingHours[0].openingStatus" :disabled="loading"></md-switch>
                        </div>
                        <div>
                            <md-field class="field-hour" :class="[
                                { 'md-valid': errors['opening_time_wednesday'] && !errors['opening_time_wednesday'][0] && touchedFields.opening_time_wednesday },
                                { 'md-error': errors['opening_time_wednesday'] && errors['opening_time_wednesday'][0] }
                            ]">
                                <validation-provider name="opening_time_wednesday"
                                    :rules="getValidationRules(schedules[2].openingHours[0].openingStatus)"
                                    v-slot="{ errors, touched }" mode="aggressive">
                                    <md-input v-model="schedules[2].openingHours[0].openHour"
                                        :disabled="loading"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                        </div>
                        <div>
                            <md-field class="field-hour" :class="[
                                { 'md-valid': errors['closing_time_wednesday'] && !errors['closing_time_wednesday'][0] && touchedFields.closing_time_wednesday },
                                { 'md-error': errors['closing_time_wednesday'] && errors['closing_time_wednesday'][0] }
                            ]">
                                <validation-provider name="closing_time_wednesday"
                                    :rules="getValidationRules(schedules[2].openingHours[0].openingStatus)"
                                    v-slot="{ errors, touched }" mode="aggressive">
                                    <md-input v-model="schedules[2].openingHours[0].closeHour"
                                        :disabled="loading"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                        </div>
                        <!-- Opening Schedule Thursday -->
                        <div>
                            {{ $t("week_days.thursday") }}
                        </div>
                        <div>
                            <md-switch v-model="schedules[3].openingHours[0].openingStatus" :disabled="loading"></md-switch>
                        </div>
                        <div>
                            <md-field class="field-hour" :class="[
                                { 'md-valid': errors['opening_time_thursday'] && !errors['opening_time_thursday'][0] && touchedFields.opening_time_thursday },
                                { 'md-error': errors['opening_time_thursday'] && errors['opening_time_thursday'][0] }
                            ]">
                                <validation-provider name="opening_time_thursday"
                                    :rules="getValidationRules(schedules[3].openingHours[0].openingStatus)"
                                    v-slot="{ errors, touched }" mode="aggressive">
                                    <md-input v-model="schedules[3].openingHours[0].openHour"
                                        :disabled="loading"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                        </div>
                        <div>
                            <md-field class="field-hour" :class="[
                                { 'md-valid': errors['closing_time_thursday'] && !errors['closing_time_thursday'][0] && touchedFields.closing_time_thursday },
                                { 'md-error': errors['closing_time_thursday'] && errors['closing_time_thursday'][0] }
                            ]">
                                <validation-provider name="closing_time_thursday"
                                    :rules="getValidationRules(schedules[3].openingHours[0].openingStatus)"
                                    v-slot="{ errors, touched }" mode="aggressive">
                                    <md-input v-model="schedules[3].openingHours[0].closeHour"
                                        :disabled="loading"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                        </div>
                        <!-- Opening Schedule Friday -->
                        <div>
                            {{ $t("week_days.friday") }}
                        </div>
                        <div>
                            <md-switch v-model="schedules[4].openingHours[0].openingStatus" :disabled="loading"></md-switch>
                        </div>
                        <div>
                            <md-field class="field-hour" :class="[
                                { 'md-valid': errors['opening_time_friday'] && !errors['opening_time_friday'][0] && touchedFields.opening_time_friday },
                                { 'md-error': errors['opening_time_friday'] && errors['opening_time_friday'][0] }
                            ]">
                                <validation-provider name="opening_time_friday"
                                    :rules="getValidationRules(schedules[4].openingHours[0].openingStatus)"
                                    v-slot="{ errors, touched }" mode="aggressive">
                                    <md-input v-model="schedules[4].openingHours[0].openHour"
                                        :disabled="loading"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                        </div>
                        <div>
                            <md-field class="field-hour" :class="[
                                { 'md-valid': errors['closing_time_friday'] && !errors['closing_time_friday'][0] && touchedFields.closing_time_friday },
                                { 'md-error': errors['closing_time_friday'] && errors['closing_time_friday'][0] }
                            ]">
                                <validation-provider name="closing_time_friday"
                                    :rules="getValidationRules(schedules[4].openingHours[0].openingStatus)"
                                    v-slot="{ errors, touched }" mode="aggressive">
                                    <md-input v-model="schedules[4].openingHours[0].closeHour"
                                        :disabled="loading"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                        </div>
                        <!-- Opening Schedule Saturday -->
                        <div>
                            {{ $t("week_days.saturday") }}
                        </div>
                        <div>
                            <md-switch v-model="schedules[5].openingHours[0].openingStatus" :disabled="loading"></md-switch>
                        </div>
                        <div>
                            <md-field class="field-hour" :class="[
                                { 'md-valid': errors['opening_time_saturday'] && !errors['opening_time_saturday'][0] && touchedFields.opening_time_saturday },
                                { 'md-error': errors['opening_time_saturday'] && errors['opening_time_saturday'][0] }
                            ]">
                                <validation-provider name="opening_time_saturday"
                                    :rules="getValidationRules(schedules[5].openingHours[0].openingStatus)"
                                    v-slot="{ errors, touched }" mode="aggressive">
                                    <md-input v-model="schedules[5].openingHours[0].openHour"
                                        :disabled="loading"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                        </div>
                        <div>
                            <md-field class="field-hour" :class="[
                                { 'md-valid': errors['closing_time_saturday'] && !errors['closing_time_saturday'][0] && touchedFields.closing_time_saturday },
                                { 'md-error': errors['closing_time_saturday'] && errors['closing_time_saturday'][0] }
                            ]">
                                <validation-provider name="closing_time_saturday"
                                    :rules="getValidationRules(schedules[5].openingHours[0].openingStatus)"
                                    v-slot="{ errors, touched }" mode="aggressive">
                                    <md-input v-model="schedules[5].openingHours[0].closeHour"
                                        :disabled="loading"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                        </div>
                        <!-- Opening Schedule Sunday -->
                        <div>
                            {{ $t("week_days.sunday") }}
                        </div>
                        <div>
                            <md-switch v-model="schedules[6].openingHours[0].openingStatus" :disabled="loading"></md-switch>
                        </div>
                        <div>
                            <md-field class="field-hour" :class="[
                                { 'md-valid': errors['opening_time_sunday'] && !errors['opening_time_sunday'][0] && touchedFields.opening_time_sunday },
                                { 'md-error': errors['opening_time_sunday'] && errors['opening_time_sunday'][0] }
                            ]">
                                <validation-provider name="opening_time_sunday"
                                    :rules="getValidationRules(schedules[6].openingHours[0].openingStatus)"
                                    v-slot="{ errors, touched }" mode="aggressive">
                                    <md-input v-model="schedules[6].openingHours[0].openHour"
                                        :disabled="loading"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                        </div>
                        <div>
                            <md-field class="field-hour" :class="[
                                { 'md-valid': errors['closing_time_sunday'] && !errors['closing_time_sunday'][0] && touchedFields.closing_time_sunday },
                                { 'md-error': errors['closing_time_sunday'] && errors['closing_time_sunday'][0] }
                            ]">
                                <validation-provider name="closing_time_sunday"
                                    :rules="getValidationRules(schedules[6].openingHours[0].openingStatus)"
                                    v-slot="{ errors, touched }" mode="aggressive">
                                    <md-input v-model="schedules[6].openingHours[0].closeHour"
                                        :disabled="loading"></md-input>
                                    <slide-y-down-transition>
                                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                    </slide-y-down-transition>
                                    <span class="md-error">{{ errors[0] }}</span>
                                </validation-provider>
                            </md-field>
                        </div>
                    </div>
                </ValidationObserver>
            </md-dialog-content>
        </md-dialog>
        <md-dialog-confirm 
            :md-active.sync="show24Dialog" 
            :md-title="$t('label.filldialogtitle')"
            :md-content="$t('label.filldialogbody')"
            :md-confirm-text="$t('label.filldialogconfirm')" 
            :md-cancel-text="$t('label.filldialogcancel')" 
            @md-cancel="show24Dialog = false" 
            @md-confirm="fill()" />

    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import {
    getDineOpeningSchedule,
    createDineOpeningSchedule,
    getShopOpeningSchedule,
    createShopOpeningSchedule,
} from "@/services/opening_schedule";

export default {
    props: ["showProp", "dineId", "shopId", "parentNameProp"],
    components: {
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
    },
    data() {
        return {
            loading: false,
            show: false,
            show24Dialog: false,
            mode: null,
            touchedFields: {
                opening_time_monday: false,
                closing_time_monday: false,
                closing_time_tuesday: false,
                opening_time_tuesday: false,
                opening_time_wednesday: false,
                closing_time_wednesday: false,
                opening_time_thursday: false,
                closing_time_thursday: false,
                opening_time_friday: false,
                closing_time_friday: false,
                opening_time_saturday: false,
                closing_time_saturday: false,
                opening_time_sunday: false,
                closing_time_sunday: false,
            },
            schedules: [
                {
                    weekday: 1,
                    openingHours: [
                        {
                            openingStatus: false,
                            openHour: "",
                            closeHour: "",
                        }
                    ],
                },
                {
                    weekday: 2,
                    openingHours: [
                        {
                            openingStatus: false,
                            openHour: "",
                            closeHour: "",
                        }
                    ],
                },
                {
                    weekday: 3,
                    openingHours: [
                        {
                            openingStatus: false,
                            openHour: "",
                            closeHour: "",
                        }
                    ],
                },
                {
                    weekday: 4,
                    openingHours: [
                        {
                            openingStatus: false,
                            openHour: "",
                            closeHour: "",
                        }
                    ],
                },
                {
                    weekday: 5,
                    openingHours: [
                        {
                            openingStatus: false,
                            openHour: "",
                            closeHour: "",
                        }
                    ],
                },
                {
                    weekday: 6,
                    openingHours: [
                        {
                            openingStatus: false,
                            openHour: "",
                            closeHour: "",
                        }
                    ],
                },
                {
                    weekday: 7,
                    openingHours: [
                        {
                            openingStatus: false,
                            openHour: "",
                            closeHour: "",
                        }
                    ],
                },
            ],
        };
    },

    watch: {
        async showProp() {
            this.show = this.showProp;
            if (this.show) {
                if (this.dineId) {
                    this.mode = "dine";
                } else if (this.shopId) {
                    this.mode = "shop";
                }
                await this.fetchItems();
            }
        },
    },

    methods: {
        async fetchItems() {
            this.loading = true;
            let response;
            if (this.mode == "dine") {
                response = await getDineOpeningSchedule(this.dineId);
            } else if (this.mode == "shop") {
                response = await getShopOpeningSchedule(this.shopId);
            }
            if (response) {
                this.schedules = response;
            }
            this.loading = false;
        },

        closeModal() {
            this.show = false;
            this.$emit("close");
        },


        showfilldialog() {
            this.show24Dialog = true;
        },

        closefilldialog() {
            this.show24Dialog = false;
        },

        fill() {

            this.show24Dialog=false;
            this.schedules.map(async (schedule) => {
                schedule.openingHours[0].openingStatus = true;
                schedule.openingHours[0].openHour = "00:00";
                schedule.openingHours[0].closeHour = "23:59";
            });

        },

        getValidationRules(openStatus) {
            let rules = "";
            if (openStatus) {
                rules = "required";
            }
            return rules;
        },

        async save() {
            this.loading = true;
            var valid = await this.$refs.form.validate();
            if (valid) {
                let response;
                if (this.mode == "dine") {
                    response = await createDineOpeningSchedule(this.dineId, this.schedules);
                } else if (this.mode == "shop") {
                    response = await createShopOpeningSchedule(this.shopId, this.schedules);
                }
                if (response) {
                    this.$noty.success(this.$t('success.saved'));
                } else {
                    this.$noty.error(this.$t('error.saved'));
                }
            } else {
                this.$noty.error(this.$t('error.saved'));
            }
            this.loading = false;
        },
    },
}
</script>

<style scoped>
.schedule-modal-content {
    padding-right: 50px;
    padding-left: 50px;
}

.save-toolbar {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 25px;
}

.opening-schedule-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
}

.opening-schedule-container>hr {
    grid-column: 1 / 5;
    width: 100%;
    border-top: 0 solid #979797;
}

.opening-schedule-header {
    color: var(--main-color);
    font-weight: bold;
}

.field-hour {
    width: 180px;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.field-hour span.md-error {
    padding: 0px !important;
}

.btn-save {
    width: 130px;
}

.btn-mark {
    width: 150px;
    padding-right: 2px;
    margin-right: 12px;
}
</style>