var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md-layout text-center"},[_c('div',{staticClass:"md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit, errors }){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('login-card',[_c('div',{staticClass:"login-logo",attrs:{"slot":"title"},slot:"title"},[_c('img',{attrs:{"src":require("@/assets/YDA_logo.png")}})]),(_vm.user == null)?_c('md-field',{class:[
                        {
                            'md-valid':
                                errors['email'] &&
                                !errors['email'][0] &&
                                _vm.touchedFields.email,
                        },
                        { 'md-form-group': true },
                        {
                            'md-error':
                                errors['email'] && errors['email'][0],
                        },
                    ],attrs:{"slot":"inputs"},slot:"inputs"},[_c('md-icon',[_vm._v("email")]),_c('label',{attrs:{"for":"email"}},[_vm._v(_vm._s(_vm.$t("fields.email")))]),_c('validation-provider',{attrs:{"name":"email","rules":"required|email","mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, touched }){return [_c('md-input',{attrs:{"name":"email","type":"email","placeholder":_vm.$t('fields.email'),"id":"email","disabled":_vm.loading},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"error"},[_vm._v("close")])],1),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('md-field',{class:[
                        {
                            'md-valid':
                                errors['password'] &&
                                !errors['password'][0] &&
                                _vm.touchedFields.password,
                        },
                        { 'md-form-group': true },
                        {
                            'md-error':
                                errors['password'] &&
                                errors['password'][0],
                        },
                    ],attrs:{"slot":"inputs"},slot:"inputs"},[_c('md-icon',[_vm._v("lock_outline")]),_c('label',{attrs:{"for":"password"}},[_vm._v(_vm._s(_vm.$t("fields.password")))]),_c('validation-provider',{attrs:{"name":"password","rules":"required","mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, touched }){return [_c('md-input',{attrs:{"name":"password","type":"password","placeholder":_vm.$t('fields.password'),"id":"password","disabled":_vm.loading},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"error"},[_vm._v("close")])],1),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.errorCredentials)?_c('div',{staticClass:"error-credentials",attrs:{"slot":"inputs"},slot:"inputs"},[_vm._v(" "+_vm._s(_vm.errorCredentials)+" ")]):_vm._e(),(_vm.user == null)?_c('md-button',{staticClass:"md-simple md-primary md-lg",attrs:{"slot":"footer","type":"submit","id":"btnLogin","disabled":_vm.loading},slot:"footer"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}]},[_vm._v(_vm._s(_vm.$t('logging_in')))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]},[_vm._v(_vm._s(_vm.$t('login')))])]):_c('md-button',{staticClass:"md-simple md-primary md-lg",attrs:{"slot":"footer","type":"submit","id":"btnNewPass","disabled":_vm.loading},slot:"footer"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}]},[_vm._v(_vm._s(_vm.$t('procesing')))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]},[_vm._v(_vm._s(_vm.$t('continue')))])])],1)],1)]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }