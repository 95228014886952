<template>
    <div class="md-layout text-center">
        <div class="
                    md-layout-item
                    md-size-33
                    md-medium-size-50
                    md-small-size-70
                    md-xsmall-size-100
                ">
            <ValidationObserver v-slot="{ handleSubmit, errors }">
                <form @submit.prevent="handleSubmit(submit)">
                    <login-card>
                        <div slot="title" class="login-logo">
                            <img src="@/assets/YDA_logo.png" />
                        </div>

                        <md-field v-if="user == null" slot="inputs" :class="[
                            {
                                'md-valid':
                                    errors['email'] &&
                                    !errors['email'][0] &&
                                    touchedFields.email,
                            },
                            { 'md-form-group': true },
                            {
                                'md-error':
                                    errors['email'] && errors['email'][0],
                            },
                        ]">
                            <md-icon>email</md-icon>
                            <label for="email">{{ $t("fields.email") }}</label>
                            <validation-provider name="email" rules="required|email" v-slot="{ errors, touched }"
                                mode="aggressive">
                                <md-input v-model="email" name="email" type="email" :placeholder="$t('fields.email')"
                                    id="email" :disabled="loading"></md-input>
                                <slide-y-down-transition>
                                    <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                </slide-y-down-transition>
                                <span class="md-error">{{ errors[0] }}</span>
                            </validation-provider>
                        </md-field>
                        <md-field slot="inputs" :class="[
                            {
                                'md-valid':
                                    errors['password'] &&
                                    !errors['password'][0] &&
                                    touchedFields.password,
                            },
                            { 'md-form-group': true },
                            {
                                'md-error':
                                    errors['password'] &&
                                    errors['password'][0],
                            },
                        ]">
                            <md-icon>lock_outline</md-icon>
                            <label for="password">{{
                                $t("fields.password")
                            }}</label>
                            <validation-provider name="password" rules="required" v-slot="{ errors, touched }"
                                mode="aggressive">
                                <md-input v-model="password" name="password" type="password"
                                    :placeholder="$t('fields.password')" id="password" :disabled="loading"></md-input>
                                <slide-y-down-transition>
                                    <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                </slide-y-down-transition>
                                <span class="md-error">{{ errors[0] }}</span>
                            </validation-provider>
                        </md-field>
                        <div class="error-credentials" slot="inputs" v-if="errorCredentials">
                            {{ errorCredentials }}
                        </div>

                        <md-button v-if="user == null" type="submit" slot="footer" class="md-simple md-primary md-lg"
                            id="btnLogin" :disabled="loading">
                            <span v-show="loading">{{ $t('logging_in') }}</span>
                            <span v-show="!loading">{{ $t('login') }}</span>
                        </md-button>
                        <md-button v-else type="submit" slot="footer" class="md-simple md-primary md-lg" id="btnNewPass"
                            :disabled="loading">
                            <span v-show="loading">{{ $t('procesing') }}</span>
                            <span v-show="!loading">{{ $t('continue') }}</span>
                        </md-button>
                    </login-card>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>
<script>
import { Auth } from "aws-amplify";
import LoginCard from "../../Cards/LoginCard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import { Roles } from "@/config";
import { getClient } from "@/services/client";
export default {
    name: "Login",
    components: {
        LoginCard,
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
    },
    data() {
        return {
            loading: false,
            title: this.$t('login'),
            touchedFields: {
                email: false,
                password: false,
            },
            user: null,
            email: null,
            password: null,
            errorCredentials: null,
        };
    },
    watch: {
        email() {
            this.touchedFields.email = true;
        },
        password() {
            this.touchedFields.password = true;
        },
    },
    methods: {
        async submit() {
            if (this.user == null) {
                console.log("login");
                await this.login();
            } else {
                console.log("new pass");
                await this.setNewPass();
            }
        },

        async login() {
            this.loading = true;
            try {
                this.user = await Auth.signIn(this.email, this.password);
                var role = 0;
                if (this.user.challengeParam) {
                    if (this.user.challengeParam.userAttributes) {
                        role = this.user.challengeParam.userAttributes['custom:role_id'];
                    }
                } else {
                    role = this.user.attributes['custom:role_id'];
                }
                
                if (!role) {
                    await Auth.signOut();
                    this.user = null;
                    this.valid = false;
                    this.password = "";
                    this.$noty.error(this.$t('error.login'));
                } else {
                    if (this.user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                        this.password = '';
                        this.title = this.$t('new_pass');
                        this.$noty.warning(this.$t('new_pass_required'));
                    } else if (role == Roles.B2B_MG || role == Roles.B2B_OP) {
                        // Check user client enabled property on B2B roles
                        const clientId = this.user.attributes['given_name'];
                        const client = await getClient(clientId);
                        if (client && client.active) {
                            this.$router.push({ name: this.$i18n.locale + '.dashboard' });
                        } else {
                            await Auth.signOut();
                            this.$noty.error(this.$t('error.login_merchant'));
                        }
                    } else {
                        this.$router.push({ name: this.$i18n.locale + '.dashboard' });
                    }
                }
            } catch (err) {
                console.error("Error login user", err);
                // this.$noty.error(this.$t("error.login"));
                this.errorCredentials = this.$t("error.login");
            }
            this.loading = false;
        },

        async setNewPass() {
            this.loading = true;
            try {
                await Auth.completeNewPassword(this.user, this.password);
                this.reset();
                this.$noty.success(this.$t('success.forgot_pass_confirm'));
                this.$router.push({ name: this.$i18n.locale + '.dashboard' });
            } catch (err) {
                console.error('Error setting password', err);
                //this.setState({ errMsg: err.message })
                this.$noty.error(this.$t('error.forgot_pass_confirm'));
            }
            this.loading = false;
        },

        reset() {
            this.user = null;
            this.valid = false;
            this.email = "";
            this.password = "";
        },
    },
};
</script>
<style scoped>
.login-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 170px;
}

.login-logo>img {
    width: 300px;
    height: 300px;
}

.error-credentials {
    margin-top: 20px;
    color: var(--error-color);
    font-size: 12px;
}

.md-card [class*="md-card-header-"]:not(.md-card-header-icon):not(.md-card-header-text):not(.md-card-header-image) {
    padding: 0px !important;
}
</style>