<template>
    <div class="md-layout">
        <div class="md-layout-item md-size-100">
            <h3 class="page-title">
                {{ $t("routes.flights.title") }}
                <md-progress-spinner v-if="loading" :md-diameter="20" :md-stroke="1" md-mode="indeterminate">
                </md-progress-spinner>
            </h3>

            <div class="md-layout-item md-size-100 inline-toolbar">
                <md-field class="search-input">
                    <md-icon class="search-icon">image_search</md-icon>
                    <label>{{ $t("label.search") }}</label>
                    <md-input v-model="searchText" @keyup.enter="filter()"></md-input>
                    <md-button v-if="searchText" class="btn-floating" @click="clearFilter()">
                        <md-icon>close</md-icon>
                    </md-button>
                </md-field>
                <md-field>
                    <md-select v-model="flightDirection" :disabled="loading" :placeholder="$t('label.flight_direction')">
                        <md-option :value="'D'">
                            {{ $t("Departures") }}
                        </md-option>
                        <md-option :value="'A'">
                            {{ $t("Arrivals") }}
                        </md-option>
                    </md-select>
                </md-field>
                <md-field>
                    <md-select v-model="internationalStatus" :disabled="loading"
                        :placeholder="$t('label.flight_internationalStatus')">
                        <md-option :value="'A'">
                            {{ $t("All") }}
                        </md-option>
                        <md-option :value="'I'">
                            {{ $t("International") }}
                        </md-option>
                        <md-option :value="'D'">
                            {{ $t("Domestic") }}
                        </md-option>
                    </md-select>
                </md-field>
                <md-button class="md-primary md-round btn-big" @click="filter()">
                    {{ $t("search") }}
                </md-button>
            </div>

            <div class="md-layout-item md-size-100">
                <md-table table-header-color="blue" v-model="Items">
                    <md-table-row slot="md-table-row" slot-scope="{ item }">
                        <md-table-cell class="flightNumber" :md-label="$t('label.flight_flightNumber')">{{ item.flightNumber
                        }}</md-table-cell>
                        <md-table-cell :md-label="$t('label.flight_Airline')"><img class="image-airline"
                                :src="item.airlineImageURL" alt="Image" /></md-table-cell>
                        <md-table-cell class="flightDestination" :md-label="$t('label.flight_originDestAirportDesc')">{{
                            item.originDestAirportDesc
                        }}</md-table-cell>
                        <md-table-cell class="flightTime" :md-label="$t('label.flight_scheduledDateTime')">{{
                            formattedTime(item.scheduledDateTime)
                        }}</md-table-cell>
                        <md-table-cell class="flightTime" :md-label="$t('label.flight_estimatedDateTime')">{{
                            formattedTime(item.flight_estimatedDateTime)
                        }}</md-table-cell>


                        <md-table-cell :class="'flightStatus ' + item.flightInternalStatus">
                            <span v-if="'flight_status_message.' + item.flightInternalStatus != item.flightInternalStatus">
                                {{ statusMsg(item) }}</span>
                            <span v-else>{{ item.remark }}</span>
                        </md-table-cell>

                        <md-table-cell class="table-cell-actions">
                            <button class="btn-icon" @click="confirmDelete(item)">
                                <md-icon>delete</md-icon>
                            </button>
                        </md-table-cell>
                    </md-table-row>
                </md-table>
            </div>
        </div>
        <md-modal v-if="showConfirm" @close="showConfirm = false">
            <h3 slot="header">
                {{ $t("confirm") }}
            </h3>
            <div slot="body">
                {{ $t("confirm_messages.delete") }}
            </div>
            <div slot="footer" class="modal-footer">
                <md-button @click="showConfirm = false" class="md-danger md-round">
                    {{ $t("cancel") }}
                </md-button>
                <md-button @click="deleteItem()" class="md-primary md-round">
                    {{ $t("delete") }}
                </md-button>
            </div>
        </md-modal>
    </div>
</template>

<script>
import {
    getFlights, deleteFlight
} from "@/services/flights";
import localeMixin from "@/mixins/locale";
const { DateTime } = require('luxon');


export default {
    mixins: [localeMixin],
    data() {


        this.filters = {
            isHome: false,
            date: null,
            locale: 'en',
            flightDirection: this.flightDirection,
            internationalStatus: this.internationalStatus,
            searchText: this.searchText
        }

        return {
            Items: [],
            filters: {},
            flightDirection: "D",
            internationalStatus: 'A',
            searchText: '',
            lastOrder: 0,
            currentSort: 'order',
            currentSortOrder: 'asc',
            sort: "order,asc",
            loading: false,
            showConfirm: false,
            ItemIdToDelete: null,
        };
    },

    watch: {
        page() {
            this.fetchItems();
        },
    },

    created: async function () {
        await this.fetchItems();
    },

    methods: {

        setFlightDirection(direction) {
            this.flightDirection = direction;
        },

        setInternationalStatus(status) {
            this.internationalStatus = status;
        },
        formattedTime(dateTime) {
            if (!dateTime) {
                return '-';
            }
            const turkishDateTime = DateTime.fromISO(dateTime+'Z', { zone: 'Europe/Istanbul' });
            const formattedTime = turkishDateTime.toFormat('HH:mm');
            return formattedTime;
        }
        ,

        async filter() {
            await this.fetchItems();
        },

        async fetchItems() {
            this.loading = true;
            this.Items = [];
            this.filters.flightDirection = this.flightDirection;
            this.filters.internationalStatus = this.internationalStatus;
            if (this.filters.internationalStatus == 'A') {
                this.filters.internationalStatus = null;
            }
            this.filters.searchText = this.searchText;

            console.info("filters", this.filters);

            const response = await getFlights(this.filters);
            

            if (response) {
                this.Items = response;
            } else {
                this.Items = [];
            }
            console.info("Items", this.Items);
            this.loading = false;
        },



        clearFilter() {
            this.flightDirection = 'D';
            this.internationalStatus = 'A';
            this.searchText = "";
            this.filters = {};
            this.fetchItems();
        },

        confirmDelete(Item) {
            this.ItemIdToDelete = Item.flightId;
            this.showConfirm = true;
        },

        async deleteItem() {
            this.loading = true;
            let response = await deleteFlight(this.ItemIdToDelete);
            if (response.status == "200") {
                this.showConfirm = false;
                this.ItemIdToDelete = null;
                this.$noty.success(this.$t('success.deleted'));
                this.fetchItems();
            } else {
                this.$noty.error(this.$t('error.deleted'));
            }
            this.loading = false;
        },

        customSort: function customSort(value) {
            this.fetchItems();
            return value;
        },

        statusMsg(flight) {
            let status = flight.flightInternalStatus;

            if ((status == 'internalStatusArrivalBeforeLanding' || status == 'internalStatusDepartureTimeBeforeFlight') && flight.estimatedDateTime != null && flight.estimatedDateTime != '') {
                return this.$t('flight_status_message.estimated') + this.formattedTime(flight.estimatedDateTime);
            }

            if ((status == 'internalStatusArrivalUnknown' || status == 'internalStatusDepartureUnknown') && flight.remark != null && flight.remark != '') {
                return flight.remark;
            }

            let params = null;
            switch (status) {
                case 'internalStatusArrivalBaggageAvailable':
                    params = { carousel: flight.carrouselCode };
                    break;
                case 'internalStatusArrivalDiverted':
                    params = { airport: flight.originDestAirportDesc };
                    break;
                case 'internalStatusArrivalNextInfoAt':
                case 'internalStatusDepartureNextInfoAt':
                    params = { datetime: this.filters.localTime(flight.estimatedDateTime) };
                    break;
                case 'internalStatusDepartureDeskOpenNoGate':
                    params = { desk: flight.checkInDesks };
                    break;
                case 'internalStatusDepartureDeskOpenGate':
                case 'internalStatusDepartureDeskClosedGate':
                case 'internalStatusDepartureBoardingStarted':
                case 'internalStatusDepartureBoardingFinalCall':
                    params = { gate: flight.gateCode };
                    break;
                default:
                    params = null;
            }

            if (params != null) {
                return this.$t('flight_status_message.' + status, params);
            }

            return this.$t('flight_status_message.' + status);
        },
    },
}
</script>

<style scoped>
/*Margenes izquierdo en cabeceras y contenidos para pintar linea izquierda*/
.image-airline {
    width: 100px;
    height: 27px;
}

.flightNumber {
    font-weight: bold;
    color: #002d56;
}

.flightDestination {
    color: #AD3227;
    font-weight: bold;
}

.flightTime {
    font-weight: normal;
    color: #002d56;
}

.flightStatus {
    font-weight: bold;
    color: #002d56;
}

.md-table-row:nth-child(even) {
    background-color: #f2f2f2;
    /* Background color for even rows */
}

.md-table-row:nth-child(odd) {
    background-color: #ffffff;
    /* Background color for odd rows */
}



.flightStatus_onTime,
.internalStatusDepartureTimeBeforeFlight,
.internalStatusArrivalBeforeLanding {
    color: #5ba610;
}

.flightStatus_goToGate,
.internalStatusDepartureDeskOpenNoGate,
.internalStatusDepartureDeskOpenGate,
.internalStatusDepartureBoardingStarted {
    color: #00a8e1;
}

.flightStatus_checkInAtDesks,
.internalStatusArrivalLandedWaitingBaggage {
    color: #002d56;
}

.flightStatus_cancelled,
.internalStatusDepartureCancelled,
.internalStatusArrivalCancelled {
    color: #AD3227
}

.flightStatus_lastCall,
.internalStatusDepartureBoardingFinalCall {
    color: #ff9231;
}
</style>