const localStorageKey = 'dalamanCMSPageStorage';

class StatePageManager {
  constructor() {
    // Estado inicial
    this.state = {
      page: null,
    };

    // Cargar estado almacenado en localStorage
    this.loadState();
  }

  // Método para guardar el estado en localStorage
  saveState() {
    localStorage.setItem(localStorageKey, JSON.stringify(this.state));
  }

  // Método para cargar el estado desde localStorage
  loadState() {
    const storedState = localStorage.getItem(localStorageKey);
    if (storedState) {
      try {
        const parsedState = JSON.parse(storedState);
        // Actualizar solo las propiedades existentes en el estado actual
        this.state = parsedState;
      } catch (error) {
        console.error('Error parsing stored state:', error);
      }
    }
  }

  // Método para actualizar página
  setPage(page) {
    this.state.page = page; // Asignar el objeto completo de state recibido
    this.saveState();
  }

  // Getter para obtener página
  getPage() {
    return this.state;
  }
}

// Crear una instancia de StateManager
const stateManager = new StatePageManager();

export default stateManager;
