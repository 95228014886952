<template>
    <ValidationObserver tag="form" ref="form" v-slot="{ errors }">
        <div class="md-layout form-layout">
            <!-- Button toolbar -->
            <div class="md-layout-item md-size-100 form-toolbar">
                <h3 class="page-title">
                    <span v-if="creating">{{ $t("routes.suggestion_create.title") }}</span>
                    <span v-if="editing">{{ $t("routes.suggestion_edit.title") }} - {{ item.title }}</span>
                </h3>
                <div class="form-buttons">
                    <md-progress-spinner v-if="loading" :md-diameter="30" :md-stroke="3" md-mode="indeterminate">
                    </md-progress-spinner>
                    <md-button type="submit" class="md-primary md-round btn-medium" @click.prevent="save()">
                        {{ $t("save") }}
                    </md-button>
                </div>
            </div>
            <!-- Order -->
            <div class="md-layout-item md-size-40">
                <md-field>
                    <md-select v-model="item.order" :disabled="loading" :placeholder="$t('label.order')">
                        <md-option v-for="(order, index) in orders" :key="index" :value="order.value">
                            {{ order.text }}
                        </md-option>
                    </md-select>
                    <span class="md-error">
                        {{ errorOrderMessage }}
                    </span>
                </md-field>
            </div>
            <div class="md-layout-item md-size-10"></div>
            <!-- Type -->
            <div class="md-layout-item md-size-40" v-if="!editing">
                <md-field>
                    <md-select v-model="item.type" :disabled="loading" :placeholder="$t('label.suggestionType')">
                        <md-option :value="0">
                            {{ $t("label.suggestionType_value_0") }}
                        </md-option>
                        <md-option :value="1">
                            {{ $t("label.suggestionType_value_1") }}
                        </md-option>
                        <md-option :value="2">
                            {{ $t("label.suggestionType_value_2") }}
                        </md-option>
                        <md-option :value="3">
                            {{ $t("label.suggestionType_value_3") }}
                        </md-option>
                    </md-select>
                </md-field>
            </div>
            <div class="md-layout-item md-size-40" v-if="editing">
            </div>

            <!-- media id -->
            <div class="md-layout-item md-size-45">
                <div class="md-layout">
                    <div>
                        <md-icon class="md-42">iconimage_search</md-icon>
                    </div>
                    <div class="md-layout-item md-size-65">
                        <md-field class="image-field">
                            <label class="label-images" v-if="!item.media || item.media.fileName == ''">{{
                                $t("label.suggestion_image") }}</label>
                            <label v-if="!item.media || item.media.fileName == ''">{{ $t("label.select_image") }}</label>
                            <label v-else>{{ $t("label.suggestion_image") }}</label>
                            <md-input readonly id="mediaInput" v-model="item.media.fileName" :disabled="true"></md-input>
                            <md-button v-if="item.media && item.media.fileName != ''" class="btn-floating"
                                @click="clearMedia()">
                                <md-icon>close</md-icon>
                            </md-button>
                            <span class="md-error">
                                {{ errorMediaMessage }}
                            </span>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-20">
                        <md-button @click="openMediaModal()" class="md-primary md-round btn-medium">
                            {{ $t("select_file") }}
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="md-layout-item md-size-10"></div>
            <!-- media image preview -->
            <div class="md-layout-item md-size-45 centered">
                <div class="media-container-suggestion">
                    <md-icon v-if="!item.media || item.media.imageURL == ''" class="md-size-2x">image</md-icon>
                    <img v-else :src="item.media && item.media.imageURL" :alt="item.imageAlt"
                        @click.prevent="viewImage(item.media && item.media.imageURL)" />
                </div>
            </div>
            <!-- Separator Map information -->
            <div class="md-layout-item md-size-100" v-if="item.type === 0">
                <div class="Group-Header">
                    <span class="Luggage-information">
                        {{ $t('label.map_information') }}
                    </span>
                    <div class="Path"></div>
                </div>
            </div>
            <!-- Building  Floor -->
            <div class="md-layout-item md-size-30" v-if="item.type === 0">
                <md-field>
                    <md-select v-model="item.buildingFloor" :disabled="loading" :placeholder="$t('label.mapBuildingFloor')">
                        <md-option v-for="(floor, idx2) of buildingfloors" :value="floor.value" :key="idx2">
                            {{ floor.text }}</md-option>
                    </md-select>
                    <span class="md-error">
                        {{ errorFloorMessage }}
                    </span>
                </md-field>
            </div>
            <div class="md-layout-item md-size-40" v-if="item.type === 0"></div>
            <!-- Building  Id -->
            <div class="md-layout-item md-size-30" v-if="item.type === 0">
                <md-field>
                    <md-select v-model="item.buildingId" :disabled="loading" :placeholder="$t('label.mapBuildingId')">
                        <md-option v-for="(building, idx3) of buildings" :value="building.value" :key="idx3">
                            {{ building.text }}</md-option>
                    </md-select>
                    <span class="md-error">
                        {{ errorBuildingMessage }}
                    </span>
                </md-field>
            </div>
            <!-- Separator Service information -->
            <div class="md-layout-item md-size-100" v-if="item.type === 2">
                <div class="Group-Header">
                    <span class="Luggage-information">
                        {{ $t('label.service_information') }}
                    </span>
                    <div class="Path"></div>
                </div>
            </div>
            <!-- service id -->
            <div class="md-layout-item md-size-45" v-if="item.type === 2">
                <div class="md-layout">

                    <div class="md-layout-item md-size-65">
                        <md-field class="image-field">
                            <label class="label-images" v-if="!item.service || item.service.name == ''">{{
                                $t("label.suggestion_service") }}</label>
                            <label v-if="!item.service || item.service.name == ''">{{ $t("label.select_service") }}</label>
                            <label v-else>{{ $t("label.suggestion_service") }}</label>
                            <md-input readonly id="mediaInput" v-model="item.service.name" :disabled="true"></md-input>
                            <md-button v-if="item.service && item.service.name != ''" class="btn-floating"
                                @click="clearService()">
                                <md-icon>close</md-icon>
                            </md-button>
                            <span class="md-error">
                                {{ errorServiceMessage }}
                            </span>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-20">
                        <md-button @click="openServiceDialog()" class="md-primary md-round btn-medium">
                            {{ $t("label.select_service") }}
                        </md-button>
                    </div>
                </div>
            </div>

            <!-- Translations -->
            <md-card class="translations-card">
                <md-card-content>
                    <md-tabs class="translations-tabs">
                        <md-tab id="tab-tr" :md-label="$t('languages.tr')" :md-icon="getLanguageIcon('tr', errors)">
                            <!-- title tr -->
                            <div class="md-layout-item md-size-100">
                                <md-field :class="[
                                    { 'md-valid': errors['title_tr'] && !errors['title_tr'][0] && touchedFields.title_tr },
                                    { 'md-error': errors['title_tr'] && errors['title_tr'][0] }
                                ]">
                                    <label for="text">{{ $t('label.suggestion_title') }}</label>
                                    <validation-provider name='title_tr' rules="required" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-textarea :md-autogrow="false" f v-model="title_tr" name="title_tr"
                                            :placeholder="$t('label.suggestion_title')" :disabled="loading">
                                        </md-textarea>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                            <!-- tabTitle tr -->
                            <div class="md-layout-item md-size-100">
                                <md-field :class="[
                                    { 'md-valid': errors['tabTitle_tr'] && !errors['tabTitle_tr'][0] && touchedFields.tabTitle_tr },
                                    { 'md-error': errors['tabTitle_tr'] && errors['tabTitle_tr'][0] }
                                ]">
                                    <label for="text">{{ $t('label.suggestion_tabTitle') }}</label>
                                    <validation-provider name='tabTitle_tr' v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-textarea :md-autogrow="false" f v-model="tabTitle_tr" name="tabTitle_tr"
                                            :placeholder="$t('label.suggestion_tabTitle')" :disabled="loading">
                                        </md-textarea>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>

                            <!-- actionText_tr -->
                            <div class="md-layout-item md-size-100" v-if="item.type === 0 || item.type === 1">
                                <md-field :class="[
                                    { 'md-valid': errors['actionText_tr'] && !errors['actionText_tr'][0] && touchedFields.actionText_tr },
                                    { 'md-error': errors['actionText_tr'] && errors['actionText_tr'][0] }
                                ]">
                                    <label for="text">{{ $t('label.actionText_tr') }}</label>
                                    <validation-provider name='actionText_tr' rules="required" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-textarea :md-autogrow="false" f v-model="actionText_tr" name="actionText_tr"
                                            :placeholder="$t('label.actionText_tr')" :disabled="loading">
                                        </md-textarea>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>

                            <!-- text_tr -->
                            <div class="md-layout-item md-size-100"
                                v-if="item.type === 0 || item.type === 1 || item.type === 2">
                                <md-field :class="[
                                    { 'md-valid': errors['text_tr'] && !errors['text_tr'][0] && touchedFields.text_tr },
                                    { 'md-error': errors['text_tr'] && errors['text_tr'][0] }
                                ]">
                                    <label for="text">{{ $t('label.text_tr') }}</label>
                                    <validation-provider name='text_tr' rules="required" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-textarea :md-autogrow="false" f v-model="text_tr" name="text_tr"
                                            :placeholder="$t('label.text_tr')" :disabled="loading">
                                        </md-textarea>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>

                            <!-- actionUrl_tr -->
                            <div class="md-layout-item md-size-100" v-if="item.type === 1">
                                <md-field :class="[
                                    { 'md-valid': errors['actionUrl_tr'] && !errors['actionUrl_tr'][0] && touchedFields.actionUrl_tr },
                                    { 'md-error': errors['actionUrl_tr'] && errors['actionUrl_tr'][0] }
                                ]">
                                    <label for="text">{{ $t('label.actionUrl_tr') }}</label>
                                    <validation-provider name='actionUrl_tr' rules="required" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-textarea :md-autogrow="false" f v-model="actionUrl_tr" name="actionUrl_tr"
                                            :placeholder="$t('label.actionUrl_tr')" :disabled="loading">
                                        </md-textarea>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>

                            <!-- buttonText_tr -->
                            <div class="md-layout-item md-size-100" v-if="item.type === 2">
                                <md-field :class="[
                                    { 'md-valid': errors['buttonText_tr'] && !errors['buttonText_tr'][0] && touchedFields.buttonText_tr },
                                    { 'md-error': errors['buttonText_tr'] && errors['buttonText_tr'][0] }
                                ]">
                                    <label for="text">{{ $t('label.buttonText_tr') }}</label>
                                    <validation-provider name='buttonText_tr' rules="required" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-textarea :md-autogrow="false" f v-model="buttonText_tr" name="buttonText_tr"
                                            :placeholder="$t('label.buttonText_tr')" :disabled="loading">
                                        </md-textarea>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                        </md-tab>


                        <md-tab id="tab-en" :md-label="$t('languages.en')" :md-icon="getLanguageIcon('en', errors)">
                            <!-- title en -->
                            <div class="md-layout-item md-size-100">
                                <md-field :class="[
                                    { 'md-valid': errors['title_en'] && !errors['title_en'][0] && touchedFields.title_en },
                                    { 'md-error': errors['title_en'] && errors['title_en'][0] }
                                ]">
                                    <label for="text">{{ $t('label.suggestion_title') }}</label>
                                    <validation-provider name='title_en' rules="required" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-textarea :md-autogrow="false" f v-model="title_en" name="title_en"
                                            :placeholder="$t('label.suggestion_title')" :disabled="loading">
                                        </md-textarea>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                            <!-- tabTitle en -->
                            <div class="md-layout-item md-size-100">
                                <md-field :class="[
                                    { 'md-valid': errors['tabTitle_en'] && !errors['tabTitle_en'][0] && touchedFields.tabTitle_en },
                                    { 'md-error': errors['tabTitle_en'] && errors['tabTitle_en'][0] }
                                ]">
                                    <label for="text">{{ $t('label.suggestion_tabTitle') }}</label>
                                    <validation-provider name='tabTitle_en' v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-textarea :md-autogrow="false" f v-model="tabTitle_en" name="tabTitle_en"
                                            :placeholder="$t('label.suggestion_tabTitle')" :disabled="loading">
                                        </md-textarea>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                            <!-- actionText_en -->
                            <div class="md-layout-item md-size-100" v-if="item.type === 0 || item.type === 1">
                                <md-field :class="[
                                    { 'md-valid': errors['actionText_en'] && !errors['actionText_en'][0] && touchedFields.actionText_en },
                                    { 'md-error': errors['actionText_en'] && errors['actionText_en'][0] }
                                ]">
                                    <label for="text">{{ $t('label.actionText_en') }}</label>
                                    <validation-provider name='actionText_en' rules="required" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-textarea :md-autogrow="false" f v-model="actionText_en" name="actionText_en"
                                            :placeholder="$t('label.actionText_en')" :disabled="loading">
                                        </md-textarea>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>

                            <!-- text_en -->
                            <div class="md-layout-item md-size-100"
                                v-if="item.type === 0 || item.type === 1 || item.type === 2">
                                <md-field :class="[
                                    { 'md-valid': errors['text_en'] && !errors['text_en'][0] && touchedFields.text_en },
                                    { 'md-error': errors['text_en'] && errors['text_en'][0] }
                                ]">
                                    <label for="text">{{ $t('label.text_en') }}</label>
                                    <validation-provider name='text_en' rules="required" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-textarea :md-autogrow="false" f v-model="text_en" name="text_en"
                                            :placeholder="$t('label.text_en')" :disabled="loading">
                                        </md-textarea>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>

                            <!-- actionUrl_en -->
                            <div class="md-layout-item md-size-100" v-if="item.type === 1">
                                <md-field :class="[
                                    { 'md-valid': errors['actionUrl_en'] && !errors['actionUrl_en'][0] && touchedFields.actionUrl_en },
                                    { 'md-error': errors['actionUrl_en'] && errors['actionUrl_en'][0] }
                                ]">
                                    <label for="text">{{ $t('label.actionUrl_en') }}</label>
                                    <validation-provider name='actionUrl_en' rules="required" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-textarea :md-autogrow="false" f v-model="actionUrl_en" name="actionUrl_en"
                                            :placeholder="$t('label.actionUrl_en')" :disabled="loading">
                                        </md-textarea>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>

                            <!-- buttonText_en -->
                            <div class="md-layout-item md-size-100" v-if="item.type === 2">
                                <md-field :class="[
                                    { 'md-valid': errors['buttonText_en'] && !errors['buttonText_en'][0] && touchedFields.buttonText_en },
                                    { 'md-error': errors['buttonText_en'] && errors['buttonText_en'][0] }
                                ]">
                                    <label for="text">{{ $t('label.buttonText_en') }}</label>
                                    <validation-provider name='buttonText_en' rules="required" v-slot="{ errors, touched }"
                                        mode="aggressive">
                                        <md-textarea :md-autogrow="false" f v-model="buttonText_en" name="buttonText_en"
                                            :placeholder="$t('label.buttonText_en')" :disabled="loading">
                                        </md-textarea>
                                        <slide-y-down-transition>
                                            <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                                        </slide-y-down-transition>
                                        <span class="md-error">{{ errors[0] }}</span>
                                    </validation-provider>
                                </md-field>
                            </div>
                        </md-tab>

                    </md-tabs>
                </md-card-content>
            </md-card>
            <!-- Buttons -->
            <div class="cont">
                <!-- retailers button -->
                <md-button v-if="item.type === 3" :disabled="loading == true || creating == true"
                    class="md-primary md-round" @click="openRetailSuggestionModal()">
                    {{ $t("Selections") }}
                </md-button>
            </div>
            <!-- Media Modal -->
            <media-modal :showProp="showMediaModal" @apply="applyMedia" @close="closeMediaModal()"></media-modal>
            <!-- Service Modal-->
            <service-modal :modeTypeProp="'service'" :showProp="showServiceDialog" @apply="applyService"
                @close="closeServiceDialog()" />
            <!-- Retailer Suggestion Modal-->
            <RetailerSuggestion-modal :showProp="showRetailSuggestionModal" :stepId="stepId"
                :suggestionId="item.suggestionId" :parentNameProp="item.title" :selectedItemsProp="[]"
                @close="closeRetailSuggestionModal()" />
        </div>
    </ValidationObserver>
</template>

<script>
import MediaModal from "@/components/Modals/Media";
import RetailerSuggestionModal from "@/components/Modals/RetailerSuggestion";

import ServiceModal from "@/components/Modals/Service";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import localeMixin from "@/mixins/locale";
import s3Mixin from "@/mixins/s3";
import {
    getStepSuggestion,
    createStepSuggestion,
    updateStepSuggestion,
} from "@/services/suggestion";

export default {
    mixins: [localeMixin, s3Mixin],
    components: {
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
        MediaModal,
        ServiceModal,
        RetailerSuggestionModal,
    },
    data() {
        return {
            stepId: "",
            loading: false,
            creating: false,
            editing: false,
            imagesLightbox: [],
            orders: [],
            touchedFields: {
                title_en: false,
                title_tr: false,
                actionText_en: false,
                actionText_tr: false,
                text_en: false,
                text_tr: false,
                actionUrl_en: false,
                actionUrl_tr: false,
                buttonText_en: false,
                buttonText_tr: false,
            },
            // 0: map
            // 1: text
            // 2: service
            // 3: retailer
            // 4: weather
            item: {
                order: 0,
                type: 0,
                title: "",
                mediaId: null,
                serviceId: null,
                imageURL: "",
                fileName: "",
                imageAlt: "",
                buildingId: null,
                buildingFloor: null,
                media: {
                    fileName: "",
                    imageURL: "",
                    imageAlt: "",
                },
                service: {
                    name: "",
                    id: null,
                    serviceId: null,
                },
                translations: [
                    {
                        locale: "en",
                        title: "",
                        tabTitle:"",
                        actionText: "",
                        text: "",
                        actionUrl: "",
                        buttonText: "",
                    },
                    {
                        locale: "tr",
                        title: "",
                        tabTitle:"",
                        actionText: "",
                        text: "",
                        actionUrl: "",
                        buttonText: "",
                    },
                ],
            },
            title_en: "",
            title_tr: "",
            tabTitle_en: "",
            tabTitle_tr: "",
            actionText_en: "",
            actionText_tr: "",
            text_en: "",
            text_tr: "",
            actionUrl_en: "",
            actionUrl_tr: "",
            buttonText_en: "",
            buttonText_tr: "",
            errorOrderMessage: "",
            errorMediaMessage: "",
            errorServiceMessage: "",
            errorBuildingMessage: "",
            errorFloorMessage: "",
            showServiceDialog: false,
            selectedService: null,
            showMediaModal: false,
            buildings: [],
            buildingfloors: [],
            showRetailSuggestionModal: false,
        };
    },

    created: async function () {
        let stepId = this.$route.params.stepId;
        this.stepId = stepId;
        let suggestionId = this.$route.params.suggestionId;
        if (stepId && suggestionId) {
            this.editing = true;
            this.creating = false;
            await this.fetchItem(stepId, suggestionId);
        } else {
            if (this.$route.params.lastOrder) {
                let lastOrder = this.$route.params.lastOrder;
                if (lastOrder) {
                    this.item.order = lastOrder;
                }
            }
            this.editing = false;
            this.creating = true;
        }
    },

    async mounted() {
        this.fillOrdersArray();
        this.fillBuildingsArray();
        this.fillBuildingFloors();
    },

    methods: {

        openRetailSuggestionModal() {
            this.showRetailSuggestionModal = true;
        },

        closeRetailSuggestionModal() {
            this.showRetailSuggestionModal = false;
        },

        viewImage(imageUrl) {
            this.imagesLightbox = [];
            this.$imageViewer.images(this.imagesLightbox);
            const image = {
                url: imageUrl,
            };
            this.imagesLightbox.push(image);
            this.$imageViewer.index(0);
            this.$imageViewer.show();
        },

        fillOrdersArray() {
            let min = 0;
            let max = 50;
            this.orders = [];
            for (let i = min; i <= max; i++) {
                if (i === 0) {
                    let z = { value: i, text: this.$t("label.order_default") };
                    this.orders.push(z);
                } else {
                    let b = { value: i, text: i.toString() };
                    this.orders.push(b);
                }
            }
        },

        assignDataModel(item) {
            this.item = item;
            this.item.title = item.title;
            this.item.type = item.type;

            if (this.item.mediaId === null) {
                this.item.mediaId = null;
                this.item.imageURL = "";
                this.item.fileName = "";
                this.item.imageAlt = "";
                this.item.media = {
                    fileName: "",
                    imageURL: "",
                    imageAlt: "",
                };
            } else {
                this.item.media = {
                    fileName: this.item.fileName,
                    imageURL: this.item.imageURL,
                    imageAlt: this.item.imageAlt,
                };
            }

            if (this.item.serviceId === null) {
                this.item.serviceId = null;
                this.item.service = {
                    name: "",
                };
            }

            if (this.item.type == 0) {
                this.item.buildingId = Number(item.buildingId);
                this.item.buildingFloor = Number(item.buildingFloor);
            }

            if (this.item.type == 2) {
                this.item.serviceId = item.serviceId;
            }

            this.putItemTranslations();
        },


        getItemTranslations() {
            let translation_en = this.item.translations.find(
                ({ locale }) => locale === "en"
            );
            if (translation_en) {
                translation_en.title = this.title_en;
                translation_en.tabTitle = this.tabTitle_en;
                translation_en.actionText = this.actionText_en;
                translation_en.text = this.text_en;
                translation_en.actionUrl = this.actionUrl_en;
                translation_en.buttonText = this.buttonText_en;
            } else {
                translation_en = {
                    locale: "en",
                    title: this.title_en,
                    tabTitle: this.tabTitle_en,
                    actionText: this.actionText_en,
                    text: this.text_en,
                    actionUrl: this.actionUrl_en,
                    buttonText: this.buttonText_en,
                };
            }

            let translation_tr = this.item.translations.find(
                ({ locale }) => locale === "tr"
            );

            if (translation_tr) {
                translation_tr.title = this.title_tr;
                translation_tr.tabTitle = this.tabTitle_tr;
                translation_tr.actionText = this.actionText_tr;
                translation_tr.text = this.text_tr;
                translation_tr.actionUrl = this.actionUrl_tr;
                translation_tr.buttonText = this.buttonText_tr;
            } else {
                translation_tr = {
                    locale: "tr",
                    title: this.title_tr,
                    tabTitle: this.tabTitle_tr,
                    actionText: this.actionText_tr,
                    text: this.text_tr,
                    actionUrl: this.actionUrl_tr,
                    buttonText: this.buttonText_tr,
                };
            }
            const translations = [translation_en, translation_tr];
            this.item.translations = translations;
        },

        putItemTranslations() {
            let translation_en = this.item.translations.find(
                ({ locale }) => locale === "en"
            );
            if (translation_en) {
                this.title_en = translation_en.title;
                this.tabTitle_en = translation_en.tabTitle;
                this.actionText_en = translation_en.actionText;
                this.text_en = translation_en.text;
                this.actionUrl_en = translation_en.actionUrl;
                this.buttonText_en = translation_en.buttonText;
            }
            let translation_tr = this.item.translations.find(
                ({ locale }) => locale === "tr"
            );
            if (translation_tr) {
                this.title_tr = translation_tr.title;
                this.tabTitle_tr = translation_tr.tabTitle;
                this.actionText_tr = translation_tr.actionText;
                this.text_tr = translation_tr.text;
                this.actionUrl_tr = translation_tr.actionUrl;
                this.buttonText_tr = translation_tr.buttonText;
            }
            const translations = [translation_en, translation_tr];
            this.item.translations = translations;
        },


        async fetchItem(stepId, itemId) {
            this.loading = true;
            let response = await getStepSuggestion(stepId, itemId);
            if (response) {
                this.assignDataModel(response);
            }
            this.loading = false;
        },

        applyMedia(media) {
            this.item.media = media;
            this.item.mediaId = media.mediaId;
            this.item.imageURL = media.imageURL;
            this.item.fileName = media.fileName;
            this.item.imageAlt = media.imageAlt;
            this.errorMediaMessage = "";
        },

        clearMedia() {
            this.item.media = { fileName: "", imageURL: "", imageAlt: "" };
            this.item.mediaId = 0;
            this.item.imageURL = this.item.media.imageURL;
            this.item.fileName = this.item.media.fileName;
            this.item.imageAlt = this.item.media.imageAlt;
            this.errorMediaMessage = "";
        },

        openMediaModal() {
            this.showMediaModal = true;
        },

        closeMediaModal() {
            this.showMediaModal = false;
            if (this.item.mediaId === 0) {
                this.errorMediaMessage = this.$t(
                    "validation.required",
                    { _field_: this.$t("label.step_image") }
                );
            }
        },

        applyService(service) {
            this.selectedService = service;
            this.item.service.name = service.name;
            this.item.serviceId = service.id;
            this.item.service.serviceId = service.serviceId;
            this.item.service.id = service.id;
            this.errorServiceMessage = "";
        },

        clearService() {
            this.item.service = { name: "" };
            this.item.serviceId = 0;
            this.errorServiceMessage = "";
        },

        openServiceDialog() {
            this.showServiceDialog = true;
        },

        closeServiceDialog() {
            this.showServiceDialog = false;
        },

        async save() {
            this.loading = true;

            this.errorOrderMessage = "";
            this.errorServiceMessage = "";
            this.errorBuildingMessage = "";
            this.errorFloorMessage = "";


            if (this.item.order === 0) {
                valid = false;
                this.errorOrderMessage = this.$t(
                    "validation.at_least_one_value",
                    { _field_: this.$t("label.dine_order") }
                );
            }

            if (this.item.type == 0) {
                if (this.item.buildingId === 0 || this.item.buildingId === null) {
                    valid = false;
                    this.errorBuildingMessage = this.$t(
                        "validation.at_least_one_value",
                        { _field_: this.$t("label.mapBuildingId") }
                    );
                }

                if (this.item.buildingFloor === null) {
                    valid = false;
                    this.errorFloorMessage = this.$t(
                        "validation.at_least_one_value",
                        { _field_: this.$t("label.mapBuildingFloor") }
                    );
                }
            }


            if (this.item.type == 2) {

                if (this.item.serviceId === 0 || this.item.serviceId === "" || this.item.serviceId == null) {
                    valid = false;
                    this.errorServiceMessage = this.$t(
                        "validation.at_least_one_value",
                        { _field_: this.$t("label.suggestion_service") }
                    );
                }
            }

            var valid = await this.$refs.form.validate();

            if (valid) {

                this.getItemTranslations();
                var newItem = JSON.parse(JSON.stringify(this.item));
                if (newItem.mediaId === 0) {
                    newItem.mediaId = null;
                }


                if (this.creating) {
                    const response = await createStepSuggestion(this.stepId, newItem);
                    if (response) {
                        this.$noty.success(this.$t("success.saved"));
                    } else {
                        this.$noty.error(this.$t("error.saved"));
                    }
                } else if (this.editing) {
                    const response = await updateStepSuggestion(this.stepId, newItem);
                    if (response) {
                        this.$noty.success(this.$t("success.saved"));
                    } else {
                        this.$noty.error(this.$t("error.saved"));
                    }
                }
            } else {
                this.$noty.error(this.$t("error.saved"));
            }

            this.loading = false;
        },

        fillBuildingsArray() {
            let min = 0;
            let max = 50;
            this.buildings = [];
            for (let i = min; i <= max; i++) {
                if (i === 0) {
                    let t = this.$t("label.mapBuildingId_default");
                    let z = { value: i, text: t };
                    this.buildings.push(z);
                } else {
                    let t = this.$t("label.mapBuildingId_value_N", { _field_: i });
                    let b = { value: i, text: t };
                    this.buildings.push(b);
                }
            }
        },

        fillBuildingFloors() {
            this.buildingfloors = [];
            this.buildingfloors.push({
                value: -1000,
                text: this.$t("label.mapBuildingFloor_value_default"),
            });
            this.buildingfloors.push({
                value: -1,
                text: this.$t("label.mapBuildingFloor_value_-1"),
            });
            this.buildingfloors.push({
                value: 0,
                text: this.$t("label.mapBuildingFloor_value_0"),
            });
            this.buildingfloors.push({
                value: 1,
                text: this.$t("label.mapBuildingFloor_value_1"),
            });
        },
    },
};

</script>

<style scoped>
.media-container-suggestion {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 394px;
    max-width: 394px;
    height: 300px;
    max-height: 300px;
    min-height: 300px;
    background-color: var(--media-background);
    border: solid 1px var(--media-border);
    overflow: hidden;
}

.media-container-suggestion img {
    object-fit: fill;
    cursor: pointer;
}
</style>