import Vue from "vue";
import VueRouter from "vue-router";
import VueNoty from "vuejs-noty";
import { Auth } from "aws-amplify";
import moment from "moment";
import App from "./App";
import i18n from "./i18n";
import "./vee-validate";

// Plugins
import Chartist from "chartist";
import DashboardPlugin from "./material-dashboard";
import imageViewer from "vue-image-viewer";
import CKEditor from '@ckeditor/ckeditor5-vue2';

import AuthLayout from "./components/pages/auth/AuthLayout";
import Login from "./components/pages/auth/Login.vue";
// import Register from './components/pages/auth/Register.vue';
import RememberPassword from "./components/pages/auth/RememberPassword.vue";
import DashboardLayout from "./components/pages/Layout/DashboardLayout";
import Dashboard from "./components/pages/Dashboard";
import AirlineList from "./components/pages/Airlines/List";
import AirlineForm from "./components/pages/Airlines/Form";
import AirportList from "./components/pages/Airports/List";
import AirportForm from "./components/pages/Airports/Form";
import AlertList from "./components/pages/Alerts/List";
import AlertForm from "./components/pages/Alerts/Form";
import CustomPageList from "./components/pages/CustomPages/List";
import CustomPageForm from "./components/pages/CustomPages/Form";
import NewItemList from "./components/pages/News/List";
import NewsItemForm from "./components/pages/News/Form";
import RestaurantList from "./components/pages/Restaurants/List";
import RestaurantForm from "./components/pages/Restaurants/Form";
import ShopList from "./components/pages/Shops/List";
import ShopForm from "./components/pages/Shops/Form";
import ServiceList from "./components/pages/Services/List";
import ServiceForm from "./components/pages/Services/Form";
import Profile from "./components/pages/UserProfile";
import DestinationList from "./components/pages/Destination/List";
import DestinationForm from "./components/pages/Destination/Form";
import ActivityList from "./components/pages/Activities/List";
import ActivityForm from "./components/pages/Activities/Form";
import TouristicRegionList from "./components/pages/TouristicRegion/List";
import TouristicRegionForm from "./components/pages/TouristicRegion/Form";
import UserList from "./components/pages/User/List";
import ClientUserList from "./components/pages/Clients/Users/List";
import UserForm from "./components/pages/User/Form";
import MediaView from "./components/pages/Media/Media";
import AncillaryList from "./components/pages/Ancillaries/List";
import AncillaryForm from "./components/pages/Ancillaries/Form";
import ClientList from "./components/pages/Clients/List";
import ClientForm from "./components/pages/Clients/Form";
import FaqList from "./components/pages/Faqs/List";
import FaqForm from "./components/pages/Faqs/Form";
import PriceList from "./components/pages/Prices/List";
import ClientUserForm from "./components/pages/Clients/Users/Form";
import MailTemplateList from "./components/pages/MailTemplates/List";
import MailTemplateForm from "./components/pages/MailTemplates/Form";
import BookingList from "./components/pages/Bookings/List";
import BookingForm from "./components/pages/Bookings/Form";
import B2BBooking from "./components/pages/Bookings/B2BBooking";
import FlightList from "./components/pages/Flights/List";
import StepList from "./components/pages/Steps/List";
import StepForm from "./components/pages/Steps/Form";
import SuggestionList from "./components/pages/Suggestions/List";
import SuggestionForm from "./components/pages/Suggestions/Form";
import EmergencyModeForm from "./components/pages/Notifications/EmergencyMode";
import NormalModeForm from "./components/pages/Notifications/NormalMode";


import { SUPPORTED_LOCALES, Roles } from "./config";

import "./assets/css/general.css";
import "./assets/css/responsive.css";
import "./assets/css/utilities.css";
import "./assets/css/custom.css";

Vue.use(imageViewer);
Vue.use(CKEditor);

Vue.use(VueRouter);
Vue.use(DashboardPlugin);
Vue.config.productionTip = false;
Vue.prototype.moment = moment;

Vue.use(VueNoty, {
  progressBar: false,
  layout: "center", //top, topLeft, topCenter, topRight, center, centerLeft, centerRight, bottom, bottomLeft, bottomCenter, bottomRight
  theme: "bootstrap-v4",
  timeout: 3000, //Set 'false' for sticky notifications
});

// Date formatters
Vue.filter("formatDate", function (value) {
  if (value) {
    return moment.utc(String(value)).local().format("DD/MM/YYYY HH:mm");
  }
});

Vue.filter("formatDateNoTime", function (value, format) {
  if (value) {
    if (format) {
      return moment(String(value), format).format("DD/MM/YYYY");
    } else {
      return moment(String(value)).format("DD/MM/YYYY");
    }
  }
});

function getLocaleRoutes(locale = "es") {
  let authRoutes = {
    path: "/" + locale,
    component: AuthLayout,
    children: [
      {
        path: i18n.t("routes.login.nav", locale),
        name: locale + ".login",
        component: Login,
        meta: { requiresGuest: true },
      },
      // {
      //     path: i18n.t('routes.register.nav', locale),
      //     name: locale + '.register',
      //     component: Register,
      //     meta: { requiresGuest: true }
      // },
      {
        path: i18n.t("routes.remember_password.nav", locale),
        name: locale + ".remember_password",
        component: RememberPassword,
        meta: { requiresGuest: true },
      },
      /*
            {
                path: i18n.t('routes.reset_password.nav', locale),
                name: locale + '.reset-password',
                component: ResetPassword,
                meta: { requiresGuest: true }
            },
            */
    ],
  };

  var routes = [
    {
      path: "/" + locale,
      component: DashboardLayout,
      children: [
        {
          path: i18n.t("routes.dashboard.nav", locale),
          name: locale + ".dashboard",
          component: Dashboard,
          meta: { requiresAuth: true },
        },
        {
          path: i18n.t("routes.profile.nav", locale),
          name: locale + ".profile",
          component: Profile,
          meta: { requiresAuth: true },
        },
        {
          path: i18n.t("routes.activities.nav", locale),
          name: locale + ".activities",
          component: ActivityList,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.activity_create.nav", locale),
          name: locale + ".activity_create",
          component: ActivityForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.activity_edit.nav", locale) + "/:activityId",
          name: locale + ".activity_edit",
          component: ActivityForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.touristicRegions.nav", locale),
          name: locale + ".touristicRegions",
          component: TouristicRegionList,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.touristicRegion_create.nav", locale),
          name: locale + ".touristicRegion_create",
          component: TouristicRegionForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.touristicRegion_edit.nav", locale) + "/:touristicRegionId",
          name: locale + ".touristicRegion_edit",
          component: TouristicRegionForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.airlines.nav", locale),
          name: locale + ".airlines",
          component: AirlineList,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.airline_create.nav", locale),
          name: locale + ".airline_create",
          component: AirlineForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.airline_edit.nav", locale) + "/:airlineId",
          name: locale + ".airline_edit",
          component: AirlineForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.airports.nav", locale),
          name: locale + ".airports",
          component: AirportList,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.airport_create.nav", locale),
          name: locale + ".airport_create",
          component: AirportForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.airport_edit.nav", locale) + "/:airportId",
          name: locale + ".airport_edit",
          component: AirportForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.alerts.nav", locale),
          name: locale + ".alerts",
          component: AlertList,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.alert_create.nav", locale),
          name: locale + ".alert_create",
          component: AlertForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.alert_edit.nav", locale) + "/:alertId",
          name: locale + ".alert_edit",
          component: AlertForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.custom_pages.nav", locale),
          name: locale + ".custom_pages",
          component: CustomPageList,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.custom_page_create.nav", locale),
          name: locale + ".custom_page_create",
          component: CustomPageForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.custom_page_edit.nav", locale) + "/:customPageId",
          name: locale + ".custom_page_edit",
          component: CustomPageForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.news.nav", locale),
          name: locale + ".news",
          component: NewItemList,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.newsItem_create.nav", locale),
          name: locale + ".newsItem_create",
          component: NewsItemForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.newsItem_edit.nav", locale) + "/:newsItemId",
          name: locale + ".newsItem_edit",
          component: NewsItemForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.restaurants.nav", locale),
          name: locale + ".restaurants",
          component: RestaurantList,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.restaurant_create.nav", locale) + "/:lastOrder",
          name: locale + ".restaurant_create",
          component: RestaurantForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.restaurant_edit.nav", locale) + "/:dineId",
          name: locale + ".restaurant_edit",
          component: RestaurantForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.shops.nav", locale),
          name: locale + ".shops",
          component: ShopList,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.shop_create.nav", locale) + "/:lastOrder",
          name: locale + ".shop_create",
          component: ShopForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.shop_edit.nav", locale) + "/:shopId",
          name: locale + ".shop_edit",
          component: ShopForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.services.nav", locale),
          name: locale + ".services",
          component: ServiceList,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.service_create.nav", locale) + "/:lastOrder",
          name: locale + ".service_create",
          component: ServiceForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.service_edit.nav", locale) + "/:serviceId",
          name: locale + ".service_edit",
          component: ServiceForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.destinations.nav", locale),
          name: locale + ".destinations",
          component: DestinationList,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.destination_create.nav", locale),
          name: locale + ".destination_create",
          component: DestinationForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path:
            i18n.t("routes.destination_edit.nav", locale) + "/:destinationId",
          name: locale + ".destination_edit",
          component: DestinationForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.users.nav", locale),
          name: locale + ".users",
          component: UserList,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
            ],
          },
        },
        {
          path: i18n.t("routes.client_users.nav", locale),
          name: locale + ".client_users",
          component: ClientUserList,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.ECOMM_MG,
            ],
          },
        },
        {
          path: i18n.t("routes.user_create.nav", locale),
          name: locale + ".user_create",
          component: UserForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
            ],
          },
        },
        {
          path:
            i18n.t("routes.user_edit.nav", locale) + "/:sub",
          name: locale + ".user_edit",
          component: UserForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
            ],
          },
        },
        {
          path:
            "edit-client/"+":clientId"+"/"+i18n.t("routes.client_user_edit.nav", locale) + "/:sub",
          name: locale + ".client_user_edit",
          component: ClientUserForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.ECOMM_MG,
            ],
          },
        },
        {
          path:
            "edit-client/"+":clientId"+"/"+i18n.t("routes.client_user_create.nav", locale),
          name: locale + ".client_user_create",
          component: ClientUserForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.ECOMM_MG,
            ],
          },
        },
        {
          path:
            i18n.t("routes.multimedia.nav", locale),
          name: locale + ".multimedia",
          component: MediaView,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.ancillaries.nav", locale),
          name: locale + ".ancillaries",
          component: AncillaryList,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.ECOMM_MG,
            ],
          },
        },
        {
          path: i18n.t("routes.ancillary_create.nav", locale)+ "/:lastOrder",
          name: locale + ".ancillary_create",
          component: AncillaryForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.ECOMM_MG,
            ],
          },
        },
        {
          path: i18n.t("routes.ancillary_edit.nav", locale) + "/:ancillaryId",
          name: locale + ".ancillary_edit",
          component: AncillaryForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.ECOMM_MG,
            ],
          },
        },        
        {
          path: i18n.t("routes.clients.nav", locale),
          name: locale + ".clients",
          component: ClientList,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.ECOMM_MG,
            ],
          },
        },
        {
          path: i18n.t("routes.client_create.nav", locale)+ "/:lastOrder",
          name: locale + ".client_create",
          component: ClientForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.ECOMM_MG,
            ],
          },
        },
        {
          path: i18n.t("routes.client_edit.nav", locale) + "/:clientId",
          name: locale + ".client_edit",
          component: ClientForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.ECOMM_MG,
            ],
          },
        },
        {
          path: i18n.t("routes.faqs.nav", locale),
          name: locale + ".faqs",
          component: FaqList,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.faq_create.nav", locale) + "/:lastOrder",
          name: locale + ".faq_create",
          component: FaqForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path:
            i18n.t("routes.faq_edit.nav", locale) + "/:faqId",
          name: locale + ".faq_edit",
          component: FaqForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.prices.nav", locale) + "/:clientId",
          name: locale + ".prices",
          component: PriceList,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.ECOMM_MG,
            ],
          },
        },
        {
          path: i18n.t("routes.mail_templates.nav", locale),
          name: locale + ".mail_templates",
          component: MailTemplateList,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
            ],
          },
        },
        {
          path: i18n.t("routes.mail_template_create.nav", locale),
          name: locale + ".mail_template_create",
          component: MailTemplateForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
            ],
          },
        },
        {
          path: i18n.t("routes.mail_template_edit.nav", locale) + "/:mailTemplateId",
          name: locale + ".mail_template_edit",
          component: MailTemplateForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
            ],
          },
        },
        {
          path: i18n.t("routes.bookings.nav", locale),
          name: locale + ".bookings",
          component: BookingList,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.ECOMM_MG,
              Roles.ECOMM_OP,
              Roles.B2B_MG,
              Roles.B2B_OP,
            ],
          },
        },
        {
          path:
            i18n.t("routes.booking_edit.nav", locale) + "/:bookingId",
          name: locale + ".booking_edit",
          component: BookingForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.ECOMM_MG,
              Roles.ECOMM_OP,
              Roles.B2B_MG,
              Roles.B2B_OP,
            ],
          },
        },
        {
          path:
            i18n.t("routes.booking_create.nav", locale),
          name: locale + ".booking_create",
          component: B2BBooking,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.B2B_MG,
              Roles.B2B_OP,
            ],
          },
        },
        {
          path:
            i18n.t("routes.booking_edit_b2b.nav", locale) + "/:bookingId",
          name: locale + ".booking_edit_b2b",
          component: B2BBooking,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.B2B_MG,
              Roles.B2B_OP,
            ],
          },
        },
        {
          path: i18n.t("routes.flights.nav", locale),
          name: locale + ".flights",
          component: FlightList,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
            ],
          },
        },
        {
          path: i18n.t("routes.steps.nav", locale),
          name: locale + ".steps",
          component: StepList,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.step_create.nav", locale),
          name: locale + ".step_create",
          component: StepForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.step_edit.nav", locale) + "/:stepId",
          name: locale + ".step_edit",
          component: StepForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: i18n.t("routes.suggestions.nav", locale),
          name: locale + ".suggestions",
          component: SuggestionList,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: `${i18n.t("routes.steps.nav", locale)}/:stepId/${i18n.t("routes.suggestion_create.nav", locale)}`,
          name: `${locale}.suggestion_create`,
          component: SuggestionForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },
        {
          path: `${i18n.t("routes.steps.nav", locale)}/:stepId/${i18n.t("routes.suggestion_edit.nav", locale)}/:suggestionId`,
          name: `${locale}.suggestion_edit`,
          component: SuggestionForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
              Roles.CONTENT,
            ],
          },
        },        
        {
          path: i18n.t("routes.emergency_mode.nav", locale),
          name: locale + ".emergency_mode",
          component: EmergencyModeForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
            ],
          },
        }, 
        {
          path: i18n.t("routes.normal_mode.nav", locale),
          name: locale + ".normal_mode",
          component: NormalModeForm,
          meta: { 
            requiresAuth: true,
            allowedRoles: [
              Roles.ADMIN,
            ],
          },
        },         
      ],
    },
    authRoutes,
  ];

  return routes;
}

function getRoutes() {
  var routes = [
    {
      path: "/",
      name: "home",
      redirect: { name: i18n.locale + ".dashboard" },
    },
    //{ path: '/protected', component: Protected, meta: { requiresAuth: true } },
  ];

  SUPPORTED_LOCALES.forEach((locale) => {
    var localeRoutes = getLocaleRoutes(locale.code);
    routes = routes.concat(localeRoutes);
  });

  return routes;
}

const router = new VueRouter({
  mode: "history",
  // base: window.location.origin,
  linkActiveClass: "nav-item active",
  linkExactActiveClass: "nav-item active",
  routes: getRoutes(),
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
  },
});

router.beforeResolve((to, from, next) => {
  var locale = to.matched[0]["path"].slice(1);
  if (SUPPORTED_LOCALES.find((loc) => loc.code === locale)) {
    i18n.locale = locale;
  }

  //Mantis 0012981
  document.title = i18n.t("webtitle", locale);
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    Auth.currentAuthenticatedUser()
      .then((authUser) => {
        // Mantis 0014424
        const routeMeta = to.meta;
        if (routeMeta.allowedRoles) {
          const authUserRole = authUser.attributes['custom:role_id'];
          if (routeMeta.allowedRoles.includes(authUserRole)) {
            next();
          } else {
            console.error("Unauthorized Role");
            next({
              name: locale + ".dashboard",
            });
          }
        } else {
          next();
        }
      })
      .catch(() => {
        console.error("Unauthorized");
        next({
          name: locale + ".login",
        });
      });
  }

  if (to.matched.some((record) => record.meta.requiresGuest)) {
    Auth.currentAuthenticatedUser()
      .then(() => {
        console.error("Already connected");
        this.$noty.warn(this.$t("error.already_connected"));
        next({
          name: locale + ".dashboard",
        });
      })
      .catch(() => {
        next();
      });
  }

  next();
});

// global library setup
Object.defineProperty(Vue.prototype, "$Chartist", {
  get() {
    return this.$root.Chartist;
  },
});

new Vue({
  render: (h) => h(App),
  i18n,
  router,
  data: {
    Chartist: Chartist,
  },
}).$mount("#app");
