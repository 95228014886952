<template>
    <div>
        <md-dialog :md-close-on-esc="false" :md-click-outside-to-close="false" :md-active.sync="show"
            class="modal-category">
            <md-dialog-title class="modal-category-header">
                <div class="md-layout-item md-size-100 form-toolbar">
                    <h3 v-if="parentName == ''">{{ $t("promos") }}</h3>
                    <h3 v-if="parentName != ''">{{ this.parentName }} - {{ $t("promos") }}</h3>
                    <md-progress-spinner class="spinner-load" v-if="loading" :md-diameter="30" :md-stroke="3"
                        md-mode="indeterminate">
                    </md-progress-spinner>
                </div>
                <md-button @click="closeModal()" class="
                        md-primary md-just-icon md-round md-simple
                        modal-close-btn
                    ">
                    <md-icon class="md-size-2x">close</md-icon>
                </md-button>
            </md-dialog-title>
            <md-dialog-content>
                <!-- Category List -->
                <div class="content-div" v-if="!creating && !editing">
                    <div class="inline-toolbar">
                        <md-field class="search-input">
                            <md-icon class="search-icon">image_search</md-icon>
                            <label>{{ $t("label.search_promoname") }}</label>
                            <md-input v-model="search"  @keyup.enter="filter()" ></md-input>
                            <md-button v-if="search" class="btn-floating" @click="clearFilter()">
                                <md-icon>close</md-icon>
                            </md-button>
                        </md-field>
                        <md-button class="md-primary md-round" @click="filter()">
                            {{ $t("search") }}
                        </md-button>
                        <md-button @click="newItem()" class="md-primary md-round">
                            {{ $t("new") }}
                        </md-button>
                    </div>

                    <div v-if="promoList.length == 0" table-header-color="blue" class="md-content md-table md-theme-default">
                        <md-table>
                            <thead>
                                <tr><!---->
                                    <th class="md-table-head md-sortable md-sorted md-sorted-desc">
                                        <div class="md-table-head-container md-ripple">
                                            <div class="md-table-head-label">
                                                {{$t('label.promo_name')}}
                                                <!---->
                                            </div>
                                        </div>
                                    </th>
                                    <th class="md-table-head">
                                        <div class="md-table-head-container md-ripple md-disabled">
                                            <div class="md-table-head-label"><!---->
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="md-table-row"> 
                                <td class="md-table-cell" style="width: 100%;">  
                                    {{$t('label.no_records')}}                             
                                </td>
                                <td class="md-table-cell" style="width: 100%;">                                                               
                                </td>
                            </tr>
                        </tbody>
                        </md-table>
                        </div>

                    <md-table v-if="promoList.length > 0" table-header-color="blue" v-model="promoList">
                        <md-table-row class="md-table-header left space" slot="md-table-row" slot-scope="{ item }">
                            <md-table-cell :md-label="$t('label.promo_name')">{{ item.title }}</md-table-cell>
                            <md-table-cell class="table-cell-actions">
                                <button class="btn-icon" @click="editItem(item.promoId)">
                                    <md-icon>edit</md-icon>
                                </button>
                                <button class="btn-icon" @click="confirmDelete(item)">
                                    <md-icon>delete</md-icon>
                                </button>
                            </md-table-cell>

                        </md-table-row>
                    </md-table>

                    <div class="pagination-container">
                        <md-pagination v-model="page" :noArrows="false" :perPage="limit" :total="total" />
                    </div>
                </div>
                <!-- Promo form -->
                <div v-if="creating || editing">
                    <ValidationObserver tag="form" ref="form" v-slot="{ errors }">
                        <div class="md-layout form-layout">
                            <div class="md-layout-item md-size-50">
                                <!-- start date -->
                                <md-datepicker v-model="item.startDate" :md-immediately="true">
                                    <label>{{ $t("label.promo_startDate") }}</label>
                                    <span class="md-error">
                                        {{ errorStartDateMessage }}
                                    </span>
                                </md-datepicker>
                                <!-- end date -->
                                <md-datepicker v-model="item.endDate" :md-immediately="true">
                                    <label>{{ $t("label.promo_endDate") }}</label>
                                    <span class="md-error">
                                        {{ errorEndDateMessage }}
                                    </span>
                                </md-datepicker>
                                <!-- Prize Promo -->
                                <div class="field-switch">
                                    <Span>{{ $t("label.promo_pricePromo") }}</Span>
                                    <md-switch id="switchPricePromoEnabled" v-model="item.prizePromo">
                                    </md-switch>
                                </div>
                            </div>
                            <div class="md-layout-item md-size-50">
                                <!-- media id -->
                                <div class="md-layout" style="margin-left:40px">
                                    <div>
                                        <md-icon class="md-42">iconimage_search</md-icon>
                                    </div>
                                    <div class="md-layout-item md-size-65">
                                        <md-field class="image-field">
                                            <label class="label-images" v-if="item.media.fileName == ''">{{
                                                    $t("label.promo_image")
                                            }}</label>
                                            <label v-if="item.media.fileName == ''">{{ $t("label.select_image")
                                            }}</label>
                                            <label v-else>{{ $t("label.promo_image") }}</label>
                                            <md-input readonly id="mediaInput" v-model="item.media.fileName"
                                                :disabled="true">
                                            </md-input>
                                            <md-button v-if="item.media.fileName != ''" class="btn-floating"
                                                @click="clearMedia()">
                                                <md-icon>close</md-icon>
                                            </md-button>
                                            <span class="md-error">
                                                {{ errorMediaMessage }}
                                            </span>
                                        </md-field>
                                    </div>
                                    <div class="md-layout-item md-size-20">
                                        <md-button @click="openMediaModal()" class="md-primary md-round">
                                            {{ $t("select_file") }}
                                        </md-button>
                                    </div>
                                    <!-- media image preview -->
                                    <div class="image-container">
                                        <md-icon v-if="item.media.imageURL == ''" class="md-size-4x">image</md-icon>
                                        <img v-else :src="item.media.imageURL" @click.prevent="viewImage(item.media.imageURL)" />
                                    </div>
                                </div>
                            </div>
                            <div class="md-layout-item md-size-100">
                                <!-- Separator localizations -->
                                <div class="Group-Header">
                                    <span class="Luggage-information">
                                        {{ $t('label.language_information') }}
                                    </span>
                                    <div class="Path"></div>
                                </div>
                                <!-- Translations -->
                                <md-card class="translations-card">
                                    <md-card-content>
                                        <md-tabs class="translations-tabs">
                                            <md-tab id="tab-tr" :md-label="$t('languages.tr')"
                                                :md-icon="getLanguageIcon('tr', errors)">
                                                <div class="inlinefields gapsm">
                                                    <!-- title tr -->
                                                    <md-field :class="[
                                                        { 'md-valid': errors['title_tr'] && !errors['title_tr'][0] && touchedFields.title_tr },
                                                        { 'md-error': errors['title_tr'] && errors['title_tr'][0] }
                                                    ]">
                                                        <label for="title_tr">{{
                                                                $t("label.promo_title")
                                                        }}</label>
                                                        <validation-provider name="title_tr" rules="required"
                                                            v-slot="{ errors, touched }" mode="aggressive">
                                                            <md-input v-model="item.title_tr" name="title_tr"
                                                                type="text" id="title_tr" :disabled="loading || view">
                                                            </md-input>
                                                            <slide-y-down-transition>
                                                                <md-icon class="error"
                                                                    v-show="errors[0] && touched">close
                                                                </md-icon>
                                                            </slide-y-down-transition>
                                                            <span class="md-error">{{ errors[0] }}</span>
                                                        </validation-provider>
                                                    </md-field>
                                                    <!-- subtitle tr -->
                                                    <md-field :class="[
                                                        { 'md-valid': errors['subtitle_tr'] && !errors['subtitle_tr'][0] && touchedFields.subtitle_tr },
                                                        { 'md-error': errors['subtitle_tr'] && errors['subtitle_tr'][0] }
                                                    ]">
                                                        <label for="subtitle_tr">{{
                                                                $t("label.promo_subtitle")
                                                        }}</label>
                                                        <validation-provider name="subtitle_tr" rules="required"
                                                            v-slot="{ errors, touched }" mode="aggressive">
                                                            <md-input v-model="item.subtitle_tr" name="subtitle_tr"
                                                                type="text" id="subtitle_tr"
                                                                :disabled="loading || view">
                                                            </md-input>
                                                            <slide-y-down-transition>
                                                                <md-icon class="error"
                                                                    v-show="errors[0] && touched">close
                                                                </md-icon>
                                                            </slide-y-down-transition>
                                                            <span class="md-error">{{ errors[0] }}</span>
                                                        </validation-provider>
                                                    </md-field>
                                                </div>
                                                <!-- label tr -->
                                                <div class="md-layout-item md-size-45">
                                                    <md-field :class="[
                                                        { 'md-valid': errors['label_tr'] && !errors['label_tr'][0] && touchedFields.label_tr },
                                                        { 'md-error': errors['label_tr'] && errors['label_tr'][0] }
                                                    ]">
                                                        <label for="label_tr">{{
                                                                $t("label.promo_labelInList")
                                                        }}</label>
                                                        <validation-provider name="label_tr" rules="required"
                                                            v-slot="{ errors, touched }" mode="aggressive">
                                                            <md-input v-model="item.label_tr" name="label_tr"
                                                                type="text" id="label_tr" :disabled="loading || view">
                                                            </md-input>
                                                            <slide-y-down-transition>
                                                                <md-icon class="error"
                                                                    v-show="errors[0] && touched">close
                                                                </md-icon>
                                                            </slide-y-down-transition>
                                                            <span class="md-error">{{ errors[0] }}</span>
                                                        </validation-provider>
                                                    </md-field>
                                                </div>
                                            </md-tab>
                                            <md-tab id="tab-en" :md-label="$t('languages.en')"
                                                :md-icon="getLanguageIcon('en', errors)">
                                                <div class="inlinefields gapsm">
                                                    <!-- title tr -->
                                                    <md-field :class="[
                                                        { 'md-valid': errors['title_en'] && !errors['title_en'][0] && touchedFields.title_en },
                                                        { 'md-error': errors['title_en'] && errors['title_en'][0] }
                                                    ]">
                                                        <label for="title_en">{{
                                                                $t("label.promo_title")
                                                        }}</label>
                                                        <validation-provider name="title_en" rules="required"
                                                            v-slot="{ errors, touched }" mode="aggressive">
                                                            <md-input v-model="item.title_en" name="title_en"
                                                                type="text" id="title_en" :disabled="loading || view">
                                                            </md-input>
                                                            <slide-y-down-transition>
                                                                <md-icon class="error"
                                                                    v-show="errors[0] && touched">close
                                                                </md-icon>
                                                            </slide-y-down-transition>
                                                            <span class="md-error">{{ errors[0] }}</span>
                                                        </validation-provider>
                                                    </md-field>
                                                    <!-- subtitle tr -->
                                                    <md-field :class="[
                                                        { 'md-valid': errors['subtitle_en'] && !errors['subtitle_en'][0] && touchedFields.subtitle_en },
                                                        { 'md-error': errors['subtitle_en'] && errors['subtitle_en'][0] }
                                                    ]">
                                                        <label for="subtitle_en">{{
                                                                $t("label.promo_subtitle")
                                                        }}</label>
                                                        <validation-provider name="subtitle_en" rules="required"
                                                            v-slot="{ errors, touched }" mode="aggressive">
                                                            <md-input v-model="item.subtitle_en" name="subtitle_en"
                                                                type="text" id="subtitle_en"
                                                                :disabled="loading || view">
                                                            </md-input>
                                                            <slide-y-down-transition>
                                                                <md-icon class="error"
                                                                    v-show="errors[0] && touched">close
                                                                </md-icon>
                                                            </slide-y-down-transition>
                                                            <span class="md-error">{{ errors[0] }}</span>
                                                        </validation-provider>
                                                    </md-field>
                                                </div>
                                                <!-- label tr -->
                                                <div class="md-layout-item md-size-45">
                                                    <md-field :class="[
                                                        { 'md-valid': errors['label_en'] && !errors['label_en'][0] && touchedFields.label_en },
                                                        { 'md-error': errors['label_en'] && errors['label_en'][0] }
                                                    ]">
                                                        <label for="label_en">{{
                                                                $t("label.promo_labelInList")
                                                        }}</label>
                                                        <validation-provider name="label_en" rules="required"
                                                            v-slot="{ errors, touched }" mode="aggressive">
                                                            <md-input v-model="item.label_en" name="label_en"
                                                                type="text" id="label_en" :disabled="loading || view">
                                                            </md-input>
                                                            <slide-y-down-transition>
                                                                <md-icon class="error"
                                                                    v-show="errors[0] && touched">close
                                                                </md-icon>
                                                            </slide-y-down-transition>
                                                            <span class="md-error">{{ errors[0] }}</span>
                                                        </validation-provider>
                                                    </md-field>
                                                </div>
                                            </md-tab>
                                        </md-tabs>
                                    </md-card-content>
                                </md-card>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <md-button @click.prevent="cancel()" class="md-danger md-round" :disabled="loading">
                                {{ $t("cancel") }}
                            </md-button>
                            <md-button v-if="!view" @click.prevent="save()" type="submit" class="md-primary md-round"
                                :disabled="loading">
                                {{ $t("save") }}
                            </md-button>
                        </div>
                    </ValidationObserver>
                </div>
            </md-dialog-content>
        </md-dialog>
        <!-- Modal Confirm Delete -->
        <md-modal v-if="showConfirm" @close="showConfirm = false">
            <h3 slot="header">
                {{ $t("confirm") }}
            </h3>
            <div slot="body">
                {{ $t("confirm_messages.delete") }}
            </div>
            <div slot="footer" class="modal-footer">
                <md-button @click="showConfirm = false" class="md-danger md-round">
                    {{ $t("cancel") }}
                </md-button>
                <md-button @click="deleteItem()" class="md-primary md-round">
                    {{ $t("delete") }}
                </md-button>
            </div>
        </md-modal>
        <!-- Media Modal -->
        <media-modal :showProp="showMediaModal" @apply="applyMedia" @close="closeMediaModal()"></media-modal>
    </div>
</template>

<script>
import MediaModal from "@/components/Modals/Media";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import {
    getDinePromos,
    getShopPromos,
    getDinePromo,
    getShopPromo,
    createDinePromo,
    createShopPromo,
    updateDinePromo,
    updateShopPromo,
    deleteDinePromo,
    deleteShopPromo

} from "@/services/promos";
import paginationMixin from "@/mixins/pagination";
import localeMixin from "@/mixins/locale";


export default {
    props: ['showProp', 'dineId', 'shopId', 'parentNameProp'],
    mixins: [paginationMixin, localeMixin],
    components: {
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
        MediaModal,
    },
    data() {
        return {
            loading: false,
            show: false,
            showConfirm: false,
            imagesLightbox:[],
            mode: null,
            parentId: "",
            parentName: "",
            touchedFields: {
                title_tr: false,
                title_en: false,
                subtitle_tr: false,
                subtitle_en: false,
                label_tr: false,
                label_en: false,
            },
            promoList: [],
            item: {
                promoId: "",
                mediaId: 0,
                imageURL: "",
                fileName: "",
                media: {
                    fileName: "", imageURL: ""
                },
                prizePromo: false,
                startDate: this.moment().format('DD/MM/yyyy'),
                endDate: this.moment().add(1, 'days').format('DD/MM/yyyy'),
                translationId_en: 0,
                translationId_tr: 0,
                title_tr: "",
                title_en: "",
                subtitle_tr: "",
                subtitle_en: "",
                label_tr: "",
                label_en: "",
            },
            errorMediaMessage: "",
            errorStartDateMessage: "",
            errorEndDateMessage: "",
            view: false,
            creating: false,
            editing: false,
            search: "",
            ItemIdToDelete: null,
            showMediaModal: false
        };
    },

    created: async function () {
        this.configDatePickerLocale();        
    },


    watch: {
        'item.startDate'() {
            this.changeDate('startDate');
            //Watch para cambios de valor en la fecha, el control no funciona
        },
        'item.endDate'() {
            this.changeDate('endDate');
            //Watch para cambios de valor en la fecha, el control no funciona
        },
        page() {
            this.fetchItems();
        },

        showProp() {
            this.show = this.showProp;
            if (this.show) {
                this.limit = 5;
                this.clearFilter();
                this.clearData();
                this.editing = false;
                this.creating = false;
                this.view = true;
                this.parentName = this.parentNameProp;
                if (this.dineId) {
                    this.parentId = this.dineId;
                    this.mode = "dine";
                } else if (this.shopId) {
                    this.parentId = this.shopId;
                    this.mode = "shop";
                }
                this.fetchItems();
            }
        },
    },


    methods: {


        viewImage(imageUrl) {
            this.view = true;
            this.imagesLightbox = [];
            this.$imageViewer.images(this.imagesLightbox);
            const image = {
                url: imageUrl,
            };
            this.imagesLightbox.push(image);
            this.$imageViewer.index(0);
            this.$imageViewer.show();
        },

        async changeDate(fieldname) {

            switch (fieldname) {
                case 'startDate':
                    this.errorStartDateMessage = " ";
                    if (!this.item.startDate) {
                        this.errorStartDateMessage = this.$t('validation.required', { _field_: this.$t('fields.date') });
                    }
                    break;

                case 'endDate':
                    this.errorEndDateMessage = " ";
                    if (!this.item.endDate) {
                        this.errorEndDateMessage = this.$t('validation.required', { _field_: this.$t('fields.date') });
                    }
                    break;

                default:
                    break;
            }

        },

        applyMedia(media) {
            this.item.media = media;
            this.item.mediaId = media.mediaId;
            this.item.imageURL = media.imageURL;
            this.item.fileName = media.fileName;
            this.errorMediaMessage = "";
        },

        openMediaModal() {
            this.showMediaModal = true;
        },

        closeMediaModal() {
            this.showMediaModal = false;
            if (this.item.mediaId == 0) {
                this.errorMediaMessage = this.$t('validation.required', { _field_: this.$t('label.promo_image') });
            }
        },

        async fetchItems() {
            this.loading = true;
            let response = null;
            if (this.mode == "dine") {
                response = await getDinePromos(this.dineId, this.page, this.limit, this.search);
            } else if (this.mode == "shop") {
                response = await getShopPromos(this.shopId, this.page, this.limit, this.search);
            }

            if (response) {
                this.promoList = response.result;
                this.total = response.pageInfo.totalPages * this.limit;
            } else {
                this.promoList = [];
                this.total = 0;
            }
            this.loading = false;
        },

        async fetchItem(itemId) {
            this.loading = true;
            let response = null;
            if (this.mode == "dine") {
                response = await getDinePromo(this.parentId, itemId, this.page, this.limit, this.search);
            } else if (this.mode == "shop") {
                response = await getShopPromo(this.parentId, itemId, this.page, this.limit, this.search);
            }
            if (response) {
                this.assignDataModel(response);
            }
            this.loading = false;
        },


        async filter() {
            this.fetchItems();
        },

        clearFilter() {
            this.search = "";
            this.fetchItems();
        },

        newItem() {
            this.clearData();
            this.creating = true;
            this.editing = false;
            this.view = false;
        },

        editItem(itemId) {
            this.creating = false;
            this.clearData();
            this.editing = true;
            this.fetchItem(itemId);
            this.view = false;

        },

        clearMedia() {

            this.item.media = { fileName: "", imageURL: "" };
            this.item.mediaId = 0;
            this.item.imageURL = this.item.media.imageURL;
            this.item.fileName = this.item.media.fileName;
        },

        closeModal() {
            this.show = false;
            this.$emit("close");
        },

        cancel() {
            this.view = false;
            this.editing = false;
            this.creating = false;
        },

        clearData() {
            this.item.promoId = "";
            this.item.mediaId = 0;
            this.item.imageURL = "";
            this.item.fileName = "";
            this.item.prizePromo = false;
            this.item.fileName = "";
            this.item.imageURL = "";

            this.item.media= {
                    fileName: "", imageURL: ""
                };

            this.item.startDate = this.moment().format('DD/MM/yyyy');
            this.item.endDate = this.moment().format('DD/MM/yyyy');
            this.item.translationId_en = 0;
            this.item.translationId_tr = 0;
            this.item.title_tr = "";
            this.item.title_en = "";
            this.item.subtitle_tr = "";
            this.item.subtitle_en = "";
            this.item.label_tr = "";
            this.item.label_en = "";
            this.errorStartDateMessage = "";
            this.errorEndDateMessage = "";
            this.errorMediaMessage = "";
        },

        async save() {

            this.errorMediaMessage = "";

            var valid = await this.$refs.form.validate();


            if (this.item.mediaId == 0) {
                valid = false;
                this.errorMediaMessage = this.$t('validation.required', { _field_: this.$t('label.promo_image') });
            }

            if (!this.item.startDate) {
                valid = false;
                this.errorStartDateMessage = this.$t('validation.required', { _field_: this.$t('fields.date') });
            }

            if (!this.item.endDate) {
                valid = false;
                this.errorEndDateMessage = this.$t('validation.required', { _field_: this.$t('fields.date') });
            }

            if (this.item.endDate < this.item.startDate) {
                valid = false;
                this.errorEndDateMessage = this.$t('error.valid_to_date');
            }

            if (!valid) {
                this.$noty.error(this.$t('error.saved'));
            }

            if (valid) {
                this.loading = true;
                if (this.creating) {

                    let response = null;
                    if (this.mode == "dine") {
                        response = await createDinePromo(this.dineId, this.convertDataModel());
                    } else if (this.mode == "shop") {
                        response = await createShopPromo(this.shopId, this.convertDataModel());
                    }

                    if (response.status == "201") {
                        this.$noty.success(this.$t('success.saved'));
                        this.cancel();

                    } else {
                        this.$noty.error(this.$t("error.saved"));
                    }
                }
                if (this.editing) {

                    let response = null;
                    if (this.mode == "dine") {
                        response = await updateDinePromo(this.dineId, this.convertDataModel());
                    } else if (this.mode == "shop") {
                        response = await updateShopPromo(this.shopId, this.convertDataModel());
                    }

                    if (response.status == "200") {
                        this.$noty.success(this.$t('success.saved'));
                        this.cancel();
                    } else {
                        this.$noty.error(this.$t("error.saved"));
                    }

                }
            }
            this.loading = false;
        },


        confirmDelete(Item) {
            this.ItemIdToDelete = Item.promoId;
            this.showConfirm = true;
        },

        async deleteItem() {
            this.loading = true;

            let response = null;
            if (this.mode == "dine") {
                response = await deleteDinePromo(this.dineId, this.ItemIdToDelete);
            } else if (this.mode == "shop") {
                response = await deleteShopPromo(this.shopId, this.ItemIdToDelete);
            }
            if (response) {
                this.$noty.success(this.$t("success.item_deleted"));
                this.showConfirm = false;
                this.ItemIdToDelete = "";
                this.fetchItems();
            }
            this.loading = false;
        },

        assignDataModel(item) {

            if (item.promoId) {
                this.item.promoId = item.promoId;
            } else {
                this.item.promoId = "";
            }

            if (item.mediaId) {
                this.item.mediaId = item.mediaId;
            } else {
                this.item.mediaId = 0;
            }

            if (item.fileName) {
                this.item.media.fileName = item.fileName;
            } else {
                this.item.media.fileName = "";
            }

            if (item.imageURL) {
                this.item.media.imageURL = item.imageURL;
            } else {
                this.item.media.imageURL = "";
            }

            if (item.dateFrom) {
                this.item.startDate = this.moment(item.dateFrom).format('DD/MM/yyyy');
            }

            if (item.dateTo) {
                this.item.endDate = this.moment(item.dateTo).format('DD/MM/yyyy');
            }

            if (item.prizePromo) {
                this.item.prizePromo = Boolean(item.prizePromo);
            } else {
                this.item.prizePromo = false;
            }



            var translation = item.translations.filter(t => t.locale == 'en')[0];

            if (translation) {

                if (translation.translationId) {
                    this.item.translationId_en = translation.translationId;
                } else {
                    this.item.translationId_en = 0;
                }

                if (translation.title) {
                    this.item.title_en = translation.title;
                } else {
                    this.item.title_en = "";
                }

                if (translation.subtitle) {
                    this.item.subtitle_en = translation.subtitle;
                } else {
                    this.item.subtitle_en = "";
                }

                if (translation.labelInList) {
                    this.item.label_en = translation.labelInList;
                } else {
                    this.item.label_en = "";
                }

            } else {

                this.item.translationId_en = 0;
                this.item.title_en = "";
                this.item.subtitle_en = "";
                this.item.label_en = "";

            }


            translation = item.translations.filter(t => t.locale == 'tr')[0];

            if (translation) {


                if (translation.translationId) {
                    this.item.translationId_tr = translation.translationId;
                } else {
                    this.item.translationId_tr = 0;
                }
                if (translation.title) {
                    this.item.title_tr = translation.title;
                } else {
                    this.item.title_tr = "";
                }

                if (translation.subtitle) {
                    this.item.subtitle_tr = translation.subtitle;
                } else {
                    this.item.subtitle_tr = "";
                }

                if (translation.labelInList) {
                    this.item.label_tr = translation.labelInList;
                } else {
                    this.item.label_tr = "";
                }

            } else {

                this.item.translationId_tr = 0;
                this.item.title_tr = "";
                this.item.subtitle_tr = "";
                this.item.label_tr = "";

            }

        },

        convertDataModel() {

            var newItem = {};
            newItem.promoId = this.item.promoId;
            newItem.mediaId = this.item.mediaId;

            const momentObjectFrom = this.moment(this.item.startDate, "DD/MM/YYYY");
            const dateISOFrom = momentObjectFrom.format("YYYY-MM-DD");
            newItem.dateFrom = dateISOFrom;
            const momentObjectTo = this.moment(this.item.endDate, "DD/MM/YYYY");
            const dateISOTo = momentObjectTo.format("YYYY-MM-DD");
            newItem.dateTo = dateISOTo;
            newItem.order = 0; //Cannot be null in db
            newItem.prizePromo = Number(this.item.prizePromo);
            newItem.translations = [];
            var trans_item_tr = {};
            trans_item_tr.translationId = this.item.translationId_tr;
            trans_item_tr.title = this.item.title_tr;
            trans_item_tr.subtitle = this.item.subtitle_tr;
            trans_item_tr.labelInList = this.item.label_tr;
            trans_item_tr.locale = "tr";
            newItem.translations.push(trans_item_tr);
            var trans_item_en = {};
            trans_item_en.translationId = this.item.translationId_en;
            trans_item_en.title = this.item.title_en;
            trans_item_en.subtitle = this.item.subtitle_en;
            trans_item_en.labelInList = this.item.label_en;
            trans_item_en.locale = "en";
            newItem.translations.push(trans_item_en);
            return newItem;

        }
    }

};
</script>

<style scope>
.modal-category-header {
    width: 100%;
    position: relative;
}

.spinner-load {
    margin-left: 12px;
    margin-top: 12px;
}

.modal-category-header .modal-close-btn {
    position: absolute;
    top: 0;
    right: 0;
}

.modal-category .modal-container {
    max-width: 1000px !important;
}

.category-table .md-table-cell:not(.table-cell-actions) {
    text-align: left;
}

.category-checkbox .md-checkbox {
    margin: 0;
}

.form-text-info {
    text-align: left;
    font-weight: normal;
}

.gapsm {
    gap: 25px;
}

.image-container {
    display: flex;
    float: left;
    justify-content: center;
    padding: 0px;
    width: 400px;
    max-width: 400px;
    max-height: 300px;
    min-height: 300px;
    margin-left: 40px;
    margin-bottom: 48px;
    background-color: var(--media-background);
    border: solid 1px var(--media-border);
}

.image-container img {
    width: 400px;
    height: 300px;
    object-fit: fill;
    cursor:pointer;
}


.inlinefields {
    display: flex;
    padding: 0px;
    justify-content: space-between;
}

.gaplg {
    gap: 250px;
}

.gapsm {
    gap: 140px;
}

</style>