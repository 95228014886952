<template>
    <div>
        <md-dialog :md-close-on-esc="false" :md-active.sync="show" :md-click-outside-to-close="false"
            class="modal-brand">
            <md-dialog-title class="modal-media-header">
                <h3>
                    {{ name }} - {{ $t("brands") }}
                    <md-progress-spinner v-if="loading" :md-diameter="20" :md-stroke="1" md-mode="indeterminate">
                    </md-progress-spinner>
                </h3>
                <md-button @click="closeModal()" class="
                        md-primary md-just-icon md-round md-simple
                        modal-close-btn
                    ">
                    <md-icon class="md-size-2x">close</md-icon>
                </md-button>
            </md-dialog-title>
            <md-dialog-content>
                <!-- Brand List -->
                <div v-if="!creating && !editing">
                    <div class="md-layout-item md-size-100 inline-toolbar">
                        <md-field class="search-input">
                            <md-icon class="search-icon">image_search</md-icon>
                            <label>{{ $t("label.search_brand") }}</label>
                            <md-input v-model="search"  @keyup.enter="filter()" ></md-input>
                            <md-button v-if="search" class="btn-floating" @click="clearFilter()">
                                <md-icon>close</md-icon>
                            </md-button>
                        </md-field>
                        <md-button class="md-primary md-round" @click="filter()">
                            {{ $t("search") }}
                        </md-button>
                        <md-button @click="newItem()" class="md-primary md-round">
                            {{ $t("new") }}
                        </md-button>
                    </div>

                    <div v-if="Items.length == 0" table-header-color="blue"
                        class="md-content md-table md-theme-default">
                        <md-table>
                            <thead>
                                <tr><!---->
                                    <th class="md-table-head md-sortable md-sorted md-sorted-desc">
                                        <div class="md-table-head-container md-ripple">
                                            <div class="md-table-head-label">
                                                {{ $t('label.brand_name') }}
                                                <!---->
                                            </div>
                                        </div>
                                    </th>
                                    <th class="md-table-head">
                                        <div class="md-table-head-container md-ripple md-disabled">
                                            <div class="md-table-head-label"><!---->
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="md-table-row">
                                    <td class="md-table-cell" style="width: 100%;">
                                        {{ $t('label.no_records') }}
                                    </td>
                                    <td class="md-table-cell" style="width: 100%;">
                                    </td>
                                </tr>
                            </tbody>
                        </md-table>
                    </div>

                    <md-table v-if="Items.length > 0" table-header-color="blue" v-model="Items"
                        :md-sort-order.sync="currentSortOrder" :md-sort.sync="currentSort" :md-sort-fn="customSort">
                        <md-table-row slot="md-table-row" slot-scope="{ item }">
                            <md-table-cell md-sort-by="name" :md-label="$t('label.brand_name')">
                                {{ item.name }}
                            </md-table-cell>
                            <md-table-cell class="table-cell-actions">
                                <button class="btn-icon" @click="editItem(item.brandId)">
                                    <md-icon>edit</md-icon>
                                </button>
                                <button class="btn-icon" @click="confirmDelete(item)">
                                    <md-icon>delete</md-icon>
                                </button>
                            </md-table-cell>
                        </md-table-row>
                    </md-table>
                    <!-- Pagination -->
                    <div class="pagination-container">
                        <md-pagination v-model="page" :noArrows="false" :perPage="limit" :total="total" />
                    </div>
                    <md-modal v-if="showConfirm" @close="showConfirm = false">
                        <h3 slot="header">
                            {{ $t("confirm") }}
                        </h3>
                        <div slot="body">
                            {{ $t("confirm_messages.delete") }}
                        </div>
                        <div slot="footer" class="modal-footer">
                            <md-button @click="showConfirm = false" class="md-danger md-round">
                                {{ $t("cancel") }}
                            </md-button>
                            <md-button @click="deleteItem()" class="md-primary md-round">
                                {{ $t("delete") }}
                            </md-button>
                        </div>
                    </md-modal>
                </div>
                <div v-else>
                    <ValidationObserver tag="form" ref="form" v-slot="{ errors }">
                        <div class="md-layout form-layout">
                            <!-- Translations -->
                            <div class="md-layout-item md-size-50">
                                <md-card class="translations-card">
                                    <md-card-content>
                                        <md-tabs class="translations-tabs">
                                            <!-- Translations TR -->
                                            <md-tab id="tab-tr" :md-label="$t('languages.tr')"
                                                :md-icon="getLanguageIcon('tr', errors)">
                                                <!-- Brand name TR -->
                                                <md-field :class="[
                                                    { 'md-valid': errors['brand_name_tr'] && !errors['brand_name_tr'][0] && touchedFields.brand_name_tr },
                                                    { 'md-error': errors['brand_name_tr'] && errors['brand_name_tr'][0] }
                                                ]">
                                                    <label for="brand_name_tr">{{
                                                            $t("label.brand_name")
                                                    }}</label>
                                                    <validation-provider name="brand_name_tr" rules="required"
                                                        v-slot="{ errors, touched }" mode="aggressive">
                                                        <md-input v-model="brand_name_tr" name="brand_name_tr"
                                                            type="text" id="brand_name_tr" :disabled="loading || view"
                                                            :placeholder="$t('label.brand_name')"></md-input>
                                                        <slide-y-down-transition>
                                                            <md-icon class="error"
                                                                v-show="errors[0] && touched">close</md-icon>
                                                        </slide-y-down-transition>
                                                        <span class="md-error">{{ errors[0] }}</span>
                                                    </validation-provider>
                                                </md-field>
                                                <!-- Brand description TR -->
                                                <md-field :class="[
                                                    { 'md-valid': errors['brand_description_tr'] && !errors['brand_description_tr'][0] && touchedFields.brand_description_tr },
                                                    { 'md-error': errors['brand_description_tr'] && errors['brand_description_tr'][0] }
                                                ]">
                                                    <label for="text">{{ $t('label.brand_description') }}</label>
                                                    <validation-provider name='brand_description_tr' rules=""
                                                        v-slot="{ errors, touched }" mode="aggressive">
                                                        <md-textarea :md-autogrow="false" f
                                                            v-model="brand_description_tr" name="brand_description_tr"
                                                            :placeholder="$t('label.brand_description')"
                                                            id="brand_description_tr" :disabled="loading">
                                                        </md-textarea>
                                                        <slide-y-down-transition>
                                                            <md-icon class="error"
                                                                v-show="errors[0] && touched">close</md-icon>
                                                        </slide-y-down-transition>
                                                        <span class="md-error">{{ errors[0] }}</span>
                                                    </validation-provider>
                                                </md-field>
                                            </md-tab>
                                            <!-- Translations EN -->
                                            <md-tab id="tab-en" :md-label="$t('languages.en')"
                                                :md-icon="getLanguageIcon('en', errors)">
                                                <!-- Brand name EN -->
                                                <md-field :class="[
                                                    { 'md-valid': errors['brand_name_en'] && !errors['brand_name_en'][0] && touchedFields.brand_name_en },
                                                    { 'md-error': errors['brand_name_en'] && errors['brand_name_en'][0] }
                                                ]">
                                                    <label for="brand_name_en">{{
                                                            $t("label.brand_name")
                                                    }}</label>
                                                    <validation-provider name="brand_name_en" rules="required"
                                                        v-slot="{ errors, touched }" mode="aggressive">
                                                        <md-input v-model="brand_name_en" name="brand_name_en"
                                                            type="text" :placeholder="$t('label.brand_name')"
                                                            id="brand_name_en" :disabled="loading || view"></md-input>
                                                        <slide-y-down-transition>
                                                            <md-icon class="error"
                                                                v-show="errors[0] && touched">close</md-icon>
                                                        </slide-y-down-transition>
                                                        <span class="md-error">{{ errors[0] }}</span>
                                                    </validation-provider>
                                                </md-field>
                                                <!-- Brand description EN -->
                                                <md-field :class="[
                                                    { 'md-valid': errors['brand_description_en'] && !errors['brand_description_en'][0] && touchedFields.brand_description_en },
                                                    { 'md-error': errors['brand_description_en'] && errors['brand_description_en'][0] }
                                                ]">
                                                    <label for="text">{{ $t('label.brand_description') }}</label>
                                                    <validation-provider name='brand_description_en' rules=""
                                                        v-slot="{ errors, touched }" mode="aggressive">
                                                        <md-textarea :md-autogrow="false" f
                                                            v-model="brand_description_en" name="brand_description_en"
                                                            :placeholder="$t('label.brand_description')"
                                                            id="brand_description_en" :disabled="loading">
                                                        </md-textarea>
                                                        <slide-y-down-transition>
                                                            <md-icon class="error"
                                                                v-show="errors[0] && touched">close</md-icon>
                                                        </slide-y-down-transition>
                                                        <span class="md-error">{{ errors[0] }}</span>
                                                    </validation-provider>
                                                </md-field>
                                            </md-tab>
                                        </md-tabs>
                                    </md-card-content>
                                </md-card>
                            </div>
                            <!-- Brand image media -->
                            <div class="md-layout-item md-size-50">
                                <div class="form-container">
                                    <!-- Media selector -->
                                    <div class="md-layout">
                                        <div>
                                            <md-icon class="md-42">iconimage_search</md-icon>
                                        </div>
                                        <div class="md-layout-item md-size-60">
                                            <md-field class="image-field">
                                                <label class="label-images" v-if="item.media.fileName == ''">{{
                                                        $t("label.brand_image")
                                                }}</label>
                                                <label v-if="item.media.fileName == ''">{{ $t("label.brand_image")
                                                }}</label>
                                                <label v-else>{{ $t("label.brand_image") }}</label>
                                                <md-input readonly id="mediaInput" v-model="item.media.fileName"
                                                    :disabled="true">
                                                </md-input>
                                                <md-button v-if="item.media.fileName != ''" class="btn-floating"
                                                    @click="clearMedia()">
                                                    <md-icon>close</md-icon>
                                                </md-button>
                                                <span class="md-error">
                                                    {{ errorMediaMessage }}
                                                </span>
                                            </md-field>
                                        </div>
                                        <div class="md-layout-item md-size-20">
                                            <md-button @click="openMediaModal()" class="md-primary md-round">
                                                {{ $t("select_file") }}
                                            </md-button>
                                        </div>
                                    </div>

                                    <!-- Media preview -->
                                    <div>
                                        <div class="media-container">
                                            <md-icon v-if="item.imageURL==''" class="md-size-5x">image</md-icon>
                                            <img v-else :src="item.imageURL" :alt="item.imageAlt" @click.prevent="viewImage(item.media.imageURL)"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <md-button @click.prevent="cancel()" class="md-danger md-round" :disabled="loading">
                                {{ $t("cancel") }}
                            </md-button>
                            <md-button v-if="!view" @click.prevent="save()" type="submit" class="md-primary md-round"
                                :disabled="loading">
                                {{ $t("save") }}
                            </md-button>
                        </div>
                    </ValidationObserver>
                </div>
                <!-- Media Modal -->
                <media-modal :showProp="showMediaModal" @apply="applyMedia" @close="closeMediaModal()"></media-modal>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import MediaModal from "@/components/Modals/Media";
import paginationMixin from "@/mixins/pagination";
import localeMixin from "@/mixins/locale";
import {
    getShopBrands,
    getShopBrand,
    createShopBrand,
    updateShopBrand,
    deleteShopBrand,
} from "@/services/brands";

export default {
    props: ["showProp", "shopId", "name"],
    mixins: [paginationMixin, localeMixin],
    components: {
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
        MediaModal,
    },
    data() {
        return {
            loading: false,
            show: false,
            view: false,
            creating: false,
            editing: false,
            showConfirm: false,
            imagesLightbox:[],
            touchedFields: {
                brand_name_tr: false,
                brand_description_tr: false,
                brand_name_en: false,
                brand_description_en: false,
            },
            Items: [],
            item: {
                brandId: null,
                order: 0,
                name: "",
                mediaId: null,
                imageURL: "",
                imageAlt: "",
                fileName: "",
                media: {
                    fileName: "",
                    imageURL: "",
                },
                translations: [
                    {
                        locale: "en",
                        name: "",
                        text: "",
                    },
                    {
                        locale: "tr",
                        name: "",
                        text: "",
                    },
                ],
            },
            brand_name_tr: "",
            brand_description_tr: "",
            brand_name_en: "",
            brand_description_en: "",
            search: "",
            currentSort: "name",
            currentSortOrder: "asc",
            showMediaModal: false,
            errorMediaMessage: "",
            ItemIdToDelete: null,
        };
    },

    watch: {
        page() {
            this.fetchItems();
        },
        showProp() {
            this.show = this.showProp;
            if (this.show) {
                this.clearFilter();
                this.clearData();
                this.editing = false;
                this.creating = false;
                this.view = true;
                this.fetchItems();
            }
        },
    },

    methods: {

        viewImage(imageUrl) {
            this.view = true;
            this.imagesLightbox = [];
            this.$imageViewer.images(this.imagesLightbox);
            const image = {
                url: imageUrl,
            };
            this.imagesLightbox.push(image);
            this.$imageViewer.index(0);
            this.$imageViewer.show();
        },

        applyMedia(media) {
            this.item.media = media;
            this.item.mediaId = media.mediaId;
            this.item.imageURL = media.imageURL;
            this.item.fileName = media.fileName;
            this.errorMediaMessage = "";
        },

        async fetchItems() {
            this.loading = true;
            this.Items = [];
            let response;
            response = await getShopBrands(this.shopId, this.page, this.limit, this.search);
            if (response) {
                this.Items = response.result;
                this.total = response.pageInfo.totalPages * this.limit;
            } else {
                this.Items = [];
                this.total = 0;
            }
            this.loading = false;
        },

        async fetchItem(itemId) {
            this.loading = true;
            let response;
            response = await getShopBrand(this.shopId, itemId);
            if (response) {
                this.item = response;
                this.item.media = {
                    fileName: response.fileName,
                    imageURL: response.imageURL,
                };
                this.putItemTranslations();
            }
            this.loading = false;
        },

        filter() {
            this.fetchItems();
        },

        clearFilter() {
            this.search = "";
            this.fetchItems();
        },

        clearData() {
            this.item = {
                brandId: null,
                order: 0,
                name: "",
                mediaId: null,
                imageURL: "",
                imageAlt: "",
                media: {
                    fileName: "",
                    imageURL: "",
                },
                translations: [
                    {
                        locale: "en",
                        name: "",
                        text: "",
                    },
                    {
                        locale: "tr",
                        name: "",
                        text: "",
                    },
                ],
            };
            this.brand_name_tr = "";
            this.brand_description_tr = "";
            this.brand_name_en = "";
            this.brand_description_en = "";
        },

        getItemTranslations() {
            let translation_en = this.item.translations.find(({ locale }) => locale == "en");
            if (translation_en) {
                translation_en.name = this.brand_name_en;
                translation_en.text = this.brand_description_en;
            } else {
                translation_en = {
                    locale: "en",
                    name: this.brand_name_en,
                    text: this.brand_description_en,
                }
            }
            let translation_tr = this.item.translations.find(({ locale }) => locale == "tr");
            if (translation_tr) {
                translation_tr.name = this.brand_name_tr;
                translation_tr.text = this.brand_description_tr;
            } else {
                translation_tr = {
                    locale: "tr",
                    name: this.brand_name_tr,
                    text: this.brand_description_tr,
                }
            }
            const translations = [translation_en, translation_tr];
            this.item.translations = translations;
        },

        putItemTranslations() {
            let translation_en = this.item.translations.find(({ locale }) => locale == "en");
            if (translation_en) {
                this.brand_name_en = translation_en.name;
                this.brand_description_en = translation_en.text;
            }
            let translation_tr = this.item.translations.find(({ locale }) => locale == "tr");
            if (translation_tr) {
                this.brand_name_tr = translation_tr.name;
                this.brand_description_tr = translation_tr.text;
            }
            const translations = [translation_en, translation_tr];
            this.item.translations = translations;
        },

        openMediaModal() {
            this.showMediaModal = true;
        },

        closeMediaModal() {
            this.showMediaModal = false;
        },

        closeModal() {
            this.show = false;
            this.$emit("close");
        },

        cancel() {
            this.view = false;
            this.editing = false;
            this.creating = false;
        },

        customSort: function customSort(value) {
            this.fetchItems();
            return value;
        },

        newItem() {
            this.clearData();
            this.creating = true;
            this.editing = false;
            this.view = false;
        },

        editItem(itemId) {
            this.creating = false;
            this.editing = true;
            this.view = false;
            this.clearData();
            this.fetchItem(itemId);
        },

        confirmDelete(Item) {
            this.ItemIdToDelete = Item.brandId;
            this.showConfirm = true;
        },

        async deleteItem() {
            this.loading = true;
            let response;
            response = await deleteShopBrand(this.shopId, this.ItemIdToDelete);
            if (response.status == "200") {
                this.showConfirm = false;
                this.ItemIdToDelete = null;
                this.$noty.success(this.$t('success.deleted'));
                this.fetchItems();
            } else {
                this.$noty.error(this.$t('error.deleted'));
            }
            this.loading = false;
        },

        async save() {
            this.loading = true;
            var valid = await this.$refs.form.validate();

            if (this.item.mediaId == null) {
                valid = false;
                this.errorMediaMessage = this.$t('validation.required', { _field_: this.$t('label.brand_image') });
            }

            if (valid) {
                this.getItemTranslations();
                let response;
                if (this.creating) {
                    response = await createShopBrand(this.shopId, this.item);
                } else if (this.editing) {
                    response = await updateShopBrand(this.shopId, this.item);
                }
                if (response) {
                    this.$noty.success(this.$t('success.saved'));
                    this.creating = false;
                    this.editing = false;
                    this.fetchItems();
                } else {
                    this.$noty.error(this.$t('error.saved'));
                }
            } else {
                this.$noty.error(this.$t('error.saved'));
            }
            this.loading = false;
        },
    },
}
</script>

<style scoped>
.form-container {
    padding-left: 25px;
    padding-right: 25px;
}

.md-card-content {
    min-height: 375px;
}

.media-container {
    margin-bottom: 15px;
}
.media-container img {
    cursor: pointer;
}
</style>