<template>
    <div>
        <md-dialog :md-close-on-esc="false" :md-active.sync="show" :md-click-outside-to-close="false" class="modal-service">
            <md-dialog-title class="modal-service-header">
                <div class="md-layout-item md-size-100 form-toolbar">
                    <h3 v-if="modeTypeProp === 'service'">{{ $t("routes.services.title") }}</h3>
                    <h3 v-else-if="modeTypeProp === 'dine'">{{ $t("routes.restaurants.title") }}</h3>
                    <h3 v-else-if="modeTypeProp === 'ancillary'">{{ $t("routes.ancillaries.title") }}</h3>
                    <h3 v-else-if="modeTypeProp === 'shop'">{{ $t("routes.shops.title") }}</h3> <md-progress-spinner
                        class="spinner-load" v-if="loading" :md-diameter="30" :md-stroke="3"
                        md-mode="indeterminate"></md-progress-spinner>
                </div>
                <md-button @click="closeModal()" class="md-primary md-just-icon md-round md-simple modal-close-btn">
                    <md-icon class="md-size-2x">close</md-icon>
                </md-button>
            </md-dialog-title>
            <md-dialog-content>
                <div class="md-layout">
                    <div class="md-layout-item md-size-100 inline-toolbar">
                        <md-field class="search-input">
                            <md-icon class="search-icon">image_search</md-icon>
                            <label>{{ $t("search") }}</label>
                            <md-input v-model="search" @keyup.enter="filter()"></md-input>
                            <md-button v-if="search" class="btn-floating" @click="clearFilter()">
                                <md-icon>close</md-icon>
                            </md-button>
                        </md-field>
                        <md-button class="md-primary md-round btn-big" @click="filter()">
                            {{ $t("search") }}
                        </md-button>
                    </div>
                    <div class="md-layout-chips">
                        <md-chips v-model="Filters" md-placeholder="" @md-delete="clearFilter()"></md-chips>
                    </div>
                    <div class="md-layout-item md-size-100">
                        <md-table table-header-color="blue" v-model="Items" :md-sort-order.sync="currentSortOrder"
                            :md-sort.sync="currentSort" :md-sort-fn="customSort">
                            <md-table-row slot="md-table-row" slot-scope="{ item }">
                                <md-table-cell class="media-checkbox">
                                    <md-checkbox v-model="selectedElement" :value="item"></md-checkbox>
                                </md-table-cell>
                                <md-table-cell md-sort-by="order" :md-label="$t('label.service_order')">
                                    {{ item.order }}
                                </md-table-cell>
                                <md-table-cell md-sort-by="name" :md-label="$t('label.name')">
                                    {{ item.name }}
                                </md-table-cell>
                                <md-table-cell md-sort-by="enabled" :md-label="$t('label.service_enabled')">
                                    <img class="Icons-Enabled" src="@/assets/icons-tick.svg" v-show="item.enabled" />
                                    <img class="Icons-Enabled" src="@/assets/icons-cross.svg" v-show="!item.enabled" />
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                        <!-- Pagination -->
                        <div class="pagination-container">
                            <md-pagination v-model="page" :noArrows="false" :perPage="limit" :total="total" />
                        </div>
                    </div>
                </div>
            </md-dialog-content>
            <md-dialog-actions>
                <md-button class="md-primary" style="width: fit-content;padding: 0px;"
                    @click="selectElement(selectedElement)">Select</md-button>
                <md-button @click="cancel()">Cancel</md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>
  
<script>
import { getServices } from "@/services/service";
import { getShops } from "@/services/shop";
import { getDines } from "@/services/dine";
import { getAncillaries } from "@/services/ancillary";

import paginationMixin from "@/mixins/pagination";

export default {
    mixins: [paginationMixin],
    props: ['showProp', 'selectedElementProp', 'modeTypeProp'],

    data() {
        return {
            Items: [],
            Filters: [],
            lastOrder: 0,
            search: "",
            loading: false,
            show: false,
            selectedItem: null,
            currentSort: 'order',
            currentSortOrder: 'asc',
            sort: "order,asc",
            selectedElement: null,
        };
    },

    watch: {
        page() {
            this.fetchItems();
        },
        showProp() {
            this.show = this.showProp;
        },
        selectedElement: {
            handler(newValue) {
                this.$emit('change', newValue);
            },
            deep: true,
        }
    },

    created() {
        this.fetchItems();
    },

    methods: {
        async fetchItems() {
            this.loading = true;
            this.sort = this.currentSort + "," + this.currentSortOrder;
            if (this.search) {
                this.Filters = [this.search];
            } else {
                this.Filters = [];
                this.search = "";
            }

            let response;

            if (this.modeTypeProp === 'service') {
                response = await getServices(this.page, this.limit, this.search, this.sort);
            } else if (this.modeTypeProp === 'shop') {
                response = await getShops(this.page, this.limit, this.search, "",this.sort);
            } else if (this.modeTypeProp === 'dine') {
                response = await getDines(this.page, this.limit, this.search, "",this.sort);
            } else if (this.modeTypeProp === 'ancillary') {
                response = await getAncillaries(this.page, this.limit, this.search, "",this.sort);
            }

            if (response) {
                this.Items = response.result;
                this.lastOrder = response.lastOrder;
                this.total = response.pageInfo.totalPages * this.limit;
            } else {
                this.Items = [];
                this.total = 0;
                this.lastOrder = 1;
            }

            this.loading = false;
        },

        filter() {
            this.fetchItems();
        },

        clearFilter() {
            this.search = "";
            this.Filters = [];
            this.fetchItems();
        },

        customSort(value) {
            this.fetchItems();
            return value;
        },

        selectElement(element) {
            this.selectedItem = element;
            this.$emit("apply", this.selectedItem);
            this.closeModal();
        },

        closeModal() {
            this.show = false;
            this.$emit("close");
        },

        cancel() {
            this.closeModal();
        },
    },
};

</script>
  
<style>
.modal-service-header {
    width: 100%;
    position: relative;
}

.modal-service-header .modal-close-btn {
    position: absolute;
    top: 0;
    right: 0;
}

.modal-service .modal-container {
    max-width: 1000px !important;
}

</style>
  