<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <h3 class="page-title">
        {{ $t("routes.suggestions.title") }} - {{ this.title }}
        <md-progress-spinner v-if="loading" :md-diameter="20" :md-stroke="1"
          md-mode="indeterminate"></md-progress-spinner>
      </h3>
    </div>
    <div class="md-layout-item md-size-100 inline-toolbar">
      <div class="lists-container">
        <md-field>
          <md-select v-model="flightDirection" :disabled="loading" @change="handleFlightDirectionSelect">
            <md-option v-for="(direction, index) in flightDirections" :key="index" :value="direction.value">
              {{ $t(direction.title) }}
            </md-option>
          </md-select>
        </md-field>
        <md-field>
          <md-select v-model="internationalStatus" :disabled="loading" @change="handleInternationalStatusSelect">
            <md-option v-for="(status, index) in internationalStatuses" :key="index" :value="status.value">
              {{ $t(status.title) }}
            </md-option>
          </md-select>
        </md-field>
      </div>
    </div>
    <div class="md-layout-item md-size-100 inline-toolbar">
      <div class="lists-container">
      <custom-list class="custom-list" :alignment="'horizontal'" :items="steps" :selected-item="selectedStep" :show-arrow="true"
        :disabled="loading" @select="handleStepSelect">
        <template v-slot:item="{ item }">
          {{ item.title }}
        </template>
      </custom-list>
      </div>
    </div>
    <div class="md-layout-item md-size-100 inline-toolbar">

      <md-field class="search-input">
        <md-icon class="search-icon">image_search</md-icon>
        <label>{{ $t("label.search_suggestion") }}</label>
        <md-input v-model="search" @keyup.enter="filter()"></md-input>
        <md-button v-if="search" class="btn-floating" @click="clearFilter()">
          <md-icon>close</md-icon>
        </md-button>
      </md-field>
      <md-button class="md-primary md-round btn-big" @click="filter()">{{ $t("search") }}</md-button>
      <router-link :to="{ name: `${$i18n.locale}.suggestion_create`, params: { stepId: stepId,lastOrder:lastOrder } }"
        class="md-primary md-round btn-big" tag="md-button">
        {{ $t("new") }}
      </router-link>
    </div>
    <div class="md-layout-chips">
      <md-chips v-model="Filters" md-placeholder="" @md-delete="clearFilter()"></md-chips>
    </div>
    <div class="md-layout-item md-size-100">

      <div v-if="Items.length == 0" table-header-color="blue" class="md-content md-table md-theme-default">
        {{ $t('label.no_records') }}
      </div>

      <md-table table-header-color="blue" v-if="Items.length > 0" v-model="Items" :md-sort-order.sync="currentSortOrder"
        :md-sort.sync="currentSort" :md-sort-fn="customSort">
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-sort-by="order" :md-label="$t('label.order')">
            <span class="table-content-center">{{ item.order }}</span>
          </md-table-cell>
          <md-table-cell md-sort-by="title" :md-label="$t('label.title')">
            <span class="table-content-center">{{ item.title }}</span>
          </md-table-cell>
          <md-table-cell md-sort-by="type" :md-label="$t('label.suggestionType')">
            <span class="table-content-center" v-if="item.type === 0">{{ $t("label.suggestionType_value_0") }}</span>
            <span class="table-content-center" v-if="item.type === 1">{{ $t("label.suggestionType_value_1") }}</span>
            <span class="table-content-center" v-if="item.type === 2">{{ $t("label.suggestionType_value_2") }}</span>
            <span class="table-content-center" v-if="item.type === 3">{{ $t("label.suggestionType_value_3") }}</span>
          </md-table-cell>
          <md-table-cell class="table-cell-actions">
            <router-link
              :to="{ name: `${$i18n.locale}.suggestion_edit`, params: { stepId: item.stepId, suggestionId: item.suggestionId } }"
              class="btn-icon">
              <md-icon>edit</md-icon>
            </router-link>
            <button class="btn-icon" @click="confirmDelete(item)">
              <md-icon>delete</md-icon>
            </button>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <!-- Pagination -->
      <div class="pagination-container" v-if="Items.length > 0">
        <md-pagination v-model="page" :noArrows="false" :perPage="limit" :total="total" />
      </div>
    </div>
    <md-modal v-if="showConfirm" @close="showConfirm = false">
      <h3 slot="header">{{ $t("confirm") }}</h3>
      <div slot="body">{{ $t("confirm_messages.delete") }}</div>
      <div slot="footer" class="modal-footer">
        <md-button @click="showConfirm = false" class="md-danger md-round">{{ $t("cancel") }}</md-button>
        <md-button @click="deleteItem()" class="md-primary md-round">{{ $t("delete") }}</md-button>
      </div>
    </md-modal>
  </div>
</template>

<script>
import { getStepsWithoutPagination } from "@/services/step";
import { getStepSuggestions, deleteStepSuggestion } from "@/services/suggestion";
import paginationMixin from "@/mixins/pagination";
import CustomList from "@/components/CustomList";

export default {
  mixins: [paginationMixin],
  components: {
    CustomList,
  },
  data() {
    return {
      flightDirections: [
        { value: "D", title: this.$t("Departures") },
        { value: "A", title: this.$t("Arrivals") },
      ],
      internationalStatuses: [
        { value: "I", title: this.$t("International") },
        { value: "D", title: this.$t("Domestic") },
      ],
      title: "",
      Items: [],
      selectedStep: null,
      stepId: null,
      steps: [],
      Filters: [],
      flightDirection: "D",
      internationalStatus: "I",
      search: "",
      lastOrder: 0,
      currentSort: "order",
      currentSortOrder: "asc",
      sort: "order,asc",
      loading: false,
      showConfirm: false,
      ItemIdToDelete: null,
    };
  },

  watch: {
    flightDirection() {
      this.updateSteps();
    },

    internationalStatus() {
      this.updateSteps();
    },

    page() {
      this.fetchItems();
    },
  },

  created() {
    this.updateSteps();
  },

  methods: {

    async updateItems() {
      await this.fetchItems();
    },

    handleFlightDirectionSelect(index) {
      this.flightDirection = this.flightDirections[index];
    },

    handleInternationalStatusSelect(index) {
      this.internationalStatus = this.internationalStatuses[index];
    },

    async fetchItems() {
      await this.fetchSuggestions();
    },

    async handleStepSelect(selectedIndex) {
      this.selectedStep = this.steps[selectedIndex];
      if (this.selectedStep) {
        this.stepId = this.selectedStep.stepId;
        this.title = `${this.selectedStep.title}`;
      }
      await this.fetchSuggestions();
    },

    async fetchSuggestions() {
      this.loading = true;

      if (this.selectedStep !== null && this.stepId !== null) {

        this.sort = `${this.currentSort},${this.currentSortOrder}`;
        if (this.search) {
          this.Filters = [this.search];
        } else {
          this.Filters = [];
          this.search = "";
        }

        this.filters = {
          flightDirection: this.flightDirection,
          internationalStatus: this.internationalStatus,
          searchText: this.search,
        };



        const response = await getStepSuggestions(
          this.stepId,
          this.page,
          this.limit,
          this.filters,
          this.sort
        );


        if (response) {
          response.result = response.result.map((item) => {
            item.stepId = this.stepId;
            return item;
          });

          this.Items = response.result;
          this.total = response.pageInfo.totalPages * this.limit;
          this.lastOrder = response.lastOrder;
        } else {
          this.Items = [];
          this.total = 0;
          this.lastOrder = 1;
        }
      } else {
        console.debug("No carga suggestions", this.selectedStep, this.stepId);
      }

      this.loading = false;
    },

    async fetchSteps() {
      if (!this.flightDirection) {
        this.flightDirection = this.flightDirections[0].value;
      }

      if (!this.internationalStatus) {
        this.internationalStatus = this.internationalStatuses[0].value;
      }

      this.loading = true;


      let stepFilters = {
        flightDirection: this.flightDirection,
        internationalStatus: this.internationalStatus,
        searchText: this.search,
      };

      this.steps = [];

      const responseSteps = await getStepsWithoutPagination(
        stepFilters,
      );

      if (responseSteps) {
        this.steps = responseSteps.map((item) => {
          return {
            value: item.stepId,
            stepId: item.stepId,
            title: item.title,
          };
        });

        this.updateSelectedStep();
      }
      this.loading = false;
    },

    updateSteps() {
      this.selectedStep = null;
      this.stepId = null;
      this.fetchSteps();
    },

    updateSelectedStep() {
      if (this.steps.length) {
        const selectedStep = this.steps.find((step) => {
          return step.stepId === this.stepId;
        });

        if (selectedStep) {
          this.selectedStep = selectedStep;
          this.stepId = selectedStep.stepId;
          this.title = `${this.selectedStep.title}`;
        } else {
          this.selectedStep = this.steps[0];
          this.stepId = this.selectedStep.stepId;
          this.title = `${this.selectedStep.title}`;
        }

        this.updateItems();
      } else {
        this.Items = [];
      }

    },

    filter() {
      this.fetchItems();
    },

    clearFilter() {
      this.search = "";
      this.Filters = [];
      this.fetchItems();
    },

    confirmDelete(item) {
      this.ItemIdToDelete = item.suggestionId;
      this.showConfirm = true;
    },

    async deleteItem() {
      this.loading = true;
      if (this.selectedStep !== null) {
        const response = await deleteStepSuggestion(
          this.stepId,
          this.ItemIdToDelete
        );
        if (response.status == 200) {
          this.showConfirm = false;
          this.ItemIdToDelete = null;
          this.$noty.success(this.$t("success.deleted"));
          this.fetchItems();
        } else {
          this.$noty.error(this.$t("error.deleted"));
        }
      }
      this.loading = false;
    },

    customSort(value) {
      this.fetchItems();
      return value;
    },
  },
};
</script>



<style scoped>
.lists-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.lists-container .md-field {
  margin-right: 10px;
  /* Ajusta el margen derecho según sea necesario */
}

.lists-container .custom-list {
  margin-right: 10px;
  /* Ajusta el margen derecho según sea necesario */
}

.inline-toolbar {
  display: flex;
  align-items: center;
}

.custom-list {
  flex: 1;
  border: 1px;
  margin-top: -32px;
  margin-bottom: 24px;
  margin-left: -14px;
}

.inline-toolbar .lists-container {
  margin-right: auto;
}
</style>